<mwl-calendar-week-view [viewDate]="viewDate" [events]="events" [locale]="locale" [hourSegments]="hourSegments"
  [dayStartHour]="dayStartHour" [daysInWeek]="daysInWeek" [hourSegmentHeight]="hourSegmentHeight"
  [dayEndHour]="dayEndHour" [eventTemplate]="customEventTemplate" [hourSegmentTemplate]="weekViewHourSegment"
  [headerTemplate]="weekHeaderTemplate" [refresh]="refresh" (beforeViewRender)="beforeWeekViewRender($event)">
</mwl-calendar-week-view>

<ng-template #weekViewHourSegment let-segment="segment" let-locale="locale" let-segmentHeight="segmentHeight"
  let-isTimeLabel="isTimeLabel">
  <div class="cal-hour-segment" [style.height.px]="segmentHeight" [class.cal-hour-start]="segment.isStart"
    [class.cal-after-hour-start]="!segment.isStart" [ngClass]="segment.cssClass">

    <div *ngIf="!isTimeLabel">

    </div>
    <div class="cal-time" *ngIf="isTimeLabel">
      {{ segment.date | date: 'shortTime' }}
    </div>
  </div>
</ng-template>

<ng-template #weekHeaderTemplate let-days="days" let-locale="locale" let-dayHeaderClicked="dayHeaderClicked">
  <div class="cal-day-headers">
    <div class="cal-header" *ngFor="let day of days" [class.cal-past]="day.isPast" [class.cal-today]="day.isToday"
      [class.cal-future]="day.isFuture" [class.cal-weekend]="day.isWeekend" nz-popover
      [nzPopoverContent]="headerContentTemplate" nzPopoverTrigger="click" nzPopoverPlacement="rightTop"
      nzPopoverOverlayClassName="calendar-event-popover" (click)="clickDayHeaderEvent(day)">
      <b>
        {{ day.date | calendarDate:'weekViewColumnHeader':locale }}
      </b>
      <br />
      <span>
        {{ day.date | calendarDate:'weekViewColumnSubHeader':locale }}
      </span>
    </div>
  </div>
</ng-template>

<ng-template #customEventTemplate let-weekEvent="weekEvent">
  <div
    class="cal-event private-sale-slot-calendar-item ps-d-flex ps-align-items-start {{(weekEvent.event.meta.slot.isMixedRoleEnabled?'mixed':'roles')}}"
    nz-popover [nzPopoverContent]="eventContentTemplate" nzPopoverTrigger="click" nzPopoverPlacement="rightBottom"
    nzPopoverOverlayClassName="calendar-event-popover" (click)="clickEvent(weekEvent.event)">

    <!-- <div  class="sold-out-container">
      <p class="sold-out-label">{{ 'calendar_event_popover_sold_out' | translation: 'Sold Out': []}}</p>
    </div> -->

    <div class="ps-d-flex ps-flex-wrap ps-w-100 calendar-item-container">
      <h2 class="calendar-item-title ps-w-100">
        {{weekEvent.event.title}}
      </h2>
      <p class="calendar-item-hour ps-w-100">
        {{weekEvent.event.start | date: "shortTime"}} - {{weekEvent.event.end | date: "shortTime"}}
      </p>
      <p class="ps-w-100">{{weekEvent.event.meta.edit}}</p>

    </div>
  </div>
</ng-template>


<ng-template #eventContentTemplate>
  <div class="calendar-event-popover-container ps-d-flex ps-flex-wrap ps-align-items-start">
    <div class="ps-w-100 ps-d-flex ps-flex-wrap ps-h-100 ps-justify-content-center">
      <h1 class="slot-title ps-w-100">{{clickedEvent?.title}}</h1>
      <div class="ps-w-100 ps-d-flex ps-align-items-center">
        <div class="slot-date-container ps-d-flex ps-align-items-center">
          <span class="slot-date-icon" nz-icon nzType="calendar" nzTheme="outline"></span>
          <p class="ps-m-0">{{clickedEvent?.start | date: "longDate"}}</p>
        </div>
        <div class="slot-date-container ps-d-flex ps-align-items-center">
          <span class="slot-date-icon" nz-icon nzType="clock-circle" nzTheme="outline"></span>
          <p class="ps-m-0">{{clickedEvent?.start | date: "shortTime"}} - {{clickedEvent?.end | date: "shortTime"}}</p>
        </div>
      </div>
      <div class="line"></div>
      <h2 class="slot-roles-title ps-w-100">{{ 'calendar_event_popover_roles_preview' 
        | translation: 'Posti totali:':[]}}</h2>
      <div class="slot-role-stats-container ps-w-100 ps-d-flex ps-align-items-center ps-justify-content-start">
        <p class="role-stats ps-m-0">
          <container-element [ngSwitch]="(clickedEvent?.meta.slot.maxMixedRoleAttendees??-1) == -1">
            <ng-container *ngSwitchCase="true">
              {{ 'calendar_event_popover_illimited_number' | translation: 'posti illimitati': []}}
            </ng-container>
            <ng-container *ngSwitchCase="false">
              {{(clickedEvent?.meta.slot.maxMixedRoleAttendees)-(clickedEvent?.meta.slot.currentSlotRoleAttendees)}}/{{clickedEvent?.meta.slot.maxMixedRoleAttendees}}
              {{'calendar_event_popover_free_slot_number' | translation: 'posti disponibili': []}}
            </ng-container>
          </container-element>
        </p>
      </div>
      <div class="line"></div>
      <h2 class="slot-roles-title ps-w-100">{{ 'calendar_event_popover_roles_preview' | translation: 'Ruoli attivi:':
        []}}</h2>

      <container-element class="ps-d-flex ps-w-100 ps-flex-wrap"
        [ngSwitch]="clickedEvent?.meta.slot.isMixedRoleEnabled??false">
        <ng-container *ngSwitchCase="true">
          <div class="slot-role-stats-container ps-w-100 ps-d-flex ps-align-items-center ps-justify-content-start">
            <p class="role-name ps-m-0">{{ 'calendar_event_popover_all_roles' | translation: 'Tutti i ruoli': []}}:</p>
            <p class="role-stats ps-m-0">
              <container-element [ngSwitch]="(clickedEvent?.meta.slot.maxMixedRoleAttendees??-1) == -1">
                <ng-container *ngSwitchCase="true">
                  {{ 'calendar_event_popover_illimited_number' | translation: 'posti illimitati': []}}
                </ng-container>
                <ng-container *ngSwitchCase="false">
                  {{(clickedEvent?.meta.slot.maxMixedRoleAttendees -
                  clickedEvent?.meta.slot.currentSlotRoleAttendees)}}/{{clickedEvent?.meta.slot.maxMixedRoleAttendees}}
                  {{'calendar_event_popover_free_slot_number' | translation: 'posti disponibili': []}}
                </ng-container>
              </container-element>
            </p>
          </div>
        </ng-container>
        <ng-container *ngSwitchCase="false">
          <div *ngFor="let role of clickedEvent?.meta.slot.roles??[]"
            class="slot-role-stats-container ps-w-100 ps-d-flex ps-align-items-center">
            <p class="role-name ps-m-0">{{role.roleName}}:</p>
            <p class="role-stats ps-m-0">
              <container-element [ngSwitch]="role.slotTotalCapacity == -1">
                <ng-container *ngSwitchCase="true">
                  {{ 'calendar_event_popover_illimited_number' | translation: 'posti illimitati': []}}
                </ng-container>
                <ng-container *ngSwitchCase="false">
                  {{role.slotAvailableCapacity}}/{{role.slotTotalCapacity}}
                  {{'calendar_event_popover_free_slot_number' | translation: 'posti disponibili': []}}
                </ng-container>
              </container-element>
            </p>
          </div>
        </ng-container>
      </container-element>


      <h2 class="slot-actions-title ps-w-100">{{ 'calendar_event_popover_actions' | translation: 'Azioni:':
        []}}</h2>
      <div class="ps-w-100 ps-d-flex ps-flex-wrap role-button-container">
        <div class="ps-col-6 ps-d-flex ps-align-items-center ps-justify-content-center">
          <button (click)="showBookedUsers(clickedEvent?.meta.slot, 'slot')" nz-button
            class="role-button show-users ps-w-100">
            <span nz-icon nzType="eye" nzTheme="outline"></span>
            {{ 'calendar_event_popover_show_guest_list' | translation: 'Mostra Utenti': []}}
          </button>
        </div>
        <div class="ps-col-6 ps-d-flex ps-align-items-center ps-justify-content-center">
          <button (click)="exportToExcel(clickedEvent?.meta.slot, 'slot')" nz-button
            class="role-button export-excel ps-w-100">
            <span nz-icon nzType="download" nzTheme="outline"></span>
            {{ 'calendar_event_popover_export_excel' | translation: 'Esporta Excel': []}}
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-template>


<ng-template #headerContentTemplate>
  <div class="calendar-event-popover-container ps-d-flex ps-flex-wrap ps-align-items-start">
    <div class="ps-w-100 ps-d-flex ps-flex-wrap ps-h-100 ps-justify-content-center">
      <h1 class="slot-title ps-w-100">{{ 'calendar_event_popover_day_summary' | translation: 'Riepilogo Prenotazioni':
        []}}</h1>
      <div class="ps-w-100 ps-d-flex ps-align-items-center">
        <div class="slot-date-container ps-d-flex ps-align-items-center">
          <span class="slot-date-icon" nz-icon nzType="calendar" nzTheme="outline"></span>
          <p class="ps-m-0">{{clickedDaySlotSummary?.dateDay??undefined | date: "longDate"}}</p>
        </div>
        <!-- <div class="slot-date-container ps-d-flex ps-align-items-center">
          <span class="slot-date-icon" nz-icon nzType="clock-circle" nzTheme="outline"></span>
          <p class="ps-m-0">{{clickedEvent?.start | date: "shortTime"}} - {{clickedEvent?.end | date: "shortTime"}}</p>
        </div> -->
      </div>
      <div class="line"></div>
      <h2 class="slot-roles-title ps-w-100">{{ 'calendar_event_popover_roles_preview' 
        | translation: 'Posti totali:':[]}}</h2>
      <div class="slot-role-stats-container ps-w-100 ps-d-flex ps-align-items-center ps-justify-content-start">
        <p class="role-stats ps-m-0">
          <container-element [ngSwitch]="(clickedDaySlotSummary?.totalSlotMaxQty??-1) == -1">
            <ng-container *ngSwitchCase="true">
              {{ 'calendar_event_popover_illimited_number' | translation: 'posti illimitati': []}}
            </ng-container>
            <ng-container *ngSwitchCase="false">
              {{clickedDaySlotSummary?.totalSlotAvailableQty}}/{{clickedDaySlotSummary?.totalSlotMaxQty}}
              {{'calendar_event_popover_free_slot_number' | translation: 'posti disponibili': []}}
            </ng-container>
          </container-element>
        </p>
      </div>
      <div class="line"></div>
      <h2 class="slot-roles-title ps-w-100">{{ 'calendar_event_popover_roles_preview' 
        | translation: 'Ruoli attivi:':[]}}</h2>

      <div *ngIf="clickedDaySlotSummary?.someMixedSlots??false"
        class="slot-role-stats-container ps-w-100 ps-d-flex ps-align-items-center ps-justify-content-start">
        <p class="role-name ps-m-0">{{ 'calendar_event_popover_all_roles' | translation: 'Tutti i ruoli': []}}:</p>
        <p class="role-stats ps-m-0">
          <container-element [ngSwitch]="(clickedDaySlotSummary?.mixedSlotRoleSummary?.totalSlotMaxQty??-1) == -1">
            <ng-container *ngSwitchCase="true">
              {{ 'calendar_event_popover_illimited_number' | translation: 'posti illimitati': []}}
            </ng-container>
            <ng-container *ngSwitchCase="false">
              {{clickedDaySlotSummary?.mixedSlotRoleSummary?.totalSlotAvailableQty}}/{{clickedDaySlotSummary?.mixedSlotRoleSummary?.totalSlotMaxQty}}
              {{'calendar_event_popover_free_slot_number' | translation: 'posti disponibili': []}}
            </ng-container>
          </container-element>
        </p>
      </div>

      <ng-container *ngIf="clickedDaySlotSummary?.someNotMixedSlots??false">
        <div *ngFor="let role of clickedDaySlotSummary?.saleEventDaySlotRoleSummary??[]"
          class="slot-role-stats-container ps-w-100 ps-d-flex ps-align-items-center">
          <p class="role-name ps-m-0">{{role.roleName}}:</p>
          <p class="role-stats ps-m-0">
            <container-element [ngSwitch]="role.totalSlotMaxQty == -1">
              <ng-container *ngSwitchCase="true">
                {{ 'calendar_event_popover_illimited_number' | translation: 'posti illimitati': []}}
              </ng-container>
              <ng-container *ngSwitchCase="false">
                {{role.totalSlotAvailableQty}}/{{role.totalSlotMaxQty}}
                {{'calendar_event_popover_free_slot_number' | translation: 'posti disponibili': []}}
              </ng-container>
            </container-element>
          </p>
        </div>
      </ng-container>

      <h2 class="slot-actions-title ps-w-100">{{ 'calendar_event_popover_actions' | translation: 'Azioni:':
        []}}</h2>
      <div class="ps-w-100 ps-d-flex ps-flex-wrap role-button-container">
        <div class="ps-col-6 ps-d-flex ps-align-items-center ps-justify-content-center">
          <button (click)="showBookedUsers(undefined, 'day')" nz-button
            class="role-button show-users ps-w-100">
            <span nz-icon nzType="eye" nzTheme="outline"></span>
            {{ 'calendar_event_popover_show_guest_list' | translation: 'Mostra Utenti': []}}
          </button>
        </div>
        <div class="ps-col-6 ps-d-flex ps-align-items-center ps-justify-content-center">
          <button (click)="exportToExcel(undefined, 'day')" nz-button
            class="role-button export-excel ps-w-100">
            <span nz-icon nzType="download" nzTheme="outline"></span>
            {{ 'calendar_event_popover_export_excel' | translation: 'Esporta Excel': []}}
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-template>