import { Injectable } from '@angular/core';
import { ContentMediaFile, GetAllImageMediasResponse, IAppMediaService, MediaFile, MediaInfo, PurpleApiResponseStatus, UploadImageOptions, UploadMediaType } from 'purple-lib';
import { lastValueFrom } from 'rxjs';
import { MediaManagerService } from '../sdk/media/api/mediaManager.service';

@Injectable({
  providedIn: 'root'
})
export class AppMediaService implements IAppMediaService {

  constructor(private mediaSvc: MediaManagerService) {
  }
  mediaControllerName: string = "MediaManager";

  async getAllImageMedias(pageNumber: number, pageSize: number, languageId: string): Promise<GetAllImageMediasResponse> {
    var res = await lastValueFrom(
      this.mediaSvc.getAllImageMedias(languageId,{
        pageNumber: pageNumber,
        pageSize: pageSize
      })
    );
    if (res.status == PurpleApiResponseStatus.Success) {
      return res!.data!;
    } else {
      return {
        images: [],
        pageNumber: 1,
        pageTot: 0
      };
    }
  }

  async getMediaInfo(languageId: string, contentId: string, contentTypeId: string, contentMediaIdx: number): Promise<MediaInfo | undefined> {
    var res = await lastValueFrom(
      this.mediaSvc.getMediaInfo(languageId,{
        contentId: contentId,
        contentTypeId: contentTypeId,
        contentMediaIdx: contentMediaIdx
      })
    );
    if (res.status == PurpleApiResponseStatus.Success) {
      return res!.data!;
    } else {
      return undefined;
    }
  }

  async deleteMediaContentMedia(languageId: string, contentId: string, contentTypeId: string, contentMediaIdx: number): Promise<boolean> {
    var res = await lastValueFrom(
      this.mediaSvc.deleteMediaContentMedia(languageId,{
        contentId: contentId,
        contentTypeId: contentTypeId,
        contentMediaIdx: contentMediaIdx
      })
    );
    if (res.status == PurpleApiResponseStatus.Success) {
      return true;
    } else {
      return false;
    }
  }

  
  async deleteMediaContentMediaById(languageId: string, mediaId: string): Promise<boolean> {
    var res = await lastValueFrom(
      this.mediaSvc.deleteMediaContentMediaById(languageId,{
        mediaId: mediaId
      })
    );
    if (res.status == PurpleApiResponseStatus.Success) {
      return true;
    } else {
      return false;
    }
  }
  
  async checkIfContentMediasExistUpload(contentId: string, contentTypeId: string, languageId: string): Promise<ContentMediaFile[]> {
    var res = await lastValueFrom(this.mediaSvc.checkIfContentMediasExistUpload(languageId, {
      contentId,
      contentTypeId
    }));

    return res.data??[];
  }

  async checkIfMediaExistUpload(mediaId: string, languageId: string): Promise<MediaFile[]> {
    var res = await lastValueFrom(this.mediaSvc.checkIfMediaExistUpload(languageId, {
      mediaId
    }));

    return res.data??[];
  }

  async uploadFile(languageId: string, uploadMediaType: UploadMediaType, file: Blob, overrideContentMedia: boolean, contentId: string, contentTypeId: string, mediaIdx?: number | undefined, 
    fileName?: string | undefined, overrideCrc?: string | undefined, uploadImageOptions?: UploadImageOptions | undefined): Promise<boolean> {
    console.log("LE OPTIONS: ", uploadImageOptions)
    console.log("LE OPTIONS fileName: ", fileName)
    
    var res = await lastValueFrom(
      this.mediaSvc
        .uploadComplete(
          languageId, 
          file, 
          uploadMediaType, 
          contentTypeId, 
          contentId, 
          mediaIdx??1, 
          overrideContentMedia,
          uploadImageOptions?.useDefaultValues??true,
          fileName, 
          undefined, 
          overrideCrc,
          uploadImageOptions?.processImageSettings?.resizeImageOptions?.pxWidth??undefined, 
          uploadImageOptions?.processImageSettings?.resizeImageOptions?.pxHeight??undefined, 
          uploadImageOptions?.processImageSettings?.resizeImageOptions?.ignoreAspectRatio??undefined, 
          uploadImageOptions?.processImageSettings?.resizeImageOptions?.shrinkOnlyLargerImage??undefined, 
          uploadImageOptions?.processImageSettings?.resizeImageOptions?.enlargeOnlySmallerImage??undefined, 
          uploadImageOptions?.processImageSettings?.resizeImageOptions?.fillArea??undefined, 
          uploadImageOptions?.processImageSettings?.convertImageOptions?.toExtension??undefined, 
          uploadImageOptions?.processImageSettings?.compressImageOptions?.quality??undefined, 
          uploadImageOptions?.processImageSettings?.compressImageOptions?.lossLess??undefined)
    );
    if (res.status == PurpleApiResponseStatus.Success) {
      console.log("File - contentId: ", contentId, " - contentTypeId: ", contentTypeId, " - language: ", languageId)
      return true;
    } else {
      return false;
    }
  }
  
}
