import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthenticationGuard } from 'src/core-modules/authentication/authentication.guard';
import { ShowMenuGuard } from 'src/core-modules/show-menu/showMenu.guard';
import { TranslationsComponent } from './translations/translations.component';
import { PrivateSalePoliciesComponent } from '../private-sales/detail/private-sale-policies/private-sale-policies.component';
import { GeneralImagesComponent } from './general-images/general-images.component';
import { CouponsRegistryComponent } from './coupons-registry/coupons-registry.component';

const routes: Routes = [
  {
    path: '',
    canActivate: [AuthenticationGuard],
    children: [
      {
        path: '',
        redirectTo: 'translations',
        pathMatch: "full"
      },
      {
        path: 'translations',
        component: TranslationsComponent,
        data: { submenu: "translations" },
        canActivate: [ShowMenuGuard]
      },
      {
        path: 'policies',
        component: PrivateSalePoliciesComponent,
        data: { submenu: "policies" },
        canActivate: [ShowMenuGuard]
      },
      {
        path: 'general-images',
        component: GeneralImagesComponent,
        data: { submenu: "general-images" },
        canActivate: [ShowMenuGuard]
      },
      {
        path: 'coupon-registry',
        component: CouponsRegistryComponent,
        data: { submenu: "coupon-registry" },
        canActivate: [ShowMenuGuard]
      }
    ]
  }

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class GeneralRoutingModule { }
