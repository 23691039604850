<ng-container *ngIf="dashSvc.dashboardHeight != undefined">

  <purple-table #purpleTable [tableBody]="tableBody" [isBordered]="true" [switchOn]="false"
    [refreshFunction]="refreshFunction" (tableData)="listOfData = $event" [tableColumns]="tabColumns"
    [tableScroll]="{y:dashSvc.dashboardHeight}" [refreshTableRowsNumber]="refreshTableRowsNumber" [version]="2" 
    [showCustomPagination]="true" tableSearchType="box" (filterBox)="dashSvc.setPurpleTablePopover($event)"
    (onSearchBoxEnd)="dashSvc.closePurpleTablePopover()" [filterBoxStatus]="dashSvc.purpleTableBoxFilterStatus">


    <ng-template #tableBody>
      <ng-container *ngFor="let data of listOfData; let i = index">
        <tr class="{{i%2==0?'main-purple-table-tr-even': 'main-purple-table-tr-odd'}} ">
          <td>{{ data.item.defaultCouponName}}</td>
          <td>
            <nz-tag class="ps-mt-2" purpleStringToColor [word]="data.item.isCouponDiscountMoneyEnabled?'money':'percent'" [opacity]="10"> 
              <container-element [ngSwitch]="data.item.isCouponDiscountMoneyEnabled">
                <ng-container *ngSwitchCase="true">
                    {{ 'table_discount_type_money' | translation: 'Soldi': []}}
                </ng-container>
                <ng-container *ngSwitchCase="false">
                  {{ 'table_discount_type_precentage' | translation: 'Percentuale': []}}
                </ng-container>
              </container-element>
            </nz-tag>
          </td>
          <td>
              <container-element [ngSwitch]="data.item.isCouponDiscountMoneyEnabled">
                <ng-container *ngSwitchCase="true">
                  <p class="ps-m-0 ps-p-0 fw-b">{{data.item.couponDiscountMoney}}</p>
                </ng-container>
                <ng-container *ngSwitchCase="false">
                  <p class="ps-m-0 ps-p-0 fw-b">{{data.item.couponDiscountPercentage}}%</p>
                  
                </ng-container>
              </container-element>
          </td>
          <td>{{ data.item.couponMaxTotalRedeems}}</td>
          <td>{{ data.item.couponMaxUserRedeems}}</td>
          <td nzAlign="center">
            <container-element [ngSwitch]="data.item.couponIsCumulative">
              <ng-container *ngSwitchCase="true">
                <p class="ps-m-0 ps-p-0 fw-b">{{ 'table_yes' | translation: 'Si': []}}</p>
              </ng-container>
              <ng-container *ngSwitchCase="false">
                <p class="ps-m-0 ps-p-0 fw-b">{{ 'table_no' | translation: 'No': []}}</p>

              </ng-container>
            </container-element>
          </td>

          <td nzAlign="center">
            <button (click)="editCoupon(data.item!)" 
            [nzTooltipTitle]="'default_coupon_table_edit_default_coupon__tooltip' | translation: 'Modifica template coupon': []"
            
            nzTooltipPlacement="top" nz-tooltip class="ps-mr-3" nz-button nzType="default" nzShape="circle"><i nz-icon
              class="fsp-18-0" nzType="edit"></i></button>
              <button (click)="deleteCoupon(data.item!)" 
              [nzTooltipTitle]="'default_coupon_table_remove_default_coupon_tooltip' | translation: 'Elimina template coupon': []"
            nzTooltipPlacement="top" nz-tooltip  nz-button nzType="default" nzShape="circle"><i nz-icon
              class="fsp-18-0" nzType="delete"></i></button>
          </td>
        </tr>
      </ng-container>
    </ng-template>
  </purple-table>
</ng-container>