import { Component, OnDestroy, OnInit } from '@angular/core';
import { PurpleLoaderService, PurpleTranslationPipe, PurpleTableColumn, PurpleTableSearchValue, PurpleTableSelectFilter } from 'purple-lib';
import { AppTranslationService } from 'src/core-modules/localization/localization.service';

import { BackOfficeService, PRPVSaleEventPurpleTableResponse, PRPVSaleEvent, Role, PurpleTableSearchGroupGroup } from 'src/core-modules/sdk/api';
import { NzModalService } from 'ng-zorro-antd/modal';
import { AddSaleEventFastModalComponent } from '../modals/add-sale-event-fast-modal/add-sale-event-fast.component-modal';
import { Router } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { DashboardLayout2Service } from 'src/app/dashboard/dashboard-layout-2/dashboard-layout-2.service';

@Component({
  selector: 'app-private-sales-list',
  templateUrl: './private-sales-list.component.html',
  styleUrls: ['./private-sales-list.component.scss']
})
export class PrivateSalesListComponent implements OnInit, OnDestroy {

  constructor(public dashSvc: DashboardLayout2Service, public backUserSvc: BackOfficeService, private loaderSvc: PurpleLoaderService,
    private modal: NzModalService, private tranPipe: PurpleTranslationPipe, public tsvc: AppTranslationService, private router: Router) { }

  ngAfterViewInit(): void {
    const pageName = "private-sale-list";
    this.dashSvc.setPageHeader(
      this.tranPipe.transform(pageName + '_page_title', 'Lista Eventi', []),
      this.tranPipe.transform(pageName + '_page_subtitle', 'Lista di tutte le private sale', []))
  }

  sub: Subject<void> = new Subject();
  listOfData: Array<PRPVSaleEventPurpleTableResponse> | undefined | null;
  refreshTableRowsNumber: number = 0;
  tabColumns: PurpleTableColumn[] = [
    {
      name: this.tranPipe.transform("table_private_sales_status", "Stato"),
      hasReorder: false,
      hasFilter: false,
      fixedWidth: "150px"
    },
    {
      name: this.tranPipe.transform("table_header_name", "Nome"),
      dbKey: "saleEventName",
      hasReorder: true,
      hasFilter: true,
      filterType: 'search'
    },
    {
      name: this.tranPipe.transform("table_private_sales_address", "Indirizzo"),
      dbKey: "addressFull",
      hasReorder: true,
      hasFilter: true,
      filterType: 'search' 
    },
    {
      name: this.tranPipe.transform("table_private_sales_public_date", "Date Pubblico"),
      dbKey: "publicOpenDate",
      hasReorder: true,
      hasFilter: false
    },
    {
      name: this.tranPipe.transform("table_private_sales_is_online", "Visibile Online"),
      dbKey: "isOnline",
      hasReorder: true,
      hasFilter: true,
      filterType: 'select',
      fixedWidth: "160px",
      filters: [
        {
          text: "Si",
          value: true
        },
        {
          text: "No",
          value: false
        }
      ]
    }
  ]


  refreshFunction = (args: {
    pageNumber: number, pageSize: number, orderColumnName?: string | null, orderType?: string | null,
    tableSearchValues?: Array<PurpleTableSearchGroupGroup> | null, tableFilters?: Array<PurpleTableSelectFilter> | null, switchOn?: boolean,
    culture: string
  }) => {
    return this.backUserSvc.getSaleEventsTable(args.culture, args)
  }


  async ngOnInit() {
    this.loaderSvc.addRequest("internal-loader")

    this.dashSvc.dashMainItems?.push(
      {
        id: "bt",
        type: "button",
        buttonText: this.tranPipe.transform("table_add_new_item_button", "Aggiungi {0}", ["Private Sale"]),
        buttonFunction: () => { this.editItem(undefined) }
      })
    this.loaderSvc.removeRequest("internal-loader", 350)
  }

  ngOnDestroy(): void {
    this.dashSvc.clearPageHeader();
    this.sub.next();
    this.sub.complete();
  }

  getSaleEventState(sale: PRPVSaleEvent): { color: string, text: string } {
    const sd = new Date(Date.parse(sale.publicOpenDate));
    const ed = new Date(Date.parse(sale.publicCloseDate));
    const now = new Date();

    if (sd > now) {
      return { color: "orange", text: this.tranPipe.transform('next_event', 'Prossimamente', []) }
    } else {
      if (ed < now) {
        return { color: "blue", text: this.tranPipe.transform('past_event', 'Conclusa', []) }
      }
    }

    return { color: "green", text: this.tranPipe.transform('now_event', 'In Corso', []) }
  }

  getSaleEventOnlineStatus(sale: PRPVSaleEvent) {
    const ed = new Date(Date.parse(sale.publicCloseDate));
    const now = new Date();

    if (ed < now) {
      return "-"
    }

    return sale.isOnline? this.tranPipe.transform('table_yes','Si', []) : this.tranPipe.transform('table_no','No', [])
  }

  editItem(saleEvent: PRPVSaleEvent | undefined) {
    this.modal.create<AddSaleEventFastModalComponent, PRPVSaleEvent | undefined>({
      nzContent: AddSaleEventFastModalComponent,
      nzData: saleEvent,
      nzTitle: saleEvent == undefined ? this.tranPipe.transform("table_item_add_new_private_sale_fast", "Crea nuova private sale") :
        this.tranPipe.transform("table_item_edit_modal_title_update", "Modifica <b>{0}</b>", [saleEvent.saleEventName]),
      nzWidth: '1000px',
      nzClassName: 'purple-modal',
      nzFooter: null,
      nzMaskClosable: false,
    }).afterClose.pipe(takeUntil(this.sub)).subscribe(async (item: Role | undefined) => {
      if (item != undefined) {
        this.refreshTableRowsNumber++;
      }
    });
  }

  async saleEventClick(saleEvent: PRPVSaleEvent) {
    console.log("Sale event click")
    await this.router.navigate([this.tsvc.currentLanguage.value, 'dashboard', 'private-sale-detail', 'general-informations'], { queryParams: { saleEvent: saleEvent.saleEventId } })
  }

}
