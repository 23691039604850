import { Component, Inject, LOCALE_ID, OnInit } from '@angular/core';
import { Guid, PurpleApiMakeRequestResponse, PurpleApiProxyService, PurpleSelectFilter, PurpleTableBoxColumn, PurpleTableSelectFilter, PurpleTranslationPipe, dashboardButton } from 'purple-lib';
import { DashboardLayout2Service } from 'src/app/dashboard/dashboard-layout-2/dashboard-layout-2.service';
import { AppTranslationService } from 'src/core-modules/localization/localization.service';

import { BackOfficeService, PRPVSaleEventFullExtended, PRPVSaleEventSlotsBooking, PRPVSaleEventSlotsBookingPurpleTableResponse, PurpleTableSearchGroupGroup, UserBookingDetailAmount } from 'src/core-modules/sdk/api';
import { PrivateSaleService } from '../../../../private-sale-service.service';
import { Subject, takeUntil } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NzMessageService } from 'ng-zorro-antd/message';
import { DatePipe, FormatWidth, getLocaleDateFormat } from '@angular/common';
import { BookSlotUserComponent } from '../../../modals/book-slot-user/book-slot-user.component';
import { environment } from 'src/environments/default/environment';
import { isBefore } from 'date-fns';

@Component({
  selector: 'app-private-sale-users-public',
  templateUrl: './private-sale-users-public.component.html',
  styleUrls: ['./private-sale-users-public.component.scss']
})
export class PrivateSaleUsersPublicComponent implements OnInit {

  constructor(@Inject(LOCALE_ID) private locale: string, private tranPipe: PurpleTranslationPipe, public dashSvc: DashboardLayout2Service, private tsvc: AppTranslationService, private router: Router,
    public admSvc: BackOfficeService, private seSvc: PrivateSaleService, private apiProxySvc: PurpleApiProxyService, private route: ActivatedRoute,
    private modal: NzModalService, private mesSvc: NzMessageService, private datePipe: DatePipe) { }

  bookingHost: string = localStorage.getItem("BookingHost")!;
  basePath: string = localStorage.getItem("BasePath")!;
  showTable: boolean = false;
  saleEvent: PRPVSaleEventFullExtended | undefined;
  sub: Subject<void> = new Subject();
  listOfData: Array<PRPVSaleEventSlotsBookingPurpleTableResponse> | undefined | null;
  refreshTableRowsNumber: number = 0;
  userDetailAmount: UserBookingDetailAmount | undefined;
  isLoading: boolean = false;

  //ScrollFunction
  usersScrollFunction = (args: { pageNumber: number, pageSize: number, culture: string, searchName: string, searchFilters: PurpleSelectFilter[] }) => {
    return this.admSvc.getUsersSelect(args.culture, args)
  }
  //SearchFunction
  usersSearchFunction = (args: { pageNumber: number, pageSize: number, culture: string, searchName: string, searchFilters: PurpleSelectFilter[] }) => {
    return this.admSvc.getUsersByNameSelect(args.culture, args)
  }
  //InitFunction (SearchByIdFunction)
  usersSearchByIdFunction = (args: { pageNumber: number, pageSize: number, culture: string, searchName: string, searchFilters: PurpleSelectFilter[] }) => {
    return this.admSvc.getUsersByIdSelect(args.culture, args)
  }

  //ScrollFunction
  saleEventRolesScrollFunction = (args: { pageNumber: number, pageSize: number, culture: string, searchName: string, searchFilters: PurpleSelectFilter[] }) => {
    args.searchFilters = [
      {
        propertyName: "saleEventId",
        filterOperator: "=",
        filterValues: [this.saleEvent!.saleEventId]
      },
      {
        propertyName: "IsPrivateInviteCounter",
        filterOperator: "=",
        filterValues: [this.saleEvent!.isPrivateInviteCounter ? "true" : "false"]
      }
    ]
    return this.admSvc.getSaleEventRolesSelect(args.culture, args)
  }
  //SearchFunction
  saleEventRolesSearchFunction = (args: { pageNumber: number, pageSize: number, culture: string, searchName: string, searchFilters: PurpleSelectFilter[] }) => {
    args.searchFilters = [
      {
        propertyName: "saleEventId",
        filterOperator: "=",
        filterValues: [this.saleEvent!.saleEventId]
      },
      {
        propertyName: "IsPrivateInviteCounter",
        filterOperator: "=",
        filterValues: [this.saleEvent!.isPrivateInviteCounter ? "true" : "false"]
      }
    ]
    return this.admSvc.getSaleEventRolesByNameSelect(args.culture, args)
  }
  //InitFunction (SearchByIdFunction)
  saleEventRolesSearchByIdFunction = (args: { pageNumber: number, pageSize: number, culture: string, searchName: string, searchFilters: PurpleSelectFilter[] }) => {
    return this.admSvc.getSaleEventRolesByIdSelect(args.culture, args)
  }


  tabColumns: PurpleTableBoxColumn[] = [
    {
      name: this.tranPipe.transform("booked_user_table_header_slot", "Fascia Oraria"),
      dbKey: "",
      hasReorder: false,
      hasFilter: false
    },
    {
      name: this.tranPipe.transform("booked_user_table_header_name", "Nome"),
      dbKey: "firstname",
      hasReorder: true,
      hasFilter: true,
      filterField: {
        fieldType: 'input-text',
        fieldControlName: 'firstname',
        fieldBootstrapColumn: 6,
        fieldName: this.tranPipe.transform('booked_user_table_box_filter_firstname', 'Nome', []),
        fieldPlaceholder: this.tranPipe.transform('table_box_filter_firstname_placeholder', 'Nome del cliente', []),
        fieldValue: undefined,
        fieldIsRequired: false,
        fieldIsDisabled: false,
        fieldPaddingLeft: false,
        fieldPaddingRight: true
      },
      operatorType: "like"
    },
    {
      name: this.tranPipe.transform("booked_user_table_header_lastname", "Cognome"),
      dbKey: "sourceLastName",
      hasReorder: true,
      hasFilter: true,
      filterField: {
        fieldType: 'input-text',
        fieldControlName: 'sourceLastName',
        fieldBootstrapColumn: 6,
        fieldName: this.tranPipe.transform('tbooked_user_table_box_filter_lastname', 'Cognome', []),
        fieldPlaceholder: this.tranPipe.transform('booked_user_table_box_filter_lastname_placeholder', 'Cognome del cliente', []),
        fieldValue: undefined,
        fieldIsRequired: false,
        fieldIsDisabled: false,
        fieldPaddingLeft: true,
        fieldPaddingRight: false
      },
      operatorType: "like"
    },
    {
      name: this.tranPipe.transform("booked_user_table_header_email", "Email"),
      dbKey: "sourceEmail",
      hasReorder: true,
      hasFilter: true,
      filterField: {
        fieldType: 'input-text',
        fieldControlName: 'sourceEmail',
        fieldBootstrapColumn: 12,
        fieldName: this.tranPipe.transform('booked_user_table_box_filter_email', 'Email', []),
        fieldPlaceholder: this.tranPipe.transform('booked_user_table_box_filter_email_placeholder', 'Email del cliente', []),
        fieldValue: undefined,
        fieldIsRequired: false,
        fieldIsDisabled: false,
        fieldPaddingLeft: false,
        fieldPaddingRight: false
      },
      operatorType: 'like'
    },
    {
      name: this.tranPipe.transform("booked_user_table_header_checkInDate", "Ora check-in"),
      dbKey: "checkInDate",
      hasReorder: true,
      hasFilter: true,
      filterField: {
        fieldType: 'date-range',
        fieldControlName: 'registrationDate',
        fieldBootstrapColumn: 12,
        fieldName: this.tranPipe.transform('table_filter_registration_date', 'Data Registrazione', []),
        fieldPlaceholder: this.tranPipe.transform('table_filter_registration_date_placeholder', 'Data Registrazione cliente', []),
        fieldValue: undefined,
        fieldIsRequired: false,
        fieldIsDisabled: false,
        fieldPaddingLeft: false,
        fieldPaddingRight: false,
        fieldOptions: [
          { id: 'dateFormat', value: getLocaleDateFormat(this.locale, FormatWidth.Medium) },
          { id: 'disabledDate', value: undefined }
        ]
      },
      operatorType: "date between"
    },
    {
      name: this.tranPipe.transform("booked_user_table_header_role", "Ruolo"),
      dbKey: "bookedSlotRoleId",
      hasReorder: true,
      hasFilter: true,
      filterField: {
        fieldType: 'select',
        fieldControlName: 'bookedSlotRoleId',
        fieldBootstrapColumn: 12,
        fieldName: this.tranPipe.transform('booked_user_table_box_filter_role', 'Ruolo', []),
        fieldPlaceholder: this.tranPipe.transform('booked_user_table_box_filter_role_placeholder', 'Ruplo cliente', []),
        fieldValue: undefined,
        fieldIsRequired: false,
        fieldIsDisabled: false,
        fieldPaddingLeft: false,
        fieldPaddingRight: false,
        fieldOptions: [
          { id: 'scrollFunction', value: this.saleEventRolesScrollFunction },
          { id: 'searchFunction', value: this.saleEventRolesSearchFunction },
          { id: 'searchByIdFunction', value: this.saleEventRolesSearchByIdFunction },
          { id: 'refreshFieldNumber', value: 0 }
        ]
      },
      operatorType: '='
    },
    {
      name: this.tranPipe.transform("table_header_actions", "Azioni"),
      hasReorder: false,
      hasFilter: false,
      fixedWidth: "230px"
    }
  ]

  refreshFunction = (args: {
    pageNumber: number, pageSize: number, orderColumnName?: string | null, orderType?: string | null,
    tableSearchValues?: Array<PurpleTableSearchGroupGroup> | null, tableFilters?: Array<PurpleTableSelectFilter> | null, switchOn?: boolean,
    culture: string
  }) => {
    args.culture = this.tsvc.translationLanguage.value;

    args.tableFilters = [
      {
        property: "saleEventId",
        filterValue: this.saleEvent?.saleEventId ?? Guid.empty()
      },
      {
        property: "exportDataType",
        filterValue: "all"
      }
    ];


    return this.admSvc.getSaleEventBookedUsersTable(args.culture, args);
  }

  ngOnDestroy(): void {
    this.sub.next();
    this.sub.complete();
    this.dashSvc.clearPageHeader();
  }

  ngOnInit() {
    this.seSvc.currentSaleEvent$.pipe(takeUntil(this.sub)).subscribe((se: PRPVSaleEventFullExtended | undefined) => {
      this.saleEvent = se;

      this.showTable = true;
    });

    this.route.queryParams.subscribe(async params => {
      this.dashSvc.dashBackButtons?.push(
        new dashboardButton(
          "back-button",
          this.tranPipe.transform("dashboard_private_general_iofo_back_button", "Torna alla lista", []),
          () => { this.router.navigate([this.tsvc.currentLanguage.value, 'dashboard', 'private-sales', 'private-sale-list']) },
          undefined,
          "arrow-left",
          undefined,
          "left"
        )
      );

      this.dashSvc.dashMainItems.push(
        new dashboardButton(
          "book-user",
          this.tranPipe.transform('book_user_button', 'Aggiungi Prenotazione', []),
          async () => { await this.bookUserSlot() })
      );

      await this.refreshSale(params['saleEvent']);
    });
  }

  private async refreshSale(seId: string) {
    await this.dashSvc.refreshSaleEvent(seId, "private-sale-users", 'Riepilogo utenti');
  }

  async showMoreInfo(el: PRPVSaleEventSlotsBookingPurpleTableResponse, event: boolean): Promise<void> {
    this.isLoading = true;

    this.listOfData!.map(m => m.expanded = false);
    el.expanded = event
    this.userDetailAmount = undefined;

    if (event == true) {

      await this.apiProxySvc.makeRequestErrorMessage<UserBookingDetailAmount>(() => this.admSvc.getSaleEventSlotBookingUserDetail(this.tsvc.currentLanguage.value, {
        saleEventId: this.saleEvent!.saleEventId,
        slotEndDate: el.item.slotEndDate,
        slotStartDate: el.item.slotStartDate,
        userId: el.item.userId
      }), false, "internal-loader", 500, undefined, undefined, (res: PurpleApiMakeRequestResponse<UserBookingDetailAmount>) => {
        this.userDetailAmount = res.data;
      })
    }

    this.isLoading = false;
  }

  bookUserSlot(userId?: string | undefined) {
    this.modal.create<BookSlotUserComponent, string | undefined>({
      nzContent: BookSlotUserComponent,
      nzTitle: this.tranPipe.transform('ebook_user_slot_modal_title', 'Aggiungi nuova prenotazione', []),
      nzWidth: '1000px',
      nzClassName: 'purple-modal',
      nzData: userId ?? undefined,
      nzFooter: null,
      nzMaskClosable: false,
    }).afterClose.subscribe((refresh: boolean) => {
      if (refresh) {
        this.refreshTableRowsNumber++;
      }
    });
  }


  unbook(book: PRPVSaleEventSlotsBooking) {
    this.modal.create(
      {
        nzTitle: this.tranPipe.transform("modal_unbook_slot_title", "Vuoi davvero cancellare la prenotazione??"),
        nzContent: this.tranPipe.transform("modal_unbook_slot_subtitle", "Sei sicuro di voler cancellare la prenotazione <b>{0} - {1}</b> dell\'utente <b>{2}</b>",
          [this.datePipe.transform(book.slotStartDate, 'shortTime') ?? "",
          this.datePipe.transform(book.slotEndDate, 'shortTime') ?? "",
          book.firstName + " " + book.lastName]),
        nzWidth: '600px',
        nzClassName: 'purple-simple-modal',
        nzCentered: false,
        nzClosable: false,
        nzMaskClosable: false,
        nzOkText: this.tranPipe.transform("modal_unbook_slot_delete_button", "Elimina"),
        nzOnOk: async () => {
          await this.apiProxySvc.makeRequestErrorMessage<boolean>(() => this.admSvc.unBookSlotAdmin(this.tsvc.translationLanguage.value, {
            saleEventId: this.saleEvent!.saleEventId!,
            bookingClientHost: window.location.hostname,
            userId: book.userId
          }), false, "internal-loader", 500, undefined, undefined, (res: PurpleApiMakeRequestResponse<boolean>) => {
            this.mesSvc.success(this.tranPipe.transform("unbook_user_slot_success_message", "La prenotazione è stata cancellata con successo"), {
              nzDuration: environment.MESSAGE_DURATION
            });

            this.refreshTableRowsNumber++;
          })
        }
      }
    )
  }


  isInPast(date: string){
    return isBefore(new Date(Date.parse(date)), new Date())
  }
}
