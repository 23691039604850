import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthenticationGuard } from 'src/core-modules/authentication/authentication.guard';
import { DashboardLayout2Component } from './dashboard-layout-2/dashboard-layout-2.component';


const routes: Routes = [
  {
    path: '',
    canActivate: [AuthenticationGuard],
    component: DashboardLayout2Component,
    children: [
      {
        path: '',
        redirectTo: 'private-sales',
        pathMatch: "full"
      },
      {
        path: 'private-sales',
        canActivate: [],
        loadChildren: () => import('./private-sales/general/private-sales.module').then(m => m.PrivateSalesModule),
        data: { menu: "private-sales", submenu: "private-sale-list" }
      },
      {
        path: 'private-sale-detail',
        canActivate: [],
        loadChildren: () => import('./private-sales/detail/private-sale-detail.module').then(m => m.PrivateSaleDetailModule),
        data: { menu: "private-sale-detail", submenu: "general-informations" }
      },
      {
        path: 'administration',
        canActivate: [],
        loadChildren: () => import('./administration/administration.module').then(m => m.AdministrationModule),
        data: { menu: "administration", submenu: "customers" }
      },
      {
        path: 'notification',
        canActivate: [],
        loadChildren: () => import('./notifications/notification.module').then(m => m.NotificationModule),
        data: { menu: "notification", submenu: "smtp-servers" }
      },
      {
        path: 'general',
        canActivate: [],
        loadChildren: () => import('./general/general.module').then(m => m.GeneralModule),
        data: { menu: "general", submenu: "translation" }
      }
    ]
  }];

@NgModule({

  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DashboardRoutingModule { }
