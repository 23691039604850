<ng-container *ngIf="dashSvc.dashboardHeight != undefined && this.showTable == true">

  <purple-table #purpleTable [tableBody]="tableBody" [isBordered]="true" [switchOn]="false"
    [refreshFunction]="refreshFunction" (tableData)="listOfData = $event" [tableColumns]="tabColumns"
    [tableScroll]="{y:dashSvc.dashboardHeight}" [refreshTableRowsNumber]="refreshTableRowsNumber" [version]="2"
    [showCustomPagination]="true" tableSearchType="box" (filterBox)="dashSvc.setPurpleTablePopover($event)" 
    (onSearchBoxEnd)="dashSvc.closePurpleTablePopover()" [filterBoxStatus]="dashSvc.purpleTableBoxFilterStatus">

    <ng-template #tableBody>
      <ng-container *ngFor="let data of listOfData; let i = index">
        <tr class="{{i%2==0?'main-purple-table-tr-even': 'main-purple-table-tr-odd'}} ">
          <td>{{ data.item.sourceFirstName }}</td>
          <td>{{ data.item.sourceLastName }}</td>
          <td>{{ data.item.sourceEmail }}</td>
          <td>{{ data.item.sourceUserCompany }}</td>
          <td nzAlign="center">
            <container-element [ngSwitch]="data.item.sourceUserIsPending">
              <ng-container *ngSwitchCase="true">
                <p class="ps-m-0 ps-p-0 fw-b">{{ 'is_pending_yes' | translation: 'Si': []}}</p>
              </ng-container>
              <ng-container *ngSwitchCase="false">
                <p class="ps-m-0 ps-p-0 fw-b">{{ 'is_pending_no' | translation: 'No': []}}</p>

              </ng-container>
            </container-element>
          </td>
          <td nzAlign="center">
            <container-element [ngSwitch]="data.item.isInvited">
              <ng-container *ngSwitchCase="true">
                <p class="ps-m-0 ps-p-0 fw-b">{{ 'is_pending_yes' | translation: 'Si': []}}</p>
              </ng-container>
              <ng-container *ngSwitchCase="false">
                <p class="ps-m-0 ps-p-0 fw-b">{{ 'is_pending_no' | translation: 'No': []}}</p>

              </ng-container>
            </container-element>
          </td>
          <td>
            <container-element [ngSwitch]="data.item.isInvited">
              <ng-container *ngSwitchCase="true">
                <p class="ps-m-0 ps-p-0">{{data.item.invitedByUserFirstName}}
                  {{data.item.invitedByUserLastName}}</p>
              </ng-container>
              <ng-container *ngSwitchCase="false">
                <p class="ps-m-0 ps-p-0 fw-b">-</p>
              </ng-container>
            </container-element>
          </td>

          <td nzAlign="center">
            <button (click)="data.expanded = !data.expanded" [nzTooltipTitle]='
                          data.expanded?
                          ("hide_more_button_sale_event_user_table" | translation: "Nascondi dati aggiuntivi"):
                          ("show_more_button_sale_event_user_table" | translation: "Mostra più dati")
                          ' nzTooltipPlacement="top" class="ps-mr-3" nz-tooltip nz-button nzType="default"
              nzShape="circle"><i nz-icon [nzType]="data.expanded?'eye-invisible':'eye'"></i>
            </button>

            <button (click)="pendingUser(data.item!)" class="ps-mr-3"
              [nzTooltipTitle]='getPendingTooltipTitle(data.item!)' nzTooltipPlacement="top" nz-tooltip nz-button
              nzType="default" nzShape="circle">
              <i nz-icon [nzType]="data.item.sourceUserIsPending?'check':'stop'"></i>
            </button>

            <button (click)="reinviteUser(data.item!)" class="ps-mr-3"
              [nzTooltipTitle]="'reinvite_user_tooltip' | translation: 'Invita utente': []" nzTooltipPlacement="top"
              nz-tooltip nz-button nzType="default" nzShape="circle">
              <i nz-icon nzType="send"></i>
            </button>

            <button (click)="deleteUser(data.item!)" class="ps-mr-3"
              [nzTooltipTitle]="'delete_user_tooltip' | translation: 'Elimina utente': []" nzTooltipPlacement="top"
              nz-tooltip nz-button nzType="default" nzShape="circle">
              <i nz-icon nzType="delete"></i>
            </button>
          </td>
        </tr>
        <tr [nzExpand]="data.expanded">
          <div class="ps-d-flex ps-w-100 ps-justify-content-center">
            <div
              class="ps-d-flex ps-flex-wrap ps-align-items-start ps-w-100 ps-justify-content-between table-expanded-row-container">

              <nz-descriptions class="ps-w-100"
                [nzTitle]="'sale_event_user_table_expanded_row_title' | translation: 'Informazioni aggiuntive': []"
                nzBordered [nzColumn]="{ xxl: 2, xl: 2, lg: 2, md: 2, sm: 1, xs: 1 }">
                <nz-descriptions-item [nzSpan]="4"
                  [nzTitle]="'sale_event_user_table_invited' | translation: 'Invitati': []">
                  <container-element [ngSwitch]="data.item.hasInvite">
                    <ng-container *ngSwitchCase="true">
                      <div class="ps-w-100 ps-d-flex ps-flex-wrap">
                        <nz-tag *ngFor="let invited of data.item.invited" class="tag ps-mt-2"
                          [nzColor]="invited.isActionCompleted?'green':'red'"
                          [nzTooltipTitle]="'invited_role' | translation: 'Ruolo: {0}': [invited.targetRoleName]"
                          nzTooltipPlacement="top" nz-tooltip>
                          {{invited.targetFirstName}} {{invited.targetLastName}} ({{invited.targetEmail}})
                        </nz-tag>
                      </div>
                    </ng-container>
                    <ng-container *ngSwitchCase="false">
                      <p class="ps-m-0 ps-p-0">
                        {{ 'sale_event_user_table_no_invite' | translation: 'Ancora nessuna persona invitata': []}}</p>
                    </ng-container>
                  </container-element>
                </nz-descriptions-item>
                <nz-descriptions-item [nzTitle]="'sale_event_user_table_role' | translation: 'Ruolo': []">
                  {{data.item.sourceRoleName}}
                </nz-descriptions-item>
                <nz-descriptions-item [nzTitle]="'sale_event_user_table_booking' | translation: 'Prenotazione': []">
                  <container-element [ngSwitch]="(data.item.sourceUserBookedSlot??undefined) != undefined">
                    <ng-container *ngSwitchCase="true">
                      <p class="ps-m-0 ps-p-0">{{data.item.sourceUserBookedSlot | date: "dd MMM yyyy HH:mm"}}
                      </p>
                    </ng-container>
                    <ng-container *ngSwitchCase="false">
                      <p class="ps-m-0 ps-p-0 ">
                        {{ 'sale_event_user_table_no_book' | translation: 'Nessuno slot prenotato': []}}</p>
                    </ng-container>
                  </container-element>
                </nz-descriptions-item>
              </nz-descriptions>
            </div>
          </div>
        </tr>
      </ng-container>
    </ng-template>
  </purple-table>
</ng-container>