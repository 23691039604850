import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { PurpleLoaderService, APP_MEDIA_SERVICE, PURPLE_LIB_USER_TOKEN, APP_TRANSLATION_SERVICE, PurpleEditTextModule, PurpleGeneralDirectiveModule, PurpleStorageService, PurpleLoaderModule, PurpleErrorModule, PurpleLanguageIconPipe, PURPLE_LIB_MEDIA_PATH, PurpleApiErrorManagerModule, PurpleApiErrorInterceptor } from 'purple-lib';
import { AppMediaService } from 'src/core-modules/medias/media.service';

import { Configuration as API_BASE_PATH_CONFIGURATION } from 'src/core-modules/sdk/api';
import { BASE_PATH as API_BASE_PATH } from 'src/core-modules/sdk/api';

import { Configuration as API_MEDIA_PATH_CONFIGURATION } from 'src/core-modules/sdk/media';
import { BASE_PATH as API_MEDIA_PATH } from 'src/core-modules/sdk/media';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import fr from '@angular/common/locales/fr';
import it from '@angular/common/locales/it';
import * as enGb from '@angular/common/locales/en-GB';
import * as enUs from '@angular/common/locales/en';
import { NZ_I18N, en_US, it_IT } from 'ng-zorro-antd/i18n';
import { registerLocaleData } from '@angular/common';
import { AppTranslationService } from 'src/core-modules/localization/localization.service';
import { LocalizationModule } from 'src/core-modules/localization/localization.module';
import { IAuthenticatedUser } from 'src/core-modules/authentication/IAuthenticatedUser';
import { AuthenticationGuard } from 'src/core-modules/authentication/authentication.guard';
import { AuthenticationService } from 'src/core-modules/authentication/authentication.service';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthenticationModule } from './authentication/authentication.module';
import { ErrorModule } from './error/error.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NZ_ICONS, NzIconModule } from 'ng-zorro-antd/icon';
import { MailOutline, LockOutline, LogoutOutline, MenuFoldOutline, ArrowRightOutline, UnderlineOutline, EyeInvisibleOutline, RedoOutline, PushpinOutline, MinusOutline, PlusSquareOutline,
  UnorderedListOutline, UserOutline, ToolOutline, TeamOutline, PhoneOutline, CrownOutline, ManOutline, FormOutline, CloseCircleTwoTone, CheckCircleTwoTone, CreditCardOutline,
  HomeOutline, PlusOutline, SmileTwoTone, PoweroffOutline, NotificationOutline, TabletOutline, MessageOutline, CloudDownloadOutline, SyncOutline, WarningOutline, PictureOutline,
TranslationOutline, SearchOutline, ShoppingCartOutline, ShoppingOutline, DatabaseOutline, FieldNumberOutline, RiseOutline, Loading3QuartersOutline, CloudOutline, DollarOutline,
EllipsisOutline, ClearOutline, SaveOutline, ArrowLeftOutline, LikeTwoTone, DislikeTwoTone, CarOutline, EnvironmentOutline, MobileOutline, TableOutline, ArrowDownOutline, FallOutline,
ArrowUpOutline, FilePdfOutline, ShopOutline, WindowsOutline, CheckOutline, StopOutline, SendOutline, DownloadOutline, ReadOutline, BookOutline, MailFill, GlobalOutline, FolderOpenOutline, FolderOutline, 
FolderTwoTone, FolderOpenTwoTone, InboxOutline, FileTwoTone, BarcodeOutline, ShareAltOutline, ControlOutline } from '@ant-design/icons-angular/icons';
import { DashboardModule } from './dashboard/dashboard.module';
import { ShowMenuGuard } from 'src/core-modules/show-menu/showMenu.guard';
import { JWT_OPTIONS, JwtModule } from '@auth0/angular-jwt';

const icons = [MailOutline, LockOutline, LogoutOutline, MenuFoldOutline, ArrowRightOutline, UnderlineOutline, FormOutline, RedoOutline, PushpinOutline, SearchOutline,MinusOutline,ArrowLeftOutline,
  EyeInvisibleOutline, UnorderedListOutline, UserOutline, ToolOutline, TeamOutline, PhoneOutline, CrownOutline, ManOutline, CloseCircleTwoTone, CheckCircleTwoTone, PictureOutline, SaveOutline,
  HomeOutline, PlusOutline, SmileTwoTone, PoweroffOutline, NotificationOutline, TabletOutline, MessageOutline, CloudDownloadOutline, SyncOutline, WarningOutline, TranslationOutline, ClearOutline,
  ShoppingCartOutline, ShoppingOutline, DatabaseOutline, FieldNumberOutline, RiseOutline, Loading3QuartersOutline, CloudOutline, DollarOutline, CreditCardOutline, EllipsisOutline, PlusSquareOutline,
  LikeTwoTone,DislikeTwoTone, CarOutline, EnvironmentOutline, MobileOutline, TableOutline, ArrowDownOutline, FallOutline, ArrowUpOutline,FilePdfOutline, ShopOutline, WindowsOutline, CheckOutline, StopOutline, 
  SendOutline, DownloadOutline, ReadOutline, BookOutline, MailFill, WarningOutline, GlobalOutline, FolderOpenOutline, FolderOutline, FolderTwoTone, FolderOpenTwoTone, InboxOutline, FileTwoTone, BarcodeOutline,
  ShareAltOutline, ControlOutline, CrownOutline];

  registerLocaleData(it, "it-it");
  registerLocaleData(it, "it");
  registerLocaleData(fr, "fr-fr");
  registerLocaleData(fr, "fr");
  registerLocaleData(enUs.default, "en-us");
  registerLocaleData(enGb.default, "en-gb");

@NgModule({
  declarations: [	
    AppComponent
   ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    PurpleEditTextModule,
    PurpleGeneralDirectiveModule,
    BrowserAnimationsModule,
    NzIconModule,
        
    PurpleLoaderModule.forRoot(),
    PurpleErrorModule.forRoot(),
    LocalizationModule,
    AuthenticationModule,
    ErrorModule,
    DashboardModule.forRoot(),
    JwtModule.forRoot({}),
    
    PurpleApiErrorManagerModule
  ],
  providers: [
    ShowMenuGuard,
    AuthenticationGuard,
    AuthenticationService,
    PurpleLanguageIconPipe,
    PurpleLoaderService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: PurpleApiErrorInterceptor,
      multi:true
    },
    {
      provide:JWT_OPTIONS,
      useFactory: (storageSvc: PurpleStorageService) => storageSvc.get<IAuthenticatedUser>("CurrentUser")?.token ?? "",
      deps: [PurpleStorageService]
    },
    { provide: NZ_ICONS, useValue: icons },
    {
      provide: API_BASE_PATH_CONFIGURATION,
      useFactory: (storageSvc: PurpleStorageService) => new API_BASE_PATH_CONFIGURATION(
        {
          basePath: localStorage.getItem("BasePath") as string,
          accessToken: ()=> storageSvc.get<IAuthenticatedUser>("CurrentUser")?.token??undefined
        }
      ),
      deps: [PurpleStorageService],
      multi: false
    },
    {
      provide: API_MEDIA_PATH_CONFIGURATION,
      useFactory: (storageSvc: PurpleStorageService) => new API_MEDIA_PATH_CONFIGURATION(
        {
          basePath: localStorage.getItem("MediaPath") as string,
          accessToken: ()=> storageSvc.get<IAuthenticatedUser>("CurrentUser")?.token??undefined
        }
      ),
      deps: [PurpleStorageService],
      multi: false
    },
    {
      provide: LOCALE_ID,
      deps: [PurpleStorageService],
      useFactory: (storageSvc: PurpleStorageService) => storageSvc.get<string>("Language")  ?? localStorage.getItem("DefaultLanguage")
    },
    {
      provide: NZ_I18N,
      useFactory: (localId: string) => {
        switch (localId) { 
          case 'en-us':
            return en_US;
          case 'it-it':
            return it_IT;
            case 'it':
              return it_IT;
          default:
            return it_IT;
        }
      },
      deps: [LOCALE_ID] 
    }, 
    { 
      provide: APP_TRANSLATION_SERVICE, 
      deps: [AppTranslationService],
      useExisting: AppTranslationService
    },
    { 
      provide: APP_MEDIA_SERVICE, 
      deps: [AppMediaService],
      useExisting: AppMediaService
    },
    { provide: API_BASE_PATH, useValue: (localStorage.getItem("BasePath") as string) },
    { provide: PURPLE_LIB_MEDIA_PATH, 
      useFactory: () => (localStorage.getItem("MediaPath") as string)
    },
    { provide: API_MEDIA_PATH, useValue: (localStorage.getItem("MediaPath") as string)},
    {
      provide: PURPLE_LIB_USER_TOKEN,
      useFactory: (storageSvc: PurpleStorageService) =>  storageSvc.get<IAuthenticatedUser>("CurrentUser")?.token??undefined,
      deps: [PurpleStorageService],
      multi: false
    }
    
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
