<ng-container *ngIf="dashSvc.dashboardHeight != undefined">
  <purple-table #purpleTable [tableBody]="tableBody" [isBordered]="true" [switchOn]="false"
    [refreshFunction]="refreshFunction" (tableData)="listOfData = $event" [tableColumns]="tabColumns"
    [tableScroll]="{y:dashSvc.dashboardHeight}" [refreshTableRowsNumber]="refreshTableRowsNumber" [version]="2"
    [showCustomPagination]="true" tableSearchType="box" (filterBox)="dashSvc.setPurpleTablePopover($event)"
    (onSearchBoxEnd)="dashSvc.closePurpleTablePopover()" [filterBoxStatus]="dashSvc.purpleTableBoxFilterStatus">

    <ng-template #tableBody>
      <ng-container *ngFor="let data of listOfData; let i = index">
        <tr class="{{i%2==0?'main-purple-table-tr-even': 'main-purple-table-tr-odd'}} ">

          <td>{{ data.item.userFirstName }}</td>
          <td>{{ data.item.userLastName }}</td>
          <td>{{ data.item.userEmail }}</td>
          <td>{{ data.item.roleName }}</td>
          <td>
            <container-element [ngSwitch]="data.item.maxInvitationsNr == -1">
              <ng-container *ngSwitchCase="true">
                {{ 'illimited' | translation: 'Illimitati': []}}
              </ng-container>
              <ng-container *ngSwitchCase="false">
                {{ data.item.maxInvitationsNr }}
              </ng-container>
            </container-element>
          </td>
          <td>{{ data.item.referralReferralRoleName }}</td>
          <td nzAlign="center">
            <button (click)="copyReferral($event, data.item)"
              [nzTooltipTitle]='"user_role_referrals_table_edit_referral" | translation: "Copia codice"'
              nzTooltipPlacement="top" nz-tooltip class="ps-mr-3" nz-button nzType="default" nzShape="circle">
              <i nz-icon nzType="copy"></i>
            </button>
            <button (click)="editItem(data.item)"
              [nzTooltipTitle]='"user_role_referrals_table_edit_referral" | translation: "Modifica codice"'
              nzTooltipPlacement="top" nz-tooltip class="ps-mr-3" nz-button nzType="default" nzShape="circle">
              <i nz-icon nzType="edit"></i>
            </button>
            <button (click)="deleteReferral(data.item)"
              [nzTooltipTitle]='"user_role_referrals_table_delete_referral" | translation: "Elimina codice"'
              nzTooltipPlacement="top" nz-tooltip nz-button nzType="default" nzShape="circle">
              <i nz-icon nzType="delete"></i>
            </button>
          </td>
        </tr>
      </ng-container>
    </ng-template>
  </purple-table>
</ng-container>