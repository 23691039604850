import { AfterViewInit, Component, Inject, LOCALE_ID, OnDestroy, OnInit } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalService } from 'ng-zorro-antd/modal';
import { BackOfficeService, PurpleTableSearchGroupGroup, User, UsersService, UserTableItem, UserTableItemPurpleTableResponse } from 'src/core-modules/sdk/api';
import { AppTranslationService } from 'src/core-modules/localization/localization.service';
import { EditUserComponent } from '../modals/edit-user/edit-user.component';
import { PurpleTableColumn, PurpleTranslationPipe, PurpleTableSearchValue, PurpleTableSelectFilter, PurpleLoaderService, PurpleTableBoxColumn, PurpleApiMakeRequestResponse } from 'purple-lib';
import { environment } from 'src/environments/default/environment';
import { EditUserAddressesComponent } from '../modals/edit-user-addresses/edit-user-addresses.component';
import { DashboardLayout2Service } from '../../dashboard-layout-2/dashboard-layout-2.service';
import { PurpleApiProxyService } from 'purple-lib';

import { Subject, takeUntil } from 'rxjs';
import { getLocaleDateFormat, FormatWidth } from '@angular/common';
import { AnonymizeUserModalComponent } from '../modals/anonymize-user-modal/anonymize-user-modal.component';
import { BuyUserMembershipComponent } from '../modals/buy-user-membership/buy-user-membership.component';

@Component({
  selector: 'app-customers',
  templateUrl: './customers.component.html',
  styleUrls: ['./customers.component.scss']
})
export class CustomersComponent implements OnInit, OnDestroy, AfterViewInit {

  constructor(@Inject(LOCALE_ID) private locale: string, private mesSvc: NzMessageService, private tranPipe: PurpleTranslationPipe, private userSvc: UsersService, private loaderSvc: PurpleLoaderService,
    public dashSvc: DashboardLayout2Service, public backUserSvc: BackOfficeService, public tsvc: AppTranslationService, private modal: NzModalService,
    private apiProxySvc: PurpleApiProxyService) {
  }

  ngAfterViewInit(): void {
    const pageName = "customers";
    this.dashSvc.setPageHeader(
      this.tranPipe.transform(pageName + '_page_title', 'Anagrafica Clienti', []),
      this.tranPipe.transform(pageName + '_page_subtitle', 'Lista degli utenti registrati alla piattaforma', [])
    )
  }

  sub: Subject<void> = new Subject();

  bookingHost: string = localStorage.getItem("BookingHost")!;
  listOfData: Array<UserTableItemPurpleTableResponse> | undefined | null;
  refreshTableRowsNumber: number = 0;

  tabColumns: PurpleTableBoxColumn[] = [
    {
      name: this.tranPipe.transform("table_header_name", "Nome"),
      dbKey: "firstname",
      hasReorder: true,
      hasFilter: true,
      filterField: {
        fieldType: 'input-text',
        fieldControlName: 'firstname',
        fieldBootstrapColumn: 6,
        fieldName: this.tranPipe.transform('table_filter_firstname', 'Nome', []),
        fieldPlaceholder: this.tranPipe.transform('table_filter_firstname_placeholder', 'Nome del cliente', []),
        fieldValue: undefined,
        fieldIsRequired: false,
        fieldIsDisabled: false,
        fieldPaddingLeft: false,
        fieldPaddingRight: true
      },
      operatorType: "like"
    },
    {
      name: this.tranPipe.transform("user_table_header_lastname", "Cognome"),
      dbKey: "lastname",
      hasReorder: true,
      hasFilter: true,
      filterField: {
        fieldType: 'input-text',
        fieldControlName: 'lastname',
        fieldBootstrapColumn: 6,
        fieldName: this.tranPipe.transform('table_filter_lastname', 'Cognome', []),
        fieldPlaceholder: this.tranPipe.transform('table_filter_lastname_placeholder', 'Cognome del cliente', []),
        fieldValue: undefined,
        fieldIsRequired: false,
        fieldIsDisabled: false,
        fieldPaddingLeft: true,
        fieldPaddingRight: false
      },
      operatorType: "like"
    },
    {
      name: this.tranPipe.transform("user_table_header_email", "Email"),
      dbKey: "email",
      hasReorder: true,
      hasFilter: true,
      filterField: {
        fieldType: 'input-text',
        fieldControlName: 'email',
        fieldBootstrapColumn: 6,
        fieldName: this.tranPipe.transform('table_filter_email', 'Email', []),
        fieldPlaceholder: this.tranPipe.transform('table_filter_email_placeholder', 'Email del cliente', []),
        fieldValue: undefined,
        fieldIsRequired: false,
        fieldIsDisabled: false,
        fieldPaddingLeft: false,
        fieldPaddingRight: true
      },
      operatorType: 'like'
    },
    {
      name: this.tranPipe.transform("user_table_header_phone", "Telefono"),
      dbKey: "phone",
      hasReorder: false,
      hasFilter: true,
      filterField: {
        fieldType: 'input-text',
        fieldControlName: 'phone',
        fieldBootstrapColumn: 6,
        fieldName: this.tranPipe.transform('table_filter_phone', 'Telefono', []),
        fieldPlaceholder: this.tranPipe.transform('table_filter_phone_placeholder', 'Telefono del cliente', []),
        fieldValue: undefined,
        fieldIsRequired: false,
        fieldIsDisabled: false,
        fieldPaddingLeft: true,
        fieldPaddingRight: false
      },
      operatorType: "like"
    },
    {
      name: this.tranPipe.transform("user_table_header_membership", "Membership"),
      dbKey: "membership",
      hasReorder: false,
      hasFilter: false,
      filterField: {
        fieldType: 'input-text',
        fieldControlName: 'membership',
        fieldBootstrapColumn: 6,
        fieldName: this.tranPipe.transform('customer_table_membership_filter_title', 'Membership', []),
        fieldPlaceholder: this.tranPipe.transform('customer_table_membership_filter_placeholder', 'Membership', []),
        fieldValue: undefined,
        fieldIsRequired: true,
        fieldIsDisabled: false,
        fieldPaddingLeft: false,
        fieldPaddingRight: false
      },
      operatorType: "like"
    },
    {
      name: this.tranPipe.transform("user_table_header_membership_expire_date", "Scadenza Membership"),
      dbKey: "membershipExpire",
      hasReorder: false,
      hasFilter: false
    },
    /* {
      name: this.tranPipe.transform("user_table_header_registration_date", "Data Registrazione"),
      dbKey: "registrationDate",
      hasReorder: true,
      hasFilter: true,
      filterField: {
        fieldType: 'date-range',
        fieldControlName: 'registrationDate',
        fieldBootstrapColumn: 12,
        fieldName: this.tranPipe.transform('table_filter_registration_date', 'Data Registrazione', []),
        fieldPlaceholder: this.tranPipe.transform('table_filter_registration_date_placeholder', 'Data Registrazione cliente', []),
        fieldValue: undefined,
        fieldIsRequired: false,
        fieldIsDisabled: false,
        fieldPaddingLeft: false,
        fieldPaddingRight: false,
        fieldOptions: [
          { id: 'dateFormat', value: getLocaleDateFormat(this.locale, FormatWidth.Medium) },
          { id: 'disabledDate', value: undefined }
        ]
      },
      operatorType: "date between"
    }, */
    {
      name: this.tranPipe.transform("table_header_actions", "Azioni"),
      hasReorder: false,
      hasFilter: false,
      fixedWidth: "190px"
    },
    {
      name: this.tranPipe.transform("user_table_header_gender", "Sesso"),
      dbKey: "genderId",
      hasReorder: true,
      hasFilter: true,
      filterField: {
        fieldType: 'select-local',
        fieldControlName: 'genderId',
        fieldBootstrapColumn: 6,
        fieldName: this.tranPipe.transform('table_filter_gender_id', 'Sesso', []),
        fieldPlaceholder: this.tranPipe.transform('table_filter_gender_id_placeholder', 'Sesso cliente', []),
        fieldValue: undefined,
        fieldIsRequired: false,
        fieldIsDisabled: false,
        fieldPaddingLeft: false,
        fieldPaddingRight: true,
        fieldOptions: [
          { id: 'isMultiple', value: false },
          { id: 'showArrow', value: true },
          { id: 'enableSearch', value: false },
          {
            id: 'options', value: [
              {
                label: 'Uomo',
                value: 'M'
              },
              {
                label: 'Donna',
                value: 'F'
              },
              {
                label: 'Altro',
                value: 'O'
              }
            ]
          }
        ]
      },
      operatorType: "=",
      hiddenColumn: true
    },
    {
      name: this.tranPipe.transform("user_table_header_email_verified", "Email verificata"),
      dbKey: "emailVerified",
      hasReorder: true,
      hasFilter: true,
      filterField: {
        fieldType: 'select-local',
        fieldControlName: 'emailVerified',
        fieldBootstrapColumn: 6,
        fieldName: this.tranPipe.transform('table_filter_email_verified', 'Email verificata', []),
        fieldPlaceholder: this.tranPipe.transform('table_filter_email_verified_placeholder', 'Email verifiata cliente', []),
        fieldValue: undefined,
        fieldIsRequired: false,
        fieldIsDisabled: false,
        fieldPaddingLeft: true,
        fieldPaddingRight: false,
        fieldOptions: [
          { id: 'isMultiple', value: false },
          { id: 'showArrow', value: true },
          { id: 'enableSearch', value: false },
          {
            id: 'options', value: [
              {
                label: 'Si',
                value: 1
              },
              {
                label: 'No',
                value: 0
              }
            ]
          }
        ]
      },
      operatorType: "=",
      hiddenColumn: true
    },
    {
      name: this.tranPipe.transform("user_table_header_birthdate", "Data di nascita"),
      dbKey: "birthDate",
      hasReorder: true,
      hasFilter: true,
      filterField: {
        fieldType: 'date-range',
        fieldControlName: 'birthDate',
        fieldBootstrapColumn: 12,
        fieldName: this.tranPipe.transform('table_filter_birthdate', 'Data di nascita', []),
        fieldPlaceholder: this.tranPipe.transform('table_filter_birthdate_placeholder', 'Data di nascita cliente', []),
        fieldValue: undefined,
        fieldIsRequired: false,
        fieldIsDisabled: false,
        fieldPaddingLeft: false,
        fieldPaddingRight: false,
        fieldOptions: [
          { id: 'dateFormat', value: getLocaleDateFormat(this.locale, FormatWidth.Medium) },
          { id: 'disabledDate', value: undefined }
        ]
      },
      operatorType: "date between",
      hiddenColumn: true
    },
    {
      name: this.tranPipe.transform("user_table_header_company", "Azienda"),
      dbKey: "userCompany",
      hasReorder: false,
      hasFilter: true,
      filterField: {
        fieldType: 'input-text',
        fieldControlName: 'userCompany',
        fieldBootstrapColumn: 6,
        fieldName: this.tranPipe.transform('table_filter_company', 'Azienda', []),
        fieldPlaceholder: this.tranPipe.transform('table_filter_company_placeholder', 'Azienda del cliente', []),
        fieldValue: undefined,
        fieldIsRequired: false,
        fieldIsDisabled: false,
        fieldPaddingLeft: false,
        fieldPaddingRight: true
      },
      operatorType: "like",
      hiddenColumn: true
    },
    {
      name: this.tranPipe.transform("user_table_header_company_role", "Ruolo in azienda"),
      dbKey: "userCompanyJobRole",
      hasReorder: false,
      hasFilter: true,
      filterField: {
        fieldType: 'input-text',
        fieldControlName: 'userCompanyJobRole',
        fieldBootstrapColumn: 6,
        fieldName: this.tranPipe.transform('table_filter_company_role', 'Ruolo in azienda', []),
        fieldPlaceholder: this.tranPipe.transform('table_filter_company_role_placeholder', 'Ruolo in azienda del cliente', []),
        fieldValue: undefined,
        fieldIsRequired: false,
        fieldIsDisabled: false,
        fieldPaddingLeft: true,
        fieldPaddingRight: false
      },
      operatorType: "like",
      hiddenColumn: true
    },
    {
      name: this.tranPipe.transform("user_table_header_is_anonymous", "Anonimizzato"),
      dbKey: "isAnonymous",
      hasReorder: true,
      hasFilter: true,
      filterField: {
        fieldType: 'select-local',
        fieldControlName: 'isAnonymous',
        fieldBootstrapColumn: 6,
        fieldName: this.tranPipe.transform('table_filter_is_anonymous', 'Anonimizzato', []),
        fieldPlaceholder: this.tranPipe.transform('table_filter_is_anonymous_placeholder', 'Anonimizzato', []),
        fieldValue: undefined,
        fieldIsRequired: false,
        fieldIsDisabled: false,
        fieldPaddingLeft: false,
        fieldPaddingRight: true,
        fieldOptions: [
          { id: 'isMultiple', value: false },
          { id: 'showArrow', value: true },
          { id: 'enableSearch', value: false },
          {
            id: 'options', value: [
              {
                label: 'Si',
                value: 1
              },
              {
                label: 'No',
                value: 0
              }
            ]
          }
        ]
      },
      operatorType: "=",
      hiddenColumn: true
    },
    {
      name: this.tranPipe.transform("user_table_header_external_id", "Identificativo Esterno"),
      dbKey: "externalId",
      hasReorder: false,
      hasFilter: true,
      filterField: {
        fieldType: 'input-text',
        fieldControlName: 'externalId',
        fieldBootstrapColumn: 6,
        fieldName: this.tranPipe.transform('table_filter_external_id', 'Identificativo esterno', []),
        fieldPlaceholder: this.tranPipe.transform('table_filter_external_id_placeholder', 'Identificativo esterno del cliente', []),
        fieldValue: undefined,
        fieldIsRequired: false,
        fieldIsDisabled: false,
        fieldPaddingLeft: true,
        fieldPaddingRight: false
      },
      operatorType: "like",
      hiddenColumn: true
    }
  ]


  refreshFunction = (args: {
    pageNumber: number, pageSize: number, orderColumnName?: string | null, orderType?: string | null,
    tableSearchValues?: Array<PurpleTableSearchGroupGroup> | null, tableFilters?: Array<PurpleTableSelectFilter> | null, switchOn?: boolean,
    culture: string
  }) => {
    args.tableFilters = [{
      property: "type",
      filterValue: "customers"
    }];
    args.switchOn = false;

    return this.backUserSvc.getUsersTable(args.culture, args)
  }

  async ngOnInit() {
    this.loaderSvc.addRequest("internal-loader")
    this.dashSvc.dashMainItems?.push(
      {
        id: "ut",
        type: "button",
        buttonStyle: 'default',
        buttonText: this.tranPipe.transform("table_add_new_item_button", "Aggiungi {0}", ["Utente"]),
        buttonFunction: () => { this.editUser(undefined) }
      })
    this.loaderSvc.removeRequest("internal-loader", 350)
  }

  ngOnDestroy(): void {
    this.dashSvc.clearPageHeader();
    this.sub.next();
    this.sub.complete();
  }

  editUser(user: UserTableItem | undefined) {
    this.modal.create<EditUserComponent, UserTableItem | undefined>({
      nzContent: EditUserComponent,
      nzData: user,
      nzTitle: user == undefined ? this.tranPipe.transform("table_item_edit_modal_title_new", "Aggiungi {0}", ["utente"]) : this.tranPipe.transform("table_item_edit_modal_title_update", "Modifica {0}", ["utente"]),
      nzWidth: '1000px',
      nzClassName: 'purple-modal',
      nzFooter: null,
      nzMaskClosable: false,
    }).afterClose.pipe(takeUntil(this.sub)).pipe(takeUntil(this.sub)).subscribe(async (user: User | undefined) => {
      if (user != undefined) {
        this.refreshTableRowsNumber++;
      }
    });
  }

  resetPassword(user: UserTableItem) {
    this.modal.create({
      nzTitle: this.tranPipe.transform("reset_user_password_admin_title", "Reset password {0}", [user.firstName + " " + user.lastName]),
      nzContent: this.tranPipe.transform("reset_user_password_admin_subtitle", "La richiesta di reset password per l'utente <b>{0}</b> verrà inviata al seguente indirizzo email: <b>{1}</b>", [user.firstName + " " + user.lastName, user.email!]),
      nzWidth: '600px',
      nzClassName: 'purple-simple-modal',
      nzOkText: this.tranPipe.transform('reset_user_password_admin_button', 'Resetta', []),
      nzOnOk: async () => {
        await this.apiProxySvc.makeRequestErrorMessage<any>(() => this.userSvc.requestResetPassword(this.tsvc.currentLanguage.value,
          {
            email: user.email,
            clientHost: this.bookingHost //TODO TOGLIERLO CON LE API NUOVE
          }), false, "internal-loader", 500, undefined, environment.MESSAGE_DURATION, () => {
            this.mesSvc.success(this.tranPipe.transform("reset_user_password_admin_ok_response", "Email di richiesta reset password inviata correttamente"), {
              nzDuration: environment.MESSAGE_DURATION
            });
            this.modal.closeAll();
          });
      },
    });
  }

  editUserAddresses(user: UserTableItem) {
    this.modal.create<EditUserAddressesComponent, UserTableItem>({
      nzTitle: this.tranPipe.transform("edit_user_addresses_admin_title", "Gestione Indirizzi: {0}", [user.firstName + " " + user.lastName]),
      nzContent: EditUserAddressesComponent,
      nzData: user,
      nzWidth: '1200px',
      nzClassName: 'purple-modal edit-user-address-modal',
      nzFooter: null
    }).afterClose.pipe(takeUntil(this.sub)).subscribe(async (refresh: boolean | undefined) => {
      if (refresh ?? false) {
        this.refreshTableRowsNumber++;
      }
    });
  }

  getPendingTooltipTitle(user: UserTableItem) {
    if (user.isPending) {
      return this.tranPipe.transform('edit_pending_button_active', 'Attiva utente', [])
    } else {
      return this.tranPipe.transform('edit_pending_button_not_active', 'Disabilita utente', [])
    }
  }

  async pendingUser(user: UserTableItem) {
    this.modal.create({
      nzTitle: user.isPending ? this.tranPipe.transform('edit_pending_user_active_title', 'Attivare utente <b>{0}</b>', [user.firstName + " " + user.lastName]) :
        this.tranPipe.transform('edit_pending_user_not_active_title', 'Disabilita utente <b>{0}</b>', [user.firstName + " " + user.lastName]),

      nzContent: user.isPending ? this.tranPipe.transform('edit_pending_user_active_subtitle', '<b>Togliere</b> dalla lista di attesa l\'utente <b>{0}</b>', [user.firstName + " " + user.lastName]) :
        this.tranPipe.transform('edit_pending_user_not_active_subtitle', '<b>Aggiungere</b> alla lista di attesa l\'utente <b>{0}</b>', [user.firstName + " " + user.lastName]),
      nzWidth: '600px',
      nzOkText: user.isPending ? this.tranPipe.transform('edit_pending_user_active_button', 'Attiva', []) : this.tranPipe.transform('edit_pending_user_not_active_button', 'Disattiva', []),
      nzClassName: 'purple-simple-modal',
      nzOnOk: async () => {
        await this.apiProxySvc.makeRequestErrorMessage<boolean>(() => this.backUserSvc.activeOrPendingUser(this.tsvc.currentLanguage.value, {
          userId: user.userId,
          bookingHost: this.bookingHost
        }), false, "internal-loader", 500, undefined, undefined, (_: PurpleApiMakeRequestResponse<boolean>) => {
          this.mesSvc.success(this.tranPipe.transform('edit_user_pending_ok', 'Modifiche salvate con successo', []), {
            nzDuration: environment.MESSAGE_DURATION
          });
          this.refreshTableRowsNumber++;
        })
      }
    })
  }

  async verifyUserEmail(user: UserTableItem) {
    this.modal.create({
      nzTitle: this.tranPipe.transform('verifiy_user_email', 'Verifica email utente <b>{0}</b>', [user.firstName + " " + user.lastName]),
      nzContent: this.tranPipe.transform('verifiy_user_email_subtitle', 'La mail dell\'utente <b>{0}</b> verrà <b>verificato</b>', [user.firstName + " " + user.lastName]),
      nzWidth: '600px',
      nzOkText: this.tranPipe.transform('verifiy_user_email_button', 'Verifica', []),
      nzClassName: 'purple-simple-modal',
      nzOnOk: async () => {
        await this.apiProxySvc.makeRequestErrorMessage<boolean>(() => this.backUserSvc.adminVerifyUserEmail(this.tsvc.currentLanguage.value, {
          userId: user.userId
        }), false, "internal-loader", 500, undefined, undefined, (_: PurpleApiMakeRequestResponse<boolean>) => {
          this.mesSvc.success(this.tranPipe.transform('edit_user_pending_ok', 'Modifiche salvate con successo', []), {
            nzDuration: environment.MESSAGE_DURATION
          });
          this.refreshTableRowsNumber++;
        })
      }
    })
  }

  async verifyUserPhone(user: UserTableItem) {
    this.modal.create({
      nzTitle: this.tranPipe.transform('verifiy_user_phone', 'Verifica telefono utente <b>{0}</b>', [user.firstName + " " + user.lastName]),
      nzContent: this.tranPipe.transform('verifiy_user_phone_subtitle', 'La telefono dell\'utente <b>{0}</b> verrà <b>verificato</b>', [user.firstName + " " + user.lastName]),
      nzWidth: '600px',
      nzOkText: this.tranPipe.transform('verifiy_user_phone_button', 'Verifica', []),
      nzClassName: 'purple-simple-modal',
      nzOnOk: async () => {
        await this.apiProxySvc.makeRequestErrorMessage<boolean>(() => this.backUserSvc.adminVerifyUserPhone(this.tsvc.currentLanguage.value, {
          userId: user.userId
        }), false, "internal-loader", 500, undefined, undefined, (_: PurpleApiMakeRequestResponse<boolean>) => {
          this.mesSvc.success(this.tranPipe.transform('edit_user_pending_ok', 'Modifiche salvate con successo', []), {
            nzDuration: environment.MESSAGE_DURATION
          });
          this.refreshTableRowsNumber++;
        })
      }
    })
  }

  async anonymizeUser(user: UserTableItem) {
    if (!user.isAnonymous) {
      this.modal.create<AnonymizeUserModalComponent, UserTableItem>({
        nzTitle: this.tranPipe.transform("anonymize_user_title", "Anonimizzazione utente: {0}", [user.firstName + " " + user.lastName]),
        nzContent: AnonymizeUserModalComponent,
        nzData: user,
        nzWidth: '600px',
        nzClassName: 'purple-modal',
        nzFooter: null
      }).afterClose.subscribe(async (refresh: boolean | undefined) => {
        if (refresh ?? false) {
          this.refreshTableRowsNumber++;
        }
      });
    }
    else {
      this.mesSvc.warning(this.tranPipe.transform('anonymize_user_already_anonymouse', 'Utente già anonimizzato', []),
        {
          nzDuration: environment.MESSAGE_DURATION
        });
    }
  }

  buyUserMembership(user: UserTableItem | undefined) {
    this.modal.create<BuyUserMembershipComponent, UserTableItem>({
      nzTitle: this.tranPipe.transform("buy_user_membership_admin_title", "Gestione Membership", []),
      nzContent: BuyUserMembershipComponent,
      nzData: user,
      nzWidth: '900px',
      nzClassName: 'purple-modal',
      nzFooter: null
    }).afterClose.pipe(takeUntil(this.sub)).subscribe(async (refresh: boolean | undefined) => {
      if (refresh ?? false) {
        this.refreshTableRowsNumber++;
      }
    });
  }

  /* editUserAddresses(user: UserTableItem) {
    this.modal.create({
      nzTitle: this.tranPipe.transform("edit_user_addresses_admin_title", "Gestione Indirizzi: {0}", [user.firstName + " " + user.lastName]),
      nzContent: EditUserAddressesComponent,
      nzComponentParams: {
        user: user
      },
      nzWidth: '1200px',
      nzClassName: 'purple-modal',
      nzFooter: null
    }).afterClose.subscribe(async (refresh: boolean | undefined) => {
      if (refresh ?? false) {
        await this.getUsers(this.orderColumnName, this.orderType, this.activeFilters)
      }
    });;
  } */
}
