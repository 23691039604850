import { Component, Inject, LOCALE_ID, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { PurpleTranslationPipe, PurpleApiProxyService, dashboardButton, PurpleApiMakeRequestResponse, purpleFormFieldsGroup, PurpleTableColumn, PurpleTableSearchValue, PurpleTableSelectFilter, Guid, PurpleSelectFilter } from 'purple-lib';
import { Observable, Subject, takeUntil } from 'rxjs';
import { DashboardLayout2Service } from 'src/app/dashboard/dashboard-layout-2/dashboard-layout-2.service';
import { AppTranslationService } from 'src/core-modules/localization/localization.service';
import { BackOfficeService, PRPVSaleEventFullExtended, PurpleTableSearchGroupGroup, Role, SaleEventActiveRole, SaleEventUserExtendedTableItemPurpleTableResponse } from 'src/core-modules/sdk/api';
import { PrivateSaleService } from '../../private-sale-service.service';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NzMessageService } from 'ng-zorro-antd/message';
import { RoleTab } from 'src/core-modules/sdk/api';
import { FormatWidth, getLocaleDateFormat } from '@angular/common';
import { environment } from 'src/environments/default/environment';
import { AddTabRoleModalComponent } from '../modals/add-tab-role-modal/add-tab-role-modal.component';
import { NzTabsCanDeactivateFn } from 'ng-zorro-antd/tabs';
import { AddUserRoleModalComponent } from '../modals/add-user-role-modal/add-user-role-modal.component';
import { error } from '@ant-design/icons-angular';

@Component({
  selector: 'app-private-sale-roles',
  templateUrl: './private-sale-roles.component.html',
  styleUrls: ['./private-sale-roles.component.scss']
})
export class PrivateSaleRolesComponent implements OnInit, OnDestroy {

  constructor(@Inject(LOCALE_ID) private locale: string, private tranPipe: PurpleTranslationPipe, public dashSvc: DashboardLayout2Service, private route: ActivatedRoute, private modal: NzModalService,
    private admSvc: BackOfficeService, private apiProxySvc: PurpleApiProxyService, public tsvc: AppTranslationService, private seSvc: PrivateSaleService, private notification: NzNotificationService,
    private router: Router, private fb: FormBuilder, private mesSvc: NzMessageService) { }

  saleEvent: PRPVSaleEventFullExtended | undefined;
  saleEventId!: string;
  sub: Subject<void> = new Subject();
  tabs: RoleTab[] = [];
  currentActiveRole: SaleEventActiveRole | undefined;
  selectedIndex: number = 0;
  settingSelectedIndex: number = 0;
  isLoading: boolean = false;
  //canUpdate: boolean = false;
  tabHeight: string = "";
  bookingHost: string = localStorage.getItem("BookingHost")!;


  showTab: boolean = false;
  alreadyPipeForm: boolean = false;

  originalFormValues: any;
  validateForm: FormGroup = this.fb.group({});
  salePurpleFormFieldGroups: editSaleRole[] = [];
  //disableForm: boolean = true;
  roleWhiteList: string[] | undefined;
  dateFormat = getLocaleDateFormat(this.locale, FormatWidth.Medium);

  listOfData: Array<SaleEventUserExtendedTableItemPurpleTableResponse> | undefined | null;
  refreshTableRowsNumber: number = 0;
  tabColumns: PurpleTableColumn[] = [
    {
      name: this.tranPipe.transform("table_header_name", "Nome"),
      dbKey: "sourceFirstName",
      hasReorder: true,
      hasFilter: true,
      filterType: 'search'
    },
    {
      name: this.tranPipe.transform("user_table_header_lastname", "Cognome"),
      dbKey: "sourceLastName",
      hasReorder: true,
      hasFilter: true,
      filterType: 'search'
    },
    {
      name: this.tranPipe.transform("user_table_header_email", "Email"),
      dbKey: "sourceEmail",
      hasReorder: true,
      hasFilter: true,
      filterType: 'search'
    },
    {
      name: this.tranPipe.transform("user_table_header_user_company", "Azienda"),
      dbKey: "sourceUserCompany",
      hasReorder: true,
      hasFilter: true,
      filterType: 'search'
    },
    {
      name: this.tranPipe.transform("user_table_header_blocked", "Bloccato"),
      dbKey: "sourceUserIsPending",
      hasReorder: true,
      hasFilter: true,
      filterType: 'select',
      fixedWidth: "130px",
      filters: [
        {
          text: this.tranPipe.transform('filter_yes', 'Si', []),
          value: true
        },
        {
          text: this.tranPipe.transform('filter_no', 'No', []),
          value: false
        }
      ]
    },
    {
      name: this.tranPipe.transform("user_table_header_invited", "Invitato"),
      dbKey: "isInvited",
      hasReorder: true,
      hasFilter: true,
      filterType: 'select',
      fixedWidth: "130px",
      filters: [
        {
          text: this.tranPipe.transform('filter_yes', 'Si', []),
          value: true
        },
        {
          text: this.tranPipe.transform('filter_no', 'No', []),
          value: false
        }
      ]
    },
    {
      name: this.tranPipe.transform("user_table_header_invited_by", "Invitato da"),
      dbKey: "invitedByUserFirstName",
      hasReorder: true,
      hasFilter: false
    },
    {
      name: this.tranPipe.transform("table_header_actions", "Azioni"),
      hasReorder: false,
      hasFilter: false,
      fixedWidth: "180px"
    }
  ]

  ngOnInit() {

    /* this.validateForm.valueChanges.pipe(
      takeUntil(this.sub),
      switchMap(async () => {
        if (this.canUpdate) {
          if (this.validateForm.valid) {
            this.isLoading = true;
          } else {
            this.canUpdate = false;
            for (const i in this.validateForm.controls) {
              this.validateForm.controls[i].markAsDirty();
              this.validateForm.controls[i].updateValueAndValidity();
            }
          }
        }
      }),
      debounceTime(800),
      switchMap(async () => forkJoin({
        referral: this.saveSettingReferralChanges(),
        limit: this.saveLimitSettingChanges()
      })
      )).subscribe(async () => {
        this.canUpdate = true;
        this.isLoading = false;
      }); */



    this.seSvc.currentSaleEvent$.pipe(takeUntil(this.sub)).subscribe((se: PRPVSaleEventFullExtended | undefined) => {
      this.saleEvent = se;

      if (se != undefined) {
        this.onSaleChange();
      }
    });

    this.dashSvc.dashboardHeightNumber.pipe(takeUntil(this.sub)).subscribe((se: number | undefined) => {
      if (se != undefined) {
        this.tabHeight = se + 117 + "px";
      }
    });

    this.route.queryParams.subscribe(async params => {
      this.saleEventId = params['saleEvent'];

      this.dashSvc.dashMainItems?.push(
        new dashboardButton(
          "back-button",
          this.tranPipe.transform("dashboard_private_general_iofo_back_button", "Torna alla lista", []),
          () => { this.router.navigate([this.tsvc.currentLanguage.value, 'dashboard', 'private-sales', 'private-sale-list']) },
          undefined,
          "arrow-left",
          undefined,
          "left"
        )
      );
      await this.refreshSale();
    });
  }

  ngOnDestroy(): void {
    this.sub.next();
    this.sub.complete();
    this.dashSvc.clearPageHeader();
  }

  refreshFunction = (args: {
    pageNumber: number, pageSize: number, orderColumnName?: string | null, orderType?: string | null,
    tableSearchValues?: Array<PurpleTableSearchGroupGroup> | null, tableFilters?: Array<PurpleTableSelectFilter> | null, switchOn?: boolean,
    culture: string
  }) => {
    args.culture = this.tsvc.translationLanguage.value;

    if ((this.currentActiveRole?.baseInfo.roleId ?? undefined) != undefined) {
      args.tableFilters = [{
        property: "sourceRoleId",
        filterValue: this.currentActiveRole?.baseInfo.roleId!
      },
      {
        property: "saleEventId",
        filterValue: this.saleEvent?.saleEventId ?? Guid.empty()
      }

      ];
    }

    return this.admSvc.getSaleEventUsersExtendedTable(args.culture, args);
  }

  private async refreshSale() {
    await this.dashSvc.refreshSaleEvent(this.saleEventId, "private-sale-roles", 'Ruoli abilitati alla sale');
  }

  getSpinnerStatus(sectionId: string): boolean {
    return this.salePurpleFormFieldGroups.find(f => f.formSectionId.strEq(sectionId))?.isSpinnerVisible ?? false;
  }

  async onSaleChange() {
    await this.apiProxySvc.makeRequestErrorPage<RoleTab[]>(() => this.admSvc.getSaleEventActiveRoles(this.tsvc.currentLanguage.value, {
      saleEventId: this.saleEvent!.saleEventId
    }), true, "internal-loader", 500, "internal-error", async () => { await this.onSaleChange() }, async (res: PurpleApiMakeRequestResponse<RoleTab[]>) => {
      this.tabs = res.data!;
      console.log("TABS: ", this.tabs);

      if (this.tabs.length > 0) {
        console.log("ON SALE CHANGE");
        this.selectedIndex = 0;
        //await this.onRoleChange(this.tabs[0].roleId, true);
      } else {
        this.modal.closeAll();
        this.addActiveSaleRole();
      }

      this.showTab = true; 
    })
  }

  canDeactivateSettingTab: NzTabsCanDeactivateFn = () => {
    if(this.settingSelectedIndex == 0){
      return this.checkCanChangeTab();
    }else{
      return true;
    }    
  };

  changeSettingTab(index: number) {
    this.dashSvc.dashMainItems = this.dashSvc.dashMainItems.filter(f => f.id.strEq("back-button"));
    if(index == 2){
      this.dashSvc.dashMainItems?.push(
        new dashboardButton(
          "edit-user-role-button",
          this.tranPipe.transform("dashboard_private_sale_roles_add_user_role", "Assegna ruolo a utente", []),
          () => { this.editUserRole() }
        )
      );
    }
  }

  copyReferral(event: Event){
    try {
      event.stopPropagation();

      if((this.currentActiveRole?.baseInfo.referralId??undefined)==undefined){
        throw error("ReferralId is null")
      }

      navigator.clipboard.writeText("https://"+this.bookingHost+"/"+this.tsvc.currentLanguage.value+"/referral?id=" + this.currentActiveRole?.baseInfo.referralId);

      this.mesSvc.success(this.tranPipe.transform("copy_referral_ok", "Codice copiato negli appunti"), {
        nzDuration: environment.MESSAGE_DURATION
      });
    } catch (error) {
      this.mesSvc.error(this.tranPipe.transform("copy_referral_ko", "Errore! impossibile copiare il codice"), {
        nzDuration: environment.MESSAGE_DURATION
      });
    }
  }

  async onRoleChange(roleId: string, firstTime: boolean) {
    this.isLoading = true;
    await this.apiProxySvc.makeRequestErrorPage<SaleEventActiveRole>(() => this.admSvc.getSaleEventActiveRoleDetail(this.tsvc.currentLanguage.value, {
      saleEventId: this.saleEvent!.saleEventId,
      roleId: roleId
    }), false, "internal-loader", 500, "internal-error", async () => { await this.onRoleChange(roleId, false) }, (res: PurpleApiMakeRequestResponse<SaleEventActiveRole>) => {
      this.currentActiveRole = res.data!;
      console.log("CURRENT ROLE: ", this.currentActiveRole);
      this.roleWhiteList = this.currentActiveRole.baseInfo.roleWhiteListDomain?.split(",");

      this.salePurpleFormFieldGroups =
        [
          {
            formSectionId: "referral",
            formSectionName: this.tranPipe.transform('private_sale_role_edit_referral', 'Informazioni Invito (Referral)', []),
            canBeDisabled: true,
            isDisabled: !this.currentActiveRole.baseInfo.isReferralActive,
            showReferralLink: true,
            purpleFormGroups: [
              {
                fieldGroupNumber: 1,
                fielGroupBootstrapColumn: 12,
                fieldGroupPaddingLeft: false,
                fieldGroupPaddingRight: false,
                formFieldFormGroup: this.validateForm,
                formFields: [
                  {
                    fieldType: 'input-number',
                    fieldControlName: 'maxInviteNumber',
                    fieldBootstrapColumn: 6,
                    fieldName: this.tranPipe.transform('private_sale_role_referral_form_max_invite_number', 'Numero massimo inviti', []),
                    fieldPlaceholder: this.tranPipe.transform('private_sale_role_referral_form_max_invite_number_placeholder', 'Specifica il numero massimo di inviti', []),
                    fieldValue: this.currentActiveRole.baseInfo.maxInvitationsNr,
                    fieldIsRequired: this.currentActiveRole.baseInfo.isReferralActive,
                    fieldIsDisabled: !this.currentActiveRole.baseInfo.isReferralActive,
                    fieldPaddingLeft: false,
                    fieldPaddingRight: true,
                    fieldOptions: [{ id: 'minNumber', value: -1 }]
                  },
                  {
                    fieldType: 'input-number',
                    fieldControlName: 'maxLinkNumber',
                    fieldBootstrapColumn: 6,
                    fieldName: this.tranPipe.transform('private_sale_role_referral_form_max_link_number', 'Numero massimo link click', []),
                    fieldPlaceholder: this.tranPipe.transform('private_sale_role_referral_form_max_link_number_placeholder', 'Specifica il numero massimo di click sul link', []),
                    fieldValue: this.currentActiveRole.baseInfo.maxLinkRedeemNr,
                    fieldIsRequired: this.currentActiveRole.baseInfo.isReferralActive,
                    fieldIsDisabled: !this.currentActiveRole.baseInfo.isReferralActive,
                    fieldPaddingLeft: true,
                    fieldPaddingRight: false,
                    fieldOptions: [{ id: 'minNumber', value: -1 }]
                  },
                  {
                    fieldType: 'select-local',
                    fieldControlName: 'allowedDomains',
                    fieldBootstrapColumn: 12,
                    fieldName: this.tranPipe.transform('private_sale_role_referral_form_allowed_domains', 'Domini mail abilitati al redeem', []),
                    fieldPlaceholder: this.tranPipe.transform('private_sale_role_referral_form_allowed_domains_placeholder', 'Seleziona i domini mail abilitati al redeem', []),
                    fieldValue: (this.roleWhiteList ?? []).filter(f => !String.prototype.isNullOrEmpty(f)),
                    fieldIsRequired: false,
                    fieldIsDisabled: !this.currentActiveRole.baseInfo.isReferralActive,
                    fieldPaddingLeft: false,
                    fieldPaddingRight: false,
                    fieldOptions: [
                      { id: 'type', value: 'tags' },
                      { id: 'showArrow', value: true },
                      { id: 'enableSearch', value: true },
                      /* {
                        id: 'options', value: [
                          (this.roleWhiteList ?? []).filter(f => String.prototype.isNullOrEmpty(f)).map(m => {
                            return {
                              label: m,
                              value: m
                            }
                          })
                        ]
                      } */
                    ]
                  },
                  {
                    fieldType: 'select',
                    fieldControlName: 'guestRole',
                    fieldBootstrapColumn: 12,
                    fieldName: this.tranPipe.transform('private_sale_role_referral_form_guest_role', 'Ruolo dell\'invitato', []),
                    fieldPlaceholder: this.tranPipe.transform('private_sale_role_referral_form_guest_role_placeholder', 'Seleziona il ruolo dell\'invitato', []),
                    fieldValue: this.currentActiveRole.baseInfo.referralReferralRoleId,
                    fieldIsRequired: this.currentActiveRole.baseInfo.isReferralActive,
                    fieldIsDisabled: !this.currentActiveRole.baseInfo.isReferralActive,
                    fieldPaddingLeft: false,
                    fieldPaddingRight: false,
                    fieldOptions: [
                      { id: 'scrollFunction', value: this.rolesScrollFunction },
                      { id: 'searchFunction', value: this.rolesSearchFunction },
                      { id: 'searchByIdFunction', value: this.rolesSearchByIdFunction },
                      { id: 'refreshFieldNumber', value: 0 },
                    ]
                  }
                ]
              }
            ]
          },
          {
            formSectionId: "limit",
            formSectionName: this.tranPipe.transform('private_sale_role_edit_limit', 'Limiti', []),
            showLine: false,
            purpleFormGroups: [
              {
                fieldGroupNumber: 1,
                fielGroupBootstrapColumn: 12,
                fieldGroupPaddingLeft: false,
                fieldGroupPaddingRight: false,
                formFieldFormGroup: this.validateForm,
                formFields: [
                  {
                    fieldType: 'date',
                    fieldControlName: 'closeBookings',
                    fieldBootstrapColumn: 12,
                    fieldName: this.tranPipe.transform('private_sale_role_referral_form_close_bookings_date', 'Data chiusura prenotazioni', []),
                    fieldPlaceholder: this.tranPipe.transform('private_sale_role_referral_form_close_bookings_date_placeholder', 'Seleziona la data di chiusura delle prenotazioni', []),
                    fieldValue: this.currentActiveRole.baseInfo.roleStopDate,
                    fieldIsRequired: false,
                    fieldIsDisabled: false,
                    fieldPaddingLeft: false,
                    fieldPaddingRight: false,
                    fieldOptions: [
                      { id: 'dateFormat', value: 'dd-MM-yyyy HH:mm'},
                      { id: 'showTime', value: { nzFormat: 'HH:mm' } }
                    ]
                  },
                  {
                    fieldType: 'date',
                    fieldControlName: 'openInviteDate',
                    fieldBootstrapColumn: 12,
                    fieldName: this.tranPipe.transform('private_sale_role_referral_form_open_invite_date', 'Data apertura inviti', []),
                    fieldPlaceholder: this.tranPipe.transform('private_sale_role_referral_form_open_invite_date_placeholder', 'Seleziona la data apertura inviti', []),
                    fieldValue: this.currentActiveRole.baseInfo.inviteStartDate,
                    fieldIsRequired: false,
                    fieldIsDisabled: false,
                    fieldPaddingLeft: false,
                    fieldPaddingRight: false,
                    fieldOptions: [
                      { id: 'dateFormat', value: 'dd-MM-yyyy HH:mm' },
                      { id: 'showTime', value: { nzFormat: 'HH:mm' } }
                    ]
                  },
                ]
              }
            ]
          }
        ]

        this.originalFormValues = this.validateForm.value;

      if (!firstTime) {
        this.refreshTableRowsNumber++;
      }

      setTimeout(() => {
        //this.changeFormStatus("referral", !this.currentActiveRole!.baseInfo.isReferralActive);

        this.salePurpleFormFieldGroups[0].purpleFormGroups[0].formFields.forEach(f => {
          if (this.currentActiveRole?.baseInfo.isReferralActive ?? false) {
            this.validateForm.get(f.fieldControlName)?.enable();

            if (f.fieldControlName.strEq("guestRole")) {
              this.validateForm.get(f.fieldControlName)?.setValue(this.currentActiveRole?.baseInfo.referralReferralRoleId);
            }
          } else {
            this.validateForm.get(f.fieldControlName)?.disable();
          }
        })

        if (!this.alreadyPipeForm) {

          this.validateForm.valueChanges.pipe(takeUntil(this.sub)).subscribe(s => {
            const idx = (this.dashSvc.dashMainItems ?? []).findIndex(f => f.id.strEq("save-roles"));

            if (idx == -1) {
              this.dashSvc.dashMainItems.push(
                new dashboardButton(
                  "save-roles",
                  this.tranPipe.transform('private_sale_roles_save_changes_button', 'Salva Modifiche', []),
                  async () => {
                    await this.saveAllChanges();
                  }
                )
              )
            }
          });
          this.alreadyPipeForm = true;
        }
        const idx = (this.dashSvc.dashMainItems ?? []).findIndex(f => f.id.strEq("save-roles"));
        if (idx != -1) {
          this.dashSvc.dashMainItems.splice(idx, 1)
        }


      }, 100);

      /*  setTimeout(() => {
         this.changeFormStatus("referral", !this.currentActiveRole!.baseInfo.isReferralActive);
       }, 100); */


    })

    this.isLoading = false;
  }

  private checkCanChangeTab(): boolean | Observable<boolean>{
    const idx = (this.dashSvc.dashMainItems ?? []).findIndex(f => f.id.strEq("save-roles"));
    if (idx === -1) {
      return true;
    } else {
      return this.confirm();
    }
  }

  canDeactivateRoleTab: NzTabsCanDeactivateFn = () => {
    return this.checkCanChangeTab();
  };

  private confirm(): Observable<boolean> {
    return new Observable(observer => {
      this.modal.create({
        nzTitle: this.tranPipe.transform('chack_change_role_tab_modal_title', 'Attenzione! modifiche non salvate', []),
        nzContent: this.tranPipe.transform('chack_change_role_tab_modal_subtitle',
          'Ci sono delle modifiche non salvate, vuoi salvarle prima di cambiare ruolo?', []),
        nzWidth: '600px',
        nzOkText: this.tranPipe.transform('chack_change_role_tab_modal_button_ok', 'Salva', []),
        nzClassName: 'purple-simple-modal',
        nzCancelText: this.tranPipe.transform('chack_change_role_tab_modal_button_no', 'Annulla', []),
        nzMaskClosable: false,
        nzOnOk: async () => {
          await this.saveAllChanges();
          observer.next(true);
          observer.complete();
        },
        nzOnCancel: () => {
          Object.keys(this.validateForm.controls).forEach(key => {
            const control: AbstractControl = this.validateForm.controls[key];
            if (control.dirty) {
              control.markAsPristine();
              control.patchValue(this.originalFormValues[key]);
            }
          });

          observer.next(true);
          observer.complete();
        }
      });
    });
  }

  editUserRole() {
    this.modal.create<AddUserRoleModalComponent, { saleEventId: string, roleId: string }>({
      nzContent: AddUserRoleModalComponent,
      nzData: {
        saleEventId: this.saleEvent!.saleEventId,
        roleId: this.currentActiveRole!.baseInfo.roleId
      },
      nzTitle: this.tranPipe.transform('edit_add_user_role_modal_title', 'Assegna ruolo a utente', []),
      nzWidth: '800px',
      nzClassName: 'purple-modal',
      nzFooter: null,
      nzMaskClosable: false,
    }).afterClose.subscribe(async () => {
      this.refreshTableRowsNumber++;
    });
  }

  async changeRoleTab(index: number) {
    console.log("CHANGE ROLE TAB");
    await this.onRoleChange(this.tabs[index].roleId, false);
  }

  async changeFormStatus(formId: string, disabled: boolean) {

    if (formId.strEq("referral")) {
      if ((this.currentActiveRole?.baseInfo.referralId ?? undefined) != undefined) {
        if (disabled) {
          await this.apiProxySvc.makeRequestErrorMessage<boolean>(() => this.admSvc.deleteRoleReferral(this.tsvc.currentLanguage.value, {
            referralId: this.currentActiveRole!.baseInfo.referralId!
          }), false, "internal-loader", 500, undefined, undefined, async () => {
            this.mesSvc.success(this.tranPipe.transform("referral_deleted_ok", "Referral disabilitato correttamente", []), {
              nzDuration: environment.MESSAGE_DURATION
            });
            console.log("CHANGE FORM STATUS");
            await this.onRoleChange(this.currentActiveRole!.baseInfo.roleId!, false);
          });
        }
      } else {
        if (!disabled) {
          await this.apiProxySvc.makeRequestErrorMessage<boolean>(() => this.admSvc.addRoleReferral(this.tsvc.currentLanguage.value, {
            maxInvitationsNr: 1,
            maxLinkRedeemNr: 1,
            roleId: this.currentActiveRole!.baseInfo.roleId!,
            roleWhiteListDomain: [],
            saleEventId: this.saleEvent!.saleEventId,
            referralReferralRoleId: undefined
          }), false, "internal-loader", 500, undefined, undefined, async () => {
            this.mesSvc.success(this.tranPipe.transform("referral_added_ok", "Referral aggiunto correttamente", []), {
              nzDuration: environment.MESSAGE_DURATION
            });
            console.log("CHANGE FORM STATUS");
            await this.onRoleChange(this.currentActiveRole!.baseInfo.roleId!, false);
          });
        }
      }
    }

    (this.salePurpleFormFieldGroups.find(f => f.formSectionId.strEq(formId))?.purpleFormGroups ?? []).forEach(f => {
      f.formFields.forEach(f1 => {
        if (disabled) {
          this.validateForm.controls[f1.fieldControlName].disable();
          this.validateForm.controls[f1.fieldControlName].removeValidators(Validators.required);
        } else {
          this.validateForm.controls[f1.fieldControlName].enable();
          if (!f1.fieldControlName.strEq("allowedDomains")) {
            this.validateForm.controls[f1.fieldControlName].addValidators(Validators.required);
          }
        }
      });
    })
  }

  //ScrollFunction
  rolesScrollFunction = (args: { pageNumber: number, pageSize: number, culture: string, searchName: string, searchFilters: PurpleSelectFilter[] }) => {
    args.searchFilters = [
      { propertyName: "roleType", filterValues: ["notStaff"] }
    ]
    if ((this.currentActiveRole?.baseInfo.roleId ?? undefined) != undefined) {
      args.searchFilters.push(
        { propertyName: "rolesToExclude", filterValues: [this.currentActiveRole!.baseInfo.roleId] }
      )
    }

    return this.admSvc.getRolesSelect(args.culture, args)
  }
  //SearchFunction
  rolesSearchFunction = (args: { pageNumber: number, pageSize: number, culture: string, searchName: string, searchFilters: PurpleSelectFilter[] }) => {
    args.searchFilters = [
      { propertyName: "roleType", filterValues: ["notStaff"] }
    ]
    if ((this.currentActiveRole?.baseInfo.roleId ?? undefined) != undefined) {
      args.searchFilters.push(
        { propertyName: "rolesToExclude", filterValues: [this.currentActiveRole!.baseInfo.roleId] }
      )
    }
    return this.admSvc.getRolesByNameSelect(args.culture, args)
  }
  //InitFunction (SearchByIdFunction)
  rolesSearchByIdFunction = (args: { pageNumber: number, pageSize: number, culture: string, searchName: string, searchFilters: PurpleSelectFilter[] }) => {
    return this.admSvc.getRolesByIdSelect(args.culture, args)
  }

  async saveAllChanges() {
    this.isLoading = true;
    for (const i in this.validateForm.controls) {
      this.validateForm.controls[i].markAsDirty();
      this.validateForm.controls[i].updateValueAndValidity();
    }

    if (this.validateForm.valid) {
      if (this.currentActiveRole?.baseInfo.isReferralActive ?? false) {
        await this.saveSettingReferralChanges();
      }
      await this.saveLimitSettingChanges();
    }

    this.isLoading = false;
  }

  async saveSettingReferralChanges() {
    if (this.validateForm.valid) {
      if ((this.currentActiveRole?.baseInfo.referralId ?? undefined) == undefined) {
        await this.apiProxySvc.makeRequestErrorMessage<boolean>(() => this.admSvc.addRoleReferral(this.tsvc.currentLanguage.value, {
          maxInvitationsNr: this.validateForm.controls['maxInviteNumber'].value,
          maxLinkRedeemNr: this.validateForm.controls['maxLinkNumber'].value,
          roleId: this.currentActiveRole!.baseInfo.roleId!,
          roleWhiteListDomain: this.validateForm.controls['allowedDomains']?.value ?? undefined,
          saleEventId: this.saleEvent!.saleEventId,
          referralReferralRoleId: this.validateForm.controls['guestRole'].value
        }), false, "internal-loader", 500, undefined, undefined, () => {
          this.mesSvc.success(this.tranPipe.transform("referral_added_ok", "Referral aggiunto correttamente", []), {
            nzDuration: environment.MESSAGE_DURATION
          });
        })
      } else {
        await this.apiProxySvc.makeRequestErrorMessage<boolean>(() => this.admSvc.updateReferral(this.tsvc.currentLanguage.value, {
          maxInvitationsNr: this.validateForm.controls['maxInviteNumber'].value,
          maxLinkRedeemNr: this.validateForm.controls['maxLinkNumber'].value,
          roleWhiteListDomain: this.validateForm.controls['allowedDomains']?.value ?? undefined,
          referralReferralRoleId: this.validateForm.controls['guestRole'].value,
          referralId: this.currentActiveRole!.baseInfo.referralId!
        }), false, "internal-loader", 500, undefined, undefined, () => {
          this.mesSvc.success(this.tranPipe.transform("referral_updated_ok", "Referral aggiornato con successo", []), {
            nzDuration: environment.MESSAGE_DURATION
          });
        })
      }
    }
  }


  async saveLimitSettingChanges() {
    if (this.validateForm.valid) {
      await this.apiProxySvc.makeRequestErrorMessage<boolean>(() => this.admSvc.updateSaleEventRoleDates(this.tsvc.currentLanguage.value, {
        roleId: this.currentActiveRole!.baseInfo.roleId!,
        saleEventId: this.saleEvent!.saleEventId,
        bookStopDate: this.validateForm.controls['closeBookings']?.value ?? undefined,
        inviteStartDate: this.validateForm.controls['openInviteDate']?.value ?? undefined
      }), false, "internal-loader", 500, undefined, undefined, () => {
        this.mesSvc.success(this.tranPipe.transform("role_dastes_update_ok", "Limiti aggiornati con successo", []), {
          nzDuration: environment.MESSAGE_DURATION
        });

        const idx = (this.dashSvc.dashMainItems ?? []).findIndex(f => f.id.strEq("save-roles"));
        if (idx != -1) {
          this.dashSvc.dashMainItems.splice(idx, 1)
        }

        this.modal.closeAll();
      });
    }
  }


  addActiveSaleRole() {
    this.modal.create<AddTabRoleModalComponent, string[]>({
      nzContent: AddTabRoleModalComponent,
      nzData: this.tabs.map(m => m.roleId),
      nzTitle: this.tranPipe.transform("add_active_role_modal_title", "Aggiungi ruolo attivo"),
      nzWidth: '600px',
      nzClassName: 'purple-modal',
      nzFooter: null,
      nzMaskClosable: false,
    }).afterClose.subscribe(async (res: Role | undefined) => {
      if (res != undefined) {
        const tabLen = this.tabs.length;

        this.tabs.push(
          {
            canBeRemoved: true,
            isStaffRole: res.isStaffRole,
            roleId: res.roleId,
            roleName: res.roleName,
            rowNumber: tabLen + 1,
            isActive: res.isActive,
            roleDescription: res.roleDescription,
            timeStamp: res.timeStamp,
            roleLevel: res.roleLevel
          }
        );


        this.selectedIndex = tabLen;
      }
    });
  }

  removeActiveSaleRole(tab: { index: number; }) {
    const tabLen = this.tabs.length;
    this.tabs.splice(tab.index, 1);

    if (tabLen == tab.index) {
      this.selectedIndex = tab.index - 1
    }
  }

  getCapacityValue(ctn: number | undefined | null) {
    if (ctn == undefined || ctn == null) {
      return this.tranPipe.transform('private_sale_role_slot_ctn_not_available', 'Non disponibili', [])
    }

    if (ctn == -1) {
      return this.tranPipe.transform('private_sale_role_slot_ctn_unlimited', 'Illimitati', [])
    }

    return ctn;
  }

}



interface editSaleRole {
  //referralLink?: string | undefined;
  showReferralLink?: boolean | undefined;
  canBeDisabled?: boolean | undefined;
  isDisabled?: boolean | undefined;
  showSpin?: boolean | undefined;
  isSpinnerVisible?: boolean | undefined;
  showLine?: boolean | undefined;
  formSectionId: string;
  formSectionName: string;
  purpleFormGroups: purpleFormFieldsGroup[]
}