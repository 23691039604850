import { Component, OnInit } from '@angular/core';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { PurpleApiMakeRequestResponse, PurpleApiProxyService, PurpleApiResponseStatus, PurpleTranslationPipe } from 'purple-lib';
import { AppTranslationService } from 'src/core-modules/localization/localization.service';
import { MailChimpService, StripoService, TemplateBase } from 'src/core-modules/sdk/api';
import { environment } from 'src/environments/default/environment';

@Component({
  selector: 'app-import-mail-template',
  templateUrl: './import-mail-template.component.html',
  styleUrls: ['./import-mail-template.component.scss']
})
export class ImportMailTemplateComponent implements OnInit {

  constructor(private stripoCtrl: StripoService, private tsvc: AppTranslationService, private tranPipe: PurpleTranslationPipe,
    private modal: NzModalRef, private mailChimpCtrl: MailChimpService, private apiProxySvc: PurpleApiProxyService) { }

  templateFolders: templateFolderTree[] = [];
  currentFolder: templateFolderTree | undefined;
  selectedTemplate: templateFolderItem | undefined;

  isLoading: boolean = false;
  tabIdx: mailTemplateImportType = mailTemplateImportType.Stripo;


  async ngOnInit() {
    if ((environment.COMPANY_NAME ?? "Purple").strEq("Jammy Dude")) {
      this.tabIdx = 1;
    }
    await this.reloadTemplateList();
  }


  async reloadTemplateList(refresh: boolean = false) {
    this.isLoading = true;

    var res = await this.apiProxySvc.makeRequestErrorMessage<TemplateBase[]>(() =>
      this.tabIdx == mailTemplateImportType.Stripo ?
        this.stripoCtrl.getTemplateList(this.tsvc.translationLanguage.value,
          {
            refresh: refresh,
            templateType: 0
          }) :

        this.mailChimpCtrl.getTemplateList(this.tsvc.translationLanguage.value), false, "internal-loader", 500,
      this.tranPipe.transform("import_template_error_response", "Nessun template trovato"), undefined, async (res: PurpleApiMakeRequestResponse<TemplateBase[]>) => {

        (res.data ?? []).forEach(el => {
          const fidx = this.templateFolders.findIndex(f => f.folder?.strEq(el.folder ?? ""));

          if (fidx == -1) {
            this.templateFolders.push({
              folder: el.folder ?? undefined,
              isOpen: false,
              templates: [{ id: el.id ?? undefined, name: el.name ?? undefined, previewImageUrl: el.previewImageUrl ?? undefined }]
            })
          } else {
            this.templateFolders[fidx].templates.push({ id: el.id ?? undefined, name: el.name ?? undefined, previewImageUrl: el.previewImageUrl ?? undefined })
          }
        });
        console.log("TEMPLATES: ", this.templateFolders)

      })


      if (res.status != PurpleApiResponseStatus.Success) {
        switch (this.tabIdx) {
          case mailTemplateImportType.Stripo:
            await this.onTabChange(mailTemplateImportType.MailChimp);
            break;
          case mailTemplateImportType.MailChimp:
            await this.onTabChange(mailTemplateImportType.Stripo);
            break;
          default:
            break;
        }
      }

    this.isLoading = false;
  }

  async onTabChange(idx: number) {
    this.tabIdx = idx;
    await this.reloadTemplateList();
  }

  setCurrentTemplate(temp: templateFolderItem) {
    this.selectedTemplate = temp;
  }


  async getTemplateHtml() {
    this.isLoading = true;
    await this.apiProxySvc.makeRequestErrorMessage<TemplateBase[]>(() =>
      this.tabIdx == mailTemplateImportType.Stripo ?
        this.stripoCtrl.getTemplate(this.tsvc.translationLanguage.value, { id: this.selectedTemplate!.id!, refresh: true, templateType: 0 }) :
        this.mailChimpCtrl.getTemplate(this.tsvc.translationLanguage.value, { id: this.selectedTemplate!.id!, refresh: true, templateType: 0 }),
      false, "internal-loader", 500, undefined, undefined, (res: PurpleApiMakeRequestResponse<TemplateBase[]>) => {
        this.modal.close(res.data)
      })
    this.isLoading = false;
  }
}

enum mailTemplateImportType {
  Stripo = 0,
  MailChimp = 1
}

export class templateFolderTree {
  folder: string | undefined;
  isOpen: boolean = false;
  templates: templateFolderItem[] = []
}


export interface templateFolderItem {
  id: string | undefined;
  name: string | undefined;
  previewImageUrl: string | undefined;
}