import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PurpleTranslationPipe, PurpleApiProxyService, PurpleApiMakeRequestResponse, dashboardButton } from 'purple-lib';
import { DashboardLayout2Service } from 'src/app/dashboard/dashboard-layout-2/dashboard-layout-2.service';
import { AppTranslationService } from 'src/core-modules/localization/localization.service';
import { BackOfficeService, PRPVSaleEventFullExtended, SaleEventAttributeValue, SaleEventAttributeValuesItem } from 'src/core-modules/sdk/api';
import { PrivateSaleService } from '../../private-sale-service.service';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NzMessageService } from 'ng-zorro-antd/message';
import { Subject, takeUntil } from 'rxjs';
import { environment } from 'src/environments/default/environment';
import { EditSaleEventAttributesModalComponent } from '../modals/edit-sale-event-attributes-modal/edit-sale-event-attributes-modal.component';

@Component({
  selector: 'app-private-sale-attributes',
  templateUrl: './private-sale-attributes.component.html',
  styleUrls: ['./private-sale-attributes.component.scss']
})
export class PrivateSaleAttributesComponent implements OnInit {

  constructor(private tranPipe: PurpleTranslationPipe, public dashSvc: DashboardLayout2Service, private route: ActivatedRoute, private modal: NzModalService,
    private admSvc: BackOfficeService, private apiProxySvc: PurpleApiProxyService, public tsvc: AppTranslationService, private seSvc: PrivateSaleService,
    private router: Router, private mesSvc: NzMessageService) { }


  saleEvent: PRPVSaleEventFullExtended | undefined;
  saleEventId!: string;
  sub: Subject<void> = new Subject();
  currentActiveRole: SaleEventAttributeValuesItem | undefined;
  selectedIndex: number = 0;
  settingSelectedIndex: number = 0;
  isLoading: boolean = false;
  saleAttributes: SaleEventAttributeValuesItem[] = [];


  ngOnInit() {
    this.seSvc.currentSaleEvent$.pipe(takeUntil(this.sub)).subscribe((se: PRPVSaleEventFullExtended | undefined) => {
      this.saleEvent = se;

      if (se != undefined) {
        this.getUserAttributes();
      }
    });

    this.route.queryParams.subscribe(async params => {
      this.saleEventId = params['saleEvent'];

      this.dashSvc.dashMainItems?.push(
        new dashboardButton(
          "back-button",
          this.tranPipe.transform("dashboard_private_general_iofo_back_button", "Torna alla lista", []),
          () => { this.router.navigate([this.tsvc.currentLanguage.value, 'dashboard', 'private-sales', 'private-sale-list']) },
          undefined,
          "arrow-left",
          undefined,
          "left"
        )
      );
      await this.refreshSale();
    });
  }

  ngOnDestroy(): void {
    this.sub.next();
    this.sub.complete();
    this.dashSvc.clearPageHeader();
  }

  private async refreshSale() {
    await this.dashSvc.refreshSaleEvent(this.saleEventId, "private-sale-roles", 'Ruoli abilitati alla sale');
  }

  async getUserAttributes() {
    await this.apiProxySvc.makeRequestErrorPage<SaleEventAttributeValuesItem[]>(() => this.admSvc.getSaleEventAttributes(this.tsvc.currentLanguage.value, {
      saleEventId: this.saleEvent!.saleEventId
    }), false, "internal-loader", 500, "internal-error", async () => { await this.getUserAttributes() }, async (res: PurpleApiMakeRequestResponse<SaleEventAttributeValuesItem[]>) => {
      this.saleAttributes = res.data ?? []
    })
  }

  async removePreference(saleEventAttributeValueId: string) {
    await this.apiProxySvc.makeRequestErrorMessage<boolean>(() => this.admSvc.removeSaleEventAttributeValue(this.tsvc.currentLanguage.value, {
      saleEventAttributeValueId: saleEventAttributeValueId
    }), false, "internal-loader", 500, undefined, undefined, async (res: PurpleApiMakeRequestResponse<boolean>) => {
      this.mesSvc.success(this.tranPipe.transform('remove_attribute_value_ok', 'Preferenza rimossa con successo', []), {
        nzDuration: environment.MESSAGE_DURATION
      });
      await this.getUserAttributes();
    })
  }

  addPreference(saleEventAttribute: SaleEventAttributeValuesItem) {
    this.modal.create<EditSaleEventAttributesModalComponent, { saleEventAttribute: SaleEventAttributeValuesItem, saleEventId: string }>({
      nzTitle: this.tranPipe.transform("add_sale_attributes_value", "Imposta valore attributi", []),
      nzContent: EditSaleEventAttributesModalComponent,
      nzData: {saleEventAttribute: saleEventAttribute, saleEventId: this.saleEvent!.saleEventId},
      nzWidth: '800px',
      nzClassName: 'ps-modal',
      nzFooter: null,
      nzCentered: true,
      nzMaskClosable: false
    }).afterClose.subscribe(async s => {
      await this.getUserAttributes();
    });
  }
}
