import { AfterViewInit, Component, OnInit } from '@angular/core';
import { NzModalService } from 'ng-zorro-antd/modal';
import { Guid, PurpleLanguageIconPipe, PurpleLoaderService, PurpleTableBoxColumn, PurpleTableColumn, PurpleTableSearchValue, PurpleTableSelectFilter, PurpleTranslationPipe } from 'purple-lib';
import { AuthenticationService } from 'src/core-modules/authentication/authentication.service';
import { BackOfficeService, PurpleTableSearchGroupGroup, Role, RolePurpleTableResponse } from 'src/core-modules/sdk/api';
import { DashboardLayout2Service } from '../../dashboard-layout-2/dashboard-layout-2.service';
import { EditRoleComponent } from '../modals/edit-role/edit-role.component';
import { Subject, Subscription, takeUntil } from 'rxjs';
import { AppTranslationService } from 'src/core-modules/localization/localization.service';


@Component({
  selector: 'app-roles',
  templateUrl: './roles.component.html',
  styleUrls: ['./roles.component.scss']
})
export class RolesComponent implements OnInit, AfterViewInit {

  constructor(public dashSvc: DashboardLayout2Service, public backUserSvc: BackOfficeService, private loaderSvc: PurpleLoaderService, private languageIconPipe: PurpleLanguageIconPipe,
    private modal: NzModalService, private tranPipe: PurpleTranslationPipe, private authSvc: AuthenticationService, public tsvc: AppTranslationService) {

  }

  ngAfterViewInit(): void {
    const pageName = "roles";
    this.dashSvc.setPageHeader(
      this.tranPipe.transform(pageName + '_page_title', 'Ruoli', []),
      this.tranPipe.transform(pageName + '_page_subtitle', 'Lista dei ruoli disponibili', []))
  }

  sub: Subject<void> = new Subject();
  
  listOfData: Array<RolePurpleTableResponse> | undefined | null;
  refreshTableRowsNumber: number = 0;
  
  tabColumns: PurpleTableBoxColumn[] = [
    {
      name: this.tranPipe.transform("table_header_translation_language", "Lingua"),
      hasReorder: false,
      hasFilter: false,
      fixedWidth: "80px"
    },
    {
      name: this.tranPipe.transform("table_header_is_translated", "Tradotto"),
      dbKey: "IsTranslated",
      hasReorder: true,
      hasFilter: true,
      fixedWidth: "110px",
      filterField: {
        fieldType: 'select-local',
        fieldControlName: 'IsTranslated',
        fieldBootstrapColumn: 12,
        fieldName: this.tranPipe.transform('table_box_filter_is_translated', 'Tradotto', []),
        fieldPlaceholder: this.tranPipe.transform('table_box_filter_is_translated_placeholder', 'Tradotto', []),
        fieldValue: undefined,
        fieldIsRequired: false,
        fieldIsDisabled: false,
        fieldPaddingLeft: false,
        fieldPaddingRight: false,
        fieldOptions: [
          { id: 'isMultiple', value: false },
          { id: 'showArrow', value: true },
          { id: 'enableSearch', value: false },
          {
            id: 'options', value: [
              {
                label: 'Si',
                value: 1
              },
              {
                label: 'No',
                value: 0
              }
            ]
          }
        ]
      },
      operatorType: "="
    },
    {
      name: this.tranPipe.transform("table_header_name", "Nome"),
      dbKey: "roleName",
      hasReorder: true,
      hasFilter: true,
      filterField: {
        fieldType: 'input-text',
        fieldControlName: 'roleName',
        fieldBootstrapColumn: 12,
        fieldName: this.tranPipe.transform('table_filter_role_name', 'Nome', []),
        fieldPlaceholder: this.tranPipe.transform('table_filter_role_name_placeholder', 'Nome ruolo', []),
        fieldValue: undefined,
        fieldIsRequired: false,
        fieldIsDisabled: false,
        fieldPaddingLeft: false,
        fieldPaddingRight: false
      },
      operatorType: "like"
    },
    {
      name: this.tranPipe.transform("role_table_header_description", "Descrizione"),
      dbKey: "roleDescription",
      hasReorder: true,
      hasFilter: true,
      filterField: {
        fieldType: 'input-text',
        fieldControlName: 'roleDescription',
        fieldBootstrapColumn: 12,
        fieldName: this.tranPipe.transform('table_filter_description', 'Descrizione', []),
        fieldPlaceholder: this.tranPipe.transform('table_filter_description_placeholder', 'Descrizione ruolo', []),
        fieldValue: undefined,
        fieldIsRequired: false,
        fieldIsDisabled: false,
        fieldPaddingLeft: false,
        fieldPaddingRight: false
      },
      operatorType: "like"
    },
    {
      name: this.tranPipe.transform("role_table_header_is_staff_role", "Ruolo Staff"),
      dbKey: "isStaffRole",
      hasReorder: true,
      hasFilter: true,
      filterField: {
        fieldType: 'select-local',
        fieldControlName: 'isStaffRole',
        fieldBootstrapColumn: 12,
        fieldName: this.tranPipe.transform('table_box_filter_is_staff_role', 'Ruolo staff', []),
        fieldPlaceholder: this.tranPipe.transform('table_box_filter_is_staff_role_placeholder', 'Ruolo staff', []),
        fieldValue: undefined,
        fieldIsRequired: false,
        fieldIsDisabled: false,
        fieldPaddingLeft: false,
        fieldPaddingRight: false,
        fieldOptions: [
          { id: 'isMultiple', value: false },
          { id: 'showArrow', value: true },
          { id: 'enableSearch', value: false },
          {
            id: 'options', value: [
              {
                label: 'Si',
                value: 1
              },
              {
                label: 'No',
                value: 0
              }
            ]
          }
        ]
      },
      operatorType: "="
    },
    {
      name: this.tranPipe.transform("table_header_actions", "Azioni"),
      hasReorder: false,
      hasFilter: false,
      fixedWidth: "190px"
    }
  ]


  refreshFunction = (args: {
    pageNumber: number, pageSize: number, orderColumnName?: string | null, orderType?: string | null,
    tableSearchValues?: Array<PurpleTableSearchGroupGroup> | null, tableFilters?: Array<PurpleTableSelectFilter> | null, switchOn?: boolean,
    culture: string
  }) => {
    if (!this.authSvc.isPurpleAdminUser()) {
      args.tableFilters = [
        { property: "rolesToExclude", filterValue: Guid.empty() }
      ]
    }
    args.culture = this.tsvc.translationLanguage.value;
    return this.backUserSvc.getRolesTable(args.culture, args)
  }


  async ngOnInit() {
    this.loaderSvc.addRequest("internal-loader")

    this.dashSvc.addTranslationSelect();

    this.tsvc.translationLanguage.pipe(takeUntil(this.sub)).subscribe(async () => {
      await this.refreshTableRowsNumber++;
    });

    this.dashSvc.dashMainItems = [];
    
    this.dashSvc.dashMainItems?.push(
      {
        id: "bt",
        type: "button",
        buttonText: this.tranPipe.transform("table_add_new_item_button", "Aggiungi {0}", ["Ruolo"]),
        buttonFunction: () => { this.editItem(undefined) }
      })
    this.loaderSvc.removeRequest("internal-loader", 350)
  }

  ngOnDestroy(): void {
    this.dashSvc.clearPageHeader();
    this.sub.next();
    this.sub.complete();
  }


  editItem(role: Role | undefined) {
    this.modal.create<EditRoleComponent, Role | undefined>({
      nzContent: EditRoleComponent,
      nzData: role,
      nzTitle: role == undefined ? this.tranPipe.transform("table_item_edit_modal_title_new", "Aggiungi {0} {1}", ["ruolo", '<span class="ps-ml-1 '+this.languageIconPipe.transform(this.tsvc.translationLanguage.value) +'"></span>']) : 
      this.tranPipe.transform("table_item_edit_modal_title_update", "Modifica {0} {1}", ["ruolo", '<span class="ps-ml-1 '+this.languageIconPipe.transform(this.tsvc.translationLanguage.value) +'"></span>']),
      nzWidth: '1000px',
      nzClassName: 'purple-modal',
      nzFooter: null,
      nzMaskClosable: false,
    }).afterClose.pipe(takeUntil(this.sub)).subscribe(async (item: Role | undefined) => {
      if (item != undefined) {
        this.refreshTableRowsNumber++;
      }
    });
  }
}