import { Component, Inject, OnInit } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { NzModalRef, NZ_MODAL_DATA } from 'ng-zorro-antd/modal';
import { lastValueFrom } from 'rxjs';
import { AppTranslationService } from 'src/core-modules/localization/localization.service';
import { BackOfficeService, PRPVUserAttributeDefaultValueFull, PurpleApiResponseStatus, SaleEventAttributeValue, SaleEventAttributeValueRequest, SaleEventAttributeValuesItem } from 'src/core-modules/sdk/api';
import { Guid, PurpleTranslationPipe } from 'purple-lib';

@Component({
  selector: 'app-edit-sale-event-attributes-modal',
  templateUrl: './edit-sale-event-attributes-modal.component.html',
  styleUrls: ['./edit-sale-event-attributes-modal.component.scss']
})
export class EditSaleEventAttributesModalComponent implements OnInit {

  constructor(@Inject(NZ_MODAL_DATA) public data :{ saleEventAttribute: SaleEventAttributeValuesItem, saleEventId: string } , 
  private admSvc: BackOfficeService, private tsvc: AppTranslationService, private modalRef: NzModalRef, private notSvc: NzNotificationService, 
    private tranPipe: PurpleTranslationPipe) { }

    preferences: SaleEventAttributeValue[] = this.data.saleEventAttribute.saleEventAttributeValues;

  ngOnInit() {
  }

  addPreference(userAttributeDefaultValue: PRPVUserAttributeDefaultValueFull) {
    var current: SaleEventAttributeValue | undefined = this.data.saleEventAttribute.
    saleEventAttributeValues.find(f=> f.userAttributeDefaultValueId.strEq(userAttributeDefaultValue.userAttributeDefaultValueId));

    this.preferences.push({
      isDefaultValue: current?.isDefaultValue??true,
      userAttributeDefaultValueId: current?.userAttributeDefaultValueId??userAttributeDefaultValue.userAttributeDefaultValueId,
      userAttributeId: current?.userAttributeId??this.data.saleEventAttribute.userAttributeId,
      saleEventAttributeValueId: current?.saleEventAttributeValueId??Guid.newGuid(),
      userAttributeValueText: current?.userAttributeValueText??userAttributeDefaultValue.userAttributeDefaultValueText,
      saleEventId: current?.saleEventId??this.data.saleEventId,
      isActive: current?.isDefaultValue??true,
      timeStamp: current?.timeStamp??undefined
    });

    //console.log("Preferenze:", this.preferences)
  }

  selectedPreference(userAttributeDefaultValueId: string): boolean {
    return this.preferences.findIndex(f => f.userAttributeDefaultValueId.strEq(userAttributeDefaultValueId)) > -1;
  }

  removePreference(userAttributeDefaultValueId: string) {
    const idx = this.preferences.findIndex(f => f.userAttributeDefaultValueId.strEq(userAttributeDefaultValueId));
    this.preferences.splice(idx, 1)
  }

  async savePreference() {
    var toUpd: SaleEventAttributeValueRequest[] = [
      {
        saleEventAttributeValues: this.preferences,
        userAttributeId: this.data.saleEventAttribute.userAttributeId
      }
    ];

    var res = await lastValueFrom(this.admSvc.addOrRemoveSaleEventAttributeValue(this.tsvc.currentLanguage.value, {
      saleEventAttributeValues: toUpd,
      saleEventId: this.data.saleEventId
    }));

    if (res.status == PurpleApiResponseStatus.Success) {
      this.modalRef.close();
    } else {
      this.notSvc.error(this.tranPipe.transform('erro_message_default_title','Errore', []), res.message, {nzDuration: 1000, nzPlacement: "top"});
     
    }
  }

}
