import pkg from '../../../package.json';

export const environment = {
  appVersion: pkg.version,
  appName: pkg.name,
  production: true,

  COMPANY_NAME: 'Off Price',
  COMPANY_ABB: 'op',
  SHOW_COUNTRY_SELECT: false,
  COUNTRY_SELECT: [
    {
      COUNTRY_NAME: 'Italia',
      COUNTRY_FLAG: 'it',
      COUNTRY_BASE_PATH: 'https://api-op.event-sales.com',
      COUNTRY_MEDIA_PATH: 'https://media-op.event-sales.com',
      COUNTRY_DEFAULT_LANGUAGE: 'it-it',

      ////TODO: DA CANCELLARE CON LE NUOVE API ////////////
      BOOKING_CLIENT_HOST: 'booking-op.event-sales.com',
      /////////////////////////////////////////////////////
    },
  ],

  SAFARI_COLOR: "#ff0000",
  HTML_THEME_COLOR: "#ffffff",
  HTML_TITLE_COLOR: "#ffc40d",

  EDIT_TRAN_SYMBOL: "",
  NEW_TRAN_SYMBOL: "",
  EDIT_TRAN_AFFIX: "backoffice_",



  MESSAGE_DURATION: 2000,
  LOADER_DELAY: 1500,

  DEFAULT_MENU_LEVEL: 1000,
  ACTIVE_MENU: [
    //ADMNISTRATION
    { "menu": "administration", "submenu": "customers", "maxlevel": 5 },
    { "menu": "administration", "submenu": "user-detail", "maxlevel": 5 },
    { "menu": "administration", "submenu": "employees", "maxlevel": 5 },
    { "menu": "administration", "submenu": "roles", "maxlevel": 5 },
    //NOTIFICATIONS
    { "menu": "notification", "submenu": "smtp-servers", "maxlevel": 5 },
    { "menu": "notification", "submenu": "mail-templates", "maxlevel": 5 },
    { "menu": "notification", "submenu": "mail-template-exceptions", "maxlevel": 5 },
    //GENERAL
    { "menu": "general", "submenu": "translations", "maxlevel": 5 },
    { "menu": "general", "submenu": "policies", "maxlevel": 5 },
    { "menu": "general", "submenu": "general-images", "maxlevel": 5 },
    { "menu": "general", "submenu": "coupon-registry", "maxlevel": 5 },
    //PURPLE-SALES
    { "menu": "private-sales", "submenu": "private-sale-list", "maxlevel": 5 },

    { "menu": "private-sale-detail", "submenu": "general-informations", "maxlevel": 5 },
    { "menu": "private-sale-detail", "submenu": "private-sale-days-slots", "maxlevel": 5 },
    { "menu": "private-sale-detail", "submenu": "private-sale-attributes", "maxlevel": 5 },
    { "menu": "private-sale-detail", "submenu": "private-sale-roles", "maxlevel": 5 },
    { "menu": "private-sale-detail", "submenu": "private-sale-users", "maxlevel": 5 },
    { "menu": "private-sale-detail", "submenu": "private-sale-imported-users", "maxlevel": 5 },
    { "menu": "private-sale-detail", "submenu": "private-sale-user-referrals", "maxlevel": 5 },
    { "menu": "private-sale-detail", "submenu": "private-sale-policies", "maxlevel": 5 },
    { "menu": "private-sale-detail", "submenu": "private-sale-cash-machine-settings", "maxlevel": 5 },
    { "menu": "private-sale-detail", "submenu": "private-sale-bookings-report", "maxlevel": 5 },
    { "menu": "private-sale-detail", "submenu": "private-sale-mail-templates", "maxlevel": 5 },
    { "menu": "private-sale-detail", "submenu": "private-sale-mail-template-exceptions", "maxlevel": 5 },
    { "menu": "private-sale-detail", "submenu": "private-sale-images", "maxlevel": 5 },
    { "menu": "private-sale-detail", "submenu": "private-sale-coupons", "maxlevel": 5 }
  ]
};