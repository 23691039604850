import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { PurpleTranslationPipe, PurpleApiProxyService, PurpleTableSearchValue, PurpleTableSelectFilter, Guid, dashboardButton, PurpleApiMakeRequestResponse, PurpleTableBoxColumn, PurpleSelectFilter, } from 'purple-lib';
import { Subject, takeUntil } from 'rxjs';
import { DashboardLayout2Service } from 'src/app/dashboard/dashboard-layout-2/dashboard-layout-2.service';
import { AppTranslationService } from 'src/core-modules/localization/localization.service';

import { BackOfficeService, PRPVSaleEventFullExtended, PRPVPolicyFullPurpleTableResponse, PRPVPolicyFull, PurpleTableSearchGroupGroup } from 'src/core-modules/sdk/api';
import { PrivateSaleService } from '../../private-sale-service.service';
import { environment } from 'src/environments/default/environment';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NzMessageService } from 'ng-zorro-antd/message';
import { ReorderPoliciesModalComponent } from '../modals/reorder-policies-modal/reorder-policies-modal.component';
import { EditPolicyModalComponent } from '../modals/edit-policy-modal/edit-policy-modal.component';

@Component({
  selector: 'app-private-sale-policies',
  templateUrl: './private-sale-policies.component.html',
  styleUrls: ['./private-sale-policies.component.scss']
})
export class PrivateSalePoliciesComponent implements OnInit, OnDestroy {

  constructor(private tranPipe: PurpleTranslationPipe, public dashSvc: DashboardLayout2Service, private tsvc: AppTranslationService, private router: Router, private mesSvc: NzMessageService,
    public admSvc: BackOfficeService, private seSvc: PrivateSaleService, private apiProxySvc: PurpleApiProxyService, private route: ActivatedRoute, private modal: NzModalService) { }

  basePath: string = localStorage.getItem("BasePath")!;
  showTable: boolean = false;
  saleEvent: PRPVSaleEventFullExtended | undefined;
  sub: Subject<void> = new Subject();
  listOfData: Array<PRPVPolicyFullPurpleTableResponse> | undefined | null;
  refreshTableRowsNumber: number = 0;

  //ScrollFunction
  policyTypesScrollFunction = (args: { pageNumber: number, pageSize: number, culture: string, searchName: string, searchFilters: PurpleSelectFilter[] }) => {
    return this.admSvc.getPolicyTypesSelect(args.culture, args)
  }

  //SearchFunction
  policyTypesSearchFunction = (args: { pageNumber: number, pageSize: number, culture: string, searchName: string, searchFilters: PurpleSelectFilter[] }) => {
    return this.admSvc.getPolicyTypesByNameSelect(args.culture, args)
  }

  //InitFunction (SearchByIdFunction)
  policyTypesSearchByIdFunction = (args: { pageNumber: number, pageSize: number, culture: string, searchName: string, searchFilters: PurpleSelectFilter[] }) => {
    return this.admSvc.getPolicyTypesByIdSelect(args.culture, args)
  }


  tabColumns: PurpleTableBoxColumn[] = [
    {
      name: this.tranPipe.transform("table_header_translation_language", "Lingua"),
      hasReorder: false,
      hasFilter: false,
      fixedWidth: "80px"
    },
    {
      name: this.tranPipe.transform("table_header_is_translated", "Tradotto"),
      dbKey: "IsTranslated",
      hasReorder: true,
      hasFilter: true,
      fixedWidth: "110px",
      filterField: {
        fieldType: 'select-local',
        fieldControlName: 'IsTranslated',
        fieldBootstrapColumn: 6,
        fieldName: this.tranPipe.transform('table_box_filter_is_translated', 'Tradotto', []),
        fieldPlaceholder: this.tranPipe.transform('table_box_filter_is_translated_placeholder', 'Tradotto', []),
        fieldValue: undefined,
        fieldIsRequired: false,
        fieldIsDisabled: false,
        fieldPaddingLeft: false,
        fieldPaddingRight: true,
        fieldOptions: [
          { id: 'isMultiple', value: false },
          { id: 'showArrow', value: true },
          { id: 'enableSearch', value: false },
          {
            id: 'options', value: [
              {
                label: 'Si',
                value: 1
              },
              {
                label: 'No',
                value: 0
              }
            ]
          }
        ]
      },
      operatorType: "="
    },
    {
      name: this.tranPipe.transform("policie_table_header_policy_name", "Nome"),
      dbKey: "policyName",
      hasReorder: true,
      hasFilter: true,
      filterField: {
        fieldType: 'input-text',
        fieldControlName: 'policyName',
        fieldBootstrapColumn: 6,
        fieldName: this.tranPipe.transform('policy_table_box_filter_name', 'Nome', []),
        fieldPlaceholder: this.tranPipe.transform('policy_table_box_filter_name_placeholder', 'Nome della policy', []),
        fieldValue: undefined,
        fieldIsRequired: false,
        fieldIsDisabled: false,
        fieldPaddingLeft: false,
        fieldPaddingRight: true
      },
      operatorType: "like"
    },
    {
      name: this.tranPipe.transform("policie_table_header_policy_type", "Tipoplogia"),
      dbKey: "policyTypeId",
      hasReorder: true,
      hasFilter: true,
      operatorType: '=',
      filterField: {
        fieldType: 'select',
        fieldControlName: 'policyTypeId',
        fieldBootstrapColumn: 12,
        fieldName: this.tranPipe.transform('policy_table_box_filter_policy_type', 'Tipologia policy', []),
        fieldPlaceholder: this.tranPipe.transform('policy_table_box_filter_policy_type_placeholder', 'Tipologia policy', []),
        fieldValue: undefined,
        fieldIsRequired: false,
        fieldIsDisabled: false,
        fieldPaddingLeft: false,
        fieldPaddingRight: false,
        fieldOptions: [
          { id: 'scrollFunction', value: this.policyTypesScrollFunction },
          { id: 'searchFunction', value: this.policyTypesSearchFunction },
          { id: 'searchByIdFunction', value: this.policyTypesSearchByIdFunction },
          { id: 'refreshFieldNumber', value: 0 }
        ]
      }
    },
    {
      name: this.tranPipe.transform("policie_table_header_policy_page", "Posizione"),
      dbKey: "policyPage",
      hasReorder: true,
      hasFilter: true,
      filterField: {
        fieldType: 'select-local',
        fieldControlName: 'policyPage',
        fieldBootstrapColumn: 6,
        fieldName: this.tranPipe.transform('policy_table_box_filter_blocked', 'Posizione policy', []),
        fieldPlaceholder: this.tranPipe.transform('policy_table_box_filter_blocked_placeholder', 'Posizione policy', []),
        fieldValue: undefined,
        fieldIsRequired: false,
        fieldIsDisabled: false,
        fieldPaddingLeft: false,
        fieldPaddingRight: true,
        fieldOptions: [
          { id: 'isMultiple', value: false },
          { id: 'showArrow', value: true },
          { id: 'enableSearch', value: false },
          {
            id: 'options', value: [
              {
                label: "Footer",
                value: "footer"
              },
              {
                label: "Form Registrazione",
                value: "register"
              },
              {
                label: "Prenotazione Slot",
                value: "booking"
              }
            ]
          }
        ]
      },
      operatorType: "="
    },
    {
      name: this.tranPipe.transform("policy_table_header_policy_title", "Titolo"),
      dbKey: "policyTitle",
      hasReorder: true,
      hasFilter: true,
      filterField: {
        fieldType: 'input-text',
        fieldControlName: 'policyTitle',
        fieldBootstrapColumn: 6,
        fieldName: this.tranPipe.transform('policy_table_box_filter_title', 'Titolo policy', []),
        fieldPlaceholder: this.tranPipe.transform('policy_table_box_filter_title_placeholder', 'Titolo policy', []),
        fieldValue: undefined,
        fieldIsRequired: false,
        fieldIsDisabled: false,
        fieldPaddingLeft: true,
        fieldPaddingRight: false
      },
      operatorType: "like"
    },
    {
      name: this.tranPipe.transform("policy_table_header_value", "Contenuto"),
      hasReorder: false,
      hasFilter: false
    },
    {
      name: this.tranPipe.transform("policy_table_header_policy_is_mandatory", "Obbligatoria"),
      dbKey: "isMandatoryPolicy",
      hasReorder: true,
      hasFilter: true,
      filterField: {
        fieldType: 'select-local',
        fieldControlName: 'isMandatoryPolicy',
        fieldBootstrapColumn: 6,
        fieldName: this.tranPipe.transform('policy_table_box_filter_is_mandatory', 'Obbligatoria', []),
        fieldPlaceholder: this.tranPipe.transform('policy_table_box_filter_is_mandatory_placeholder', 'Policy obbligatoria', []),
        fieldValue: undefined,
        fieldIsRequired: false,
        fieldIsDisabled: false,
        fieldPaddingLeft: false,
        fieldPaddingRight: true,
        fieldOptions: [
          { id: 'isMultiple', value: false },
          { id: 'showArrow', value: true },
          { id: 'enableSearch', value: false },
          {
            id: 'options', value: [
              {
                label: 'Si',
                value: 1
              },
              {
                label: 'No',
                value: 0
              }
            ]
          }
        ]
      },
      operatorType: "="
    },
    {
      name: this.tranPipe.transform("table_header_actions", "Azioni"),
      hasReorder: false,
      hasFilter: false,
      fixedWidth: "180px"
    }
  ]


  refreshFunction = (args: {
    pageNumber: number, pageSize: number, orderColumnName?: string | null, orderType?: string | null,
    tableSearchValues?: Array<PurpleTableSearchGroupGroup> | null, tableFilters?: Array<PurpleTableSelectFilter> | null, switchOn?: boolean,
    culture: string
  }) => {
    args.culture = this.tsvc.translationLanguage.value;

    args.tableFilters = [
      {
        property: "saleEventId",
        filterValue: this.saleEvent?.saleEventId ?? Guid.empty()
      }
    ];


    return this.admSvc.getSaleEventPoliciesTable(args.culture, args);
  }

  ngOnDestroy(): void {
    this.sub.next();
    this.sub.complete();
    this.dashSvc.clearPageHeader();
  }

  ngOnInit() {
    this.seSvc.currentSaleEvent$.pipe(takeUntil(this.sub)).subscribe((se: PRPVSaleEventFullExtended | undefined) => {
      this.saleEvent = se;
      console.log("SETTO LA SALE CORRENTE: ", this.saleEvent?.saleEventId)
      this.showTable = true;
    });

    this.tsvc.translationLanguage.pipe(takeUntil(this.sub)).subscribe(() => {
      this.refreshTableRowsNumber++
    });

    this.route.queryParams.subscribe(async params => {
      console.log("Params: ", params['saleEvent'])
      await this.refreshSale(params['saleEvent']);
    });
  }

  private async refreshSale(seId: string | undefined) {
    const cSeid = seId ?? Guid.empty();

    this.dashSvc.dashBackButtons = [];
    this.dashSvc.addTranslationSelect();

    this.dashSvc.dashMainItems = [];

    if (!cSeid.strEq(Guid.empty())) {
      this.dashSvc.dashMainItems.push(
        new dashboardButton(
          "back-button",
          this.tranPipe.transform("dashboard_private_general_iofo_back_button", "Torna alla lista", []),
          () => { this.router.navigate([this.tsvc.currentLanguage.value, 'dashboard', 'private-sales', 'private-sale-list']) },
          undefined,
          "arrow-left",
          undefined,
          "left"
        )
      )
    }

    this.dashSvc.dashMainItems.push(
      new dashboardButton(
        "add-policy",
        this.tranPipe.transform('private_sale_plocies_add_policy_button', 'Aggiungi Policy', []),
        () => { this.editPolicy(undefined) }))

    console.log("FACCIO REFRESH DELLA SALE")

    await this.dashSvc.refreshSaleEvent(cSeid, "private-sale-policies", this.tranPipe.transform('private_sale_policies_subtitle', 'Policy della sale', []),
      seId == undefined ? this.tranPipe.transform('private_sale_policies_general_page_title', 'Policy', []) : undefined, true);
    this.refreshTableRowsNumber++
  }

  reorderPolicies() {
    this.modal.create<ReorderPoliciesModalComponent, PRPVPolicyFull[]>({
      nzContent: ReorderPoliciesModalComponent,
      nzData: this.listOfData?.map(m => m.item) ?? [],
      nzTitle: this.tranPipe.transform('reorder_policy_new_title', 'Riordinare policy pagina', []),
      nzWidth: '1000px',
      nzClassName: 'purple-modal',
      nzFooter: null,
      nzMaskClosable: false,
    }).afterClose.subscribe(async (refresh: boolean) => {
      if (refresh) {
        this.refreshTableRowsNumber++;
      }
    });
  }

  editPolicy(policy: PRPVPolicyFull | undefined) {
    var hpbp: boolean = false;

    if (policy == undefined) {
      console.log("Lista: ", this.listOfData, " - index:", this.listOfData?.findIndex(f => f.item.policyPage.strEq("booking")))
      hpbp = (this.listOfData?.findIndex(f => f.item.policyPage.strEq("booking")) ?? -1) > -1;
    }

    this.modal.create<EditPolicyModalComponent, { policyFull: PRPVPolicyFull | undefined, saleEventId: string, haveAlreadyBookingPolicy: boolean }>({
      nzContent: EditPolicyModalComponent,
      nzData: {
        policyFull: policy,
        saleEventId: this.saleEvent?.saleEventId ?? Guid.empty(),
        haveAlreadyBookingPolicy: hpbp
      },
      nzTitle: policy == undefined ? this.tranPipe.transform('edit_policy_add_new_title', 'Aggiungi nuova policy', []) : this.tranPipe.transform('edit_policy_edit_new_title', 'Modifica Policy <b>{0}</b>', [policy.policyName]),
      nzWidth: '1000px',
      nzOkText: "Conferma",
      nzClassName: 'purple-modal',
      nzFooter: null,
      nzMaskClosable: false,
    }).afterClose.subscribe(async (refresh: boolean) => {
      if (refresh) {
        this.refreshTableRowsNumber++;
      }
    });
  }

  deletePolicy(policy: PRPVPolicyFull) {
    this.modal.create({
      nzTitle: this.tranPipe.transform("delete_sale_event_policy_title", "Rimuovere {0} dalle policy?", [policy.policyName]),
      nzContent: this.tranPipe.transform("delete_sale_event_policy_subtitle", "<b>{0}</b> verrà rimosso dalle policy", [policy.policyName]),
      nzWidth: '600px',
      nzClassName: 'purple-simple-modal',
      nzOkText: this.tranPipe.transform('delete_sale_event_policy_button', 'Rimuovi', []),
      nzOnOk: async () => {

        await this.apiProxySvc.makeRequestErrorMessage<boolean>(() => this.admSvc.deleteSaleEventPolicy(this.tsvc.currentLanguage.value, {
          saleEventId: policy.saleEventId,
          policyId: policy.policyId
        }), false, "internal-loader", 500, undefined, undefined, (res: PurpleApiMakeRequestResponse<boolean>) => {
          this.mesSvc.success(this.tranPipe.transform("ddelete_sale_event_policy_ok_response", "<b>{0}</b> rimossa dalle policy", [policy.policyName]), {
            nzDuration: environment.MESSAGE_DURATION
          });
          this.modal.closeAll();
          this.refreshTableRowsNumber++;
        })
      }
    })
  }

  getMediaLink(contentId: string, contentTypeId: string): string {
    return `${this.basePath}/${this.tsvc.currentLanguage.value}/medias/getmedia?ContentId=${contentId}&ContentTypeId=${contentTypeId}`
  }


}
