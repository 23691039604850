import { Component, Inject, Input, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { purpleFormFieldsGroup, PurpleApiProxyService, PurpleTranslationPipe, Helper, PurpleSelectFilter, PurpleApiMakeRequestResponse } from 'purple-lib';
import { AppTranslationService } from 'src/core-modules/localization/localization.service';
import { LocalizationService, BackOfficeService, SaleEventLimitedExceptionSaleCategory } from 'src/core-modules/sdk/api';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalRef, NZ_MODAL_DATA } from 'ng-zorro-antd/modal';

import { Subject } from 'rxjs';
import { environment } from 'src/environments/default/environment';

@Component({
  selector: 'app-edit-category-limit-exception-modal',
  templateUrl: './edit-category-limit-exception-modal.component.html',
  styleUrls: ['./edit-category-limit-exception-modal.component.scss']
})
export class EditCategoryLimitExceptionModalComponent implements OnInit {
  /* @Input() categoryExceptionLimitFull: SaleEventLimitedExceptionSaleCategory | undefined = undefined;
  @Input() saleEventId!: string; */

  sub: Subject<void> = new Subject();
  originalCategoryLimit!: EditCategoryExceptionLimit;
  newObj!: EditCategoryExceptionLimit;
  isNew: boolean = false;
  validateForm: FormGroup = this.fb.group({});
  purpleFormFieldGroups: purpleFormFieldsGroup[] | undefined;
  disableForm: boolean = false;
  isLoading: boolean = false;

  constructor(@Inject(NZ_MODAL_DATA) private data: { categoryExceptionLimitFull: SaleEventLimitedExceptionSaleCategory | undefined, saleEventId: string }, private modalRef: NzModalRef, private mesSvc: NzMessageService, private apiProxySvc: PurpleApiProxyService, private admSvc: BackOfficeService,
    private locSvc: LocalizationService, private tsvc: AppTranslationService, private fb: FormBuilder, private tranPipe: PurpleTranslationPipe) { }

  async ngOnInit(): Promise<void> {
    this.originalCategoryLimit = {
      categoryId: this.data.categoryExceptionLimitFull?.saleCategory,
      skipAmount: this.data.categoryExceptionLimitFull?.skipAmount ?? false,
      skipCount: this.data.categoryExceptionLimitFull?.skipCount ?? false
    }

    if (this.data.categoryExceptionLimitFull == undefined) {
      this.isNew = true;
    }

    this.newObj = Helper.storeNewObj(this.originalCategoryLimit);

    this.purpleFormFieldGroups = [
      {
        fieldGroupNumber: 1,
        fielGroupBootstrapColumn: 12,
        fieldGroupPaddingLeft: false,
        fieldGroupPaddingRight: false,
        formFieldFormGroup: this.validateForm,
        formFields: [
          {
            fieldType: 'select',
            fieldControlName: 'category',
            fieldBootstrapColumn: 12,
            fieldName: this.tranPipe.transform('edit_category_limit_category', 'Categoria', []),
            fieldPlaceholder: this.tranPipe.transform('edit_category_limit_category_placeholder', 'Seleziona la categoria', []),
            fieldValue: this.newObj.categoryId,
            fieldIsRequired: true,
            fieldIsDisabled: false,
            fieldPaddingLeft: false,
            fieldPaddingRight: false,
            fieldOptions: [
              { id: 'scrollFunction', value: this.categorysScrollFunction },
              { id: 'searchFunction', value: this.categorysSearchFunction },
              { id: 'searchByIdFunction', value: this.categorysSearchByIdFunction },
              { id: 'refreshFieldNumber', value: 0 },
            ]
          },
          {
            fieldType: 'toggle',
            fieldControlName: 'skipAmount',
            fieldBootstrapColumn: 12,
            fieldName: this.tranPipe.transform('edit_category_exception_limit_skip_amount', 'Disabilita limiti quantità', []),
            fieldPlaceholder: this.tranPipe.transform('edit_category_exception_limit_skip_amount_placeholder', 'Disabilita limiti quantità', []),
            fieldValue: this.newObj.skipAmount,
            fieldIsRequired: false,
            fieldIsDisabled: false,
            fieldPaddingLeft: false,
            fieldPaddingRight: false,
            fieldOptions: []
          },
          {
            fieldType: 'toggle',
            fieldControlName: 'skipCount',
            fieldBootstrapColumn: 12,
            fieldName: this.tranPipe.transform('edit_category_exception_limit_skip_count', 'Disabilita limiti importo', []),
            fieldPlaceholder: this.tranPipe.transform('edit_category_exception_limit_skip_count_placeholder', 'Disabilita limiti importo', []),
            fieldValue: this.newObj.skipCount,
            fieldIsRequired: false,
            fieldIsDisabled: false,
            fieldPaddingLeft: false,
            fieldPaddingRight: false,
            fieldOptions: []
          }
        ]
      }
    ]
  }


  async editCategoryExceptionLimit() {
    this.isLoading = true;
    for (const i in this.validateForm.controls) {
      this.validateForm.controls[i].markAsDirty();
      this.validateForm.controls[i].updateValueAndValidity();
    }
    if (this.validateForm.valid) {
      await this.apiProxySvc.makeRequestErrorMessage<boolean>(() => this.admSvc.addOrUpdateSaleEventCategoryExceptionLimit(this.tsvc.currentLanguage.value, {
        saleCategory: this.validateForm.controls['category'].value,
        skipAmount: this.validateForm.controls['skipAmount'].value,
        skipCount: this.validateForm.controls['skipCount'].value,
        isNew: this.isNew,
        saleEventId: this.data.saleEventId
      }), false, "internal-loader", 500, undefined, undefined, (res: PurpleApiMakeRequestResponse<boolean>) => {
        let text: string;
        if (this.isNew) {
          text = this.tranPipe.transform('edit_limit_category_exception_add_success', 'Eccezione limite category aggiunto con successo', [])
        } else {
          text = this.tranPipe.transform('edit_limit_category_exception_update_success', 'Eccezione limite category aggiornato con successo', [])
        }
        this.mesSvc.success(text, {
          nzDuration: environment.MESSAGE_DURATION
        });
        this.modalRef.destroy(res.data);
      })

    }
    this.isLoading = false
  }

  //ScrollFunction
  categorysScrollFunction = (args: { pageNumber: number, pageSize: number, culture: string, searchName: string, searchFilters: PurpleSelectFilter[] }) => {
    args.searchFilters = [
      { propertyName: "saleEventId", filterValues: [this.data.saleEventId], filterOperator:"=" }
    ]
    return this.admSvc.getSaleEventItemsCategoriesSelect(args.culture, args)
  }
  //SearchFunction
  categorysSearchFunction = (args: { pageNumber: number, pageSize: number, culture: string, searchName: string, searchFilters: PurpleSelectFilter[] }) => {
    args.searchFilters = [
      { propertyName: "saleEventId", filterValues: [this.data.saleEventId], filterOperator:"=" }
    ]
    return this.admSvc.getSaleEventItemsCategoriesByNameSelect(args.culture, args)
  }
  //InitFunction (SearchByIdFunction)
  categorysSearchByIdFunction = (args: { pageNumber: number, pageSize: number, culture: string, searchName: string, searchFilters: PurpleSelectFilter[] }) => {
    return this.admSvc.getSaleEventItemsCategoriesByIdSelect(args.culture, args)
  }

}


interface EditCategoryExceptionLimit {
  categoryId: string | undefined;
  skipCount: boolean;
  skipAmount: boolean;
}