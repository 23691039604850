export * from './backOffice.service';
import { BackOfficeService } from './backOffice.service';
export * from './booking.service';
import { BookingService } from './booking.service';
export * from './localization.service';
import { LocalizationService } from './localization.service';
export * from './mailChimp.service';
import { MailChimpService } from './mailChimp.service';
export * from './medias.service';
import { MediasService } from './medias.service';
export * from './membershipManager.service';
import { MembershipManagerService } from './membershipManager.service';
export * from './stripo.service';
import { StripoService } from './stripo.service';
export * from './transactions.service';
import { TransactionsService } from './transactions.service';
export * from './users.service';
import { UsersService } from './users.service';
export const APIS = [BackOfficeService, BookingService, LocalizationService, MailChimpService, MediasService, MembershipManagerService, StripoService, TransactionsService, UsersService];
