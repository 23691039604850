<div class="ps-d2-main-layout" (window:resize)="onResize()">

  <!----------------------------------------------- SIDE MENU COMPONENT ----------------------------------------------------------------->
  <app-purple-layout-left-side [activeMenus]="activeMenus" [(isMenuCollapsed)]="dashSvc.isMenuCollapsed"
    [menuSections]="dashSvc.currentMenuSection" [(isUserMenuOpen)]="dashSvc.isUserMenuOpen"
    [userEmail]="authSvc.currentUser!.user!.email" [userFirstName]="authSvc.currentUser!.user!.firstName"
    [userLastName]="authSvc.currentUser!.user!.lastName" [isPurpleAdmin]="authSvc.isPurpleAdminUser()"
    [softwareVersion]="dashSvc.softwareVersion" [currentMenu]="dashSvc.currentMenu" [currentSubMenu]="dashSvc.currentSubMenu" 
    [countrySelectEnabled]="true" [countryFlag]="countryFlag" (changeCountry)="dashSvc.changeCountry(false)"
    (clickLogout)="dashSvc.logout(false)">
  </app-purple-layout-left-side>
  <!---------------------------------------------------------------------------------------------------------------------------------->


  <div class="ps-d2-main-container {{dashSvc.isMenuCollapsed?'ps-menu-collapsed':'ps-menu-not-collapsed'}} ps-d-block ps-w-100">

    <!----------------------------------------------- HEADER COMPONENT --------------------------------------------------------------->
    <app-purple-layout-header 
    [isMenuCollapsed]="dashSvc.isMenuCollapsed" 
    [dashBackButtons]="dashSvc.dashBackButtons" 
    [dashMainItems]="dashSvc.dashMainItems" 
    [pageTitle]="pgTitle"
    [pageSubTitle]="pgSubTitle"
    class="custom-header"
    ></app-purple-layout-header>
    <!-------------------------------------------------------------------------------------------------------------------------------->

    <div class="ps-d2-content" (click)="contentClick()">
      <purple-loader class="internal-loader {{dashSvc.isMenuCollapsed?'ps-d2-menu-collapsed':''}}" height="250px"
        [isGlobal]="true" loaderPath="/assets/loaders/internal-loader.json" loaderId="internal-loader">
      </purple-loader>

      <purple-500-error errorPageId="internal-error"
        customClass="error-page-internal-custom {{dashSvc.isMenuCollapsed?'ps-d2-menu-collapsed':'ps-d2-menu-not-collapsed'}}"
        [isGlobal]="true">
      </purple-500-error>
      <router-outlet></router-outlet>
    </div>
  </div>
</div>