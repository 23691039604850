<ng-container *ngIf="dashSvc.dashboardHeight != undefined">
  <purple-table #purpleTable [tableBody]="tableBody" [isBordered]="true" [switchOn]="false"
    [refreshFunction]="refreshFunction" (tableData)="listOfData = $event" [tableColumns]="tabColumns"
    [tableScroll]="{y:dashSvc.dashboardHeight}" [refreshTableRowsNumber]="refreshTableRowsNumber"[version]="2" 
    [showCustomPagination]="true" >

    <ng-template #tableBody>
      <ng-container *ngFor="let data of listOfData; let i = index">
        <tr class="{{i%2==0?'main-purple-table-tr-even': 'main-purple-table-tr-odd'}} ps-cursor-pointer"
        [nzTooltipTitle]='"table_private_sale_click_to_open_sale_detail" | translation: "Click per mostrare dettaglio"' nz-tooltip 
        (click)="saleEventClick(data.item)">
          <td nzAlign="center">
            <nz-tag [nzColor]="getSaleEventState(data.item).color">
              {{getSaleEventState(data.item).text}}
            </nz-tag>
          </td>
          <td>{{ data.item.saleEventName }}</td>
          <td>{{ data.item.addressFull }}</td>
          <td>{{ data.item.publicOpenDate | date: "shortDate" }} - {{ data.item.publicCloseDate | date: "shortDate" }}</td>
          <td>{{getSaleEventOnlineStatus(data.item)}}</td>
        </tr>
      </ng-container>
    </ng-template>
  </purple-table>
</ng-container>










