<div class="ps-w-100 ps-d-flex ps-flex-wrap clone-day-slots-container">
  <div class="ps-d-flex ps-flex-wrap ps-w-100 ps-align-items-center clone-day-slots-title-container">
    <h3 class="clone-day-slots-title">
      {{ 'clone_day_slots_clone_day_title' | translation: 'Clona giorno': []}}
    </h3>
    <h1 class="clone-day-slots-day-title">
      {{daySlots.dateDay | date: "fullDate"}}
    </h1>
  </div>
  <button nz-button class="close-button" (click)="closeModal()">
    <span nz-icon nzType="close"></span>
  </button>
  <div class="line"></div>
  <nz-spin class="ps-w-100 spinner" [nzSpinning]="isLoading" [nzIndicator]="purpleModalLoaderTemplate">
    <div class="ps-w-100 ps-d-flex ps-flex-wrap general-container">
      <div class="ps-d-flex ps-flex-wrap clone-from-day-preview-container ps-align-items-start">
        <div class="ps-w-100 ps-d-flex ps-flex-wrap">
          <p class="clone-from-title ps-w-100">{{ 'clone_day_slots_clone_from_title' | translation: 'Informazioni Slot':
            []}}</p>
          <div *ngFor="let slot of daySlots.slots" class="ps-d-flex ps-flex-wrap clone-from-slot-hour-container">
            <p class="hour-label ps-w-100">{{slot.slotStartDate | date: "shortTime"}} - {{slot.slotEndDate | date:
              "shortTime"}}</p>
            <div class="ps-w-100 ps-d-flex ps-align-items-center">
              <container-element [ngSwitch]="slot.isMixedRoleEnabled">
                <ng-container *ngSwitchCase="true">
                  <div class="role-count-container ps-w-100 ps-d-flex ps-align-items-center">
                    <p class="role-label">{{ 'clone_day_slots_clone_day_all_roles' | translation: 'Tutti i ruoli': []}}:
                    </p>
                    <p class="count-label">{{getSlotCapacityLabel(slot.maxMixedRoleAttendees)}}</p>
                  </div>
                </ng-container>
                <ng-container *ngSwitchCase="false">
                  <div *ngFor="let role of slot.roles"
                    class="role-count-container ps-w-100 ps-d-flex ps-align-items-center">
                    <p class="role-label">{{role.roleName}}:</p>
                    <p class="count-label">{{getSlotCapacityLabel(role.slotTotalCapacity??-1)}}</p>
                  </div>
                </ng-container>
              </container-element>
            </div>
          </div>
        </div>
      </div>
      <div class="ps-d-flex ps-flex-wrap clone-to-day-slots-container ps-align-items-start">
        <div class="ps-w-100 ps-d-flex ps-flex-wrap ps-h-100">
          <p class="clone-to-slots-title ps-w-100">
            {{ 'clone_day_slots_clone_to_days_title' | translation: 'Giorni Disponibili': []}}</p>
          <p class="clone-to-slots-subtitle ps-w-100" [innerHTML]="'clone_day_slots_clone_to_days_subtitle' | translation: 'Seleziona i giorni sui quali vuoi replicare gli slot del giorno \<b>{0}\</b>': 
            [datePipe.transform(daySlots.dateDay, 'fullDate')??'']"></p>
          <div class="selected-days-container ps-w-100">
            <p class="selected-day-title">{{ 'clone_day_slots_clone_selected_days' | translation: 'Giorni selezionati':
              []}}:
              <span *ngFor="let day of getSelectedDays(); let i = index" class="selected-day-value">
                <ng-container *ngIf="i!=0"> - </ng-container>
                {{day.date | date: "shortDate"}}
              </span>
            </p>
          </div>
          <div class="ps-w-100 ps-d-flex ps-flex-wrap ps-align-items-start clone-to-day-slot-days-container">
            <div class="ps-w-100 ps-d-flex ps-flex-wrap">

              <div *ngFor="let day of days" class="clone-day {{day.isSelected?'selected':''}}"
                (click)="day.isSelected = !day.isSelected">
                <div class="ps-d-flex ps-flex-wrap ps-justify-content-center">
                  <p class="clone-day-label-day-number ps-text-center">{{day.date | date:"dd" }}</p>
                  <p class="clone-day-label-month ps-m-0 ps-text-center">{{day.date | date:"MMMM" }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </nz-spin>
  <div class="purple-button-container edit-day-slot-buttons-container ps-w-100 ps-d-flex ps-flex-wrap">
    <div class="ps-d-flex ps-flex-wrap ps-justify-content-center">
      <button (click)="cloneDaySlots()" nz-button class="purple-modal-button" [disabled]="isLoading"
        [nzLoading]="isLoading">
        <span class="button-icon" nz-icon nzType="copy"></span>
        {{ 'clone_day_slots_copy_button' | translation: 'Copia Slots': []}}
      </button>
    </div>

  </div>
  <ng-template #purpleModalLoaderTemplate>
    <div class="purple-modal-custom-spinner">
      <span nz-icon nzType="loading"></span>
    </div>
  </ng-template>
</div>