import { Component, Inject, Input, OnInit } from '@angular/core';
import { NzModalRef, NZ_MODAL_DATA } from 'ng-zorro-antd/modal';
import { NzMessageService } from 'ng-zorro-antd/message';
import { PurpleApiMakeRequestResponse, PurpleApiProxyService, PurpleTranslationPipe } from 'purple-lib';
import { differenceInCalendarDays } from 'date-fns';
import { ApiUpdateSaleEventOnlineStatusRequest, BackOfficeService } from 'src/core-modules/sdk/api';
import { AppTranslationService } from 'src/core-modules/localization/localization.service';
import { environment } from 'src/environments/default/environment';

@Component({
  selector: 'app-edit-online-status',
  templateUrl: './edit-online-status-modal.component.html',
  styleUrls: ['./edit-online-status-modal.component.scss']
})
export class EditOnlineStatusModalComponent implements OnInit {

  constructor(@Inject(NZ_MODAL_DATA) private data: { currentStatusInfo: {statusName: string; statusColor: string; statusId: number; },  publicOpenDate: Date | undefined, currentNextDate: Date | undefined, saleEventId: string }, private modalRef: NzModalRef, private tranPipe: PurpleTranslationPipe, private apiProxySvc: PurpleApiProxyService, private admSvc: BackOfficeService, 
    private tsvc: AppTranslationService, private mesSvc: NzMessageService) { }
  
  selectedStatus: number = 0;
  isLoading: boolean = false;
  nextDate: Date | undefined = undefined;

  ngOnInit() {
    this.selectedStatus = this.data.currentStatusInfo.statusId;
    this.nextDate = this.data.currentNextDate;
  }

  setCurrentStatus(statusId: number) {
    if (statusId != this.selectedStatus) {
      this.selectedStatus = statusId;

      this.modalRef.getElement().getElementsByClassName("ant-modal-title")[0].firstElementChild!.innerHTML =
        this.tranPipe.transform("edit_sale_event_online_status_title", "Stato Private Sale: <b>{0}</b>", [
          this.getSaleOnlineStateName(statusId).toUpperCase()
        ])
    }

  }

  private getSaleOnlineStateName(statusId: number): string {
    switch (statusId) {
      case 0:
        return this.tranPipe.transform('tag_sale_not_online', 'offline')
      case 1:
        return this.tranPipe.transform('tag_sale_show_preview', 'preview')
      case 2:
        return this.tranPipe.transform('tag_sale_is_online', 'online')
      default:
        return "";
    }
  }

  async saveChangeStatus() {
    this.isLoading = true;

    if (this.selectedStatus != this.data.currentStatusInfo.statusId && (this.selectedStatus != 1 || (this.selectedStatus == 1 && this.data.currentNextDate != this.nextDate))) {

      var req: ApiUpdateSaleEventOnlineStatusRequest | undefined = undefined;

      switch (this.selectedStatus) {
        case 0:
          req = {
            isOnline: false,
            showComingSoon: false,
            saleEventId: this.data.saleEventId,
            comingSoonStartDate: null
          }
          break;
        case 1:
          req = {
            isOnline: false,
            showComingSoon: true,
            saleEventId: this.data.saleEventId,
            comingSoonStartDate: this.nextDate!.toIsoStringPurple()
          }
          break;
        case 2:
          req = {
            isOnline: true,
            showComingSoon: false,
            saleEventId: this.data.saleEventId,
            comingSoonStartDate: null
          }
          break;

        default:
          break;
      }

      await this.apiProxySvc.makeRequestErrorMessage<Boolean>(() => this.admSvc.updateSaleEventOnlineStatus(this.tsvc.translationLanguage.value, req), 
      false, "internal-loader", 500, undefined, undefined, (res: PurpleApiMakeRequestResponse<Boolean>) => {
        this.mesSvc.success(this.tranPipe.transform('update_private_sale_online_status_ok','Stato cambiato con successo', []), {
          nzDuration: environment.MESSAGE_DURATION
        });

        this.modalRef.destroy(res.data);
      })

    }

    this.isLoading = false
  }

  disabledPublicDates = (current: Date): boolean => {
    if (differenceInCalendarDays(current, new Date()) < 0) {
      return true;
    }

    if (this.data.publicOpenDate == null || (this.data.publicOpenDate != null && differenceInCalendarDays(current, this.data.publicOpenDate) > 0)) {
      return true
    }

    return false;
  }

}

