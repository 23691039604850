import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-summary-stats-item',
  templateUrl: './summary-stats-item.component.html',
  styleUrls: ['./summary-stats-item.component.scss']
})
export class SummaryStatsItemComponent implements OnInit {

  constructor() { }
  @Input() title!: string;

  @Input() mainValueTitle!: string;
  @Input() mainValue!: string; 

  @Input() showSecondaryValue: boolean = false;
  @Input() secondaryValueTitle: string | undefined | null;
  @Input() secondaryValue: string | undefined | null;

  @Input() valueCustomColor: string | undefined | null = "#475467";
  @Input() otherValue!: string;
  @Input() otherValueText!: string;
  @Input() otherValueIcon: string | undefined | null;

  @Input() imageUrl: string | undefined | null;

  ngOnInit() {
  }

}
