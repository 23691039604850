import { Component, Inject, LOCALE_ID, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Helper, PurpleApiMakeRequestResponse, PurpleApiProxyService, PurpleSelectFilter, PurpleTranslationPipe, dashboardButton, dashboardTag, purpleFormField, purpleFormFieldsGroup } from 'purple-lib';
import { Subject, interval, takeUntil, throttle } from 'rxjs';
import { DashboardLayout2Service } from 'src/app/dashboard/dashboard-layout-2/dashboard-layout-2.service';
import { AppTranslationService } from 'src/core-modules/localization/localization.service';
import { Address, AngularSummaryStatsItem, BackOfficeService, BookingService, Owner, PRPVSaleEventFullExtended, PRPVSaleEventFullNullable, SaleEventAttributeValuesItem, SaleEventDescriptions } from 'src/core-modules/sdk/api';
import { PrivateSaleService } from '../../private-sale-service.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

import { FormatWidth, getLocaleDateFormat } from '@angular/common';
import { differenceInCalendarDays } from 'date-fns';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NzMessageService } from 'ng-zorro-antd/message';
import { EditOnlineStatusModalComponent } from '../modals/edit-online-status-modal/edit-online-status-modal.component';
import { environment } from 'src/environments/default/environment';

@Component({
  selector: 'app-private-sale-general-info',
  templateUrl: './private-sale-general-info.component.html',
  styleUrls: ['./private-sale-general-info.component.scss']
})
export class PrivateSaleGeneralInfoComponent implements OnInit, OnDestroy {

  constructor(@Inject(LOCALE_ID) private locale: string, private tranPipe: PurpleTranslationPipe, private dashSvc: DashboardLayout2Service, private route: ActivatedRoute, private modal: NzModalService,
    private admSvc: BackOfficeService, private apiProxySvc: PurpleApiProxyService, public tsvc: AppTranslationService, private seSvc: PrivateSaleService, private notification: NzNotificationService,
    private router: Router, private fb: FormBuilder, private bookSvc: BookingService, private mesSvc: NzMessageService) { }

  sub: Subject<void> = new Subject();
  saleEvent!: PRPVSaleEventFullExtended | undefined;
  saleEventId!: string;
  saleStats: AngularSummaryStatsItem[] | undefined;

  validateForm: FormGroup = this.fb.group({});

  salePurpleFormFieldGroups: editSaleFormItem[] = [];

  disableForm: boolean = false;
  isLoading: boolean = false;
  phonePrefixes: { label: string, value: string }[] = Helper.getPhonePrefixes().map(m => { return { label: m, value: m } });
  currencies: { label: string, value: string }[] = Helper.getCurrencies().map(m => { return { label: m.EnglishName + " (" + m.CurrencySymbol + ")", value: m.IsoSymbol } });

  dateFormat = getLocaleDateFormat(this.locale, FormatWidth.Medium);
  pubDate: Date[] | null = null;
  staffDate: Date[] | null = null;
  ownerIds: string[] = [];

  alreadyPipeForm: boolean = false;

  currentChanged: any | undefined;

  ngOnInit() {
    //this.dashSvc.setCurrentMenu("sale");
    //console.log("MEnu after navigation: ", this.dashSvc.currentMenuSection)

    setTimeout(() => {
      this.seSvc.currentSaleEvent$.pipe(takeUntil(this.sub)).subscribe((se: PRPVSaleEventFullExtended | undefined) => {
        //console.log("E' CAMBIATA LA SALE: ", se)
        this.saleEvent = se;

        if (se != undefined) {
          this.onSaleChange();

        }
      });
    }, 100);


    this.tsvc.translationLanguage.pipe(takeUntil(this.sub)).subscribe(async () => {
      if (this.saleEvent != undefined) {
        //this.salePurpleFormFieldGroups.find(f => f.formSectionId.strEq("description"))!.isSpinnerVisible = true;

        await this.apiProxySvc.makeRequestErrorMessage<SaleEventDescriptions>(() =>
          this.admSvc.getSaleEventDescriptions(this.tsvc.translationLanguage.value, {
            saleEventId: this.saleEventId
          }), false, "internal-loader", 0, this.tranPipe.transform('sale_event_descriptions_not_available', 'Descrizioni non disponibili', []), 500, (res: PurpleApiMakeRequestResponse<SaleEventDescriptions>) => {
            //console.log("CAMBIO LINGUA DESCRIZIONE: ", res.data?.languageId)
            if (!this.validateForm.contains("description")) {
              this.validateForm.addControl("description", new FormControl({ value: res.data?.saleEventDescription, disabled: false }))
            }
            else {
              this.validateForm.controls["description"].setValue(res.data?.saleEventDescription)
            }

            if (!this.validateForm.contains("descriptionDetail")) {
              this.validateForm.addControl("descriptionDetail", new FormControl({ value: res.data?.saleEventDetailDescription ?? "", disabled: false }))
            }
            else {
              this.validateForm.controls["descriptionDetail"].setValue(res.data?.saleEventDetailDescription ?? "")
            }
          })

        setTimeout(() => {
          this.salePurpleFormFieldGroups.find(f => f.formSectionId.strEq("description"))!.isSpinnerVisible = false;
        }, 200);
      }

    });

    this.route.queryParams.subscribe(async params => {
      //console.log("ROUTER QUERY PARAMS: ", params['saleEvent'])
      this.saleEventId = params['saleEvent'];
      this.dashSvc.dashBackButtons?.push(
        new dashboardButton(
          "back-button",
          this.tranPipe.transform("dashboard_private_general_iofo_back_button", "Torna alla lista", []),
          () => { this.router.navigate([this.tsvc.currentLanguage.value, 'dashboard', 'private-sales', 'private-sale-list']) },
          undefined,
          "arrow-left",
          undefined,
          "left"
        )
      );

      await this.refreshSale();
    });
  }

  getSpinnerStatus(sectionId: string): boolean {
    return this.salePurpleFormFieldGroups.find(f => f.formSectionId.strEq(sectionId))?.isSpinnerVisible ?? false;
  }

  async onSaleChange() {
    this.addSaleTags();
    this.notOffline();
    //console.log("CAMBIA LA SALE")
    //this.refreshSaleManu();

    await this.apiProxySvc.makeRequestErrorMessage<Owner[]>(() =>
      this.admSvc.getSaleEventOwnerFilters(this.tsvc.currentLanguage.value, {
        saleEventId: this.saleEventId
      }), true, "internal-loader", 500, this.tranPipe.transform('sale_event_owners_not_available', 'Owners non disponibili', []), 5000, (res: PurpleApiMakeRequestResponse<Owner[]>) => {
        this.ownerIds = res.data!.map(m => m.ownerId);
      });

    this.setForms();

    await this.apiProxySvc.makeRequestErrorMessage<AngularSummaryStatsItem[]>(() =>
      this.admSvc.getSaleEventBookingsStats(this.tsvc.currentLanguage.value, {
        saleEventId: this.saleEventId
      }), true, "internal-loader", 500, this.tranPipe.transform('sale_event_stats_not_available', 'Statistiche non disponibili', []), 500, (res: PurpleApiMakeRequestResponse<AngularSummaryStatsItem[]>) => {
        this.saleStats = res.data;
      })
  }

  changeSaleDescriptionLanguage(languageId: string) {
    this.tsvc.changeTranslationLanguage(languageId);
  }

  private async refreshSale() {
    await this.dashSvc.refreshSaleEvent(this.saleEventId, "private-sale-general-info", 'Informazioni generali della sale', undefined, true);
  }

  private notOffline() {
    if (!(this.saleEvent?.isOnline ?? false)) {
      this.notification.error(this.tranPipe.transform('private_sale_is_offline_message_title', 'Sale Offline', []),
        this.tranPipe.transform('private_sale_is_offline_message_subtitle', 'La private sale <b>non è</b> attualmente visibile sulla piattafrom', []), {
        nzKey: 'download-pdf',
        nzDuration: 5000
      });
    }

  }

  private getSaleOnlineState(): { statusName: string, statusColor: string, statusId: number } {
    if (this.saleEvent == undefined) {
      return { statusName: this.tranPipe.transform('tag_sale_not_online', 'offline'), statusColor: "red", statusId: 0 };
    } else {
      if (this.saleEvent.isOnline) {
        return { statusName: this.tranPipe.transform('tag_sale_is_online', 'online'), statusColor: "green", statusId: 2 };
      }
      else {
        if (this.saleEvent.showComingSoon) {
          return { statusName: this.tranPipe.transform('tag_sale_show_preview', 'preview'), statusColor: "orange", statusId: 1 };
        }
        else {
          return { statusName: this.tranPipe.transform('tag_sale_not_online', 'offline'), statusColor: "red", statusId: 0 };
        }
      }
    }
  }

  private addSaleTags() {
    this.dashSvc.dashMainItems = [];
    this.dashSvc.dashMainItems?.push(
      new dashboardTag(
        "tag-online",
        this.getSaleOnlineState().statusName,
        true,
        this.getSaleOnlineState().statusColor,
        () => {
          this.modal.create<EditOnlineStatusModalComponent, { currentStatusInfo: { statusName: string; statusColor: string; statusId: number; }, publicOpenDate: Date | undefined, currentNextDate: Date | undefined, saleEventId: string }>({
            nzTitle: this.tranPipe.transform("edit_sale_event_online_status_title", "Stato Private Sale: <b>{0}</b>", [
              this.getSaleOnlineState().statusName.toUpperCase()
            ]),
            nzContent: EditOnlineStatusModalComponent,
            nzWidth: '800px',
            nzClassName: 'purple-modal edit-sale-event-online-status-modal',
            nzFooter: null,
            nzData: {
              currentStatusInfo: this.getSaleOnlineState(),
              publicOpenDate: this.pubDate != null ? this.pubDate[1] : undefined,
              currentNextDate: (this.saleEvent?.comingSoonStartDate ?? undefined) != undefined ? new Date(Date.parse(this.saleEvent!.comingSoonStartDate!)) : undefined,
              saleEventId: this.saleEvent!.saleEventId
            },
            nzMaskClosable: false,
          }).afterClose.pipe(takeUntil(this.sub)).subscribe(async (refresh: boolean | undefined) => {
            if (refresh ?? false) {
              await this.refreshSale();
            }
          });;
        }
      ),
      new dashboardTag(
        "tag-type",
        (this.saleEvent?.isPrivateInviteCounter ? this.tranPipe.transform('tag_sale_is_private', 'privata') : this.tranPipe.transform('tag_sale_not_private', 'pubblica')),
        false,
        (this.saleEvent?.isPrivateInviteCounter ? "blue" : "blue")
      )
    );
  }

  private getDateRange(startDate: string | undefined, endDate: string | undefined): Date[] {
    if (startDate != undefined && endDate != undefined) {
      return [new Date(Date.parse(startDate)), new Date(Date.parse(endDate))]
    }

    return [];
  }

  private getEditDescriptionFormField(descType?: string | undefined, descValue?: string | undefined): purpleFormField {
    if (descType == undefined) {
      descType = this.saleEvent?.saleEventDetailLayout ?? "base"
    }

    if (descType.strEq("base")) {
      if (descValue == undefined) {
        descValue = this.saleEvent?.saleEventDescription ?? undefined
      }

      return {
        fieldType: 'textarea',
        fieldControlName: 'description',
        fieldBootstrapColumn: 12,
        fieldName: this.tranPipe.transform('edit_sale_event_description_text', 'Descrizione', []),
        fieldPlaceholder: this.tranPipe.transform('edit_sale_event_description_text_placeholder', 'Descrizione private sale', []),
        fieldValue: descValue,
        fieldIsRequired: false,
        fieldOptions: [{ id: 'rowNumber', value: 8 }],
        fieldPaddingLeft: false,
        fieldPaddingRight: false
      }
    } else {
      if (descValue == undefined) {
        descValue = this.saleEvent?.saleEventDetailDescription ?? ""
      }

      return {
        fieldType: 'ckeditor',
        fieldControlName: 'descriptionDetail',
        fieldBootstrapColumn: 12,
        fieldName: this.tranPipe.transform('edit_sale_event_description_extended', 'Descrizione', []),
        fieldPlaceholder: this.tranPipe.transform('edit_sale_event_description_extended_placeholder', 'Descrizione private sale', []),
        fieldValue: descValue,
        fieldIsRequired: true,
        fieldPaddingLeft: false,
        fieldPaddingRight: false,
        fieldOptions: [
          {
            id: 'config', value: {
              toolbar: {
                items: [
                  'undo', 'redo',
                  '|', 'heading',
                  '|', 'fontsize', 'fontColor', 'fontBackgroundColor',
                  '|', 'bold', 'italic', 'strikethrough', 'subscript', 'superscript', 'code',
                  '|', 'link', 'uploadImage', 'blockQuote', 'codeBlock',
                  '|', 'bulletedList', 'numberedList', 'todoList', 'outdent', 'indent'
                ]
              }
            }
          }
        ]
      }
    }
  }

  private async setForms() {
    //console.log("SETTO FORM FIELD")
    this.staffDate = this.getDateRange(this.saleEvent?.startDate, this.saleEvent?.endDate);
    this.pubDate = this.getDateRange(this.saleEvent?.publicOpenDate, this.saleEvent?.publicCloseDate);

    await this.apiProxySvc.makeRequestErrorMessage<Address>(() => this.bookSvc.getSaleEventAddressByAddressId(this.tsvc.currentLanguage.value, {
      addressId: this.saleEvent!.addressId
    }), true, "internal-loader", 500, undefined, undefined, async (res: PurpleApiMakeRequestResponse<Address>) => {
      this.salePurpleFormFieldGroups =
        [
          {
            formSectionId: "generalInfo",
            formSectionName: this.tranPipe.transform('edit_sale_event_general_info_sale_title', 'Informazioni Generali', []),
            purpleFormGroups: [

              {
                fieldGroupNumber: 1,
                fielGroupBootstrapColumn: 12,
                fieldGroupPaddingLeft: true,
                fieldGroupPaddingRight: true,
                formFieldFormGroup: this.validateForm,
                formFields: [
                  {
                    fieldType: 'input-text',
                    fieldControlName: 'name',
                    fieldBootstrapColumn: 12,
                    fieldName: this.tranPipe.transform('edit_sale_event_general_info_name', 'Nome', []),
                    fieldPlaceholder: this.tranPipe.transform('edit_sale_event_general_infoname_placeholder', 'Nome private sale', []),
                    fieldValue: this.saleEvent?.saleEventName,
                    fieldIsRequired: true,
                    fieldPaddingLeft: false,
                    fieldPaddingRight: false
                  },
                  {
                    fieldType: 'input-text',
                    fieldControlName: 'internalName',
                    fieldBootstrapColumn: 6,
                    fieldName: this.tranPipe.transform('edit_sale_event_name_internal', 'Nome Interno private sale', []),
                    fieldPlaceholder: this.tranPipe.transform('edit_sale_event_name_internal_placeholder', 'Nome interno private sale', []),
                    fieldValue: this.saleEvent?.saleEventInternalName,
                    fieldIsRequired: false,
                    fieldPaddingLeft: false,
                    fieldPaddingRight: true
                  },
                  {
                    fieldType: 'input-text',
                    fieldControlName: 'internalCode',
                    fieldBootstrapColumn: 6,
                    fieldName: this.tranPipe.transform('edit_sale_event_code_internal', 'Codice Interno private sale', []),
                    fieldPlaceholder: this.tranPipe.transform('edit_sale_event_code_internal_placeholder', 'Codice interno private sale', []),
                    fieldValue: this.saleEvent?.saleEventInternalCode,
                    fieldIsRequired: false,
                    fieldPaddingLeft: true,
                    fieldPaddingRight: false
                  },
                  {
                    fieldType: 'input-text',
                    fieldControlName: 'url',
                    fieldBootstrapColumn: 12,
                    fieldName: this.tranPipe.transform('edit_sale_event_url', 'Url', []),
                    fieldPlaceholder: this.tranPipe.transform('edit_sale_event_url_placeholder', 'Url private sale', []),
                    fieldValue: this.saleEvent?.saleEventCode,
                    fieldIsRequired: true,
                    fieldPaddingLeft: false,
                    fieldPaddingRight: false
                  },
                  {
                    fieldType: 'select-local',
                    fieldControlName: 'isPrivate',
                    fieldBootstrapColumn: 12,
                    fieldName: this.tranPipe.transform('edit_sale_event_general_info_sale_type', 'Tipologia', []),
                    fieldPlaceholder: this.tranPipe.transform('edit_sale_event_general_info_sale_type_placeholder', 'Tipologia private sale', []),
                    fieldValue: this.saleEvent?.isPrivateInviteCounter,
                    fieldIsRequired: true,
                    fieldOptions: [
                      { id: 'type', value: 'default' },
                      { id: 'showArrow', value: true },
                      {
                        id: 'options', value: [
                          {
                            label: this.tranPipe.transform('sale_type_public', 'Pubblica', []),
                            value: false
                          },
                          {
                            label: this.tranPipe.transform('sale_type_private', 'Privata', []),
                            value: true
                          }
                        ]
                      }
                    ],
                    fieldCustomClass: "ps-pb-0",
                    fieldPaddingLeft: false,
                    fieldPaddingRight: false
                  }
                ]
              }

            ]
          },
          {
            formSectionId: "description",
            formSectionName: this.tranPipe.transform('edit_sale_event_description_title', 'Pagina Dettaglio', []),
            canBeTranslated: true,
            showSpin: true,
            isSpinnerVisible: false,
            purpleFormGroups: [
              {
                fieldGroupNumber: 2,
                fielGroupBootstrapColumn: 12,
                fieldGroupPaddingLeft: true,
                fieldGroupPaddingRight: true,
                formFieldFormGroup: this.validateForm,
                formFields: [
                  {
                    fieldType: 'select-local',
                    fieldControlName: 'descriptionType',
                    fieldBootstrapColumn: 12,
                    fieldName: this.tranPipe.transform('edit_sale_event_description_type', 'Tipologia Descrizione', []),
                    fieldPlaceholder: this.tranPipe.transform('edit_sale_event_description_type_placeholder', 'Tipologia descrizione private sale', []),

                    fieldCustomClass: undefined,
                    fieldOptions: [
                      { id: 'type', value: 'default' },
                      { id: 'enableSearch', value: false },
                      { id: 'showArrow', value: true },
                      {
                        id: 'options', value: [
                          {
                            label: this.tranPipe.transform('sale_type_description_base', 'Base', []),
                            value: "base"
                          },
                          {
                            label: this.tranPipe.transform('sale_type_description_extened', 'Estesa', []),
                            value: "avanzata"
                          }
                        ]
                      }
                    ],
                    fieldValue: this.saleEvent?.saleEventDetailLayout,
                    fieldIsRequired: true,
                    fieldPaddingLeft: false,
                    fieldPaddingRight: false
                  },
                  this.getEditDescriptionFormField(),
                  {
                    fieldType: 'input-text',
                    fieldControlName: 'detailItemsQty',
                    fieldBootstrapColumn: 4,
                    fieldName: this.tranPipe.transform('edit_sale_event_detail_items_qty_title', 'Quantità articoli', []),
                    fieldPlaceholder: this.tranPipe.transform('edit_sale_event_detail_items_qty_placeholder', 'Quantità articoli', []),
                    fieldValue: this.saleEvent?.saleEventDetailItemsQty,
                    fieldIsRequired: false,
                    fieldIsDisabled: false,
                    fieldPaddingLeft: false,
                    fieldPaddingRight: true
                  },
                  {
                    fieldType: 'input-text',
                    fieldControlName: 'detailAvgDiscount',
                    fieldBootstrapColumn: 4,
                    fieldName: this.tranPipe.transform('edit_sale_event_detail_detail_avg_discount_title', 'Sconto medio', []),
                    fieldPlaceholder: this.tranPipe.transform('edit_sale_event_detail_detail_avg_discount_placeholder', 'Sconto medio', []),
                    fieldValue: this.saleEvent?.saleEventDetailAvgDiscount,
                    fieldIsRequired: false,
                    fieldIsDisabled: false,
                    fieldPaddingLeft: true,
                    fieldPaddingRight: true
                  },
                  {
                    fieldType: 'input-text',
                    fieldControlName: 'detailMaxDiscount',
                    fieldBootstrapColumn: 4,
                    fieldName: this.tranPipe.transform('edit_sale_event_detail_detail_max_discount_title', 'Sconto massimo', []),
                    fieldPlaceholder: this.tranPipe.transform('edit_sale_event_detail_detail_max_discount_placeholder', 'Sconto massimo', []),
                    fieldValue: this.saleEvent?.saleEventDetailMaxDiscount,
                    fieldIsRequired: false,
                    fieldIsDisabled: false,
                    fieldPaddingLeft: true,
                    fieldPaddingRight: false
                  }
                ]
              }
            ]
          },
          {
            formSectionId: "contact",
            formSectionName: this.tranPipe.transform('edit_sale_event_contacts_title', 'Informazioni di Contatto', []),
            purpleFormGroups: [
              {
                fieldGroupNumber: 3,
                fielGroupBootstrapColumn: 12,
                fieldGroupPaddingLeft: true,
                fieldGroupPaddingRight: true,
                formFieldFormGroup: this.validateForm,
                formFields: [
                  {
                    fieldType: 'input-text',
                    fieldControlName: 'email',
                    fieldBootstrapColumn: 6,
                    fieldName: this.tranPipe.transform('edit_sale_event_contacts_email', 'Email', []),
                    fieldPlaceholder: this.tranPipe.transform('edit_sale_event_contacts_email_placeholder', 'Email private sale', []),
                    fieldValue: this.saleEvent?.saleEventEmail,
                    fieldIsRequired: true,
                    fieldCustomValidators: [Validators.email],
                    fieldPaddingLeft: false,
                    fieldPaddingRight: true
                  },
                  {
                    fieldType: 'input-text',
                    fieldControlName: 'phoneNumber',
                    fieldBootstrapColumn: 6,
                    fieldName: this.tranPipe.transform('edit_sale_event_contacts_phone', 'Telefono', []),
                    fieldPlaceholder: this.tranPipe.transform('edit_sale_event_contacts_phone_placeholder', 'Telefono Utente', []),
                    fieldValue: this.saleEvent?.saleEventPhone,
                    fieldIsRequired: false,
                    fieldAffix: {
                      fieldControlName: "phonePrefix",
                      fieldPlaceholder: this.tranPipe.transform('edit_sale_event_contacts_phone_prefix_placeholder', 'Pref.', []),
                      fieldType: "select-local",
                      fieldWidth: "90px",
                      fieldIsRequired: true,
                      fieldValue: this.saleEvent?.saleEventPhonePrefix,
                      fieldOptions: [
                        { id: 'type', value: 'default' },
                        { id: 'showArrow', value: true },
                        {
                          id: 'options', value: this.phonePrefixes
                        }
                      ]
                    },
                    fieldPaddingLeft: true,
                    fieldPaddingRight: false
                  },
                  {
                    fieldType: 'select',
                    fieldControlName: 'address',
                    fieldBootstrapColumn: 12,
                    fieldName: this.tranPipe.transform('edit_sale_event_contacts_address', 'Indirizzo (Google)', []),
                    fieldPlaceholder: this.tranPipe.transform('edit_sale_event_contacts_placeholder', 'Indirizzo private sale', []),
                    fieldOptions: [
                      { id: 'searchFunction', value: this.addressSearchNameFunction },
                      { id: 'searchByIdFunction', value: this.addressSearchIdFunction },
                      { id: 'refreshFieldNumber', value: 0 }
                    ],
                    fieldValue: res.data?.addressExternalId,
                    fieldIsRequired: true,
                    fieldPaddingLeft: false,
                    fieldPaddingRight: false
                  },
                  {
                    fieldType: 'input-text',
                    fieldControlName: 'addressFull',
                    fieldBootstrapColumn: 12,
                    fieldName: this.tranPipe.transform('edit_sale_event_contacts_address_full', 'Indirizzo (Mostrato)', []),
                    fieldPlaceholder: this.tranPipe.transform('edit_sale_event_contacts_address_full_placeholder', 'Indirizzo private sale', []),
                    fieldValue: res.data?.addressFull,
                    fieldIsRequired: true,
                    fieldIsDisabled: this.saleEvent?.addressExternalId == undefined || this.saleEvent?.addressExternalId == null,
                    fieldPaddingLeft: false,
                    fieldPaddingRight: false
                  }
                ]
              }
            ]
          },
          {
            formSectionId: "dates",
            formSectionName: this.tranPipe.transform('edit_sale_event_date_title', 'Date della Sale', []),
            purpleFormGroups: [
              {
                fieldGroupNumber: 4,
                fielGroupBootstrapColumn: 12,
                fieldGroupPaddingLeft: true,
                fieldGroupPaddingRight: true,
                formFieldFormGroup: this.validateForm,
                formFields: [
                  {
                    fieldType: 'date-range',
                    fieldControlName: 'staffDate',
                    fieldBootstrapColumn: 12,
                    fieldName: this.tranPipe.transform('edit_sale_event_date_staff', 'Date Staff', []),
                    fieldPlaceholder: this.tranPipe.transform('edit_sale_event_date_staff_placeholder', 'Date per lo staff della private sale', []),

                    fieldCustomClass: undefined,
                    fieldOptions: [{ id: 'dateFormat', value: this.dateFormat }],
                    fieldValue: this.staffDate,
                    fieldVerticalSpace: undefined,
                    fieldIsRequired: true,
                    fieldCustomValidators: undefined,
                    fieldPaddingLeft: false,
                    fieldPaddingRight: false
                  },
                  {
                    fieldType: 'date-range',
                    fieldControlName: 'publicDate',
                    fieldBootstrapColumn: 12,
                    fieldName: this.tranPipe.transform('edit_sale_event_date_public', 'Date di apertura al pubblico', []),
                    fieldPlaceholder: this.tranPipe.transform('edit_sale_event_date_public_placeholder', 'Date di apertura al pubblico private sale', []),

                    fieldCustomClass: undefined,
                    fieldOptions: [
                      { id: 'dateFormat', value: this.dateFormat },
                      { id: "disabledDate", value: this.disabledPublicDates }],
                    fieldValue: this.pubDate,
                    fieldVerticalSpace: undefined,
                    fieldIsRequired: true,
                    fieldCustomValidators: undefined,
                    fieldPaddingLeft: false,
                    fieldPaddingRight: false
                  }
                ]
              }
            ]
          },
          {
            formSectionId: "otherInfo",
            formSectionName: this.tranPipe.transform('edit_sale_event_varie_title', 'Altre Impostazioni', []),
            showLine: false,
            purpleFormGroups: [
              {
                fieldGroupNumber: 5,
                fielGroupBootstrapColumn: 12,
                fieldGroupPaddingLeft: true,
                fieldGroupPaddingRight: true,
                formFieldFormGroup: this.validateForm,
                formFields: [
                  /* {
                    fieldType: 'select',
                    fieldControlName: 'currency',
                    fieldBootstrapColumn: 6,
                    fieldName: this.tranPipe.transform('edit_sale_event_other_currency', 'Valuta', []),
                    fieldPlaceholder: this.tranPipe.transform('edit_sale_event_other_currency_placeholder', 'Valuta sale event', []),
                    fieldOptions: [
                      { id: 'scrollFunction', value: this.currencyScrollFunction },
                      { id: 'searchFunction', value: this.currencySearchFunction },
                      { id: 'searchByIdFunction', value: this.currencySearchIdFunction },
                      { id: 'refreshFieldNumber', value: 0 }
                    ],
                    fieldValue: this.saleEvent?.currency,
                    fieldIsRequired: true,
                    fieldPaddingLeft: false,
                    fieldPaddingRight: true
                  }, */
                  {
                    fieldType: 'select-local',
                    fieldControlName: 'currency',
                    fieldBootstrapColumn: 6,
                    fieldName: this.tranPipe.transform('edit_sale_event_other_currency', 'Valuta', []),
                    fieldPlaceholder: this.tranPipe.transform('edit_sale_event_other_currency_placeholder', 'Valuta sale event', []),
                    fieldValue: this.saleEvent?.currency,
                    fieldIsRequired: true,
                    fieldOptions: [
                      { id: 'type', value: 'default' },
                      { id: 'showArrow', value: true },
                      { id: 'options', value: this.currencies }
                    ],
                    fieldPaddingLeft: false,
                    fieldPaddingRight: true
                  },
                  {
                    fieldType: 'input-number',
                    fieldControlName: 'tax',
                    fieldBootstrapColumn: 6,
                    fieldName: this.tranPipe.transform('edit_sale_event_other_tax', 'Percentuale Iva', []),
                    fieldPlaceholder: this.tranPipe.transform('edit_sale_event_other_tax_placeholder', 'Percentuale Iva private sale', []),

                    fieldValue: this.saleEvent?.defaultTax,
                    fieldIsRequired: true,
                    fieldOptions: [{ id: 'minNumber', value: 0 }],
                    fieldPaddingLeft: true,
                    fieldPaddingRight: false
                  },
                  {
                    fieldType: 'select-multiple',
                    fieldControlName: 'owners',
                    fieldBootstrapColumn: 12,
                    fieldName: this.tranPipe.transform('edit_sale_event_other_owners', 'Tag owner abilitati', []),
                    fieldPlaceholder: this.tranPipe.transform('edit_sale_event_other_owners_placeholder', 'Tag owner abilitati sale event', []),
                    fieldOptions: [
                      { id: 'scrollFunction', value: this.ownersScrollFunction },
                      { id: 'searchFunction', value: this.ownersSearchFunction },
                      { id: 'searchByIdFunction', value: this.ownersSearchIdsFunction },
                      { id: 'refreshFieldNumber', value: 0 }
                    ],
                    fieldValue: this.ownerIds,
                    fieldIsRequired: false,
                    fieldPaddingLeft: false,
                    fieldPaddingRight: false
                  },
                  {
                    fieldType: 'toggle',
                    fieldControlName: 'isStatsEnable',
                    fieldBootstrapColumn: 4,
                    fieldName: this.tranPipe.transform('edit_sale_event_other_is_stats_enable', 'Statistiche Abilitate', []),
                    fieldPlaceholder: "",
                    fieldValue: this.saleEvent?.isStatsVisible,
                    fieldIsRequired: false,
                    fieldPaddingLeft: false,
                    fieldPaddingRight: true
                  },
                  {
                    fieldType: 'toggle',
                    fieldControlName: 'isInvoiceNumberingEnabled',
                    fieldBootstrapColumn: 4,
                    fieldName: this.tranPipe.transform('edit_sale_event_other_is_envoice_numbering_enable', 'Gestione enumerazione scontrino', []),
                    fieldPlaceholder: "",
                    fieldValue: this.saleEvent?.isInvoiceNumberingEnabled,
                    fieldIsRequired: false,
                    fieldPaddingLeft: false,
                    fieldPaddingRight: false
                  },
                  {
                    fieldType: 'toggle',
                    fieldControlName: 'isCartDiscountEnabled',
                    fieldBootstrapColumn: 4,
                    fieldName: this.tranPipe.transform('edit_sale_event_other_is_cart_discount_enable', 'Abilita sconto in cassa', []),
                    fieldPlaceholder: "",
                    fieldValue: this.saleEvent?.isCartDiscountEnabled,
                    fieldIsRequired: false,
                    fieldPaddingLeft: true,
                    fieldPaddingRight: false
                  },
                  {
                    fieldType: 'input-number',
                    fieldControlName: 'maxUserCheckins',
                    fieldBootstrapColumn: 6,
                    fieldName: this.tranPipe.transform('edit_sale_event_other_max_user_checkins', 'Numero massimo check-in utente', []),
                    fieldPlaceholder: this.tranPipe.transform('edit_sale_event_other_max_user_checkins_placeholder', 'Numero massimo check-in utente', []),
                    fieldValue: this.saleEvent?.maxUserCheckins,
                    fieldIsRequired: true,
                    fieldOptions: [{ id: 'minNumber', value: 0 }],
                    fieldPaddingLeft: false,
                    fieldPaddingRight: true
                  },
                  {
                    fieldType: 'input-number',
                    fieldControlName: 'maxUserTransactions',
                    fieldBootstrapColumn: 6,
                    fieldName: this.tranPipe.transform('edit_sale_event_other_max_user_transactions', 'Numero massimo transazioni utente', []),
                    fieldPlaceholder: this.tranPipe.transform('edit_sale_event_other_max_user_transactions_placeholder', 'Numero massimo transazioni utente', []),
                    fieldValue: this.saleEvent?.maxUserTransactions,
                    fieldIsRequired: true,
                    fieldOptions: [{ id: 'minNumber', value: 0 }],
                    fieldPaddingLeft: true,
                    fieldPaddingRight: false
                  },
                  {
                    fieldType: 'toggle',
                    fieldControlName: 'canBookOnlyOnce',
                    fieldBootstrapColumn: 4,
                    fieldName: this.tranPipe.transform('edit_sale_event_other_can_book_only_once', 'Prenotazione singola', []),
                    fieldPlaceholder: "",
                    fieldValue: this.saleEvent?.canBookOnlyOnce,
                    fieldIsRequired: false,
                    fieldPaddingLeft: false,
                    fieldPaddingRight: true
                  },
                  {
                    fieldType: 'toggle',
                    fieldControlName: 'isWareHouse',
                    fieldBootstrapColumn: 4,
                    fieldName: this.tranPipe.transform('edit_sale_event_other_is_warehouse', 'Magazzino', []),
                    fieldPlaceholder: "",
                    fieldValue: this.saleEvent?.isWareHouse,
                    fieldIsRequired: false,
                    fieldPaddingLeft: true,
                    fieldPaddingRight: true
                  },
                  {
                    fieldType: 'toggle',
                    fieldControlName: 'shouldAddTax',
                    fieldBootstrapColumn: 4,
                    fieldName: this.tranPipe.transform('edit_sale_event_other_should_add_tax', 'Aggiungi tasse', []),
                    fieldPlaceholder: "",
                    fieldValue: this.saleEvent?.shouldAddTax,
                    fieldIsRequired: false,
                    fieldPaddingLeft: true,
                    fieldPaddingRight: false
                  }
                ]
              }
            ]
          }
        ]

      setTimeout(() => {
        if (!this.alreadyPipeForm) {
          this.validateForm.controls["descriptionType"].valueChanges.pipe(takeUntil(this.sub)).subscribe((s: string | undefined) => {

            if (!this.validateForm.contains("description")) {
              this.validateForm.addControl("description", new FormControl({ value: this.saleEvent?.saleEventDescription, disabled: false }))
            }

            if (!this.validateForm.contains("descriptionDetail")) {
              this.validateForm.addControl("descriptionDetail", new FormControl({ value: this.saleEvent?.saleEventDetailDescription ?? "", disabled: false }))
            }

            var formField = undefined;
            if ((s ?? "").strEq("base")) {
              this.validateForm.get("descriptionDetail")!.removeValidators(Validators.required);
              formField = this.getEditDescriptionFormField(s, this.validateForm.controls["description"].value)
            } else {
              this.validateForm.get("descriptionDetail")!.addValidators(Validators.required);
              formField = this.getEditDescriptionFormField(s, this.validateForm.controls["descriptionDetail"].value)
            }

            if (formField != undefined) {
              this.salePurpleFormFieldGroups.find(f => f.formSectionId.strEq("description"))!.purpleFormGroups[0].formFields = [
                this.salePurpleFormFieldGroups.find(f => f.formSectionId.strEq("description"))!.purpleFormGroups[0].formFields[0],
                formField,
                {
                  fieldType: 'input-text',
                  fieldControlName: 'detailItemsQty',
                  fieldBootstrapColumn: 4,
                  fieldName: this.tranPipe.transform('edit_sale_event_detail_items_qty_title', 'Quantità articoli', []),
                  fieldPlaceholder: this.tranPipe.transform('edit_sale_event_detail_items_qty_placeholder', 'Quantità articoli', []),
                  fieldValue: this.saleEvent?.saleEventDetailItemsQty,
                  fieldIsRequired: false,
                  fieldIsDisabled: false,
                  fieldPaddingLeft: false,
                  fieldPaddingRight: true
                },
                {
                  fieldType: 'input-text',
                  fieldControlName: 'detailAvgDiscount',
                  fieldBootstrapColumn: 4,
                  fieldName: this.tranPipe.transform('edit_sale_event_detail_detail_avg_discount_title', 'Sconto medio', []),
                  fieldPlaceholder: this.tranPipe.transform('edit_sale_event_detail_detail_avg_discount_placeholder', 'Sconto medio', []),
                  fieldValue: this.saleEvent?.saleEventDetailAvgDiscount,
                  fieldIsRequired: false,
                  fieldIsDisabled: false,
                  fieldPaddingLeft: true,
                  fieldPaddingRight: true
                },
                {
                  fieldType: 'input-text',
                  fieldControlName: 'detailMaxDiscount',
                  fieldBootstrapColumn: 4,
                  fieldName: this.tranPipe.transform('edit_sale_event_detail_detail_max_discount_title', 'Sconto massimo', []),
                  fieldPlaceholder: this.tranPipe.transform('edit_sale_event_detail_detail_max_discount_placeholder', 'Sconto massimo', []),
                  fieldValue: this.saleEvent?.saleEventDetailMaxDiscount,
                  fieldIsRequired: false,
                  fieldIsDisabled: false,
                  fieldPaddingLeft: true,
                  fieldPaddingRight: false
                }
              ]
            }

            //console.log("Cambia valore: ", formField)
          });


          this.validateForm.controls["address"].valueChanges.pipe(
            throttle(() => interval(500)), takeUntil(this.sub)).subscribe(async (s: string | undefined | null) => {
              if (!this.isLoading) {
                console.log("Questa è S: ", s, " - ", this.saleEvent?.addressExternalId)
                if (s == undefined || s == null) {
                  this.validateForm.controls['addressFull'].setValue(undefined);
                  this.validateForm.controls['addressFull'].disable();
                } else {
                  if (s != this.saleEvent?.addressExternalId) {

                    await this.apiProxySvc.makeRequestErrorFunction<Address>(() => this.admSvc.getAddressDetail(this.tsvc.currentLanguage.value, {
                      externalAddressId: s!
                    }), false, "internal-loader", 500, undefined, (res: PurpleApiMakeRequestResponse<Address>) => {
                      this.saleEvent!.addressFull = res.data?.addressFull;
                      this.validateForm.controls['addressFull'].setValue(this.saleEvent!.addressFull);
                      this.validateForm.controls['addressFull'].enable();
                    })
                  }
                }

              }


            });

          this.validateForm.valueChanges.pipe(takeUntil(this.sub)).subscribe(s => {
            const idx = (this.dashSvc.dashMainItems ?? []).findIndex(f => f.id.strEq("save-sale"));

            if (idx == -1) {
              this.dashSvc.dashMainItems.push(
                new dashboardButton(
                  "save-sale",
                  this.tranPipe.transform('private_sale_general_save_changes_button', 'Salva Modifiche', []),
                  async () => { await this.updatePrivateSaleInfo() }
                )
              )
            }
          });

          this.alreadyPipeForm = true;
        }

      }, 100);


    })
  }

  async updatePrivateSaleInfo(): Promise<void> {
    this.isLoading = true;

    for (const i in this.validateForm.controls) {
      this.validateForm.controls[i].markAsDirty();
      this.validateForm.controls[i].updateValueAndValidity();
    }

    if (this.validateForm.valid) {
      this.modal.create({
        nzTitle: this.tranPipe.transform("update_sale_event_save_modal_title", "Vuoi davvero aggiornare le informazioni?"),
        nzContent: this.tranPipe.transform("update_sale_event_save_modal_subtitle", "Le informazioni della private sale \"<b>{0}</b>\" verranno aggiornate", [this.validateForm.controls["name"].value]),
        nzWidth: '600px',
        nzClassName: 'purple-simple-modal',
        nzOkText: this.tranPipe.transform('update_sale_event_save_button', 'Salva', []),
        nzOnOk: async () => {
          const owners: string[] = this.validateForm.controls["owners"].value ?? [];

          await this.apiProxySvc.makeRequestErrorMessage<PRPVSaleEventFullNullable>(() => this.admSvc.updateSaleEvent(this.tsvc.translationLanguage.value, {
            saleEventId: this.saleEvent!.saleEventId,
            saleEventName: this.validateForm.controls["name"].value,
            saleEventInternalName: this.validateForm.controls["internalName"].value,
            saleEventCode: this.validateForm.controls["url"].value,
            onlyPrivateInvite: this.validateForm.controls["isPrivate"].value,
            saleEventDetailLayout: this.validateForm.controls["descriptionType"].value,
            saleEventDescription: this.validateForm.controls["description"]?.value ?? undefined,
            saleEventDetailDescription: (this.validateForm.controls["descriptionType"].value == 'base') ? null : this.validateForm.controls["descriptionDetail"].value,
            saleEventEmail: this.validateForm.controls["email"].value,
            saleEventPhone: this.validateForm.controls["phoneNumber"].value,
            saleEventPhonePrefix: this.validateForm.controls["phonePrefix"].value,
            externalAddressId: this.validateForm.controls["address"].value,
            addressFull: this.validateForm.controls["addressFull"].value,
            startDate: (this.validateForm.controls["staffDate"].value)[0].toIsoStringPurple(),
            endDate: (this.validateForm.controls["staffDate"].value)[1].toIsoStringPurple(),
            publicOpenDate: (this.validateForm.controls["publicDate"].value)[0].toIsoStringPurple(),
            publicCloseDate: (this.validateForm.controls["publicDate"].value)[1].toIsoStringPurple(),
            currency: this.validateForm.controls["currency"].value,
            defaultTax: this.validateForm.controls["tax"].value,
            limitOwners: owners,
            isStatsVisible: this.validateForm.controls["isStatsEnable"].value,
            isOnline: this.saleEvent!.isOnline,
            showComingSoon: this.saleEvent!.showComingSoon,
            comingSoonStartDate: null,
            isOwnersVisible: owners.length > 0,
            isInvoiceNumberingEnabled: this.validateForm.controls["isInvoiceNumberingEnabled"].value,
            maxUserCheckins: this.validateForm.controls["maxUserCheckins"].value,
            maxUserTransactions: this.validateForm.controls["maxUserTransactions"].value,
            saleEventInternalCode: this.validateForm.controls["internalCode"].value,
            isCartDiscountEnabled: this.validateForm.controls["isCartDiscountEnabled"].value,
            saleEventDetailItemsQty: this.validateForm.controls["detailItemsQty"].value,
            saleEventDetailAvgDiscount: this.validateForm.controls["detailAvgDiscount"].value,
            saleEventDetailMaxDiscount: this.validateForm.controls["detailMaxDiscount"].value,
            canBookOnlyOnce: this.validateForm.controls["canBookOnlyOnce"].value,
            isWareHouse: this.validateForm.controls["isWareHouse"].value,
            shouldAddTax: this.validateForm.controls["shouldAddTax"].value

          }), true, "internal-loader", 500, undefined, 700, async (res: PurpleApiMakeRequestResponse<PRPVSaleEventFullNullable>) => {
            this.mesSvc.success(this.tranPipe.transform("private_sale_general_save_changes_ok", "Informazioni aggiornate con successo"), {
              nzDuration: environment.MESSAGE_DURATION
            })

            await this.refreshSale();


            setTimeout(() => {

              const idx = (this.dashSvc.dashMainItems ?? []).findIndex(f => f.id.strEq("save-sale"));
              //console.log("Cancello: ", idx)

              if (idx != -1) {
                this.dashSvc.dashMainItems.splice(idx, 1)
              }
            }, 100);
          })
        },
      });
    }
    this.isLoading = false;
  }

  ngOnDestroy(): void {
    this.sub.next();
    this.sub.complete();
    this.dashSvc.clearPageHeader();
  }

  //#region Address
  addressSearchNameFunction = (args: { pageNumber: number, pageSize: number, culture: string, searchName: string, searchFilters: PurpleSelectFilter[] }) => {
    return this.bookSvc.getAddressByNameSelect(args.culture, args)
  }

  addressSearchIdFunction = (args: { pageNumber: number, pageSize: number, culture: string, searchName: string, searchFilters: PurpleSelectFilter[] }) => {
    return this.bookSvc.getAddressByIdSelect(args.culture, args)
  }
  //#endregion

  //#region Owners
  ownersSearchFunction = (args: { pageNumber: number, pageSize: number, culture: string, searchName: string, searchFilters: PurpleSelectFilter[] }) => {
    return this.admSvc.getOwnersByNameSelect(args.culture, args)
  }

  ownersScrollFunction = (args: { pageNumber: number, pageSize: number, culture: string, searchName: string, searchFilters: PurpleSelectFilter[] }) => {
    return this.admSvc.getOwnersSelect(args.culture, args)
  }

  ownersSearchIdsFunction = (args: { pageNumber: number, pageSize: number, culture: string, searchName: string[], searchFilters: PurpleSelectFilter[] }) => {
    return this.admSvc.getOwnersByIdsSelect(args.culture, args)
  }
  //#endregion

  disabledPublicDates = (current: Date): boolean => {
    const staffDate = this.validateForm.controls["staffDate"]?.value ?? undefined
    if (staffDate != undefined && differenceInCalendarDays(staffDate[0], current) > 0) {
      return true
    }

    if (staffDate != undefined && differenceInCalendarDays(current, staffDate[1]) > 0) {
      return true
    }

    return false;
  }

  disabledComingSoonDate = (current: Date): boolean =>
    differenceInCalendarDays(current, new Date()) < 0;

}


interface editSaleFormItem {
  canBeTranslated?: boolean | undefined;
  showSpin?: boolean | undefined;
  isSpinnerVisible?: boolean | undefined;
  showLine?: boolean | undefined;
  formSectionId: string;
  formSectionName: string;
  purpleFormGroups: purpleFormFieldsGroup[]
}