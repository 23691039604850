import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { AppTranslationService } from 'src/core-modules/localization/localization.service';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NzMessageService } from 'ng-zorro-antd/message';
import { PurpleTableSearchValue, PurpleTableColumn, PurpleTranslationPipe, PurpleTableSelectFilter, PurpleLoaderService, PurpleLanguageIconPipe, PurpleTableBoxColumn, PurpleStorageService } from 'purple-lib';
import { environment } from 'src/environments/default/environment';
import { EditTranslationComponent } from '../modals/edit-translation/edit-translation.component';
import { DashboardLayout2Service } from '../../dashboard-layout-2/dashboard-layout-2.service';
import { PurpleApiProxyService } from 'purple-lib';
import { Subject, takeUntil } from 'rxjs';
import { BackOfficeService, PRPVTranslationFull, PRPVTranslationFullPurpleTableResponse, PurpleTableSearchGroupGroup } from 'src/core-modules/sdk/api';


@Component({
  selector: 'app-translations',
  templateUrl: './translations.component.html',
  styleUrls: ['./translations.component.scss']
})
export class TranslationsComponent implements OnInit, AfterViewInit, OnDestroy {

  constructor(public strSvc: PurpleStorageService, public dashSvc: DashboardLayout2Service, public backLocSvc: BackOfficeService, private loaderSvc: PurpleLoaderService, private languageIconPipe: PurpleLanguageIconPipe,
    public tsvc: AppTranslationService, private modal: NzModalService, private tranPipe: PurpleTranslationPipe, private mesSvc: NzMessageService, private apiProxySvc: PurpleApiProxyService) {
  }

  ngAfterViewInit(): void {
    const pageName = "translations";
    this.dashSvc.setPageHeader(
      this.tranPipe.transform(pageName + '_page_title', 'Lista Traduzioni', []),
      this.tranPipe.transform(pageName + '_page_subtitle', 'Lista delle traduzioni presenti nel sistema', []))
  }

  ngOnDestroy(): void {
    this.dashSvc.clearPageHeader();

    this.sub.next();
    this.sub.complete();
  }
  sub: Subject<void> = new Subject();

  passwordVisible: string[] = [];
  listOfData: Array<PRPVTranslationFullPurpleTableResponse> | undefined | null;
  refreshTableRowsNumber: number = 0;
  tabColumns: PurpleTableBoxColumn[] = [
    {
      name: this.tranPipe.transform("table_header_translation_language", "Lingua"),
      hasReorder: false,
      hasFilter: false,
      fixedWidth: "80px"
    },
    {
      name: this.tranPipe.transform("table_header_is_translated", "Tradotto"),
      dbKey: "IsTranslated",
      hasReorder: true,
      hasFilter: true,
      fixedWidth: "110px",
      filterField: {
        fieldType: 'select-local',
        fieldControlName: 'IsTranslated',
        fieldBootstrapColumn: 12,
        fieldName: this.tranPipe.transform('table_box_filter_is_translated', 'Tradotto', []),
        fieldPlaceholder: this.tranPipe.transform('table_box_filter_is_translated_placeholder', 'Tradotto', []),
        fieldValue: undefined,
        fieldIsRequired: false,
        fieldIsDisabled: false,
        fieldPaddingLeft: false,
        fieldPaddingRight: false,
        fieldOptions: [
          { id: 'isMultiple', value: false },
          { id: 'showArrow', value: true },
          { id: 'enableSearch', value: false },
          {
            id: 'options', value: [
              {
                label: 'Si',
                value: 1
              },
              {
                label: 'No',
                value: 0
              }
            ]
          }
        ]
      },
      operatorType: "="
    },
    {
      name: this.tranPipe.transform("translation_table_header_translation_key", "Chiave Traduzione"),
      dbKey: "translationId",
      hasReorder: true,
      hasFilter: true,
      filterField: {
        fieldType: 'input-text',
        fieldControlName: 'translationId',
        fieldBootstrapColumn: 12,
        fieldName: this.tranPipe.transform('translation_table_filter_translation_key', 'Chiave traduzione', []),
        fieldPlaceholder: this.tranPipe.transform('translation_table_filter_translation_key_placeholder', 'Chiave della traduzione', []),
        fieldValue: undefined,
        fieldIsRequired: false,
        fieldIsDisabled: false,
        fieldPaddingLeft: false,
        fieldPaddingRight: false
      },
      operatorType: "like"
    },
    {
      name: this.tranPipe.transform("translation_table_header_translation_value", "Traduzione"),
      dbKey: "translationValue",
      hasReorder: true,
      hasFilter: true,
      filterField: {
        fieldType: 'textarea',
        fieldControlName: 'translationValue',
        fieldBootstrapColumn: 12,
        fieldName: this.tranPipe.transform('translation_value', 'Traduzione', []),
        fieldPlaceholder: this.tranPipe.transform('translation_value_placeholder', 'Traduzione', []),
        fieldValue: undefined,
        fieldIsRequired: false,
        fieldIsDisabled: false,
        fieldPaddingLeft: false,
        fieldPaddingRight: false,
        fieldOptions: [{ id: 'rowNumber', value: 4 }]
      },
      operatorType: "like"
    },
    {
      name: this.tranPipe.transform("table_header_actions", "Azioni"),
      hasReorder: false,
      hasFilter: false,
      fixedWidth: "190px"
    }
  ]

  refreshFunction = (args: {
    pageNumber: number, pageSize: number, orderColumnName?: string | null, orderType?: string | null,
    tableSearchValues?: Array<PurpleTableSearchGroupGroup> | null, tableFilters?: Array<PurpleTableSelectFilter> | null, switchOn?: boolean,
    culture: string
  }) => {
    args.culture = this.tsvc.translationLanguage.value;
    return this.backLocSvc.getTranslationsTable(args.culture, args);
  }


  async ngOnInit() {
    this.loaderSvc.addRequest("internal-loader")

    this.tsvc.translationLanguage.pipe(takeUntil(this.sub)).subscribe(() => {
      this.refreshTableRowsNumber++
    });

    this.dashSvc.dashMainItems = [];

    this.dashSvc.dashMainItems?.push({
      id: "bt",
      type: "button",
      buttonText: this.tranPipe.transform("table_add_new_item_button", "Aggiungi {0}", ["Traduzione"]),
      buttonFunction: () => { this.editTranslation(undefined) }
    })

    this.dashSvc.addTranslationSelect();

    this.loaderSvc.removeRequest("internal-loader", 350)
  }


  editTranslation(translation: PRPVTranslationFull | undefined) {
    this.modal.create<EditTranslationComponent, PRPVTranslationFull | undefined>({
      nzContent: EditTranslationComponent,
      nzData: translation,
      nzTitle: translation == undefined ? this.tranPipe.transform("table_item_edit_modal_title_new", "Aggiungi {0} {1}", ["traduzione", '<span class="ps-ml-1 ' + this.languageIconPipe.transform(this.tsvc.translationLanguage.value) + '"></span>']) :
        this.tranPipe.transform("table_item_edit_modal_title_update", "Modifica {0} {1}", ["traduzione", '<span class="ps-ml-1 ' + this.languageIconPipe.transform(this.tsvc.translationLanguage.value) + '"></span>']),
      nzWidth: '800px',
      nzOkText: "Conferma", 
      nzClassName: 'purple-modal',
      nzFooter: null,
      nzMaskClosable: false,
    }).afterClose.pipe(takeUntil(this.sub)).subscribe(async (refresh: boolean) => {
      if (refresh) {
        this.refreshTableRowsNumber++;
      }
    });
  }

  async deleteTranslation(translation: PRPVTranslationFull) {
    this.modal.create({
      nzTitle: "Eliminare " + translation.translationId,
      nzContent: `Vuoi davvero eliminare la traduzione ${translation.translationId}?`,
      nzWidth: '600px',
      nzClassName: 'purple-simple-modal',
      nzFooter: [
        {
          label: this.tranPipe.transform('edit_transaltion_delete_all', 'Elimina globale', []),
          type: "primary",
          onClick: async () => await this.apiProxySvc.makeRequestErrorMessage<boolean>(() => this.backLocSvc.deleteTranslation(this.tsvc.currentLanguage.value, {
            translationId: translation.translationId,
            onlyCurrentCulture: false
          }), false, "internal-loader", 500, undefined, undefined, () => {
            this.mesSvc.success("Traduzione elimiata correttamente", {
              nzDuration: environment.MESSAGE_DURATION
            });
            this.refreshTableRowsNumber++;
            this.modal.closeAll()
          })
        },

        {
          label: this.tranPipe.transform('edit_transaltion_delete_culture', 'Elimina cultura', []),
          onClick: async () => await this.apiProxySvc.makeRequestErrorMessage<boolean>(() => this.backLocSvc.deleteTranslation(this.tsvc.currentLanguage.value, {
            translationId: translation.translationId,
            onlyCurrentCulture: true
          }), false, "internal-loader", 500, undefined, undefined, () => {
            this.mesSvc.success("Traduzione elimiata correttamente", {
              nzDuration: environment.MESSAGE_DURATION
            });
            this.refreshTableRowsNumber++;
            this.modal.closeAll()
          })
        }
      ]
    })
  }
}
