<ng-container *ngIf="dashSvc.dashboardHeight != undefined && this.showTable == true">

  <purple-table #purpleTable [tableBody]="tableBody" [isBordered]="true" [switchOn]="false"
    [refreshFunction]="refreshFunction" (tableData)="listOfData = $event" [tableColumns]="tabColumns"
    [tableScroll]="{y:dashSvc.dashboardHeight}" [refreshTableRowsNumber]="refreshTableRowsNumber"[version]="2"
    [showCustomPagination]="true" tableSearchType="box" (filterBox)="dashSvc.setPurpleTablePopover($event)"
    (onSearchBoxEnd)="dashSvc.closePurpleTablePopover()" [filterBoxStatus]="dashSvc.purpleTableBoxFilterStatus">

    <ng-template #tableBody>
      <ng-container *ngFor="let data of listOfData; let i = index">
        <tr class="{{i%2==0?'main-purple-table-tr-even': 'main-purple-table-tr-odd'}} ">
          <td>{{ data.item.firstName }}</td>
          <td>{{ data.item.lastName }}</td>
          <td>{{ data.item.email }}</td>
          <!-- <td>{{ data.item.userCompany }}</td>
        <td>{{ data.item.roleName }}</td> -->
          <td nzAlign="center">
            <container-element [ngSwitch]="data.item.isPending">
              <ng-container *ngSwitchCase="true">
                <p class="ps-m-0 ps-p-0 fw-b">{{ 'is_pending_yes' | translation: 'Si': []}}</p>
              </ng-container>
              <ng-container *ngSwitchCase="false">
                <p class="ps-m-0 ps-p-0 fw-b">{{ 'is_pending_no' | translation: 'No': []}}</p>

              </ng-container>
            </container-element>
          </td>
          <td nzAlign="center">
            <container-element [ngSwitch]="data.item.isEmailSent">
              <ng-container *ngSwitchCase="true">
                <p class="ps-m-0 ps-p-0 fw-b">{{ 'is_pending_yes' | translation: 'Si': []}}</p>
              </ng-container>
              <ng-container *ngSwitchCase="false">
                <p class="ps-m-0 ps-p-0 fw-b">{{ 'is_pending_no' | translation: 'No': []}}</p>

              </ng-container>
            </container-element>
          </td>
          <!-- <td nzAlign="center">
            <container-element [ngSwitch]="data.item.isEmailSent">
              <ng-container *ngSwitchCase="true">
                <p class="ps-m-0 ps-p-0">{{ data.item.emailDate | date: "dd-MM-yyyy HH:mm"}}</p>
              </ng-container>
              <ng-container *ngSwitchCase="false">
                <p class="ps-m-0 ps-p-0 fw-b">-</p>

              </ng-container>
            </container-element>
          </td> -->
          <td nzAlign="center">
            <container-element [ngSwitch]="data.item.isRegistered">
              <ng-container *ngSwitchCase="true">
                <p class="ps-m-0 ps-p-0 fw-b">{{ 'is_pending_yes' | translation: 'Si': []}}</p>
              </ng-container>
              <ng-container *ngSwitchCase="false">
                <p class="ps-m-0 ps-p-0 fw-b">{{ 'is_pending_no' | translation: 'No': []}}</p>

              </ng-container>
            </container-element>
          </td>

          <td nzAlign="center">
            <button (click)="data.expanded = !data.expanded" [nzTooltipTitle]='
                        data.expanded?
                        ("hide_more_button_sale_event_user_table" | translation: "Nascondi dati aggiuntivi"):
                        ("show_more_button_sale_event_user_table" | translation: "Mostra più dati")
                        ' nzTooltipPlacement="top" class="ps-mr-3" nz-tooltip nz-button nzType="default"
              nzShape="circle"><i nz-icon [nzType]="data.expanded?'eye-invisible':'eye'"></i>
            </button>
          </td>
        </tr>

        <tr [nzExpand]="data.expanded">
          <div class="ps-d-flex ps-w-100 ps-justify-content-center">
            <div class="ps-d-flex ps-flex-wrap ps-align-items-start ps-w-100 ps-justify-content-between 
              table-expanded-row-container">

              <nz-descriptions class="ps-w-100"
                [nzTitle]="'sale_event_user_table_expanded_row_title' | translation: 'Informazioni aggiuntive': []"
                nzBordered [nzColumn]="{ xxl: 2, xl: 2, lg: 2, md: 2, sm: 1, xs: 1 }">
                
                <nz-descriptions-item [nzTitle]="'imported_user_table_company' | translation: 'Azienda': []">
                  {{ data.item.userCompany }}
                </nz-descriptions-item>
                
                <nz-descriptions-item [nzTitle]="'sale_event_user_table_role' | translation: 'Ruolo': []">
                  {{data.item.roleName}}
                </nz-descriptions-item>

                <nz-descriptions-item [nzTitle]="'sale_event_user_table_sent_date' | translation: 'Data invio email': []">
                  <container-element [ngSwitch]="data.item.isEmailSent">
                    <ng-container *ngSwitchCase="true">
                      <p class="ps-m-0 ps-p-0">{{ data.item.emailDate | date: "dd-MM-yyyy HH:mm"}}</p>
                    </ng-container>
                    <ng-container *ngSwitchCase="false">
                      <p class="ps-m-0 ps-p-0 fw-b">-</p>
      
                    </ng-container>
                  </container-element>                  
                </nz-descriptions-item>
              </nz-descriptions>
            </div>
          </div>
        </tr>
      </ng-container>
    </ng-template>
  </purple-table>
</ng-container>