import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthenticationGuard } from 'src/core-modules/authentication/authentication.guard';
import { ShowMenuGuard } from 'src/core-modules/show-menu/showMenu.guard';
import { CustomersComponent } from './customers/customers.component';
import { EmployeesComponent } from './employees/employees.component';
import { RolesComponent } from './roles/roles.component';
import { UserDetailComponent } from './user-detail/user-detail.component';

const routes: Routes = [
  {
    path: '',
    canActivate: [AuthenticationGuard],
    children: [
      {
        path: '',
        redirectTo: 'customers',
        pathMatch: "full"
      },
      {
        path: 'customers',
        component: CustomersComponent,
        data: { submenu: "customers" },
        canActivate: [ShowMenuGuard],
      },
      {
        path: 'user-detail',
        component: UserDetailComponent,
        data: { submenu: "customers" },
        canActivate: [ShowMenuGuard]
      },
      {
        path: 'employees',
        component: EmployeesComponent,
        data: { submenu: "employees" },
        canActivate: [ShowMenuGuard]
      },
      {
        path: 'roles',
        component: RolesComponent,
        data: { submenu: "roles" },
        canActivate: [ShowMenuGuard]
      }
    ]
  }

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AdministrationRoutingModule { }
