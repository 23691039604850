import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GeneralRoutingModule } from './general-routing.module';
import { TranslationModule } from './translations/translations.module';
import { GeneralImagesComponent } from './general-images/general-images.component';
import { PurpleEditImageModule, PurplePipeModule, PurpleTableModule, PurpleFormModule, PurpleGeneralDirectiveModule } from 'purple-lib';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { CouponsRegistryComponent } from './coupons-registry/coupons-registry.component';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzMessageModule } from 'ng-zorro-antd/message';
import { NzPopoverModule } from 'ng-zorro-antd/popover';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzTableModule } from 'ng-zorro-antd/table';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzTagModule } from 'ng-zorro-antd/tag';
import { EditDefaultCouponModalComponent } from './modals/edit-default-coupon-modal/edit-default-coupon-modal.component';

@NgModule({
  imports: [
    CommonModule,
    TranslationModule,
    GeneralRoutingModule,
    PurplePipeModule,
    PurpleEditImageModule,
    NzToolTipModule,
    PurpleTableModule,
    NzFormModule,
    NzIconModule,
    NzInputModule,
    NzButtonModule,
    NzMessageModule,
    NzPopoverModule,
    NzModalModule,
    NzTableModule,
    FormsModule,
    ReactiveFormsModule,
    PurpleFormModule,
    NzSpinModule,
    NzTagModule,
    PurpleGeneralDirectiveModule
  ],
  declarations: [GeneralImagesComponent, CouponsRegistryComponent, EditDefaultCouponModalComponent]
})
export class GeneralModule { }
