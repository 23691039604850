import { Component, Inject, LOCALE_ID, OnDestroy, OnInit } from '@angular/core';
import { CalendarEvent, CalendarWeekViewBeforeRenderEvent } from 'angular-calendar';
import { addDays, isSameDay, subDays, } from 'date-fns';
import { Subject, lastValueFrom, takeUntil } from 'rxjs';
import { BackOfficeService,  PRPVSaleEventFullExtended, SaleEventDayItem, SaleEventDaySlotItem } from 'src/core-modules/sdk/api';
import { PrivateSaleService } from '../../private-sale-service.service';
import { ActivatedRoute, Router } from '@angular/router';
import { PurpleApiMakeRequestResponse, PurpleApiProxyService, PurpleTranslationPipe, dashboardButton } from 'purple-lib';
import { DashboardLayout2Service } from 'src/app/dashboard/dashboard-layout-2/dashboard-layout-2.service';
import { AppTranslationService } from 'src/core-modules/localization/localization.service';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { ShowBookedUsersModalComponent } from '../modals/show-booked-users-modal/show-booked-users-modal.component';

@Component({
  selector: 'app-private-sale-bookings-report',
  templateUrl: './private-sale-bookings-report.component.html',
  styleUrls: ['./private-sale-bookings-report.component.scss']
})

export class PrivateSaleBookingsReportComponent implements OnInit, OnDestroy {

  constructor(@Inject(LOCALE_ID) public locale: string, private seSvc: PrivateSaleService, private route: ActivatedRoute, private dashSvc: DashboardLayout2Service,
    private tranPipe: PurpleTranslationPipe, private tsvc: AppTranslationService, private router: Router, private apiProxySvc: PurpleApiProxyService, private admSvc: BackOfficeService, private modal: NzModalService,
    private notification: NzNotificationService) {
    this.dateOrViewChanged();
  }

  weekStartsOn: number = 1;
  dayStartHour: number = 6;
  dayEndHour: number = 24;
  hourSegments: number = 2;
  hourSegmentHeight: number = 50;
  daysInWeek = 4;

  viewDate: Date = new Date();
  minDate: Date = new Date();
  maxDate: Date = new Date();
  periodStartDate: Date = new Date();
  periodEndDate: Date = new Date();

  events: CalendarEvent[] = [];
  refresh = new Subject<void>();

  sub: Subject<void> = new Subject();
  saleEvent: PRPVSaleEventFullExtended | undefined;

  clickedDaySlot: Date | undefined;
  clickedDaySlotSummary: SaleEventDayItem | undefined;
  clickedEvent: CalendarEvent | undefined;
  allCalendarSlots: SaleEventDayItem[] = [];

  //headerMenuShowCreate: boolean = true;
  //headerMenuShowClone: boolean = true;

  ngOnInit() {
    //this.dashSvc.setCurrentMenu("sale");

    this.seSvc.currentSaleEvent$.pipe(takeUntil(this.sub)).subscribe((se: PRPVSaleEventFullExtended | undefined) => {
      this.saleEvent = se;

      if (se != undefined) {
        this.refreshCalendar();
      }
    });

    this.route.queryParams.subscribe(async params => {
      this.dashSvc.dashBackButtons?.push(
        new dashboardButton(
          "back-button",
          this.tranPipe.transform("dashboard_private_general_iofo_back_button", "Torna alla lista", []),
          () => { this.router.navigate([this.tsvc.currentLanguage.value, 'dashboard', 'private-sales', 'private-sale-list']) },
          undefined,
          "arrow-left",
          undefined,
          "left"
        )
      );


      await this.refreshSale(params['saleEvent']);
    });
  }

  ngOnDestroy(): void {
    this.dashSvc.clearPageHeader();
    this.sub.next();
    this.sub.complete();
  }

  private async refreshSale(seId: string) {
    await this.dashSvc.refreshSaleEvent(seId, "private-sale-bookings-report", 'Riepilogo prenotazioni');
  }

  async refreshCalendar() {
    await this.apiProxySvc.makeRequestErrorPage<SaleEventDayItem[]>(() => this.admSvc.getSaleEventDateDaySlots(this.tsvc.currentLanguage.value, {
      saleEventId: this.saleEvent!.saleEventId
    }), true, "internal-loader", 500, "internal-error", undefined, async (res: PurpleApiMakeRequestResponse<SaleEventDayItem[]>) => {
      this.allCalendarSlots = res.data ?? [];
      this.events = [];

      this.minDate = new Date(Date.parse(this.saleEvent!.publicOpenDate.changeDateTimezone())).skipTime();
      this.maxDate = new Date(new Date(Date.parse(this.saleEvent!.publicCloseDate.changeDateTimezone())).setHours(23, 59));
      this.viewDate = this.minDate;

      if (this.dateIsValid(new Date())) {
        this.viewDate = new Date()
      } else {
        if (res.data!.length > 0 && res.data![0].slots.length > 0) {
          this.viewDate = new Date(Date.parse(res.data![0].slots[0].slotStartDate))
        }
      }

      (res.data ?? []).forEach(f => {
        f.slots.forEach(f1 => {

          this.addEvent(f1);

        })
      })
    });
  }


  increment(): void {
    this.changeDate(addDays(this.viewDate, this.daysInWeek));
  }

  decrement(): void {
    this.changeDate(subDays(this.viewDate, this.daysInWeek));
  }

  dateIsValid(date: Date): boolean {
    return date >= this.minDate && date <= this.maxDate;
  }

  changeDate(date: Date): void {
    this.viewDate = date;

  }

  dateOrViewChanged(): void {

    const prevBtnEnable = this.dateIsValid(
      subDays(this.periodStartDate, 1)
    );

    const nextBtnEnable = this.dateIsValid(
      new Date(addDays(this.periodEndDate, 1).setHours(0, 0, 0))
    );

    if (this.dashSvc.dashMainItems.length == 0) {
      this.dashSvc.dashMainItems.push(
        new dashboardButton(
          "show-users",
          this.tranPipe.transform('calendar_show_users', 'Mostra Utenti', []),
          () => { this.showBookedUsers(undefined, 'all') }
        ),
        new dashboardButton(
          "export-excel",
          this.tranPipe.transform('calendar_export_excel', 'Esporta Excel', []),
          () => { this.exportToExcel(undefined, 'all') }
        ),
        new dashboardButton(
          "calendar-prev",
          this.tranPipe.transform('calendar_prev_button', 'Indietro', []),
          () => { this.decrement() }
        ),
        new dashboardButton(
          "calendar-next",
          this.tranPipe.transform('calendar_next_button', 'Avanti', []),
          () => { this.increment() }
        )
      )
    }
    (this.dashSvc.dashMainItems.find(f => f.id.strEq("calendar-prev")) as dashboardButton).isEnable = prevBtnEnable;
    (this.dashSvc.dashMainItems.find(f => f.id.strEq("calendar-next")) as dashboardButton).isEnable = nextBtnEnable;

    if (this.viewDate < this.minDate) {
      this.changeDate(this.minDate);
    } else if (this.viewDate > this.maxDate) {
      this.changeDate(this.maxDate);
    }


    //console.log("dateOrViewChanged viewDate: ", this.viewDate);
    //console.log("minDate: ", this.minDate);
    //console.log("maxDate: ", this.maxDate);
    //console.log("PrevBtn date: ", subDays(this.periodStartDate,1));
    //console.log("NextBtn date: ", new Date(addDays(this.periodEndDate, 1).setHours(0,0,0)));
    //console.log("dateOrViewChanged: ", " - prevBtn enable: ", prevBtnEnable, " - nextBtn enable: ",nextBtnEnable)
  }

  beforeWeekViewRender(renderEvent: CalendarWeekViewBeforeRenderEvent) {
    this.periodStartDate = renderEvent.period.start;
    this.periodEndDate = renderEvent.period.end;

    setTimeout(() => {
      this.dateOrViewChanged();

    }, 100);
  }

  clickDayHeaderEvent(day: any) {
    this.clickedDaySlot = day.date;
    this.clickedDaySlotSummary = this.allCalendarSlots.filter(f => isSameDay(new Date(Date.parse(f.dateDay)), day.date))[0];
  }

  private addEvent(slot: SaleEventDaySlotItem, editText: string = "") {
    this.events.push({
      id: slot.slotStartDate + "_" + slot.slotEndDate,
      start: new Date(Date.parse(slot.slotStartDate)),
      end: new Date(Date.parse(slot.slotEndDate)),
      title: slot.isMixedRoleEnabled ? this.tranPipe.transform('event_calendar_title_all_roles', 'Tutti i Ruoli', []) : slot.roles.map(m => m.roleName).join(" - "),
      meta: {
        slot: slot,
        edit: editText
      }
    })
  }

  showBookedUsers(slot: SaleEventDaySlotItem | undefined, exportDataType: 'day' | 'slot' | 'all') {

    var slotStartDate = undefined;

    switch (exportDataType) {
      case 'all':
        slotStartDate = undefined;
        break;
      case 'day':
        slotStartDate = this.clickedDaySlot?.toIsoStringPurple();
        break;
      case 'slot':
        slotStartDate = slot?.slotStartDate;
        break;
      default:
        break;
    }

    this.modal.create<ShowBookedUsersModalComponent, { slotStartDate: string | undefined, saleEventId: string, saleEventCurrency: string, exportDataType: "day" | "slot" | "all" }>({
      nzContent: ShowBookedUsersModalComponent,
      nzData: {
        slotStartDate: slotStartDate,
        saleEventId: this.saleEvent!.saleEventId,
        saleEventCurrency: this.saleEvent!.currency,
        exportDataType: exportDataType
      },
      nzTitle: this.tranPipe.transform("show_booked_users_modal_title", "Lista utenti prenotati", []),
      nzWidth: '90%',
      nzClassName: 'purple-modal booked-users-modal',
      nzFooter: null,
      nzMaskClosable: false,
    });
  }

  async exportToExcel(slot: SaleEventDaySlotItem | undefined, type: 'day' | 'slot' | 'all') {
    var slotStartDate: string | undefined = undefined;

    switch (type) {
      case 'all':
        slotStartDate = undefined;
        break;
      case 'day':
        slotStartDate = this.clickedDaySlot?.toIsoStringPurple();
        break;
      case 'slot':
        slotStartDate = slot?.slotStartDate;
        break;
      default:
        break;
    }

    this.notification.blank(this.tranPipe.transform('export_excel_activity_download_message_title', 'Download file excel in corso...', []),
      this.tranPipe.transform('export_excel_activity_download_message_subtitle', 'La generazione e il download del file excel protrrebbe richiedere alcuni secondi', []), {
      nzKey: 'download-excel'
    });


    var res = await lastValueFrom(this.admSvc.exportBookedUsersExcelReport(
      this.saleEvent!.saleEventId, type, this.tsvc.currentLanguage.value, slotStartDate
    ));
    const url = window.URL.createObjectURL(res);

    // Create a link
    let link = document.createElement('a');
    link.href = url;

    link.download = this.saleEvent?.saleEventName + "_booked_user_list.xlsx";

    // Trigger click event on the link
    document.body.appendChild(link); // Required for Firefox
    link.click();
    console.log("fACCIO LINK CLICK")
    document.body.removeChild(link); // Required for Firefox

    this.notification.success(this.tranPipe.transform('export_excel_activity_downloaded_message_title', 'Download file excel completato', []),
      this.tranPipe.transform('export_excel_activity_downloaded_message_subtitle', 'Il file excel è stato generato e scaricato correttamente', []), {
      nzKey: 'download-excel'
    });

  }


  clickEvent(event: CalendarEvent) {
    this.clickedEvent = event;
  }
}
