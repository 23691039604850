import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Helper, PurpleApiMakeRequestResponse, PurpleApiProxyService, PurpleTranslationPipe, purpleFormFieldsGroup } from 'purple-lib';
import { AppTranslationService } from 'src/core-modules/localization/localization.service';
import { BackOfficeService, DefaultCoupon } from 'src/core-modules/sdk/api';
import { environment } from 'src/environments/default/environment';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalRef, NZ_MODAL_DATA } from 'ng-zorro-antd/modal';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-edit-default-coupon-modal',
  templateUrl: './edit-default-coupon-modal.component.html',
  styleUrls: ['./edit-default-coupon-modal.component.scss']
})
export class EditDefaultCouponModalComponent implements OnInit {

  sub: Subject<void> = new Subject();
  originalEditItem!: EditDefaultCouponItem;
  newObj!: EditDefaultCouponItem;
  isNew: boolean = false;

  validateForm: FormGroup = this.fb.group({});
  purpleFormFieldGroups: purpleFormFieldsGroup[] | undefined;
  disableForm: boolean = false;
  isLoading: boolean = false;

  constructor(@Inject(NZ_MODAL_DATA) private defaultCouponFull: DefaultCoupon | undefined, private modalRef: NzModalRef, private mesSvc: NzMessageService, private apiProxySvc: PurpleApiProxyService,
    private backSvc: BackOfficeService, private tsvc: AppTranslationService, private fb: FormBuilder, private tranPipe: PurpleTranslationPipe) { }


  async ngOnInit(): Promise<void> {
    this.originalEditItem = {
      defaultCouponId: this.defaultCouponFull?.defaultCouponId ?? undefined,
      defaultCouponName: this.defaultCouponFull?.defaultCouponName ?? undefined,
      isCouponDiscountMoneyEnabled: this.defaultCouponFull?.isCouponDiscountMoneyEnabled ?? false,
      isCouponDiscountPercentageEnabled: this.defaultCouponFull?.isCouponDiscountPercentageEnabled ?? true,
      couponDiscountMoney: this.defaultCouponFull?.couponDiscountMoney ?? undefined,
      couponDiscountPercentage: this.defaultCouponFull?.couponDiscountPercentage ?? undefined,
      couponMaxTotalRedeems: this.defaultCouponFull?.couponMaxTotalRedeems ?? undefined,
      couponMaxUserRedeems: this.defaultCouponFull?.couponMaxUserRedeems ?? undefined,
      couponIsCumulative: this.defaultCouponFull?.couponIsCumulative ?? false
    }

    this.isNew = this.defaultCouponFull == undefined;
    this.newObj = Helper.storeNewObj(this.originalEditItem);

    this.purpleFormFieldGroups = [
      {
        fieldGroupNumber: 1,
        fielGroupBootstrapColumn: 12,
        fieldGroupPaddingLeft: false,
        fieldGroupPaddingRight: false,
        formFieldFormGroup: this.validateForm,
        formFields: [
          {
            fieldType: 'input-text',
            fieldControlName: 'name',
            fieldBootstrapColumn: 12,
            fieldName: this.tranPipe.transform('edit_default_coupon_name', 'Nome', []),
            fieldPlaceholder: this.tranPipe.transform('edit_default_coupon_name_placeholder', 'Nome template coupon', []),
            fieldValue: this.newObj.defaultCouponName,
            fieldIsRequired: true,
            fieldPaddingLeft: false,
            fieldPaddingRight: false
          },
          {
            fieldType: 'toggle',
            fieldControlName: 'isDiscountMoneyEnabled',
            fieldBootstrapColumn: 12,
            fieldName: this.tranPipe.transform('edit_default_coupon_type', 'Tipologia Sconto', []),
            fieldPlaceholder: this.tranPipe.transform('edit_default_coupon_type_placeholder', '', []),
            fieldValue: this.newObj.isCouponDiscountMoneyEnabled,
            fieldIsRequired: false,
            fieldIsDisabled: false,
            fieldPaddingLeft: false,
            fieldPaddingRight: false,
            fieldOptions: [
              { id: 'checkText', value: this.tranPipe.transform('edit_default_coupon_type_toggle_money', 'Soldi', []) },
              { id: 'unCheckText', value: this.tranPipe.transform('edit_default_coupon_type_toggle_percentage', 'Percentuale', []) }
            ]
          },
          {
            fieldType: 'input-number',
            fieldControlName: 'couponDiscountMoney',
            fieldBootstrapColumn: 6,
            fieldName: this.tranPipe.transform('edit_default_coupon_discount_money', 'Sconto in denaro', []),
            fieldPlaceholder: this.tranPipe.transform('edit_default_coupon_discount_money_placeholder', 'Sconto in denaro', []),
            fieldValue: this.newObj.couponDiscountMoney,
            fieldIsRequired: this.newObj.isCouponDiscountMoneyEnabled? true : false,
            fieldIsDisabled: this.newObj.isCouponDiscountMoneyEnabled? false : true,
            fieldPaddingLeft: false,
            fieldPaddingRight: true,
            fieldOptions: [{ id: 'minNumber', value: 0.1 }]
          },
          {
            fieldType: 'input-number',
            fieldControlName: 'couponDiscountPercentage',
            fieldBootstrapColumn: 6,
            fieldName: this.tranPipe.transform('edit_default_coupon_discount_percentage', 'Sconto in percentuale', []),
            fieldPlaceholder: this.tranPipe.transform('edit_default_coupon_discount_percentage_placeholder', 'Sconto in percentuale', []),
            fieldValue: this.newObj.couponDiscountPercentage,
            fieldIsRequired: this.newObj.isCouponDiscountPercentageEnabled? true : false,
            fieldIsDisabled: this.newObj.isCouponDiscountPercentageEnabled? false : true,
            fieldPaddingLeft: true,
            fieldPaddingRight: false,
            fieldOptions: [{ id: 'minNumber', value: 0.1 }]
          },
          {
            fieldType: 'input-number',
            fieldControlName: 'couponMaxTotalRedeems',
            fieldBootstrapColumn: 6,
            fieldName: this.tranPipe.transform('edit_default_coupon_total_redeems', 'Numero massimo utilizzi', []),
            fieldPlaceholder: this.tranPipe.transform('edit_default_coupon_discount_total_redeems_placeholder', 'Numero massimo utilizzi per la sale', []),
            fieldValue: this.newObj.couponMaxTotalRedeems,
            fieldIsRequired: true,
            fieldIsDisabled: false,
            fieldPaddingLeft: false,
            fieldPaddingRight: true,
            fieldOptions: [{ id: 'minNumber', value: 1 }]
          },
          {
            fieldType: 'input-number',
            fieldControlName: 'couponMaxUserRedeems',
            fieldBootstrapColumn: 6,
            fieldName: this.tranPipe.transform('edit_default_coupon_user_redeems', 'Numero massimo utilizzi per utente', []),
            fieldPlaceholder: this.tranPipe.transform('edit_default_coupon_discount_user_redeems_placeholder', 'Numero massimo utilizzi per utente', []),
            fieldValue: this.newObj.couponMaxUserRedeems,
            fieldIsRequired: true,
            fieldIsDisabled: false,
            fieldPaddingLeft: true,
            fieldPaddingRight: false,
            fieldOptions: [{ id: 'minNumber', value: 1 }]
          },
          {
            fieldType: 'toggle',
            fieldControlName: 'isCumulative',
            fieldBootstrapColumn: 12,
            fieldName: this.tranPipe.transform('edit_default_coupon_is_cumulative', 'Comulabile', []),
            fieldPlaceholder: "",
            fieldValue: this.originalEditItem.couponIsCumulative,
            fieldIsRequired: false,
            fieldOptions: [
              { id: 'checkText', value: this.tranPipe.transform('switch_yes', 'Si', []) },
              { id: 'unCheckText', value: this.tranPipe.transform('switch_no', 'No', []) },
            ],
            fieldPaddingLeft: false,
            fieldPaddingRight: false
          }
        ]
      }
    ]

    
    setTimeout(() => {
      this.validateForm.controls["isDiscountMoneyEnabled"].valueChanges.pipe(takeUntil(this.sub)).subscribe((s: boolean) => {
        if (s) {
          this.validateForm.controls['couponDiscountMoney'].enable();
          this.validateForm.controls['couponDiscountMoney'].addValidators(Validators.required);

          this.validateForm.controls['couponDiscountPercentage'].disable();
          this.validateForm.controls['couponDiscountPercentage'].removeValidators(Validators.required);
        } else {
          this.validateForm.controls['couponDiscountPercentage'].enable();
          this.validateForm.controls['couponDiscountPercentage'].addValidators(Validators.required);

          this.validateForm.controls['couponDiscountMoney'].disable();
          this.validateForm.controls['couponDiscountMoney'].removeValidators(Validators.required);
        }
      });
    }, 100);
  }

  ngOnDestroy(): void {

    this.sub.next();
    this.sub.complete();
  }

  async editCoupon() {
    this.isLoading = true;
    for (const i in this.validateForm.controls) {
      this.validateForm.controls[i].markAsDirty();
      this.validateForm.controls[i].updateValueAndValidity();
    }

    if (this.validateForm.valid) {
      this.newObj.defaultCouponName = this.validateForm.controls["name"].value;
      this.newObj.isCouponDiscountMoneyEnabled = this.validateForm.controls["isDiscountMoneyEnabled"].value;
      this.newObj.isCouponDiscountPercentageEnabled = !this.validateForm.controls["isDiscountMoneyEnabled"].value;
      this.newObj.couponDiscountMoney = this.validateForm.controls["couponDiscountMoney"].value;
      this.newObj.couponDiscountPercentage = this.validateForm.controls["couponDiscountPercentage"].value;
      this.newObj.couponMaxTotalRedeems = this.validateForm.controls["couponMaxTotalRedeems"].value;
      this.newObj.couponMaxUserRedeems = this.validateForm.controls["couponMaxUserRedeems"].value;
      this.newObj.couponIsCumulative = this.validateForm.controls["isCumulative"].value;

      if (Helper.checkIfDoChanges(this.newObj, this.originalEditItem)) {

        await this.apiProxySvc.makeRequestErrorMessage<Boolean>(() => this.backSvc.addOrUpdateCouponBase(this.tsvc.translationLanguage.value, {
          isNew: this.isNew,
          couponIsCumulative: this.newObj.couponIsCumulative,
          couponMaxTotalRedeems: this.newObj.couponMaxTotalRedeems!,
          couponMaxUserRedeems: this.newObj.couponMaxUserRedeems!,
          defaultCouponName: this.newObj.defaultCouponName!,
          isCouponDiscountMoneyEnabled: this.newObj.isCouponDiscountMoneyEnabled,
          isCouponDiscountPercentageEnabled: this.newObj.isCouponDiscountPercentageEnabled,
          couponDiscountMoney: this.newObj.couponDiscountMoney,
          couponDiscountPercentage: this.newObj.couponDiscountPercentage,
          defaultCouponId: this.newObj.defaultCouponId
        }), false, "internal-loader", 500, undefined, undefined, (res: PurpleApiMakeRequestResponse<Boolean>) => {
          let text: string;
          if (this.isNew) {
            text = this.tranPipe.transform('edit_default_coupon_add', 'Template coupon aggiunto con successo')
          } else {
            text = this.tranPipe.transform('edit_default_coupon_update', 'Template coupon aggiornato con successo')
          }
          this.mesSvc.success(text, {
            nzDuration: environment.MESSAGE_DURATION
          });
          this.modalRef.destroy(res.data);
        })

      }
    }
    this.isLoading = false
  }
}

class EditDefaultCouponItem {
  defaultCouponId: string | undefined;
  defaultCouponName: string | undefined;
  couponDiscountPercentage?: number | undefined;
  isCouponDiscountPercentageEnabled!: boolean;
  couponDiscountMoney?: number | undefined;
  isCouponDiscountMoneyEnabled!: boolean;
  couponMaxTotalRedeems: number | undefined;
  couponMaxUserRedeems: number | undefined;
  couponIsCumulative!: boolean;
}
