import { Component, Inject, Input, OnInit } from '@angular/core';
import { PurpleTranslationPipe, PurpleApiMakeRequestResponse, PurpleApiProxyService } from 'purple-lib';
import { AppTranslationService } from 'src/core-modules/localization/localization.service';
import { BackOfficeService } from 'src/core-modules/sdk/api';
import { environment } from 'src/environments/default/environment';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalRef, NZ_MODAL_DATA } from 'ng-zorro-antd/modal';
import { NzUploadFile } from 'ng-zorro-antd/upload';

@Component({
  selector: 'app-import-private-sale-users-modal',
  templateUrl: './import-private-sale-users-modal.component.html',
  styleUrls: ['./import-private-sale-users-modal.component.scss']
})
export class ImportPrivateSaleUsersModalComponent implements OnInit {

  constructor(@Inject(NZ_MODAL_DATA) private saleEventId: string, private mesSvc: NzMessageService, private tranPipe: PurpleTranslationPipe, private backSvc: BackOfficeService,
    private tsvc: AppTranslationService, private modalRef: NzModalRef, private apiProxySvc: PurpleApiProxyService) { }

  ngOnInit() {
  }
  fileList: NzUploadFile[] = [];
  isLoading = false;

  beforeUpload = (file: NzUploadFile): boolean => {
    this.fileList = this.fileList.concat(file);
    return false;
  };


  async uploadFile(): Promise<void> {
    this.isLoading = true;

    console.log("FiliList: ", this.fileList, " - ", (this.fileList[0].originFileObj));

    await this.apiProxySvc.makeRequestErrorMessage<boolean>(() => this.backSvc.importExcelInvites(this.tsvc.currentLanguage.value, (this.fileList[0]! as any), this.saleEventId),
      false, "internal-loader", 500, undefined, 10000, (res: PurpleApiMakeRequestResponse<boolean>) => {
        this.mesSvc.success(this.tranPipe.transform('file_upload_success', '"{0}" caricato con successo', [this.fileList[0].name]), { nzDuration: environment.MESSAGE_DURATION });
        this.isLoading = false;
        this.modalRef.close(true);
      });

    this.isLoading = false;
  }

}
