<nz-tabset class="roles-tabset" [(nzSelectedIndex)]="selectedIndex" (nzSelectedIndexChange)="changeRoleTab($event)"
  nzType="editable-card" (nzAdd)="addActiveSaleRole()" (nzClose)="removeActiveSaleRole($event)"
  [nzCanDeactivate]="canDeactivateRoleTab">
  <nz-tab *ngFor="let tab of tabs" [nzClosable]="tab.canBeRemoved" [nzTitle]="tab.roleName">
    <nz-spin [nzSpinning]="isLoading" [nzIndicator]="purpleTabLoaderTemplate" [style.height]="tabHeight">
      <nz-tabset class="role-menu-tabset {{settingSelectedIndex==2?'hide-sroll':''}}" nzTabPosition="left"
        [(nzSelectedIndex)]="settingSelectedIndex" (nzSelectedIndexChange)="changeSettingTab($event)"
        [nzCanDeactivate]="canDeactivateSettingTab">
        <nz-tab [nzTitle]="'private_sale_roles_settingd_tab' | translation: 'Impostazioni': []">
          <!-- <h1 class="ps-w-100 private-sale-role-title first">{{ 'private_sale_role_settings_title' | translation:
          'Impostazioni': []}}</h1> -->
          <div class="ps-w-100 ps-d-flex private-sale-role-external-container">
            <div *ngIf="this.currentActiveRole != undefined"
              class="ps-w-100 ps-d-flex ps-flex-wrap ps-justify-content-center private-sale-role-container">
              <ng-container *ngFor="let formInfo of salePurpleFormFieldGroups; let i = index;">
                <div class="ps-d-flex ps-w-100 private-sale-role-item {{i%2==0?'':'odd'}}">
                  <div class="form-section-title-container">
                    <p class="form-section-title">
                      {{formInfo.formSectionName}}
                    </p>
                    <div *ngIf="formInfo.canBeDisabled??false" class="ps-w-100 ps-d-flex">
                      <nz-select class="status-select"
                        [nzPlaceHolder]="'private_sale_role_referral_select_placeholder' | translation: 'Stato'"
                        [nzShowArrow]="true" [ngModel]="formInfo.isDisabled"
                        (ngModelChange)="changeFormStatus(formInfo.formSectionId, $event)">
                        <nz-option [nzLabel]="'private_sale_role_referral_select_active' | translation: 'Attivo': []"
                          [nzValue]="false"></nz-option>
                        <nz-option
                          [nzLabel]="'private_sale_role_referral_select_disable' | translation: 'Disabilitato': []"
                          [nzValue]="true"></nz-option>
                      </nz-select>
                    </div>
                    <div *ngIf="(currentActiveRole.baseInfo.isReferralActive??false) && (formInfo.showReferralLink??false)" class="ps-w-100 ps-d-flex ps-pt-3">
                      <a (click)="copyReferral($event)">
                        {{ "role_referrals_table_edit_referral" | translation: "Copia Codice Invito"}}
                      </a>
                    </div>
                  </div>
                  <div class="form-section-value-external-container">
                    <div class="form-section-value-container">
                      <container-element [ngSwitch]="formInfo.showSpin??false">
                        <ng-container *ngSwitchCase="true">
                          <nz-spin [nzSpinning]="getSpinnerStatus('description')"
                            [nzIndicator]="purpleTabLoaderTemplate">
                            <app-purple-form [(formGroup)]="validateForm"
                              [purpleFormFieldGroups]="formInfo.purpleFormGroups">
                            </app-purple-form>
                          </nz-spin>
                        </ng-container>
                        <ng-container *ngSwitchCase="false">
                          <app-purple-form [(formGroup)]="validateForm"
                            [purpleFormFieldGroups]="formInfo.purpleFormGroups">
                          </app-purple-form>
                        </ng-container>
                      </container-element>
                    </div>
                  </div>
                </div>
                <div *ngIf="(formInfo.showLine??true)" class="line"></div>
              </ng-container>
            </div>
          </div>
        </nz-tab>
        <nz-tab [nzTitle]="'private_sale_role_reserved_slots_tab' | translation: 'Slot Riservati': []">
          <!-- <h1 class="ps-w-100 private-sale-role-title first">{{ 'private_sale_role_settings_reserved_slots' | translation:
          'Slot Riservati': []}}</h1> -->
          <div class="ps-w-100 ps-d-flex ps-flex-wrap private-sale-role-slots-container">
            <div *ngFor="let slotDay of (currentActiveRole?.slots??[]); let i = index"
              class="private-sale-role-slot ps-d-flex ps-align-items-center {{i%2==0?'':'odd'}}">
              <div class="date-container ps-d-flex ps-flex-wrap ps-align-items-center">
                <p class="ps-w-100 year">
                  {{slotDay.dateDay | date: "yyyy"}}
                </p>
                <h2 class="ps-w-100 day">
                  {{slotDay.dateDay | date: "dd"}}
                </h2>
                <p class="ps-w-100 month">
                  {{slotDay.dateDay | date: "MMM"}}
                </p>
              </div>
              <div class="slot-info-container ps-d-flex ps-flex-wrap ps-align-items-center">
                <div *ngFor="let slot of slotDay.slots"
                  class="hour-slot-item ps-d-flex ps-flex-wrap {{slot.isMixedSlot?'mixed':'reserved'}}">
                  <div class="ps-d-flex ps-justify-content-between ps-w-100 ps-align-items-center title-container">
                    <h2 class="hour">
                      {{slot.slotStartDate | date: "HH:mm"}} - {{slot.slotEndDate | date: "HH:mm"}}
                    </h2>
                    <p *ngIf="slot.isMixedSlot" class="ps-m-0 mixed">
                      {{ 'private_sale_role_slot_mixed' | translation: 'misto': []}}
                    </p>
                  </div>

                  <div class="ps-w-100 ps-d-flex ps-align-items-center property">
                    <p class="property-title">
                      {{ 'private_sale_role_slot_total_ctn' | translation: 'Posti Totali:': []}}
                    </p>
                    <p class="property-value">
                      {{ getCapacityValue(slot.slotTotalCapacity)}}
                    </p>
                  </div>
                  <div class="ps-w-100 ps-d-flex ps-align-items-center property">
                    <p class="property-title">
                      {{ 'private_sale_role_slot_available_ctn' | translation: 'Posti Disponibili:': []}}
                    </p>
                    <p class="property-value">
                      {{ getCapacityValue(slot.slotAvailableCapacity)}}
                    </p>
                  </div>
                  <div class="ps-w-100 ps-d-flex ps-align-items-center property">
                    <p class="property-title">
                      {{ 'private_sale_role_slot_booked_ctn' | translation: 'Posti Prenotati:': []}}
                    </p>
                    <p class="property-value">
                      {{ getCapacityValue(slot.slotBookedCapacity)}}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </nz-tab>
        <nz-tab [nzTitle]="'private_sale_role_users_tab' | translation: 'Utenti': []">
          <purple-table *ngIf="currentActiveRole!= undefined && currentActiveRole.baseInfo.roleId == tab.roleId "
            #purpleTable [tableBody]="tableBody" [isBordered]="true" [switchOn]="false"
            [refreshFunction]="refreshFunction" (tableData)="listOfData = $event" [tableColumns]="tabColumns"
            [tableScroll]="{y:dashSvc.dashboardHeight}" [refreshTableRowsNumber]="refreshTableRowsNumber" [version]="2"
            [showCustomPagination]="true">

            <ng-template #tableBody>
              <ng-container *ngFor="let data of listOfData; let i = index">
                <tr class="{{i%2==0?'main-purple-table-tr-even': 'main-purple-table-tr-odd'}} ">
                  <td>{{ data.item.sourceFirstName }}</td>
                  <td>{{ data.item.sourceLastName }}</td>
                  <td>{{ data.item.sourceEmail }}</td>
                  <td>{{ data.item.sourceUserCompany }}</td>
                  <td nzAlign="center">
                    <container-element [ngSwitch]="data.item.sourceUserIsPending">
                      <ng-container *ngSwitchCase="true">
                        <p class="ps-m-0 ps-p-0 fw-b">{{ 'is_pending_yes' | translation: 'Si': []}}</p>
                      </ng-container>
                      <ng-container *ngSwitchCase="false">
                        <p class="ps-m-0 ps-p-0 fw-b">{{ 'is_pending_no' | translation: 'No': []}}</p>

                      </ng-container>
                    </container-element>
                  </td>
                  <td nzAlign="center">
                    <container-element [ngSwitch]="data.item.isInvited">
                      <ng-container *ngSwitchCase="true">
                        <p class="ps-m-0 ps-p-0 fw-b">{{ 'is_pending_yes' | translation: 'Si': []}}</p>
                      </ng-container>
                      <ng-container *ngSwitchCase="false">
                        <p class="ps-m-0 ps-p-0 fw-b">{{ 'is_pending_no' | translation: 'No': []}}</p>

                      </ng-container>
                    </container-element>
                  </td>
                  <td>
                    <container-element [ngSwitch]="data.item.isInvited">
                      <ng-container *ngSwitchCase="true">
                        <p class="ps-m-0 ps-p-0">{{data.item.invitedByUserFirstName}}
                          {{data.item.invitedByUserLastName}}</p>
                      </ng-container>
                      <ng-container *ngSwitchCase="false">
                        <p class="ps-m-0 ps-p-0 fw-b">-</p>
                      </ng-container>
                    </container-element>
                  </td>

                  <td nzAlign="center">
                    <button (click)="data.expanded = !data.expanded" [nzTooltipTitle]='
                          data.expanded?
                          ("hide_more_button_sale_event_user_table" | translation: "Nascondi dati aggiuntivi"):
                          ("show_more_button_sale_event_user_table" | translation: "Mostra più dati")
                          ' nzTooltipPlacement="top" class="ps-mr-3" nz-tooltip nz-button nzType="default"
                      nzShape="circle"><i nz-icon [nzType]="data.expanded?'eye-invisible':'eye'"></i>
                    </button>

                    <!-- <button (click)="pendingUser(data.item!)" class="ps-mr-3"
                      [nzTooltipTitle]='getPendingTooltipTitle(data.item!)' nzTooltipPlacement="top" nz-tooltip
                      nz-button nzType="default" nzShape="circle">
                      <i nz-icon [nzType]="data.item.sourceUserIsPending?'check':'stop'"></i>
                    </button>

                    <button (click)="reinviteUser(data.item!)" class="ps-mr-3"
                      [nzTooltipTitle]="'reinvite_user_tooltip' | translation: 'Invita utente': []"
                      nzTooltipPlacement="top" nz-tooltip nz-button nzType="default" nzShape="circle">
                      <i nz-icon nzType="send"></i>
                    </button>

                    <button (click)="deleteUser(data.item!)" class="ps-mr-3"
                      [nzTooltipTitle]="'delete_user_tooltip' | translation: 'Elimina utente': []"
                      nzTooltipPlacement="top" nz-tooltip nz-button nzType="default" nzShape="circle">
                      <i nz-icon nzType="delete"></i>
                    </button> -->
                  </td>
                </tr>
                <tr [nzExpand]="data.expanded">
                  <div class="ps-d-flex ps-w-100 ps-justify-content-center">
                    <div
                      class="ps-d-flex ps-flex-wrap ps-align-items-start ps-w-100 ps-justify-content-between table-expanded-row-container">

                      <nz-descriptions class="ps-w-100"
                        [nzTitle]="'sale_event_user_table_expanded_row_title' | translation: 'Informazioni aggiuntive': []"
                        nzBordered [nzColumn]="{ xxl: 2, xl: 2, lg: 2, md: 2, sm: 1, xs: 1 }">
                        <nz-descriptions-item [nzSpan]="4"
                          [nzTitle]="'sale_event_user_table_invited' | translation: 'Invitati': []">
                          <container-element [ngSwitch]="data.item.hasInvite">
                            <ng-container *ngSwitchCase="true">
                              <div class="ps-w-100 ps-d-flex ps-flex-wrap">
                                <nz-tag *ngFor="let invited of data.item.invited" class="tag ps-mt-2"
                                  [nzColor]="invited.isActionCompleted?'green':'red'"
                                  [nzTooltipTitle]="'invited_role' | translation: 'Ruolo: {0}': [invited.targetRoleName]"
                                  nzTooltipPlacement="top" nz-tooltip>
                                  {{invited.targetFirstName}} {{invited.targetLastName}} ({{invited.targetEmail}})
                                </nz-tag>
                              </div>
                            </ng-container>
                            <ng-container *ngSwitchCase="false">
                              <p class="ps-m-0 ps-p-0">
                                {{ 'sale_event_user_table_no_invite' | translation: 'Ancora nessuna persona invitata':
                                []}}</p>
                            </ng-container>
                          </container-element>
                        </nz-descriptions-item>
                        <nz-descriptions-item [nzTitle]="'sale_event_user_table_role' | translation: 'Ruolo': []">
                          {{data.item.sourceRoleName}}
                        </nz-descriptions-item>
                        <nz-descriptions-item
                          [nzTitle]="'sale_event_user_table_booking' | translation: 'Prenotazione': []">
                          <container-element [ngSwitch]="(data.item.sourceUserBookedSlot??undefined) != undefined">
                            <ng-container *ngSwitchCase="true">
                              <p class="ps-m-0 ps-p-0">{{data.item.sourceUserBookedSlot | date: "dd MMM yyyy HH:mm"}}
                              </p>
                            </ng-container>
                            <ng-container *ngSwitchCase="false">
                              <p class="ps-m-0 ps-p-0 ">
                                {{ 'sale_event_user_table_no_book' | translation: 'Nessuno slot prenotato': []}}</p>
                            </ng-container>
                          </container-element>
                        </nz-descriptions-item>
                      </nz-descriptions>
                    </div>
                  </div>
                </tr>
              </ng-container>
            </ng-template>
          </purple-table>
        </nz-tab>
      </nz-tabset>


    </nz-spin>
  </nz-tab>
</nz-tabset>


<ng-template #purpleTabLoaderTemplate>
  <div class="tab-custom-spinner">
    <span nz-icon nzType="loading"></span>
  </div>
</ng-template>