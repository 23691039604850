<ng-container *ngIf="dashSvc.dashboardHeight != undefined">
  <purple-table #purpleTable [tableBody]="tableBody" [isBordered]="true" [switchOn]="false"
    [refreshFunction]="refreshFunction" (tableData)="listOfData = $event" [tableColumns]="tabColumns"
    [tableScroll]="{y:dashSvc.dashboardHeight}" [refreshTableRowsNumber]="refreshTableRowsNumber" [version]="2"
    [showCustomPagination]="true" tableSearchType="box" (filterBox)="dashSvc.setPurpleTablePopover($event)"
    (onSearchBoxEnd)="dashSvc.closePurpleTablePopover()" [filterBoxStatus]="dashSvc.purpleTableBoxFilterStatus">

    <ng-template #tableBody>
      <ng-container *ngFor="let data of listOfData; let i = index">
        <tr class="{{i%2==0?'main-purple-table-tr-even': 'main-purple-table-tr-odd'}} ">
          <td>{{ data.item.firstName }}</td>
          <td>{{ data.item.lastName }}</td>
          <td>
            <div class="ps-w-100 ps-d-flex ps-flex-wrap ps-align-items-center">
              <div class="verified-status-dot {{data.item.emailVerified?'verified':''}}"></div>
              <div class="verified-status-right">
                {{ data.item.email }}
              </div>
            </div>
          </td>
          <td>
            <div class="ps-w-100 ps-d-flex ps-flex-wrap ps-align-items-center">
              <div class="verified-status-dot {{data.item.phoneVerified?'verified':''}}"></div>

              <div class="verified-status-right">
                <span *ngIf="(data.item.phone??undefined)!= undefined">
                  <span *ngIf="(data.item.phonePrefix??undefined)!= undefined">{{data.item.phonePrefix}}</span>
                  {{data.item.phone}}
                </span>
                <span *ngIf="(data.item.phone??undefined)== undefined">
                  {{ 'customer_table_not_specify' | translation: 'Non specificato': [] }}
                </span>

              </div>


            </div>

          </td>
          <td>{{ data.item.membership.membershipName | titlecase }}</td>
          <td>{{ (data.item.membership.membershipEndDate | date:'dd MMM yyyy (HH:mm)')??"-" }}</td>

          <!-- <td>{{ data.item.registrationDate | date:'dd MMM yyyy (HH:mm)' }}</td> -->



          <td nzAlign="center">
            <button (click)="data.expanded = !data.expanded"
              [routerLink]="['/', tsvc.currentLanguage.value, 'dashboard', 'administration', 'user-detail']"
              [queryParams]="{userId: data.item.userId}"
              [nzTooltipTitle]='"show_user_detail_customer_table" | translation: "Mostra scheda utente"'
              nzTooltipPlacement="top" class="ps-mr-3" nz-tooltip nz-button nzType="default" nzShape="circle">
              <i nz-icon class="fsp-18-0" nzType="plus"></i>
            </button>

            <!-- <button (click)="data.expanded = !data.expanded" [nzTooltipTitle]='
              data.expanded?
              ("hide_more_button_customer_table" | translation: "Nascondi dati aggiuntivi"):
              ("show_more_button_customer_table" | translation: "Mostra più dati")' nzTooltipPlacement="top"
              class="ps-mr-3" nz-tooltip nz-button nzType="default" nzShape="circle"><i nz-icon class="fsp-18-0"
                [nzType]="data.expanded?'eye-invisible':'eye'"></i>
            </button> -->

            <button (click)="editUser(data.item!)"
              [nzTooltipTitle]='"edit_button_customer_table" | translation: "Modifica cliente"' nzTooltipPlacement="top"
              nz-tooltip class="ps-mr-3" nz-button nzType="default" nzShape="circle"><i nz-icon class="fsp-18-0"
                nzType="edit"></i></button>

            <button nz-popover nzPopoverPlacement="left"
              [nzPopoverTitle]='"edit_user_show_more" | translation: "Altre azioni"' nzPopoverTrigger='click'
              [nzPopoverContent]="userMoreActions"
              [nzTooltipTitle]='"edit_user_show_more" | translation: "Altre azioni"' nzTooltipPlacement="top" nz-tooltip
              class="ps-mr-3" nz-button nzType="default" nzShape="circle"><i nz-icon nzType="unordered-list"
                class="fsp-18-0" nzTheme="outline"></i></button>

            <ng-template #userMoreActions>
              <div class="ps-d-flex ps-flex-wrap">

                <!-- <button (click)="deleteUser(data.item!)" class="ps-mr-3"
                [nzTooltipTitle]='"delete_button_customer_table" | translation: "Elimina cliente"'
                nzTooltipPlacement="top" nz-tooltip nz-button nzType="default" nzShape="circle">
                <i nz-icon class="fsp-18-0" nzType="delete"></i>
              </button> -->

                <button (click)="pendingUser(data.item!)" class="ps-mr-3"
                  [nzTooltipTitle]='getPendingTooltipTitle(data.item!)' nzTooltipPlacement="top" nz-tooltip nz-button
                  nzType="default" nzShape="circle">
                  <i nz-icon class="fsp-18-0" [nzType]="data.item.isPending?'check':'stop'"></i>
                </button>

                <button (click)="resetPassword(data.item!)" class="ps-mr-3"
                  [nzTooltipTitle]='"reset_password_button_customer_table" | translation: "Resetta Password Utente"'
                  nzTooltipPlacement="top" nz-tooltip nz-button nzType="default" nzShape="circle">
                  <i nz-icon class="fsp-18-0" nzType="redo"></i>
                </button>

                <button (click)="anonymizeUser(data.item!)" *ngIf="!data.item.isAnonymous" class="ps-mr-3"
                  [nzTooltipTitle]='"anonymize_user_email_button_customer_table" | translation: "Anonimizza Utente"'
                  nzTooltipPlacement="top" nz-tooltip nz-button nzType="default" nzShape="circle">
                  <i nz-icon class="fsp-18-0" nzType="clear"></i>
                </button>

                <button (click)="verifyUserEmail(data.item!)" *ngIf="!data.item.emailVerified" class="ps-mr-3"
                  [nzTooltipTitle]='"verify_user_email_button_customer_table" | translation: "Verifica email"'
                  nzTooltipPlacement="top" nz-tooltip nz-button nzType="default" nzShape="circle">
                  <i nz-icon class="fsp-18-0" nzType="check"></i>
                </button>

                <button (click)="verifyUserPhone(data.item!)" *ngIf="!data.item.phoneVerified" class="ps-mr-3"
                  [nzTooltipTitle]='"verify_user_phone_button_customer_table" | translation: "Verifica telefono"'
                  nzTooltipPlacement="top" nz-tooltip nz-button nzType="default" nzShape="circle">
                  <i nz-icon class="fsp-18-0" nzType="check"></i>
                </button>

                <button (click)="buyUserMembership(data.item!)" class="ps-mr-3"
                  [nzTooltipTitle]='"buy_user_membership_customer_table" | translation: "Gestione Membership"'
                  nzTooltipPlacement="top" nz-tooltip nz-button nzType="default" nzShape="circle">
                  <i nz-icon class="fsp-18-0" nzType="crown"></i>
                </button>

                <button (click)="editUserAddresses(data.item!)"
                  [nzTooltipTitle]='"edit_user_addresses_button_customer_table" | translation: "Gestione Indirizzi Utente"'
                  nzTooltipPlacement="top" nz-tooltip nz-button nzType="default" nzShape="circle">
                  <i nz-icon class="fsp-18-0" nzType="home"></i>
                </button>


              </div>
            </ng-template>
          </td>
        </tr>
        <tr #expandedRow [nzExpand]="data.expanded">
          <div class="ps-d-flex ps-w-100 ps-justify-content-center">
            <div
              class="ps-d-flex ps-flex-wrap ps-align-items-start ps-w-100 ps-justify-content-between table-expanded-row-container">
              <nz-descriptions class="ps-w-100"
                [nzTitle]="'customer_table_expanded_row_title' | translation: 'Informazioni aggiuntive': []" nzBordered
                [nzColumn]="{ xxl: 2, xl: 2, lg: 2, md: 2, sm: 1, xs: 1 }">
                <nz-descriptions-item [nzTitle]="'customer_table_gender' | translation: 'Sesso': []">
                  {{data.item.genderId | gender}}
                </nz-descriptions-item>

                <nz-descriptions-item [nzTitle]="'customer_table_birthdate' | translation: 'Data di nascita': []">
                  <span *ngIf="(data.item.birthDate??undefined)!= undefined">
                    {{data.item.birthDate | date:"dd MMM yyyy"}}
                  </span>
                  <span *ngIf="(data.item.birthDate??undefined)== undefined">
                    {{ 'customer_table_not_specify_w' | translation: 'Non specificata': [] }}
                  </span>
                </nz-descriptions-item>

                <nz-descriptions-item [nzTitle]="'customer_table_email_verified' | translation: 'Email Verificata': []">
                  <nz-badge [nzStatus]="data.item.emailVerified?'success':'error'"
                    [nzText]="data.item.emailVerified?'Si':'No'">
                  </nz-badge>
                </nz-descriptions-item>

                <nz-descriptions-item
                  [nzTitle]="'customer_table_external_id' | translation: 'Identificativo Esterno': []">
                  <span *ngIf="(data.item.externalId??undefined)!= undefined">
                    {{data.item.externalId}}
                  </span>
                  <span *ngIf="(data.item.externalId??undefined)== undefined">
                    {{ 'customer_table_not_specify' | translation: 'Non specificato': [] }}
                  </span>

                </nz-descriptions-item>

                <nz-descriptions-item [nzTitle]="'customer_table_user_company' | translation: 'Azienda': []">
                  {{data.item.userCompany?data.item.userCompany:'customer_table_not_specify'
                  | translation: 'Non specificato': []}}
                </nz-descriptions-item>

                <nz-descriptions-item
                  [nzTitle]="'customer_table_user_company_job_role' | translation: 'Ruolo in Azienda': []">
                  {{data.item.userCompanyJobRole?data.item.userCompanyJobRole:'customer_table_not_specify' |
                  translation: 'Non specificato': []}}
                </nz-descriptions-item>

                <nz-descriptions-item [nzTitle]="'customer_table_is_anonymouys' | translation: 'Anonimizzato': []">
                  <nz-badge [nzStatus]="data.item.isAnonymous?'success':'error'"
                    [nzText]="data.item.isAnonymous?'Si':'No'">
                  </nz-badge>
                </nz-descriptions-item>

                <nz-descriptions-item [nzTitle]="'customer_table_is_pending' | translation: 'Bloccato': []">
                  <nz-badge [nzStatus]="!data.item.isPending?'success':'error'"
                    [nzText]="data.item.isPending?'Si':'No'">
                  </nz-badge>
                </nz-descriptions-item>

                <nz-descriptions-item *ngIf="data.item.isAnonymous"
                  [nzTitle]="'customer_table_owner' | translation: 'Referente Anonimizzazione': []">
                  {{data.item.owner.ownerName}}
                </nz-descriptions-item>


              </nz-descriptions>
            </div>
          </div>
        </tr>
      </ng-container>
    </ng-template>
  </purple-table>
</ng-container>