<div class="user-detail-container" *ngIf="userDetail != undefined">
  <nz-descriptions class="ps-col-6 ps-pl-0" nzBordered
    [nzTitle]="'user_detail_basic_info_title' | translation: 'Informazioni base': []"
    [nzColumn]="{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }">

    <nz-descriptions-item [nzTitle]="'user_detail_basic_info_firstname' | translation: 'Nome': []">
      {{userDetail.firstName}}
    </nz-descriptions-item>

    <nz-descriptions-item [nzTitle]="'user_detail_basic_info_lastname' | translation: 'Cognome': []">
      {{userDetail.lastName}}
    </nz-descriptions-item>

    <nz-descriptions-item [nzTitle]="'user_detail_basic_info_email' | translation: 'Email': []">
      {{userDetail.email}}
    </nz-descriptions-item>

    <nz-descriptions-item [nzTitle]="'user_detail_basic_info_phone' | translation: 'Phone': []">
      {{userDetail.phonePrefix}} {{userDetail.phone}}
    </nz-descriptions-item>

    <nz-descriptions-item [nzTitle]="'user_detail_basic_info_membership' | translation: 'Membership': []">
      {{userDetail.memberships.membershipName | titlecase}}
    </nz-descriptions-item>

    <nz-descriptions-item
      [nzTitle]="'user_detail_basic_info_membership_expire' | translation: 'Scadenza Membership': []">
      {{userDetail.memberships.membershipEndDate | date: "dd MMMM yyyy"}}
    </nz-descriptions-item>

  </nz-descriptions>

  <nz-descriptions class="ps-col-6 ps-pr-0" nzBordered
    [nzTitle]="'user_detail_advanced_info_title' | translation: 'Informazioni aggiuntive': []"
    [nzColumn]="{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }">

    <nz-descriptions-item [nzTitle]="'user_detail_advanced_info_gender' | translation: 'Genere': []">
      {{userDetail.genderId | gender}}
    </nz-descriptions-item>

    <nz-descriptions-item [nzTitle]="'user_detail_advanced_info_birthdate' | translation: 'Data di nascita': []">
      {{userDetail.birthDate | date: "dd MMMM yyyy"}}
    </nz-descriptions-item>

    <nz-descriptions-item
      [nzTitle]="'user_detail_advanced_info_registration_date' | translation: 'Data registrazione': []">
      {{userDetail.registrationDate | date: "dd MMMM yyyy"}}
    </nz-descriptions-item>

    <nz-descriptions-item [nzTitle]="'user_detail_advanced_info_company' | translation: 'Azienda': []">
      {{userDetail.userCompany}} <span *ngIf="userDetail.userCompanyJobRole">({{userDetail.userCompanyJobRole}})</span>
    </nz-descriptions-item>

  </nz-descriptions>

  <nz-descriptions class="ps-col-12 ps-px-0 space" nzBordered
    [nzTitle]="'user_detail_attributes_title' | translation: 'Attributi utente': []"
    [nzColumn]="{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }">

    <nz-descriptions-item *ngFor="let att of userDetail.attributes" [nzTitle]="att.attributeName">
      {{(att.userAttributeValues.length > 0? att.userAttributeValues.join(", ") :
      'user_detail_not_attributes' | translation: 'Non specificato': []
      )}}

    </nz-descriptions-item>
  </nz-descriptions>

  <nz-descriptions class="ps-col-6 ps-pl-0 space" nzBordered
    [nzTitle]="'user_detail_consumer_behaviour_title' | translation: 'Consumer behaviour': []"
    [nzColumn]="{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }">

    <nz-descriptions-item [nzTitle]="'user_detail_consumer_behaviour_user_blocked' | translation: 'Bloccato': []">
      {{userDetail.isPending? 'Si': 'No'}}
    </nz-descriptions-item>

    <nz-descriptions-item [nzTitle]="'user_detail_consumer_behaviour_user_bookings' | translation: 'Booked': []">
      {{userDetail.bookingCounters.bookings}}
    </nz-descriptions-item>

    <nz-descriptions-item [nzTitle]="'user_detail_consumer_behaviour_user_check_ins' | translation: 'Check in': []">
      {{userDetail.bookingCounters.shows}}
    </nz-descriptions-item>

    <nz-descriptions-item [nzTitle]="'user_detail_consumer_behaviour_user_no_show' | translation: 'No show': []">
      {{userDetail.bookingCounters.noShows}}
    </nz-descriptions-item>
  </nz-descriptions>

  <nz-descriptions class="ps-col-6 ps-pr-0 space" nzBordered
    [nzTitle]="'user_detail_shopping_behaviour_title' | translation: 'Shopping behaviour': []"
    [nzColumn]="{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }">

    <nz-descriptions-item [nzTitle]="'user_detail_consumer_shopping_spend_tot' | translation: 'Shop spend Tot': []">
      {{getPriceNormalized(userDetail.shopBehaviour.shopSpendTot)}}
    </nz-descriptions-item>

    <nz-descriptions-item [nzTitle]="'user_detail_consumer_shopping_max_tot' | translation: 'Shop spend Max': []">
      {{getPriceNormalized(userDetail.shopBehaviour.shopSpendMax)}}
    </nz-descriptions-item>

    <nz-descriptions-item [nzTitle]="'user_detail_consumer_shopping_avg_tot' | translation: 'Shop spend Avarage': []">
      {{getPriceNormalized(userDetail.shopBehaviour.shopSpendAvg)}}
    </nz-descriptions-item>

  </nz-descriptions>

  <p class="history-table-title">
    {{ 'user_detail_history_title' | translation: 'History': []}}
  </p>
  <nz-spin class="ps-w-100" [nzSpinning]="isLoadingBookings">
    
  <table class="history-table">
    <tr class="history-table-header">
      <th>
        {{ 'user_detail_history_events' | translation: 'Events': []}}
      </th>
      <th>
        {{ 'user_detail_history_check_in_ok' | translation: 'Presente': []}}
      </th>
      <th>
        {{ 'user_detail_history_check_slot_booked' | translation: 'Slot booked': []}}
      </th>
      <th>
        {{ 'user_detail_history_check_shop_spend' | translation: 'Shop spend': []}}
      </th>
    </tr>
    @if(userDetail.bookingHistory.bookings.length > 0) {
      <tr *ngFor="let book of userDetail.bookingHistory.bookings" class="history-table-content">
        <td>
          {{book.saleEventName| titlecase}}
        </td>
        <td>
          {{book.checkInOk? "Si" : "No"}}
        </td>
        <td>
          <div [innerHTML]="getBookedSlotStr(book)">
          </div>
        </td>
        <td>
          <nz-collapse nzExpandIconPosition="right" [nzGhost]="true">
            <nz-collapse-panel class="{{i>0?'line-space':''}}" *ngFor="let tran of book.transactions; let i = index"
            [nzHeader]="getTransactionTotals(tran, book)"
            [nzDisabled]="book.transactions.length == 0"
            [nzShowArrow]="book.transactions.length > 0">
            <table class="history-table-transactions-table">
                <tr class="history-table-transactions-table-header">
                  <th>
                    {{ 'user_detail_history_check_wowid' | translation: 'Wow ID': []}}
                  </th>
                  <th>
                    {{ 'user_detail_history_check_items' | translation: 'Model desc.': []}}
                  </th>
                  <th>
                    {{ 'user_detail_history_check_category' | translation: 'Category': []}}
                  </th>
                  <th>
                    {{ 'user_detail_history_check_size' | translation: 'Size': []}}
                  </th>
                  <th>
                    {{ 'user_detail_history_check_price' | translation: 'Price': []}}
                  </th>
                </tr>
                <tr *ngFor="let item of tran.items" class="history-table-transactions-table-content">
                    <td>
                      #{{item.itemWowId}}
                    </td>
                    <td>
                      {{item.itemDescription | titlecase}}
                    </td>
                    <td>
                      {{item.itemCategory}}
                    </td>
                    <td>
                      {{item.itemSize}}
                    </td>
                    <td>
                      {{item.itemPrice | currency: 'EUR'}}
                    </td>                  
                </tr>
            </table>
            </nz-collapse-panel>
          </nz-collapse>          
        </td>
      </tr>
    }@else{
      <tr class="history-table-content">
        <td class="ps-text-center" colspan="4">
          {{ 'user_detail_history_no_sales' | translation: 'Nessuna partecipazione': []}}
        </td>
      </tr>
    }
  </table>
  <div *ngIf="userDetail.bookingHistory?.showMore??false" class="ps-w-100 ps-d-flex ps-justify-content-center">
    <p (click)="loadMoreBookings()" class="user-bookings-show-more-button">
      {{ 'user_profile_show_more_bookings_button' | translation: 'Carica altro': []}}
    </p>
  </div>
</nz-spin>
</div>