<div *ngIf="editDaySlot != undefined" class="ps-w-100 ps-d-flex ps-flex-wrap edit-day-slot-container">
  <div class="ps-d-flex ps-flex-wrap ps-w-100 ps-align-items-center edit-day-slot-title-container">
    <h3 class="edit-day-slot-day-title">
      {{editDaySlot.slotStartDate | date: "fullDate"}}
    </h3>
    <h1 class="edit-day-slot-hour-title">
      {{editDaySlot.slotStartDate | date: "shortTime"}} - {{editDaySlot.slotEndDate | date: "shortTime"}}
    </h1>
  </div>
  <!--   <button *ngIf="!isNewSlot" nz-button class="delete-button header-button" (click)="deleteDaySlot()">
    <span nz-icon nzType="delete"></span>
  </button>
  <button nz-button class="save-button header-button" (click)="saveChanges()">
    <span nz-icon nzType="save"></span>
  </button> -->
  <button nz-button class="close-button" (click)="closeModal()">
    <span nz-icon nzType="close"></span>
  </button>
  <div class="line"></div>
  <nz-spin class="ps-w-100 spinner" [nzSpinning]="isLoading" [nzIndicator]="purpleModalLoaderTemplate">

    <ng-container *ngFor="let formInfo of purpleFormFieldGroups; let i = index;">
      <div class="ps-d-flex ps-w-100 general-info-item {{i%2==0?'':'odd'}}">
        <div class="form-section-title-container">
          <p class="form-section-title">
            {{formInfo.formSectionName}}
          </p>
        </div>
        <div class="form-section-value-external-container">
          <div class="form-section-value-container">
            <app-purple-form [disbledForm]="disableForm" [(formGroup)]="validateForm"
              [purpleFormFieldGroups]="formInfo.purpleFormGroups">
            </app-purple-form>
            <ng-container *ngIf="formInfo.formSectionId == 'role_and_limit'">

              <nz-table
                *ngIf="editDaySlot.isMixedRoleEnabled || (!editDaySlot.isMixedRoleEnabled && editDaySlot.roles.length > 0)"
                #slotRoles [nzData]="editDaySlot.isMixedRoleEnabled?editDaySlot.mixedRoles:editDaySlot.roles"
                class="ps-w-100 role-table" [nzShowPagination]="false" [nzPageIndex]="1"
                [nzPageSize]="editDaySlot.isMixedRoleEnabled?editDaySlot.mixedRoles.length:editDaySlot.roles.length">
                <thead>
                  <tr>
                    <th>{{ 'edit_day_slot_modal_table_role_name' | translation: 'Nome': []}}</th>
                    <th>{{ 'edit_day_slot_modal_table_restrictions' | translation: 'Limitazioni': []}}</th>
                    <th>{{ 'edit_day_slot_modal_table_occupation_state' | translation: 'Posti Disponibili': []}}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let role of slotRoles.data; let i = index;"
                    class="{{i%2==0?'main-purple-table-tr-even': 'main-purple-table-tr-odd'}}">
                    <td>{{role.roleName}}</td>
                    <td class="ps-d-flex ps-flex-wrap ps-align-items-center">
                      <p class="ps-mb-0 ps-fsp-13-0 ps-pr-2">
                        {{ 'edit_day_slots_modal_table_max_role_book_number' | translation: 'Posti disponibili:':
                        []}}
                      </p>
                      <nz-input-number [ngModel]="role.slotTotalCapacity"
                        (ngModelChange)="changeRoleSlotCapacity($event, role)" [nzMin]="-1" [nzStep]="1"
                        [nzFormatter]="formatterUser">
                      </nz-input-number>
                    </td>
                    <td>
                      <p *ngIf="(role.slotTotalCapacity??-1) < 0">
                        {{ 'edit_day_slots_modal_illimited' | translation:'illimitato': []}}
                      </p>
                      <p *ngIf="(role.slotTotalCapacity??-1) > 0">
                        {{(role.slotAvailableCapacity??-1)==-1 ?
                        role.slotTotalCapacity:
                        role.slotAvailableCapacity
                        }}/{{role.slotTotalCapacity}}
                      </p>
                    </td>
                  </tr>
                </tbody>
              </nz-table>
            </ng-container>

            <nz-collapse *ngIf="formInfo.purpleFormCollapsedGroups.length>0" class="more-info-collapse"
              [nzBordered]="false" [nzExpandIconPosition]="'right'" nzGhost>
              <nz-collapse-panel
                [nzHeader]="'edit_day_slot_more_options_title' | translation: 'Informazioni aggiuntive': []">
                <app-purple-form [disbledForm]="disableForm" [(formGroup)]="validateForm"
                  [purpleFormFieldGroups]="formInfo.purpleFormCollapsedGroups">
                </app-purple-form>
              </nz-collapse-panel>
            </nz-collapse>
          </div>
        </div>
      </div>
      <div *ngIf="(formInfo.showLine??true)" class="line"></div>
    </ng-container>

    <div class="ps-w-100 ps-d-flex">
      <container-element [ngSwitch]="editDaySlot.isMixedRoleEnabled">
        <ng-container *ngSwitchCase="true">

        </ng-container>
        <ng-container *ngSwitchCase="false">

        </ng-container>
      </container-element>
    </div>
  </nz-spin>
  <div class="purple-button-container edit-day-slot-buttons-container ps-w-100 ps-d-flex ps-flex-wrap">
    <div class="ps-d-flex ps-flex-wrap ps-justify-content-center">
      <button *ngIf="!isNewSlot " (click)="deleteDaySlot()" nz-button class="purple-modal-button delete-button" [disabled]="isLoading"
      [nzLoading]="isLoading">
      <span class="button-icon" nz-icon nzType="delete"></span>
      {{ 'edit_day_slot_delete_button' | translation: 'Elimina Slot': []}}
    </button>
    <button (click)="saveChanges()" nz-button class="purple-modal-button" [disabled]="isLoading"
      [nzLoading]="isLoading">
      <span class="button-icon" nz-icon nzType="save"></span>
      {{ 'edit_day_slot_save_button' | translation: 'Salva Modifiche': []}}
    </button>
    </div>
    
  </div>
  <ng-template #purpleModalLoaderTemplate>
    <div class="purple-modal-custom-spinner">
      <span nz-icon nzType="loading"></span>
    </div>
  </ng-template>
</div>