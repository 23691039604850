<div class="ps-d-flex ps-flex-wrap ps-w-100 ps-justify-content-center">
  <nz-spin [nzSpinning]="isLoading" [nzIndicator]="purpleModalLoaderTemplate">
    <div class="ps-d-flex ps-flex-wrap ps-w-100 ps-algin-items-center ps-justify-content-center
  sale-event-status-buttons-container">

      <button (click)="setCurrentStatus(0)" nz-button
        class="sale-event-status-button {{selectedStatus == 0?'offline':''}}">
        <p class="ps-m-0">{{ 'tag_sale_not_online' | translation: 'offline': []}}</p>
        <span nz-icon nzType="eye-invisible" nzTheme="outline"></span>
      </button>
      <button (click)="setCurrentStatus(1)" nz-button
        class="sale-event-status-button {{selectedStatus == 1?'next':''}}">
        <p class="ps-m-0">{{ 'tag_sale_show_preview' | translation: 'preview': []}}</p>
        <span nz-icon nzType="calendar" nzTheme="outline"></span>
      </button>
      <button (click)="setCurrentStatus(2)" nz-button
        class="sale-event-status-button {{selectedStatus == 2?'online':''}}">
        <p class="ps-m-0">{{ 'tag_sale_is_online' | translation: 'online': []}}</p>
        <span nz-icon nzType="eye" nzTheme="outline"></span>
      </button>
    </div>
    <div *ngIf="selectedStatus == 1" class="ps-w-100 ps-d-flex ps-justify-content-center">
      <nz-date-picker class="cooming-soon-date-picker"
        [nzPlaceHolder]="'general_private_sale_coming_soon_start_dateplaceholder' | translation: 'Mostra a partire da': []"
        nzFormat="dd-MM-yyyy" [nzDisabledDate]="disabledPublicDates" [(ngModel)]="nextDate">
      </nz-date-picker>
    </div>
  </nz-spin>
  <ng-template #purpleModalLoaderTemplate>
    <div class="purple-modal-custom-spinner">
      <span nz-icon nzType="loading"></span>
    </div>
  </ng-template>
  <div class="purple-button-container ps-w-100 ps-d-flex">
    <button (click)="saveChangeStatus()" nz-button class="purple-modal-button" [disabled]="isLoading"
      [nzLoading]="isLoading">
      {{ 'change_privbate_sale_online_status_button' | translation: 'Modifica Stato'}}
    </button>
  </div>
</div>