import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { AuthenticationService } from './authentication.service';
import { AppTranslationService } from '../localization/localization.service';
import { JwtHelperService } from '@auth0/angular-jwt';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationGuard  {
  constructor(private router: Router, private authenticationService: AuthenticationService, private tsvc: AppTranslationService, private jwtSvc: JwtHelperService) { }

  canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const token = this.authenticationService.currentUser?.token ?? "";
    try {
      if (!this.jwtSvc.isTokenExpired(token)) {
        this.authenticationService.checkUserRoles(token);
        return true;
      }

    } catch (error) {
      //
    }

    return this.router.navigate([this.tsvc.currentLanguage.value, 'login']);
  }
}
