import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { purpleFormFieldsGroup, PurpleTranslationPipe, PurpleApiProxyService, Helper, PurpleApiMakeRequestResponse } from 'purple-lib';
import { AppTranslationService } from 'src/core-modules/localization/localization.service';
import { BackOfficeService, PRPVPolicyFull } from 'src/core-modules/sdk/api';
import { environment } from 'src/environments/default/environment';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { NzMessageService } from 'ng-zorro-antd/message';

@Component({
  selector: 'app-edit-base-policy-modal',
  templateUrl: './edit-base-policy-modal.component.html',
  styleUrls: ['./edit-base-policy-modal.component.scss']
})
export class EditBasePolicyModalComponent implements OnInit {
  @Input() policyFull: PRPVPolicyFull | undefined = undefined;

  originalPolicyItem!: EditPolicyItem;
  newObj!: EditPolicyItem;
  isNew: boolean = false;

  validateForm: FormGroup = this.fb.group({});
  purpleFormFieldGroups: purpleFormFieldsGroup[] | undefined;
  disableForm: boolean = false;
  isLoading: boolean = false;

  constructor(private modalRef: NzModalRef, private fb: FormBuilder, private tranPipe: PurpleTranslationPipe, private apiProxySvc: PurpleApiProxyService,
    private mesSvc: NzMessageService, private backUserSvc: BackOfficeService, private tsvc: AppTranslationService) { }


  async ngOnInit(): Promise<void> {
    this.originalPolicyItem = {
      policyId: this.policyFull?.policyId,
      policyName: this.policyFull?.policyName,
      policyDescription: this.policyFull?.policyDescription ?? undefined
    }

    if (this.policyFull == undefined) {
      this.isNew = true;
    }

    this.newObj = Helper.storeNewObj(this.originalPolicyItem)

    this.purpleFormFieldGroups = [
      {
        fieldGroupNumber: 1,
        fielGroupBootstrapColumn: 12,
        fieldGroupPaddingLeft: false,
        fieldGroupPaddingRight: false,
        formFieldFormGroup: this.validateForm,
        formFields: [
          {
            fieldType: 'input-text',
            fieldControlName: 'name',
            fieldBootstrapColumn: 12,
            fieldName: this.tranPipe.transform('edit_base_policy_name', 'Nome', []),
            fieldPlaceholder: this.tranPipe.transform('edit_base_policy_name_placeholder', 'Nome policy', []),
            fieldValue: this.newObj.policyName,
            fieldIsRequired: true,
            fieldPaddingLeft: false,
            fieldPaddingRight: false
          },
          {
            fieldType: 'textarea',
            fieldControlName: 'description',
            fieldBootstrapColumn: 12,
            fieldName: this.tranPipe.transform('edit_base_policy_description', 'Descrizione', []),
            fieldPlaceholder: this.tranPipe.transform('edit_base_policy_description_placeholder', 'Descrizione policy', []),
            fieldValue: this.newObj.policyDescription,
            fieldIsRequired: false,
            fieldOptions: [{ id: 'rowNumber', value: 5 }],
            fieldPaddingLeft: false,
            fieldPaddingRight: false
          }
        ]
      }
    ]
  }


  async editPolicy() {
    this.isLoading = true;
    for (const i in this.validateForm.controls) {
      this.validateForm.controls[i].markAsDirty();
      this.validateForm.controls[i].updateValueAndValidity();
    }

    if (this.validateForm.valid) {
      this.newObj.policyId = (this.validateForm.controls["name"].value as string).sanitizer("_");
      this.newObj.policyName = this.validateForm.controls["name"].value;
      this.newObj.policyDescription = this.validateForm.controls["description"].value;

      if (Helper.checkIfDoChanges(this.newObj, this.originalPolicyItem)) {
        await this.apiProxySvc.makeRequestErrorMessage<boolean>(() => this.backUserSvc.addOrUpdatePolicy(this.tsvc.translationLanguage.value, {
          policyDescription: this.newObj.policyDescription,
          policyName: this.newObj.policyName!,
          policyId: this.newObj.policyId!,
          isNew: this.isNew
        }), false, "internal-loader", 500, undefined, undefined, (res: PurpleApiMakeRequestResponse<boolean>) => {
          let text: string;
          if (this.isNew) {
            text = this.tranPipe.transform('edit_base_policy_add', 'Policy aggiunta con successo')
          } else {
            text = this.tranPipe.transform('edit_base_policy_update', 'Policy aggiunta con successo')
          }
          this.mesSvc.success(text, {
            nzDuration: environment.MESSAGE_DURATION
          });
          this.modalRef.destroy(res.data?this.newObj.policyId : undefined);
        })
      } else {
        this.mesSvc.warning(this.tranPipe.transform('edit_no_changes', 'Nessuna modifica effettuata', []), {
          nzDuration: environment.MESSAGE_DURATION
        });
      }
    }
    this.isLoading = false
  }
}

interface EditPolicyItem {
  policyId?: string | undefined;
  policyName?: string | undefined;
  policyDescription?: string | undefined;
}