<nz-spin [nzSpinning]="isLoading || isLoadingNormal || isLoadingForce" [nzIndicator]="purpleModalLoaderTemplate">
    <app-purple-form *ngIf="purpleFormFieldGroups!= undefined" 
    [disbledForm]="disableForm" [(formGroup)]="validateForm"
      [purpleFormFieldGroups]="purpleFormFieldGroups">
    </app-purple-form>
  </nz-spin>
  <ng-template #purpleModalLoaderTemplate>
    <div class="purple-modal-custom-spinner">
      <span nz-icon nzType="loading"></span>
    </div>
  </ng-template>
  <div class="purple-button-container ps-w-100 ps-d-flex">
    <button (click)="bookSlot(true)" nz-button class="purple-modal-button ps-mr-3" [disabled]="isLoadingForce"
      [nzLoading]="isLoadingForce">
      {{ 'force_book_slot_modal_button' | translation: 'Forza Prenota': []}}
    </button>
    <button (click)="bookSlot(false)" nz-button class="purple-modal-button ps-ml-3" [disabled]="isLoadingNormal"
      [nzLoading]="isLoadingNormal">
      {{ 'book_slot_modal_button' | translation: 'Prenota': []}}
    </button>
  </div>