import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { purpleFormFieldsGroup, PurpleTranslationPipe, PurpleApiProxyService, PurpleSelectFilter, PurpleApiMakeRequestResponse } from 'purple-lib';
import { AppTranslationService } from 'src/core-modules/localization/localization.service';
import { BackOfficeService, UserTableItem } from 'src/core-modules/sdk/api';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalRef, NZ_MODAL_DATA } from 'ng-zorro-antd/modal';
import { environment } from 'src/environments/default/environment';

@Component({
  selector: 'app-anonymize-user-modal',
  templateUrl: './anonymize-user-modal.component.html',
  styleUrls: ['./anonymize-user-modal.component.scss']
})
export class AnonymizeUserModalComponent implements OnInit {

  originalEditItem!: anonymizeUser;
  validateForm: FormGroup = this.fb.group({});
  purpleFormFieldGroups: purpleFormFieldsGroup[] | undefined;
  disableForm: boolean = false;
  isLoading: boolean = false;

  constructor(@Inject(NZ_MODAL_DATA) private user: UserTableItem, private modalRef: NzModalRef, private fb: FormBuilder, private tranPipe: PurpleTranslationPipe, private apiProxySvc: PurpleApiProxyService,
    private mesSvc: NzMessageService, private admSvc: BackOfficeService, private tsvc: AppTranslationService) { }

  async ngOnInit(): Promise<void> {
    this.originalEditItem = {
      user: this.user.userId,
      customers: undefined,
      owner: undefined
    }

    this.purpleFormFieldGroups = [
      {
        fieldGroupNumber: 1,
        fielGroupBootstrapColumn: 12,
        fieldGroupPaddingLeft: false,
        fieldGroupPaddingRight: false,
        formFieldFormGroup: this.validateForm,
        formFields: [
          {
            fieldType: 'select',
            fieldControlName: 'owner',
            fieldBootstrapColumn: 12,
            fieldName: this.tranPipe.transform('anonymize_user_owner', 'Referente Anonimizzazione', []),
            fieldPlaceholder: this.tranPipe.transform('anonymize_user_owner_placeholder', 'Seleziona il referente della anonimizzazione', []),
            fieldOptions: [
              { id: 'scrollFunction', value: this.ownerScrollFunction },
              { id: 'searchFunction', value: this.ownerSearchFunction },
              { id: 'searchByIdFunction', value: this.ownerSearchByIdFunction },
              { id: 'refreshFieldNumber', value: 0 }
            ],
            fieldValue: this.originalEditItem.owner,
            fieldIsRequired: true,
            fieldPaddingLeft: false,
            fieldPaddingRight: false,
            fieldIsDisabled: false
          },
          {
            fieldType: 'select-multiple',
            fieldControlName: 'customers',
            fieldBootstrapColumn: 12,
            fieldName: this.tranPipe.transform('anonymize_user_customers', 'Clienti Associati', []),
            fieldPlaceholder: this.tranPipe.transform('anonymize_user_customers_placeholder', 'Seleziona i clienti associati al referente', []),
            fieldOptions: [
              { id: 'scrollFunction', value: this.customersScrollFunction },
              { id: 'searchFunction', value: this.customersSearchFunction },
              { id: 'searchByIdFunction', value: this.customersSearchByIdFunction },
              { id: 'refreshFieldNumber', value: 0 }
            ],
            fieldValue: this.originalEditItem.customers,
            fieldIsRequired: false,
            fieldPaddingLeft: false,
            fieldPaddingRight: false,
            fieldIsDisabled: false
          }
        ]
      }
    ]
  }

  async anonymizeUser() {
    this.isLoading = true;

    for (const i in this.validateForm.controls) {
      this.validateForm.controls[i].markAsDirty();
      this.validateForm.controls[i].updateValueAndValidity();
    }

    if (this.validateForm.valid) {
      await this.apiProxySvc.makeRequestErrorMessage<Boolean>(() => this.admSvc.anonymizeUser(this.tsvc.translationLanguage.value, {
        ownerId: this.validateForm.controls["owner"].value,
        userId: this.originalEditItem.user,
        customerIds: this.validateForm.controls["customers"].value??[]
      }), false, "internal-loader", 500, undefined, undefined, (res: PurpleApiMakeRequestResponse<Boolean>) => {
        this.mesSvc.success(this.tranPipe.transform('anonymize_user_success', 'Utente anonimizzato con successo', []), {
          nzDuration: environment.MESSAGE_DURATION
        });
        this.modalRef.destroy(res.data);
      })
    }
    this.isLoading = false
  }


  //#region Customers
  customersSearchFunction = (args: { pageNumber: number, pageSize: number, culture: string, searchName: string, searchFilters: PurpleSelectFilter[] }) => {
    return this.admSvc.getCustomersByNameSelect(args.culture, args)
  }

  customersScrollFunction = (args: { pageNumber: number, pageSize: number, culture: string, searchName: string, searchFilters: PurpleSelectFilter[] }) => {
    return this.admSvc.getCustomersSelect(args.culture, args)
  }

  customersSearchByIdFunction = (args: { pageNumber: number, pageSize: number, culture: string, searchName: string[], searchFilters: PurpleSelectFilter[] }) => {
    return this.admSvc.getCustomersByIdsSelect(args.culture, args)
  }
  //#endregion

  //#region Owners
  ownerSearchFunction = (args: { pageNumber: number, pageSize: number, culture: string, searchName: string, searchFilters: PurpleSelectFilter[] }) => {
    return this.admSvc.getOwnersByNameSelect(args.culture, args)
  }

  ownerScrollFunction = (args: { pageNumber: number, pageSize: number, culture: string, searchName: string, searchFilters: PurpleSelectFilter[] }) => {
    return this.admSvc.getOwnersSelect(args.culture, args)
  }

  ownerSearchByIdFunction = (args: { pageNumber: number, pageSize: number, culture: string, searchName: string, searchFilters: PurpleSelectFilter[] }) => {
    return this.admSvc.getOwnersByIdSelect(args.culture, args)
  }
  //#endregion
}


interface anonymizeUser {
  user: string;
  owner: string | undefined;
  customers: string[] | undefined;
}