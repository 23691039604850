import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { PRPVSaleEventFullExtended } from 'src/core-modules/sdk/api';

@Injectable({
  providedIn: 'root'
})
export class PrivateSaleService {

  constructor() { }
  currentSaleEvent$: BehaviorSubject<PRPVSaleEventFullExtended | undefined> = new BehaviorSubject<PRPVSaleEventFullExtended | undefined>(undefined);

  setCurrentSaleEvent(se: PRPVSaleEventFullExtended | undefined): void{
    this.currentSaleEvent$.next(se);
  }

  
}
