import { Component, OnInit } from '@angular/core';
import { PrivateSaleService } from '../../../private-sale-service.service';

@Component({
  selector: 'app-private-sale-users',
  templateUrl: './private-sale-users.component.html',
  styleUrls: ['./private-sale-users.component.scss']
})
export class PrivateSaleUsersComponent implements OnInit {

  constructor(public seSvc: PrivateSaleService) { }

  ngOnInit() {
  }

}
