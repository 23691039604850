import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthenticationGuard } from 'src/core-modules/authentication/authentication.guard';
import { LocalizationGuard } from 'src/core-modules/localization/localization.guard';
import { LoginComponent } from './authentication/login/login.component';
import { Error404Component } from './error/404/404.component';
import { CountryGuard } from 'src/core-modules/country/country.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'none-none/login',
    pathMatch: 'full'
  },
  {
    path: '404',
    component: Error404Component, 
    canActivate: [CountryGuard]
  },
  {
    path: ':cultureId',
    canActivate: [LocalizationGuard, CountryGuard],
    children: [
      {
        path: '',
        redirectTo: 'login',
        pathMatch: 'full'
      },
      {
        path: 'login',
        component: LoginComponent
      },
      {
        path: 'dashboard',
        canActivate: [AuthenticationGuard],
        loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule)
      }
    ]
  },

];


@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
