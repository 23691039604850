import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { purpleFormFieldsGroup, PurpleApiProxyService, PurpleTranslationPipe, Helper, PurpleApiMakeRequestResponse, PurpleSelectFilter } from 'purple-lib';
import { Subject } from 'rxjs';
import { AppTranslationService } from 'src/core-modules/localization/localization.service';
import { BackOfficeService, LocalizationService, ReferralTableItem } from 'src/core-modules/sdk/api';
import { environment } from 'src/environments/default/environment';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalRef, NZ_MODAL_DATA } from 'ng-zorro-antd/modal';

@Component({
  selector: 'app-edit-user-referral-modal',
  templateUrl: './edit-user-referral-modal.component.html',
  styleUrls: ['./edit-user-referral-modal.component.scss']
})
export class EditUserReferralModalComponent implements OnInit {

  sub: Subject<void> = new Subject();
  originalUserReferral!: EditBrandLimit;
  newObj!: EditBrandLimit;
  isNew: boolean = false;
  validateForm: FormGroup = this.fb.group({});
  purpleFormFieldGroups: purpleFormFieldsGroup[] | undefined;
  disableForm: boolean = false;
  isLoading: boolean = false;

  constructor(@Inject(NZ_MODAL_DATA) private data: { userReferrralFull: ReferralTableItem | undefined, saleEventId: string, usedRoles: string[], usedUsers: string[] }, private modalRef: NzModalRef, private mesSvc: NzMessageService, 
  private apiProxySvc: PurpleApiProxyService, private admSvc: BackOfficeService, private locSvc: LocalizationService, private tsvc: AppTranslationService, private fb: FormBuilder, 
  private tranPipe: PurpleTranslationPipe) { }

  async ngOnInit(): Promise<void> {
    this.originalUserReferral = {
      referralId: this.data.userReferrralFull?.referralId??undefined,
      maxInvitationNr: this.data.userReferrralFull?.maxInvitationsNr??undefined,
      referralReferralRole: this.data.userReferrralFull?.referralReferralRoleId??undefined,
      referralRole: this.data.userReferrralFull?.roleId??undefined,
      referralUser: this.data.userReferrralFull?.userId??undefined
    }

    if(this.data.userReferrralFull == undefined){
      this.isNew = true;
    }

    this.newObj = Helper.storeNewObj(this.originalUserReferral);

    this.purpleFormFieldGroups = [
      {
        fieldGroupNumber: 1,
        fielGroupBootstrapColumn: 12,
        fieldGroupPaddingLeft: false,
        fieldGroupPaddingRight: false,
        formFieldFormGroup: this.validateForm,
        formFields: [
          {
            fieldType: 'select',
            fieldControlName: 'referralUser',
            fieldBootstrapColumn: 12,
            fieldName: this.tranPipe.transform('edit_user_referral_user', 'Utente', []),
            fieldPlaceholder: this.tranPipe.transform('edit_user_referral_user_placeholder', 'Seleziona l\'utente', []),
            fieldValue: this.newObj.referralUser,
            fieldIsRequired: true,
            fieldIsDisabled: !this.isNew,
            fieldPaddingLeft: false,
            fieldPaddingRight: false,
            fieldOptions: [
              { id: 'scrollFunction', value: this.usersScrollFunction },
              { id: 'searchFunction', value: this.usersSearchFunction },
              { id: 'searchByIdFunction', value: this.usersSearchByIdFunction },
              { id: 'refreshFieldNumber', value: 0 },
            ]
          },
          {
            fieldType: 'select',
            fieldControlName: 'referralRole',
            fieldBootstrapColumn: 12,
            fieldName: this.tranPipe.transform('edit_user_referral_role', 'Ruolo dell\'invitato', []),
            fieldPlaceholder: this.tranPipe.transform('edit_user_referral_role_placeholder', 'Seleziona il ruolo dell\'invitato', []),
            fieldValue: this.newObj.referralRole,
            fieldIsRequired: true,
            fieldIsDisabled: !this.isNew,
            fieldPaddingLeft: false,
            fieldPaddingRight: false,
            fieldOptions: [
              { id: 'scrollFunction', value: this.rolesScrollFunction },
              { id: 'searchFunction', value: this.rolesSearchFunction },
              { id: 'searchByIdFunction', value: this.rolesSearchByIdFunction },
              { id: 'refreshFieldNumber', value: 0 },
            ]
          },
          {
            fieldType: 'input-number',
            fieldControlName: 'maxInvitationNr',
            fieldBootstrapColumn: 6,
            fieldName: this.tranPipe.transform('edit_user_referral_max_invite', 'Numero massimo inviti', []),
            fieldPlaceholder: this.tranPipe.transform('eedit_user_referral_max_inviteplaceholder', 'Seleziona il numero massimo di inviti', []),
            fieldValue: this.newObj.maxInvitationNr,
            fieldIsRequired: true,
            fieldIsDisabled: false,
            fieldPaddingLeft: false,
            fieldPaddingRight: true,
            fieldOptions: [{ id: 'minNumber', value: -1 }]
          },
          {
            fieldType: 'select',
            fieldControlName: 'referralReferralRole',
            fieldBootstrapColumn: 6,
            fieldName: this.tranPipe.transform('edit_user_referral_referral_role', 'Ruolo dell\'invitato del invitato', []),
            fieldPlaceholder: this.tranPipe.transform('edit_user_referral_referral_role_placeholder', 'Seleziona il ruolo dell\'invitato del invitato', []),
            fieldValue: this.newObj.referralReferralRole,
            fieldIsRequired: true,
            fieldIsDisabled: false,
            fieldPaddingLeft: false,
            fieldPaddingRight: true,
            fieldOptions: [
              { id: 'scrollFunction', value: this.rolesScrollFunction },
              { id: 'searchFunction', value: this.rolesSearchFunction },
              { id: 'searchByIdFunction', value: this.rolesSearchByIdFunction },
              { id: 'refreshFieldNumber', value: 0 },
            ]
          }
        ]
      }
    ]
  }


  async editItem() {
    this.isLoading = true;

    this.newObj

    for (const i in this.validateForm.controls) {
      this.validateForm.controls[i].markAsDirty();
      this.validateForm.controls[i].updateValueAndValidity();
    }

    if (this.validateForm.valid) {

      this.newObj.referralUser = this.validateForm.controls["referralUser"].value;
      this.newObj.maxInvitationNr = this.validateForm.controls["maxInvitationNr"].value;
      this.newObj.referralReferralRole = this.validateForm.controls["referralReferralRole"].value;
      this.newObj.referralRole = this.validateForm.controls["referralRole"].value;

      if (Helper.objIsEqual(this.newObj, this.originalUserReferral)) {
        this.mesSvc.warning(this.tranPipe.transform('edit_no_changes', 'Nessuna modifica effettuata', []), {
          nzDuration: environment.MESSAGE_DURATION,
        });
        this.isLoading = false;
        return;
      }

      await this.apiProxySvc.makeRequestErrorMessage<boolean>(() => {
        if(this.isNew){
          return this.admSvc.addUserRoleReferral(this.tsvc.currentLanguage.value, {
            maxInvitationsNr: this.newObj.maxInvitationNr!,
            roleId: this.newObj.referralRole!,
            roleWhiteListDomain: [],
            saleEventId: this.data.saleEventId,
            referralReferralRoleId: this.newObj.referralReferralRole,
            userId: this.newObj.referralUser!
          })
        }else{
          return this.admSvc.updateReferral(this.tsvc.currentLanguage.value, {
            maxInvitationsNr: this.newObj.maxInvitationNr!,
            roleWhiteListDomain: [],
            referralReferralRoleId: this.newObj.referralReferralRole,
            referralId: this.newObj.referralId!,
            maxLinkRedeemNr: undefined
          })
        }
      }, false, "internal-loader", 500, undefined, undefined, (res: PurpleApiMakeRequestResponse<boolean>) => {
        let text: string;
        if (this.isNew) {
          text = this.tranPipe.transform('edit_user_referral_add_success', 'Codice invito aggiunto con successo', [])
        } else {
          text = this.tranPipe.transform('edit_user_referral_update_success', 'Codice invito aggiornato con successo', [])
        }
        this.mesSvc.success(text, {
          nzDuration: environment.MESSAGE_DURATION
        });
        this.modalRef.destroy(res.data);
      })
      
    }
    this.isLoading = false
  }

  //ScrollFunction
  usersScrollFunction = (args: { pageNumber: number, pageSize: number, culture: string, searchName: string, searchFilters: PurpleSelectFilter[] }) => {
    if(this.data.usedUsers.length > 0){
      args.searchFilters = [
        { propertyName: "usersToExclude", filterValues: this.data.usedUsers, filterOperator: "not in" }
      ]
    }
    
    return this.admSvc.getUsersSelect(args.culture, args)
  }
  //SearchFunction
  usersSearchFunction = (args: { pageNumber: number, pageSize: number, culture: string, searchName: string, searchFilters: PurpleSelectFilter[] }) => {
    if(this.data.usedUsers.length > 0){
      args.searchFilters = [
        { propertyName: "usersToExclude", filterValues: this.data.usedUsers, filterOperator: "not in" }
      ]
    }
    return this.admSvc.getUsersByNameSelect(args.culture, args)
  }
  //InitFunction (SearchByIdFunction)
  usersSearchByIdFunction = (args: { pageNumber: number, pageSize: number, culture: string, searchName: string, searchFilters: PurpleSelectFilter[] }) => {
    if(this.data.usedUsers.length > 0){
      args.searchFilters = [
        { propertyName: "usersToExclude", filterValues: this.data.usedUsers, filterOperator: "not in" }
      ]
    }
    return this.admSvc.getUsersByIdSelect(args.culture, args)
  }

  //ScrollFunction
  rolesScrollFunction = (args: { pageNumber: number, pageSize: number, culture: string, searchName: string, searchFilters: PurpleSelectFilter[] }) => {
    args.searchFilters = [
      { propertyName: "roleType", filterValues: ["notStaff"] }
    ]
    return this.admSvc.getRolesSelect(args.culture, args)
  }
  //SearchFunction
  rolesSearchFunction = (args: { pageNumber: number, pageSize: number, culture: string, searchName: string, searchFilters: PurpleSelectFilter[] }) => {
    args.searchFilters = [
      { propertyName: "roleType", filterValues: ["notStaff"] }
    ]
    return this.admSvc.getRolesByNameSelect(args.culture, args)
  }
  //InitFunction (SearchByIdFunction)
  rolesSearchByIdFunction = (args: { pageNumber: number, pageSize: number, culture: string, searchName: string, searchFilters: PurpleSelectFilter[] }) => {
    return this.admSvc.getRolesByIdSelect(args.culture, args)
  }

}


interface EditBrandLimit {
  referralId: string | undefined;
  referralUser: string | undefined;
  referralRole: string | undefined;
  referralReferralRole: string | undefined;
  maxInvitationNr: number | undefined;
}
