import { Component, OnDestroy, OnInit } from '@angular/core';
import { PurpleTranslationPipe, PurpleApiProxyService, PurpleTableColumn, PurpleTableSearchValue, PurpleTableSelectFilter, Guid, PurpleApiMakeRequestResponse, dashboardButton, PurpleTableBoxColumn } from 'purple-lib';
import { Subject, takeUntil } from 'rxjs';
import { DashboardLayout2Service } from 'src/app/dashboard/dashboard-layout-2/dashboard-layout-2.service';
import { AppTranslationService } from 'src/core-modules/localization/localization.service';

import { BackOfficeService, GetSaleEventSystemEventExceptionsResponse, GetSaleEventSystemEventExceptionsResponsePurpleTableResponse, PRPVSaleEventFullExtended, PurpleTableSearchGroupGroup, SaleEventMailTemplateExceptionItem } from 'src/core-modules/sdk/api';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NzMessageService } from 'ng-zorro-antd/message';
import { environment } from 'src/environments/default/environment';
import { AddMailTemplateExceptionFastComponent } from '../../modals/add-mail-template-exception-fast/add-mail-template-exception-fast.component';
import { EditMailTemplateExceptionComponent } from '../../modals/edit-mail-template-exception/edit-mail-template-exception.component';
import { PrivateSaleService } from 'src/app/dashboard/private-sales/private-sale-service.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-base-system-event-mail-template-exceptions',
  templateUrl: './base-system-event-mail-template-exceptions.component.html',
  styleUrls: ['./base-system-event-mail-template-exceptions.component.scss']
})
export class BaseSystemEventMailTemplateExceptionsComponent implements OnInit, OnDestroy {

  constructor(public admSvc: BackOfficeService, public dashSvc: DashboardLayout2Service, public tsvc: AppTranslationService,
    private modal: NzModalService, private tranPipe: PurpleTranslationPipe, private apiProxySvc: PurpleApiProxyService,
    private mesSvc: NzMessageService, private seSvc: PrivateSaleService, private route: ActivatedRoute, private router: Router) {}

  
  sub: Subject<void> = new Subject();
  showTable: boolean = false;

  listOfData: Array<GetSaleEventSystemEventExceptionsResponsePurpleTableResponse> | undefined | null;
  refreshTableRowsNumber: number = 0;
  saleEvent: PRPVSaleEventFullExtended | undefined;
  /* tabColumns: PurpleTableColumn[] = [
    {
      name: this.tranPipe.transform("translation_table_header_translation_language", "Lingua"),
      dbKey: "",
      hasReorder: false,
      hasFilter: false,
      fixedWidth: "80px"
    },
    {
      name: this.tranPipe.transform("table_header_is_translated", "Tradotto"),
      dbKey: "ToTranslate",
      hasReorder: true,
      hasFilter: true,
      filterType: 'select',
      fixedWidth: "130px",
      filters: [
        {
          text: "Si",
          value: false
        },
        {
          text: "No",
          value: true
        }
      ]
    },
    {
      name: this.tranPipe.transform("table_header_name", "Nome"),
      dbKey: "SystemEventName",
      hasReorder: true,
      hasFilter: true,
      filterType: 'search'
    },
    {
      name: this.tranPipe.transform("table_header_description", "Descrizione"),
      dbKey: "SystemEventDescription",
      hasReorder: true,
      hasFilter: true,
      filterType: 'search'
    },
    {
      name: this.tranPipe.transform("table_header_mail_template_exception_filter_1", "Filtro 1"),
      dbKey: "",
      hasReorder: false,
      hasFilter: false
    },
    {
      name: this.tranPipe.transform("table_header_mail_template_exception_filter_2", "Filtro 2"),
      dbKey: "",
      hasReorder: false,
      hasFilter: false
    },
    {
      name: this.tranPipe.transform("table_header_actions", "Azioni"),
      hasReorder: false,
      hasFilter: false,
      fixedWidth: "150px",
    }
  ]
 */

  tabColumns: PurpleTableBoxColumn[] = [
    {
      name: this.tranPipe.transform("table_header_translation_language", "Lingua"),
      hasReorder: false,
      hasFilter: false,
      fixedWidth: "80px"
    },
    {
      name: this.tranPipe.transform("table_header_is_translated", "Tradotto"),
      dbKey: "ToTranslate",
      hasReorder: true,
      hasFilter: true,
      fixedWidth: "110px",
      filterField: {
        fieldType: 'select-local',
        fieldControlName: 'ToTranslate',
        fieldBootstrapColumn: 12,
        fieldName: this.tranPipe.transform('table_box_filter_is_translated', 'Tradotto', []),
        fieldPlaceholder: this.tranPipe.transform('table_box_filter_is_translated_placeholder', 'Tradotto', []),
        fieldValue: undefined,
        fieldIsRequired: false,
        fieldIsDisabled: false,
        fieldPaddingLeft: false,
        fieldPaddingRight: false,
        fieldOptions: [
          { id: 'isMultiple', value: false },
          { id: 'showArrow', value: true },
          { id: 'enableSearch', value: false },
          {
            id: 'options', value: [
              {
                label: 'Si',
                value: 0
              },
              {
                label: 'No',
                value: 1
              }
            ]
          }
        ]
      },
      operatorType: "="
    },
    {
      name: this.tranPipe.transform("table_header_name", "Nome"),
      dbKey: "SystemEventName",
      hasReorder: true,
      hasFilter: true,
      filterField: {
        fieldType: 'input-text',
        fieldControlName: 'SystemEventName',
        fieldBootstrapColumn: 12,
        fieldName: this.tranPipe.transform('table_filter_system_event_name', 'Nome', []),
        fieldPlaceholder: this.tranPipe.transform('table_filter_system_event_name_placeholder', 'Nome ruolo', []),
        fieldValue: undefined,
        fieldIsRequired: false,
        fieldIsDisabled: false,
        fieldPaddingLeft: false,
        fieldPaddingRight: false
      },
      operatorType: "like"
    },
    {
      name: this.tranPipe.transform("system_event_table_header_description", "Descrizione"),
      dbKey: "SystemEventDescription",
      hasReorder: true,
      hasFilter: true,
      filterField: {
        fieldType: 'input-text',
        fieldControlName: 'SystemEventDescription',
        fieldBootstrapColumn: 12,
        fieldName: this.tranPipe.transform('table_filter_system_event_description', 'Descrizione', []),
        fieldPlaceholder: this.tranPipe.transform('table_filter_description_system_event_placeholder', 'Descrizione ruolo', []),
        fieldValue: undefined,
        fieldIsRequired: false,
        fieldIsDisabled: false,
        fieldPaddingLeft: false,
        fieldPaddingRight: false
      },
      operatorType: "like"
    },
    {
      name: this.tranPipe.transform("system_event_exception_table_header_extra_pk1", "Filtro 1"),
      dbKey: "ExtraPk1",
      hasReorder: true,
      hasFilter: false,
      filterField: {
        fieldType: 'input-text',
        fieldControlName: 'ExtraPk1',
        fieldBootstrapColumn: 6,
        fieldName: this.tranPipe.transform('table_filter_system_event_exception_extra_pk1', 'Filtro 1', []),
        fieldPlaceholder: this.tranPipe.transform('table_filter_system_event_exception_extra_pk1_placeholder', 'Filtro 1', []),
        fieldValue: undefined,
        fieldIsRequired: false,
        fieldIsDisabled: false,
        fieldPaddingLeft: false,
        fieldPaddingRight: true
      },
      operatorType: "like"
    },
    {
      name: this.tranPipe.transform("system_event_exception_table_header_extra_pk2", "Filtro 2"),
      dbKey: "ExtraPk2",
      hasReorder: true,
      hasFilter: false,
      filterField: {
        fieldType: 'input-text',
        fieldControlName: 'ExtraPk2',
        fieldBootstrapColumn: 6,
        fieldName: this.tranPipe.transform('table_filter_system_event_exception_extra_pk2', 'Filtro 2', []),
        fieldPlaceholder: this.tranPipe.transform('table_filter_system_event_exception_extra_pk2_placeholder', 'Filtro 2', []),
        fieldValue: undefined,
        fieldIsRequired: false,
        fieldIsDisabled: false,
        fieldPaddingLeft: true,
        fieldPaddingRight: false
      },
      operatorType: "like"
    },
    {
      name: this.tranPipe.transform("table_header_actions", "Azioni"),
      hasReorder: false,
      hasFilter: false,
      fixedWidth: "190px"
    },
    
  ]

  refreshFunction = (args: {
    pageNumber: number, pageSize: number, orderColumnName?: string | null, orderType?: string | null,
    tableSearchValues?: Array<PurpleTableSearchGroupGroup> | null, tableFilters?: Array<PurpleTableSelectFilter> | null, switchOn?: boolean,
    culture: string
  }) => {
    args.tableFilters = [{
      property: "saleEventId",
      filterValue: this.saleEvent?.saleEventId??Guid.empty()
    }];
    args.culture = this.tsvc.translationLanguage.value;
    return this.admSvc.getSaleEventSystemEventExceptionsTable(args.culture, args)
  }

  async ngOnInit() {
    this.dashSvc.addTranslationSelect();

    this.tsvc.translationLanguage.pipe(takeUntil(this.sub)).subscribe(async () => {
      await this.refreshTableRowsNumber++;
    });

    this.seSvc.currentSaleEvent$.pipe(takeUntil(this.sub)).subscribe((se: PRPVSaleEventFullExtended | undefined) => {
      this.saleEvent = se;
      this.showTable = true;
    });

    this.route.queryParams.subscribe(async params => {
      await this.refreshSale(params['saleEvent']);
    })

    
    
  }

  private async refreshSale(seId: string | undefined) {
    console.log("FACCIO REFRESH DELLA SALE")
    const cSeid = seId??Guid.empty();

    this.dashSvc.dashMainItems = [];


    if(!cSeid.strEq(Guid.empty())){
      this.dashSvc.dashMainItems?.push(
        new dashboardButton(
          "back-button",
          this.tranPipe.transform("dashboard_private_general_iofo_back_button", "Torna alla lista", []),
          () => { this.router.navigate([this.tsvc.currentLanguage.value, 'dashboard', 'private-sales', 'private-sale-list']) },
          undefined,
          "arrow-left",
          undefined,
          "left"
        )
      );
    }

    this.dashSvc.dashMainItems.push(
      new dashboardButton(
        "add-new-exception",
        this.tranPipe.transform("base_system_event_mail_template_exceptions_add_new_button", "Nuova Eccezione", []),
        async () => { await this.addNewSystemEventException() }
      )
    )

    await this.dashSvc.refreshSaleEvent(cSeid, "base-system-event-mail-template-exceptions", 'Lista delle eccezioni relative ai template configurabili per le notifiche email',
      seId==undefined?this.tranPipe.transform('base-system-event-mail-template-exceptions_general_page_title', 'Eccezioni Template Email', []):undefined, true);
  }

  ngOnDestroy(): void {
    this.sub.next();
    this.sub.complete();
    this.dashSvc.clearPageHeader();
  }

  async editSystemEventMailTemplateException(seMtExc: GetSaleEventSystemEventExceptionsResponse) {
    await this.apiProxySvc.makeRequestErrorMessage<SaleEventMailTemplateExceptionItem>(() => this.admSvc.getSystemEventSaleEventMailTemplateExceptionDetail(this.tsvc.currentLanguage.value, {
      exceptionId: seMtExc.exceptionId
    }), false, "internal-loader", 500, undefined, undefined, (res: PurpleApiMakeRequestResponse<SaleEventMailTemplateExceptionItem>) => {
      this.modal.create<EditMailTemplateExceptionComponent, {saleEventMailTemplateException: SaleEventMailTemplateExceptionItem, systemEventId: string, systemEventName: string, saleEventId: string, isPreview: boolean}>({
        nzContent: EditMailTemplateExceptionComponent,
        nzData: {
          saleEventMailTemplateException: res.data!,
          systemEventId: seMtExc.systemEvent.systemEventId,
          systemEventName: seMtExc.systemEvent.systemEventName,
          saleEventId: seMtExc.saleEventId,
          isPreview: false
        },
        nzTitle: "Modifica template",
        nzWidth: '85%',
        nzOkText: "Conferma",
        nzClassName: 'purple-modal edit-se-mail-template-exception-modal',
        nzFooter: null,
        nzMaskClosable: false,
      }).afterClose.subscribe(async (refresh: boolean | undefined) => {
        if (refresh) {
          this.refreshTableRowsNumber++;
        }
      });
    })
  }

  async addNewSystemEventException() {
    this.modal.create<AddMailTemplateExceptionFastComponent, {alreadyPresentSystemEventExceptions: GetSaleEventSystemEventExceptionsResponse[], saleEventId: string }>({
      nzContent: AddMailTemplateExceptionFastComponent,
      nzData: {
        alreadyPresentSystemEventExceptions: this.listOfData?.map(m=> m.item)??[],
        saleEventId: this.saleEvent?.saleEventId??Guid.empty()
      },
      nzTitle: "Modifica template",
      nzWidth: '600px',
      nzOkText: "Conferma",
      nzClassName: 'purple-modal edit-se-mail-template-modal',
      nzFooter: null,
      nzMaskClosable: false,
    }).afterClose.subscribe(async (refresh: boolean | undefined) => {
      if (refresh) {
        this.refreshTableRowsNumber++;
      }
    });
  }

  async deleteException(seMtExc: GetSaleEventSystemEventExceptionsResponse) {
    this.modal.create({
      nzTitle: "Eliminare " + seMtExc.systemEvent.systemEventName + " - " + seMtExc.extraPk1?.extraValue ?? "" + seMtExc.extraPk2?.extraValue ?? "" + seMtExc.extraPk3?.extraValue ?? "" + seMtExc.extraPk4?.extraValue ?? "",
      nzContent: `Vuoi davvero eliminare il template ${seMtExc.systemEvent.systemEventName + " - " + seMtExc.extraPk1?.extraValue ?? "" + seMtExc.extraPk2?.extraValue ?? "" + seMtExc.extraPk3?.extraValue ?? "" + seMtExc.extraPk4?.extraValue ?? ""}?`,
      nzWidth: '600px',
      nzOkText: "Elimina",
      nzClassName: 'purple-simple-modal',
      nzFooter: [
        {
          label: "Elimina globale",
          onClick: async () => {

            await this.apiProxySvc.makeRequestErrorMessage<boolean>(() => this.admSvc.deleteSystemEventException(this.tsvc.currentLanguage.value, {
              exceptionId: seMtExc.exceptionId,
              deleteAllCulture: true
            }), false, "internal-loader", 500, undefined, undefined, () => {
              this.mesSvc.success("Traduzione elimiata correttamente", {
                nzDuration: environment.MESSAGE_DURATION
              });
              this.refreshTableRowsNumber++;
              this.modal.closeAll()
            })
          }
        },
        {
          label: "Elimina cultura",
          onClick: async () => {

            await this.apiProxySvc.makeRequestErrorMessage<boolean>(() => this.admSvc.deleteSystemEventException(this.tsvc.currentLanguage.value, {
              exceptionId: seMtExc.exceptionId,
              deleteAllCulture: false
            }), false, "internal-loader", 500, undefined, undefined, () => {
              this.mesSvc.success("Traduzione elimiata correttamente", {
                nzDuration: environment.MESSAGE_DURATION
              });
              this.refreshTableRowsNumber++;
              this.modal.closeAll()
            })
          }
        }
      ]
    })
  }
}
