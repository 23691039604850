<div class="ps-w-100 ps-d-flex ps-flex-wrap ps-align-items-center stats-external-container"
  *ngIf="saleStats!= undefined">
  <div *ngFor="let stat of saleStats" class="ps-col-4 stats-container">
    <app-summary-stats-item [title]="stat.title" [mainValueTitle]="stat.mainValueTitle" [mainValue]="stat.mainValue"
      [showSecondaryValue]="stat.showSecondaryValue" [secondaryValueTitle]="stat.secondaryValueTitle"
      [secondaryValue]="stat.secondaryValue" [valueCustomColor]="stat.valueCustomColor" [otherValue]="stat.otherValue"
      [otherValueIcon]="stat.otherValueIcon" [otherValueText]="stat.otherValueText" [imageUrl]="stat.imageUrl">
    </app-summary-stats-item>
  </div>
</div>
<div class="ps-w-100 ps-d-flex general-info-external-container">
  <div *ngIf="salePurpleFormFieldGroups.length > 0" class="ps-w-100 ps-d-flex ps-flex-wrap ps-justify-content-center general-info-container">

    <ng-container *ngFor="let formInfo of salePurpleFormFieldGroups; let i = index;">
      <div class="ps-d-flex ps-w-100 general-info-item {{i%2==0?'':'odd'}}">
        <div class="form-section-title-container">
          <p class="form-section-title">
            {{formInfo.formSectionName}} <span *ngIf="formInfo.canBeTranslated??false"
              class="{{tsvc.translationLanguage | async | languageIcon}}"></span>
          </p>
          <div *ngIf="formInfo.canBeTranslated??false" class="ps-w-100 ps-d-flex">
            <nz-select class="sale-description-language-select" [disabled]="disableForm"
              [nzPlaceHolder]="'select_private_sale_description_language' | translation: 'Lingua'" [nzShowArrow]="true"
              [ngModel]="tsvc.translationLanguage.value" (ngModelChange)="changeSaleDescriptionLanguage($event)">

              <nz-option *ngFor="let opt of this.tsvc.availableLanguages" [nzLabel]="opt.languageName"
                [nzValue]="opt.languageId">
              </nz-option>
            </nz-select>
          </div>
        </div>
        <div class="form-section-value-external-container">
          <div class="form-section-value-container">
            <container-element [ngSwitch]="formInfo.showSpin??false">
              <ng-container *ngSwitchCase="true">
                <nz-spin [nzSpinning]="getSpinnerStatus('description')" [nzIndicator]="purpleModalLoaderTemplate">
                  <app-purple-form [disbledForm]="disableForm" [(formGroup)]="validateForm"
                    [purpleFormFieldGroups]="formInfo.purpleFormGroups">
                  </app-purple-form>
                </nz-spin>
              </ng-container>
              <ng-container *ngSwitchCase="false">
                <app-purple-form [disbledForm]="disableForm" [(formGroup)]="validateForm"
                  [purpleFormFieldGroups]="formInfo.purpleFormGroups">
                </app-purple-form>
              </ng-container>
            </container-element>
          </div>
        </div>
      </div>
      <div *ngIf="(formInfo.showLine??true)" class="line"></div>
    </ng-container>
  </div>
</div>


<ng-template #purpleModalLoaderTemplate>
  <div class="form-section-custom-spinner">
    <span nz-icon nzType="loading"></span>
  </div>
</ng-template>