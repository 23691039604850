<ng-container *ngIf="dashSvc.dashboardHeight != undefined">

  <purple-table #purpleTable [tableBody]="tableBody" [isBordered]="true" [switchOn]="false"
    [refreshFunction]="refreshFunction" (tableData)="listOfData = $event" [tableColumns]="tabColumns"
    [tableScroll]="{y:dashSvc.dashboardHeight}" [refreshTableRowsNumber]="refreshTableRowsNumber" [version]="2"
    [showCustomPagination]="true" tableSearchType="box" (filterBox)="dashSvc.setPurpleTablePopover($event)"
    (onSearchBoxEnd)="dashSvc.closePurpleTablePopover()" [filterBoxStatus]="dashSvc.purpleTableBoxFilterStatus">

    <ng-template #tableBody>
      <ng-container *ngFor="let data of listOfData; let i = index">
        <tr class="{{i%2==0?'main-purple-table-tr-even': 'main-purple-table-tr-odd'}} {{data.item.couponCanBeUsed?'':'main-purple-table-tr-disabled'}}">
          <td><p class="ps-m-0 ps-p-0 fw-b">{{ data.item.couponId}}</p></td>
          <td>
            <nz-tag class="ps-mt-2" purpleStringToColor [word]="data.item.couponTypeId" [opacity]="10"> 
              {{data.item.couponTypeName}}
            </nz-tag>
          </td>
          <td>{{ data.item.roleName??"-"}}</td>
          <td>{{ data.item.fullUserInfo??"-"}}</td>
          <td>
            <container-element [ngSwitch]="data.item.isCouponDiscountMoneyEnabled">
              <ng-container *ngSwitchCase="true">
                <p class="ps-m-0 ps-p-0">{{data.item.couponDiscountMoney | currency: saleEvent!.currency!}}</p>
              </ng-container>
              <ng-container *ngSwitchCase="false">
                <p class="ps-m-0 ps-p-0">{{data.item.couponDiscountPercentage}}%</p>
              </ng-container>
            </container-element>
          </td>
          <!-- <td>{{ data.item.couponMaxTotalRedeems}}</td>
          <td>{{ data.item.couponMaxUserRedeems}}</td>
          <td>
            <container-element [ngSwitch]="data.item.couponIsCumulative">
              <ng-container *ngSwitchCase="true">
                <p class="ps-m-0 ps-p-0">{{ 'table_yes' | translation: 'Si': []}}</p>
              </ng-container>
              <ng-container *ngSwitchCase="false">
                <p class="ps-m-0 ps-p-0">{{ 'table_no' | translation: 'No': []}}</p>

              </ng-container>
            </container-element>
          </td>
          <td>{{ data.item.couponTotalUsage}}</td> -->

          <td>
            <button (click)="data.expanded = !data.expanded" [nzTooltipTitle]='
                          data.expanded?
                          ("hide_more_button_sale_event_user_table" | translation: "Nascondi dati aggiuntivi"):
                          ("show_more_button_sale_event_user_table" | translation: "Mostra più dati")
                          ' nzTooltipPlacement="top" class="ps-mr-3" nz-tooltip nz-button nzType="default"
              nzShape="circle"><i nz-icon [nzType]="data.expanded?'eye-invisible':'eye'"></i>
            </button>
            
            <button (click)="editCoupon(data.item!)"
              [nzTooltipTitle]="'private_sale_coupon_table_edit_private_sale_coupon_tooltip' | translation: 'Modifica coupon': []"
              nzTooltipPlacement="top" nz-tooltip class="ps-mr-3" nz-button nzType="default" nzShape="circle"><i nz-icon
                class="fsp-18-0" nzType="edit"></i></button>


                <button (click)="pendingUser(data.item!)" class="ps-mr-3"
                [nzTooltipTitle]='getCouponStatusTooltipTitle(data.item!)' nzTooltipPlacement="top" nz-tooltip nz-button
                nzType="default" nzShape="circle">
                <i nz-icon [nzType]="data.item.couponCanBeUsed?'stop':'check'"></i>
              </button>

            <!-- <button (click)="deleteCoupon(data.item!)"
              [nzTooltipTitle]="'private_sale_coupon_table_remove_private_sale_coupon_tooltip' | translation: 'Elimina template coupon': []"
              nzTooltipPlacement="top" nz-tooltip nz-button nzType="default" nzShape="circle"><i nz-icon
                class="fsp-18-0" nzType="delete"></i></button> -->
          </td>
        </tr>

        <tr [nzExpand]="data.expanded">
          <div class="ps-d-flex ps-w-100 ps-justify-content-center">
            <div
              class="ps-d-flex ps-flex-wrap ps-align-items-start ps-w-100 ps-justify-content-between table-expanded-row-container">

              <nz-descriptions class="ps-w-100"
                [nzTitle]="'sale_event_user_table_expanded_row_title' | translation: 'Informazioni aggiuntive': []"
                nzBordered [nzColumn]="{ xxl: 2, xl: 2, lg: 2, md: 2, sm: 1, xs: 1 }">
                <nz-descriptions-item [nzSpan]="4"
                  [nzTitle]="'private_sale_coupon_table_header_total_redeem' | translation: 'Max utilizzi': []">
                  {{data.item.couponMaxTotalRedeems}}
                </nz-descriptions-item>
                <nz-descriptions-item [nzTitle]="'private_sale_coupon_table_header_user_redeem' | translation: 'Max utilizzi utente': []">
                  {{data.item.couponMaxUserRedeems}}
                </nz-descriptions-item>
                <nz-descriptions-item [nzTitle]="'private_sale_coupon_table_header_is_cumulative' | translation: 'Cumulabile': []">
                  <container-element [ngSwitch]="data.item.couponIsCumulative">
                    <ng-container *ngSwitchCase="true">
                      <p class="ps-m-0 ps-p-0">{{ 'table_yes' | translation: 'Si': []}}</p>
                    </ng-container>
                    <ng-container *ngSwitchCase="false">
                      <p class="ps-m-0 ps-p-0">{{ 'table_no' | translation: 'No': []}}</p>
      
                    </ng-container>
                  </container-element>
                </nz-descriptions-item>
                <nz-descriptions-item [nzTitle]="'private_sale_coupon_table_header_expire_date' | translation: 'Data scadenza': []">
                  {{data.item.couponExpireDate | date: 'shortDate'}}
                </nz-descriptions-item>
                <nz-descriptions-item [nzTitle]="'private_sale_coupon_table_header_user_redeem' | translation: 'Utilizzi totali': []">
                  {{data.item.couponTotalUsage}}
                </nz-descriptions-item>
              </nz-descriptions>
            </div>
          </div>
        </tr>
      </ng-container>
    </ng-template>
  </purple-table>
</ng-container>