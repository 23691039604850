import { Component, OnInit } from '@angular/core';
import { NzModalService } from 'ng-zorro-antd/modal';
import { Guid, PurpleApiMakeRequestResponse, PurpleApiProxyService, PurpleLanguageIconPipe, PurpleTableBoxColumn, PurpleTableColumn, PurpleTableSearchValue, PurpleTableSelectFilter, PurpleTranslationPipe, dashboardButton, dashboardToggle, } from 'purple-lib';
import { AppTranslationService } from 'src/core-modules/localization/localization.service';
import { EditMailTemplateComponent } from '../../modals/edit-mail-template/edit-mail-template.component';
import { DashboardLayout2Service } from 'src/app/dashboard/dashboard-layout-2/dashboard-layout-2.service';
import { Subject, takeUntil } from 'rxjs';
import { BackOfficeService, GetSaleEventSystemEventsResponsePurpleTableResponse, PRPVSaleEventFullExtended, PurpleTableSearchGroupGroup, SaleEventMailTemplateItem } from 'src/core-modules/sdk/api';

import { PrivateSaleService } from 'src/app/dashboard/private-sales/private-sale-service.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-base-system-events',
  templateUrl: './base-system-event-mail-templates.component.html',
  styleUrls: ['./base-system-event-mail-templates.component.scss']
})
export class BaseSystemEventMailTemplatesComponent implements OnInit {

  constructor(public admSvc: BackOfficeService, public dashSvc: DashboardLayout2Service, public tsvc: AppTranslationService,
    private modal: NzModalService, private tranPipe: PurpleTranslationPipe, private seSvc: PrivateSaleService, private router: Router,
    private apiProxySvc: PurpleApiProxyService, private languageIconPipe: PurpleLanguageIconPipe, private route: ActivatedRoute) { }


  sub: Subject<void> = new Subject();
  saleEvent: PRPVSaleEventFullExtended | undefined;
  showTable: boolean = false;
  isDefaultSale: boolean = true;

  listOfData: Array<GetSaleEventSystemEventsResponsePurpleTableResponse> | undefined | null;
  refreshTableRowsNumber: number = 0;

  tabColumns: PurpleTableBoxColumn[] = []

  refreshFunction = (args: {
    pageNumber: number, pageSize: number, orderColumnName?: string | null, orderType?: string | null,
    tableSearchValues?: Array<PurpleTableSearchGroupGroup> | null, tableFilters?: Array<PurpleTableSelectFilter> | null, switchOn?: boolean,
    culture: string
  }) => {
    args.tableFilters = [{
      property: "saleEventId",
      filterValue: this.saleEvent?.saleEventId ?? Guid.empty()
    }];
    args.culture = this.tsvc.translationLanguage.value;
    return this.admSvc.getSaleEventSystemEventsTable(args.culture, args)
  }

  async ngOnInit() {
    this.dashSvc.addTranslationSelect();

    this.tsvc.translationLanguage.pipe(takeUntil(this.sub)).subscribe(async () => {
      await this.refreshTableRowsNumber++;
    });

    this.seSvc.currentSaleEvent$.pipe(takeUntil(this.sub)).subscribe((se: PRPVSaleEventFullExtended | undefined) => {
      this.saleEvent = se;
      this.showTable = true;
      this.isDefaultSale = (this.saleEvent?.saleEventId ?? Guid.empty()).strEq(Guid.empty());

      /* this.tabColumns = [
        {
          name: this.tranPipe.transform("translation_table_header_translation_language", "Lingua"),
          dbKey: "",
          hasReorder: false,
          hasFilter: false,
          fixedWidth: "80px"
        },
        {
          name: this.tranPipe.transform("table_header_is_translated", "Tradotto"),
          dbKey: "ToTranslate",
          hasReorder: true,
          hasFilter: true,
          filterType: 'select',
          fixedWidth: "130px",
          filters: [
            {
              text: "Si",
              value: false
            },
            {
              text: "No",
              value: true
            }
          ]
        },
        {
          name: this.tranPipe.transform("table_header_name", "Nome"),
          dbKey: "SystemEventName",
          hasReorder: true,
          hasFilter: true,
          filterType: 'search'
        },
        {
          name: this.tranPipe.transform("table_header_description", "Descrizione"),
          dbKey: "SystemEventDescription",
          hasReorder: true,
          hasFilter: true,
          filterType: 'search'
        },
        {
          name: this.tranPipe.transform("table_header_actions", "Azioni"),
          hasReorder: false,
          hasFilter: false,
          fixedWidth: "150px",
        }
      ] */

      this.tabColumns = [
        {
          name: this.tranPipe.transform("table_header_translation_language", "Lingua"),
          hasReorder: false,
          hasFilter: false,
          fixedWidth: "80px"
        },
        {
          name: this.tranPipe.transform("table_header_is_translated", "Tradotto"),
          dbKey: "ToTranslate",
          hasReorder: true,
          hasFilter: true,
          fixedWidth: "110px",
          filterField: {
            fieldType: 'select-local',
            fieldControlName: 'ToTranslate',
            fieldBootstrapColumn: 12,
            fieldName: this.tranPipe.transform('table_box_filter_is_translated', 'Tradotto', []),
            fieldPlaceholder: this.tranPipe.transform('table_box_filter_is_translated_placeholder', 'Tradotto', []),
            fieldValue: undefined,
            fieldIsRequired: false,
            fieldIsDisabled: false,
            fieldPaddingLeft: false,
            fieldPaddingRight: false,
            fieldOptions: [
              { id: 'isMultiple', value: false },
              { id: 'showArrow', value: true },
              { id: 'enableSearch', value: false },
              {
                id: 'options', value: [
                  {
                    label: 'Si',
                    value: 0
                  },
                  {
                    label: 'No',
                    value: 1
                  }
                ]
              }
            ]
          },
          operatorType: "="
        },
        {
          name: this.tranPipe.transform("table_header_name", "Nome"),
          dbKey: "SystemEventName",
          hasReorder: true,
          hasFilter: true,
          filterField: {
            fieldType: 'input-text',
            fieldControlName: 'SystemEventName',
            fieldBootstrapColumn: 12,
            fieldName: this.tranPipe.transform('table_filter_system_event_name', 'Nome', []),
            fieldPlaceholder: this.tranPipe.transform('table_filter_system_event_name_placeholder', 'Nome ruolo', []),
            fieldValue: undefined,
            fieldIsRequired: false,
            fieldIsDisabled: false,
            fieldPaddingLeft: false,
            fieldPaddingRight: false
          },
          operatorType: "like"
        },
        {
          name: this.tranPipe.transform("system_event_table_header_description", "Descrizione"),
          dbKey: "SystemEventDescription",
          hasReorder: true,
          hasFilter: true,
          filterField: {
            fieldType: 'input-text',
            fieldControlName: 'SystemEventDescription',
            fieldBootstrapColumn: 12,
            fieldName: this.tranPipe.transform('table_filter_system_event_description', 'Descrizione', []),
            fieldPlaceholder: this.tranPipe.transform('table_filter_description_system_event_placeholder', 'Descrizione ruolo', []),
            fieldValue: undefined,
            fieldIsRequired: false,
            fieldIsDisabled: false,
            fieldPaddingLeft: false,
            fieldPaddingRight: false
          },
          operatorType: "like"
        },
        {
          name: this.tranPipe.transform("table_header_actions", "Azioni"),
          hasReorder: false,
          hasFilter: false,
          fixedWidth: "190px"
        }
      ]

      if (!this.isDefaultSale) {
        this.tabColumns.splice(4, 0,
          {
            name: this.tranPipe.transform("table_header_default", "Default"),
            hasReorder: false,
            hasFilter: false,
            fixedWidth: "100px",
            showInfo: true,
            infoText: this.tranPipe.transform('edit_system_mail_template_default_info',
              'Se default viene utilizzato il mail template specificato in generale, se si vuole utilizzare un mail template personalizzato per la private sale {0} togliere il flag da default',
              [this.saleEvent?.saleEventName ?? ''])
          })
      }

      //this.refreshTableRowsNumber++;
    });

    this.route.queryParams.subscribe(async params => {
      console.log("ROUTE QUERY PARAMS")
      await this.refreshSale(params['saleEvent']);
    })
  }

  private async refreshSale(seId: string | undefined) {
    const cSeid = seId??Guid.empty();

    this.dashSvc.dashMainItems = [];

    if(!cSeid.strEq(Guid.empty())){
      this.dashSvc.dashMainItems?.push(
        new dashboardButton(
          "back-button",
          this.tranPipe.transform("dashboard_private_general_iofo_back_button", "Torna alla lista", []),
          () => { this.router.navigate([this.tsvc.currentLanguage.value, 'dashboard', 'private-sales', 'private-sale-list']) },
          undefined,
          "arrow-left",
          undefined,
          "left"
        )
      );
    }
    
    await this.dashSvc.refreshSaleEvent(cSeid, "base-system-event-mail-template", 'Lista dei template configurabili per le notifiche email',
      seId==undefined?this.tranPipe.transform('base-system-event-mail-template_general_page_title', 'Template Email', []):undefined, true);
  }

  async changeTemplateVisibility(saleEventId: string, systemEventId: string, check: boolean) {

    await this.apiProxySvc.makeRequestErrorMessage<boolean>(()=>this.admSvc.setSaleEventSystemEventVisibility(this.tsvc.currentLanguage.value, {
      saleEventId: saleEventId,
      systemEventId: systemEventId,
      showDefault: check
    }),true, "internal-loader",500,undefined,undefined,()=>{
      this.refreshTableRowsNumber++;
    })
  }


  ngOnDestroy(): void {
    this.sub.next();
    this.sub.complete();
    this.dashSvc.clearPageHeader();
  }

  async editSystemEventMailTemplate(pk1: string, systemEventId: string, systemEventName: string, showPreview: boolean = false) {
    await this.apiProxySvc.makeRequestErrorMessage<SaleEventMailTemplateItem>(() => this.admSvc.getSystemEventSaleEventMailTemplateDetail(this.tsvc.translationLanguage.value, {
      saleEventId: pk1,
      systemEventId: systemEventId!
    }), false, "internal-loader", 500, undefined, undefined, (res: PurpleApiMakeRequestResponse<SaleEventMailTemplateItem>) => {
      this.modal.create<EditMailTemplateComponent, { saleEventMailTemplate: SaleEventMailTemplateItem | undefined, systemEventId: string, systemEventName: string, saleEventId: string, isPreview: boolean }>({
        nzContent: EditMailTemplateComponent,
        nzData: {
          saleEventMailTemplate: res.data,
          systemEventId: systemEventId,
          systemEventName: systemEventName,
          saleEventId: pk1,
          isPreview: showPreview
        },
        nzTitle: this.tranPipe.transform('edit_sysem_event_mail_title', 'Email Template: <b>{0}</b> {1}', [systemEventName, '<span class="ps-ml-1 ' + this.languageIconPipe.transform(this.tsvc.translationLanguage.value) + '"></span>']),
        nzWidth: '85%',
        nzOkText: "Conferma",
        nzClassName: 'purple-modal edit-se-mail-template-modal',
        nzFooter: null,
        nzMaskClosable: false
      }).afterClose.subscribe(async () => {
        this.refreshTableRowsNumber++;
      });
    });
  }
}
