import { Injectable, OnInit, TemplateRef } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/default/environment';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NzMessageService } from 'ng-zorro-antd/message';
import { ActivatedRoute, Router } from '@angular/router';
import { PurpleApiMakeRequestResponse, PurpleApiProxyService, PurpleTranslationPipe, dashboardButton, dashboardLocalSelect, dashboardPopover, dashboardTag, dashboardToggle, psMenuItem, showMenu } from 'purple-lib';
import { AuthenticationService } from 'src/core-modules/authentication/authentication.service';
import { AppTranslationService } from 'src/core-modules/localization/localization.service';
import { PrivateSaleService } from '../private-sales/private-sale-service.service';
import { BackOfficeService, PRPVSaleEventFullExtended } from 'src/core-modules/sdk/api';

@Injectable({
  providedIn: 'root'
})
export class DashboardLayout2Service {
  softwareVersion: string = environment.appVersion;
  dashboardHeight: any | undefined;
  dashboardHeightNumber: BehaviorSubject<number | undefined> = new BehaviorSubject<number | undefined>(undefined);
  isMenuCollapsed: boolean = false;
  showMenu: showMenu[] = [];

  pageTitle: BehaviorSubject<string | undefined> = new BehaviorSubject<string | undefined>(undefined);
  pageSubTitle: BehaviorSubject<string | undefined> = new BehaviorSubject<string | undefined>(undefined);
  isUserMenuOpen: boolean = false;

  dashMainItems: (dashboardButton | dashboardToggle | dashboardPopover | dashboardLocalSelect | dashboardTag)[] = [];
  dashBackButtons: (dashboardButton | dashboardLocalSelect)[] = [];
  currentMenu: string | undefined;
  currentSubMenu: string | undefined;

  purpleTableBoxFilterStatus: "open" | "close" = "close";

  generalMenuSections: psMenuItem[] = [
    {
      id: "private-sales",
      name: this.tranPipe.transform('menu_private_sale', 'Private Sale', []),
      relativeLink: "private-sales",
      collapseIconName: "team",
      subMenus: [
        {
          id: "private-sale-list",
          iconName: "shop",
          name: this.tranPipe.transform('menu_private_sale_all_private_sales', 'Eventi', []),
          relativeLink: "private-sale-list"
        }
      ]
    },
    {
      id: "administration",
      name: this.tranPipe.transform('menu_administration', 'Amministrazione', []),
      relativeLink: "administration",
      collapseIconName: "team",
      subMenus: [
        {
          id: "customers",
          iconName: "user",
          name: this.tranPipe.transform('menu_users_customers', 'Clienti', []),
          relativeLink: "customers"
        },
        {
          id: "employees",
          iconName: "team",
          name: this.tranPipe.transform('menu_users_employees', 'Dipendenti', []),
          relativeLink: "employees"
        },
        {
          id: "roles",
          iconName: "tool",
          name: this.tranPipe.transform('menu_users_roles', 'Ruoli', []),
          relativeLink: "roles"
        },
      ]
    },
    {
      id: "notification",
      name: this.tranPipe.transform('menu_private_sale_notifications_mail', 'Notifiche', []),
      relativeLink: "notification",
      collapseIconName: "message",
      subMenus: [
        {
          id: "mail-templates",
          iconName: "message",
          name: this.tranPipe.transform('menu_notification_mail_templates', 'Mail Template', []),
          relativeLink: "mail-templates"
        },
        {
          id: "mail-template-exceptions",
          iconName: "warning",
          name: this.tranPipe.transform('menu_notification_mail_template_exceptions', 'Eccezioni Mail', []),
          relativeLink: "mail-template-exceptions"
        },
        {
          id: "smtp-servers",
          iconName: "tool",
          name: this.tranPipe.transform('menu_notification_smtp_servers', 'Configurazioni Smtp', []),
          relativeLink: "smtp-servers"
        }
      ]
    },
    {
      id: "general",
      name: this.tranPipe.transform('menu_general', 'Generale', []),
      relativeLink: "general",
      collapseIconName: "tool",
      subMenus: [
        {
          id: "translations",
          iconName: "translation",
          name: this.tranPipe.transform('menu_general_translations', 'Traduzioni', []),
          relativeLink: "translations"
        },
        {
          id: "policies",
          iconName: "read",
          name: this.tranPipe.transform('menu_general_policies', 'Policy', []),
          relativeLink: "policies"
        },
        {
          id: "general-images",
          iconName: "picture",
          name: this.tranPipe.transform('menu_general_general_images', 'Immagini', []),
          relativeLink: "general-images"
        },
        {
          id: "coupon-registry",
          iconName: "barcode",
          name: this.tranPipe.transform('menu_general_coupon_registry', 'Anagarfica Coupons', []),
          relativeLink: "coupon-registry"
        }
      ]
    }
  ]

  saleMenuSections: psMenuItem[] = [
    {
      id: "private-sale-detail",
      name: this.tranPipe.transform('menu_private_sale', 'Private Sale', []),
      relativeLink: "private-sale-detail",
      collapseIconName: "team",
      subMenus: [
        {
          id: "general-informations",
          iconName: "shop",
          name: this.tranPipe.transform('menu_private_sale_general_info', 'Informazioni Generali', []),
          relativeLink: "general-informations",
          queryParams: { saleEvent: this.seSvc.currentSaleEvent$.value?.saleEventId }
        },
        {
          id: "private-sale-days-slots",
          iconName: "calendar",
          name: this.tranPipe.transform('menu_private_sale_days_slots', 'Fasce Orarie', []),
          relativeLink: "private-sale-days-slots",
          queryParams: { saleEvent: this.seSvc.currentSaleEvent$.value?.saleEventId }
        },
        {
          id: "private-sale-roles",
          iconName: "tool",
          name: this.tranPipe.transform('menu_private_sale_roles', 'Ruoli', []),
          relativeLink: "private-sale-roles",
          queryParams: { saleEvent: this.seSvc.currentSaleEvent$.value?.saleEventId }
        },
        {
          id: "private-sale-attributes",
          iconName: "control",
          name: this.tranPipe.transform('menu_private_sale_attributes', 'Attributi', []),
          relativeLink: "private-sale-attributes",
          queryParams: { saleEvent: this.seSvc.currentSaleEvent$.value?.saleEventId }
        },
        {
          id: "private-sale-users",
          iconName: "team",
          name: this.tranPipe.transform('menu_private_sale_users', 'Utenti Sale', []),
          relativeLink: "private-sale-users",
          queryParams: { saleEvent: this.seSvc.currentSaleEvent$.value?.saleEventId }
        },
        {
          id: "private-sale-imported-users",
          iconName: "download",
          name: this.tranPipe.transform('menu_private_sale_imported_users', 'Utenti Importati', []),
          relativeLink: "private-sale-imported-users",
          queryParams: { saleEvent: this.seSvc.currentSaleEvent$.value?.saleEventId }
        },
        {
          id: "private-sale-user-referrals",
          iconName: "share-alt",
          name: this.tranPipe.transform('menu_private_sale_user_referrals', 'Codici invito Utenti', []),
          relativeLink: "private-sale-user-referrals",
          queryParams: { saleEvent: this.seSvc.currentSaleEvent$.value?.saleEventId }
        },
        {
          id: "private-sale-policies",
          iconName: "read",
          name: this.tranPipe.transform('menu_private_sale_policies', 'Policy', []),
          relativeLink: "private-sale-policies",
          queryParams: { saleEvent: this.seSvc.currentSaleEvent$.value?.saleEventId }
        },
        {
          id: "private-sale-cash-machine-settings",
          iconName: "shopping-cart",
          name: this.tranPipe.transform('menu_private_sale_cash_machine_settings', 'Limiti Cassa', []),
          relativeLink: "private-sale-cash-machine-settings",
          queryParams: { saleEvent: this.seSvc.currentSaleEvent$.value?.saleEventId }
        },
        {
          id: "private-sale-bookings-report",
          iconName: "book",
          name: this.tranPipe.transform('menu_private_sale_bookings_report', 'Report prenotazioni', []),
          relativeLink: "private-sale-bookings-report",
          queryParams: { saleEvent: this.seSvc.currentSaleEvent$.value?.saleEventId }
        },
        {
          id: "private-sale-mail-templates",
          iconName: "message",
          name: this.tranPipe.transform('menu_private_sale_mail_templates', 'Mail Template', []),
          relativeLink: "private-sale-mail-templates",
          queryParams: { saleEvent: this.seSvc.currentSaleEvent$.value?.saleEventId }
        },
        {
          id: "private-sale-mail-template-exceptions",
          iconName: "warning",
          name: this.tranPipe.transform('menu_private_sale_mail_template_exceptions', 'Eccezioni Mail', []),
          relativeLink: "private-sale-mail-template-exceptions",
          queryParams: { saleEvent: this.seSvc.currentSaleEvent$.value?.saleEventId }
        },
        {
          id: "private-sale-images",
          iconName: "picture",
          name: this.tranPipe.transform('menu_private_sale_images', 'Immagini', []),
          relativeLink: "private-sale-images",
          queryParams: { saleEvent: this.seSvc.currentSaleEvent$.value?.saleEventId }
        },
        {
          id: "private-sale-coupons",
          iconName: "barcode",
          name: this.tranPipe.transform('menu_private_sale_coupons', 'Coupons', []),
          relativeLink: "private-sale-coupons",
          queryParams: { saleEvent: this.seSvc.currentSaleEvent$.value?.saleEventId }
        }
      ]
    }
  ]

  currentMenuSection: psMenuItem[] = this.generalMenuSections;

  constructor(private modal: NzModalService, private mesSvc: NzMessageService, private tsvc: AppTranslationService, private route: ActivatedRoute,
    private router: Router, private tranPipe: PurpleTranslationPipe, private authSvc: AuthenticationService,
    private seSvc: PrivateSaleService, private apiProxySvc: PurpleApiProxyService, private admSvc: BackOfficeService) {

    if (this.showMenu.length == 0) {
      this.showMenu = environment.ACTIVE_MENU.map(m => ({ menu: m.menu, submenu: m.submenu } as showMenu))
    }
  }

  setCurrentMenuItems(type: "default" | "sale") {
    switch (type) {
      case "default":
        this.currentMenuSection = [];
        this.currentMenuSection = this.generalMenuSections;
        break;
      case "sale":
        console.log("Setto il current menu a sale")
        this.currentMenuSection = [];
        this.currentMenuSection = this.saleMenuSections;
        break;
      default:
        break;
    }
    console.log("Current Menu: ", this.currentMenuSection)

  }

  addTranslationSelect(position: "back" | "main" = "back") {

    var idx = -1;

    if (position === 'back') {
      idx = this.dashBackButtons.findIndex(f => f.id.strEq("tran-language"));
    } else {
      idx = this.dashMainItems.findIndex(f => f.id.strEq("tran-language"));
    }

    if(idx == -1){
      const sel = new dashboardLocalSelect("tran-language", async (id: string | undefined) => {
        if (id != undefined) {
          this.tsvc.changeTranslationLanguage(id);



          this.router.navigate([this.router.url.split('?')[0]], {
  
            queryParams: {
              translationLanguage: this.tsvc.translationLanguage.value
            },
            relativeTo: this.route,
            queryParamsHandling: 'merge'
          });
        }
      },
        this.tsvc.availableLanguages.map(m => {
          return {
            label: m.languageName,
            value: m.languageId
          }
        }),
        'default',
        this.tsvc.translationLanguage.value,
        this.tranPipe.transform('select_translation_language', 'Lingua Traduzione', []),
        this.tranPipe.transform('select_translation_language_placeholder', 'Seleziona la lingua di traduzione', []),
        true,
        false,
        false,
        "translation-language-select"
      );
  
      if (position.strEq("back")) {
        this.dashBackButtons?.push(sel);
      } else {
        this.dashMainItems?.push(sel);
      }
    }
  }

  setPageHeader(title?: string | undefined, subtitle?: string | undefined): void {
    setTimeout(() => {
      this.pageTitle.next(title);
      this.pageSubTitle.next(subtitle);
    }, 100);
  }

  setCurrentActiveMenuSubMenu(menu?: string | undefined, subMenu?: string | undefined): void {
    this.currentMenu = menu;
    this.currentSubMenu = subMenu;
  }

  clearPageHeader() {
    this.dashBackButtons = [];
    this.dashMainItems = [];
    this.pageTitle.next(undefined);
    this.pageSubTitle.next(undefined);
  }

  logout(isMobile: boolean): void {
    this.isUserMenuOpen = false;
    this.modal.create(
      {
        nzTitle: isMobile ? this.tranPipe.transform("logout_title_mobile", "Logout") : this.tranPipe.transform("logout_title_desktop", "Vuoi davvero uscire??"),
        nzContent: this.tranPipe.transform("logout_subtitle", "{0} sei sicuro di voler uscire?", [this.authSvc.currentUser?.user?.firstName!]),
        nzWidth: isMobile ? '80%' : '600px',
        nzClassName: 'purple-simple-modal',
        nzCentered: isMobile ? true : false,
        nzClosable: false,
        nzMaskClosable: false,
        nzOkText: this.tranPipe.transform("logout", "logout"),
        nzOnOk: async () => {
          var res = this.authSvc.logout()

          if (res) {
            this.mesSvc.success(this.tranPipe.transform("logout_ok_response", "Logout effettuato correttamente"), {
              nzDuration: environment.MESSAGE_DURATION
            })

            this.router.navigate([this.tsvc.currentLanguage.value, 'login'], {})
          } else {
            this.mesSvc.error(this.tranPipe.transform("logout_error_response", "Errore! non è stato possibile effettuare il logout"), {
              nzDuration: environment.MESSAGE_DURATION
            })
          }
        }
      }
    )
  }

  changeCountry(isMobile: boolean) {
    this.modal.create(
      {
        nzTitle: this.tranPipe.transform("modal_change_country_title", "Cambia nazione?"),
        nzContent: this.tranPipe.transform("modal_change_country_subtitle", "Sei sicuro di voler cambiare nazione? assicurati di avere un account valido per la nazione che selezionerai", []),
        nzWidth: isMobile ? '80%' : '500px',
        nzClassName: 'purple-simple-modal',
        nzCentered: isMobile ? true : false,
        nzClosable: true,
        nzMaskClosable: false,
        nzOkText: this.tranPipe.transform("modal_change_country_button", "Cambia"),
        nzCancelDisabled: true,
        nzCancelText: null,
        nzZIndex: 1000,
        nzOnOk: async () => {
          localStorage.clear();
          window.location.reload();
        }
      }
    )
  }

  async refreshSaleEvent(saleEventId: string, pageName: string, pageSubtitle:string, overrideTitle: string | undefined = undefined, forceRefresh: boolean = false){
    if(this.seSvc.currentSaleEvent$.value == undefined || forceRefresh){
      await this.apiProxySvc.makeRequestErrorPage<PRPVSaleEventFullExtended>(() => this.admSvc.getSaleEvent(this.tsvc.currentLanguage.value, {
        saleEventId: saleEventId
      }), true, "internal-loader", 500, "internal-error", undefined, async (res: PurpleApiMakeRequestResponse<PRPVSaleEventFullExtended>) => {
        this.setPageHeader(
          overrideTitle??this.tranPipe.transform(pageName + '_page_title', '{0}', [res.data!.saleEventName]),
          this.tranPipe.transform(pageName + '_page_subtitle', pageSubtitle, []));
  
        this.seSvc.setCurrentSaleEvent(res.data);
      });
    }else{
      this.setPageHeader(
        overrideTitle??this.tranPipe.transform(pageName + '_page_title', '{0}', [this.seSvc.currentSaleEvent$.value.saleEventName]),
        this.tranPipe.transform(pageName + '_page_subtitle', pageSubtitle, []));
    }    
  }

  closePurpleTablePopover() {
    const el = this.dashMainItems.find(f => f.id.strEq("purple-table-filter-popover"));

    if (el != undefined) {
      (el as dashboardPopover).closePopover()
    }
  }

  setPurpleTablePopover(template: TemplateRef<any>) {
    this.purpleTableBoxFilterStatus = "close";
    
    this.dashMainItems.push(
      new dashboardPopover("purple-table-filter-popover",
        this.tranPipe.transform('purple_table_filter_button', 'Filtri', []),
        template,
        undefined,
        'default',
        'down',
        'filter-button',
        undefined,
        'click',
        'bottomRight',
        'purple-table-filters-popover',
        false,
        (isOpen: boolean) => {
          this.purpleTableBoxFilterStatus = isOpen ? "open" : "close"
        }));
  }
}

