import { Component, Inject, OnInit } from '@angular/core';
import { PurpleApiMakeRequestResponse, PurpleApiProxyService, PurpleSelectFilter, PurpleTranslationPipe } from 'purple-lib';
import { AppTranslationService } from 'src/core-modules/localization/localization.service';
import { BackOfficeService } from 'src/core-modules/sdk/api';
import { environment } from 'src/environments/default/environment';
import { NzModalRef, NZ_MODAL_DATA } from 'ng-zorro-antd/modal';
import { NzMessageService } from 'ng-zorro-antd/message';


@Component({
  selector: 'app-send-imported-users-email-modal',
  templateUrl: './send-imported-users-email-modal.component.html',
  styleUrls: ['./send-imported-users-email-modal.component.scss']
})
export class SendImportedUsersEmailModalComponent implements OnInit {

  constructor(@Inject(NZ_MODAL_DATA) private saleEventId: string, private admSvc: BackOfficeService, private tsvc: AppTranslationService, private tranPipe: PurpleTranslationPipe,
    private apiProxySvc: PurpleApiProxyService, private modalRef: NzModalRef, private mesSvc: NzMessageService) { }

  isLoading: boolean = false;
  bookingHost: string = localStorage.getItem("BookingHost")!;

  ngOnInit() {
  }

  roleId: string | undefined;

  async sendEmail() {
    this.isLoading = true;
    await this.apiProxySvc.makeRequestErrorMessage<boolean>(() => this.admSvc.enqueueBulkImportedUsers(this.tsvc.currentLanguage.value, {
      clientHost: this.bookingHost,
      roleId: this.roleId,
      saleEventId: this.saleEventId
    }), false, "internal-loader", 500, undefined, undefined, (res: PurpleApiMakeRequestResponse<boolean>) => {
      this.mesSvc.success(this.tranPipe.transform('invited_users_email_sent_ok','Email correttamente accodate per essere spedite', []), {
        nzDuration: environment.MESSAGE_DURATION
      });
      this.isLoading = false;
      this.modalRef.destroy(true);
    });
    this.isLoading = false;
  }


  //ScrollFunction
  rolesScrollFunction = (args: { pageNumber: number, pageSize: number, culture: string, searchName: string, searchFilters: PurpleSelectFilter[] }) => {
    args.searchFilters = [
      { propertyName: "roleType", filterValues: ["notStaff"] }
    ]

    return this.admSvc.getRolesSelect(args.culture, args)
  }
  //SearchFunction
  rolesSearchFunction = (args: { pageNumber: number, pageSize: number, culture: string, searchName: string, searchFilters: PurpleSelectFilter[] }) => {
    args.searchFilters = [
      { propertyName: "roleType", filterValues: ["notStaff"] }
    ]
    return this.admSvc.getRolesByNameSelect(args.culture, args)
  }
  //InitFunction (SearchByIdFunction)
  rolesSearchByIdFunction = (args: { pageNumber: number, pageSize: number, culture: string, searchName: string, searchFilters: PurpleSelectFilter[] }) => {
    return this.admSvc.getRolesByIdSelect(args.culture, args)
  }

}
