<ng-container *ngIf="dashSvc.dashboardHeight != undefined && showTable">
  <purple-table #purpleTable [tableBody]="tableBody" [isBordered]="true" [switchOn]="false"
    [refreshFunction]="refreshFunction" (tableData)="listOfData = $event" [tableColumns]="tabColumns"
    [tableScroll]="{y:dashSvc.dashboardHeight}" [refreshTableRowsNumber]="refreshTableRowsNumber" [version]="2"
    [showCustomPagination]="true" tableSearchType="box" (filterBox)="dashSvc.setPurpleTablePopover($event)" 
    (onSearchBoxEnd)="dashSvc.closePurpleTablePopover()" [filterBoxStatus]="dashSvc.purpleTableBoxFilterStatus">
    <ng-template #tableBody>
      <ng-container *ngFor="let data of listOfData; let i = index">
        <tr class="{{i%2==0?'main-purple-table-tr-even': 'main-purple-table-tr-odd'}} ">
          <td nzAlign="center"><span class="{{tsvc.translationLanguage.value | languageIcon}}"></span></td>
          <td nzAlign="center">
            <container-element [ngSwitch]="data.item.toTranslate">
              <ng-container *ngSwitchCase="false">
                <span nz-icon nzType="check-circle" [nzTheme]="'twotone'" [nzTwotoneColor]="'#52c41a'"></span>
              </ng-container>
              <ng-container *ngSwitchCase="true">
                <span nz-icon nzType="close-circle" [nzTheme]="'twotone'" [nzTwotoneColor]="'#eb2f96'"></span>
              </ng-container>
            </container-element>
          </td>
          <td>{{data.item.systemEvent.systemEventName}}</td>
          <td>{{data.item.systemEvent.systemEventDescription}}</td>
          <td *ngIf="!isDefaultSale" nzAlign="center">
            <label nz-checkbox
              (ngModelChange)="changeTemplateVisibility(data.item.saleEventId, data.item.systemEvent.systemEventId, $event)"
              [ngModel]="data.item.useDefaultTemplate"></label>
          </td>
          <td nzAlign="center">
            <button
              (click)="editSystemEventMailTemplate(data.item.saleEventId , data.item.systemEvent.systemEventId, data.item.systemEvent.systemEventName, true)"
              [nzTooltipTitle]='"email_table_mail_template_preview" | translation: "Mostra Anteprima"' [disabled]="!isDefaultSale && data.item.useDefaultTemplate"
              nzTooltipPlacement="top" nz-tooltip class="ps-mr-3" nz-button nzType="default" nzShape="circle">
              <i nz-icon class="fsp-18-0" nzType="eye"></i>
            </button>

            <button
              (click)="editSystemEventMailTemplate(data.item.saleEventId , data.item.systemEvent.systemEventId, data.item.systemEvent.systemEventName)"
              [nzTooltipTitle]='"email_table_mail_template_edit" | translation: "Modifica Template"' [disabled]="!isDefaultSale && data.item.useDefaultTemplate"
              nzTooltipPlacement="top" nz-tooltip nz-button nzType="default" nzShape="circle">
              <i nz-icon class="fsp-18-0" nzType="edit"></i>
            </button>
          </td>
        </tr>
      </ng-container>
    </ng-template>
  </purple-table>
</ng-container>