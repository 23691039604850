<nz-upload class="{{fileList.length>0?'selected':''}}" nzType="drag" [nzBeforeUpload]="beforeUpload"
  [(nzFileList)]="fileList" nzListType="picture" [nzMultiple]="false">
  <p class="ant-upload-drag-icon">
    <span nz-icon nzType="inbox"></span>
  </p>
  <p class="ant-upload-text">{{ 'upload_text' | translation: 'Clicca o trascina qui per caricare': []}}</p>
  <p class="ant-upload-hint">
    {{ 'upload_subtext' | translation: 'Formati supportati: {0}': ['xlsx']}}
  </p>
</nz-upload>

<div class="purple-button-container ps-w-100 ps-d-flex">
  <button (click)="uploadFile()" nz-button class="purple-modal-button" [disabled]="fileList.length==0 || isLoading"
    [nzLoading]="isLoading">
    {{ 'upload_button' | translation: 'Carica': []}}
  </button>
</div>