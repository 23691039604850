import { Component, Inject, LOCALE_ID, OnDestroy, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { PurpleTranslationPipe, PurpleApiProxyService, PurpleTableColumn, PurpleTableSearchValue, PurpleTableSelectFilter, Guid, dashboardButton, PurpleApiMakeRequestResponse, PurpleTableBoxColumn, PurpleSelectFilter } from 'purple-lib';
import { Subject, takeUntil } from 'rxjs';
import { DashboardLayout2Service } from 'src/app/dashboard/dashboard-layout-2/dashboard-layout-2.service';
import { AppTranslationService } from 'src/core-modules/localization/localization.service';

import { BackOfficeService, PRPVSaleEventFullExtended, PRPVUserBulkImportStatusPurpleTableResponse, PurpleTableSearchGroupGroup } from 'src/core-modules/sdk/api';
import { PrivateSaleService } from '../../../private-sale-service.service';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NzMessageService } from 'ng-zorro-antd/message';
import { environment } from 'src/environments/default/environment';
import { SendImportedUsersEmailModalComponent } from '../../modals/send-imported-users-email-modal/send-imported-users-email-modal.component';
import { ImportPrivateSaleUsersModalComponent } from '../../modals/import-private-sale-users-modal/import-private-sale-users-modal.component';
import { getLocaleDateFormat, FormatWidth } from '@angular/common';

@Component({
  selector: 'app-private-sale-imported-users',
  templateUrl: './private-sale-imported-users.component.html',
  styleUrls: ['./private-sale-imported-users.component.scss']
})
export class PrivateSaleImportedUsersComponent implements OnInit, OnDestroy {
  constructor(@Inject(LOCALE_ID) private locale: string, private tranPipe: PurpleTranslationPipe, public dashSvc: DashboardLayout2Service, private tsvc: AppTranslationService, private router: Router,
    public admSvc: BackOfficeService, private seSvc: PrivateSaleService, private apiProxySvc: PurpleApiProxyService, private route: ActivatedRoute,
    private modal: NzModalService, private mesSvc: NzMessageService) { }

  showTable: boolean = false;
  saleEvent: PRPVSaleEventFullExtended | undefined;
  sub: Subject<void> = new Subject();
  listOfData: Array<PRPVUserBulkImportStatusPurpleTableResponse> | undefined | null;
  refreshTableRowsNumber: number = 0;

  //ScrollFunction
  saleEventRolesScrollFunction = (args: { pageNumber: number, pageSize: number, culture: string, searchName: string, searchFilters: PurpleSelectFilter[] }) => {
    args.searchFilters = [
      {
        propertyName: "saleEventId",
        filterOperator: "=",
        filterValues: [this.saleEvent!.saleEventId]
      },
      {
        propertyName: "IsPrivateInviteCounter",
        filterOperator: "=",
        filterValues: [this.saleEvent!.isPrivateInviteCounter ? "true" : "false"]
      }
    ]
    return this.admSvc.getSaleEventRolesSelect(args.culture, args)
  }
  //SearchFunction
  saleEventRolesSearchFunction = (args: { pageNumber: number, pageSize: number, culture: string, searchName: string, searchFilters: PurpleSelectFilter[] }) => {
    args.searchFilters = [
      {
        propertyName: "saleEventId",
        filterOperator: "=",
        filterValues: [this.saleEvent!.saleEventId]
      },
      {
        propertyName: "IsPrivateInviteCounter",
        filterOperator: "=",
        filterValues: [this.saleEvent!.isPrivateInviteCounter ? "true" : "false"]
      }
    ]
    return this.admSvc.getSaleEventRolesByNameSelect(args.culture, args)
  }
  //InitFunction (SearchByIdFunction)
  saleEventRolesSearchByIdFunction = (args: { pageNumber: number, pageSize: number, culture: string, searchName: string, searchFilters: PurpleSelectFilter[] }) => {
    return this.admSvc.getSaleEventRolesByIdSelect(args.culture, args)
  }

  tabColumns: PurpleTableBoxColumn[] = [
    {
      name: this.tranPipe.transform("table_header_name", "Nome"),
      dbKey: "firstname",
      hasReorder: true,
      hasFilter: true,
      filterField: {
        fieldType: 'input-text',
        fieldControlName: 'firstname',
        fieldBootstrapColumn: 6,
        fieldName: this.tranPipe.transform('imported_user_table_box_filter_firstname', 'Nome', []),
        fieldPlaceholder: this.tranPipe.transform('imported_user_table_box_filter_firstname_placeholder', 'Nome del cliente', []),
        fieldValue: undefined,
        fieldIsRequired: false,
        fieldIsDisabled: false,
        fieldPaddingLeft: false,
        fieldPaddingRight: true
      },
      operatorType: "like"
    },
    {
      name: this.tranPipe.transform("imported_user_table_header_lastname", "Cognome"),
      dbKey: "lastname",
      hasReorder: true,
      hasFilter: true,
      filterField: {
        fieldType: 'input-text',
        fieldControlName: 'lastname',
        fieldBootstrapColumn: 6,
        fieldName: this.tranPipe.transform('imported_user_table_box_filter_lastname', 'Cognome', []),
        fieldPlaceholder: this.tranPipe.transform('imported_user_table_box_filter_lastname_placeholder', 'Cognome del cliente', []),
        fieldValue: undefined,
        fieldIsRequired: false,
        fieldIsDisabled: false,
        fieldPaddingLeft: true,
        fieldPaddingRight: false
      },
      operatorType: "like"
    },
    {
      name: this.tranPipe.transform("imported_user_table_header_email", "Email"),
      dbKey: "email",
      hasReorder: true,
      hasFilter: true,
      filterField: {
        fieldType: 'input-text',
        fieldControlName: 'email',
        fieldBootstrapColumn: 6,
        fieldName: this.tranPipe.transform('imported_user_table_box_filter_email', 'Email', []),
        fieldPlaceholder: this.tranPipe.transform('imported_user_table_box_filter_email_placeholder', 'Email del cliente', []),
        fieldValue: undefined,
        fieldIsRequired: false,
        fieldIsDisabled: false,
        fieldPaddingLeft: false,
        fieldPaddingRight: true
      },
      operatorType: 'like'
    },
    {
      name: this.tranPipe.transform("imported_user_table_header_blocked", "Bloccato"),
      dbKey: "isPending",
      hasReorder: true,
      hasFilter: true,
      filterField: {
        fieldType: 'select-local',
        fieldControlName: 'isPending',
        fieldBootstrapColumn: 6,
        fieldName: this.tranPipe.transform('imported_user_table_box_filter_blocked', 'Bloccato', []),
        fieldPlaceholder: this.tranPipe.transform('imported_user_table_box_filter_blocked_placeholder', 'Cliente bloccato', []),
        fieldValue: undefined,
        fieldIsRequired: false,
        fieldIsDisabled: false,
        fieldPaddingLeft: true,
        fieldPaddingRight: false,
        fieldOptions: [
          { id: 'isMultiple', value: false },
          { id: 'showArrow', value: true },
          { id: 'enableSearch', value: false },
          {
            id: 'options', value: [
              {
                label: 'Si',
                value: 1
              },
              {
                label: 'No',
                value: 0
              }
            ]
          }
        ]
      },
      operatorType: "="
    },
    {
      name: this.tranPipe.transform("imported_user_table_header_email_sent", "Email inviata"),
      dbKey: "isEmailSent",
      hasReorder: true,
      hasFilter: true,
      filterField: {
        fieldType: 'select-local',
        fieldControlName: 'isEmailSent',
        fieldBootstrapColumn: 6,
        fieldName: this.tranPipe.transform('imported_user_table_box_filter_email_sent', 'Email inviata', []),
        fieldPlaceholder: this.tranPipe.transform('imported_user_table_box_filter_email_sent_placeholder', 'Email inviata', []),
        fieldValue: undefined,
        fieldIsRequired: false,
        fieldIsDisabled: false,
        fieldPaddingLeft: false,
        fieldPaddingRight: true,
        fieldOptions: [
          { id: 'isMultiple', value: false },
          { id: 'showArrow', value: true },
          { id: 'enableSearch', value: false },
          {
            id: 'options', value: [
              {
                label: 'Si',
                value: 1
              },
              {
                label: 'No',
                value: 0
              }
            ]
          }
        ]
      },
      operatorType: "="
    },
    {
      name: this.tranPipe.transform("imported_user_table_header_is_registered", "Registrazione Completata"),
      dbKey: "isRegistered",
      hasReorder: true,
      hasFilter: true,
      filterField: {
        fieldType: 'select-local',
        fieldControlName: 'isRegistered',
        fieldBootstrapColumn: 6,
        fieldName: this.tranPipe.transform('imported_user_table_box_filter_is_registered', 'Registrazione Completata', []),
        fieldPlaceholder: this.tranPipe.transform('imported_user_table_box_filter_is_registered_placeholder', 'Registrazione Completata', []),
        fieldValue: undefined,
        fieldIsRequired: false,
        fieldIsDisabled: false,
        fieldPaddingLeft: true,
        fieldPaddingRight: false,
        fieldOptions: [
          { id: 'isMultiple', value: false },
          { id: 'showArrow', value: true },
          { id: 'enableSearch', value: false },
          {
            id: 'options', value: [
              {
                label: 'Si',
                value: 1
              },
              {
                label: 'No',
                value: 0
              }
            ]
          }
        ]
      },
      operatorType: "="
    },
    {
      name: this.tranPipe.transform("table_header_actions", "Azioni"),
      hasReorder: false,
      hasFilter: false
    },
    {
      name: this.tranPipe.transform("imported_user_table_header_company", "Azienda"),
      dbKey: "userCompany",
      hasReorder: false,
      hasFilter: true,
      filterField: {
        fieldType: 'input-text',
        fieldControlName: 'userCompany',
        fieldBootstrapColumn: 6,
        fieldName: this.tranPipe.transform('imported_user_table_box_filter_company', 'Azienda', []),
        fieldPlaceholder: this.tranPipe.transform('imported_user_table_box_filter_company_placeholder', 'Azienda del cliente', []),
        fieldValue: undefined,
        fieldIsRequired: false,
        fieldIsDisabled: false,
        fieldPaddingLeft: false,
        fieldPaddingRight: true
      },
      operatorType: "like",
      hiddenColumn: true
    },
    {
      name: this.tranPipe.transform("imported_user_table_header_role", "Ruolo"),
      dbKey: "sourceRoleId",
      hasReorder: true,
      hasFilter: true,
      filterField: {
        fieldType: 'select',
        fieldControlName: 'sourceRoleId',
        fieldBootstrapColumn: 6,
        fieldName: this.tranPipe.transform('imported_user_table_box_filter_role', 'Ruolo', []),
        fieldPlaceholder: this.tranPipe.transform('imported_user_table_box_filter_role_placeholder', 'Ruolo cliente', []),
        fieldValue: undefined,
        fieldIsRequired: false,
        fieldIsDisabled: false,
        fieldPaddingLeft: true,
        fieldPaddingRight: false,
        fieldOptions: [
          { id: 'scrollFunction', value: this.saleEventRolesScrollFunction },
          { id: 'searchFunction', value: this.saleEventRolesSearchFunction },
          { id: 'searchByIdFunction', value: this.saleEventRolesSearchByIdFunction },
          { id: 'refreshFieldNumber', value: 0 }
        ]
      },
      operatorType: '=',
      hiddenColumn: true
    },
    {
      name: this.tranPipe.transform("imported_imported_user_table_header_email_sent_date", "Data invio email"),
      dbKey: "emailDate",
      hasReorder: true,
      hasFilter: true,
      filterField: {
        fieldType: 'date-range',
        fieldControlName: 'emailDate',
        fieldBootstrapColumn: 12,
        fieldName: this.tranPipe.transform('imported_user_table_box_filter_email_sent_date', 'Data invio email', []),
        fieldPlaceholder: this.tranPipe.transform('imported_user_table_box_filter_sent_date_placeholder', 'Data invio email', []),
        fieldValue: undefined,
        fieldIsRequired: false,
        fieldIsDisabled: false,
        fieldPaddingLeft: false,
        fieldPaddingRight: false,
        fieldOptions: [
          { id: 'dateFormat', value: getLocaleDateFormat(this.locale, FormatWidth.Medium) },
          { id: 'disabledDate', value: undefined }
        ]
      },
      operatorType: "date between",
      hiddenColumn: true
    }
  ]

  refreshFunction = (args: {
    pageNumber: number, pageSize: number, orderColumnName?: string | null, orderType?: string | null,
    tableSearchValues?: Array<PurpleTableSearchGroupGroup> | null, tableFilters?: Array<PurpleTableSelectFilter> | null, switchOn?: boolean,
    culture: string
  }) => {
    args.culture = this.tsvc.translationLanguage.value;

    args.tableFilters = [
      {
        property: "saleEventId",
        filterValue: this.saleEvent?.saleEventId ?? Guid.empty()
      }
    ];


    return this.admSvc.getSaleEventBulkImportedUsersTable(args.culture, args);
  }

  ngOnDestroy(): void {
    this.sub.next();
    this.sub.complete();
    this.dashSvc.clearPageHeader();
  }

  ngOnInit() {
    this.seSvc.currentSaleEvent$.pipe(takeUntil(this.sub)).subscribe((se: PRPVSaleEventFullExtended | undefined) => {
      this.saleEvent = se;

      this.showTable = true;
    });

    this.route.queryParams.subscribe(async params => {
      this.dashSvc.dashBackButtons?.push(
        new dashboardButton(
          "back-button",
          this.tranPipe.transform("dashboard_private_general_iofo_back_button", "Torna alla lista", []),
          () => { this.router.navigate([this.tsvc.currentLanguage.value, 'dashboard', 'private-sales', 'private-sale-list']) },
          undefined,
          "arrow-left",
          undefined,
          "left"
        )
      );

      this.dashSvc.dashMainItems.push(
        new dashboardButton(
          "import-users",
          this.tranPipe.transform('import_sale_event_users_button', 'Importa Utenti', []),
          () => { this.importSaleEventUsers() }),

        new dashboardButton(
          "send-emails",
          this.tranPipe.transform('send_sale_event_users_button', 'Invia Email', []),
          () => { this.sendEmails() })
      )

      await this.refreshSale(params['saleEvent']);
    });
  }

  private async refreshSale(seId: string) {
    await this.dashSvc.refreshSaleEvent(seId, "private-sale-imported-users", 'Utenti importati');
  }

  async sendEmails() {
    this.modal.create<SendImportedUsersEmailModalComponent, string>({
      nzContent: SendImportedUsersEmailModalComponent,
      nzTitle: this.tranPipe.transform('send_email_im,ported_users_modal_title', 'Invio email agli utenti importati', []),
      nzWidth: '600px',
      nzClassName: 'purple-modal send-imported-users-email-modal',
      nzFooter: null,
      nzData: this.saleEvent!.saleEventId,
      nzMaskClosable: false,
    }).afterClose.subscribe(async (refresh: boolean | undefined) => {
      if (refresh ?? false) {
        this.refreshTableRowsNumber++;
      }
    });
  }

  importSaleEventUsers() {
    if (this.saleEvent?.saleEventId ?? undefined != undefined) {
      this.modal.create<ImportPrivateSaleUsersModalComponent, string>({
        nzContent: ImportPrivateSaleUsersModalComponent,
        nzData: this.saleEvent!.saleEventId,
        nzTitle: this.tranPipe.transform('import_users_modal_title', 'Importazione massiva utenti', []),
        nzWidth: '800px',
        nzFooter: null,
        nzClassName: 'purple-modal',
        nzMaskClosable: false,
      }).afterClose.subscribe(async (refresh: boolean | undefined) => {
        if (refresh ?? false) {
          this.refreshTableRowsNumber++;
        }
      });
    } else {
      this.mesSvc.error(this.tranPipe.transform('try_import_users_no_sale_error', 'Prima di importare gli utenti è neseccsario salvare la Private Sale', []),
        { nzDuration: environment.MESSAGE_DURATION })
    }

  }


}
