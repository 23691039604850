<ng-container *ngIf="dashSvc.dashboardHeight != undefined">

  <purple-table #purpleTable [tableBody]="tableBody" [isBordered]="true" [switchOn]="false"
    [refreshFunction]="refreshFunction" (tableData)="listOfData = $event" [tableColumns]="tabColumns"
    [tableScroll]="{y:dashSvc.dashboardHeight}" [refreshTableRowsNumber]="refreshTableRowsNumber" [version]="2"
    [showCustomPagination]="true">


    <ng-template #tableBody>
      <ng-container *ngFor="let dataItem of listOfData; let i = index">
        <tr class="{{i%2==0?'main-purple-table-tr-even': 'main-purple-table-tr-odd'}} ">
          <td>{{dataItem.item.slotStartDate| date: "HH:mm"}} - {{dataItem.item.slotEndDate | date: "HH:mm"}}</td>
          <td>
            {{dataItem.item.firstName}}
          </td>
          <td>
            {{dataItem.item.lastName}}
          </td>
          <td>
            {{dataItem.item.email}}
          </td>
          <td>
            <span *ngIf="dataItem.item.isCheckInSlotValid">{{dataItem.item.checkInDate | date: "HH:mm"}}</span>
            <span *ngIf="!dataItem.item.isCheckInSlotValid && dataItem.item.isSameDayCheckIn">
              {{ 'slot_table_slot_check_in_same_date_another_slot' | translation: 'Check-In fuori dallo slot': []}}
            </span>
            <span *ngIf="!dataItem.item.isCheckInSlotValid && !dataItem.item.isSameDayCheckIn">
              {{ 'slot_table_slot_no_check_in_date' | translation: 'Nessun Check-In': []}}
            </span>
          </td>
          <td>
            <container-element [ngSwitch]="dataItem.item.bookedSlotRoleName == undefined">
              <ng-container *ngSwitchCase="true">
                -
              </ng-container>
              <ng-container *ngSwitchCase="false">
                {{dataItem.item.bookedSlotRoleName}}
              </ng-container>
            </container-element>
          </td>

          <td nzAlign="center">
            <button (click)="showMoreInfo(dataItem, !dataItem.expanded)" [nzTooltipTitle]='
              dataItem.expanded?
              ("hide_more_button_booked_users_table" | translation: "Nascondi dati spesa"):
              ("show_more_button_booked_users_table" | translation: "Mostra dati spesa")
              ' nzTooltipPlacement="top" class="ps-mr-3" nz-tooltip nz-button nzType="default" nzShape="circle"><i
                nz-icon class="fsp-18-0" [nzType]="dataItem.expanded?'eye-invisible':'eye'"></i>
            </button>
          </td>
        </tr>
        <tr [nzExpand]="dataItem.expanded">
          <nz-spin [nzSpinning]="isLoading" [nzIndicator]="purpleModalLoaderTemplate">
            <div
              class="ps-d-flex ps-flex-wrap ps-align-items-start ps-w-100 ps-justify-content-between table-expanded-row-container">
              <nz-descriptions class="ps-w-100"
                [nzTitle]="'slot_table_slot_expanded_row_title' | translation: 'Informazioni aggiuntive': []" nzBordered
                [nzColumn]="{ xxl: 2, xl: 2, lg: 2, md: 2, sm: 1, xs: 1 }">
                <nz-descriptions-item [nzTitle]="'slot_table_slot_total_amount' | translation: 'Spesa Totale': []">
                  {{userDetailAmount?.totalAmount??0| currency: data.saleEventCurrency}}
                </nz-descriptions-item>
                <nz-descriptions-item
                  [nzTitle]="'slot_table_slot_total_item_count' | translation: 'Totale Articoli': []">
                  {{userDetailAmount?.totalItemCount??0}}
                </nz-descriptions-item>
              </nz-descriptions>
            </div>
          </nz-spin>
        </tr>
      </ng-container>
    </ng-template>
  </purple-table>
</ng-container>

<ng-template #purpleModalLoaderTemplate>
  <div class="purple-modal-custom-spinner">
    <span nz-icon nzType="loading"></span>
  </div>
</ng-template>