<div class="ps-w-100 ps-d-flex ps-flex-wrap">
  <div *ngFor="let image of images; let i = index"
    class="ps-col-6 ps-d-flex ps-flex-wrap {{i%2==0?'ps-pl-0': 'ps-pr-0'}} image-external-container">
    <div class="ps-w-100 image-container" (click)="clickImage($event)">
      <img purpleEditableImage [contentId]="image.contentId" [editAlwaysOn]="true" [contentTypeId]="image.contentTypeId"
        [cropperType]="image.cropperType!" class="{{image.class}}" [aspectRatio]="image.aspectRatio??0" [imageOpts]="image.imageOpts"/>

      <div class="image-overlay ps-d-flex ps-align-items-center ps-justify-content-center">
        {{ 'private_sale_image_tap_to_edit' | translation: 'Clicca per modificare': []}}
      </div>
    </div>
    <div class="ps-w-100 ps-d-flex ps-flex-wrap ps-justify-content image-info-container">
      <p class="image-title ps-w-100">
        {{image.imageTitle}} 
        <sup *ngIf="image.showPropInfo">
          <i nz-icon nzType="info-circle" nzTheme="outline" class="fsp-14-0 ps-ml-1" nzTooltipPlacement="right"
            [nzTooltipTitle]="image.propInfo" nz-tooltip>
          </i> 
        </sup>
      </p>
      <p class="image-subtitle ps-w-100">{{ image.imageDescription }}</p>
    </div>
  </div>
</div>