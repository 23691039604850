import { Component, Inject, Input, OnInit } from '@angular/core';
import { PurpleApiProxyService, PurpleSelectFilter, PurpleApiMakeRequestResponse } from 'purple-lib';
import { AppTranslationService } from 'src/core-modules/localization/localization.service';

import { BackOfficeService, Role } from 'src/core-modules/sdk/api';
import { NzModalRef, NZ_MODAL_DATA } from 'ng-zorro-antd/modal';

@Component({
  selector: 'app-add-tab-role-modal',
  templateUrl: './add-tab-role-modal.component.html',
  styleUrls: ['./add-tab-role-modal.component.scss']
})
export class AddTabRoleModalComponent implements OnInit {

  constructor(@Inject(NZ_MODAL_DATA) private roleToExcludeIds: string[], private modal: NzModalRef, private admSvc: BackOfficeService, private apiProxySvc: PurpleApiProxyService, public tsvc: AppTranslationService) { }


  ngOnInit() {
  }

  async onSelectRole(roleId: string | undefined){
    if(roleId != undefined){
      await this.apiProxySvc.makeRequestErrorMessage<Role>(()=>this.admSvc.getRoleDetail(this.tsvc.currentLanguage.value, {
      roleId: roleId
      }),false, "internal-loader",500,undefined,undefined,(res: PurpleApiMakeRequestResponse<Role>)=>{
        this.modal.close(res.data!);
      })
    }
  }


  //ScrollFunction
  rolesScrollFunction = (args: { pageNumber: number, pageSize: number, culture: string, searchName: string, searchFilters: PurpleSelectFilter[] }) => {
    args.searchFilters = [
      { propertyName: "roleType", filterValues: ["notStaff"] }
    ]

    if(this.roleToExcludeIds.length > 0){
      args.searchFilters.push(
        { propertyName: "rolesToExclude", filterValues: this.roleToExcludeIds }
      )
    }

    return this.admSvc.getRolesSelect(args.culture, args)
  }
  //SearchFunction
  rolesSearchFunction = (args: { pageNumber: number, pageSize: number, culture: string, searchName: string, searchFilters: PurpleSelectFilter[] }) => {
    args.searchFilters = [
      { propertyName: "roleType", filterValues: ["notStaff"] }
    ]
    
    if(this.roleToExcludeIds.length > 0){
      args.searchFilters.push(
        { propertyName: "rolesToExclude", filterValues: this.roleToExcludeIds }
      )
    }

    return this.admSvc.getRolesByNameSelect(args.culture, args)
  }
  //InitFunction (SearchByIdFunction)
  rolesSearchByIdFunction = (args: { pageNumber: number, pageSize: number, culture: string, searchName: string[], searchFilters: PurpleSelectFilter[] }) => {
    return this.admSvc.getRolesByIdsSelect(args.culture, args)
  }
}
