<div class="ps-col-12 ps-px-0 ps-d-flex ps-flex-wrap ps-align-items-center form-item-container">
  <p class="reorder-title">{{ 'reorder_sale_event_policies_select_page_title' | translation: 'Seleziona la pagina che vuoi riordinare': []}}</p>
  <nz-select class="ps-w-100 ps-mb-3" (ngModelChange)="changePage($event)" [(ngModel)]="selectedPage">
    <nz-option nzLabel="Footer" nzValue="footer"></nz-option>
    <nz-option nzLabel="Registrazione" nzValue="register"></nz-option>
    <nz-option nzLabel="Prenotazione" nzValue="booking"></nz-option>
  </nz-select>
</div>

<ng-container *ngIf="selectedPage != undefined">
  <div *ngIf="pagePolicies.length > 0; else loader" class="ps-w-100 ps-d-flex ps-flex-wrap" cdkDropList
    (cdkDropListDropped)="reorderFields($event)">
    <div *ngFor="let aa of pagePolicies" class="reorder-item ps-d-flex ps-align-items-center" cdkDrag>
      <i class="ps-mr-3 reorder-item-drag-icon" nz-icon nzType="menu" nzTheme="outline" cdkDragHandle></i>
      <h3 class="ps-w-100 reorder-item-title">{{aa.policyName}}</h3>
    </div>
  </div>


  
  <div class="ps-w-100 ps-d-flex ps-justify-content-around ps-align-items-center ps-mt-4">
    <button (click)="saveReorder()" nz-button class="modal-ok-button" [disabled]="isLoading" [nzLoading]="isLoading">
      {{ 'edit_reoder_save' | translation: 'Salva': []}}
    </button>
  </div>
</ng-container>



<ng-template #loader>
  <div class="ps-w-100 ps-d-flex ps-align-items-center ps-justify-content-center" style="min-height: 300px;">
    <nz-spin nzSimple></nz-spin>
  </div>

</ng-template>