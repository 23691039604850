import { Component, Inject, LOCALE_ID, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { purpleFormFieldsGroup, PurpleApiProxyService, PurpleTranslationPipe, Helper, PurpleApiMakeRequestResponse, PurpleSelectFilter } from 'purple-lib';
import { Subject, takeUntil } from 'rxjs';
import { AppTranslationService } from 'src/core-modules/localization/localization.service';
import { BackOfficeService, DefaultCoupon, PRPVCouponFull } from 'src/core-modules/sdk/api';
import { environment } from 'src/environments/default/environment';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalRef, NZ_MODAL_DATA } from 'ng-zorro-antd/modal';

import { FormatWidth, getLocaleDateFormat } from '@angular/common';

@Component({
  selector: 'app-edit-coupon-modal',
  templateUrl: './edit-coupon-modal.component.html',
  styleUrls: ['./edit-coupon-modal.component.scss']
})
export class EditCouponModalComponent implements OnInit {

  sub: Subject<void> = new Subject();
  originalEditItem!: EditSaleCouponItem;
  newObj!: EditSaleCouponItem;
  isNew: boolean = false;

  validateForm: FormGroup = this.fb.group({});
  purpleFormFieldGroups: purpleFormFieldsGroup[] | undefined;
  disableForm: boolean = false;
  isLoading: boolean = false;
  dateFormat = getLocaleDateFormat(this.locale, FormatWidth.Medium);

  constructor(@Inject(NZ_MODAL_DATA) private data: { couponFull: PRPVCouponFull | undefined, saleEventId: string }, @Inject(LOCALE_ID) private locale: string, private modalRef: NzModalRef, private mesSvc: NzMessageService, private apiProxySvc: PurpleApiProxyService,
    private admSvc: BackOfficeService, private tsvc: AppTranslationService, private fb: FormBuilder, private tranPipe: PurpleTranslationPipe) { }


  async ngOnInit(): Promise<void> {
    this.originalEditItem = {
      couponTypeId: this.data.couponFull?.couponTypeId ?? undefined,
      couponId: this.data.couponFull?.couponId ?? undefined,
      defaultCouponId: undefined,

      roleId: this.data.couponFull?.roleId ?? undefined,
      userId: this.data.couponFull?.userId ?? undefined,
      isCouponDiscountMoneyEnabled: this.data.couponFull?.isCouponDiscountMoneyEnabled ?? false,
      isCouponDiscountPercentageEnabled: this.data.couponFull?.isCouponDiscountPercentageEnabled ?? true,
      couponDiscountMoney: this.data.couponFull?.couponDiscountMoney ?? undefined,
      couponDiscountPercentage: this.data.couponFull?.couponDiscountPercentage ?? undefined,
      couponMaxTotalRedeems: this.data.couponFull?.couponMaxTotalRedeems ?? undefined,
      couponMaxUserRedeems: this.data.couponFull?.couponMaxUserRedeems ?? undefined,
      couponIsCumulative: this.data.couponFull?.couponIsCumulative ?? false,
      couponExpireDate: this.data.couponFull?.couponExpireDate ?? undefined
    }

    this.isNew = this.data.couponFull == undefined;
    this.newObj = Helper.storeNewObj(this.originalEditItem);

    this.purpleFormFieldGroups = [
      {
        fieldGroupNumber: 1,
        fielGroupBootstrapColumn: 12,
        fieldGroupPaddingLeft: false,
        fieldGroupPaddingRight: false,
        formFieldFormGroup: this.validateForm,
        formFields: [
          {
            fieldType: 'select',
            fieldControlName: 'couponType',
            fieldBootstrapColumn: 12,
            fieldName: this.tranPipe.transform('edit_coupon_coupon_type', 'Tipologia coupon', []),
            fieldPlaceholder: this.tranPipe.transform('edit_coupon_coupon_type_placeholder', 'Tipologia coupon', []),
            fieldValue: this.newObj.couponTypeId,
            fieldIsRequired: true,
            fieldIsDisabled: !this.isNew,
            fieldPaddingLeft: false,
            fieldPaddingRight: false,
            fieldOptions: [
              { id: 'scrollFunction', value: this.couponTypesScrollFunction },
              { id: 'searchFunction', value: this.couponTypesSearchFunction },
              { id: 'searchByIdFunction', value: this.couponTypesSearchByIdFunction }
            ]
          },
          {
            fieldType: 'select',
            fieldControlName: 'defaultCoupon',
            fieldBootstrapColumn: 12,
            fieldName: this.tranPipe.transform('edit_coupon_coupon_template', 'Importa impostazioni da template coupon', []),
            fieldPlaceholder: this.tranPipe.transform('edit_coupon_coupon_template_placeholder', 'Seleziona il template coupon', []),
            fieldValue: this.newObj.defaultCouponId,
            fieldIsRequired: false,
            fieldIsDisabled: this.newObj.couponTypeId == undefined || !this.isNew,
            fieldPaddingLeft: false,
            fieldPaddingRight: false,
            fieldOptions: [
              { id: 'scrollFunction', value: this.defaultCouponsScrollFunction },
              { id: 'searchFunction', value: this.defaultCouponsSearchFunction },
              { id: 'searchByIdFunction', value: this.defaultCouponsSearchByIdFunction }
            ]
          },
          {
            fieldType: 'input-text',
            fieldControlName: 'id',
            fieldBootstrapColumn: 12,
            fieldName: this.tranPipe.transform('edit_sale_coupon_code', 'Codice', []),
            fieldPlaceholder: this.tranPipe.transform('edit_sale_coupon_code_placeholder', 'Codice coupon', []),
            fieldValue: this.newObj.couponId,
            fieldIsRequired: true,
            fieldIsDisabled: this.newObj.couponTypeId == undefined || !this.isNew,
            fieldPaddingLeft: false,
            fieldPaddingRight: false
          },
          {
            fieldType: 'select',
            fieldControlName: 'role',
            fieldBootstrapColumn: 6,
            fieldName: this.tranPipe.transform('edit_coupon_role', 'Ruolo', []),
            fieldPlaceholder: this.tranPipe.transform('edit_coupon_role_placeholder', 'Ruolo', []),
            fieldValue: this.newObj.roleId,
            fieldIsRequired: this.newObj.couponTypeId != undefined && this.newObj.couponTypeId.strEq("role"),
            fieldIsDisabled: this.newObj.couponTypeId == undefined || !this.newObj.couponTypeId.strEq("role") || !this.isNew,
            fieldPaddingLeft: false,
            fieldPaddingRight: true,
            fieldOptions: [
              { id: 'scrollFunction', value: this.rolesScrollFunction },
              { id: 'searchFunction', value: this.rolesSearchFunction },
              { id: 'searchByIdFunction', value: this.rolesSearchByIdFunction }
            ]
          },
          {
            fieldType: 'select',
            fieldControlName: 'user',
            fieldBootstrapColumn: 6,
            fieldName: this.tranPipe.transform('edit_coupon_user', 'Utente', []),
            fieldPlaceholder: this.tranPipe.transform('edit_coupon_user_placeholder', 'Utente', []),
            fieldValue: this.newObj.userId,
            fieldIsRequired: this.newObj.couponTypeId != undefined && this.newObj.couponTypeId.strEq("user"),
            fieldIsDisabled: this.newObj.couponTypeId == undefined || !this.newObj.couponTypeId.strEq("user") || !this.isNew,
            fieldPaddingLeft: true,
            fieldPaddingRight: false,
            fieldOptions: [
              { id: 'scrollFunction', value: this.usersScrollFunction },
              { id: 'searchFunction', value: this.usersSearchFunction },
              { id: 'searchByIdFunction', value: this.usersSearchByIdFunction }
            ]
          },
          {
            fieldType: 'toggle',
            fieldControlName: 'isDiscountMoneyEnabled',
            fieldBootstrapColumn: 12,
            fieldName: this.tranPipe.transform('edit_sale_coupon_type', 'Tipologia Sconto', []),
            fieldPlaceholder: this.tranPipe.transform('edit_sale_coupon_type_placeholder', '', []),
            fieldValue: this.newObj.isCouponDiscountMoneyEnabled,
            fieldIsRequired: false,
            fieldIsDisabled: this.newObj.couponTypeId == undefined,
            fieldPaddingLeft: false,
            fieldPaddingRight: false,
            fieldOptions: [
              { id: 'checkText', value: this.tranPipe.transform('edit_sale_coupon_type_toggle_money', 'Soldi', []) },
              { id: 'unCheckText', value: this.tranPipe.transform('edit_sale_coupon_type_toggle_percentage', 'Percentuale', []) }
            ]
          },
          {
            fieldType: 'input-number',
            fieldControlName: 'couponDiscountMoney',
            fieldBootstrapColumn: 6,
            fieldName: this.tranPipe.transform('edit_sale_coupon_discount_money', 'Sconto in denaro', []),
            fieldPlaceholder: this.tranPipe.transform('edit_sale_coupon_discount_money_placeholder', 'Sconto in denaro', []),
            fieldValue: this.newObj.couponDiscountMoney,
            fieldIsRequired: this.newObj.isCouponDiscountMoneyEnabled ? true : false,
            fieldIsDisabled: this.newObj.couponTypeId == undefined || (this.newObj.isCouponDiscountMoneyEnabled ? false : true),
            fieldPaddingLeft: false,
            fieldPaddingRight: true,
            fieldOptions: [{ id: 'minNumber', value: 0.1 }]
          },
          {
            fieldType: 'input-number',
            fieldControlName: 'couponDiscountPercentage',
            fieldBootstrapColumn: 6,
            fieldName: this.tranPipe.transform('edit_sale_coupon_discount_percentage', 'Sconto in percentuale', []),
            fieldPlaceholder: this.tranPipe.transform('edit_sale_coupon_discount_percentage_placeholder', 'Sconto in percentuale', []),
            fieldValue: this.newObj.couponDiscountPercentage,
            fieldIsRequired: this.newObj.isCouponDiscountPercentageEnabled ? true : false,
            fieldIsDisabled: this.newObj.couponTypeId == undefined || (this.newObj.isCouponDiscountPercentageEnabled ? false : true),
            fieldPaddingLeft: true,
            fieldPaddingRight: false,
            fieldOptions: [{ id: 'minNumber', value: 0.1 }]
          },
          {
            fieldType: 'input-number',
            fieldControlName: 'couponMaxTotalRedeems',
            fieldBootstrapColumn: 6,
            fieldName: this.tranPipe.transform('edit_sale_coupon_total_redeems', 'Numero massimo utilizzi', []),
            fieldPlaceholder: this.tranPipe.transform('edit_sale_coupon_discount_total_redeems_placeholder', 'Numero massimo utilizzi per la sale', []),
            fieldValue: this.newObj.couponMaxTotalRedeems,
            fieldIsRequired: true,
            fieldIsDisabled: this.newObj.couponTypeId == undefined,
            fieldPaddingLeft: false,
            fieldPaddingRight: true,
            fieldOptions: [{ id: 'minNumber', value: 1 }]
          },
          {
            fieldType: 'input-number',
            fieldControlName: 'couponMaxUserRedeems',
            fieldBootstrapColumn: 6,
            fieldName: this.tranPipe.transform('edit_sale_coupon_user_redeems', 'Numero massimo utilizzi per utente', []),
            fieldPlaceholder: this.tranPipe.transform('edit_sale_coupon_discount_user_redeems_placeholder', 'Numero massimo utilizzi per utente', []),
            fieldValue: this.newObj.couponMaxUserRedeems,
            fieldIsRequired: true,
            fieldIsDisabled: this.newObj.couponTypeId == undefined,
            fieldPaddingLeft: true,
            fieldPaddingRight: false,
            fieldOptions: [{ id: 'minNumber', value: 1 }]
          },
          {
            fieldType: 'toggle',
            fieldControlName: 'isCumulative',
            fieldBootstrapColumn: 6,
            fieldName: this.tranPipe.transform('edit_sale_coupon_is_cumulative', 'Comulabile', []),
            fieldPlaceholder: "",
            fieldValue: this.newObj.couponIsCumulative,
            fieldIsRequired: false,
            fieldOptions: [
              { id: 'checkText', value: this.tranPipe.transform('switch_yes', 'Si', []) },
              { id: 'unCheckText', value: this.tranPipe.transform('switch_no', 'No', []) },
            ],
            fieldPaddingLeft: false,
            fieldPaddingRight: true,
            fieldIsDisabled: this.newObj.couponTypeId == undefined
          },
          {
            fieldType: 'date',
            fieldControlName: 'couponExpireDate',
            fieldBootstrapColumn: 6,
            fieldName: this.tranPipe.transform('edit_sale_coupon_expire_date', 'Data scadenza', []),
            fieldPlaceholder: this.tranPipe.transform('edit_sale_coupon_expire_date_placeholder', 'Data fine validità coupon', []),
            fieldValue: this.newObj.couponExpireDate,
            fieldIsRequired: false,
            fieldIsDisabled: this.newObj.couponTypeId == undefined,
            fieldPaddingLeft: true,
            fieldPaddingRight: false,
            fieldOptions: [
              { id: 'dateFormat', value: getLocaleDateFormat(this.locale, FormatWidth.Medium) },
              { id: 'showTime', value: false }
            ]
          }
        ]
      }
    ]


    setTimeout(() => {
      this.validateForm.controls["couponType"].valueChanges.pipe(takeUntil(this.sub)).subscribe((s: string) => {
        switch (s.toLowerCase()) {
          case "sale":
            this.validateForm.controls['id'].enable();
            this.validateForm.controls['id'].addValidators(Validators.required);

            this.validateForm.controls['role'].disable();
            this.validateForm.controls['role'].removeValidators(Validators.required);

            this.validateForm.controls['user'].disable();
            this.validateForm.controls['user'].removeValidators(Validators.required);

            this.validateForm.controls['couponMaxTotalRedeems'].enable();
            break;

          case "role":
            this.validateForm.controls['id'].enable();
            this.validateForm.controls['id'].addValidators(Validators.required);

            this.validateForm.controls['role'].enable();
            this.validateForm.controls['role'].addValidators(Validators.required);

            this.validateForm.controls['user'].disable();
            this.validateForm.controls['user'].removeValidators(Validators.required);

            this.validateForm.controls['couponMaxTotalRedeems'].enable();
            break;

          case "user":
            this.validateForm.controls['id'].enable();
            this.validateForm.controls['id'].addValidators(Validators.required);

            this.validateForm.controls['role'].disable();
            this.validateForm.controls['role'].removeValidators(Validators.required);

            this.validateForm.controls['user'].enable();
            this.validateForm.controls['user'].addValidators(Validators.required);

            this.validateForm.controls['couponMaxTotalRedeems'].disable();
            this.validateForm.controls['couponMaxTotalRedeems'].removeValidators(Validators.required);
            break;

          default:
            break;
        }

        this.validateForm.controls['defaultCoupon'].enable();
        this.validateForm.controls['isDiscountMoneyEnabled'].enable();
        this.validateForm.controls['couponDiscountPercentage'].enable();
        this.validateForm.controls['couponMaxUserRedeems'].enable();
        this.validateForm.controls['isCumulative'].enable();
        this.validateForm.controls['couponExpireDate'].enable();
      });

      this.validateForm.controls["defaultCoupon"].valueChanges.pipe(takeUntil(this.sub)).subscribe(async (s: string | undefined) => {
        if (s != undefined) {

          await this.apiProxySvc.makeRequestErrorMessage<DefaultCoupon>(() => this.admSvc.getDefaultCouponBase(this.tsvc.currentLanguage.value, {
            defaultCouponId: s
          }), false, "internal-loader", 500, undefined, undefined, (res: PurpleApiMakeRequestResponse<DefaultCoupon>) => {
            const couponDef = res.data;

            this.validateForm.controls['couponDiscountMoney'].setValue(couponDef?.couponDiscountMoney);
            this.validateForm.controls['couponDiscountPercentage'].setValue(couponDef?.couponDiscountPercentage);
            this.validateForm.controls['couponMaxTotalRedeems'].setValue(couponDef?.couponMaxTotalRedeems);
            this.validateForm.controls['couponMaxUserRedeems'].setValue(couponDef?.couponMaxUserRedeems);
            this.validateForm.controls['isCumulative'].setValue(couponDef?.couponIsCumulative);
            this.validateForm.controls['isDiscountMoneyEnabled'].setValue(couponDef?.isCouponDiscountMoneyEnabled ?? false);
          })
        }
      });


      this.validateForm.controls["isDiscountMoneyEnabled"].valueChanges.pipe(takeUntil(this.sub)).subscribe((s: boolean) => {
        if (s) {
          this.validateForm.controls['couponDiscountMoney'].enable();
          this.validateForm.controls['couponDiscountMoney'].addValidators(Validators.required);

          this.validateForm.controls['couponDiscountPercentage'].disable();
          this.validateForm.controls['couponDiscountPercentage'].removeValidators(Validators.required);
        } else {
          this.validateForm.controls['couponDiscountPercentage'].enable();
          this.validateForm.controls['couponDiscountPercentage'].addValidators(Validators.required);

          this.validateForm.controls['couponDiscountMoney'].disable();
          this.validateForm.controls['couponDiscountMoney'].removeValidators(Validators.required);
        }
      });
    }, 100);
  }

  ngOnDestroy(): void {

    this.sub.next();
    this.sub.complete();
  }

  async editCoupon() {
    this.isLoading = true;
    for (const i in this.validateForm.controls) {
      this.validateForm.controls[i].markAsDirty();
      this.validateForm.controls[i].updateValueAndValidity();
    }

    if (this.validateForm.valid) {
      this.newObj.couponId = this.validateForm.controls["id"].value;
      this.newObj.isCouponDiscountMoneyEnabled = this.validateForm.controls["isDiscountMoneyEnabled"].value;
      this.newObj.isCouponDiscountPercentageEnabled = !this.validateForm.controls["isDiscountMoneyEnabled"].value;
      this.newObj.couponDiscountMoney = this.validateForm.controls["couponDiscountMoney"].value;
      this.newObj.couponDiscountPercentage = this.validateForm.controls["couponDiscountPercentage"].value;
      this.newObj.couponMaxTotalRedeems = this.validateForm.controls["couponMaxTotalRedeems"].value;
      this.newObj.couponMaxUserRedeems = this.validateForm.controls["couponMaxUserRedeems"].value;
      this.newObj.couponIsCumulative = this.validateForm.controls["isCumulative"].value;
      this.newObj.couponExpireDate = this.validateForm.controls["couponExpireDate"].value;

      this.newObj.couponTypeId = this.validateForm.controls["couponType"].value;
      this.newObj.roleId = this.validateForm.controls["role"].value;
      this.newObj.userId = this.validateForm.controls["user"].value;

      if (Helper.checkIfDoChanges(this.newObj, this.originalEditItem)) {

        await this.apiProxySvc.makeRequestErrorMessage<Boolean>(() => this.admSvc.addOrUpdateCoupon(this.tsvc.translationLanguage.value, {
          isNew: this.isNew,
          couponIsCumulative: this.newObj.couponIsCumulative,
          couponMaxTotalRedeems: this.newObj.couponTypeId?.strEq("user") ? this.newObj.couponMaxUserRedeems! : this.newObj.couponMaxTotalRedeems!,
          couponMaxUserRedeems: this.newObj.couponMaxUserRedeems!,
          isCouponDiscountMoneyEnabled: this.newObj.isCouponDiscountMoneyEnabled,
          isCouponDiscountPercentageEnabled: this.newObj.isCouponDiscountPercentageEnabled,
          couponDiscountMoney: this.newObj.couponDiscountMoney,
          couponDiscountPercentage: this.newObj.couponDiscountPercentage,
          couponId: this.newObj.couponId!,
          couponType: this.newObj.couponTypeId!,
          saleEventId: this.data.saleEventId,
          roleId: this.newObj.roleId,
          userId: this.newObj.userId,
          couponExpireDate: this.newObj.couponExpireDate
        }), false, "internal-loader", 500, undefined, undefined, (res: PurpleApiMakeRequestResponse<Boolean>) => {
          let text: string;
          if (this.isNew) {
            text = this.tranPipe.transform('edit_sale_coupon_add', 'Template coupon aggiunto con successo')
          } else {
            text = this.tranPipe.transform('edit_sale_coupon_update', 'Template coupon aggiornato con successo')
          }
          this.mesSvc.success(text, {
            nzDuration: environment.MESSAGE_DURATION
          });
          this.modalRef.destroy(res.data);
        })

      }
    }
    this.isLoading = false
  }



  //#region DefaultCoupons
  defaultCouponsSearchFunction = (args: { pageNumber: number, pageSize: number, culture: string, searchName: string, searchFilters: PurpleSelectFilter[] }) => {
    return this.admSvc.getDefaultCouponByNameSelect(args.culture, args)
  }

  defaultCouponsScrollFunction = (args: { pageNumber: number, pageSize: number, culture: string, searchName: string, searchFilters: PurpleSelectFilter[] }) => {
    return this.admSvc.getDefaultCouponSelect(args.culture, args)
  }

  defaultCouponsSearchByIdFunction = (args: { pageNumber: number, pageSize: number, culture: string, searchName: string, searchFilters: PurpleSelectFilter[] }) => {
    return this.admSvc.getDefaultCouponByIdSelect(args.culture, args)
  }
  //#endregion


  //#region CouponTypes
  couponTypesSearchFunction = (args: { pageNumber: number, pageSize: number, culture: string, searchName: string, searchFilters: PurpleSelectFilter[] }) => {
    return this.admSvc.getCouponTypesByNameSelect(args.culture, args)
  }

  couponTypesScrollFunction = (args: { pageNumber: number, pageSize: number, culture: string, searchName: string, searchFilters: PurpleSelectFilter[] }) => {
    return this.admSvc.getCouponTypesSelect(args.culture, args)
  }

  couponTypesSearchByIdFunction = (args: { pageNumber: number, pageSize: number, culture: string, searchName: string, searchFilters: PurpleSelectFilter[] }) => {
    return this.admSvc.getCouponTypesByIdSelect(args.culture, args)
  }
  //#endregion

  //ScrollFunction
  rolesScrollFunction = (args: { pageNumber: number, pageSize: number, culture: string, searchName: string, searchFilters: PurpleSelectFilter[] }) => {
    args.searchFilters = [
      { propertyName: "roleType", filterValues: ["notStaff"] }
    ]
    return this.admSvc.getRolesSelect(args.culture, args)
  }
  //SearchFunction
  rolesSearchFunction = (args: { pageNumber: number, pageSize: number, culture: string, searchName: string, searchFilters: PurpleSelectFilter[] }) => {
    args.searchFilters = [
      { propertyName: "roleType", filterValues: ["notStaff"] }
    ]

    return this.admSvc.getRolesByNameSelect(args.culture, args)
  }
  //InitFunction (SearchByIdFunction)
  rolesSearchByIdFunction = (args: { pageNumber: number, pageSize: number, culture: string, searchName: string, searchFilters: PurpleSelectFilter[] }) => {
    return this.admSvc.getRolesByIdSelect(args.culture, args)
  }

  //ScrollFunction
  usersScrollFunction = (args: { pageNumber: number, pageSize: number, culture: string, searchName: string, searchFilters: PurpleSelectFilter[] }) => {
    return this.admSvc.getUsersSelect(args.culture, args)
  }
  //SearchFunction
  usersSearchFunction = (args: { pageNumber: number, pageSize: number, culture: string, searchName: string, searchFilters: PurpleSelectFilter[] }) => {
    return this.admSvc.getUsersByNameSelect(args.culture, args)
  }
  //InitFunction (SearchByIdFunction)
  usersSearchByIdFunction = (args: { pageNumber: number, pageSize: number, culture: string, searchName: string, searchFilters: PurpleSelectFilter[] }) => {
    return this.admSvc.getUsersByIdSelect(args.culture, args)
  }
}

class EditSaleCouponItem {
  couponId: string | undefined;
  couponTypeId: string | undefined;
  defaultCouponId: string | undefined;
  roleId: string | undefined;
  userId: string | undefined;
  couponDiscountPercentage?: number | undefined;
  isCouponDiscountPercentageEnabled!: boolean;
  couponDiscountMoney?: number | undefined;
  isCouponDiscountMoneyEnabled!: boolean;
  couponMaxTotalRedeems: number | undefined;
  couponMaxUserRedeems: number | undefined;
  couponIsCumulative!: boolean;
  couponExpireDate: string | undefined;
}
