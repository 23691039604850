import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Guid, PurpleApiProxyService, PurpleTranslationPipe, UploadImageOptions, dashboardButton } from 'purple-lib';
import { Subject, takeUntil } from 'rxjs';
import { DashboardLayout2Service } from 'src/app/dashboard/dashboard-layout-2/dashboard-layout-2.service';
import { AppTranslationService } from 'src/core-modules/localization/localization.service';
import { BackOfficeService,  PRPVSaleEventFullExtended } from 'src/core-modules/sdk/api';
import { PrivateSaleService } from '../../private-sale-service.service';
import { EditImage } from 'src/app/dashboard/models/EditImage';

@Component({
  selector: 'app-private-sale-images',
  templateUrl: './private-sale-images.component.html',
  styleUrls: ['./private-sale-images.component.scss'] 
})
export class PrivateSaleImagesComponent implements OnInit, OnDestroy {

  constructor(private tranPipe: PurpleTranslationPipe, private tsvc: AppTranslationService, public dashSvc: DashboardLayout2Service, private apiProxySvc: PurpleApiProxyService
    , private route: ActivatedRoute, public admSvc: BackOfficeService, private seSvc: PrivateSaleService, private router: Router) { }

  sub: Subject<void> = new Subject();
  saleEvent: PRPVSaleEventFullExtended | undefined;

  pdfImgeOpt: UploadImageOptions = {
    useDefaultValues: false,
    processImageSettings: {
      convertImageOptions: {
        toExtension: "png"
      }
    }
  }

  images: EditImage[] = [];

  ngOnInit() {
    this.seSvc.currentSaleEvent$.pipe(takeUntil(this.sub)).subscribe((se: PRPVSaleEventFullExtended | undefined) => {
      this.saleEvent = se;
      
      this.images = [
        //BANNER DETTAGLIO
        {
          contentId: this.saleEvent?.saleEventId+"-banner",
          contentTypeId: "sale-event-detail-banner-image",
          imageTitle: this.tranPipe.transform('private_sale_image_detail_banner_title','Dettaglio', []),
          imageDescription: this.tranPipe.transform('private_sale_image_detail_banner_subtitle','Immagine banner nella pagina dettaglio della private sale', []),
          showPropInfo: true,
          propInfo: this.tranPipe.transform('private_sale_image_info_logo','Proporzioni consigliate: 16/9', []),
          aspectRatio: 16/9,
          class: "banner-image"
        }, 
        //PREVIEW LISTA SALE
        {
          contentId: this.saleEvent?.saleEventId+"-preview",
          contentTypeId: "sale-event-preview-image",
          imageTitle: this.tranPipe.transform('private_sale_image_list_preview_title','Preview lista', []),
          imageDescription: this.tranPipe.transform('private_sale_image_list_preview_subtitle','Immagine preview nella pagina riepilogo di tutte le private sale attive online', []),
          showPropInfo: true,
          propInfo: this.tranPipe.transform('private_sale_image_info_list_preview','Dimensioni consigliate: H 205px - L 270px', []),
          aspectRatio: 270/205,
          class: "logo-image"
        },
        //LOGO
        {
          contentId: this.saleEvent?.saleEventId+"-logo",
          contentTypeId: "sale-event-logo",
          imageTitle: this.tranPipe.transform('private_sale_image_logo_title','Logo', []),
          imageDescription: this.tranPipe.transform('private_sale_image_logo_subtitle','Logo della della private sale nel modale di prenotazione', []),
          showPropInfo: true,
          propInfo: this.tranPipe.transform('private_sale_image_info_logo','Dimensioni consigliate: H 100px - L 100px', []),
          aspectRatio: 1/1,
          class: "logo-image"
        },
        //PDF PRENOTAZIONE
        {
          contentId: this.saleEvent?.saleEventId+"-pdf-logo",
          contentTypeId: "pdf-logo",
          imageTitle: this.tranPipe.transform('private_sale_image_pdf_title','Immagine pdf prenotazione', []),
          imageDescription: this.tranPipe.transform('private_sale_image_pdf_subtitle','Immagini presente sul pdf di conferma della prenotazione alla private sale', []),
          showPropInfo: true,
          propInfo: this.tranPipe.transform('private_sale_image_info_pdf','Dimensioni consigliate: H 512px - L 512px', []),
          aspectRatio: 1/1,
          class: "pdf-logo-image",
          imageOpts: this.pdfImgeOpt
        },
        //LOGIN
        {
          contentId: this.saleEvent?.saleEventId+"-authentication",
          contentTypeId: "authentication-image",
          imageTitle: this.tranPipe.transform('private_sale_image_login_desktop_title','Immagine login desktop', []),
          imageDescription: this.tranPipe.transform('private_sale_image_login_desktop_subtitle','Immagine presente nella pagina di login e registrazione desktop relative alla private sale corrente', []),
          showPropInfo: true,
          propInfo: this.tranPipe.transform('private_sale_image_info_login_desktop','Proporzioni consigliate: 9/16', []),
          aspectRatio: 9/16,
          class: "login-image"
        },
        //LOGIN MOBILE
        {
          contentId: this.saleEvent?.saleEventId+"-authentication",
          contentTypeId: "authentication-image-mobile",
          imageTitle: this.tranPipe.transform('private_sale_image_login_mobile_title','Immagine login mobile', []),
          imageDescription: this.tranPipe.transform('private_sale_image_login_mobile_subtitle','Immagine presente nella pagina di login e registrazione mobile relative alla private sale corrente', []),
          showPropInfo: true,
          propInfo: this.tranPipe.transform('private_sale_image_info_login_mobile','Proporzioni consigliate: H 230px - L 300-360px', []),
          aspectRatio: 2/1,
          class: "login-mobile-image"
        },
        //EMAIL
        {
          contentId: this.saleEvent?.saleEventId+"-email",
          contentTypeId: "sale-event-email-image",
          imageTitle: this.tranPipe.transform('private_sale_image_email_title','Immagine email', []),
          imageDescription: this.tranPipe.transform('private_sale_image_email_subtitle','Immagine che può essere inserita all\'interno delle email che vengono inviate dal sistema relative alla private sale corrente', []),
          showPropInfo: true,
          propInfo: this.tranPipe.transform('private_sale_image_info_login_mobile','Dimensioni libere', []),
          aspectRatio: undefined,
          cropperType: 'free',
          class: "email-image"
        }
      ]
    });

    this.route.queryParams.subscribe(async params => {
      await this.refreshSale(params['saleEvent']);

      this.dashSvc.dashBackButtons = [];

      this.dashSvc.dashBackButtons.push(
        new dashboardButton(
          "back-button",
          this.tranPipe.transform("dashboard_private_general_iofo_back_button", "Torna alla lista", []),
          () => { this.router.navigate([this.tsvc.currentLanguage.value, 'dashboard', 'private-sales', 'private-sale-list']) },
          undefined,
          "arrow-left",
          undefined,
          "left"
        )
      )
    });
  }

  ngOnDestroy(): void {
    this.sub.next();
    this.sub.complete();
    this.dashSvc.clearPageHeader();
  }

  private async refreshSale(seId: string | undefined) {
    await this.dashSvc.refreshSaleEvent(seId??Guid.empty(), "private-sale-images", 'Immagini della sale'); 
  }

  clickImage(event: any){
    event.srcElement.parentElement!.children[0].click()
  }
}
