import { Component, Inject, LOCALE_ID, OnDestroy, OnInit } from '@angular/core';
import { CalendarEvent, CalendarWeekViewBeforeRenderEvent } from 'angular-calendar';
import { addDays, isBefore, isSameDay, subDays, } from 'date-fns';
import { Subject, takeUntil } from 'rxjs';
import { ApiTreeItem, BackOfficeService,  PRPVSaleEventFullExtended, SaleEventDayItem, SaleEventDaySlotItem } from 'src/core-modules/sdk/api';
import { PrivateSaleService } from '../../private-sale-service.service';
import { ActivatedRoute, Router } from '@angular/router';
import { PurpleApiMakeRequestResponse, PurpleApiProxyService, PurpleTranslationPipe, dashboardButton } from 'purple-lib';
import { DashboardLayout2Service } from 'src/app/dashboard/dashboard-layout-2/dashboard-layout-2.service';
import { AppTranslationService } from 'src/core-modules/localization/localization.service';
import { NzContextMenuService, NzDropdownMenuComponent } from 'ng-zorro-antd/dropdown';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NzMessageService } from 'ng-zorro-antd/message';
import { DatePipe } from '@angular/common';
import { environment } from 'src/environments/default/environment';
import { CloneDaySlotsModalComponent } from '../modals/clone-day-slots-modal/clone-day-slots-modal.component';
import { EditDaySlotModalComponent } from '../modals/edit-day-slot-modal/edit-day-slot-modal.component';

@Component({
  selector: 'app-private-sale-days-slots',
  templateUrl: './private-sale-days-slots.component.html',
  styleUrls: ['./private-sale-days-slots.component.scss']
})

export class PrivateSaleDaysSlotsComponent implements OnInit, OnDestroy {

  constructor(@Inject(LOCALE_ID) public locale: string, private seSvc: PrivateSaleService, private route: ActivatedRoute, private dashSvc: DashboardLayout2Service, private nzContextMenuService: NzContextMenuService,
    private tranPipe: PurpleTranslationPipe, private tsvc: AppTranslationService, private router: Router, private apiProxySvc: PurpleApiProxyService, private admSvc: BackOfficeService, private modal: NzModalService,
    private datePipe: DatePipe, private mesSvc: NzMessageService) {
    this.dateOrViewChanged();
  }

  bookingHost: string = localStorage.getItem("BookingHost")!;
  
  weekStartsOn: number = 1;
  dayStartHour: number = 6;
  dayEndHour: number = 24;
  hourSegments: number = 2;
  hourSegmentHeight: number = 50;
  daysInWeek = 4;

  viewDate: Date = new Date();
  minDate: Date = new Date();
  maxDate: Date = new Date();
  periodStartDate: Date = new Date();
  periodEndDate: Date = new Date();

  events: CalendarEvent[] = [];
  refresh = new Subject<void>();

  sub: Subject<void> = new Subject();
  saleEvent: PRPVSaleEventFullExtended | undefined;

  clickedDaySlot: Date | undefined;
  clickedEvent: CalendarEvent | undefined;
  allCalendarSlots: SaleEventDayItem[] = [];

  headerMenuShowCreate: boolean = true;
  headerMenuShowClone: boolean = true;

  ngOnInit() {
    //this.dashSvc.setCurrentMenu("sale");

    this.seSvc.currentSaleEvent$.pipe(takeUntil(this.sub)).subscribe((se: PRPVSaleEventFullExtended | undefined) => {
      this.saleEvent = se;

      if (se != undefined) {
        this.refreshCalendar();
      }
    });

    this.route.queryParams.subscribe(async params => {
      this.dashSvc.dashBackButtons?.push(
        new dashboardButton(
          "back-button",
          this.tranPipe.transform("dashboard_private_general_iofo_back_button", "Torna alla lista", []),
          () => { this.router.navigate([this.tsvc.currentLanguage.value, 'dashboard', 'private-sales', 'private-sale-list']) },
          undefined,
          "arrow-left",
          undefined,
          "left"
        )
      );


      await this.refreshSale(params['saleEvent']);
    });
  }

  ngOnDestroy(): void {
    this.dashSvc.clearPageHeader();
    this.sub.next();
    this.sub.complete();
  }

  private async refreshSale(seId: string) {
    await this.dashSvc.refreshSaleEvent(seId, "private-sale-day-slots", 'Calendario fasce orarie della sale');
  }

  isPastSlot(event: CalendarEvent){
    if(isBefore(event.start, Date.now())){
      return true
    }

    return false
  }

  async refreshCalendar() {
    await this.apiProxySvc.makeRequestErrorPage<SaleEventDayItem[]>(() => this.admSvc.getSaleEventDateDaySlots(this.tsvc.currentLanguage.value, {
      saleEventId: this.saleEvent!.saleEventId
    }), true, "internal-loader", 500, "internal-error", undefined, async (res: PurpleApiMakeRequestResponse<SaleEventDayItem[]>) => {
      this.allCalendarSlots = res.data ?? [];
      this.events = [];

      this.minDate = new Date(Date.parse(this.saleEvent!.publicOpenDate.changeDateTimezone())).skipTime();
      this.maxDate = new Date(new Date(Date.parse(this.saleEvent!.publicCloseDate.changeDateTimezone())).setHours(23, 59));
      this.viewDate = this.minDate;

      if (this.dateIsValid(new Date())) {
        this.viewDate = new Date()
      } else {
        if (res.data!.length > 0 && res.data![0].slots.length > 0) {
          this.viewDate = new Date(Date.parse(res.data![0].slots[0].slotStartDate))
        }
      }

      (res.data ?? []).forEach(f => {
        f.slots.forEach(f1 => {

          this.addEvent(f1);

        })
      })
    });
  }


  increment(): void {
    this.changeDate(addDays(this.viewDate, this.daysInWeek));
  }

  decrement(): void {
    this.changeDate(subDays(this.viewDate, this.daysInWeek));
  }

  dateIsValid(date: Date): boolean {
    return date >= this.minDate && date <= this.maxDate;
  }

  changeDate(date: Date): void {
    this.viewDate = date;

  }

  dateOrViewChanged(): void {

    const prevBtnEnable = this.dateIsValid(
      subDays(this.periodStartDate, 1)
    );

    const nextBtnEnable = this.dateIsValid(
      new Date(addDays(this.periodEndDate, 1).setHours(0, 0, 0))
    );

    if (this.dashSvc.dashMainItems.length == 0) {
      this.dashSvc.dashMainItems.push(
        new dashboardButton(
          "calendar-prev",
          this.tranPipe.transform('calendar_prev_button', 'Indietro', []),
          () => { this.decrement() }
        ),
        new dashboardButton(
          "calendar-next",
          this.tranPipe.transform('calendar_next_button', 'Avanti', []),
          () => { this.increment() }
        )
      )
    }
    (this.dashSvc.dashMainItems.find(f => f.id.strEq("calendar-prev")) as dashboardButton).isEnable = prevBtnEnable;
    (this.dashSvc.dashMainItems.find(f => f.id.strEq("calendar-next")) as dashboardButton).isEnable = nextBtnEnable;

    if (this.viewDate < this.minDate) {
      this.changeDate(this.minDate);
    } else if (this.viewDate > this.maxDate) {
      this.changeDate(this.maxDate);
    }


    //console.log("dateOrViewChanged viewDate: ", this.viewDate);
    //console.log("PrevBtn date: ", subDays(this.periodStartDate,1));
    //console.log("NextBtn date: ", new Date(addDays(this.periodEndDate, 1).setHours(0,0,0)));
    //console.log("dateOrViewChanged: ", " - prevBtn disabled: ", this.prevBtnDisabled, " - nextBtn disabled: ", this.nextBtnDisabled)
  }

  dateCanAdd(date: Date): boolean {
    return date > new Date() && date <= this.maxDate;
  }

  beforeWeekViewRender(renderEvent: CalendarWeekViewBeforeRenderEvent) {
    this.periodStartDate = renderEvent.period.start;
    this.periodEndDate = renderEvent.period.end;

    //console.log("Period: ", this.periodStartDate, " - ", this.periodEndDate)
    renderEvent.hourColumns.forEach((hourColumn) => {
      hourColumn.hours.forEach((hour) => {
        hour.segments.forEach((segment) => {

          if (!this.dateCanAdd(segment.date)) {
            segment.cssClass = 'cal-disabled';
          }
        });
      });
    });

    setTimeout(() => {
      this.dateOrViewChanged();

    }, 100);
  }

  contextMenu(event: MouseEvent, menu: NzDropdownMenuComponent, day: Date): void {
    if (day >= this.minDate && day <= this.maxDate) {
      this.clickedDaySlot = day;
      this.nzContextMenuService.create(event, menu);
    }
  }

  headerContextMenu(event: MouseEvent, menu: NzDropdownMenuComponent, isPast: boolean, day: Date): void {
    if (day <= this.maxDate) {
      const daySlots = this.allCalendarSlots.find(f => isSameDay(Date.parse(f.dateDay), day))?.slots ?? [];
      
      if (daySlots.length > 0) {
        this.headerMenuShowClone = true;
      } else {
        this.headerMenuShowClone = false;
      }

      if (day < this.minDate || isPast) {
        this.headerMenuShowCreate = false;
      }

      this.clickedDaySlot = day;
      this.nzContextMenuService.create(event, menu);
    }
  }

  cloneEvents() {
    const daySlots = this.allCalendarSlots.find(f => isSameDay(Date.parse(f.dateDay), this.clickedDaySlot!));

    this.modal.create<CloneDaySlotsModalComponent, SaleEventDayItem | undefined>({
      nzContent: CloneDaySlotsModalComponent,
      nzData: daySlots,
      nzTitle: undefined,
      nzWidth: '1100px',
      nzClassName: 'purple-modal clone-day-slots-modal',
      nzFooter: null,
      nzMaskClosable: false,
    }).afterClose.subscribe(async (res: boolean | undefined) => { 
      if((res??false) == true){
        await this.refreshCalendar();
        this.refresh.next();
      }
      
    });
  }

  editEvent(event: CalendarEvent | undefined = undefined) {
    const daySlots = this.clickedDaySlot == undefined ? [] : this.allCalendarSlots.find(f => isSameDay(Date.parse(f.dateDay), this.clickedDaySlot!))?.slots ?? []
    console.log("Day slots: ", daySlots)
    console.log("Lo slot: ", this.clickedDaySlot)

    this.modal.create<EditDaySlotModalComponent, { daySlots:SaleEventDaySlotItem[], clickedSlot: Date | undefined, daySlot: SaleEventDaySlotItem | undefined }>({
      nzContent: EditDaySlotModalComponent,
      nzData: {
        daySlots: daySlots,
        clickedSlot: this.clickedDaySlot,
        daySlot: event?.meta.slot
      },
      nzTitle: this.tranPipe.transform("add_new_day_slot_modal_title", "Aggiungi slot", []),
      nzWidth: '1100px',
      nzOkText: "Conferma",
      nzClassName: 'purple-modal edit-day-slot-modal',
      nzFooter: null,
      nzMaskClosable: false,
    }).afterClose.subscribe(async (action: { slot: SaleEventDaySlotItem, type: "add" | "delete" } | undefined) => {
      if(action != undefined){
        await this.refreshCalendar();
        this.refresh.next();
      }
      /* if (action != undefined && action.type == "add") {
        console.log("Setto evento");

        const idx = this.events.findIndex(f => (f.id as string).strEq(action.slot.slotStartDate + "_" + action.slot.slotEndDate));
        console.log("Setto evento idx: ", idx);

        if (idx != -1) {
          this.events.splice(idx, 1);
        }

        this.addEvent(action.slot, "edited");

        console.log("Eventi prima di refresh: ", this.events)
        this.refresh.next();
        console.log("Eventi dopo refresh: ", this.events)
      }

      if (action != undefined && action.type == "delete") {
        console.log("Eventi prima di delete: ", this.events)
        const idx = this.events.findIndex(f => (f.id as string).strEq(action.slot.slotStartDate + "_" + action.slot.slotEndDate));
        console.log("Setto evento idx: ", idx);

        if (idx != -1) {
          this.events.splice(idx, 1);
        }

        this.refresh.next();
      } */
    });
  }

  /* checkIfSlotIsFull(slot: SaleEventDaySlotItem){
    if(slot.isMixedRoleEnabled){
      return slot.currentSlotRoleAttendees == slot.maxMixedRoleAttendees;
    }else{
      const tot = slot.
    }
  } */

  private addEvent(slot: SaleEventDaySlotItem, editText: string = "") {
    this.events.push({
      id: slot.slotStartDate + "_" + slot.slotEndDate,
      start: new Date(Date.parse(slot.slotStartDate.changeDateTimezone())),
      end: new Date(Date.parse(slot.slotEndDate.changeDateTimezone())),
      title: slot.isMixedRoleEnabled ? this.tranPipe.transform('event_calendar_title_all_roles', 'Tutti i Ruoli', []) : slot.roles.map(m => m.roleName).join(" - "),
      meta: {
        slot: slot,
        edit: editText
      }
    })
  }

  async deleteDaySlot(event: CalendarEvent) {

    await this.apiProxySvc.makeRequestErrorMessage<ApiTreeItem[]>(() => this.admSvc.getSaleEventSlotBookings(this.tsvc.currentLanguage.value, {
      saleEventId: this.seSvc.currentSaleEvent$.value!.saleEventId,
      slotStartDate: event.start.toIsoStringPurple()
    }), false, "internal-loader", 500, undefined, undefined, (res: PurpleApiMakeRequestResponse<ApiTreeItem[]>) => {
      this.modal.create({
        nzTitle: this.tranPipe.transform('delete_day_slot_modal_title', 'Eliminazione fascia oraria: {0} - {1}',
          [this.datePipe.transform(event.start, "HH:mm")!, this.datePipe.transform(event.end, "HH:mm")!]),
        nzContent: res.data?.length ?? 0 > 0 ?
          this.tranPipe.transform('delete_day_slot_modal_info', 'Attenzione lo slot <b>{0} - {1}</b> è già stato prenotato da <b>{2}</b> utenti! ai quali verrà <b>notificata tramite mail</b> la cancellazione della fascia oraria.',
            [this.datePipe.transform(event.start, "HH:mm")!, this.datePipe.transform(event.end, "HH:mm")!,
            res.data?.reduce((sum, current) => sum + current.children.length, 0).toString() ?? ""])
          : this.tranPipe.transform('delete_day_slot_modal_info_empty', 'Vuoi davvero eliminare lo slot <b>{0} - {1}</b>',
            [this.datePipe.transform(event.start, "HH:mm")!, this.datePipe.transform(event.end, "HH:mm")!]),
        nzWidth: '600px',
        nzOkText: res.data?.length ?? 0 > 0 ? this.tranPipe.transform("delete_day_slot_modal_delete_of_confirm", "Elimina Comunque") :
          this.tranPipe.transform("delete_day_slot_modal_delete_confirm", "Elimina"),
        nzClassName: 'purple-simple-modal',
        nzMaskClosable: false,
        nzOnOk: async () => {

          await this.apiProxySvc.makeRequestErrorMessage<boolean>(() => this.admSvc.deleteSaleEventSlot(this.tsvc.currentLanguage.value, {
            saleEventId: this.seSvc.currentSaleEvent$.value!.saleEventId,
            slotStartDate: event.start.toIsoStringPurple(),
            bookingClientHost: this.bookingHost
          }), false, "internal-loader", 500, undefined, undefined, (res: PurpleApiMakeRequestResponse<boolean>) => {
            if (res) {
              this.mesSvc.success(this.tranPipe.transform("delete_day_slot_modal_success", "Fascia Oraria eliminata correttamente"), { nzDuration: environment.MESSAGE_DURATION });

              const idx = this.events.findIndex(f => (f.id as string).strEq(event.meta.slot.slotStartDate + "_" + event.meta.slot.slotEndDate));
              console.log("Setto evento idx: ", idx);

              if (idx != -1) {
                this.events.splice(idx, 1);
              }

              this.refresh.next();
            }
          })
        }
      });
    })

  }


  clickEvent(event: CalendarEvent) {
    this.clickedEvent = event;
  }
}
