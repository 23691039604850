import { Component, OnInit } from '@angular/core';
import { PurpleTranslationPipe, PurpleTableBoxColumn, PurpleTableSearchValue, PurpleTableSelectFilter, dashboardButton, PurpleApiProxyService, PurpleSelectFilter, PurpleApiResponseStatus } from 'purple-lib';
import { Subject, lastValueFrom, takeUntil } from 'rxjs';
import { DashboardLayout2Service } from 'src/app/dashboard/dashboard-layout-2/dashboard-layout-2.service';
import { AuthenticationService } from 'src/core-modules/authentication/authentication.service';
import { AppTranslationService } from 'src/core-modules/localization/localization.service';
import { BackOfficeService, PRPVSaleEventFullExtended, PurpleTableSearchGroupGroup, ReferralTableItem, ReferralTableItemPurpleTableResponse } from 'src/core-modules/sdk/api';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NzMessageService } from 'ng-zorro-antd/message';
import { PrivateSaleService } from '../../../private-sale-service.service';
import { Router, ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/default/environment';
import { EditUserReferralModalComponent } from '../../modals/edit-user-referral-modal/edit-user-referral-modal.component';

@Component({
  selector: 'app-private-sale-user-referrals',
  templateUrl: './private-sale-user-referrals.component.html',
  styleUrls: ['./private-sale-user-referrals.component.scss']
})
export class PrivateSaleUserReferralsComponent implements OnInit {

  constructor(private tranPipe: PurpleTranslationPipe, public dashSvc: DashboardLayout2Service, private tsvc: AppTranslationService, private router: Router,
    public admSvc: BackOfficeService, private seSvc: PrivateSaleService, private apiProxySvc: PurpleApiProxyService, private route: ActivatedRoute,
    private modal: NzModalService, private mesSvc: NzMessageService, private authSvc: AuthenticationService) { }

  bookingHost: string = localStorage.getItem("BookingHost")!;
  basePath: string = localStorage.getItem("BasePath")!;
  showTable: boolean = false;
  saleEvent: PRPVSaleEventFullExtended | undefined;
  sub: Subject<void> = new Subject();
  listOfData: Array<ReferralTableItemPurpleTableResponse> | undefined | null;
  refreshTableRowsNumber: number = 0;


  //ScrollFunction
  rolesScrollFunction = (args: { pageNumber: number, pageSize: number, culture: string, searchName: string, searchFilters: PurpleSelectFilter[] }) => {
    args.searchFilters = [
      { propertyName: "roleType", filterValues: ["notStaff"] }
    ]
    return this.admSvc.getRolesSelect(args.culture, args)
  }
  //SearchFunction
  rolesSearchFunction = (args: { pageNumber: number, pageSize: number, culture: string, searchName: string, searchFilters: PurpleSelectFilter[] }) => {
    args.searchFilters = [
      { propertyName: "roleType", filterValues: ["notStaff"] }
    ]
    return this.admSvc.getRolesByNameSelect(args.culture, args)
  }
  //InitFunction (SearchByIdFunction)
  rolesSearchByIdFunction = (args: { pageNumber: number, pageSize: number, culture: string, searchName: string, searchFilters: PurpleSelectFilter[] }) => {
    return this.admSvc.getRolesByIdSelect(args.culture, args)
  }

  tabColumns: PurpleTableBoxColumn[] = [
    {
      name: this.tranPipe.transform("private_sale_user_referrals_table_header_name", "Nome"),
      dbKey: "firstname",
      hasReorder: true,
      hasFilter: true,
      filterField: {
        fieldType: 'input-text',
        fieldControlName: 'firstname',
        fieldBootstrapColumn: 6,
        fieldName: this.tranPipe.transform('private_sale_user_referrals_table_box_filter_firstname', 'Nome', []),
        fieldPlaceholder: this.tranPipe.transform('private_sale_user_referrals_table_box_filter_firstname_placeholder', 'Nome del cliente', []),
        fieldValue: undefined,
        fieldIsRequired: false,
        fieldIsDisabled: false,
        fieldPaddingLeft: false,
        fieldPaddingRight: true
      },
      operatorType: "like"
    },
    {
      name: this.tranPipe.transform("private_sale_user_referrals_table_header_lastname", "Cognome"),
      dbKey: "lastname",
      hasReorder: true,
      hasFilter: true,
      filterField: {
        fieldType: 'input-text',
        fieldControlName: 'lastname',
        fieldBootstrapColumn: 6,
        fieldName: this.tranPipe.transform('private_sale_user_referrals__table_box_filter_lastname', 'Cognome', []),
        fieldPlaceholder: this.tranPipe.transform('private_sale_user_referrals__table_box_filter_lastname_placeholder', 'Cognome del cliente', []),
        fieldValue: undefined,
        fieldIsRequired: false,
        fieldIsDisabled: false,
        fieldPaddingLeft: true,
        fieldPaddingRight: false
      },
      operatorType: "like"
    },
    {
      name: this.tranPipe.transform("private_sale_user_referrals_table_header_email", "Email"),
      dbKey: "email",
      hasReorder: true,
      hasFilter: true,
      filterField: {
        fieldType: 'input-text',
        fieldControlName: 'email',
        fieldBootstrapColumn: 6,
        fieldName: this.tranPipe.transform('private_sale_user_referrals_table_box_filter_email', 'Email', []),
        fieldPlaceholder: this.tranPipe.transform('private_sale_user_referrals_table_box_filter_email_placeholder', 'Email del cliente', []),
        fieldValue: undefined,
        fieldIsRequired: false,
        fieldIsDisabled: false,
        fieldPaddingLeft: false,
        fieldPaddingRight: true
      },
      operatorType: 'like'
    },
    {
      name: this.tranPipe.transform("private_sale_user_referrals_table_header_role", "Ruolo"),
      dbKey: "roleId",
      hasReorder: true,
      hasFilter: true,
      operatorType: '=',
      filterField: {
        fieldType: 'select',
        fieldControlName: 'roleId',
        fieldBootstrapColumn: 6,
        fieldName: this.tranPipe.transform('private_sale_user_referrals_table_box_filter_role', 'Ruolo invitato', []),
        fieldPlaceholder: this.tranPipe.transform('private_sale_user_referrals_table_box_filter_role_placeholder', 'Ruolo dell\'invitato', []),
        fieldValue: undefined,
        fieldIsRequired: false,
        fieldIsDisabled: false,
        fieldPaddingLeft: true,
        fieldPaddingRight: false,
        fieldOptions: [
          { id: 'scrollFunction', value: this.rolesScrollFunction },
          { id: 'searchFunction', value: this.rolesSearchFunction },
          { id: 'searchByIdFunction', value: this.rolesSearchByIdFunction },
          { id: 'refreshFieldNumber', value: 0 }
        ]
      }
    },
    {
      name: this.tranPipe.transform("private_sale_user_referrals_table_header_max_user_referrals", "Max inviti"),
      dbKey: "MaxInvitationsNr",
      hasReorder: true,
      hasFilter: true,
      filterField: {
        fieldType: 'input-number',
        fieldControlName: 'MaxInvitationsNr',
        fieldBootstrapColumn: 6,
        fieldName: this.tranPipe.transform('private_sale_user_referrals_table_box_filter_max_user_referrals', 'Numero massimo inviti', []),
        fieldPlaceholder: this.tranPipe.transform('private_sale_user_referrals_table_box_filter_max_user_referrals_placeholder', 'Numero massimo inviti per utente', []),
        fieldValue: undefined,
        fieldIsRequired: false,
        fieldIsDisabled: false,
        fieldPaddingLeft: false,
        fieldPaddingRight: true,
        fieldOptions: [{ id: 'minNumber', value: -1 }]
      },
      operatorType: "="
    },
    {
      name: this.tranPipe.transform("private_sale_user_referrals_table_header_role_2", "Ruolo 2"),
      dbKey: "referralReferralRoleId",
      hasReorder: true,
      hasFilter: true,
      operatorType: '=',
      filterField: {
        fieldType: 'select',
        fieldControlName: 'referralReferralRoleId',
        fieldBootstrapColumn: 6,
        fieldName: this.tranPipe.transform('private_sale_user_referrals_table_box_filter_role_2', 'Ruolo invitato dell\'invitato', []),
        fieldPlaceholder: this.tranPipe.transform('private_sale_user_referrals_table_box_filter_role_2_placeholder', 'Ruolo invitato dell\'invitato', []),
        fieldValue: undefined,
        fieldIsRequired: false,
        fieldIsDisabled: false,
        fieldPaddingLeft: true,
        fieldPaddingRight: false,
        fieldOptions: [
          { id: 'scrollFunction', value: this.rolesScrollFunction },
          { id: 'searchFunction', value: this.rolesSearchFunction },
          { id: 'searchByIdFunction', value: this.rolesSearchByIdFunction },
          { id: 'refreshFieldNumber', value: 0 }
        ]
      }
    },
    {
      name: this.tranPipe.transform("table_header_actions", "Azioni"),
      hasReorder: false,
      hasFilter: false,
      fixedWidth: "200px"
    }
  ]

  refreshFunction = (args: {
    pageNumber: number, pageSize: number, orderColumnName?: string | null, orderType?: string | null,
    tableSearchValues?: Array<PurpleTableSearchGroupGroup> | null, tableFilters?: Array<PurpleTableSelectFilter> | null, switchOn?: boolean,
    culture: string
  }) => {
    args.culture = this.tsvc.translationLanguage.value;

    args.tableFilters = [
      {
        property: "saleEventId",
        filterValue: this.saleEvent!.saleEventId
      },
      {
        property: "type",
        filterValue: "user"
      }
    ];

    return this.admSvc.getReferralsTable(args.culture, args);
  }

  ngOnDestroy(): void {
    this.sub.next();
    this.sub.complete();
    this.dashSvc.clearPageHeader();
  }

  ngOnInit() {
    this.seSvc.currentSaleEvent$.pipe(takeUntil(this.sub)).subscribe((se: PRPVSaleEventFullExtended | undefined) => {
      this.saleEvent = se;

      this.showTable = true;
    });

    this.route.queryParams.subscribe(async params => {
      this.dashSvc.dashBackButtons?.push(
        new dashboardButton(
          "back-button",
          this.tranPipe.transform("dashboard_private_general_iofo_back_button", "Torna alla lista", []),
          () => { this.router.navigate([this.tsvc.currentLanguage.value, 'dashboard', 'private-sales', 'private-sale-list']) },
          undefined,
          "arrow-left",
          undefined,
          "left"
        )
      );

      this.dashSvc.dashMainItems?.push(
        {
          id: "bt",
          type: "button",
          buttonText: this.tranPipe.transform("table_add_new_item_button_user_referral", "Aggiungi codice", []),
          buttonFunction: () => {
            this.editItem(undefined) 
          }
        })


      await this.refreshSale(params['saleEvent']);
    });
  }

  private async refreshSale(seId: string) {
    await this.dashSvc.refreshSaleEvent(seId, "private-sale-users", 'Riepilogo utenti');
  }


  editItem(referral: ReferralTableItem | undefined) {
    this.modal.create<EditUserReferralModalComponent, { userReferrralFull: ReferralTableItem | undefined, saleEventId: string, usedRoles: string[], usedUsers: string[] }>({
      nzContent: EditUserReferralModalComponent,
      nzData: {
        userReferrralFull: referral,
        usedRoles: this.listOfData?.map(m => m.item.roleId)??[],
        usedUsers: this.listOfData?.map(m => m.item.userId)??[],
        saleEventId: this.saleEvent!.saleEventId
      },
      nzTitle: referral == undefined ?
        this.tranPipe.transform('edit_referral_modal_title_new', 'Nuovo Referral Code', []) :
        this.tranPipe.transform('edit_referral_modal_title_update', 'Modifica Referral Code', []),
      nzWidth: '1000px',
      nzOkText: "Conferma",
      nzClassName: 'purple-modal',
      nzFooter: null,
      nzMaskClosable: false,
    }).afterClose.subscribe(async () => {
      this.refreshTableRowsNumber++;
    });
  }

  copyReferral(event: Event, referral: ReferralTableItem){
    try {
      event.stopPropagation();
      navigator.clipboard.writeText("https://"+this.bookingHost+"/"+this.tsvc.currentLanguage.value+"/referral?id=" + referral.referralId);

      this.mesSvc.success(this.tranPipe.transform("copy_referral_ok", "Codice copiato negli appunti"), {
        nzDuration: environment.MESSAGE_DURATION
      });
    } catch (error) {
      this.mesSvc.error(this.tranPipe.transform("copy_referral_ko", "Errore! impossibile copiare il codice"), {
        nzDuration: environment.MESSAGE_DURATION
      });
    }
  }

  async deleteReferral(referral: ReferralTableItem) {
    const res = await lastValueFrom(this.admSvc.countReferralRedeems(this.tsvc.currentLanguage.value, {
      referralId: referral.referralId
    }));

    const referralReedemsCount = res.data ?? 0

    this.modal.create({
      nzTitle: this.tranPipe.transform("delete_user_role_referral_modal_title", "Eliminare codice invito \"<b>{0}-{1}-{2}</b>\"", [referral?.saleEventName ?? "", referral?.roleName ?? "", referral.userFirstName]),
      nzContent: referralReedemsCount > 0 ?
        this.tranPipe.transform("delete_user_role_referral_modal_subtitle_1", "Attenzione! il <b>codice invito</b> che si vuole eliminare è già stato utilizzato da <b>{0}</b> utenti, le loro eventuali prenotazioni verranno mantenute",
          [referralReedemsCount.toString()]) :
        this.tranPipe.transform("delete_user_role_referral_modal_subtitle_2", "Il codice invito \"<b>{0}-{1}-{2}</b>\" verrà eliminato definitivamente",
          [referral?.saleEventName ?? "", referral?.roleName ?? "", referral.userFirstName]),
      nzWidth: '600px',
      nzClassName: 'purple-simple-modal',
      nzOkText: this.tranPipe.transform('delete_user_role_referral_modal_button', 'Elimina', []),
      nzOnOk: async () => {
        const res = await lastValueFrom(this.admSvc.deleteRoleReferral(this.tsvc.currentLanguage.value, {
          referralId: referral.referralId
        }));

        if (res.status == PurpleApiResponseStatus.Success) {
          this.mesSvc.success(this.tranPipe.transform("delete_user_role_referral_modal_ok_response", "Codice invito eliminato correttamente"), {
            nzDuration: environment.MESSAGE_DURATION
          });
          this.modal.closeAll();
        } else {
          this.mesSvc.error(res.message, {
            nzDuration: environment.MESSAGE_DURATION
          });
        }
      },
    }).afterClose.subscribe(async () => {
      this.refreshTableRowsNumber++;
    });
  }
}
