<ng-container *ngIf="dashSvc.dashboardHeight != undefined && showTable">

  <purple-table #purpleTable [tableBody]="tableBody" [isBordered]="true" [switchOn]="false"
  [refreshFunction]="refreshFunction" (tableData)="listOfData = $event" [tableColumns]="tabColumns"
  [tableScroll]="{y:dashSvc.dashboardHeight}" [refreshTableRowsNumber]="refreshTableRowsNumber" [version]="2"
  [showCustomPagination]="true" tableSearchType="box" (filterBox)="dashSvc.setPurpleTablePopover($event)" 
  (onSearchBoxEnd)="dashSvc.closePurpleTablePopover()" [filterBoxStatus]="dashSvc.purpleTableBoxFilterStatus">

    <ng-template #tableBody>
      <ng-container *ngFor="let data of listOfData; let i = index">
        <tr class="{{i%2==0?'main-purple-table-tr-even': 'main-purple-table-tr-odd'}} ">
          <td nzAlign="center"><span class="{{data.item.languageId | languageIcon}}"></span></td>
          <td nzAlign="center">
            <container-element [ngSwitch]="data.item.isTranslated">
              <ng-container *ngSwitchCase="true">
                <span nz-icon nzType="check-circle" [nzTheme]="'twotone'" [nzTwotoneColor]="'#52c41a'"></span>
              </ng-container>
              <ng-container *ngSwitchCase="false">
                <span nz-icon nzType="close-circle" [nzTheme]="'twotone'" [nzTwotoneColor]="'#eb2f96'"></span>
              </ng-container>
            </container-element>
          </td>

          <td>{{ data.item.policyName}}</td>
          <td>{{ data.item.policyTypeName }}</td>
          <td>{{ data.item.policyPage }}</td>
          <td>{{ data.item.policyTitle }}</td>
          <td>

            <container-element [ngSwitch]="data.item.policyTypeId">
              <ng-container *ngSwitchCase="'iubenda'">
                <a *ngIf="data.item.policyUrl!=undefined && !data.toTranslate" target="_blank"
                  [href]="data.item.policyUrl" class="ps-m-0 ps-p-0">Link Iubenda</a>
                <p *ngIf="data.item.policyUrl==undefined && !data.toTranslate">Errore! Url mancante</p>
              </ng-container>
              <ng-container *ngSwitchCase="'link'">
                <a *ngIf="!data.toTranslate" target="_blank" [href]="data.item.policyUrl" class="ps-m-0 ps-p-0">Link</a>
              </ng-container>
              <ng-container *ngSwitchCase="'media'">
                <a *ngIf="data.item.policyContentId!=undefined && data.item.policyContentTypeId != undefined && !data.toTranslate"
                  target="_blank" [href]="getMediaLink(data.item.policyContentId, data.item.policyContentTypeId)"
                  class="ps-m-0 ps-p-0">Allegato</a>
              </ng-container>
              <ng-container *ngSwitchCase="'html'">
                <div class="ps-w-100" [innerHtml]="data.item.policyHtml"></div>
              </ng-container>
              <ng-container *ngSwitchCase="'text'">
                {{data.item.policyText | slice:0:100}}
                <span *ngIf="(data.item.policyText?.length??0)>100">...</span>
              </ng-container>
              <ng-container *ngSwitchDefault>
              </ng-container>
            </container-element>
          </td>
          <td nzAlign="center">
            <container-element [ngSwitch]="data.item.isMandatoryPolicy">
              <ng-container *ngSwitchCase="true">
                <p class="ps-p-0 ps-m-0 fw-b">{{ 'table_yes' | translation: 'Si': []}}</p>
              </ng-container>
              <ng-container *ngSwitchCase="false">
                <p class="ps-p-0 ps-m-0 fw-b">{{ 'table_no' | translation: 'No': []}}</p>
              </ng-container>
            </container-element>
          </td>
          <td nzAlign="center">
            <button (click)="editPolicy(data.item!)"
              [nzTooltipTitle]="'edit_policy_tooltip' | translation: 'Modifica Policy': []" nzTooltipPlacement="top"
              nz-tooltip class="ps-mr-3" nz-button nzType="default" nzShape="circle"><i nz-icon 
                nzType="edit"></i></button>

            <button (click)="deletePolicy(data.item!)" class="ps-mr-3"
              [nzTooltipTitle]='"delete_policy_tooltip" | translation: "Rimuovi Policy"' nzTooltipPlacement="top"
              nz-tooltip nz-button nzType="default" nzShape="circle">
              <i nz-icon nzType="delete"></i>
            </button>
          </td>
        </tr>
      </ng-container>
    </ng-template>
  </purple-table>
</ng-container>