import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthenticationGuard } from 'src/core-modules/authentication/authentication.guard';
import { ShowMenuGuard } from 'src/core-modules/show-menu/showMenu.guard';
import { PrivateSaleDaysSlotsComponent } from './private-sale-days-slots/private-sale-days-slots.component';
import { PrivateSaleGeneralInfoComponent } from './private-sale-general-info/private-sale-general-info.component';
import { PrivateSaleGuard } from '../private-sale.guard';
import { PrivateSaleRolesComponent } from './private-sale-roles/private-sale-roles.component';
import { PrivateSaleImportedUsersComponent } from './users/private-sale-imported-users/private-sale-imported-users.component';
import { PrivateSalePoliciesComponent } from './private-sale-policies/private-sale-policies.component';
import { PrivateSaleCashMachineSettingsComponent } from './private-sale-cash-machine-settings/private-sale-cash-machine-settings.component';
import { PrivateSaleBookingsReportComponent } from './private-sale-bookings-report/private-sale-bookings-report.component';
import { BaseSystemEventMailTemplatesComponent } from '../../notifications/mail/base-system-event-mail-templates/base-system-event-mail-templates.component';
import { BaseSystemEventMailTemplateExceptionsComponent } from '../../notifications/mail/base-system-event-mail-template-exceptions/base-system-event-mail-template-exceptions.component';
import { PrivateSaleImagesComponent } from './private-sale-images/private-sale-images.component';
import { PrivateSaleCouponsComponent } from './private-sale-coupons/private-sale-coupons.component';
import { PrivateSaleUserReferralsComponent } from './users/private-sale-user-referrals/private-sale-user-referrals.component';
import { PrivateSaleAttributesComponent } from './private-sale-attributes/private-sale-attributes.component';
import { PrivateSaleUsersComponent } from './users/private-sale-users/private-sale-users.component';

const routes: Routes = [
  {
    path: '',
    canActivate: [AuthenticationGuard],
    children: [
      {
        path: '',
        redirectTo: 'general-informations',
        pathMatch: "full"
      },
      {
        path: 'general-informations',
        component: PrivateSaleGeneralInfoComponent,
        data: { submenu: "general-informations" },
        canActivate: [PrivateSaleGuard, ShowMenuGuard]
      },
      {
        path: 'private-sale-days-slots',
        component: PrivateSaleDaysSlotsComponent,
        data: { submenu: "private-sale-days-slots" },
        canActivate: [PrivateSaleGuard, ShowMenuGuard]
      },
      {
        path: 'private-sale-roles',
        component: PrivateSaleRolesComponent,
        data: { submenu: "private-sale-roles" },
        canActivate: [PrivateSaleGuard, ShowMenuGuard]
      },
      {
        path: 'private-sale-attributes',
        component: PrivateSaleAttributesComponent,
        data: { submenu: "private-sale-attributes" },
        canActivate: [PrivateSaleGuard, ShowMenuGuard]
      },
      {
        path: 'private-sale-users',
        component: PrivateSaleUsersComponent,
        data: { submenu: "private-sale-users" },
        canActivate: [PrivateSaleGuard, ShowMenuGuard]
      },
      {
        path: 'private-sale-imported-users', 
        component: PrivateSaleImportedUsersComponent,
        data: { submenu: "private-sale-imported-users" },
        canActivate: [PrivateSaleGuard, ShowMenuGuard]
      },
      { 
        path: 'private-sale-user-referrals',
        component: PrivateSaleUserReferralsComponent,
        data: { submenu: "private-sale-user-referrals" },
        canActivate: [PrivateSaleGuard, ShowMenuGuard]
      },
      {
        path: 'private-sale-policies',
        component: PrivateSalePoliciesComponent,
        data: { submenu: "private-sale-policies" },
        canActivate: [PrivateSaleGuard, ShowMenuGuard]
      }, 
      {
        path: 'private-sale-cash-machine-settings',
        component: PrivateSaleCashMachineSettingsComponent,
        data: { submenu: "private-sale-cash-machine-settings" },
        canActivate: [PrivateSaleGuard, ShowMenuGuard]
      }, 
      {
        path: 'private-sale-bookings-report',
        component: PrivateSaleBookingsReportComponent,
        data: { submenu: "private-sale-bookings-report" },
        canActivate: [PrivateSaleGuard, ShowMenuGuard]
      }, 
      {
        path: 'private-sale-mail-templates',
        component: BaseSystemEventMailTemplatesComponent,
        data: { submenu: "private-sale-mail-templates" },
        canActivate: [PrivateSaleGuard, ShowMenuGuard]
      }, 
      {
        path: 'private-sale-mail-template-exceptions',
        component: BaseSystemEventMailTemplateExceptionsComponent,
        data: { submenu: "private-sale-mail-template-exceptions" },
        canActivate: [PrivateSaleGuard, ShowMenuGuard]
      }, 
      {
        path: 'private-sale-images',
        component: PrivateSaleImagesComponent,
        data: { submenu: "private-sale-images" },
        canActivate: [PrivateSaleGuard, ShowMenuGuard]
      }, 
      {
        path: 'private-sale-coupons',
        component: PrivateSaleCouponsComponent,
        data: { submenu: "private-sale-coupons" },
        canActivate: [PrivateSaleGuard, ShowMenuGuard]
      }
    ]
  }

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PrivateSaleDetailRoutingModule { }
