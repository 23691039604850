import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzMessageModule } from 'ng-zorro-antd/message';
import { AdministrationRoutingModule } from './administration-routing.module';
import { NzPopoverModule } from 'ng-zorro-antd/popover';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { CustomersComponent } from './customers/customers.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { EditUserComponent } from './modals/edit-user/edit-user.component';
import { NzBadgeModule } from 'ng-zorro-antd/badge';
import { NzCodeEditorModule } from 'ng-zorro-antd/code-editor';
import { NzCollapseModule } from 'ng-zorro-antd/collapse';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzTableStyleService } from 'ng-zorro-antd/table';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzInputNumberModule } from 'ng-zorro-antd/input-number';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzSwitchModule } from 'ng-zorro-antd/switch';
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { NzTreeViewModule } from 'ng-zorro-antd/tree-view';
import { NzTypographyModule } from 'ng-zorro-antd/typography';
import { NzDescriptionsModule } from 'ng-zorro-antd/descriptions';
import { NzTagModule } from 'ng-zorro-antd/tag';
import { PurpleFormModule, PurpleGeneralDirectiveModule, PurplePipeModule, PurpleSelectModule, PurpleTableModule } from 'purple-lib';
import { EditRoleComponent } from './modals/edit-role/edit-role.component';
import { RolesComponent } from './roles/roles.component';
import { EmployeesComponent } from './employees/employees.component';
import { EditEmployeeComponent } from './modals/edit-employee/edit-employee.component';
import { EditUserAddressesComponent } from './modals/edit-user-addresses/edit-user-addresses.component';
import { EditUserAddressDetailComponent } from './modals/edit-user-address-detail/edit-user-address-detail.component';
import { AnonymizeUserModalComponent } from './modals/anonymize-user-modal/anonymize-user-modal.component';
import { UserDetailComponent } from './user-detail/user-detail.component';
import { BuyUserMembershipComponent } from './modals/buy-user-membership/buy-user-membership.component';

@NgModule({
  declarations: [CustomersComponent, RolesComponent, EmployeesComponent, EditUserComponent, EditRoleComponent, UserDetailComponent,
    EditEmployeeComponent, EditUserAddressesComponent, EditUserAddressDetailComponent, AnonymizeUserModalComponent, BuyUserMembershipComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NzDropDownModule,
    NzButtonModule,
    NzToolTipModule,
    NzMessageModule,
    NzPopoverModule,
    AdministrationRoutingModule,
    NzCardModule,
    NzSpinModule,
    NzModalModule,
    NzCheckboxModule,
    PurplePipeModule,
    NzDescriptionsModule,
    NzSelectModule,
    NzInputModule,
    NzInputNumberModule,
    NzDatePickerModule,
    NzIconModule,
    NzSwitchModule,
    PurpleSelectModule,
    NzFormModule,
    NzCodeEditorModule,
    NzTabsModule,
    NzCollapseModule,
    NzDividerModule,
    NzBadgeModule,
    NzTreeViewModule,
    NzTypographyModule,
    NzTagModule,
    PurpleTableModule,
    NzTableModule,
    PurpleGeneralDirectiveModule,
    PurpleFormModule
  ],
  exports:[],
  providers:[NzTableStyleService]
})
export class AdministrationModule { }
