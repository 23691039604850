import { Component, Inject, LOCALE_ID, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Guid, PurpleApiMakeRequestResponse, PurpleApiProxyService, PurpleSelectFilter, PurpleTableBoxColumn, PurpleTableColumn, PurpleTableSearchValue, PurpleTableSelectFilter, PurpleTranslationPipe, dashboardButton } from 'purple-lib';
import { Subject, takeUntil } from 'rxjs';
import { DashboardLayout2Service } from 'src/app/dashboard/dashboard-layout-2/dashboard-layout-2.service';
import { AppTranslationService } from 'src/core-modules/localization/localization.service';
import { BackOfficeService, PRPVCouponFull, PRPVCouponFullPurpleTableResponse, PRPVSaleEventFullExtended, PurpleTableSearchGroupGroup } from 'src/core-modules/sdk/api';
import { PrivateSaleService } from '../../private-sale-service.service';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NzMessageService } from 'ng-zorro-antd/message';

import { EditCouponModalComponent } from '../modals/edit-coupon-modal/edit-coupon-modal.component';
import { environment } from 'src/environments/default/environment';
import { getLocaleDateFormat, FormatWidth } from '@angular/common';

@Component({
  selector: 'app-private-sale-coupons',
  templateUrl: './private-sale-coupons.component.html',
  styleUrls: ['./private-sale-coupons.component.scss']
})
export class PrivateSaleCouponsComponent implements OnInit {

  constructor(@Inject(LOCALE_ID) private locale: string, private tranPipe: PurpleTranslationPipe, public dashSvc: DashboardLayout2Service, private tsvc: AppTranslationService, private router: Router, private mesSvc: NzMessageService,
    public admSvc: BackOfficeService, private seSvc: PrivateSaleService, private apiProxySvc: PurpleApiProxyService, private route: ActivatedRoute, private modal: NzModalService) { }

  showTable: boolean = false;
  saleEvent: PRPVSaleEventFullExtended | undefined;
  sub: Subject<void> = new Subject();
  listOfData: Array<PRPVCouponFullPurpleTableResponse> | undefined | null;
  refreshTableRowsNumber: number = 0;
  
  //ScrollFunction
  couponTypesScrollFunction = (args: { pageNumber: number, pageSize: number, culture: string, searchName: string, searchFilters: PurpleSelectFilter[] }) => {
    return this.admSvc.getCouponTypesSelect(args.culture, args)
  }
  //SearchFunction
  couponTypesSearchFunction = (args: { pageNumber: number, pageSize: number, culture: string, searchName: string, searchFilters: PurpleSelectFilter[] }) => {
    return this.admSvc.getCouponTypesByNameSelect(args.culture, args)
  }
  //InitFunction (SearchByIdFunction)
  couponTypesSearchByIdFunction = (args: { pageNumber: number, pageSize: number, culture: string, searchName: string, searchFilters: PurpleSelectFilter[] }) => {
    return this.admSvc.getCouponTypesByIdSelect(args.culture, args)
  }

  //ScrollFunction
  usersScrollFunction = (args: { pageNumber: number, pageSize: number, culture: string, searchName: string, searchFilters: PurpleSelectFilter[] }) => {
    return this.admSvc.getUsersSelect(args.culture, args)
  }
  //SearchFunction
  usersSearchFunction = (args: { pageNumber: number, pageSize: number, culture: string, searchName: string, searchFilters: PurpleSelectFilter[] }) => {
    return this.admSvc.getUsersByNameSelect(args.culture, args)
  }
  //InitFunction (SearchByIdFunction)
  usersSearchByIdFunction = (args: { pageNumber: number, pageSize: number, culture: string, searchName: string, searchFilters: PurpleSelectFilter[] }) => {
    return this.admSvc.getUsersByIdSelect(args.culture, args)
  }

    //ScrollFunction
    rolesScrollFunction = (args: { pageNumber: number, pageSize: number, culture: string, searchName: string, searchFilters: PurpleSelectFilter[] }) => {
      args.searchFilters = [
        { propertyName: "roleType", filterValues: ["notStaff"] }
      ]
      return this.admSvc.getRolesSelect(args.culture, args)
    }
    //SearchFunction
    rolesSearchFunction = (args: { pageNumber: number, pageSize: number, culture: string, searchName: string, searchFilters: PurpleSelectFilter[] }) => {
      args.searchFilters = [
        { propertyName: "roleType", filterValues: ["notStaff"] }
      ]
      return this.admSvc.getRolesByNameSelect(args.culture, args)
    }
    //InitFunction (SearchByIdFunction)
    rolesSearchByIdFunction = (args: { pageNumber: number, pageSize: number, culture: string, searchName: string, searchFilters: PurpleSelectFilter[] }) => {
      return this.admSvc.getRolesByIdSelect(args.culture, args)
    }

  tabColumns: PurpleTableBoxColumn[] = [
    {
      name: this.tranPipe.transform("private_sale_coupon_table_header_name", "Codice"),
      dbKey: "couponId",
      hasReorder: true,
      hasFilter: true,
      filterField: {
        fieldType: 'input-text',
        fieldControlName: 'couponId',
        fieldBootstrapColumn: 6,
        fieldName: this.tranPipe.transform('private_sale_coupon_table_box_filter_coupon', 'Codice', []),
        fieldPlaceholder: this.tranPipe.transform('private_sale_coupon_table_box_filter_coupon_placeholder', 'Codice del coupon', []),
        fieldValue: undefined,
        fieldIsRequired: false,
        fieldIsDisabled: false,
        fieldPaddingLeft: false,
        fieldPaddingRight: true
      },
      operatorType: "like"
    },
    {
      name: this.tranPipe.transform("private_sale_coupon_table_header_discount_type", "Tipologia Sconto"),
      dbKey: "couponTypeId",
      hasReorder: true,
      hasFilter: true,
      filterField: {
        fieldType: 'select',
        fieldControlName: 'couponTypeId',
        fieldBootstrapColumn: 6,
        fieldName: this.tranPipe.transform('private_sale_coupon_table_box_filter_discount_type', 'Tipologia sconto', []),
        fieldPlaceholder: this.tranPipe.transform('private_sale_coupon_table_box_filter_discount_type_placeholder', 'Tipologia sconto', []),
        fieldValue: undefined,
        fieldIsRequired: false,
        fieldIsDisabled: false,
        fieldPaddingLeft: true,
        fieldPaddingRight: false,
        fieldOptions: [
          { id: 'scrollFunction', value: this.couponTypesScrollFunction },
          { id: 'searchFunction', value: this.couponTypesSearchFunction },
          { id: 'searchByIdFunction', value: this.couponTypesSearchByIdFunction },
          { id: 'refreshFieldNumber', value: 0 }
        ]
      },
      operatorType: '='
    },
    {
      name: this.tranPipe.transform("private_sale_coupon_table_header_discount_role", "Ruolo"),
      dbKey: "roleId",
      hasReorder: true,
      hasFilter: true,
      operatorType: '=',
      filterField: {
        fieldType: 'select',
        fieldControlName: 'roleId',
        fieldBootstrapColumn: 12,
        fieldName: this.tranPipe.transform('private_sale_coupon_table_box_filter_discount_role', 'Ruolo', []),
        fieldPlaceholder: this.tranPipe.transform('private_sale_coupon_table_box_filter_discount_role_placeholder', 'Ruolo del coupon', []),
        fieldValue: undefined,
        fieldIsRequired: false,
        fieldIsDisabled: false,
        fieldPaddingLeft: false,
        fieldPaddingRight: false,
        fieldOptions: [
          { id: 'scrollFunction', value: this.rolesScrollFunction },
          { id: 'searchFunction', value: this.rolesSearchFunction },
          { id: 'searchByIdFunction', value: this.rolesSearchByIdFunction },
          { id: 'refreshFieldNumber', value: 0 }
        ]
      }
    },
    {
      name: this.tranPipe.transform("private_sale_coupon_table_header_discount_user", "Utente"),
      dbKey: "userId",
      hasReorder: true,
      hasFilter: true,
      operatorType: '=',
      filterField: {
        fieldType: 'select',
        fieldControlName: 'userId',
        fieldBootstrapColumn: 6,
        fieldName: this.tranPipe.transform('private_sale_coupon_table_box_filter_discount_user', 'Utente', []),
        fieldPlaceholder: this.tranPipe.transform('private_sale_coupon_table_box_filter_discount_user_placeholder', 'Utente del coupon', []),
        fieldValue: undefined,
        fieldIsRequired: false,
        fieldIsDisabled: false,
        fieldPaddingLeft: false,
        fieldPaddingRight: true,
        fieldOptions: [
          { id: 'scrollFunction', value: this.usersScrollFunction },
          { id: 'searchFunction', value: this.usersSearchFunction },
          { id: 'searchByIdFunction', value: this.usersSearchByIdFunction },
          { id: 'refreshFieldNumber', value: 0 }
        ]
      }
    },
    {
      name: this.tranPipe.transform("private_sale_coupon_table_header_discount", "Sconto"),
      dbKey: "",
      hasReorder: false,
      hasFilter: false
    },
    {
      name: this.tranPipe.transform("table_header_actions", "Azioni"),
      hasReorder: false,
      hasFilter: false,
      fixedWidth: "180px"
    },
    {
      name: this.tranPipe.transform("private_sale_coupon_table_header_is_cumulative", "Cumulabile"),
      dbKey: "couponIsCumulative",
      hasReorder: true,
      hasFilter: true,
      filterField: {
        fieldType: 'select-local',
        fieldControlName: 'couponIsCumulative',
        fieldBootstrapColumn: 6,
        fieldName: this.tranPipe.transform('private_sale_coupon_table_box_filter_is_cumulative', 'Cumulabile', []),
        fieldPlaceholder: this.tranPipe.transform('private_sale_coupon_table_box_filter_is_cumulative_placeholder', 'Coupon cumulabile', []),
        fieldValue: undefined,
        fieldIsRequired: false,
        fieldIsDisabled: false,
        fieldPaddingLeft: true,
        fieldPaddingRight: false,
        fieldOptions: [
          { id: 'isMultiple', value: false },
          { id: 'showArrow', value: true },
          { id: 'enableSearch', value: false },
          {
            id: 'options', value: [
              {
                label: 'Si',
                value: 1
              },
              {
                label: 'No',
                value: 0
              }
            ]
          }
        ]
      },
      operatorType: "=",
      hiddenColumn: true
    },
    {
      name: this.tranPipe.transform("private_sale_coupon_table_header_coupon_expire_date", "Data scadenza"),
      dbKey: "couponExpireDate",
      hasReorder: true,
      hasFilter: true,
      filterField: {
        fieldType: 'date-range',
        fieldControlName: 'couponExpireDate',
        fieldBootstrapColumn: 12,
        fieldName: this.tranPipe.transform('private_sale_coupon_table_box_filter_coupon_expire_date', 'Data scadenza', []),
        fieldPlaceholder: this.tranPipe.transform('private_sale_coupon_table_box_filter_coupon_expire_date_placeholder', 'Data scadenza coupon', []),
        fieldValue: undefined,
        fieldIsRequired: false,
        fieldIsDisabled: false,
        fieldPaddingLeft: false,
        fieldPaddingRight: false,
        fieldOptions: [
          { id: 'dateFormat', value: getLocaleDateFormat(this.locale, FormatWidth.Medium) },
          { id: 'disabledDate', value: undefined }
        ]
      },
      operatorType: "date between",
      hiddenColumn: true
    }
  ]

  refreshFunction = (args: {
    pageNumber: number, pageSize: number, orderColumnName?: string | null, orderType?: string | null,
    tableSearchValues?: Array<PurpleTableSearchGroupGroup> | null, tableFilters?: Array<PurpleTableSelectFilter> | null, switchOn?: boolean,
    culture: string
  }) => {
    args.tableFilters = [
      {
        property: "saleEventId",
        filterValue: this.saleEvent?.saleEventId ?? Guid.empty()
      }
    ];


    return this.admSvc.getSaleCouponTable(args.culture, args);
  }

  ngOnDestroy(): void {
    this.sub.next();
    this.sub.complete();
    this.dashSvc.clearPageHeader();
  }

  ngOnInit() {
    this.seSvc.currentSaleEvent$.pipe(takeUntil(this.sub)).subscribe((se: PRPVSaleEventFullExtended | undefined) => {
      this.saleEvent = se;
      console.log("SETTO LA SALE CORRENTE: ", this.saleEvent?.saleEventId)
      this.showTable = true;
    });

    this.route.queryParams.subscribe(async params => {
      console.log("Params: ", params['saleEvent'])
      await this.refreshSale(params['saleEvent']);
    });
  }

  private async refreshSale(seId: string) {
    this.dashSvc.dashBackButtons = [];
    this.dashSvc.dashMainItems = [];

      this.dashSvc.dashBackButtons.push(
        new dashboardButton(
          "back-button",
          this.tranPipe.transform("dashboard_private_general_iofo_back_button", "Torna alla lista", []),
          () => { this.router.navigate([this.tsvc.currentLanguage.value, 'dashboard', 'private-sales', 'private-sale-list']) },
          undefined,
          "arrow-left",
          undefined,
          "left"
        )
      )
    

    this.dashSvc.dashMainItems.push(
      new dashboardButton(
      "add-policy",
      this.tranPipe.transform('private_sale_coupons_add_coupon_button', 'Aggiungi Coupon', []),
      () => { this.editCoupon(undefined) }))

      console.log("FACCIO REFRESH DELLA SALE")

    await this.dashSvc.refreshSaleEvent(seId, "private-sale-coupons", this.tranPipe.transform('private_sale_coupons_subtitle','Coupon attive della sale', []));
    this.refreshTableRowsNumber++
  }

  editCoupon(coupon: PRPVCouponFull | undefined) {
    this.modal.create<EditCouponModalComponent, { couponFull: PRPVCouponFull | undefined, saleEventId: string }>({
      nzContent: EditCouponModalComponent,
      nzData: { couponFull: coupon, saleEventId: this.saleEvent!.saleEventId },
      nzTitle: coupon == undefined ? this.tranPipe.transform("coupon_table_item_edit_modal_title_new", "Aggiungi coupon", []) :
        this.tranPipe.transform("coupon_table_item_edit_modal_title_update", "Modifica coupon <b>{0}</b>", [coupon.couponId]),
      nzWidth: '1000px',
      nzOkText: "Conferma",
      nzClassName: 'purple-modal',
      nzFooter: null,
      nzMaskClosable: false,
    }).afterClose.pipe(takeUntil(this.sub)).subscribe(async (refresh: boolean) => {
      if (refresh) {
        this.refreshTableRowsNumber++;
      }
    });
  }

  async pendingUser(coupon: PRPVCouponFull) {
    this.modal.create({
      nzTitle: coupon.couponCanBeUsed ? this.tranPipe.transform('edit_coupon_status_not_active_title', 'Disabilita coupon "<b>{0}</b>"', [coupon.couponId]) :
      this.tranPipe.transform('edit_coupon_status_active_title', 'Attivare coupon <b>{0}</b>', [coupon.couponId]),

      nzContent: coupon.couponCanBeUsed ? this.tranPipe.transform('edit_coupon_status_active_subtitle', '<b>Disabilitare</b> l\'utilizzo del coupon "<b>{0}</b>"', [coupon.couponId]) :
        this.tranPipe.transform('edit_coupon_status_not_active_subtitle', '<b>Abilitare</b> l\'utilizzo del coupon "<b>{0}</b>"', [coupon.couponId]),
      nzWidth: '600px',
      nzOkText: coupon.couponCanBeUsed ? this.tranPipe.transform('edit_coupon_status_not_active_button', 'Disabilita', []) : this.tranPipe.transform('edit_coupon_status_active_button', 'Abilita', []),
      nzClassName: 'purple-simple-modal',
      nzOnOk: async () => {
        await this.apiProxySvc.makeRequestErrorMessage<boolean>(() => this.admSvc.enableDisableCoupon(this.tsvc.currentLanguage.value, {
          couponId: coupon.couponId,
          saleEventId: this.saleEvent?.saleEventId!
        }), false, "internal-loader", 500, undefined, undefined, (res: PurpleApiMakeRequestResponse<boolean>) => {
          this.mesSvc.success(this.tranPipe.transform('edit_coupon_status_ok', 'Modifiche salvate con successo', []), {
            nzDuration: environment.MESSAGE_DURATION
          });
          this.refreshTableRowsNumber++;
        })
      }
    })
  }

  getCouponStatusTooltipTitle(user: PRPVCouponFull) {
    if (user.couponCanBeUsed) {
      return this.tranPipe.transform('edit_coupon_status_button_not_active', 'Disabilita coupon', [])
    } else {
      return this.tranPipe.transform('edit_coupon_status_button_active', 'Abilita coupon', [])
    }
  }
}
