import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PurpleApiMakeRequestResponse, PurpleApiProxyService, PurpleTranslationPipe, dashboardButton } from 'purple-lib';
import { AppTranslationService } from 'src/core-modules/localization/localization.service';
import { BackOfficeService, GetUserProfileBookingsResponse, UserDetail, UserProfileBookingItem, UserProfileBookingTransaction } from 'src/core-modules/sdk/api';
import { DashboardLayout2Service } from '../../dashboard-layout-2/dashboard-layout-2.service';
import { CurrencyPipe, DatePipe } from '@angular/common';

@Component({
  selector: 'app-user-detail',
  templateUrl: './user-detail.component.html',
  styleUrls: ['./user-detail.component.scss']
})
export class UserDetailComponent implements OnInit {

  constructor(private route: ActivatedRoute, private admSvc: BackOfficeService, private tsvc: AppTranslationService, 
    private apiProxySvc: PurpleApiProxyService, private dashSvc: DashboardLayout2Service, private tranPipe: PurpleTranslationPipe,
    private datePipe: DatePipe, private currencyPipe: CurrencyPipe, private router: Router) { }

  userDetail: UserDetail | undefined;
  pageName = "user-detail";
  isLoadingBookings = false;
  userId!: string;

  ngOnInit() {
    this.route.queryParams.subscribe(async params => {
      this.userId = params['userId'];

      await this.refreshUserData(this.userId);

      this.dashSvc.dashBackButtons?.push(
        new dashboardButton(
          "back-button",
          this.tranPipe.transform("dashboard_user_detail_back_button", "Torna alla lista utenti", []),
          () => { this.router.navigate([this.tsvc.currentLanguage.value, 'dashboard', 'administration', 'customers']) },
          undefined,
          "arrow-left",
          undefined,
          "left"
        )
      );
    });
  }

  ngOnDestroy(): void {
    this.dashSvc.clearPageHeader();
  }

  async refreshUserData(userId: string){
    await this.apiProxySvc.makeRequestErrorPage<UserDetail>(()=>this.admSvc.getUserDetail(this.tsvc.currentLanguage.value, {
    userId: userId
    }),true, "internal-loader",500,"internal-error",async ()=>{await this.refreshUserData(userId)},(res: PurpleApiMakeRequestResponse<UserDetail>)=>{
      this.userDetail = res.data
      this.dashSvc.setPageHeader(
        this.tranPipe.transform(this.pageName + '_page_title', 'Scheda utente', []),
        this.userDetail?.firstName + " " + this.userDetail?.lastName
      );
    })
  }

  async loadMoreBookings(){
    this.isLoadingBookings = true;

    await this.apiProxySvc.makeRequestErrorMessage<GetUserProfileBookingsResponse>(()=>this.admSvc.getUserDetailBookings(this.tsvc.currentLanguage.value, {
      getCounters: false,
      pageNumber: this.userDetail!.bookingHistory.pageNumber + 1,
      pageSize: 3,
      userId: this.userId
    }),false, "internal-loader",500,undefined,undefined,(res: PurpleApiMakeRequestResponse<GetUserProfileBookingsResponse>)=>{
      this.userDetail!.bookingHistory!.bookings = [...this.userDetail!.bookingHistory!.bookings, ...res.data!.bookings];
      this.userDetail!.bookingHistory!.pageNumber = res.data!.pageNumber;
      this.userDetail!.bookingHistory!.pageSize = res.data!.pageSize;
      this.userDetail!.bookingHistory!.showMore = res.data!.showMore;
    })
    this.isLoadingBookings = false;
  }

  getBookedSlotStr(book: UserProfileBookingItem){
    return book.slots.map(m=> this.datePipe.transform(m.slotStartDate, "dd MMMM -  HH:mm") + "-"+  this.datePipe.transform(m.slotEndDate, "HH:mm")).join("</br>")
  }

  getTransactionTotals(tran: UserProfileBookingTransaction, book: UserProfileBookingItem): string{
    return book.checkInOk ? 
    this.currencyPipe.transform(tran.transactionTotalAmount, "EUR")! +
    (book.transactions.length>1? " - ("+this.datePipe.transform(tran.transactionDate, "dd/MM/yyyy HH:mm")+")":"")   
    : "-";
  }

  getPriceNormalized(val: number | undefined | null): string{
    return val ? this.currencyPipe.transform(val, "EUR")! : "-";
  }

}
