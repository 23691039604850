<div class="ps-w-100 ps-d-flex user-attributes-container">
  <nz-spin class="ps-w-100" [nzSpinning]="isLoading" [nzIndicator]="purpleTabLoaderTemplate">
    <div *ngFor="let att of saleAttributes" class="user-attributes-item-container ps-d-flex ps-flex-wrap ps-w-100">

      <h2 class="attribute-title ps-w-100">
        {{att.userAttributeName}}
      </h2>
      <container-element [ngSwitch]="att.angularTemplateType" class="ps-w-100">
        <ng-container *ngSwitchCase="'select-multiple'" class="ps-w-100">
          <div class="choises-container ps-w-100 ps-d-flex ps-flex-wrap">
            <div *ngFor="let defVal of att.saleEventAttributeValues" class="ps-col-6 ps-d-flex">
              <div class="choises-item ps-align-items-center ps-justify-content-between
             ps-w-100 ps-d-flex ">
                <p class="choise-text-value">
                  {{defVal.userAttributeValueText}}
                </p>
                <button nz-button class="choise-button" nzType="default"
                  (click)="removePreference(defVal.saleEventAttributeValueId)">
                  <i nz-icon nzType="minus" nzTheme="outline"></i>
                  {{ 'add_user_attribute_prefernce_added' | translation: 'Rimuovi': []}}
                </button>
              </div>

            </div>

            <div *ngIf="att.canAddMore" class="ps-col-6 ps-d-flex">
              <div class="choises-item ps-align-items-center ps-justify-content-between
            ps-w-100 ps-d-flex ">
                <p class="choise-text-value">
                  {{ 'add_new_user_attribute' | translation: 'Aggiungi nuovo interesse': []}}
                </p>
                <button nz-button class="choise-button" nzType="primary" (click)="addPreference(att)">
                  <i nz-icon nzType="plus" nzTheme="outline"></i>
                  {{ 'add_user_attribute_prefernce_add' | translation: 'Aggiungi': []}}
                </button>
              </div>
            </div>

          </div>
        </ng-container>

      </container-element>
    </div>
  </nz-spin>
</div>


<ng-template #purpleTabLoaderTemplate>
  <div class="tab-custom-spinner">
    <span nz-icon nzType="loading"></span>
  </div>
</ng-template>