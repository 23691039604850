import { Component, OnInit } from '@angular/core';
import { Guid, PurpleTranslationPipe, UploadImageOptions } from 'purple-lib';
import { DashboardLayout2Service } from '../../dashboard-layout-2/dashboard-layout-2.service';
import { AuthenticationService } from 'src/core-modules/authentication/authentication.service';
import { EditImage } from '../../models/EditImage';

@Component({
  selector: 'app-general-images',
  templateUrl: './general-images.component.html',
  styleUrls: ['./general-images.component.scss']
})
export class GeneralImagesComponent implements OnInit {

  constructor(private dashSvc: DashboardLayout2Service, private tranPipe: PurpleTranslationPipe, public authSvc: AuthenticationService) { }
  generalSaleEvent = Guid.empty();

  pdfImgeOpt: UploadImageOptions = {
    useDefaultValues: false,
    processImageSettings: {
      convertImageOptions: {
        toExtension: "png"
      }
    }
  }

  images: EditImage[]= [
    //LOGO HEADER BOOKING
    {
      contentId: "logo-header",
      contentTypeId: "logo-company",
      imageTitle: this.tranPipe.transform('private_sale_image_logo_header_title','Logo header booking', []),
      imageDescription: this.tranPipe.transform('private_sale_image_logo_header_subtitle','Immagine presente nell\'header della piattaforma di booking', []),
      showPropInfo: true,
      propInfo: this.tranPipe.transform('private_sale_image_info_logo_header','Proporzioni consigliate: H 65px - L 150px', []),
      aspectRatio: 2/1,
      class: "logo-header-booking"
    },
    //LOGO FOOTER BOOKING
    {
      contentId: "logo-footer",
      contentTypeId: "logo-company",
      imageTitle: this.tranPipe.transform('private_sale_image_logo_footer_title','Logo footer booking', []),
      imageDescription: this.tranPipe.transform('private_sale_image_logo_footer_subtitle','Immagine presente nel footer della piattaforma di booking', []),
      showPropInfo: true,
      propInfo: this.tranPipe.transform('private_sale_image_info_logo_footer','Proporzioni consigliate: H 90px - L 150px', []),
      aspectRatio: 2/1,
      class: "logo-footer-booking"
    },
    //LOGO
    {
      contentId: this.generalSaleEvent+"-logo",
      contentTypeId: "sale-event-logo",
      imageTitle: this.tranPipe.transform('private_sale_image_logo_title','Logo', []),
      imageDescription: this.tranPipe.transform('private_sale_image_logo_subtitle','Logo del marchio della private sale', []),
      showPropInfo: true,
      propInfo: this.tranPipe.transform('private_sale_image_info_logo','Dimensioni consigliate: H 100px - L 100px', []),
      aspectRatio: 1/1,
      class: "logo-image"
    },
    //PDF PRENOTAZIONE
    {
      contentId: this.generalSaleEvent+"-pdf-logo",
      contentTypeId: "pdf-logo",
      imageTitle: this.tranPipe.transform('private_sale_image_pdf_title','Immagine pdf prenotazione', []),
      imageDescription: this.tranPipe.transform('private_sale_image_pdf_subtitle','Immagini presente sul pdf di conferma della prenotazione alla private sale', []),
      showPropInfo: true,
      propInfo: this.tranPipe.transform('private_sale_image_info_pdf','Dimensioni consigliate: H 512px - L 512px', []),
      aspectRatio: 1/1,
      class: "pdf-logo-image",
      imageOpts: this.pdfImgeOpt
    },
    //LOGIN
    {
      contentId: this.generalSaleEvent+"-authentication",
      contentTypeId: "authentication-image",
      imageTitle: this.tranPipe.transform('private_sale_image_login_desktop_title','Immagine login desktop', []),
      imageDescription: this.tranPipe.transform('private_sale_image_login_desktop_subtitle','Immagine presente nella pagina di login e registrazione desktop relative alla private sale corrente', []),
      showPropInfo: true,
      propInfo: this.tranPipe.transform('private_sale_image_info_login_desktop','Proporzioni consigliate: 9/16', []),
      aspectRatio: 9/16,
      class: "login-image"
    },
    //LOGIN MOBILE
    {
      contentId: this.generalSaleEvent+"-authentication",
      contentTypeId: "authentication-image-mobile",
      imageTitle: this.tranPipe.transform('private_sale_image_login_mobile_title','Immagine login mobile', []),
      imageDescription: this.tranPipe.transform('private_sale_image_login_mobile_subtitle','Immagine presente nella pagina di login e registrazione mobile relative alla private sale corrente', []),
      showPropInfo: true,
      propInfo: this.tranPipe.transform('private_sale_image_info_login_mobile','Proporzioni consigliate: H 230px - L 300-360px', []),
      aspectRatio: 2/1,
      class: "login-mobile-image"
    },
    //PANNELLO LOGIN
    {
      contentId: "logo-backoffice-login",
      contentTypeId: "logo",
      imageTitle: this.tranPipe.transform('backoffice_login_logo_title','Immagine login pannello', []),
      imageDescription: this.tranPipe.transform('backoffice_login_logo_subtitle','Immagine presente nella pagina di login del pannello di amministrazione', []),
      showPropInfo: true,
      propInfo: this.tranPipe.transform('backoffice_login_logo_mobile','Proporzioni consigliate: H 250px - L 250px', []),
      aspectRatio: 1/1,
      class: "admin-login-logo-image",
      showImage: this.authSvc.isAdminUser()
    },
    //PANNELLO MENU
    {
      contentId: "logo-purple-layout-left-side",
      contentTypeId: "media-purple-lib",
      imageTitle: this.tranPipe.transform('backoffice_menu_logo_title','Immagine menu pannello', []),
      imageDescription: this.tranPipe.transform('backoffice_menu_logo_subtitle','Immagine presente sopra il menu del pannello', []),
      showPropInfo: true,
      propInfo: this.tranPipe.transform('backoffice_menu_logo_mobile','Proporzioni consigliate: H 40px - L 40px', []),
      aspectRatio: 1/1,
      class: "admin-left-side-logo-image",
      showImage: this.authSvc.isAdminUser()
    },
    //PANNELLO USER
    {
      contentId: "logo-purple-layout-user",
      contentTypeId: "media-purple-lib",
      imageTitle: this.tranPipe.transform('backoffice_menu_user_title','Immagine menu utente', []),
      imageDescription: this.tranPipe.transform('backoffice_menu_user_subtitle','Immagine presente sotto il menu del pannello', []),
      showPropInfo: true,
      propInfo: this.tranPipe.transform('backoffice_menu_user_mobile','Proporzioni consigliate: H 40px - L 40px', []),
      aspectRatio: 1/1,
      class: "admin-left-side-logo-image",
      showImage: this.authSvc.isAdminUser()
    }
  ]

  ngOnInit() {
  }

  clickImage(event: any) {
    event.srcElement.parentElement!.children[0].click()
  }

  ngAfterViewInit(): void {
    const pageName = "general_images";
    this.dashSvc.setPageHeader(
      this.tranPipe.transform(pageName + '_page_title', 'Immagini', []),
      this.tranPipe.transform(pageName + '_page_subtitle', 'Lista delle immagini generali', []))
  }
}


