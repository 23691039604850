import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { editor } from 'monaco-editor';
import { purpleFormFieldsGroup, PurpleTranslationPipe, PurpleApiProxyService, Helper, PurpleApiMakeRequestResponse, PurpleSelectFilter } from 'purple-lib';
import { AppTranslationService } from 'src/core-modules/localization/localization.service';
import { SaleEventMailTemplateExceptionItem, BackOfficeService } from 'src/core-modules/sdk/api';
import { environment } from 'src/environments/default/environment';
import { ImportMailTemplateComponent } from '../import-mail-template/import-mail-template.component';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalService, NZ_MODAL_DATA } from 'ng-zorro-antd/modal';


@Component({
  selector: 'app-edit-mail-template-exception',
  templateUrl: './edit-mail-template-exception.component.html',
  styleUrls: ['./edit-mail-template-exception.component.scss']
})
export class EditMailTemplateExceptionComponent implements OnInit {

  /* @Input() saleEventMailTemplateException!: SaleEventMailTemplateExceptionItem;
  @Input() systemEventId!: string;
  @Input() systemEventName!: string;
  @Input() saleEventId!: string;
  @Input() isPreview: boolean = false; */
  basePath: string = localStorage.getItem("BasePath")!;

  newObj!: SaleEventMailTemplateExceptionItem;
  previewText: string | undefined = undefined;
  isTestLoading: boolean = false;

  currentTabIdx: number = 0;
  passwordVisible = false;
  editor?: editor.ICodeEditor | editor.IEditor;

  previewButtonText: string = "";

  validateForm: FormGroup = this.fb.group({});
  purpleFormFieldGroups: purpleFormFieldsGroup[] | undefined;
  disableForm: boolean = false;
  isLoading: boolean = false;

  constructor(@Inject(NZ_MODAL_DATA) public data: {saleEventMailTemplateException: SaleEventMailTemplateExceptionItem, systemEventId: string, systemEventName: string, saleEventId: string, isPreview: Boolean}, private mesSvc: NzMessageService, private admSvc: BackOfficeService, private tsvc: AppTranslationService, private tranPipe: PurpleTranslationPipe,
    private modal: NzModalService, private fb: FormBuilder, private apiProxySvc: PurpleApiProxyService) { }

  async ngOnInit(): Promise<void> {
    this.newObj = Helper.storeNewObj(this.data.saleEventMailTemplateException);
    this.previewText = this.newObj
      .mailContent!.replace('https://{{host}}', this.basePath)
      .replace('{{saleEventId}}', this.newObj.saleEventId!);

    if (this.data.isPreview) {
      this.previewButtonText = this.tranPipe.transform('edit_mail_back_to_editor', 'Torna all\'editor', []);
    } else {
      this.previewButtonText = this.tranPipe.transform('edit_mail_show_preview', 'Mostra Anteprima', []);
    }

    this.purpleFormFieldGroups = [
      {
        fieldGroupNumber: 1,
        fielGroupBootstrapColumn: 12,
        fieldGroupPaddingLeft: true,
        fieldGroupPaddingRight: true,
        formFieldFormGroup: this.validateForm,
        formFields: [
          {
            fieldType: 'select',
            fieldControlName: 'smtp',
            fieldBootstrapColumn: 12,
            fieldName: this.tranPipe.transform('edit_mail_template_smtp', 'Configurazione Smtp', []),
            fieldPlaceholder: this.tranPipe.transform('edit_mail_template_smtp_placeholder', 'Seleziona una configurazione Smtp tra quelle disponibili', []),
            fieldOptions: [
              { id: 'scrollFunction', value: this.smtpServerScrollFunction },
              { id: 'searchFunction', value: this.smtpServerSearchFunction },
              { id: 'searchByIdFunction', value: this.smtpServerSearchByIdFunction },
              { id: 'refreshFieldNumber', value: 0 }
            ],
            fieldValue: this.newObj.smtpId,
            fieldIsRequired: true,
            fieldPaddingLeft: false,
            fieldPaddingRight: false,
            fieldIsDisabled: true
          },
          {
            fieldType: 'input-text',
            fieldControlName: 'senderName',
            fieldBootstrapColumn: 6,
            fieldName: this.tranPipe.transform('edit_mail_template_sender_name', 'Nome del mittente', []),
            fieldPlaceholder: this.tranPipe.transform('edit_mail_template_sender_name_placeholder', 'Inserisci qui il nome del mittente della email', []),

            fieldValue: this.newObj.senderName,
            fieldIsRequired: true,
            fieldPaddingLeft: false,
            fieldPaddingRight: true,
            fieldIsDisabled: true
          },
          {
            fieldType: 'input-text',
            fieldControlName: 'senderEmail',
            fieldBootstrapColumn: 6,
            fieldName: this.tranPipe.transform('edit_mail_template_sender_email', 'Indirizzo Email del mittente', []),
            fieldPlaceholder: this.tranPipe.transform('edit_mail_template_sender_email_placeholder', 'Inserisci qui l\'indirizzo email del mittente della email', []),

            fieldValue: this.newObj.senderEmail,
            fieldIsRequired: true,
            fieldPaddingLeft: true,
            fieldPaddingRight: false,
            fieldIsDisabled: true
          },
          {
            fieldType: 'input-text',
            fieldControlName: 'subject',
            fieldBootstrapColumn: 12,
            fieldName: this.tranPipe.transform('edit_mail_template_subject', 'Soggetto Email', []),
            fieldPlaceholder: this.tranPipe.transform('edit_mail_template_subject_placeholder', 'Inserisci qui il soggetto della email', []),

            fieldValue: this.newObj.mailSubject,
            fieldIsRequired: true,
            fieldPaddingLeft: false,
            fieldPaddingRight: false,
            fieldIsDisabled: false
          },
        ]
      }
    ]
  }

  importMailTemplate() {
    this.modal.create({
      nzContent: ImportMailTemplateComponent,
      nzTitle: this.tranPipe.transform('edit_mail_import_from_stripo_modal_title', 'Importa template da Stripo', []),
      nzWidth: '800px',
      nzClassName: 'purple-modal import-mail-template-modal',
      nzFooter: null,
      nzMaskClosable: false,
    }).afterClose.subscribe((htmlContent: string | null | undefined) => {

      if (htmlContent != null && htmlContent != undefined) {
        this.newObj.mailContent = htmlContent
      }
    });
  }

  togglePreviewButton() {
    if (!this.data.isPreview) {
      this.previewText = this.newObj!.mailContent!.replace(
        'https://{{host}}',
        this.basePath
      ).replace('{{saleEventId}}', this.newObj!.saleEventId!);

      this.previewButtonText = this.tranPipe.transform('edit_mail_back_to_editor', 'Torna all\'editor', []);
    } else {
      this.previewButtonText = this.tranPipe.transform('edit_mail_show_preview', 'Mostra Anteprima', []);
    }

    this.data.isPreview = !this.data.isPreview;
  }

  onEditorInit(e: editor.ICodeEditor | editor.IEditor): void {
    console.log('On editor init');
    this.editor = e;
    setTimeout(() => {
      this.editor!.trigger('', 'editor.action.formatDocument', null);
    }, 100);
  }

  checkReplacers(): string[] {
    var re = new RegExp("(?<=\{\{)(.*?)(?=\}\})", "g");

    var results: string[] = [];
    var m;
    do {
      m = re.exec(this.newObj.mailContent);
      if (m && !results.includes(m[1])) {
        results.push(m[1])
      }
    } while (m);

    const reps = this.newObj.systemEventReplacers.map(m => m.eventReplacerId);
    const diff = results.filter(x => !reps.includes(x));
    return diff;
  }

  async saveTemplateChanges() {
    this.isLoading = true;
    this.newObj.mailSubject = this.validateForm.controls["subject"].value;

    if (Helper.checkIfDoChanges(this.data.saleEventMailTemplateException, this.newObj)) {

      var check = this.newObj.mailContent.length > 0;
      var check1: string[] = this.checkReplacers();

      this.validateForm.controls["smtp"].setValue(this.newObj.smtpId);

      for (const i in this.validateForm.controls) {
        this.validateForm.controls[i].markAsDirty();
        this.validateForm.controls[i].updateValueAndValidity();
      }

      if (!check) {
        this.currentTabIdx = 0;
        this.mesSvc.error(this.tranPipe.transform('edit_mail_template_html_content_error', 'Inserisci codice html valido', []), {
          nzDuration: environment.MESSAGE_DURATION,
        });
      }
      else if (check1.length > 0) {
        this.currentTabIdx = 0;
        this.mesSvc.error(this.tranPipe.transform('edit_mail_template_html_content_error_replacer', 'Replacer non validi: {0}', [check1.join(", ")]), {
          nzDuration: environment.MESSAGE_DURATION,
        });
      }
      else if (!this.validateForm.valid) {
        this.currentTabIdx = 1;
        this.mesSvc.error(this.tranPipe.transform('edit_mail_template_general_parameter_error', 'Compila tutti i campi correttamente', []), {
          nzDuration: environment.MESSAGE_DURATION,
        });
      } else {
        await this.apiProxySvc.makeRequestErrorMessage<boolean>(() => this.admSvc.updateSystemEventSaleEventMailTemplateException(
          this.tsvc.translationLanguage.getValue(),
          {
            exceptionId: this.newObj.exceptionId,
            extraPk1: this.newObj.extraPk1!,
            extraPk2: this.newObj.extraPk2,
            extraPk3: this.newObj.extraPk3,
            extraPk4: this.newObj.extraPk4,
            mailContent: this.newObj.mailContent,
            mailSubject: this.newObj.mailSubject,
            mailTemplateId: this.newObj.mailTemplateId,
            saleEventId: this.data.saleEventId,
            systemEventId: this.data.systemEventId,
          }), false, "internal-loader", 500, undefined, undefined, (res: PurpleApiMakeRequestResponse<boolean>) => {
          this.mesSvc.success(this.tranPipe.transform('email_template_update_ok', 'Template aggiornato con successo', []), {
            nzDuration: environment.MESSAGE_DURATION,
          });
          this.data.saleEventMailTemplateException = this.newObj;
          this.newObj = Helper.storeNewObj(this.data.saleEventMailTemplateException);
        })
      }
    } else {
      this.mesSvc.warning(this.tranPipe.transform('edit_no_changes', 'Nessuna modifica effettuata'), {
        nzDuration: environment.MESSAGE_DURATION,
      });
    }
    this.isLoading = false;
  }

  copyToClipboard(event: Event, textToCopy: string) {
    event.stopPropagation();
    this.mesSvc.info(this.tranPipe.transform('edit_mail_template_copy_to_clipboard', 'Replacer copiato negli appunti', []))

    navigator.clipboard.writeText("{{" + textToCopy + "}}");
  }

  //#region Select
  //ScrollFunction
  smtpServerScrollFunction = (args: { pageNumber: number, pageSize: number, culture: string, searchName: string, searchFilters: PurpleSelectFilter[] }) => {
    return this.admSvc.getSmtpServersSelect(args.culture, args)
  }
  //SearchFunction
  smtpServerSearchFunction = (args: { pageNumber: number, pageSize: number, culture: string, searchName: string, searchFilters: PurpleSelectFilter[] }) => {
    return this.admSvc.getSmtpServersByNameSelect(args.culture, args)
  }
  //InitFunction (SearchByIdFunction)
  smtpServerSearchByIdFunction = (args: { pageNumber: number, pageSize: number, culture: string, searchName: string, searchFilters: PurpleSelectFilter[] }) => {
    return this.admSvc.getSmtpServersByIdSelect(args.culture, args)
  }
  //#endregion
}
