import { Component, Inject, Input, LOCALE_ID, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Helper, PurpleApiMakeRequestResponse, PurpleApiProxyService, PurpleSelectFilter, PurpleTranslationPipe, purpleFormFieldsGroup } from 'purple-lib';
import { AppTranslationService } from 'src/core-modules/localization/localization.service';
import { BackOfficeService, BookingService, PRPVSaleEvent } from 'src/core-modules/sdk/api';
import { environment } from 'src/environments/default/environment';
import { NzModalRef, NZ_MODAL_DATA } from 'ng-zorro-antd/modal';
import { NzMessageService } from 'ng-zorro-antd/message';
import { FormatWidth, getLocaleDateFormat } from '@angular/common';

import { differenceInCalendarDays } from 'date-fns';

@Component({
  selector: 'app-add-sale-event-fast',
  templateUrl: './add-sale-event-fast.component-modal.html',
  styleUrls: ['./add-sale-event-fast.component-modal.scss']
})
export class AddSaleEventFastModalComponent implements OnInit {

  originalEditItem!: EditFastSaleEvent;
  newObj!: EditFastSaleEvent;
  isNew: boolean = false;

  validateForm: FormGroup = this.fb.group({});
  purpleFormFieldGroups: purpleFormFieldsGroup[] | undefined;
  disableForm: boolean = false;
  isLoading: boolean = false;

  dateFormat = getLocaleDateFormat(this.locale, FormatWidth.Medium);
  staffDate: Date[] | null = null;
  publicDate: Date[] | null = null;

  constructor(@Inject(NZ_MODAL_DATA) private saleEventFull: PRPVSaleEvent | undefined, @Inject(LOCALE_ID) private locale: string, private modalRef: NzModalRef, private fb: FormBuilder, private tranPipe: PurpleTranslationPipe, private apiProxySvc: PurpleApiProxyService,
    private mesSvc: NzMessageService, private backSvc: BackOfficeService, private tsvc: AppTranslationService, private bookSvc: BookingService) { }


  async ngOnInit(): Promise<void> {
    this.originalEditItem = {
      name: this.saleEventFull?.saleEventName,
      email: this.saleEventFull?.saleEventEmail,
      staffEndDate: this.saleEventFull?.endDate,
      staffOpenDate: this.saleEventFull?.startDate,
      publicEndDate: this.saleEventFull?.publicCloseDate,
      publicOpenDate: this.saleEventFull?.publicOpenDate,
      address: this.saleEventFull?.addressId,
      isPrivate: this.saleEventFull?.isPrivateInviteCounter
    }

    this.isNew = this.saleEventFull == undefined;
    this.newObj = Helper.storeNewObj(this.originalEditItem);

    if (!this.isNew) {
      this.staffDate = [];
      this.staffDate.push(new Date(Date.parse(this.newObj.staffOpenDate!)))
      this.staffDate.push(new Date(Date.parse(this.newObj.staffEndDate!)))

      this.publicDate = [];
      this.publicDate.push(new Date(Date.parse(this.newObj.publicOpenDate!)))
      this.publicDate.push(new Date(Date.parse(this.newObj.publicEndDate!)))
    }

    this.purpleFormFieldGroups = [
      {
        fieldGroupNumber: 1,
        fielGroupBootstrapColumn: 12,
        fieldGroupPaddingLeft: false,
        fieldGroupPaddingRight: false,
        formFieldFormGroup: this.validateForm,
        formFields: [
          {
            fieldType: 'input-text',
            fieldControlName: 'name',
            fieldBootstrapColumn: 6,
            fieldName: this.tranPipe.transform('fast_add_new_sale_name', 'Nome', []),
            fieldPlaceholder: this.tranPipe.transform('fast_add_new_sale_name_placeholder', 'Nome private sale', []),
            fieldValue: this.newObj.name,
            fieldIsRequired: true,
            fieldPaddingLeft: false,
            fieldPaddingRight: true
          },
          {
            fieldType: 'input-text',
            fieldControlName: 'email',
            fieldBootstrapColumn: 6,
            fieldName: this.tranPipe.transform('fast_add_new_sale_email', 'Email', []),
            fieldPlaceholder: this.tranPipe.transform('fast_add_new_sale_email_placeholder', 'Email private sale', []),
            fieldValue: this.newObj.email,
            fieldIsRequired: true,
            fieldCustomValidators: [Validators.email],
            fieldPaddingLeft: true,
            fieldPaddingRight: false
          },
          {
            fieldType: 'select-local',
            fieldControlName: 'isPrivate',
            fieldBootstrapColumn: 12,
            fieldName: this.tranPipe.transform('fast_add_new_sale_type', 'Tipologia', []),
            fieldPlaceholder: this.tranPipe.transform('fast_add_new_sale_type_placeholder', 'Tipologia private sale', []),

            fieldCustomClass: undefined,
            fieldOptions: [
              { id: 'type', value: 'default' },
              { id: 'showArrow', value: true },
              {
                id: 'options', value: [
                  {
                    label: this.tranPipe.transform('sale_type_public', 'Pubblica', []),
                    value: false
                  },
                  {
                    label: this.tranPipe.transform('sale_type_private', 'Privata', []),
                    value: true
                  }
                ]
              }
            ],
            fieldValue: this.newObj.isPrivate,
            fieldIsRequired: true,
            fieldPaddingLeft: false,
            fieldPaddingRight: false
          },
          {
            fieldType: 'select',
            fieldControlName: 'address',
            fieldBootstrapColumn: 12,
            fieldName: this.tranPipe.transform('fast_add_new_sale_address', 'Indirizzo', []),
            fieldPlaceholder: this.tranPipe.transform('fast_add_new_sale_address_placeholder', 'Indirizzo private sale', []),
            fieldOptions: [
              { id: 'searchFunction', value: this.addressSearchNameFunction },
              { id: 'searchByIdFunction', value: this.addressSearchIdFunction },
              { id: 'refreshFieldNumber', value: 0 }
            ],
            fieldValue: this.newObj.address,
            fieldIsRequired: true,
            fieldPaddingLeft: false,
            fieldPaddingRight: false
          },
          {
            fieldType: 'date-range',
            fieldControlName: 'dateRange',
            fieldBootstrapColumn: 6,
            fieldName: this.tranPipe.transform('fast_add_new_sale_staff_date', 'Date Staff', []),
            fieldPlaceholder: this.tranPipe.transform('fast_add_new_sale_staff_date_placeholder', 'Date per lo staff della private sale', []),

            fieldCustomClass: undefined,
            fieldOptions: [{ id: 'dateFormat', value: this.dateFormat }],
            fieldValue: this.staffDate,
            fieldVerticalSpace: undefined,
            fieldIsRequired: true,
            fieldCustomValidators: undefined,
            fieldPaddingLeft: false,
            fieldPaddingRight: true
          },
          {
            fieldType: 'date-range',
            fieldControlName: 'dateRange',
            fieldBootstrapColumn: 6,
            fieldName: this.tranPipe.transform('fast_add_new_sale_public_date', 'Date di apertura al pubblico', []),
            fieldPlaceholder: this.tranPipe.transform('fast_add_new_sale_public_date_placeholder', 'Date di apertura al pubblico della private sale', []),

            fieldCustomClass: undefined,
            fieldOptions: [{ id: 'dateFormat', value: this.dateFormat }, { id: "disabledDate", value: this.disabledPublicDates }],
            fieldValue: this.publicDate,
            fieldVerticalSpace: undefined,
            fieldIsRequired: true,
            fieldCustomValidators: undefined,
            fieldPaddingLeft: true,
            fieldPaddingRight: false
          }
        ]
      }
    ]
  }

  //#region Address
  addressSearchNameFunction = (args: { pageNumber: number, pageSize: number, culture: string, searchName: string, searchFilters: PurpleSelectFilter[] }) => {
    return this.bookSvc.getAddressByNameSelect(args.culture, args)
  }

  addressSearchIdFunction = (args: { pageNumber: number, pageSize: number, culture: string, searchName: string, searchFilters: PurpleSelectFilter[] }) => {
    return this.bookSvc.getAddressByIdSelect(args.culture, args)
  }
  //#endregion

  disabledPublicDates = (current: Date): boolean => {
    if (this.staffDate != null && differenceInCalendarDays(this.staffDate[0], current) > 0) {
      return true
    }

    if (this.staffDate != null && differenceInCalendarDays(current, this.staffDate[1]) > 0) {
      return true
    }

    return false;
  }


  async addSale() {
    this.isLoading = true;
    for (const i in this.validateForm.controls) {
      this.validateForm.controls[i].markAsDirty();
      this.validateForm.controls[i].updateValueAndValidity();
    }


    if (this.validateForm.valid) {
      this.newObj.name = this.validateForm.controls["name"].value;
      this.newObj.address = this.validateForm.controls["address"].value;
      this.newObj.email = this.validateForm.controls["email"].value;
      this.newObj.staffOpenDate = this.validateForm.controls["dateRange"].value[0].toIsoStringPurple()
      this.newObj.email = this.validateForm.controls["email"].value;

      if (Helper.checkIfDoChanges(this.newObj, this.originalEditItem)) {

        await this.apiProxySvc.makeRequestErrorMessage<Boolean>(() => this.backSvc.createNewSaleEvent(this.tsvc.translationLanguage.value, {
          currency: "eur",
          defaultTax: 22,
          externalAddressId: this.validateForm.controls["address"].value,
          saleEventCode: (this.validateForm.controls["name"].value as string).sanitizer("-"),
          saleEventEmail: this.validateForm.controls["email"].value,
          saleEventPhone: "123456789",
          saleEventPhonePrefix: "+39",
          saleEventName: this.validateForm.controls["name"].value,
          saleEventDescription: undefined,
          endDate: (this.validateForm.controls["dateRange"].value)[1].toIsoStringPurple(),
          startDate: (this.validateForm.controls["dateRange"].value)[0].toIsoStringPurple(),
          publicCloseDate: (this.validateForm.controls["dateRange"].value)[1].toIsoStringPurple(),
          publicOpenDate: (this.validateForm.controls["dateRange"].value)[0].toIsoStringPurple(),
          isOnline: false,
          saleEventDetailLayout: "base",
          saleEventDetailDescription: null,
          saleEventInternalName: this.validateForm.controls["name"].value,
          showComingSoon: false,
          isStatsVisible: false,
          onlyPrivateInvite: this.validateForm.controls["isPrivate"].value,
          isOwnersVisible: false,
          comingSoonStartDate: null,
          limitOwners: []
        }), false, "internal-loader", 500, undefined, undefined, (res: PurpleApiMakeRequestResponse<Boolean>) => {
          let text: string;
          if (this.isNew) {
            text = this.tranPipe.transform('fast_add_new_sale_add', 'Ruolo aggiunto con successo')
          } else {
            text = this.tranPipe.transform('fast_add_new_sale_update', 'Ruolo aggiornato con successo')
          }
          this.mesSvc.success(text, {
            nzDuration: environment.MESSAGE_DURATION
          });
          this.modalRef.destroy(res.data);
        })

      }

      this.isLoading = false
    }
  }
}

class EditFastSaleEvent {
  name: string | undefined;
  email: string | undefined;
  address: string | undefined;
  staffOpenDate: string | undefined;
  staffEndDate: string | undefined;
  publicOpenDate: string | undefined;
  publicEndDate: string | undefined;
  isPrivate: boolean | undefined;
}