/**
 * Api v4
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

import { ActiveOrPendingUserRequest } from '../model/models';
import { AddOrRemoveSaleEventAttributeValueRequest } from '../model/models';
import { AddOrUpdateAnonymizationOwnerRequest } from '../model/models';
import { AddOrUpdateCouponBaseRequest } from '../model/models';
import { AddOrUpdateCouponRequest } from '../model/models';
import { AddOrUpdateEmployeeRequest } from '../model/models';
import { AddOrUpdateMailTemplateBaseRequest } from '../model/models';
import { AddOrUpdatePolicyRequest } from '../model/models';
import { AddOrUpdateRoleRequest } from '../model/models';
import { AddOrUpdateSaleEventBrandLimitRequest } from '../model/models';
import { AddOrUpdateSaleEventCarouselSlidesRequest } from '../model/models';
import { AddOrUpdateSaleEventCategoryExceptionLimitRequest } from '../model/models';
import { AddOrUpdateSaleEventCategoryLimitRequest } from '../model/models';
import { AddOrUpdateSaleEventPolicyRequest } from '../model/models';
import { AddOrUpdateSaleEventRoleInviteStartDateRequest } from '../model/models';
import { AddOrUpdateSaleEventRoleStopDateRequest } from '../model/models';
import { AddOrUpdateSaleEventUserRoleRequest } from '../model/models';
import { AddOrUpdateSaleEventUserRolesRequest } from '../model/models';
import { AddOrUpdateSmtpRequest } from '../model/models';
import { AddOrUpdateSmtpServerRequest } from '../model/models';
import { AddOrUpdateUserAddressRequest } from '../model/models';
import { AddOrUpdateUserRequest } from '../model/models';
import { AddRoleFromSlotEditRequest } from '../model/models';
import { AddSystemEventSaleEventMailTemplateExceptionRequest } from '../model/models';
import { AddressPurpleApiResponse } from '../model/models';
import { AdminVerifyUserEmailRequest } from '../model/models';
import { AdminVerifyUserPhoneRequest } from '../model/models';
import { AngularSummaryStatsItemListPurpleApiResponse } from '../model/models';
import { AnonymizeUserRequest } from '../model/models';
import { AnonymizeUsersByOwnerIdRequest } from '../model/models';
import { ApiAddRoleReferralRequest } from '../model/models';
import { ApiAddUserRoleReferralRequest } from '../model/models';
import { ApiCountReferralRedeemsRequest } from '../model/models';
import { ApiCreateNewSaleEventRequest } from '../model/models';
import { ApiDeleteRoleReferralRequest } from '../model/models';
import { ApiDeleteSystemEventExceptionRequest } from '../model/models';
import { ApiDeleteUserRoleReferralRequest } from '../model/models';
import { ApiEnqueueBulkImportedUsersRequest } from '../model/models';
import { ApiGetPurpleSelectByIdRequest } from '../model/models';
import { ApiGetPurpleSelectByNameRequest } from '../model/models';
import { ApiGetPurpleSelectMultipleByIdRequest } from '../model/models';
import { ApiGetPurpleSelectRequest } from '../model/models';
import { ApiGetPurpleTableRequest } from '../model/models';
import { ApiGetSaleEventAttributesRequest } from '../model/models';
import { ApiGetSaleEventDescriptionsRequest } from '../model/models';
import { ApiGetSaleEventRequest } from '../model/models';
import { ApiGetSaleEventSystemEventExceptionsRequest } from '../model/models';
import { ApiGetSaleEventSystemEventsRequest } from '../model/models';
import { ApiGetSystemEventExceptionExtraKeysRequest } from '../model/models';
import { ApiGetUserDetailBookingsRequest } from '../model/models';
import { ApiSetSaleEventSystemEventVisibilityRequest } from '../model/models';
import { ApiTreeItemListPurpleApiResponse } from '../model/models';
import { ApiUpdateReferralRequest } from '../model/models';
import { ApiUpdateSaleEventOnlineStatusRequest } from '../model/models';
import { ApiUpdateSaleEventRequest } from '../model/models';
import { BookSlotAdminRequest } from '../model/models';
import { BooleanPurpleApiResponse } from '../model/models';
import { BrandLimitTableItemListPurpleApiResponse } from '../model/models';
import { BrandLimitTableItemPurpleTablePagedResponsePurpleApiResponse } from '../model/models';
import { ChangeUserEmailRequest } from '../model/models';
import { CheckIfRoleIsUsedRequest } from '../model/models';
import { CheckIfSlotIsFullRequest } from '../model/models';
import { CloneDateSlotRequest } from '../model/models';
import { CreateOrUpdateSaleEventDateDaySlotRequest } from '../model/models';
import { DateSlotRoleListPurpleApiResponse } from '../model/models';
import { DateSlotRolePurpleApiResponse } from '../model/models';
import { DefaultCouponPurpleApiResponse } from '../model/models';
import { DefaultCouponPurpleTablePagedResponsePurpleApiResponse } from '../model/models';
import { DeleteCouponBaseRequest } from '../model/models';
import { DeleteEmployeeRequest } from '../model/models';
import { DeleteMailTemplateBaseRequest } from '../model/models';
import { DeleteRoleRequest } from '../model/models';
import { DeleteSaleEventBrandLimitRequest } from '../model/models';
import { DeleteSaleEventCarouselSlideRequest } from '../model/models';
import { DeleteSaleEventCategoryExceptionLimitRequest } from '../model/models';
import { DeleteSaleEventCategoryLimitRequest } from '../model/models';
import { DeleteSaleEventPolicyRequest } from '../model/models';
import { DeleteSaleEventRoleInviteStartDateRequest } from '../model/models';
import { DeleteSaleEventRoleStopDateRequest } from '../model/models';
import { DeleteSaleEventSlotFromSlotEditRequest } from '../model/models';
import { DeleteSaleEventSlotRequest } from '../model/models';
import { DeleteSaleEventUserRoleRequest } from '../model/models';
import { DeleteSmtpServerRequest } from '../model/models';
import { DeleteTranslationRequest } from '../model/models';
import { DeleteUserRequest } from '../model/models';
import { EnableDisableCouponRequest } from '../model/models';
import { EnableDisableSaleEventLimitsRequest } from '../model/models';
import { EnableDisableUserAddressRequest } from '../model/models';
import { GetAddressByExternalAddressIdRequest } from '../model/models';
import { GetDateSlotAllRolesRequest } from '../model/models';
import { GetDefaultCouponBaseRequest } from '../model/models';
import { GetNewSlideDefaultFieldsRequest } from '../model/models';
import { GetOwnerCustomerIdsRequest } from '../model/models';
import { GetRoleDetailRequest } from '../model/models';
import { GetSaleEventActiveRoleDetailRequest } from '../model/models';
import { GetSaleEventActiveRolesRequest } from '../model/models';
import { GetSaleEventBookingStatsRequest } from '../model/models';
import { GetSaleEventBrandsLimitsRequest } from '../model/models';
import { GetSaleEventCarouselSlideItemRequest } from '../model/models';
import { GetSaleEventCategoryExceptionLimitsRequest } from '../model/models';
import { GetSaleEventCategoryItemsLimitsRequest } from '../model/models';
import { GetSaleEventCloneDateSlotDateRangeRequest } from '../model/models';
import { GetSaleEventDateDayBookingsPreviewRequest } from '../model/models';
import { GetSaleEventDateDaySlotsRequest } from '../model/models';
import { GetSaleEventDateRangeRequest } from '../model/models';
import { GetSaleEventDayBaseSlotsRequest } from '../model/models';
import { GetSaleEventDayRolesSelectRequest } from '../model/models';
import { GetSaleEventDaySlotsSelectItemListPurpleApiResponse } from '../model/models';
import { GetSaleEventDaySlotsSelectRequest } from '../model/models';
import { GetSaleEventLimitsRequest } from '../model/models';
import { GetSaleEventOwnerFiltersRequest } from '../model/models';
import { GetSaleEventSlotBookingUserDetailRequest } from '../model/models';
import { GetSaleEventSlotBookingsRequest } from '../model/models';
import { GetSaleEventSlotsSelectItemListPurpleApiResponse } from '../model/models';
import { GetSaleEventSlotsSelectRequest } from '../model/models';
import { GetSaleEventSystemEventExceptionsResponseListPurpleApiResponse } from '../model/models';
import { GetSaleEventSystemEventExceptionsResponsePurpleTablePagedResponsePurpleApiResponse } from '../model/models';
import { GetSaleEventSystemEventsResponseListPurpleApiResponse } from '../model/models';
import { GetSaleEventSystemEventsResponsePurpleTablePagedResponsePurpleApiResponse } from '../model/models';
import { GetSystemEventSaleEventMailTemplateExceptionRequest } from '../model/models';
import { GetSystemEventSaleEventMailTemplateRequest } from '../model/models';
import { GetUserDetailRequestRequest } from '../model/models';
import { GetUserProfileBookingsResponsePurpleApiResponse } from '../model/models';
import { GuidListPurpleApiResponse } from '../model/models';
import { Int32PurpleApiResponse } from '../model/models';
import { MailTemplatePurpleApiResponse } from '../model/models';
import { MailTemplatePurpleTablePagedResponsePurpleApiResponse } from '../model/models';
import { OwnerListPurpleApiResponse } from '../model/models';
import { OwnerPurpleApiResponse } from '../model/models';
import { OwnerTableItemPurpleTablePagedResponsePurpleApiResponse } from '../model/models';
import { PRPVCouponFullPurpleTablePagedResponsePurpleApiResponse } from '../model/models';
import { PRPVPolicyFullPurpleTablePagedResponsePurpleApiResponse } from '../model/models';
import { PRPVSaleEventFullExtendedPurpleApiResponse } from '../model/models';
import { PRPVSaleEventFullNullablePurpleApiResponse } from '../model/models';
import { PRPVSaleEventPurpleTablePagedResponsePurpleApiResponse } from '../model/models';
import { PRPVSaleEventRoleInviteStartDatePurpleTablePagedResponsePurpleApiResponse } from '../model/models';
import { PRPVSaleEventRoleStopDatePurpleTablePagedResponsePurpleApiResponse } from '../model/models';
import { PRPVSaleEventSlotsBookingPurpleTablePagedResponsePurpleApiResponse } from '../model/models';
import { PRPVTranslationFullPurpleTablePagedResponsePurpleApiResponse } from '../model/models';
import { PRPVUserAddressPurpleTablePagedResponsePurpleApiResponse } from '../model/models';
import { PRPVUserBulkImportStatusPurpleTablePagedResponsePurpleApiResponse } from '../model/models';
import { PurpleApiResponse } from '../model/models';
import { PurpleSelectPagedResponsePurpleApiResponse } from '../model/models';
import { ReInviteRequest } from '../model/models';
import { ReferralTableItemPurpleTablePagedResponsePurpleApiResponse } from '../model/models';
import { RemoveSaleEventAttributeValueRequest } from '../model/models';
import { ReorderSaleEventPoliciesRequest } from '../model/models';
import { ResetUserPasswordRequest } from '../model/models';
import { RoleListPurpleApiResponse } from '../model/models';
import { RolePurpleApiResponse } from '../model/models';
import { RolePurpleTablePagedResponsePurpleApiResponse } from '../model/models';
import { RoleTabListPurpleApiResponse } from '../model/models';
import { SaleEventActiveRolePurpleApiResponse } from '../model/models';
import { SaleEventAttributeValuesItemListPurpleApiResponse } from '../model/models';
import { SaleEventCarouselSlideFieldItemListPurpleApiResponse } from '../model/models';
import { SaleEventCarouselSlideItemListPurpleApiResponse } from '../model/models';
import { SaleEventCloneDateItemListPurpleApiResponse } from '../model/models';
import { SaleEventDateItemListPurpleApiResponse } from '../model/models';
import { SaleEventDayItemListPurpleApiResponse } from '../model/models';
import { SaleEventDaySlotItemListPurpleApiResponse } from '../model/models';
import { SaleEventDaySlotItemPurpleApiResponse } from '../model/models';
import { SaleEventDescriptionsPurpleApiResponse } from '../model/models';
import { SaleEventLimitedExceptionSaleCategoryListPurpleApiResponse } from '../model/models';
import { SaleEventLimitedExceptionSaleCategoryPurpleTablePagedResponsePurpleApiResponse } from '../model/models';
import { SaleEventLimitedSaleCategoryListPurpleApiResponse } from '../model/models';
import { SaleEventLimitedSaleCategoryPurpleTablePagedResponsePurpleApiResponse } from '../model/models';
import { SaleEventLimitsPurpleApiResponse } from '../model/models';
import { SaleEventMailTemplateExceptionItemPurpleApiResponse } from '../model/models';
import { SaleEventMailTemplateItemPurpleApiResponse } from '../model/models';
import { SaleEventRoleInviteStartDatePurpleApiResponse } from '../model/models';
import { SaleEventRoleStopDatePurpleApiResponse } from '../model/models';
import { SaleEventSlotBookingPreviewListPurpleApiResponse } from '../model/models';
import { SaleEventSlotListPurpleApiResponse } from '../model/models';
import { SaleEventUserExtendedTableItemPurpleTablePagedResponsePurpleApiResponse } from '../model/models';
import { SaleEventUserInviteTableItemPurpleTablePagedResponsePurpleApiResponse } from '../model/models';
import { SaleEventUserRoleTableItemPurpleTablePagedResponsePurpleApiResponse } from '../model/models';
import { SendUserRemindersRequest } from '../model/models';
import { SmtpServerListPurpleApiResponse } from '../model/models';
import { SmtpServerPurpleApiResponse } from '../model/models';
import { SmtpServerPurpleTablePagedResponsePurpleApiResponse } from '../model/models';
import { StringPurpleApiResponse } from '../model/models';
import { SystemEventExtrasPurpleApiResponse } from '../model/models';
import { TestSmtpRequest } from '../model/models';
import { UnBookSlotAdminRequest } from '../model/models';
import { UpdateSaleEventLimitsRequest } from '../model/models';
import { UpdateSaleEventRoleDatesRequest } from '../model/models';
import { UpdateSaleEventSystemEventMailTemplateRequest } from '../model/models';
import { UpdateShowSaleEventCarouselSliderRequest } from '../model/models';
import { UpdateSystemEventSaleEventMailTemplateExceptionRequest } from '../model/models';
import { UserBookingDetailAmountPurpleApiResponse } from '../model/models';
import { UserDetailPurpleApiResponse } from '../model/models';
import { UserPurpleApiResponse } from '../model/models';
import { UserTableItemPurpleTablePagedResponsePurpleApiResponse } from '../model/models';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';



@Injectable({
  providedIn: 'root'
})
export class BackOfficeService {

    protected basePath = 'http://localhost';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }

    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key,
                        (value as Date).toISOString().substr(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * @param culture 
     * @param activeOrPendingUserRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public activeOrPendingUser(culture: string, activeOrPendingUserRequest?: ActiveOrPendingUserRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<BooleanPurpleApiResponse>;
    public activeOrPendingUser(culture: string, activeOrPendingUserRequest?: ActiveOrPendingUserRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<BooleanPurpleApiResponse>>;
    public activeOrPendingUser(culture: string, activeOrPendingUserRequest?: ActiveOrPendingUserRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<BooleanPurpleApiResponse>>;
    public activeOrPendingUser(culture: string, activeOrPendingUserRequest?: ActiveOrPendingUserRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (culture === null || culture === undefined) {
            throw new Error('Required parameter culture was null or undefined when calling ActiveOrPendingUser.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<BooleanPurpleApiResponse>(`${this.configuration.basePath}/${encodeURIComponent(String(culture))}/api/BackOffice/ActiveOrPendingUser`,
            activeOrPendingUserRequest,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param culture 
     * @param addOrRemoveSaleEventAttributeValueRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public addOrRemoveSaleEventAttributeValue(culture: string, addOrRemoveSaleEventAttributeValueRequest?: AddOrRemoveSaleEventAttributeValueRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<BooleanPurpleApiResponse>;
    public addOrRemoveSaleEventAttributeValue(culture: string, addOrRemoveSaleEventAttributeValueRequest?: AddOrRemoveSaleEventAttributeValueRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<BooleanPurpleApiResponse>>;
    public addOrRemoveSaleEventAttributeValue(culture: string, addOrRemoveSaleEventAttributeValueRequest?: AddOrRemoveSaleEventAttributeValueRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<BooleanPurpleApiResponse>>;
    public addOrRemoveSaleEventAttributeValue(culture: string, addOrRemoveSaleEventAttributeValueRequest?: AddOrRemoveSaleEventAttributeValueRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (culture === null || culture === undefined) {
            throw new Error('Required parameter culture was null or undefined when calling AddOrRemoveSaleEventAttributeValue.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<BooleanPurpleApiResponse>(`${this.configuration.basePath}/${encodeURIComponent(String(culture))}/api/BackOffice/AddOrRemoveSaleEventAttributeValue`,
            addOrRemoveSaleEventAttributeValueRequest,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param culture 
     * @param addOrUpdateAnonymizationOwnerRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public addOrUpdateAnonymizationOwner(culture: string, addOrUpdateAnonymizationOwnerRequest?: AddOrUpdateAnonymizationOwnerRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<OwnerPurpleApiResponse>;
    public addOrUpdateAnonymizationOwner(culture: string, addOrUpdateAnonymizationOwnerRequest?: AddOrUpdateAnonymizationOwnerRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<OwnerPurpleApiResponse>>;
    public addOrUpdateAnonymizationOwner(culture: string, addOrUpdateAnonymizationOwnerRequest?: AddOrUpdateAnonymizationOwnerRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<OwnerPurpleApiResponse>>;
    public addOrUpdateAnonymizationOwner(culture: string, addOrUpdateAnonymizationOwnerRequest?: AddOrUpdateAnonymizationOwnerRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (culture === null || culture === undefined) {
            throw new Error('Required parameter culture was null or undefined when calling AddOrUpdateAnonymizationOwner.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<OwnerPurpleApiResponse>(`${this.configuration.basePath}/${encodeURIComponent(String(culture))}/api/BackOffice/AddOrUpdateAnonymizationOwner`,
            addOrUpdateAnonymizationOwnerRequest,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param culture 
     * @param addOrUpdateCouponBaseRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public addOrUpdateCouponBase(culture: string, addOrUpdateCouponBaseRequest?: AddOrUpdateCouponBaseRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<BooleanPurpleApiResponse>;
    public addOrUpdateCouponBase(culture: string, addOrUpdateCouponBaseRequest?: AddOrUpdateCouponBaseRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<BooleanPurpleApiResponse>>;
    public addOrUpdateCouponBase(culture: string, addOrUpdateCouponBaseRequest?: AddOrUpdateCouponBaseRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<BooleanPurpleApiResponse>>;
    public addOrUpdateCouponBase(culture: string, addOrUpdateCouponBaseRequest?: AddOrUpdateCouponBaseRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (culture === null || culture === undefined) {
            throw new Error('Required parameter culture was null or undefined when calling AddOrUpdateCouponBase.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<BooleanPurpleApiResponse>(`${this.configuration.basePath}/${encodeURIComponent(String(culture))}/api/BackOffice/AddOrUpdateCouponBase`,
            addOrUpdateCouponBaseRequest,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param culture 
     * @param addOrUpdateCouponRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public addOrUpdateCoupon(culture: string, addOrUpdateCouponRequest?: AddOrUpdateCouponRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<BooleanPurpleApiResponse>;
    public addOrUpdateCoupon(culture: string, addOrUpdateCouponRequest?: AddOrUpdateCouponRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<BooleanPurpleApiResponse>>;
    public addOrUpdateCoupon(culture: string, addOrUpdateCouponRequest?: AddOrUpdateCouponRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<BooleanPurpleApiResponse>>;
    public addOrUpdateCoupon(culture: string, addOrUpdateCouponRequest?: AddOrUpdateCouponRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (culture === null || culture === undefined) {
            throw new Error('Required parameter culture was null or undefined when calling AddOrUpdateCoupon.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<BooleanPurpleApiResponse>(`${this.configuration.basePath}/${encodeURIComponent(String(culture))}/api/BackOffice/AddOrUpdateCoupon`,
            addOrUpdateCouponRequest,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param culture 
     * @param addOrUpdateEmployeeRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public addOrUpdateEmployee(culture: string, addOrUpdateEmployeeRequest?: AddOrUpdateEmployeeRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<BooleanPurpleApiResponse>;
    public addOrUpdateEmployee(culture: string, addOrUpdateEmployeeRequest?: AddOrUpdateEmployeeRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<BooleanPurpleApiResponse>>;
    public addOrUpdateEmployee(culture: string, addOrUpdateEmployeeRequest?: AddOrUpdateEmployeeRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<BooleanPurpleApiResponse>>;
    public addOrUpdateEmployee(culture: string, addOrUpdateEmployeeRequest?: AddOrUpdateEmployeeRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (culture === null || culture === undefined) {
            throw new Error('Required parameter culture was null or undefined when calling AddOrUpdateEmployee.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<BooleanPurpleApiResponse>(`${this.configuration.basePath}/${encodeURIComponent(String(culture))}/api/BackOffice/AddOrUpdateEmployee`,
            addOrUpdateEmployeeRequest,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param culture 
     * @param addOrUpdateMailTemplateBaseRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public addOrUpdateMailTemplateBase(culture: string, addOrUpdateMailTemplateBaseRequest?: AddOrUpdateMailTemplateBaseRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<MailTemplatePurpleApiResponse>;
    public addOrUpdateMailTemplateBase(culture: string, addOrUpdateMailTemplateBaseRequest?: AddOrUpdateMailTemplateBaseRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<MailTemplatePurpleApiResponse>>;
    public addOrUpdateMailTemplateBase(culture: string, addOrUpdateMailTemplateBaseRequest?: AddOrUpdateMailTemplateBaseRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<MailTemplatePurpleApiResponse>>;
    public addOrUpdateMailTemplateBase(culture: string, addOrUpdateMailTemplateBaseRequest?: AddOrUpdateMailTemplateBaseRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (culture === null || culture === undefined) {
            throw new Error('Required parameter culture was null or undefined when calling AddOrUpdateMailTemplateBase.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<MailTemplatePurpleApiResponse>(`${this.configuration.basePath}/${encodeURIComponent(String(culture))}/api/BackOffice/AddOrUpdateMailTemplateBase`,
            addOrUpdateMailTemplateBaseRequest,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param culture 
     * @param addOrUpdatePolicyRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public addOrUpdatePolicy(culture: string, addOrUpdatePolicyRequest?: AddOrUpdatePolicyRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<BooleanPurpleApiResponse>;
    public addOrUpdatePolicy(culture: string, addOrUpdatePolicyRequest?: AddOrUpdatePolicyRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<BooleanPurpleApiResponse>>;
    public addOrUpdatePolicy(culture: string, addOrUpdatePolicyRequest?: AddOrUpdatePolicyRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<BooleanPurpleApiResponse>>;
    public addOrUpdatePolicy(culture: string, addOrUpdatePolicyRequest?: AddOrUpdatePolicyRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (culture === null || culture === undefined) {
            throw new Error('Required parameter culture was null or undefined when calling AddOrUpdatePolicy.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<BooleanPurpleApiResponse>(`${this.configuration.basePath}/${encodeURIComponent(String(culture))}/api/BackOffice/AddOrUpdatePolicy`,
            addOrUpdatePolicyRequest,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param culture 
     * @param addOrUpdateRoleRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public addOrUpdateRole(culture: string, addOrUpdateRoleRequest?: AddOrUpdateRoleRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<RolePurpleApiResponse>;
    public addOrUpdateRole(culture: string, addOrUpdateRoleRequest?: AddOrUpdateRoleRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<RolePurpleApiResponse>>;
    public addOrUpdateRole(culture: string, addOrUpdateRoleRequest?: AddOrUpdateRoleRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<RolePurpleApiResponse>>;
    public addOrUpdateRole(culture: string, addOrUpdateRoleRequest?: AddOrUpdateRoleRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (culture === null || culture === undefined) {
            throw new Error('Required parameter culture was null or undefined when calling AddOrUpdateRole.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<RolePurpleApiResponse>(`${this.configuration.basePath}/${encodeURIComponent(String(culture))}/api/BackOffice/AddOrUpdateRole`,
            addOrUpdateRoleRequest,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param culture 
     * @param addOrUpdateSaleEventBrandLimitRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public addOrUpdateSaleEventBrandLimit(culture: string, addOrUpdateSaleEventBrandLimitRequest?: AddOrUpdateSaleEventBrandLimitRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<BooleanPurpleApiResponse>;
    public addOrUpdateSaleEventBrandLimit(culture: string, addOrUpdateSaleEventBrandLimitRequest?: AddOrUpdateSaleEventBrandLimitRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<BooleanPurpleApiResponse>>;
    public addOrUpdateSaleEventBrandLimit(culture: string, addOrUpdateSaleEventBrandLimitRequest?: AddOrUpdateSaleEventBrandLimitRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<BooleanPurpleApiResponse>>;
    public addOrUpdateSaleEventBrandLimit(culture: string, addOrUpdateSaleEventBrandLimitRequest?: AddOrUpdateSaleEventBrandLimitRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (culture === null || culture === undefined) {
            throw new Error('Required parameter culture was null or undefined when calling AddOrUpdateSaleEventBrandLimit.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<BooleanPurpleApiResponse>(`${this.configuration.basePath}/${encodeURIComponent(String(culture))}/api/BackOffice/AddOrUpdateSaleEventBrandLimit`,
            addOrUpdateSaleEventBrandLimitRequest,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param culture 
     * @param addOrUpdateSaleEventCarouselSlidesRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public addOrUpdateSaleEventCarouselSlides(culture: string, addOrUpdateSaleEventCarouselSlidesRequest?: AddOrUpdateSaleEventCarouselSlidesRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<BooleanPurpleApiResponse>;
    public addOrUpdateSaleEventCarouselSlides(culture: string, addOrUpdateSaleEventCarouselSlidesRequest?: AddOrUpdateSaleEventCarouselSlidesRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<BooleanPurpleApiResponse>>;
    public addOrUpdateSaleEventCarouselSlides(culture: string, addOrUpdateSaleEventCarouselSlidesRequest?: AddOrUpdateSaleEventCarouselSlidesRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<BooleanPurpleApiResponse>>;
    public addOrUpdateSaleEventCarouselSlides(culture: string, addOrUpdateSaleEventCarouselSlidesRequest?: AddOrUpdateSaleEventCarouselSlidesRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (culture === null || culture === undefined) {
            throw new Error('Required parameter culture was null or undefined when calling AddOrUpdateSaleEventCarouselSlides.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<BooleanPurpleApiResponse>(`${this.configuration.basePath}/${encodeURIComponent(String(culture))}/api/BackOffice/AddOrUpdateSaleEventCarouselSlides`,
            addOrUpdateSaleEventCarouselSlidesRequest,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param culture 
     * @param addOrUpdateSaleEventCategoryExceptionLimitRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public addOrUpdateSaleEventCategoryExceptionLimit(culture: string, addOrUpdateSaleEventCategoryExceptionLimitRequest?: AddOrUpdateSaleEventCategoryExceptionLimitRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<BooleanPurpleApiResponse>;
    public addOrUpdateSaleEventCategoryExceptionLimit(culture: string, addOrUpdateSaleEventCategoryExceptionLimitRequest?: AddOrUpdateSaleEventCategoryExceptionLimitRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<BooleanPurpleApiResponse>>;
    public addOrUpdateSaleEventCategoryExceptionLimit(culture: string, addOrUpdateSaleEventCategoryExceptionLimitRequest?: AddOrUpdateSaleEventCategoryExceptionLimitRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<BooleanPurpleApiResponse>>;
    public addOrUpdateSaleEventCategoryExceptionLimit(culture: string, addOrUpdateSaleEventCategoryExceptionLimitRequest?: AddOrUpdateSaleEventCategoryExceptionLimitRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (culture === null || culture === undefined) {
            throw new Error('Required parameter culture was null or undefined when calling AddOrUpdateSaleEventCategoryExceptionLimit.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<BooleanPurpleApiResponse>(`${this.configuration.basePath}/${encodeURIComponent(String(culture))}/api/BackOffice/AddOrUpdateSaleEventCategoryExceptionLimit`,
            addOrUpdateSaleEventCategoryExceptionLimitRequest,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param culture 
     * @param addOrUpdateSaleEventCategoryLimitRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public addOrUpdateSaleEventCategoryLimit(culture: string, addOrUpdateSaleEventCategoryLimitRequest?: AddOrUpdateSaleEventCategoryLimitRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<BooleanPurpleApiResponse>;
    public addOrUpdateSaleEventCategoryLimit(culture: string, addOrUpdateSaleEventCategoryLimitRequest?: AddOrUpdateSaleEventCategoryLimitRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<BooleanPurpleApiResponse>>;
    public addOrUpdateSaleEventCategoryLimit(culture: string, addOrUpdateSaleEventCategoryLimitRequest?: AddOrUpdateSaleEventCategoryLimitRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<BooleanPurpleApiResponse>>;
    public addOrUpdateSaleEventCategoryLimit(culture: string, addOrUpdateSaleEventCategoryLimitRequest?: AddOrUpdateSaleEventCategoryLimitRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (culture === null || culture === undefined) {
            throw new Error('Required parameter culture was null or undefined when calling AddOrUpdateSaleEventCategoryLimit.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<BooleanPurpleApiResponse>(`${this.configuration.basePath}/${encodeURIComponent(String(culture))}/api/BackOffice/AddOrUpdateSaleEventCategoryLimit`,
            addOrUpdateSaleEventCategoryLimitRequest,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param culture 
     * @param addOrUpdateSaleEventPolicyRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public addOrUpdateSaleEventPolicy(culture: string, addOrUpdateSaleEventPolicyRequest?: AddOrUpdateSaleEventPolicyRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<BooleanPurpleApiResponse>;
    public addOrUpdateSaleEventPolicy(culture: string, addOrUpdateSaleEventPolicyRequest?: AddOrUpdateSaleEventPolicyRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<BooleanPurpleApiResponse>>;
    public addOrUpdateSaleEventPolicy(culture: string, addOrUpdateSaleEventPolicyRequest?: AddOrUpdateSaleEventPolicyRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<BooleanPurpleApiResponse>>;
    public addOrUpdateSaleEventPolicy(culture: string, addOrUpdateSaleEventPolicyRequest?: AddOrUpdateSaleEventPolicyRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (culture === null || culture === undefined) {
            throw new Error('Required parameter culture was null or undefined when calling AddOrUpdateSaleEventPolicy.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<BooleanPurpleApiResponse>(`${this.configuration.basePath}/${encodeURIComponent(String(culture))}/api/BackOffice/AddOrUpdateSaleEventPolicy`,
            addOrUpdateSaleEventPolicyRequest,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param culture 
     * @param addOrUpdateSaleEventRoleInviteStartDateRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public addOrUpdateSaleEventRoleInviteStartDate(culture: string, addOrUpdateSaleEventRoleInviteStartDateRequest?: AddOrUpdateSaleEventRoleInviteStartDateRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<SaleEventRoleInviteStartDatePurpleApiResponse>;
    public addOrUpdateSaleEventRoleInviteStartDate(culture: string, addOrUpdateSaleEventRoleInviteStartDateRequest?: AddOrUpdateSaleEventRoleInviteStartDateRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<SaleEventRoleInviteStartDatePurpleApiResponse>>;
    public addOrUpdateSaleEventRoleInviteStartDate(culture: string, addOrUpdateSaleEventRoleInviteStartDateRequest?: AddOrUpdateSaleEventRoleInviteStartDateRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<SaleEventRoleInviteStartDatePurpleApiResponse>>;
    public addOrUpdateSaleEventRoleInviteStartDate(culture: string, addOrUpdateSaleEventRoleInviteStartDateRequest?: AddOrUpdateSaleEventRoleInviteStartDateRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (culture === null || culture === undefined) {
            throw new Error('Required parameter culture was null or undefined when calling AddOrUpdateSaleEventRoleInviteStartDate.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<SaleEventRoleInviteStartDatePurpleApiResponse>(`${this.configuration.basePath}/${encodeURIComponent(String(culture))}/api/BackOffice/AddOrUpdateSaleEventRoleInviteStartDate`,
            addOrUpdateSaleEventRoleInviteStartDateRequest,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param culture 
     * @param addOrUpdateSaleEventRoleStopDateRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public addOrUpdateSaleEventRoleStopDate(culture: string, addOrUpdateSaleEventRoleStopDateRequest?: AddOrUpdateSaleEventRoleStopDateRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<SaleEventRoleStopDatePurpleApiResponse>;
    public addOrUpdateSaleEventRoleStopDate(culture: string, addOrUpdateSaleEventRoleStopDateRequest?: AddOrUpdateSaleEventRoleStopDateRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<SaleEventRoleStopDatePurpleApiResponse>>;
    public addOrUpdateSaleEventRoleStopDate(culture: string, addOrUpdateSaleEventRoleStopDateRequest?: AddOrUpdateSaleEventRoleStopDateRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<SaleEventRoleStopDatePurpleApiResponse>>;
    public addOrUpdateSaleEventRoleStopDate(culture: string, addOrUpdateSaleEventRoleStopDateRequest?: AddOrUpdateSaleEventRoleStopDateRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (culture === null || culture === undefined) {
            throw new Error('Required parameter culture was null or undefined when calling AddOrUpdateSaleEventRoleStopDate.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<SaleEventRoleStopDatePurpleApiResponse>(`${this.configuration.basePath}/${encodeURIComponent(String(culture))}/api/BackOffice/AddOrUpdateSaleEventRoleStopDate`,
            addOrUpdateSaleEventRoleStopDateRequest,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param culture 
     * @param addOrUpdateSaleEventUserRoleRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public addOrUpdateSaleEventUserRole(culture: string, addOrUpdateSaleEventUserRoleRequest?: AddOrUpdateSaleEventUserRoleRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<BooleanPurpleApiResponse>;
    public addOrUpdateSaleEventUserRole(culture: string, addOrUpdateSaleEventUserRoleRequest?: AddOrUpdateSaleEventUserRoleRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<BooleanPurpleApiResponse>>;
    public addOrUpdateSaleEventUserRole(culture: string, addOrUpdateSaleEventUserRoleRequest?: AddOrUpdateSaleEventUserRoleRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<BooleanPurpleApiResponse>>;
    public addOrUpdateSaleEventUserRole(culture: string, addOrUpdateSaleEventUserRoleRequest?: AddOrUpdateSaleEventUserRoleRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (culture === null || culture === undefined) {
            throw new Error('Required parameter culture was null or undefined when calling AddOrUpdateSaleEventUserRole.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<BooleanPurpleApiResponse>(`${this.configuration.basePath}/${encodeURIComponent(String(culture))}/api/BackOffice/AddOrUpdateSaleEventUserRole`,
            addOrUpdateSaleEventUserRoleRequest,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param culture 
     * @param addOrUpdateSaleEventUserRolesRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public addOrUpdateSaleEventUserRoles(culture: string, addOrUpdateSaleEventUserRolesRequest?: AddOrUpdateSaleEventUserRolesRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<BooleanPurpleApiResponse>;
    public addOrUpdateSaleEventUserRoles(culture: string, addOrUpdateSaleEventUserRolesRequest?: AddOrUpdateSaleEventUserRolesRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<BooleanPurpleApiResponse>>;
    public addOrUpdateSaleEventUserRoles(culture: string, addOrUpdateSaleEventUserRolesRequest?: AddOrUpdateSaleEventUserRolesRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<BooleanPurpleApiResponse>>;
    public addOrUpdateSaleEventUserRoles(culture: string, addOrUpdateSaleEventUserRolesRequest?: AddOrUpdateSaleEventUserRolesRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (culture === null || culture === undefined) {
            throw new Error('Required parameter culture was null or undefined when calling AddOrUpdateSaleEventUserRoles.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<BooleanPurpleApiResponse>(`${this.configuration.basePath}/${encodeURIComponent(String(culture))}/api/BackOffice/AddOrUpdateSaleEventUserRoles`,
            addOrUpdateSaleEventUserRolesRequest,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param culture 
     * @param addOrUpdateSmtpRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public addOrUpdateSmtp(culture: string, addOrUpdateSmtpRequest?: AddOrUpdateSmtpRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<BooleanPurpleApiResponse>;
    public addOrUpdateSmtp(culture: string, addOrUpdateSmtpRequest?: AddOrUpdateSmtpRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<BooleanPurpleApiResponse>>;
    public addOrUpdateSmtp(culture: string, addOrUpdateSmtpRequest?: AddOrUpdateSmtpRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<BooleanPurpleApiResponse>>;
    public addOrUpdateSmtp(culture: string, addOrUpdateSmtpRequest?: AddOrUpdateSmtpRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (culture === null || culture === undefined) {
            throw new Error('Required parameter culture was null or undefined when calling AddOrUpdateSmtp.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<BooleanPurpleApiResponse>(`${this.configuration.basePath}/${encodeURIComponent(String(culture))}/api/BackOffice/AddOrUpdateSmtp`,
            addOrUpdateSmtpRequest,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param culture 
     * @param addOrUpdateSmtpServerRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public addOrUpdateSmtpServer(culture: string, addOrUpdateSmtpServerRequest?: AddOrUpdateSmtpServerRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<SmtpServerPurpleApiResponse>;
    public addOrUpdateSmtpServer(culture: string, addOrUpdateSmtpServerRequest?: AddOrUpdateSmtpServerRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<SmtpServerPurpleApiResponse>>;
    public addOrUpdateSmtpServer(culture: string, addOrUpdateSmtpServerRequest?: AddOrUpdateSmtpServerRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<SmtpServerPurpleApiResponse>>;
    public addOrUpdateSmtpServer(culture: string, addOrUpdateSmtpServerRequest?: AddOrUpdateSmtpServerRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (culture === null || culture === undefined) {
            throw new Error('Required parameter culture was null or undefined when calling AddOrUpdateSmtpServer.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<SmtpServerPurpleApiResponse>(`${this.configuration.basePath}/${encodeURIComponent(String(culture))}/api/BackOffice/AddOrUpdateSmtpServer`,
            addOrUpdateSmtpServerRequest,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param culture 
     * @param addOrUpdateUserAddressRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public addOrUpdateUserAddress(culture: string, addOrUpdateUserAddressRequest?: AddOrUpdateUserAddressRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<BooleanPurpleApiResponse>;
    public addOrUpdateUserAddress(culture: string, addOrUpdateUserAddressRequest?: AddOrUpdateUserAddressRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<BooleanPurpleApiResponse>>;
    public addOrUpdateUserAddress(culture: string, addOrUpdateUserAddressRequest?: AddOrUpdateUserAddressRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<BooleanPurpleApiResponse>>;
    public addOrUpdateUserAddress(culture: string, addOrUpdateUserAddressRequest?: AddOrUpdateUserAddressRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (culture === null || culture === undefined) {
            throw new Error('Required parameter culture was null or undefined when calling AddOrUpdateUserAddress.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<BooleanPurpleApiResponse>(`${this.configuration.basePath}/${encodeURIComponent(String(culture))}/api/BackOffice/AddOrUpdateUserAddress`,
            addOrUpdateUserAddressRequest,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param culture 
     * @param addOrUpdateUserRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public addOrUpdateUser(culture: string, addOrUpdateUserRequest?: AddOrUpdateUserRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<UserPurpleApiResponse>;
    public addOrUpdateUser(culture: string, addOrUpdateUserRequest?: AddOrUpdateUserRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<UserPurpleApiResponse>>;
    public addOrUpdateUser(culture: string, addOrUpdateUserRequest?: AddOrUpdateUserRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<UserPurpleApiResponse>>;
    public addOrUpdateUser(culture: string, addOrUpdateUserRequest?: AddOrUpdateUserRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (culture === null || culture === undefined) {
            throw new Error('Required parameter culture was null or undefined when calling AddOrUpdateUser.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<UserPurpleApiResponse>(`${this.configuration.basePath}/${encodeURIComponent(String(culture))}/api/BackOffice/AddOrUpdateUser`,
            addOrUpdateUserRequest,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param culture 
     * @param addRoleFromSlotEditRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public addRoleFromSlotEdit(culture: string, addRoleFromSlotEditRequest?: AddRoleFromSlotEditRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<DateSlotRolePurpleApiResponse>;
    public addRoleFromSlotEdit(culture: string, addRoleFromSlotEditRequest?: AddRoleFromSlotEditRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<DateSlotRolePurpleApiResponse>>;
    public addRoleFromSlotEdit(culture: string, addRoleFromSlotEditRequest?: AddRoleFromSlotEditRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<DateSlotRolePurpleApiResponse>>;
    public addRoleFromSlotEdit(culture: string, addRoleFromSlotEditRequest?: AddRoleFromSlotEditRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (culture === null || culture === undefined) {
            throw new Error('Required parameter culture was null or undefined when calling AddRoleFromSlotEdit.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<DateSlotRolePurpleApiResponse>(`${this.configuration.basePath}/${encodeURIComponent(String(culture))}/api/BackOffice/AddRoleFromSlotEdit`,
            addRoleFromSlotEditRequest,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param culture 
     * @param apiAddRoleReferralRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public addRoleReferral(culture: string, apiAddRoleReferralRequest?: ApiAddRoleReferralRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<BooleanPurpleApiResponse>;
    public addRoleReferral(culture: string, apiAddRoleReferralRequest?: ApiAddRoleReferralRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<BooleanPurpleApiResponse>>;
    public addRoleReferral(culture: string, apiAddRoleReferralRequest?: ApiAddRoleReferralRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<BooleanPurpleApiResponse>>;
    public addRoleReferral(culture: string, apiAddRoleReferralRequest?: ApiAddRoleReferralRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (culture === null || culture === undefined) {
            throw new Error('Required parameter culture was null or undefined when calling AddRoleReferral.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<BooleanPurpleApiResponse>(`${this.configuration.basePath}/${encodeURIComponent(String(culture))}/api/BackOffice/AddRoleReferral`,
            apiAddRoleReferralRequest,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param culture 
     * @param addSystemEventSaleEventMailTemplateExceptionRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public addSystemEventSaleEventMailTemplateException(culture: string, addSystemEventSaleEventMailTemplateExceptionRequest?: AddSystemEventSaleEventMailTemplateExceptionRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<BooleanPurpleApiResponse>;
    public addSystemEventSaleEventMailTemplateException(culture: string, addSystemEventSaleEventMailTemplateExceptionRequest?: AddSystemEventSaleEventMailTemplateExceptionRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<BooleanPurpleApiResponse>>;
    public addSystemEventSaleEventMailTemplateException(culture: string, addSystemEventSaleEventMailTemplateExceptionRequest?: AddSystemEventSaleEventMailTemplateExceptionRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<BooleanPurpleApiResponse>>;
    public addSystemEventSaleEventMailTemplateException(culture: string, addSystemEventSaleEventMailTemplateExceptionRequest?: AddSystemEventSaleEventMailTemplateExceptionRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (culture === null || culture === undefined) {
            throw new Error('Required parameter culture was null or undefined when calling AddSystemEventSaleEventMailTemplateException.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<BooleanPurpleApiResponse>(`${this.configuration.basePath}/${encodeURIComponent(String(culture))}/api/BackOffice/AddSystemEventSaleEventMailTemplateException`,
            addSystemEventSaleEventMailTemplateExceptionRequest,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param culture 
     * @param apiAddUserRoleReferralRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public addUserRoleReferral(culture: string, apiAddUserRoleReferralRequest?: ApiAddUserRoleReferralRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<BooleanPurpleApiResponse>;
    public addUserRoleReferral(culture: string, apiAddUserRoleReferralRequest?: ApiAddUserRoleReferralRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<BooleanPurpleApiResponse>>;
    public addUserRoleReferral(culture: string, apiAddUserRoleReferralRequest?: ApiAddUserRoleReferralRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<BooleanPurpleApiResponse>>;
    public addUserRoleReferral(culture: string, apiAddUserRoleReferralRequest?: ApiAddUserRoleReferralRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (culture === null || culture === undefined) {
            throw new Error('Required parameter culture was null or undefined when calling AddUserRoleReferral.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<BooleanPurpleApiResponse>(`${this.configuration.basePath}/${encodeURIComponent(String(culture))}/api/BackOffice/AddUserRoleReferral`,
            apiAddUserRoleReferralRequest,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param culture 
     * @param adminVerifyUserEmailRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public adminVerifyUserEmail(culture: string, adminVerifyUserEmailRequest?: AdminVerifyUserEmailRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<BooleanPurpleApiResponse>;
    public adminVerifyUserEmail(culture: string, adminVerifyUserEmailRequest?: AdminVerifyUserEmailRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<BooleanPurpleApiResponse>>;
    public adminVerifyUserEmail(culture: string, adminVerifyUserEmailRequest?: AdminVerifyUserEmailRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<BooleanPurpleApiResponse>>;
    public adminVerifyUserEmail(culture: string, adminVerifyUserEmailRequest?: AdminVerifyUserEmailRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (culture === null || culture === undefined) {
            throw new Error('Required parameter culture was null or undefined when calling AdminVerifyUserEmail.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<BooleanPurpleApiResponse>(`${this.configuration.basePath}/${encodeURIComponent(String(culture))}/api/BackOffice/AdminVerifyUserEmail`,
            adminVerifyUserEmailRequest,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param culture 
     * @param adminVerifyUserPhoneRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public adminVerifyUserPhone(culture: string, adminVerifyUserPhoneRequest?: AdminVerifyUserPhoneRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<BooleanPurpleApiResponse>;
    public adminVerifyUserPhone(culture: string, adminVerifyUserPhoneRequest?: AdminVerifyUserPhoneRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<BooleanPurpleApiResponse>>;
    public adminVerifyUserPhone(culture: string, adminVerifyUserPhoneRequest?: AdminVerifyUserPhoneRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<BooleanPurpleApiResponse>>;
    public adminVerifyUserPhone(culture: string, adminVerifyUserPhoneRequest?: AdminVerifyUserPhoneRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (culture === null || culture === undefined) {
            throw new Error('Required parameter culture was null or undefined when calling AdminVerifyUserPhone.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<BooleanPurpleApiResponse>(`${this.configuration.basePath}/${encodeURIComponent(String(culture))}/api/BackOffice/AdminVerifyUserPhone`,
            adminVerifyUserPhoneRequest,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param culture 
     * @param anonymizeUserRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public anonymizeUser(culture: string, anonymizeUserRequest?: AnonymizeUserRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<BooleanPurpleApiResponse>;
    public anonymizeUser(culture: string, anonymizeUserRequest?: AnonymizeUserRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<BooleanPurpleApiResponse>>;
    public anonymizeUser(culture: string, anonymizeUserRequest?: AnonymizeUserRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<BooleanPurpleApiResponse>>;
    public anonymizeUser(culture: string, anonymizeUserRequest?: AnonymizeUserRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (culture === null || culture === undefined) {
            throw new Error('Required parameter culture was null or undefined when calling AnonymizeUser.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<BooleanPurpleApiResponse>(`${this.configuration.basePath}/${encodeURIComponent(String(culture))}/api/BackOffice/AnonymizeUser`,
            anonymizeUserRequest,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param culture 
     * @param anonymizeUsersByOwnerIdRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public anonymizeUsersByOwnerId(culture: string, anonymizeUsersByOwnerIdRequest?: AnonymizeUsersByOwnerIdRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<PurpleApiResponse>;
    public anonymizeUsersByOwnerId(culture: string, anonymizeUsersByOwnerIdRequest?: AnonymizeUsersByOwnerIdRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<PurpleApiResponse>>;
    public anonymizeUsersByOwnerId(culture: string, anonymizeUsersByOwnerIdRequest?: AnonymizeUsersByOwnerIdRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<PurpleApiResponse>>;
    public anonymizeUsersByOwnerId(culture: string, anonymizeUsersByOwnerIdRequest?: AnonymizeUsersByOwnerIdRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (culture === null || culture === undefined) {
            throw new Error('Required parameter culture was null or undefined when calling AnonymizeUsersByOwnerId.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<PurpleApiResponse>(`${this.configuration.basePath}/${encodeURIComponent(String(culture))}/api/BackOffice/AnonymizeUsersByOwnerId`,
            anonymizeUsersByOwnerIdRequest,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param culture 
     * @param bookSlotAdminRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public bookSlotAdmin(culture: string, bookSlotAdminRequest?: BookSlotAdminRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<BooleanPurpleApiResponse>;
    public bookSlotAdmin(culture: string, bookSlotAdminRequest?: BookSlotAdminRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<BooleanPurpleApiResponse>>;
    public bookSlotAdmin(culture: string, bookSlotAdminRequest?: BookSlotAdminRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<BooleanPurpleApiResponse>>;
    public bookSlotAdmin(culture: string, bookSlotAdminRequest?: BookSlotAdminRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (culture === null || culture === undefined) {
            throw new Error('Required parameter culture was null or undefined when calling BookSlotAdmin.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<BooleanPurpleApiResponse>(`${this.configuration.basePath}/${encodeURIComponent(String(culture))}/api/BackOffice/BookSlotAdmin`,
            bookSlotAdminRequest,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param culture 
     * @param changeUserEmailRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public changeUserEmail(culture: string, changeUserEmailRequest?: ChangeUserEmailRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<PurpleApiResponse>;
    public changeUserEmail(culture: string, changeUserEmailRequest?: ChangeUserEmailRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<PurpleApiResponse>>;
    public changeUserEmail(culture: string, changeUserEmailRequest?: ChangeUserEmailRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<PurpleApiResponse>>;
    public changeUserEmail(culture: string, changeUserEmailRequest?: ChangeUserEmailRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (culture === null || culture === undefined) {
            throw new Error('Required parameter culture was null or undefined when calling ChangeUserEmail.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<PurpleApiResponse>(`${this.configuration.basePath}/${encodeURIComponent(String(culture))}/api/BackOffice/ChangeUserEmail`,
            changeUserEmailRequest,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param culture 
     * @param checkIfRoleIsUsedRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public checkIfRoleIsUsed(culture: string, checkIfRoleIsUsedRequest?: CheckIfRoleIsUsedRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<ApiTreeItemListPurpleApiResponse>;
    public checkIfRoleIsUsed(culture: string, checkIfRoleIsUsedRequest?: CheckIfRoleIsUsedRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<ApiTreeItemListPurpleApiResponse>>;
    public checkIfRoleIsUsed(culture: string, checkIfRoleIsUsedRequest?: CheckIfRoleIsUsedRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<ApiTreeItemListPurpleApiResponse>>;
    public checkIfRoleIsUsed(culture: string, checkIfRoleIsUsedRequest?: CheckIfRoleIsUsedRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (culture === null || culture === undefined) {
            throw new Error('Required parameter culture was null or undefined when calling CheckIfRoleIsUsed.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<ApiTreeItemListPurpleApiResponse>(`${this.configuration.basePath}/${encodeURIComponent(String(culture))}/api/BackOffice/CheckIfRoleIsUsed`,
            checkIfRoleIsUsedRequest,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param culture 
     * @param checkIfSlotIsFullRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public checkIfSlotIsFull(culture: string, checkIfSlotIsFullRequest?: CheckIfSlotIsFullRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<BooleanPurpleApiResponse>;
    public checkIfSlotIsFull(culture: string, checkIfSlotIsFullRequest?: CheckIfSlotIsFullRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<BooleanPurpleApiResponse>>;
    public checkIfSlotIsFull(culture: string, checkIfSlotIsFullRequest?: CheckIfSlotIsFullRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<BooleanPurpleApiResponse>>;
    public checkIfSlotIsFull(culture: string, checkIfSlotIsFullRequest?: CheckIfSlotIsFullRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (culture === null || culture === undefined) {
            throw new Error('Required parameter culture was null or undefined when calling CheckIfSlotIsFull.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<BooleanPurpleApiResponse>(`${this.configuration.basePath}/${encodeURIComponent(String(culture))}/api/BackOffice/CheckIfSlotIsFull`,
            checkIfSlotIsFullRequest,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param culture 
     * @param cloneDateSlotRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public cloneDateSlot(culture: string, cloneDateSlotRequest?: CloneDateSlotRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<BooleanPurpleApiResponse>;
    public cloneDateSlot(culture: string, cloneDateSlotRequest?: CloneDateSlotRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<BooleanPurpleApiResponse>>;
    public cloneDateSlot(culture: string, cloneDateSlotRequest?: CloneDateSlotRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<BooleanPurpleApiResponse>>;
    public cloneDateSlot(culture: string, cloneDateSlotRequest?: CloneDateSlotRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (culture === null || culture === undefined) {
            throw new Error('Required parameter culture was null or undefined when calling CloneDateSlot.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<BooleanPurpleApiResponse>(`${this.configuration.basePath}/${encodeURIComponent(String(culture))}/api/BackOffice/CloneDateSlot`,
            cloneDateSlotRequest,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param culture 
     * @param apiCountReferralRedeemsRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public countReferralRedeems(culture: string, apiCountReferralRedeemsRequest?: ApiCountReferralRedeemsRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Int32PurpleApiResponse>;
    public countReferralRedeems(culture: string, apiCountReferralRedeemsRequest?: ApiCountReferralRedeemsRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Int32PurpleApiResponse>>;
    public countReferralRedeems(culture: string, apiCountReferralRedeemsRequest?: ApiCountReferralRedeemsRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Int32PurpleApiResponse>>;
    public countReferralRedeems(culture: string, apiCountReferralRedeemsRequest?: ApiCountReferralRedeemsRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (culture === null || culture === undefined) {
            throw new Error('Required parameter culture was null or undefined when calling CountReferralRedeems.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<Int32PurpleApiResponse>(`${this.configuration.basePath}/${encodeURIComponent(String(culture))}/api/BackOffice/CountReferralRedeems`,
            apiCountReferralRedeemsRequest,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param culture 
     * @param apiCreateNewSaleEventRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createNewSaleEvent(culture: string, apiCreateNewSaleEventRequest?: ApiCreateNewSaleEventRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<PRPVSaleEventFullNullablePurpleApiResponse>;
    public createNewSaleEvent(culture: string, apiCreateNewSaleEventRequest?: ApiCreateNewSaleEventRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<PRPVSaleEventFullNullablePurpleApiResponse>>;
    public createNewSaleEvent(culture: string, apiCreateNewSaleEventRequest?: ApiCreateNewSaleEventRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<PRPVSaleEventFullNullablePurpleApiResponse>>;
    public createNewSaleEvent(culture: string, apiCreateNewSaleEventRequest?: ApiCreateNewSaleEventRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (culture === null || culture === undefined) {
            throw new Error('Required parameter culture was null or undefined when calling CreateNewSaleEvent.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<PRPVSaleEventFullNullablePurpleApiResponse>(`${this.configuration.basePath}/${encodeURIComponent(String(culture))}/api/BackOffice/CreateNewSaleEvent`,
            apiCreateNewSaleEventRequest,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param culture 
     * @param createOrUpdateSaleEventDateDaySlotRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createOrUpdateSaleEventDateDaySlot(culture: string, createOrUpdateSaleEventDateDaySlotRequest?: CreateOrUpdateSaleEventDateDaySlotRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<SaleEventDaySlotItemPurpleApiResponse>;
    public createOrUpdateSaleEventDateDaySlot(culture: string, createOrUpdateSaleEventDateDaySlotRequest?: CreateOrUpdateSaleEventDateDaySlotRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<SaleEventDaySlotItemPurpleApiResponse>>;
    public createOrUpdateSaleEventDateDaySlot(culture: string, createOrUpdateSaleEventDateDaySlotRequest?: CreateOrUpdateSaleEventDateDaySlotRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<SaleEventDaySlotItemPurpleApiResponse>>;
    public createOrUpdateSaleEventDateDaySlot(culture: string, createOrUpdateSaleEventDateDaySlotRequest?: CreateOrUpdateSaleEventDateDaySlotRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (culture === null || culture === undefined) {
            throw new Error('Required parameter culture was null or undefined when calling CreateOrUpdateSaleEventDateDaySlot.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<SaleEventDaySlotItemPurpleApiResponse>(`${this.configuration.basePath}/${encodeURIComponent(String(culture))}/api/BackOffice/CreateOrUpdateSaleEventDateDaySlot`,
            createOrUpdateSaleEventDateDaySlotRequest,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param culture 
     * @param deleteCouponBaseRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteCouponBase(culture: string, deleteCouponBaseRequest?: DeleteCouponBaseRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<BooleanPurpleApiResponse>;
    public deleteCouponBase(culture: string, deleteCouponBaseRequest?: DeleteCouponBaseRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<BooleanPurpleApiResponse>>;
    public deleteCouponBase(culture: string, deleteCouponBaseRequest?: DeleteCouponBaseRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<BooleanPurpleApiResponse>>;
    public deleteCouponBase(culture: string, deleteCouponBaseRequest?: DeleteCouponBaseRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (culture === null || culture === undefined) {
            throw new Error('Required parameter culture was null or undefined when calling DeleteCouponBase.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<BooleanPurpleApiResponse>(`${this.configuration.basePath}/${encodeURIComponent(String(culture))}/api/BackOffice/DeleteCouponBase`,
            deleteCouponBaseRequest,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param culture 
     * @param deleteEmployeeRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteEmployee(culture: string, deleteEmployeeRequest?: DeleteEmployeeRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<BooleanPurpleApiResponse>;
    public deleteEmployee(culture: string, deleteEmployeeRequest?: DeleteEmployeeRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<BooleanPurpleApiResponse>>;
    public deleteEmployee(culture: string, deleteEmployeeRequest?: DeleteEmployeeRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<BooleanPurpleApiResponse>>;
    public deleteEmployee(culture: string, deleteEmployeeRequest?: DeleteEmployeeRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (culture === null || culture === undefined) {
            throw new Error('Required parameter culture was null or undefined when calling DeleteEmployee.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<BooleanPurpleApiResponse>(`${this.configuration.basePath}/${encodeURIComponent(String(culture))}/api/BackOffice/DeleteEmployee`,
            deleteEmployeeRequest,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param culture 
     * @param deleteMailTemplateBaseRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteMailTemplateBase(culture: string, deleteMailTemplateBaseRequest?: DeleteMailTemplateBaseRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<BooleanPurpleApiResponse>;
    public deleteMailTemplateBase(culture: string, deleteMailTemplateBaseRequest?: DeleteMailTemplateBaseRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<BooleanPurpleApiResponse>>;
    public deleteMailTemplateBase(culture: string, deleteMailTemplateBaseRequest?: DeleteMailTemplateBaseRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<BooleanPurpleApiResponse>>;
    public deleteMailTemplateBase(culture: string, deleteMailTemplateBaseRequest?: DeleteMailTemplateBaseRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (culture === null || culture === undefined) {
            throw new Error('Required parameter culture was null or undefined when calling DeleteMailTemplateBase.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<BooleanPurpleApiResponse>(`${this.configuration.basePath}/${encodeURIComponent(String(culture))}/api/BackOffice/DeleteMailTemplateBase`,
            deleteMailTemplateBaseRequest,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param culture 
     * @param deleteRoleRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteRole(culture: string, deleteRoleRequest?: DeleteRoleRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<BooleanPurpleApiResponse>;
    public deleteRole(culture: string, deleteRoleRequest?: DeleteRoleRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<BooleanPurpleApiResponse>>;
    public deleteRole(culture: string, deleteRoleRequest?: DeleteRoleRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<BooleanPurpleApiResponse>>;
    public deleteRole(culture: string, deleteRoleRequest?: DeleteRoleRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (culture === null || culture === undefined) {
            throw new Error('Required parameter culture was null or undefined when calling DeleteRole.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<BooleanPurpleApiResponse>(`${this.configuration.basePath}/${encodeURIComponent(String(culture))}/api/BackOffice/DeleteRole`,
            deleteRoleRequest,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param culture 
     * @param apiDeleteRoleReferralRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteRoleReferral(culture: string, apiDeleteRoleReferralRequest?: ApiDeleteRoleReferralRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<BooleanPurpleApiResponse>;
    public deleteRoleReferral(culture: string, apiDeleteRoleReferralRequest?: ApiDeleteRoleReferralRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<BooleanPurpleApiResponse>>;
    public deleteRoleReferral(culture: string, apiDeleteRoleReferralRequest?: ApiDeleteRoleReferralRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<BooleanPurpleApiResponse>>;
    public deleteRoleReferral(culture: string, apiDeleteRoleReferralRequest?: ApiDeleteRoleReferralRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (culture === null || culture === undefined) {
            throw new Error('Required parameter culture was null or undefined when calling DeleteRoleReferral.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<BooleanPurpleApiResponse>(`${this.configuration.basePath}/${encodeURIComponent(String(culture))}/api/BackOffice/DeleteRoleReferral`,
            apiDeleteRoleReferralRequest,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param culture 
     * @param deleteSaleEventBrandLimitRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteSaleEventBrandLimit(culture: string, deleteSaleEventBrandLimitRequest?: DeleteSaleEventBrandLimitRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<BooleanPurpleApiResponse>;
    public deleteSaleEventBrandLimit(culture: string, deleteSaleEventBrandLimitRequest?: DeleteSaleEventBrandLimitRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<BooleanPurpleApiResponse>>;
    public deleteSaleEventBrandLimit(culture: string, deleteSaleEventBrandLimitRequest?: DeleteSaleEventBrandLimitRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<BooleanPurpleApiResponse>>;
    public deleteSaleEventBrandLimit(culture: string, deleteSaleEventBrandLimitRequest?: DeleteSaleEventBrandLimitRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (culture === null || culture === undefined) {
            throw new Error('Required parameter culture was null or undefined when calling DeleteSaleEventBrandLimit.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<BooleanPurpleApiResponse>(`${this.configuration.basePath}/${encodeURIComponent(String(culture))}/api/BackOffice/DeleteSaleEventBrandLimit`,
            deleteSaleEventBrandLimitRequest,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param culture 
     * @param deleteSaleEventCarouselSlideRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteSaleEventCarouselSlide(culture: string, deleteSaleEventCarouselSlideRequest?: DeleteSaleEventCarouselSlideRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<BooleanPurpleApiResponse>;
    public deleteSaleEventCarouselSlide(culture: string, deleteSaleEventCarouselSlideRequest?: DeleteSaleEventCarouselSlideRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<BooleanPurpleApiResponse>>;
    public deleteSaleEventCarouselSlide(culture: string, deleteSaleEventCarouselSlideRequest?: DeleteSaleEventCarouselSlideRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<BooleanPurpleApiResponse>>;
    public deleteSaleEventCarouselSlide(culture: string, deleteSaleEventCarouselSlideRequest?: DeleteSaleEventCarouselSlideRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (culture === null || culture === undefined) {
            throw new Error('Required parameter culture was null or undefined when calling DeleteSaleEventCarouselSlide.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<BooleanPurpleApiResponse>(`${this.configuration.basePath}/${encodeURIComponent(String(culture))}/api/BackOffice/DeleteSaleEventCarouselSlide`,
            deleteSaleEventCarouselSlideRequest,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param culture 
     * @param deleteSaleEventCategoryExceptionLimitRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteSaleEventCategoryExceptionLimit(culture: string, deleteSaleEventCategoryExceptionLimitRequest?: DeleteSaleEventCategoryExceptionLimitRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<BooleanPurpleApiResponse>;
    public deleteSaleEventCategoryExceptionLimit(culture: string, deleteSaleEventCategoryExceptionLimitRequest?: DeleteSaleEventCategoryExceptionLimitRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<BooleanPurpleApiResponse>>;
    public deleteSaleEventCategoryExceptionLimit(culture: string, deleteSaleEventCategoryExceptionLimitRequest?: DeleteSaleEventCategoryExceptionLimitRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<BooleanPurpleApiResponse>>;
    public deleteSaleEventCategoryExceptionLimit(culture: string, deleteSaleEventCategoryExceptionLimitRequest?: DeleteSaleEventCategoryExceptionLimitRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (culture === null || culture === undefined) {
            throw new Error('Required parameter culture was null or undefined when calling DeleteSaleEventCategoryExceptionLimit.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<BooleanPurpleApiResponse>(`${this.configuration.basePath}/${encodeURIComponent(String(culture))}/api/BackOffice/DeleteSaleEventCategoryExceptionLimit`,
            deleteSaleEventCategoryExceptionLimitRequest,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param culture 
     * @param deleteSaleEventCategoryLimitRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteSaleEventCategoryLimit(culture: string, deleteSaleEventCategoryLimitRequest?: DeleteSaleEventCategoryLimitRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<BooleanPurpleApiResponse>;
    public deleteSaleEventCategoryLimit(culture: string, deleteSaleEventCategoryLimitRequest?: DeleteSaleEventCategoryLimitRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<BooleanPurpleApiResponse>>;
    public deleteSaleEventCategoryLimit(culture: string, deleteSaleEventCategoryLimitRequest?: DeleteSaleEventCategoryLimitRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<BooleanPurpleApiResponse>>;
    public deleteSaleEventCategoryLimit(culture: string, deleteSaleEventCategoryLimitRequest?: DeleteSaleEventCategoryLimitRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (culture === null || culture === undefined) {
            throw new Error('Required parameter culture was null or undefined when calling DeleteSaleEventCategoryLimit.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<BooleanPurpleApiResponse>(`${this.configuration.basePath}/${encodeURIComponent(String(culture))}/api/BackOffice/DeleteSaleEventCategoryLimit`,
            deleteSaleEventCategoryLimitRequest,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param culture 
     * @param deleteSaleEventPolicyRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteSaleEventPolicy(culture: string, deleteSaleEventPolicyRequest?: DeleteSaleEventPolicyRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<BooleanPurpleApiResponse>;
    public deleteSaleEventPolicy(culture: string, deleteSaleEventPolicyRequest?: DeleteSaleEventPolicyRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<BooleanPurpleApiResponse>>;
    public deleteSaleEventPolicy(culture: string, deleteSaleEventPolicyRequest?: DeleteSaleEventPolicyRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<BooleanPurpleApiResponse>>;
    public deleteSaleEventPolicy(culture: string, deleteSaleEventPolicyRequest?: DeleteSaleEventPolicyRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (culture === null || culture === undefined) {
            throw new Error('Required parameter culture was null or undefined when calling DeleteSaleEventPolicy.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<BooleanPurpleApiResponse>(`${this.configuration.basePath}/${encodeURIComponent(String(culture))}/api/BackOffice/DeleteSaleEventPolicy`,
            deleteSaleEventPolicyRequest,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param culture 
     * @param deleteSaleEventRoleInviteStartDateRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteSaleEventRoleInviteStartDate(culture: string, deleteSaleEventRoleInviteStartDateRequest?: DeleteSaleEventRoleInviteStartDateRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<BooleanPurpleApiResponse>;
    public deleteSaleEventRoleInviteStartDate(culture: string, deleteSaleEventRoleInviteStartDateRequest?: DeleteSaleEventRoleInviteStartDateRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<BooleanPurpleApiResponse>>;
    public deleteSaleEventRoleInviteStartDate(culture: string, deleteSaleEventRoleInviteStartDateRequest?: DeleteSaleEventRoleInviteStartDateRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<BooleanPurpleApiResponse>>;
    public deleteSaleEventRoleInviteStartDate(culture: string, deleteSaleEventRoleInviteStartDateRequest?: DeleteSaleEventRoleInviteStartDateRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (culture === null || culture === undefined) {
            throw new Error('Required parameter culture was null or undefined when calling DeleteSaleEventRoleInviteStartDate.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<BooleanPurpleApiResponse>(`${this.configuration.basePath}/${encodeURIComponent(String(culture))}/api/BackOffice/DeleteSaleEventRoleInviteStartDate`,
            deleteSaleEventRoleInviteStartDateRequest,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param culture 
     * @param deleteSaleEventRoleStopDateRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteSaleEventRoleStopDate(culture: string, deleteSaleEventRoleStopDateRequest?: DeleteSaleEventRoleStopDateRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<BooleanPurpleApiResponse>;
    public deleteSaleEventRoleStopDate(culture: string, deleteSaleEventRoleStopDateRequest?: DeleteSaleEventRoleStopDateRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<BooleanPurpleApiResponse>>;
    public deleteSaleEventRoleStopDate(culture: string, deleteSaleEventRoleStopDateRequest?: DeleteSaleEventRoleStopDateRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<BooleanPurpleApiResponse>>;
    public deleteSaleEventRoleStopDate(culture: string, deleteSaleEventRoleStopDateRequest?: DeleteSaleEventRoleStopDateRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (culture === null || culture === undefined) {
            throw new Error('Required parameter culture was null or undefined when calling DeleteSaleEventRoleStopDate.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<BooleanPurpleApiResponse>(`${this.configuration.basePath}/${encodeURIComponent(String(culture))}/api/BackOffice/DeleteSaleEventRoleStopDate`,
            deleteSaleEventRoleStopDateRequest,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param culture 
     * @param deleteSaleEventSlotFromSlotEditRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteSaleEventSlotFromSlotEdit(culture: string, deleteSaleEventSlotFromSlotEditRequest?: DeleteSaleEventSlotFromSlotEditRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<SaleEventDaySlotItemListPurpleApiResponse>;
    public deleteSaleEventSlotFromSlotEdit(culture: string, deleteSaleEventSlotFromSlotEditRequest?: DeleteSaleEventSlotFromSlotEditRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<SaleEventDaySlotItemListPurpleApiResponse>>;
    public deleteSaleEventSlotFromSlotEdit(culture: string, deleteSaleEventSlotFromSlotEditRequest?: DeleteSaleEventSlotFromSlotEditRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<SaleEventDaySlotItemListPurpleApiResponse>>;
    public deleteSaleEventSlotFromSlotEdit(culture: string, deleteSaleEventSlotFromSlotEditRequest?: DeleteSaleEventSlotFromSlotEditRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (culture === null || culture === undefined) {
            throw new Error('Required parameter culture was null or undefined when calling DeleteSaleEventSlotFromSlotEdit.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<SaleEventDaySlotItemListPurpleApiResponse>(`${this.configuration.basePath}/${encodeURIComponent(String(culture))}/api/BackOffice/DeleteSaleEventSlotFromSlotEdit`,
            deleteSaleEventSlotFromSlotEditRequest,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param culture 
     * @param deleteSaleEventSlotRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteSaleEventSlot(culture: string, deleteSaleEventSlotRequest?: DeleteSaleEventSlotRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<BooleanPurpleApiResponse>;
    public deleteSaleEventSlot(culture: string, deleteSaleEventSlotRequest?: DeleteSaleEventSlotRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<BooleanPurpleApiResponse>>;
    public deleteSaleEventSlot(culture: string, deleteSaleEventSlotRequest?: DeleteSaleEventSlotRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<BooleanPurpleApiResponse>>;
    public deleteSaleEventSlot(culture: string, deleteSaleEventSlotRequest?: DeleteSaleEventSlotRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (culture === null || culture === undefined) {
            throw new Error('Required parameter culture was null or undefined when calling DeleteSaleEventSlot.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<BooleanPurpleApiResponse>(`${this.configuration.basePath}/${encodeURIComponent(String(culture))}/api/BackOffice/DeleteSaleEventSlot`,
            deleteSaleEventSlotRequest,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param culture 
     * @param deleteSaleEventUserRoleRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteSaleEventUserRole(culture: string, deleteSaleEventUserRoleRequest?: DeleteSaleEventUserRoleRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<BooleanPurpleApiResponse>;
    public deleteSaleEventUserRole(culture: string, deleteSaleEventUserRoleRequest?: DeleteSaleEventUserRoleRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<BooleanPurpleApiResponse>>;
    public deleteSaleEventUserRole(culture: string, deleteSaleEventUserRoleRequest?: DeleteSaleEventUserRoleRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<BooleanPurpleApiResponse>>;
    public deleteSaleEventUserRole(culture: string, deleteSaleEventUserRoleRequest?: DeleteSaleEventUserRoleRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (culture === null || culture === undefined) {
            throw new Error('Required parameter culture was null or undefined when calling DeleteSaleEventUserRole.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<BooleanPurpleApiResponse>(`${this.configuration.basePath}/${encodeURIComponent(String(culture))}/api/BackOffice/DeleteSaleEventUserRole`,
            deleteSaleEventUserRoleRequest,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param culture 
     * @param deleteSmtpServerRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteSmtpServer(culture: string, deleteSmtpServerRequest?: DeleteSmtpServerRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<BooleanPurpleApiResponse>;
    public deleteSmtpServer(culture: string, deleteSmtpServerRequest?: DeleteSmtpServerRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<BooleanPurpleApiResponse>>;
    public deleteSmtpServer(culture: string, deleteSmtpServerRequest?: DeleteSmtpServerRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<BooleanPurpleApiResponse>>;
    public deleteSmtpServer(culture: string, deleteSmtpServerRequest?: DeleteSmtpServerRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (culture === null || culture === undefined) {
            throw new Error('Required parameter culture was null or undefined when calling DeleteSmtpServer.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<BooleanPurpleApiResponse>(`${this.configuration.basePath}/${encodeURIComponent(String(culture))}/api/BackOffice/DeleteSmtpServer`,
            deleteSmtpServerRequest,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param culture 
     * @param apiDeleteSystemEventExceptionRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteSystemEventException(culture: string, apiDeleteSystemEventExceptionRequest?: ApiDeleteSystemEventExceptionRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<BooleanPurpleApiResponse>;
    public deleteSystemEventException(culture: string, apiDeleteSystemEventExceptionRequest?: ApiDeleteSystemEventExceptionRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<BooleanPurpleApiResponse>>;
    public deleteSystemEventException(culture: string, apiDeleteSystemEventExceptionRequest?: ApiDeleteSystemEventExceptionRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<BooleanPurpleApiResponse>>;
    public deleteSystemEventException(culture: string, apiDeleteSystemEventExceptionRequest?: ApiDeleteSystemEventExceptionRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (culture === null || culture === undefined) {
            throw new Error('Required parameter culture was null or undefined when calling DeleteSystemEventException.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<BooleanPurpleApiResponse>(`${this.configuration.basePath}/${encodeURIComponent(String(culture))}/api/BackOffice/DeleteSystemEventException`,
            apiDeleteSystemEventExceptionRequest,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param culture 
     * @param deleteTranslationRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteTranslation(culture: string, deleteTranslationRequest?: DeleteTranslationRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<BooleanPurpleApiResponse>;
    public deleteTranslation(culture: string, deleteTranslationRequest?: DeleteTranslationRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<BooleanPurpleApiResponse>>;
    public deleteTranslation(culture: string, deleteTranslationRequest?: DeleteTranslationRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<BooleanPurpleApiResponse>>;
    public deleteTranslation(culture: string, deleteTranslationRequest?: DeleteTranslationRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (culture === null || culture === undefined) {
            throw new Error('Required parameter culture was null or undefined when calling DeleteTranslation.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<BooleanPurpleApiResponse>(`${this.configuration.basePath}/${encodeURIComponent(String(culture))}/api/BackOffice/DeleteTranslation`,
            deleteTranslationRequest,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param culture 
     * @param deleteUserRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteUser(culture: string, deleteUserRequest?: DeleteUserRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<BooleanPurpleApiResponse>;
    public deleteUser(culture: string, deleteUserRequest?: DeleteUserRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<BooleanPurpleApiResponse>>;
    public deleteUser(culture: string, deleteUserRequest?: DeleteUserRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<BooleanPurpleApiResponse>>;
    public deleteUser(culture: string, deleteUserRequest?: DeleteUserRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (culture === null || culture === undefined) {
            throw new Error('Required parameter culture was null or undefined when calling DeleteUser.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<BooleanPurpleApiResponse>(`${this.configuration.basePath}/${encodeURIComponent(String(culture))}/api/BackOffice/DeleteUser`,
            deleteUserRequest,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param culture 
     * @param apiDeleteUserRoleReferralRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteUserRoleReferral(culture: string, apiDeleteUserRoleReferralRequest?: ApiDeleteUserRoleReferralRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<BooleanPurpleApiResponse>;
    public deleteUserRoleReferral(culture: string, apiDeleteUserRoleReferralRequest?: ApiDeleteUserRoleReferralRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<BooleanPurpleApiResponse>>;
    public deleteUserRoleReferral(culture: string, apiDeleteUserRoleReferralRequest?: ApiDeleteUserRoleReferralRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<BooleanPurpleApiResponse>>;
    public deleteUserRoleReferral(culture: string, apiDeleteUserRoleReferralRequest?: ApiDeleteUserRoleReferralRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (culture === null || culture === undefined) {
            throw new Error('Required parameter culture was null or undefined when calling DeleteUserRoleReferral.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<BooleanPurpleApiResponse>(`${this.configuration.basePath}/${encodeURIComponent(String(culture))}/api/BackOffice/DeleteUserRoleReferral`,
            apiDeleteUserRoleReferralRequest,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param culture 
     * @param enableDisableCouponRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public enableDisableCoupon(culture: string, enableDisableCouponRequest?: EnableDisableCouponRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<BooleanPurpleApiResponse>;
    public enableDisableCoupon(culture: string, enableDisableCouponRequest?: EnableDisableCouponRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<BooleanPurpleApiResponse>>;
    public enableDisableCoupon(culture: string, enableDisableCouponRequest?: EnableDisableCouponRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<BooleanPurpleApiResponse>>;
    public enableDisableCoupon(culture: string, enableDisableCouponRequest?: EnableDisableCouponRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (culture === null || culture === undefined) {
            throw new Error('Required parameter culture was null or undefined when calling EnableDisableCoupon.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<BooleanPurpleApiResponse>(`${this.configuration.basePath}/${encodeURIComponent(String(culture))}/api/BackOffice/EnableDisableCoupon`,
            enableDisableCouponRequest,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param culture 
     * @param enableDisableSaleEventLimitsRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public enableDisableSaleEventLimits(culture: string, enableDisableSaleEventLimitsRequest?: EnableDisableSaleEventLimitsRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<BooleanPurpleApiResponse>;
    public enableDisableSaleEventLimits(culture: string, enableDisableSaleEventLimitsRequest?: EnableDisableSaleEventLimitsRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<BooleanPurpleApiResponse>>;
    public enableDisableSaleEventLimits(culture: string, enableDisableSaleEventLimitsRequest?: EnableDisableSaleEventLimitsRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<BooleanPurpleApiResponse>>;
    public enableDisableSaleEventLimits(culture: string, enableDisableSaleEventLimitsRequest?: EnableDisableSaleEventLimitsRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (culture === null || culture === undefined) {
            throw new Error('Required parameter culture was null or undefined when calling EnableDisableSaleEventLimits.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<BooleanPurpleApiResponse>(`${this.configuration.basePath}/${encodeURIComponent(String(culture))}/api/BackOffice/EnableDisableSaleEventLimits`,
            enableDisableSaleEventLimitsRequest,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param culture 
     * @param enableDisableUserAddressRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public enableDisableUserAddress(culture: string, enableDisableUserAddressRequest?: EnableDisableUserAddressRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<BooleanPurpleApiResponse>;
    public enableDisableUserAddress(culture: string, enableDisableUserAddressRequest?: EnableDisableUserAddressRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<BooleanPurpleApiResponse>>;
    public enableDisableUserAddress(culture: string, enableDisableUserAddressRequest?: EnableDisableUserAddressRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<BooleanPurpleApiResponse>>;
    public enableDisableUserAddress(culture: string, enableDisableUserAddressRequest?: EnableDisableUserAddressRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (culture === null || culture === undefined) {
            throw new Error('Required parameter culture was null or undefined when calling EnableDisableUserAddress.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<BooleanPurpleApiResponse>(`${this.configuration.basePath}/${encodeURIComponent(String(culture))}/api/BackOffice/EnableDisableUserAddress`,
            enableDisableUserAddressRequest,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param culture 
     * @param apiEnqueueBulkImportedUsersRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public enqueueBulkImportedUsers(culture: string, apiEnqueueBulkImportedUsersRequest?: ApiEnqueueBulkImportedUsersRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<PurpleApiResponse>;
    public enqueueBulkImportedUsers(culture: string, apiEnqueueBulkImportedUsersRequest?: ApiEnqueueBulkImportedUsersRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<PurpleApiResponse>>;
    public enqueueBulkImportedUsers(culture: string, apiEnqueueBulkImportedUsersRequest?: ApiEnqueueBulkImportedUsersRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<PurpleApiResponse>>;
    public enqueueBulkImportedUsers(culture: string, apiEnqueueBulkImportedUsersRequest?: ApiEnqueueBulkImportedUsersRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (culture === null || culture === undefined) {
            throw new Error('Required parameter culture was null or undefined when calling EnqueueBulkImportedUsers.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<PurpleApiResponse>(`${this.configuration.basePath}/${encodeURIComponent(String(culture))}/api/BackOffice/EnqueueBulkImportedUsers`,
            apiEnqueueBulkImportedUsersRequest,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param saleEventId 
     * @param type 
     * @param culture 
     * @param slotDate 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public exportBookedUsersExcelReport(saleEventId: string, type: string, culture: string, slotDate?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/octet-stream'}): Observable<Blob>;
    public exportBookedUsersExcelReport(saleEventId: string, type: string, culture: string, slotDate?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/octet-stream'}): Observable<HttpResponse<Blob>>;
    public exportBookedUsersExcelReport(saleEventId: string, type: string, culture: string, slotDate?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/octet-stream'}): Observable<HttpEvent<Blob>>;
    public exportBookedUsersExcelReport(saleEventId: string, type: string, culture: string, slotDate?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/octet-stream'}): Observable<any> {
        if (saleEventId === null || saleEventId === undefined) {
            throw new Error('Required parameter saleEventId was null or undefined when calling ExportBookedUsersExcelReport.');
        }
        if (type === null || type === undefined) {
            throw new Error('Required parameter type was null or undefined when calling ExportBookedUsersExcelReport.');
        }
        if (culture === null || culture === undefined) {
            throw new Error('Required parameter culture was null or undefined when calling ExportBookedUsersExcelReport.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (saleEventId !== undefined && saleEventId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>saleEventId, 'SaleEventId');
        }
        if (type !== undefined && type !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>type, 'Type');
        }
        if (slotDate !== undefined && slotDate !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>slotDate, 'SlotDate');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/octet-stream'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        return this.httpClient.get(`${this.configuration.basePath}/${encodeURIComponent(String(culture))}/api/BackOffice/ExportBookedUsersExcelReport`,
            {
                params: queryParameters,
                responseType: "blob",
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param saleEventId 
     * @param culture 
     * @param day 
     * @param slotStartDate 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public exportBookingsExcelReport(saleEventId: string, culture: string, day?: string, slotStartDate?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/octet-stream'}): Observable<Blob>;
    public exportBookingsExcelReport(saleEventId: string, culture: string, day?: string, slotStartDate?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/octet-stream'}): Observable<HttpResponse<Blob>>;
    public exportBookingsExcelReport(saleEventId: string, culture: string, day?: string, slotStartDate?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/octet-stream'}): Observable<HttpEvent<Blob>>;
    public exportBookingsExcelReport(saleEventId: string, culture: string, day?: string, slotStartDate?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/octet-stream'}): Observable<any> {
        if (saleEventId === null || saleEventId === undefined) {
            throw new Error('Required parameter saleEventId was null or undefined when calling ExportBookingsExcelReport.');
        }
        if (culture === null || culture === undefined) {
            throw new Error('Required parameter culture was null or undefined when calling ExportBookingsExcelReport.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (saleEventId !== undefined && saleEventId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>saleEventId, 'SaleEventId');
        }
        if (day !== undefined && day !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>day, 'Day');
        }
        if (slotStartDate !== undefined && slotStartDate !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>slotStartDate, 'SlotStartDate');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/octet-stream'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        return this.httpClient.get(`${this.configuration.basePath}/${encodeURIComponent(String(culture))}/api/BackOffice/ExportBookingsExcelReport`,
            {
                params: queryParameters,
                responseType: "blob",
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param culture 
     * @param getAddressByExternalAddressIdRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAddressDetail(culture: string, getAddressByExternalAddressIdRequest?: GetAddressByExternalAddressIdRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<AddressPurpleApiResponse>;
    public getAddressDetail(culture: string, getAddressByExternalAddressIdRequest?: GetAddressByExternalAddressIdRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<AddressPurpleApiResponse>>;
    public getAddressDetail(culture: string, getAddressByExternalAddressIdRequest?: GetAddressByExternalAddressIdRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<AddressPurpleApiResponse>>;
    public getAddressDetail(culture: string, getAddressByExternalAddressIdRequest?: GetAddressByExternalAddressIdRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (culture === null || culture === undefined) {
            throw new Error('Required parameter culture was null or undefined when calling GetAddressDetail.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<AddressPurpleApiResponse>(`${this.configuration.basePath}/${encodeURIComponent(String(culture))}/api/BackOffice/GetAddressDetail`,
            getAddressByExternalAddressIdRequest,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param culture 
     * @param apiGetPurpleSelectByIdRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getBrandsByIdSelect(culture: string, apiGetPurpleSelectByIdRequest?: ApiGetPurpleSelectByIdRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<PurpleSelectPagedResponsePurpleApiResponse>;
    public getBrandsByIdSelect(culture: string, apiGetPurpleSelectByIdRequest?: ApiGetPurpleSelectByIdRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<PurpleSelectPagedResponsePurpleApiResponse>>;
    public getBrandsByIdSelect(culture: string, apiGetPurpleSelectByIdRequest?: ApiGetPurpleSelectByIdRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<PurpleSelectPagedResponsePurpleApiResponse>>;
    public getBrandsByIdSelect(culture: string, apiGetPurpleSelectByIdRequest?: ApiGetPurpleSelectByIdRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (culture === null || culture === undefined) {
            throw new Error('Required parameter culture was null or undefined when calling GetBrandsByIdSelect.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<PurpleSelectPagedResponsePurpleApiResponse>(`${this.configuration.basePath}/${encodeURIComponent(String(culture))}/api/BackOffice/GetBrandsByIdSelect`,
            apiGetPurpleSelectByIdRequest,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param culture 
     * @param apiGetPurpleSelectByNameRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getBrandsByNameSelect(culture: string, apiGetPurpleSelectByNameRequest?: ApiGetPurpleSelectByNameRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<PurpleSelectPagedResponsePurpleApiResponse>;
    public getBrandsByNameSelect(culture: string, apiGetPurpleSelectByNameRequest?: ApiGetPurpleSelectByNameRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<PurpleSelectPagedResponsePurpleApiResponse>>;
    public getBrandsByNameSelect(culture: string, apiGetPurpleSelectByNameRequest?: ApiGetPurpleSelectByNameRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<PurpleSelectPagedResponsePurpleApiResponse>>;
    public getBrandsByNameSelect(culture: string, apiGetPurpleSelectByNameRequest?: ApiGetPurpleSelectByNameRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (culture === null || culture === undefined) {
            throw new Error('Required parameter culture was null or undefined when calling GetBrandsByNameSelect.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<PurpleSelectPagedResponsePurpleApiResponse>(`${this.configuration.basePath}/${encodeURIComponent(String(culture))}/api/BackOffice/GetBrandsByNameSelect`,
            apiGetPurpleSelectByNameRequest,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param culture 
     * @param apiGetPurpleSelectRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getBrandsSelect(culture: string, apiGetPurpleSelectRequest?: ApiGetPurpleSelectRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<PurpleSelectPagedResponsePurpleApiResponse>;
    public getBrandsSelect(culture: string, apiGetPurpleSelectRequest?: ApiGetPurpleSelectRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<PurpleSelectPagedResponsePurpleApiResponse>>;
    public getBrandsSelect(culture: string, apiGetPurpleSelectRequest?: ApiGetPurpleSelectRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<PurpleSelectPagedResponsePurpleApiResponse>>;
    public getBrandsSelect(culture: string, apiGetPurpleSelectRequest?: ApiGetPurpleSelectRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (culture === null || culture === undefined) {
            throw new Error('Required parameter culture was null or undefined when calling GetBrandsSelect.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<PurpleSelectPagedResponsePurpleApiResponse>(`${this.configuration.basePath}/${encodeURIComponent(String(culture))}/api/BackOffice/GetBrandsSelect`,
            apiGetPurpleSelectRequest,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param culture 
     * @param apiGetPurpleSelectByIdRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getCouponTypesByIdSelect(culture: string, apiGetPurpleSelectByIdRequest?: ApiGetPurpleSelectByIdRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<PurpleSelectPagedResponsePurpleApiResponse>;
    public getCouponTypesByIdSelect(culture: string, apiGetPurpleSelectByIdRequest?: ApiGetPurpleSelectByIdRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<PurpleSelectPagedResponsePurpleApiResponse>>;
    public getCouponTypesByIdSelect(culture: string, apiGetPurpleSelectByIdRequest?: ApiGetPurpleSelectByIdRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<PurpleSelectPagedResponsePurpleApiResponse>>;
    public getCouponTypesByIdSelect(culture: string, apiGetPurpleSelectByIdRequest?: ApiGetPurpleSelectByIdRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (culture === null || culture === undefined) {
            throw new Error('Required parameter culture was null or undefined when calling GetCouponTypesByIdSelect.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<PurpleSelectPagedResponsePurpleApiResponse>(`${this.configuration.basePath}/${encodeURIComponent(String(culture))}/api/BackOffice/GetCouponTypesByIdSelect`,
            apiGetPurpleSelectByIdRequest,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param culture 
     * @param apiGetPurpleSelectByNameRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getCouponTypesByNameSelect(culture: string, apiGetPurpleSelectByNameRequest?: ApiGetPurpleSelectByNameRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<PurpleSelectPagedResponsePurpleApiResponse>;
    public getCouponTypesByNameSelect(culture: string, apiGetPurpleSelectByNameRequest?: ApiGetPurpleSelectByNameRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<PurpleSelectPagedResponsePurpleApiResponse>>;
    public getCouponTypesByNameSelect(culture: string, apiGetPurpleSelectByNameRequest?: ApiGetPurpleSelectByNameRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<PurpleSelectPagedResponsePurpleApiResponse>>;
    public getCouponTypesByNameSelect(culture: string, apiGetPurpleSelectByNameRequest?: ApiGetPurpleSelectByNameRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (culture === null || culture === undefined) {
            throw new Error('Required parameter culture was null or undefined when calling GetCouponTypesByNameSelect.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<PurpleSelectPagedResponsePurpleApiResponse>(`${this.configuration.basePath}/${encodeURIComponent(String(culture))}/api/BackOffice/GetCouponTypesByNameSelect`,
            apiGetPurpleSelectByNameRequest,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param culture 
     * @param apiGetPurpleSelectRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getCouponTypesSelect(culture: string, apiGetPurpleSelectRequest?: ApiGetPurpleSelectRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<PurpleSelectPagedResponsePurpleApiResponse>;
    public getCouponTypesSelect(culture: string, apiGetPurpleSelectRequest?: ApiGetPurpleSelectRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<PurpleSelectPagedResponsePurpleApiResponse>>;
    public getCouponTypesSelect(culture: string, apiGetPurpleSelectRequest?: ApiGetPurpleSelectRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<PurpleSelectPagedResponsePurpleApiResponse>>;
    public getCouponTypesSelect(culture: string, apiGetPurpleSelectRequest?: ApiGetPurpleSelectRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (culture === null || culture === undefined) {
            throw new Error('Required parameter culture was null or undefined when calling GetCouponTypesSelect.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<PurpleSelectPagedResponsePurpleApiResponse>(`${this.configuration.basePath}/${encodeURIComponent(String(culture))}/api/BackOffice/GetCouponTypesSelect`,
            apiGetPurpleSelectRequest,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param culture 
     * @param apiGetPurpleSelectByIdRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getCustomersByIdSelect(culture: string, apiGetPurpleSelectByIdRequest?: ApiGetPurpleSelectByIdRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<PurpleSelectPagedResponsePurpleApiResponse>;
    public getCustomersByIdSelect(culture: string, apiGetPurpleSelectByIdRequest?: ApiGetPurpleSelectByIdRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<PurpleSelectPagedResponsePurpleApiResponse>>;
    public getCustomersByIdSelect(culture: string, apiGetPurpleSelectByIdRequest?: ApiGetPurpleSelectByIdRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<PurpleSelectPagedResponsePurpleApiResponse>>;
    public getCustomersByIdSelect(culture: string, apiGetPurpleSelectByIdRequest?: ApiGetPurpleSelectByIdRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (culture === null || culture === undefined) {
            throw new Error('Required parameter culture was null or undefined when calling GetCustomersByIdSelect.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<PurpleSelectPagedResponsePurpleApiResponse>(`${this.configuration.basePath}/${encodeURIComponent(String(culture))}/api/BackOffice/GetCustomersByIdSelect`,
            apiGetPurpleSelectByIdRequest,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param culture 
     * @param apiGetPurpleSelectMultipleByIdRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getCustomersByIdsSelect(culture: string, apiGetPurpleSelectMultipleByIdRequest?: ApiGetPurpleSelectMultipleByIdRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<PurpleSelectPagedResponsePurpleApiResponse>;
    public getCustomersByIdsSelect(culture: string, apiGetPurpleSelectMultipleByIdRequest?: ApiGetPurpleSelectMultipleByIdRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<PurpleSelectPagedResponsePurpleApiResponse>>;
    public getCustomersByIdsSelect(culture: string, apiGetPurpleSelectMultipleByIdRequest?: ApiGetPurpleSelectMultipleByIdRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<PurpleSelectPagedResponsePurpleApiResponse>>;
    public getCustomersByIdsSelect(culture: string, apiGetPurpleSelectMultipleByIdRequest?: ApiGetPurpleSelectMultipleByIdRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (culture === null || culture === undefined) {
            throw new Error('Required parameter culture was null or undefined when calling GetCustomersByIdsSelect.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<PurpleSelectPagedResponsePurpleApiResponse>(`${this.configuration.basePath}/${encodeURIComponent(String(culture))}/api/BackOffice/GetCustomersByIdsSelect`,
            apiGetPurpleSelectMultipleByIdRequest,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param culture 
     * @param apiGetPurpleSelectByNameRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getCustomersByNameSelect(culture: string, apiGetPurpleSelectByNameRequest?: ApiGetPurpleSelectByNameRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<PurpleSelectPagedResponsePurpleApiResponse>;
    public getCustomersByNameSelect(culture: string, apiGetPurpleSelectByNameRequest?: ApiGetPurpleSelectByNameRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<PurpleSelectPagedResponsePurpleApiResponse>>;
    public getCustomersByNameSelect(culture: string, apiGetPurpleSelectByNameRequest?: ApiGetPurpleSelectByNameRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<PurpleSelectPagedResponsePurpleApiResponse>>;
    public getCustomersByNameSelect(culture: string, apiGetPurpleSelectByNameRequest?: ApiGetPurpleSelectByNameRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (culture === null || culture === undefined) {
            throw new Error('Required parameter culture was null or undefined when calling GetCustomersByNameSelect.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<PurpleSelectPagedResponsePurpleApiResponse>(`${this.configuration.basePath}/${encodeURIComponent(String(culture))}/api/BackOffice/GetCustomersByNameSelect`,
            apiGetPurpleSelectByNameRequest,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param culture 
     * @param apiGetPurpleSelectRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getCustomersSelect(culture: string, apiGetPurpleSelectRequest?: ApiGetPurpleSelectRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<PurpleSelectPagedResponsePurpleApiResponse>;
    public getCustomersSelect(culture: string, apiGetPurpleSelectRequest?: ApiGetPurpleSelectRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<PurpleSelectPagedResponsePurpleApiResponse>>;
    public getCustomersSelect(culture: string, apiGetPurpleSelectRequest?: ApiGetPurpleSelectRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<PurpleSelectPagedResponsePurpleApiResponse>>;
    public getCustomersSelect(culture: string, apiGetPurpleSelectRequest?: ApiGetPurpleSelectRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (culture === null || culture === undefined) {
            throw new Error('Required parameter culture was null or undefined when calling GetCustomersSelect.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<PurpleSelectPagedResponsePurpleApiResponse>(`${this.configuration.basePath}/${encodeURIComponent(String(culture))}/api/BackOffice/GetCustomersSelect`,
            apiGetPurpleSelectRequest,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param culture 
     * @param getDateSlotAllRolesRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getDateSlotAllRoles(culture: string, getDateSlotAllRolesRequest?: GetDateSlotAllRolesRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<DateSlotRoleListPurpleApiResponse>;
    public getDateSlotAllRoles(culture: string, getDateSlotAllRolesRequest?: GetDateSlotAllRolesRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<DateSlotRoleListPurpleApiResponse>>;
    public getDateSlotAllRoles(culture: string, getDateSlotAllRolesRequest?: GetDateSlotAllRolesRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<DateSlotRoleListPurpleApiResponse>>;
    public getDateSlotAllRoles(culture: string, getDateSlotAllRolesRequest?: GetDateSlotAllRolesRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (culture === null || culture === undefined) {
            throw new Error('Required parameter culture was null or undefined when calling GetDateSlotAllRoles.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<DateSlotRoleListPurpleApiResponse>(`${this.configuration.basePath}/${encodeURIComponent(String(culture))}/api/BackOffice/GetDateSlotAllRoles`,
            getDateSlotAllRolesRequest,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param culture 
     * @param getDefaultCouponBaseRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getDefaultCouponBase(culture: string, getDefaultCouponBaseRequest?: GetDefaultCouponBaseRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<DefaultCouponPurpleApiResponse>;
    public getDefaultCouponBase(culture: string, getDefaultCouponBaseRequest?: GetDefaultCouponBaseRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<DefaultCouponPurpleApiResponse>>;
    public getDefaultCouponBase(culture: string, getDefaultCouponBaseRequest?: GetDefaultCouponBaseRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<DefaultCouponPurpleApiResponse>>;
    public getDefaultCouponBase(culture: string, getDefaultCouponBaseRequest?: GetDefaultCouponBaseRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (culture === null || culture === undefined) {
            throw new Error('Required parameter culture was null or undefined when calling GetDefaultCouponBase.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<DefaultCouponPurpleApiResponse>(`${this.configuration.basePath}/${encodeURIComponent(String(culture))}/api/BackOffice/GetDefaultCouponBase`,
            getDefaultCouponBaseRequest,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param culture 
     * @param apiGetPurpleSelectByIdRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getDefaultCouponByIdSelect(culture: string, apiGetPurpleSelectByIdRequest?: ApiGetPurpleSelectByIdRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<PurpleSelectPagedResponsePurpleApiResponse>;
    public getDefaultCouponByIdSelect(culture: string, apiGetPurpleSelectByIdRequest?: ApiGetPurpleSelectByIdRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<PurpleSelectPagedResponsePurpleApiResponse>>;
    public getDefaultCouponByIdSelect(culture: string, apiGetPurpleSelectByIdRequest?: ApiGetPurpleSelectByIdRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<PurpleSelectPagedResponsePurpleApiResponse>>;
    public getDefaultCouponByIdSelect(culture: string, apiGetPurpleSelectByIdRequest?: ApiGetPurpleSelectByIdRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (culture === null || culture === undefined) {
            throw new Error('Required parameter culture was null or undefined when calling GetDefaultCouponByIdSelect.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<PurpleSelectPagedResponsePurpleApiResponse>(`${this.configuration.basePath}/${encodeURIComponent(String(culture))}/api/BackOffice/GetDefaultCouponByIdSelect`,
            apiGetPurpleSelectByIdRequest,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param culture 
     * @param apiGetPurpleSelectByNameRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getDefaultCouponByNameSelect(culture: string, apiGetPurpleSelectByNameRequest?: ApiGetPurpleSelectByNameRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<PurpleSelectPagedResponsePurpleApiResponse>;
    public getDefaultCouponByNameSelect(culture: string, apiGetPurpleSelectByNameRequest?: ApiGetPurpleSelectByNameRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<PurpleSelectPagedResponsePurpleApiResponse>>;
    public getDefaultCouponByNameSelect(culture: string, apiGetPurpleSelectByNameRequest?: ApiGetPurpleSelectByNameRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<PurpleSelectPagedResponsePurpleApiResponse>>;
    public getDefaultCouponByNameSelect(culture: string, apiGetPurpleSelectByNameRequest?: ApiGetPurpleSelectByNameRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (culture === null || culture === undefined) {
            throw new Error('Required parameter culture was null or undefined when calling GetDefaultCouponByNameSelect.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<PurpleSelectPagedResponsePurpleApiResponse>(`${this.configuration.basePath}/${encodeURIComponent(String(culture))}/api/BackOffice/GetDefaultCouponByNameSelect`,
            apiGetPurpleSelectByNameRequest,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param culture 
     * @param apiGetPurpleSelectRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getDefaultCouponSelect(culture: string, apiGetPurpleSelectRequest?: ApiGetPurpleSelectRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<PurpleSelectPagedResponsePurpleApiResponse>;
    public getDefaultCouponSelect(culture: string, apiGetPurpleSelectRequest?: ApiGetPurpleSelectRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<PurpleSelectPagedResponsePurpleApiResponse>>;
    public getDefaultCouponSelect(culture: string, apiGetPurpleSelectRequest?: ApiGetPurpleSelectRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<PurpleSelectPagedResponsePurpleApiResponse>>;
    public getDefaultCouponSelect(culture: string, apiGetPurpleSelectRequest?: ApiGetPurpleSelectRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (culture === null || culture === undefined) {
            throw new Error('Required parameter culture was null or undefined when calling GetDefaultCouponSelect.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<PurpleSelectPagedResponsePurpleApiResponse>(`${this.configuration.basePath}/${encodeURIComponent(String(culture))}/api/BackOffice/GetDefaultCouponSelect`,
            apiGetPurpleSelectRequest,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param culture 
     * @param apiGetPurpleTableRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getDefaultCouponTable(culture: string, apiGetPurpleTableRequest?: ApiGetPurpleTableRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<DefaultCouponPurpleTablePagedResponsePurpleApiResponse>;
    public getDefaultCouponTable(culture: string, apiGetPurpleTableRequest?: ApiGetPurpleTableRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<DefaultCouponPurpleTablePagedResponsePurpleApiResponse>>;
    public getDefaultCouponTable(culture: string, apiGetPurpleTableRequest?: ApiGetPurpleTableRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<DefaultCouponPurpleTablePagedResponsePurpleApiResponse>>;
    public getDefaultCouponTable(culture: string, apiGetPurpleTableRequest?: ApiGetPurpleTableRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (culture === null || culture === undefined) {
            throw new Error('Required parameter culture was null or undefined when calling GetDefaultCouponTable.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<DefaultCouponPurpleTablePagedResponsePurpleApiResponse>(`${this.configuration.basePath}/${encodeURIComponent(String(culture))}/api/BackOffice/GetDefaultCouponTable`,
            apiGetPurpleTableRequest,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param culture 
     * @param apiGetPurpleTableRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getMailTemplatesBaseTable(culture: string, apiGetPurpleTableRequest?: ApiGetPurpleTableRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<MailTemplatePurpleTablePagedResponsePurpleApiResponse>;
    public getMailTemplatesBaseTable(culture: string, apiGetPurpleTableRequest?: ApiGetPurpleTableRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<MailTemplatePurpleTablePagedResponsePurpleApiResponse>>;
    public getMailTemplatesBaseTable(culture: string, apiGetPurpleTableRequest?: ApiGetPurpleTableRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<MailTemplatePurpleTablePagedResponsePurpleApiResponse>>;
    public getMailTemplatesBaseTable(culture: string, apiGetPurpleTableRequest?: ApiGetPurpleTableRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (culture === null || culture === undefined) {
            throw new Error('Required parameter culture was null or undefined when calling GetMailTemplatesBaseTable.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<MailTemplatePurpleTablePagedResponsePurpleApiResponse>(`${this.configuration.basePath}/${encodeURIComponent(String(culture))}/api/BackOffice/GetMailTemplatesBaseTable`,
            apiGetPurpleTableRequest,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param culture 
     * @param apiGetPurpleSelectByIdRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getMailTemplatesByIdSelect(culture: string, apiGetPurpleSelectByIdRequest?: ApiGetPurpleSelectByIdRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<PurpleSelectPagedResponsePurpleApiResponse>;
    public getMailTemplatesByIdSelect(culture: string, apiGetPurpleSelectByIdRequest?: ApiGetPurpleSelectByIdRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<PurpleSelectPagedResponsePurpleApiResponse>>;
    public getMailTemplatesByIdSelect(culture: string, apiGetPurpleSelectByIdRequest?: ApiGetPurpleSelectByIdRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<PurpleSelectPagedResponsePurpleApiResponse>>;
    public getMailTemplatesByIdSelect(culture: string, apiGetPurpleSelectByIdRequest?: ApiGetPurpleSelectByIdRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (culture === null || culture === undefined) {
            throw new Error('Required parameter culture was null or undefined when calling GetMailTemplatesByIdSelect.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<PurpleSelectPagedResponsePurpleApiResponse>(`${this.configuration.basePath}/${encodeURIComponent(String(culture))}/api/BackOffice/GetMailTemplatesByIdSelect`,
            apiGetPurpleSelectByIdRequest,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param culture 
     * @param apiGetPurpleSelectByNameRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getMailTemplatesByNameSelect(culture: string, apiGetPurpleSelectByNameRequest?: ApiGetPurpleSelectByNameRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<PurpleSelectPagedResponsePurpleApiResponse>;
    public getMailTemplatesByNameSelect(culture: string, apiGetPurpleSelectByNameRequest?: ApiGetPurpleSelectByNameRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<PurpleSelectPagedResponsePurpleApiResponse>>;
    public getMailTemplatesByNameSelect(culture: string, apiGetPurpleSelectByNameRequest?: ApiGetPurpleSelectByNameRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<PurpleSelectPagedResponsePurpleApiResponse>>;
    public getMailTemplatesByNameSelect(culture: string, apiGetPurpleSelectByNameRequest?: ApiGetPurpleSelectByNameRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (culture === null || culture === undefined) {
            throw new Error('Required parameter culture was null or undefined when calling GetMailTemplatesByNameSelect.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<PurpleSelectPagedResponsePurpleApiResponse>(`${this.configuration.basePath}/${encodeURIComponent(String(culture))}/api/BackOffice/GetMailTemplatesByNameSelect`,
            apiGetPurpleSelectByNameRequest,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param culture 
     * @param apiGetPurpleSelectRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getMailTemplatesSelect(culture: string, apiGetPurpleSelectRequest?: ApiGetPurpleSelectRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<PurpleSelectPagedResponsePurpleApiResponse>;
    public getMailTemplatesSelect(culture: string, apiGetPurpleSelectRequest?: ApiGetPurpleSelectRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<PurpleSelectPagedResponsePurpleApiResponse>>;
    public getMailTemplatesSelect(culture: string, apiGetPurpleSelectRequest?: ApiGetPurpleSelectRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<PurpleSelectPagedResponsePurpleApiResponse>>;
    public getMailTemplatesSelect(culture: string, apiGetPurpleSelectRequest?: ApiGetPurpleSelectRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (culture === null || culture === undefined) {
            throw new Error('Required parameter culture was null or undefined when calling GetMailTemplatesSelect.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<PurpleSelectPagedResponsePurpleApiResponse>(`${this.configuration.basePath}/${encodeURIComponent(String(culture))}/api/BackOffice/GetMailTemplatesSelect`,
            apiGetPurpleSelectRequest,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param culture 
     * @param getNewSlideDefaultFieldsRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getNewSlideDefaultFields(culture: string, getNewSlideDefaultFieldsRequest?: GetNewSlideDefaultFieldsRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<SaleEventCarouselSlideFieldItemListPurpleApiResponse>;
    public getNewSlideDefaultFields(culture: string, getNewSlideDefaultFieldsRequest?: GetNewSlideDefaultFieldsRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<SaleEventCarouselSlideFieldItemListPurpleApiResponse>>;
    public getNewSlideDefaultFields(culture: string, getNewSlideDefaultFieldsRequest?: GetNewSlideDefaultFieldsRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<SaleEventCarouselSlideFieldItemListPurpleApiResponse>>;
    public getNewSlideDefaultFields(culture: string, getNewSlideDefaultFieldsRequest?: GetNewSlideDefaultFieldsRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (culture === null || culture === undefined) {
            throw new Error('Required parameter culture was null or undefined when calling GetNewSlideDefaultFields.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<SaleEventCarouselSlideFieldItemListPurpleApiResponse>(`${this.configuration.basePath}/${encodeURIComponent(String(culture))}/api/BackOffice/GetNewSlideDefaultFields`,
            getNewSlideDefaultFieldsRequest,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param culture 
     * @param getOwnerCustomerIdsRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getOwnerCustomerIds(culture: string, getOwnerCustomerIdsRequest?: GetOwnerCustomerIdsRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<GuidListPurpleApiResponse>;
    public getOwnerCustomerIds(culture: string, getOwnerCustomerIdsRequest?: GetOwnerCustomerIdsRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<GuidListPurpleApiResponse>>;
    public getOwnerCustomerIds(culture: string, getOwnerCustomerIdsRequest?: GetOwnerCustomerIdsRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<GuidListPurpleApiResponse>>;
    public getOwnerCustomerIds(culture: string, getOwnerCustomerIdsRequest?: GetOwnerCustomerIdsRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (culture === null || culture === undefined) {
            throw new Error('Required parameter culture was null or undefined when calling GetOwnerCustomerIds.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<GuidListPurpleApiResponse>(`${this.configuration.basePath}/${encodeURIComponent(String(culture))}/api/BackOffice/GetOwnerCustomerIds`,
            getOwnerCustomerIdsRequest,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param culture 
     * @param apiGetPurpleSelectByIdRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getOwnersByIdSelect(culture: string, apiGetPurpleSelectByIdRequest?: ApiGetPurpleSelectByIdRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<PurpleSelectPagedResponsePurpleApiResponse>;
    public getOwnersByIdSelect(culture: string, apiGetPurpleSelectByIdRequest?: ApiGetPurpleSelectByIdRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<PurpleSelectPagedResponsePurpleApiResponse>>;
    public getOwnersByIdSelect(culture: string, apiGetPurpleSelectByIdRequest?: ApiGetPurpleSelectByIdRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<PurpleSelectPagedResponsePurpleApiResponse>>;
    public getOwnersByIdSelect(culture: string, apiGetPurpleSelectByIdRequest?: ApiGetPurpleSelectByIdRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (culture === null || culture === undefined) {
            throw new Error('Required parameter culture was null or undefined when calling GetOwnersByIdSelect.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<PurpleSelectPagedResponsePurpleApiResponse>(`${this.configuration.basePath}/${encodeURIComponent(String(culture))}/api/BackOffice/GetOwnersByIdSelect`,
            apiGetPurpleSelectByIdRequest,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param culture 
     * @param apiGetPurpleSelectMultipleByIdRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getOwnersByIdsSelect(culture: string, apiGetPurpleSelectMultipleByIdRequest?: ApiGetPurpleSelectMultipleByIdRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<PurpleSelectPagedResponsePurpleApiResponse>;
    public getOwnersByIdsSelect(culture: string, apiGetPurpleSelectMultipleByIdRequest?: ApiGetPurpleSelectMultipleByIdRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<PurpleSelectPagedResponsePurpleApiResponse>>;
    public getOwnersByIdsSelect(culture: string, apiGetPurpleSelectMultipleByIdRequest?: ApiGetPurpleSelectMultipleByIdRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<PurpleSelectPagedResponsePurpleApiResponse>>;
    public getOwnersByIdsSelect(culture: string, apiGetPurpleSelectMultipleByIdRequest?: ApiGetPurpleSelectMultipleByIdRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (culture === null || culture === undefined) {
            throw new Error('Required parameter culture was null or undefined when calling GetOwnersByIdsSelect.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<PurpleSelectPagedResponsePurpleApiResponse>(`${this.configuration.basePath}/${encodeURIComponent(String(culture))}/api/BackOffice/GetOwnersByIdsSelect`,
            apiGetPurpleSelectMultipleByIdRequest,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param culture 
     * @param apiGetPurpleSelectByNameRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getOwnersByNameSelect(culture: string, apiGetPurpleSelectByNameRequest?: ApiGetPurpleSelectByNameRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<PurpleSelectPagedResponsePurpleApiResponse>;
    public getOwnersByNameSelect(culture: string, apiGetPurpleSelectByNameRequest?: ApiGetPurpleSelectByNameRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<PurpleSelectPagedResponsePurpleApiResponse>>;
    public getOwnersByNameSelect(culture: string, apiGetPurpleSelectByNameRequest?: ApiGetPurpleSelectByNameRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<PurpleSelectPagedResponsePurpleApiResponse>>;
    public getOwnersByNameSelect(culture: string, apiGetPurpleSelectByNameRequest?: ApiGetPurpleSelectByNameRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (culture === null || culture === undefined) {
            throw new Error('Required parameter culture was null or undefined when calling GetOwnersByNameSelect.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<PurpleSelectPagedResponsePurpleApiResponse>(`${this.configuration.basePath}/${encodeURIComponent(String(culture))}/api/BackOffice/GetOwnersByNameSelect`,
            apiGetPurpleSelectByNameRequest,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param culture 
     * @param apiGetPurpleSelectRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getOwnersSelect(culture: string, apiGetPurpleSelectRequest?: ApiGetPurpleSelectRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<PurpleSelectPagedResponsePurpleApiResponse>;
    public getOwnersSelect(culture: string, apiGetPurpleSelectRequest?: ApiGetPurpleSelectRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<PurpleSelectPagedResponsePurpleApiResponse>>;
    public getOwnersSelect(culture: string, apiGetPurpleSelectRequest?: ApiGetPurpleSelectRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<PurpleSelectPagedResponsePurpleApiResponse>>;
    public getOwnersSelect(culture: string, apiGetPurpleSelectRequest?: ApiGetPurpleSelectRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (culture === null || culture === undefined) {
            throw new Error('Required parameter culture was null or undefined when calling GetOwnersSelect.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<PurpleSelectPagedResponsePurpleApiResponse>(`${this.configuration.basePath}/${encodeURIComponent(String(culture))}/api/BackOffice/GetOwnersSelect`,
            apiGetPurpleSelectRequest,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param culture 
     * @param apiGetPurpleTableRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getOwnersTable(culture: string, apiGetPurpleTableRequest?: ApiGetPurpleTableRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<OwnerTableItemPurpleTablePagedResponsePurpleApiResponse>;
    public getOwnersTable(culture: string, apiGetPurpleTableRequest?: ApiGetPurpleTableRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<OwnerTableItemPurpleTablePagedResponsePurpleApiResponse>>;
    public getOwnersTable(culture: string, apiGetPurpleTableRequest?: ApiGetPurpleTableRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<OwnerTableItemPurpleTablePagedResponsePurpleApiResponse>>;
    public getOwnersTable(culture: string, apiGetPurpleTableRequest?: ApiGetPurpleTableRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (culture === null || culture === undefined) {
            throw new Error('Required parameter culture was null or undefined when calling GetOwnersTable.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<OwnerTableItemPurpleTablePagedResponsePurpleApiResponse>(`${this.configuration.basePath}/${encodeURIComponent(String(culture))}/api/BackOffice/GetOwnersTable`,
            apiGetPurpleTableRequest,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param culture 
     * @param apiGetPurpleSelectByIdRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getPoliciesByIdSelect(culture: string, apiGetPurpleSelectByIdRequest?: ApiGetPurpleSelectByIdRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<PurpleSelectPagedResponsePurpleApiResponse>;
    public getPoliciesByIdSelect(culture: string, apiGetPurpleSelectByIdRequest?: ApiGetPurpleSelectByIdRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<PurpleSelectPagedResponsePurpleApiResponse>>;
    public getPoliciesByIdSelect(culture: string, apiGetPurpleSelectByIdRequest?: ApiGetPurpleSelectByIdRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<PurpleSelectPagedResponsePurpleApiResponse>>;
    public getPoliciesByIdSelect(culture: string, apiGetPurpleSelectByIdRequest?: ApiGetPurpleSelectByIdRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (culture === null || culture === undefined) {
            throw new Error('Required parameter culture was null or undefined when calling GetPoliciesByIdSelect.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<PurpleSelectPagedResponsePurpleApiResponse>(`${this.configuration.basePath}/${encodeURIComponent(String(culture))}/api/BackOffice/GetPoliciesByIdSelect`,
            apiGetPurpleSelectByIdRequest,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param culture 
     * @param apiGetPurpleSelectByNameRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getPoliciesByNameSelect(culture: string, apiGetPurpleSelectByNameRequest?: ApiGetPurpleSelectByNameRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<PurpleSelectPagedResponsePurpleApiResponse>;
    public getPoliciesByNameSelect(culture: string, apiGetPurpleSelectByNameRequest?: ApiGetPurpleSelectByNameRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<PurpleSelectPagedResponsePurpleApiResponse>>;
    public getPoliciesByNameSelect(culture: string, apiGetPurpleSelectByNameRequest?: ApiGetPurpleSelectByNameRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<PurpleSelectPagedResponsePurpleApiResponse>>;
    public getPoliciesByNameSelect(culture: string, apiGetPurpleSelectByNameRequest?: ApiGetPurpleSelectByNameRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (culture === null || culture === undefined) {
            throw new Error('Required parameter culture was null or undefined when calling GetPoliciesByNameSelect.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<PurpleSelectPagedResponsePurpleApiResponse>(`${this.configuration.basePath}/${encodeURIComponent(String(culture))}/api/BackOffice/GetPoliciesByNameSelect`,
            apiGetPurpleSelectByNameRequest,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param culture 
     * @param apiGetPurpleSelectRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getPoliciesSelect(culture: string, apiGetPurpleSelectRequest?: ApiGetPurpleSelectRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<PurpleSelectPagedResponsePurpleApiResponse>;
    public getPoliciesSelect(culture: string, apiGetPurpleSelectRequest?: ApiGetPurpleSelectRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<PurpleSelectPagedResponsePurpleApiResponse>>;
    public getPoliciesSelect(culture: string, apiGetPurpleSelectRequest?: ApiGetPurpleSelectRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<PurpleSelectPagedResponsePurpleApiResponse>>;
    public getPoliciesSelect(culture: string, apiGetPurpleSelectRequest?: ApiGetPurpleSelectRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (culture === null || culture === undefined) {
            throw new Error('Required parameter culture was null or undefined when calling GetPoliciesSelect.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<PurpleSelectPagedResponsePurpleApiResponse>(`${this.configuration.basePath}/${encodeURIComponent(String(culture))}/api/BackOffice/GetPoliciesSelect`,
            apiGetPurpleSelectRequest,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param culture 
     * @param apiGetPurpleSelectByIdRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getPolicyTypesByIdSelect(culture: string, apiGetPurpleSelectByIdRequest?: ApiGetPurpleSelectByIdRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<PurpleSelectPagedResponsePurpleApiResponse>;
    public getPolicyTypesByIdSelect(culture: string, apiGetPurpleSelectByIdRequest?: ApiGetPurpleSelectByIdRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<PurpleSelectPagedResponsePurpleApiResponse>>;
    public getPolicyTypesByIdSelect(culture: string, apiGetPurpleSelectByIdRequest?: ApiGetPurpleSelectByIdRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<PurpleSelectPagedResponsePurpleApiResponse>>;
    public getPolicyTypesByIdSelect(culture: string, apiGetPurpleSelectByIdRequest?: ApiGetPurpleSelectByIdRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (culture === null || culture === undefined) {
            throw new Error('Required parameter culture was null or undefined when calling GetPolicyTypesByIdSelect.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<PurpleSelectPagedResponsePurpleApiResponse>(`${this.configuration.basePath}/${encodeURIComponent(String(culture))}/api/BackOffice/GetPolicyTypesByIdSelect`,
            apiGetPurpleSelectByIdRequest,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param culture 
     * @param apiGetPurpleSelectByNameRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getPolicyTypesByNameSelect(culture: string, apiGetPurpleSelectByNameRequest?: ApiGetPurpleSelectByNameRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<PurpleSelectPagedResponsePurpleApiResponse>;
    public getPolicyTypesByNameSelect(culture: string, apiGetPurpleSelectByNameRequest?: ApiGetPurpleSelectByNameRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<PurpleSelectPagedResponsePurpleApiResponse>>;
    public getPolicyTypesByNameSelect(culture: string, apiGetPurpleSelectByNameRequest?: ApiGetPurpleSelectByNameRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<PurpleSelectPagedResponsePurpleApiResponse>>;
    public getPolicyTypesByNameSelect(culture: string, apiGetPurpleSelectByNameRequest?: ApiGetPurpleSelectByNameRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (culture === null || culture === undefined) {
            throw new Error('Required parameter culture was null or undefined when calling GetPolicyTypesByNameSelect.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<PurpleSelectPagedResponsePurpleApiResponse>(`${this.configuration.basePath}/${encodeURIComponent(String(culture))}/api/BackOffice/GetPolicyTypesByNameSelect`,
            apiGetPurpleSelectByNameRequest,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param culture 
     * @param apiGetPurpleSelectRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getPolicyTypesSelect(culture: string, apiGetPurpleSelectRequest?: ApiGetPurpleSelectRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<PurpleSelectPagedResponsePurpleApiResponse>;
    public getPolicyTypesSelect(culture: string, apiGetPurpleSelectRequest?: ApiGetPurpleSelectRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<PurpleSelectPagedResponsePurpleApiResponse>>;
    public getPolicyTypesSelect(culture: string, apiGetPurpleSelectRequest?: ApiGetPurpleSelectRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<PurpleSelectPagedResponsePurpleApiResponse>>;
    public getPolicyTypesSelect(culture: string, apiGetPurpleSelectRequest?: ApiGetPurpleSelectRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (culture === null || culture === undefined) {
            throw new Error('Required parameter culture was null or undefined when calling GetPolicyTypesSelect.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<PurpleSelectPagedResponsePurpleApiResponse>(`${this.configuration.basePath}/${encodeURIComponent(String(culture))}/api/BackOffice/GetPolicyTypesSelect`,
            apiGetPurpleSelectRequest,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param culture 
     * @param apiGetPurpleTableRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getReferralsTable(culture: string, apiGetPurpleTableRequest?: ApiGetPurpleTableRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<ReferralTableItemPurpleTablePagedResponsePurpleApiResponse>;
    public getReferralsTable(culture: string, apiGetPurpleTableRequest?: ApiGetPurpleTableRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<ReferralTableItemPurpleTablePagedResponsePurpleApiResponse>>;
    public getReferralsTable(culture: string, apiGetPurpleTableRequest?: ApiGetPurpleTableRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<ReferralTableItemPurpleTablePagedResponsePurpleApiResponse>>;
    public getReferralsTable(culture: string, apiGetPurpleTableRequest?: ApiGetPurpleTableRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (culture === null || culture === undefined) {
            throw new Error('Required parameter culture was null or undefined when calling GetReferralsTable.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<ReferralTableItemPurpleTablePagedResponsePurpleApiResponse>(`${this.configuration.basePath}/${encodeURIComponent(String(culture))}/api/BackOffice/GetReferralsTable`,
            apiGetPurpleTableRequest,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param culture 
     * @param getRoleDetailRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getRoleDetail(culture: string, getRoleDetailRequest?: GetRoleDetailRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<RolePurpleApiResponse>;
    public getRoleDetail(culture: string, getRoleDetailRequest?: GetRoleDetailRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<RolePurpleApiResponse>>;
    public getRoleDetail(culture: string, getRoleDetailRequest?: GetRoleDetailRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<RolePurpleApiResponse>>;
    public getRoleDetail(culture: string, getRoleDetailRequest?: GetRoleDetailRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (culture === null || culture === undefined) {
            throw new Error('Required parameter culture was null or undefined when calling GetRoleDetail.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<RolePurpleApiResponse>(`${this.configuration.basePath}/${encodeURIComponent(String(culture))}/api/BackOffice/GetRoleDetail`,
            getRoleDetailRequest,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param culture 
     * @param apiGetPurpleSelectByIdRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getRolesByIdSelect(culture: string, apiGetPurpleSelectByIdRequest?: ApiGetPurpleSelectByIdRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<PurpleSelectPagedResponsePurpleApiResponse>;
    public getRolesByIdSelect(culture: string, apiGetPurpleSelectByIdRequest?: ApiGetPurpleSelectByIdRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<PurpleSelectPagedResponsePurpleApiResponse>>;
    public getRolesByIdSelect(culture: string, apiGetPurpleSelectByIdRequest?: ApiGetPurpleSelectByIdRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<PurpleSelectPagedResponsePurpleApiResponse>>;
    public getRolesByIdSelect(culture: string, apiGetPurpleSelectByIdRequest?: ApiGetPurpleSelectByIdRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (culture === null || culture === undefined) {
            throw new Error('Required parameter culture was null or undefined when calling GetRolesByIdSelect.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<PurpleSelectPagedResponsePurpleApiResponse>(`${this.configuration.basePath}/${encodeURIComponent(String(culture))}/api/BackOffice/GetRolesByIdSelect`,
            apiGetPurpleSelectByIdRequest,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param culture 
     * @param apiGetPurpleSelectMultipleByIdRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getRolesByIdsSelect(culture: string, apiGetPurpleSelectMultipleByIdRequest?: ApiGetPurpleSelectMultipleByIdRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<PurpleSelectPagedResponsePurpleApiResponse>;
    public getRolesByIdsSelect(culture: string, apiGetPurpleSelectMultipleByIdRequest?: ApiGetPurpleSelectMultipleByIdRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<PurpleSelectPagedResponsePurpleApiResponse>>;
    public getRolesByIdsSelect(culture: string, apiGetPurpleSelectMultipleByIdRequest?: ApiGetPurpleSelectMultipleByIdRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<PurpleSelectPagedResponsePurpleApiResponse>>;
    public getRolesByIdsSelect(culture: string, apiGetPurpleSelectMultipleByIdRequest?: ApiGetPurpleSelectMultipleByIdRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (culture === null || culture === undefined) {
            throw new Error('Required parameter culture was null or undefined when calling GetRolesByIdsSelect.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<PurpleSelectPagedResponsePurpleApiResponse>(`${this.configuration.basePath}/${encodeURIComponent(String(culture))}/api/BackOffice/GetRolesByIdsSelect`,
            apiGetPurpleSelectMultipleByIdRequest,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param culture 
     * @param apiGetPurpleSelectByNameRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getRolesByNameSelect(culture: string, apiGetPurpleSelectByNameRequest?: ApiGetPurpleSelectByNameRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<PurpleSelectPagedResponsePurpleApiResponse>;
    public getRolesByNameSelect(culture: string, apiGetPurpleSelectByNameRequest?: ApiGetPurpleSelectByNameRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<PurpleSelectPagedResponsePurpleApiResponse>>;
    public getRolesByNameSelect(culture: string, apiGetPurpleSelectByNameRequest?: ApiGetPurpleSelectByNameRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<PurpleSelectPagedResponsePurpleApiResponse>>;
    public getRolesByNameSelect(culture: string, apiGetPurpleSelectByNameRequest?: ApiGetPurpleSelectByNameRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (culture === null || culture === undefined) {
            throw new Error('Required parameter culture was null or undefined when calling GetRolesByNameSelect.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<PurpleSelectPagedResponsePurpleApiResponse>(`${this.configuration.basePath}/${encodeURIComponent(String(culture))}/api/BackOffice/GetRolesByNameSelect`,
            apiGetPurpleSelectByNameRequest,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param culture 
     * @param apiGetPurpleSelectRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getRolesSelect(culture: string, apiGetPurpleSelectRequest?: ApiGetPurpleSelectRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<PurpleSelectPagedResponsePurpleApiResponse>;
    public getRolesSelect(culture: string, apiGetPurpleSelectRequest?: ApiGetPurpleSelectRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<PurpleSelectPagedResponsePurpleApiResponse>>;
    public getRolesSelect(culture: string, apiGetPurpleSelectRequest?: ApiGetPurpleSelectRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<PurpleSelectPagedResponsePurpleApiResponse>>;
    public getRolesSelect(culture: string, apiGetPurpleSelectRequest?: ApiGetPurpleSelectRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (culture === null || culture === undefined) {
            throw new Error('Required parameter culture was null or undefined when calling GetRolesSelect.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<PurpleSelectPagedResponsePurpleApiResponse>(`${this.configuration.basePath}/${encodeURIComponent(String(culture))}/api/BackOffice/GetRolesSelect`,
            apiGetPurpleSelectRequest,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param culture 
     * @param apiGetPurpleTableRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getRolesTable(culture: string, apiGetPurpleTableRequest?: ApiGetPurpleTableRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<RolePurpleTablePagedResponsePurpleApiResponse>;
    public getRolesTable(culture: string, apiGetPurpleTableRequest?: ApiGetPurpleTableRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<RolePurpleTablePagedResponsePurpleApiResponse>>;
    public getRolesTable(culture: string, apiGetPurpleTableRequest?: ApiGetPurpleTableRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<RolePurpleTablePagedResponsePurpleApiResponse>>;
    public getRolesTable(culture: string, apiGetPurpleTableRequest?: ApiGetPurpleTableRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (culture === null || culture === undefined) {
            throw new Error('Required parameter culture was null or undefined when calling GetRolesTable.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<RolePurpleTablePagedResponsePurpleApiResponse>(`${this.configuration.basePath}/${encodeURIComponent(String(culture))}/api/BackOffice/GetRolesTable`,
            apiGetPurpleTableRequest,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param culture 
     * @param apiGetPurpleTableRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getSaleCouponTable(culture: string, apiGetPurpleTableRequest?: ApiGetPurpleTableRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<PRPVCouponFullPurpleTablePagedResponsePurpleApiResponse>;
    public getSaleCouponTable(culture: string, apiGetPurpleTableRequest?: ApiGetPurpleTableRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<PRPVCouponFullPurpleTablePagedResponsePurpleApiResponse>>;
    public getSaleCouponTable(culture: string, apiGetPurpleTableRequest?: ApiGetPurpleTableRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<PRPVCouponFullPurpleTablePagedResponsePurpleApiResponse>>;
    public getSaleCouponTable(culture: string, apiGetPurpleTableRequest?: ApiGetPurpleTableRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (culture === null || culture === undefined) {
            throw new Error('Required parameter culture was null or undefined when calling GetSaleCouponTable.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<PRPVCouponFullPurpleTablePagedResponsePurpleApiResponse>(`${this.configuration.basePath}/${encodeURIComponent(String(culture))}/api/BackOffice/GetSaleCouponTable`,
            apiGetPurpleTableRequest,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param culture 
     * @param getSaleEventActiveRoleDetailRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getSaleEventActiveRoleDetail(culture: string, getSaleEventActiveRoleDetailRequest?: GetSaleEventActiveRoleDetailRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<SaleEventActiveRolePurpleApiResponse>;
    public getSaleEventActiveRoleDetail(culture: string, getSaleEventActiveRoleDetailRequest?: GetSaleEventActiveRoleDetailRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<SaleEventActiveRolePurpleApiResponse>>;
    public getSaleEventActiveRoleDetail(culture: string, getSaleEventActiveRoleDetailRequest?: GetSaleEventActiveRoleDetailRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<SaleEventActiveRolePurpleApiResponse>>;
    public getSaleEventActiveRoleDetail(culture: string, getSaleEventActiveRoleDetailRequest?: GetSaleEventActiveRoleDetailRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (culture === null || culture === undefined) {
            throw new Error('Required parameter culture was null or undefined when calling GetSaleEventActiveRoleDetail.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<SaleEventActiveRolePurpleApiResponse>(`${this.configuration.basePath}/${encodeURIComponent(String(culture))}/api/BackOffice/GetSaleEventActiveRoleDetail`,
            getSaleEventActiveRoleDetailRequest,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param culture 
     * @param getSaleEventActiveRolesRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getSaleEventActiveRoles(culture: string, getSaleEventActiveRolesRequest?: GetSaleEventActiveRolesRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<RoleTabListPurpleApiResponse>;
    public getSaleEventActiveRoles(culture: string, getSaleEventActiveRolesRequest?: GetSaleEventActiveRolesRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<RoleTabListPurpleApiResponse>>;
    public getSaleEventActiveRoles(culture: string, getSaleEventActiveRolesRequest?: GetSaleEventActiveRolesRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<RoleTabListPurpleApiResponse>>;
    public getSaleEventActiveRoles(culture: string, getSaleEventActiveRolesRequest?: GetSaleEventActiveRolesRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (culture === null || culture === undefined) {
            throw new Error('Required parameter culture was null or undefined when calling GetSaleEventActiveRoles.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<RoleTabListPurpleApiResponse>(`${this.configuration.basePath}/${encodeURIComponent(String(culture))}/api/BackOffice/GetSaleEventActiveRoles`,
            getSaleEventActiveRolesRequest,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param culture 
     * @param apiGetSaleEventAttributesRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getSaleEventAttributes(culture: string, apiGetSaleEventAttributesRequest?: ApiGetSaleEventAttributesRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<SaleEventAttributeValuesItemListPurpleApiResponse>;
    public getSaleEventAttributes(culture: string, apiGetSaleEventAttributesRequest?: ApiGetSaleEventAttributesRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<SaleEventAttributeValuesItemListPurpleApiResponse>>;
    public getSaleEventAttributes(culture: string, apiGetSaleEventAttributesRequest?: ApiGetSaleEventAttributesRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<SaleEventAttributeValuesItemListPurpleApiResponse>>;
    public getSaleEventAttributes(culture: string, apiGetSaleEventAttributesRequest?: ApiGetSaleEventAttributesRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (culture === null || culture === undefined) {
            throw new Error('Required parameter culture was null or undefined when calling GetSaleEventAttributes.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<SaleEventAttributeValuesItemListPurpleApiResponse>(`${this.configuration.basePath}/${encodeURIComponent(String(culture))}/api/BackOffice/GetSaleEventAttributes`,
            apiGetSaleEventAttributesRequest,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param culture 
     * @param apiGetPurpleTableRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getSaleEventBookedUsersTable(culture: string, apiGetPurpleTableRequest?: ApiGetPurpleTableRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<PRPVSaleEventSlotsBookingPurpleTablePagedResponsePurpleApiResponse>;
    public getSaleEventBookedUsersTable(culture: string, apiGetPurpleTableRequest?: ApiGetPurpleTableRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<PRPVSaleEventSlotsBookingPurpleTablePagedResponsePurpleApiResponse>>;
    public getSaleEventBookedUsersTable(culture: string, apiGetPurpleTableRequest?: ApiGetPurpleTableRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<PRPVSaleEventSlotsBookingPurpleTablePagedResponsePurpleApiResponse>>;
    public getSaleEventBookedUsersTable(culture: string, apiGetPurpleTableRequest?: ApiGetPurpleTableRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (culture === null || culture === undefined) {
            throw new Error('Required parameter culture was null or undefined when calling GetSaleEventBookedUsersTable.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<PRPVSaleEventSlotsBookingPurpleTablePagedResponsePurpleApiResponse>(`${this.configuration.basePath}/${encodeURIComponent(String(culture))}/api/BackOffice/GetSaleEventBookedUsersTable`,
            apiGetPurpleTableRequest,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param culture 
     * @param getSaleEventBookingStatsRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getSaleEventBookingsStats(culture: string, getSaleEventBookingStatsRequest?: GetSaleEventBookingStatsRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<AngularSummaryStatsItemListPurpleApiResponse>;
    public getSaleEventBookingsStats(culture: string, getSaleEventBookingStatsRequest?: GetSaleEventBookingStatsRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<AngularSummaryStatsItemListPurpleApiResponse>>;
    public getSaleEventBookingsStats(culture: string, getSaleEventBookingStatsRequest?: GetSaleEventBookingStatsRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<AngularSummaryStatsItemListPurpleApiResponse>>;
    public getSaleEventBookingsStats(culture: string, getSaleEventBookingStatsRequest?: GetSaleEventBookingStatsRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (culture === null || culture === undefined) {
            throw new Error('Required parameter culture was null or undefined when calling GetSaleEventBookingsStats.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<AngularSummaryStatsItemListPurpleApiResponse>(`${this.configuration.basePath}/${encodeURIComponent(String(culture))}/api/BackOffice/GetSaleEventBookingsStats`,
            getSaleEventBookingStatsRequest,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param culture 
     * @param getSaleEventBrandsLimitsRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getSaleEventBrandsLimits(culture: string, getSaleEventBrandsLimitsRequest?: GetSaleEventBrandsLimitsRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<BrandLimitTableItemListPurpleApiResponse>;
    public getSaleEventBrandsLimits(culture: string, getSaleEventBrandsLimitsRequest?: GetSaleEventBrandsLimitsRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<BrandLimitTableItemListPurpleApiResponse>>;
    public getSaleEventBrandsLimits(culture: string, getSaleEventBrandsLimitsRequest?: GetSaleEventBrandsLimitsRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<BrandLimitTableItemListPurpleApiResponse>>;
    public getSaleEventBrandsLimits(culture: string, getSaleEventBrandsLimitsRequest?: GetSaleEventBrandsLimitsRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (culture === null || culture === undefined) {
            throw new Error('Required parameter culture was null or undefined when calling GetSaleEventBrandsLimits.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<BrandLimitTableItemListPurpleApiResponse>(`${this.configuration.basePath}/${encodeURIComponent(String(culture))}/api/BackOffice/GetSaleEventBrandsLimits`,
            getSaleEventBrandsLimitsRequest,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param culture 
     * @param apiGetPurpleTableRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getSaleEventBrandsLimitsTable(culture: string, apiGetPurpleTableRequest?: ApiGetPurpleTableRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<BrandLimitTableItemPurpleTablePagedResponsePurpleApiResponse>;
    public getSaleEventBrandsLimitsTable(culture: string, apiGetPurpleTableRequest?: ApiGetPurpleTableRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<BrandLimitTableItemPurpleTablePagedResponsePurpleApiResponse>>;
    public getSaleEventBrandsLimitsTable(culture: string, apiGetPurpleTableRequest?: ApiGetPurpleTableRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<BrandLimitTableItemPurpleTablePagedResponsePurpleApiResponse>>;
    public getSaleEventBrandsLimitsTable(culture: string, apiGetPurpleTableRequest?: ApiGetPurpleTableRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (culture === null || culture === undefined) {
            throw new Error('Required parameter culture was null or undefined when calling GetSaleEventBrandsLimitsTable.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<BrandLimitTableItemPurpleTablePagedResponsePurpleApiResponse>(`${this.configuration.basePath}/${encodeURIComponent(String(culture))}/api/BackOffice/GetSaleEventBrandsLimitsTable`,
            apiGetPurpleTableRequest,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param culture 
     * @param apiGetPurpleTableRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getSaleEventBulkImportedUsersTable(culture: string, apiGetPurpleTableRequest?: ApiGetPurpleTableRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<PRPVUserBulkImportStatusPurpleTablePagedResponsePurpleApiResponse>;
    public getSaleEventBulkImportedUsersTable(culture: string, apiGetPurpleTableRequest?: ApiGetPurpleTableRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<PRPVUserBulkImportStatusPurpleTablePagedResponsePurpleApiResponse>>;
    public getSaleEventBulkImportedUsersTable(culture: string, apiGetPurpleTableRequest?: ApiGetPurpleTableRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<PRPVUserBulkImportStatusPurpleTablePagedResponsePurpleApiResponse>>;
    public getSaleEventBulkImportedUsersTable(culture: string, apiGetPurpleTableRequest?: ApiGetPurpleTableRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (culture === null || culture === undefined) {
            throw new Error('Required parameter culture was null or undefined when calling GetSaleEventBulkImportedUsersTable.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<PRPVUserBulkImportStatusPurpleTablePagedResponsePurpleApiResponse>(`${this.configuration.basePath}/${encodeURIComponent(String(culture))}/api/BackOffice/GetSaleEventBulkImportedUsersTable`,
            apiGetPurpleTableRequest,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param culture 
     * @param getSaleEventCarouselSlideItemRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getSaleEventCarouselSlideItem(culture: string, getSaleEventCarouselSlideItemRequest?: GetSaleEventCarouselSlideItemRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<SaleEventCarouselSlideItemListPurpleApiResponse>;
    public getSaleEventCarouselSlideItem(culture: string, getSaleEventCarouselSlideItemRequest?: GetSaleEventCarouselSlideItemRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<SaleEventCarouselSlideItemListPurpleApiResponse>>;
    public getSaleEventCarouselSlideItem(culture: string, getSaleEventCarouselSlideItemRequest?: GetSaleEventCarouselSlideItemRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<SaleEventCarouselSlideItemListPurpleApiResponse>>;
    public getSaleEventCarouselSlideItem(culture: string, getSaleEventCarouselSlideItemRequest?: GetSaleEventCarouselSlideItemRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (culture === null || culture === undefined) {
            throw new Error('Required parameter culture was null or undefined when calling GetSaleEventCarouselSlideItem.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<SaleEventCarouselSlideItemListPurpleApiResponse>(`${this.configuration.basePath}/${encodeURIComponent(String(culture))}/api/BackOffice/GetSaleEventCarouselSlideItem`,
            getSaleEventCarouselSlideItemRequest,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param culture 
     * @param getSaleEventCategoryExceptionLimitsRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getSaleEventCategoryExceptionLimits(culture: string, getSaleEventCategoryExceptionLimitsRequest?: GetSaleEventCategoryExceptionLimitsRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<SaleEventLimitedExceptionSaleCategoryListPurpleApiResponse>;
    public getSaleEventCategoryExceptionLimits(culture: string, getSaleEventCategoryExceptionLimitsRequest?: GetSaleEventCategoryExceptionLimitsRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<SaleEventLimitedExceptionSaleCategoryListPurpleApiResponse>>;
    public getSaleEventCategoryExceptionLimits(culture: string, getSaleEventCategoryExceptionLimitsRequest?: GetSaleEventCategoryExceptionLimitsRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<SaleEventLimitedExceptionSaleCategoryListPurpleApiResponse>>;
    public getSaleEventCategoryExceptionLimits(culture: string, getSaleEventCategoryExceptionLimitsRequest?: GetSaleEventCategoryExceptionLimitsRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (culture === null || culture === undefined) {
            throw new Error('Required parameter culture was null or undefined when calling GetSaleEventCategoryExceptionLimits.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<SaleEventLimitedExceptionSaleCategoryListPurpleApiResponse>(`${this.configuration.basePath}/${encodeURIComponent(String(culture))}/api/BackOffice/GetSaleEventCategoryExceptionLimits`,
            getSaleEventCategoryExceptionLimitsRequest,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param culture 
     * @param apiGetPurpleTableRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getSaleEventCategoryExceptionLimitsTable(culture: string, apiGetPurpleTableRequest?: ApiGetPurpleTableRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<SaleEventLimitedExceptionSaleCategoryPurpleTablePagedResponsePurpleApiResponse>;
    public getSaleEventCategoryExceptionLimitsTable(culture: string, apiGetPurpleTableRequest?: ApiGetPurpleTableRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<SaleEventLimitedExceptionSaleCategoryPurpleTablePagedResponsePurpleApiResponse>>;
    public getSaleEventCategoryExceptionLimitsTable(culture: string, apiGetPurpleTableRequest?: ApiGetPurpleTableRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<SaleEventLimitedExceptionSaleCategoryPurpleTablePagedResponsePurpleApiResponse>>;
    public getSaleEventCategoryExceptionLimitsTable(culture: string, apiGetPurpleTableRequest?: ApiGetPurpleTableRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (culture === null || culture === undefined) {
            throw new Error('Required parameter culture was null or undefined when calling GetSaleEventCategoryExceptionLimitsTable.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<SaleEventLimitedExceptionSaleCategoryPurpleTablePagedResponsePurpleApiResponse>(`${this.configuration.basePath}/${encodeURIComponent(String(culture))}/api/BackOffice/GetSaleEventCategoryExceptionLimitsTable`,
            apiGetPurpleTableRequest,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param culture 
     * @param getSaleEventCategoryItemsLimitsRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getSaleEventCategoryItemsLimits(culture: string, getSaleEventCategoryItemsLimitsRequest?: GetSaleEventCategoryItemsLimitsRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<SaleEventLimitedSaleCategoryListPurpleApiResponse>;
    public getSaleEventCategoryItemsLimits(culture: string, getSaleEventCategoryItemsLimitsRequest?: GetSaleEventCategoryItemsLimitsRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<SaleEventLimitedSaleCategoryListPurpleApiResponse>>;
    public getSaleEventCategoryItemsLimits(culture: string, getSaleEventCategoryItemsLimitsRequest?: GetSaleEventCategoryItemsLimitsRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<SaleEventLimitedSaleCategoryListPurpleApiResponse>>;
    public getSaleEventCategoryItemsLimits(culture: string, getSaleEventCategoryItemsLimitsRequest?: GetSaleEventCategoryItemsLimitsRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (culture === null || culture === undefined) {
            throw new Error('Required parameter culture was null or undefined when calling GetSaleEventCategoryItemsLimits.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<SaleEventLimitedSaleCategoryListPurpleApiResponse>(`${this.configuration.basePath}/${encodeURIComponent(String(culture))}/api/BackOffice/GetSaleEventCategoryItemsLimits`,
            getSaleEventCategoryItemsLimitsRequest,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param culture 
     * @param apiGetPurpleTableRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getSaleEventCategoryItemsLimitsTable(culture: string, apiGetPurpleTableRequest?: ApiGetPurpleTableRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<SaleEventLimitedSaleCategoryPurpleTablePagedResponsePurpleApiResponse>;
    public getSaleEventCategoryItemsLimitsTable(culture: string, apiGetPurpleTableRequest?: ApiGetPurpleTableRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<SaleEventLimitedSaleCategoryPurpleTablePagedResponsePurpleApiResponse>>;
    public getSaleEventCategoryItemsLimitsTable(culture: string, apiGetPurpleTableRequest?: ApiGetPurpleTableRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<SaleEventLimitedSaleCategoryPurpleTablePagedResponsePurpleApiResponse>>;
    public getSaleEventCategoryItemsLimitsTable(culture: string, apiGetPurpleTableRequest?: ApiGetPurpleTableRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (culture === null || culture === undefined) {
            throw new Error('Required parameter culture was null or undefined when calling GetSaleEventCategoryItemsLimitsTable.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<SaleEventLimitedSaleCategoryPurpleTablePagedResponsePurpleApiResponse>(`${this.configuration.basePath}/${encodeURIComponent(String(culture))}/api/BackOffice/GetSaleEventCategoryItemsLimitsTable`,
            apiGetPurpleTableRequest,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param culture 
     * @param getSaleEventCloneDateSlotDateRangeRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getSaleEventCloneDateSlotDateRange(culture: string, getSaleEventCloneDateSlotDateRangeRequest?: GetSaleEventCloneDateSlotDateRangeRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<SaleEventCloneDateItemListPurpleApiResponse>;
    public getSaleEventCloneDateSlotDateRange(culture: string, getSaleEventCloneDateSlotDateRangeRequest?: GetSaleEventCloneDateSlotDateRangeRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<SaleEventCloneDateItemListPurpleApiResponse>>;
    public getSaleEventCloneDateSlotDateRange(culture: string, getSaleEventCloneDateSlotDateRangeRequest?: GetSaleEventCloneDateSlotDateRangeRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<SaleEventCloneDateItemListPurpleApiResponse>>;
    public getSaleEventCloneDateSlotDateRange(culture: string, getSaleEventCloneDateSlotDateRangeRequest?: GetSaleEventCloneDateSlotDateRangeRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (culture === null || culture === undefined) {
            throw new Error('Required parameter culture was null or undefined when calling GetSaleEventCloneDateSlotDateRange.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<SaleEventCloneDateItemListPurpleApiResponse>(`${this.configuration.basePath}/${encodeURIComponent(String(culture))}/api/BackOffice/GetSaleEventCloneDateSlotDateRange`,
            getSaleEventCloneDateSlotDateRangeRequest,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param culture 
     * @param getSaleEventDateDayBookingsPreviewRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getSaleEventDateDayBookingsPreview(culture: string, getSaleEventDateDayBookingsPreviewRequest?: GetSaleEventDateDayBookingsPreviewRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<SaleEventSlotBookingPreviewListPurpleApiResponse>;
    public getSaleEventDateDayBookingsPreview(culture: string, getSaleEventDateDayBookingsPreviewRequest?: GetSaleEventDateDayBookingsPreviewRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<SaleEventSlotBookingPreviewListPurpleApiResponse>>;
    public getSaleEventDateDayBookingsPreview(culture: string, getSaleEventDateDayBookingsPreviewRequest?: GetSaleEventDateDayBookingsPreviewRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<SaleEventSlotBookingPreviewListPurpleApiResponse>>;
    public getSaleEventDateDayBookingsPreview(culture: string, getSaleEventDateDayBookingsPreviewRequest?: GetSaleEventDateDayBookingsPreviewRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (culture === null || culture === undefined) {
            throw new Error('Required parameter culture was null or undefined when calling GetSaleEventDateDayBookingsPreview.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<SaleEventSlotBookingPreviewListPurpleApiResponse>(`${this.configuration.basePath}/${encodeURIComponent(String(culture))}/api/BackOffice/GetSaleEventDateDayBookingsPreview`,
            getSaleEventDateDayBookingsPreviewRequest,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param culture 
     * @param apiGetPurpleTableRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getSaleEventDateDayBookingsTable(culture: string, apiGetPurpleTableRequest?: ApiGetPurpleTableRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<PRPVSaleEventSlotsBookingPurpleTablePagedResponsePurpleApiResponse>;
    public getSaleEventDateDayBookingsTable(culture: string, apiGetPurpleTableRequest?: ApiGetPurpleTableRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<PRPVSaleEventSlotsBookingPurpleTablePagedResponsePurpleApiResponse>>;
    public getSaleEventDateDayBookingsTable(culture: string, apiGetPurpleTableRequest?: ApiGetPurpleTableRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<PRPVSaleEventSlotsBookingPurpleTablePagedResponsePurpleApiResponse>>;
    public getSaleEventDateDayBookingsTable(culture: string, apiGetPurpleTableRequest?: ApiGetPurpleTableRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (culture === null || culture === undefined) {
            throw new Error('Required parameter culture was null or undefined when calling GetSaleEventDateDayBookingsTable.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<PRPVSaleEventSlotsBookingPurpleTablePagedResponsePurpleApiResponse>(`${this.configuration.basePath}/${encodeURIComponent(String(culture))}/api/BackOffice/GetSaleEventDateDayBookingsTable`,
            apiGetPurpleTableRequest,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param culture 
     * @param getSaleEventDateDaySlotsRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getSaleEventDateDaySlots(culture: string, getSaleEventDateDaySlotsRequest?: GetSaleEventDateDaySlotsRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<SaleEventDayItemListPurpleApiResponse>;
    public getSaleEventDateDaySlots(culture: string, getSaleEventDateDaySlotsRequest?: GetSaleEventDateDaySlotsRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<SaleEventDayItemListPurpleApiResponse>>;
    public getSaleEventDateDaySlots(culture: string, getSaleEventDateDaySlotsRequest?: GetSaleEventDateDaySlotsRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<SaleEventDayItemListPurpleApiResponse>>;
    public getSaleEventDateDaySlots(culture: string, getSaleEventDateDaySlotsRequest?: GetSaleEventDateDaySlotsRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (culture === null || culture === undefined) {
            throw new Error('Required parameter culture was null or undefined when calling GetSaleEventDateDaySlots.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<SaleEventDayItemListPurpleApiResponse>(`${this.configuration.basePath}/${encodeURIComponent(String(culture))}/api/BackOffice/GetSaleEventDateDaySlots`,
            getSaleEventDateDaySlotsRequest,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param culture 
     * @param getSaleEventDateRangeRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getSaleEventDateRange(culture: string, getSaleEventDateRangeRequest?: GetSaleEventDateRangeRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<SaleEventDateItemListPurpleApiResponse>;
    public getSaleEventDateRange(culture: string, getSaleEventDateRangeRequest?: GetSaleEventDateRangeRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<SaleEventDateItemListPurpleApiResponse>>;
    public getSaleEventDateRange(culture: string, getSaleEventDateRangeRequest?: GetSaleEventDateRangeRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<SaleEventDateItemListPurpleApiResponse>>;
    public getSaleEventDateRange(culture: string, getSaleEventDateRangeRequest?: GetSaleEventDateRangeRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (culture === null || culture === undefined) {
            throw new Error('Required parameter culture was null or undefined when calling GetSaleEventDateRange.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<SaleEventDateItemListPurpleApiResponse>(`${this.configuration.basePath}/${encodeURIComponent(String(culture))}/api/BackOffice/GetSaleEventDateRange`,
            getSaleEventDateRangeRequest,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param culture 
     * @param getSaleEventDayBaseSlotsRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getSaleEventDayBaseSlots(culture: string, getSaleEventDayBaseSlotsRequest?: GetSaleEventDayBaseSlotsRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<SaleEventSlotListPurpleApiResponse>;
    public getSaleEventDayBaseSlots(culture: string, getSaleEventDayBaseSlotsRequest?: GetSaleEventDayBaseSlotsRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<SaleEventSlotListPurpleApiResponse>>;
    public getSaleEventDayBaseSlots(culture: string, getSaleEventDayBaseSlotsRequest?: GetSaleEventDayBaseSlotsRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<SaleEventSlotListPurpleApiResponse>>;
    public getSaleEventDayBaseSlots(culture: string, getSaleEventDayBaseSlotsRequest?: GetSaleEventDayBaseSlotsRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (culture === null || culture === undefined) {
            throw new Error('Required parameter culture was null or undefined when calling GetSaleEventDayBaseSlots.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<SaleEventSlotListPurpleApiResponse>(`${this.configuration.basePath}/${encodeURIComponent(String(culture))}/api/BackOffice/GetSaleEventDayBaseSlots`,
            getSaleEventDayBaseSlotsRequest,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param culture 
     * @param getSaleEventDayRolesSelectRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getSaleEventDayRolesSelect(culture: string, getSaleEventDayRolesSelectRequest?: GetSaleEventDayRolesSelectRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<RoleListPurpleApiResponse>;
    public getSaleEventDayRolesSelect(culture: string, getSaleEventDayRolesSelectRequest?: GetSaleEventDayRolesSelectRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<RoleListPurpleApiResponse>>;
    public getSaleEventDayRolesSelect(culture: string, getSaleEventDayRolesSelectRequest?: GetSaleEventDayRolesSelectRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<RoleListPurpleApiResponse>>;
    public getSaleEventDayRolesSelect(culture: string, getSaleEventDayRolesSelectRequest?: GetSaleEventDayRolesSelectRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (culture === null || culture === undefined) {
            throw new Error('Required parameter culture was null or undefined when calling GetSaleEventDayRolesSelect.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<RoleListPurpleApiResponse>(`${this.configuration.basePath}/${encodeURIComponent(String(culture))}/api/BackOffice/GetSaleEventDayRolesSelect`,
            getSaleEventDayRolesSelectRequest,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param culture 
     * @param getSaleEventDaySlotsSelectRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getSaleEventDaySlotsSelect(culture: string, getSaleEventDaySlotsSelectRequest?: GetSaleEventDaySlotsSelectRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<GetSaleEventDaySlotsSelectItemListPurpleApiResponse>;
    public getSaleEventDaySlotsSelect(culture: string, getSaleEventDaySlotsSelectRequest?: GetSaleEventDaySlotsSelectRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<GetSaleEventDaySlotsSelectItemListPurpleApiResponse>>;
    public getSaleEventDaySlotsSelect(culture: string, getSaleEventDaySlotsSelectRequest?: GetSaleEventDaySlotsSelectRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<GetSaleEventDaySlotsSelectItemListPurpleApiResponse>>;
    public getSaleEventDaySlotsSelect(culture: string, getSaleEventDaySlotsSelectRequest?: GetSaleEventDaySlotsSelectRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (culture === null || culture === undefined) {
            throw new Error('Required parameter culture was null or undefined when calling GetSaleEventDaySlotsSelect.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<GetSaleEventDaySlotsSelectItemListPurpleApiResponse>(`${this.configuration.basePath}/${encodeURIComponent(String(culture))}/api/BackOffice/GetSaleEventDaySlotsSelect`,
            getSaleEventDaySlotsSelectRequest,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param culture 
     * @param apiGetSaleEventDescriptionsRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getSaleEventDescriptions(culture: string, apiGetSaleEventDescriptionsRequest?: ApiGetSaleEventDescriptionsRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<SaleEventDescriptionsPurpleApiResponse>;
    public getSaleEventDescriptions(culture: string, apiGetSaleEventDescriptionsRequest?: ApiGetSaleEventDescriptionsRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<SaleEventDescriptionsPurpleApiResponse>>;
    public getSaleEventDescriptions(culture: string, apiGetSaleEventDescriptionsRequest?: ApiGetSaleEventDescriptionsRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<SaleEventDescriptionsPurpleApiResponse>>;
    public getSaleEventDescriptions(culture: string, apiGetSaleEventDescriptionsRequest?: ApiGetSaleEventDescriptionsRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (culture === null || culture === undefined) {
            throw new Error('Required parameter culture was null or undefined when calling GetSaleEventDescriptions.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<SaleEventDescriptionsPurpleApiResponse>(`${this.configuration.basePath}/${encodeURIComponent(String(culture))}/api/BackOffice/GetSaleEventDescriptions`,
            apiGetSaleEventDescriptionsRequest,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param culture 
     * @param apiGetPurpleSelectByIdRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getSaleEventItemsBrandsByIdSelect(culture: string, apiGetPurpleSelectByIdRequest?: ApiGetPurpleSelectByIdRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<PurpleSelectPagedResponsePurpleApiResponse>;
    public getSaleEventItemsBrandsByIdSelect(culture: string, apiGetPurpleSelectByIdRequest?: ApiGetPurpleSelectByIdRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<PurpleSelectPagedResponsePurpleApiResponse>>;
    public getSaleEventItemsBrandsByIdSelect(culture: string, apiGetPurpleSelectByIdRequest?: ApiGetPurpleSelectByIdRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<PurpleSelectPagedResponsePurpleApiResponse>>;
    public getSaleEventItemsBrandsByIdSelect(culture: string, apiGetPurpleSelectByIdRequest?: ApiGetPurpleSelectByIdRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (culture === null || culture === undefined) {
            throw new Error('Required parameter culture was null or undefined when calling GetSaleEventItemsBrandsByIdSelect.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<PurpleSelectPagedResponsePurpleApiResponse>(`${this.configuration.basePath}/${encodeURIComponent(String(culture))}/api/BackOffice/GetSaleEventItemsBrandsByIdSelect`,
            apiGetPurpleSelectByIdRequest,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param culture 
     * @param apiGetPurpleSelectByNameRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getSaleEventItemsBrandsByNameSelect(culture: string, apiGetPurpleSelectByNameRequest?: ApiGetPurpleSelectByNameRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<PurpleSelectPagedResponsePurpleApiResponse>;
    public getSaleEventItemsBrandsByNameSelect(culture: string, apiGetPurpleSelectByNameRequest?: ApiGetPurpleSelectByNameRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<PurpleSelectPagedResponsePurpleApiResponse>>;
    public getSaleEventItemsBrandsByNameSelect(culture: string, apiGetPurpleSelectByNameRequest?: ApiGetPurpleSelectByNameRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<PurpleSelectPagedResponsePurpleApiResponse>>;
    public getSaleEventItemsBrandsByNameSelect(culture: string, apiGetPurpleSelectByNameRequest?: ApiGetPurpleSelectByNameRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (culture === null || culture === undefined) {
            throw new Error('Required parameter culture was null or undefined when calling GetSaleEventItemsBrandsByNameSelect.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<PurpleSelectPagedResponsePurpleApiResponse>(`${this.configuration.basePath}/${encodeURIComponent(String(culture))}/api/BackOffice/GetSaleEventItemsBrandsByNameSelect`,
            apiGetPurpleSelectByNameRequest,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param culture 
     * @param apiGetPurpleSelectRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getSaleEventItemsBrandsSelect(culture: string, apiGetPurpleSelectRequest?: ApiGetPurpleSelectRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<PurpleSelectPagedResponsePurpleApiResponse>;
    public getSaleEventItemsBrandsSelect(culture: string, apiGetPurpleSelectRequest?: ApiGetPurpleSelectRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<PurpleSelectPagedResponsePurpleApiResponse>>;
    public getSaleEventItemsBrandsSelect(culture: string, apiGetPurpleSelectRequest?: ApiGetPurpleSelectRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<PurpleSelectPagedResponsePurpleApiResponse>>;
    public getSaleEventItemsBrandsSelect(culture: string, apiGetPurpleSelectRequest?: ApiGetPurpleSelectRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (culture === null || culture === undefined) {
            throw new Error('Required parameter culture was null or undefined when calling GetSaleEventItemsBrandsSelect.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<PurpleSelectPagedResponsePurpleApiResponse>(`${this.configuration.basePath}/${encodeURIComponent(String(culture))}/api/BackOffice/GetSaleEventItemsBrandsSelect`,
            apiGetPurpleSelectRequest,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param culture 
     * @param apiGetPurpleSelectByIdRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getSaleEventItemsCategoriesByIdSelect(culture: string, apiGetPurpleSelectByIdRequest?: ApiGetPurpleSelectByIdRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<PurpleSelectPagedResponsePurpleApiResponse>;
    public getSaleEventItemsCategoriesByIdSelect(culture: string, apiGetPurpleSelectByIdRequest?: ApiGetPurpleSelectByIdRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<PurpleSelectPagedResponsePurpleApiResponse>>;
    public getSaleEventItemsCategoriesByIdSelect(culture: string, apiGetPurpleSelectByIdRequest?: ApiGetPurpleSelectByIdRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<PurpleSelectPagedResponsePurpleApiResponse>>;
    public getSaleEventItemsCategoriesByIdSelect(culture: string, apiGetPurpleSelectByIdRequest?: ApiGetPurpleSelectByIdRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (culture === null || culture === undefined) {
            throw new Error('Required parameter culture was null or undefined when calling GetSaleEventItemsCategoriesByIdSelect.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<PurpleSelectPagedResponsePurpleApiResponse>(`${this.configuration.basePath}/${encodeURIComponent(String(culture))}/api/BackOffice/GetSaleEventItemsCategoriesByIdSelect`,
            apiGetPurpleSelectByIdRequest,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param culture 
     * @param apiGetPurpleSelectByNameRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getSaleEventItemsCategoriesByNameSelect(culture: string, apiGetPurpleSelectByNameRequest?: ApiGetPurpleSelectByNameRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<PurpleSelectPagedResponsePurpleApiResponse>;
    public getSaleEventItemsCategoriesByNameSelect(culture: string, apiGetPurpleSelectByNameRequest?: ApiGetPurpleSelectByNameRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<PurpleSelectPagedResponsePurpleApiResponse>>;
    public getSaleEventItemsCategoriesByNameSelect(culture: string, apiGetPurpleSelectByNameRequest?: ApiGetPurpleSelectByNameRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<PurpleSelectPagedResponsePurpleApiResponse>>;
    public getSaleEventItemsCategoriesByNameSelect(culture: string, apiGetPurpleSelectByNameRequest?: ApiGetPurpleSelectByNameRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (culture === null || culture === undefined) {
            throw new Error('Required parameter culture was null or undefined when calling GetSaleEventItemsCategoriesByNameSelect.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<PurpleSelectPagedResponsePurpleApiResponse>(`${this.configuration.basePath}/${encodeURIComponent(String(culture))}/api/BackOffice/GetSaleEventItemsCategoriesByNameSelect`,
            apiGetPurpleSelectByNameRequest,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param culture 
     * @param apiGetPurpleSelectRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getSaleEventItemsCategoriesSelect(culture: string, apiGetPurpleSelectRequest?: ApiGetPurpleSelectRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<PurpleSelectPagedResponsePurpleApiResponse>;
    public getSaleEventItemsCategoriesSelect(culture: string, apiGetPurpleSelectRequest?: ApiGetPurpleSelectRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<PurpleSelectPagedResponsePurpleApiResponse>>;
    public getSaleEventItemsCategoriesSelect(culture: string, apiGetPurpleSelectRequest?: ApiGetPurpleSelectRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<PurpleSelectPagedResponsePurpleApiResponse>>;
    public getSaleEventItemsCategoriesSelect(culture: string, apiGetPurpleSelectRequest?: ApiGetPurpleSelectRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (culture === null || culture === undefined) {
            throw new Error('Required parameter culture was null or undefined when calling GetSaleEventItemsCategoriesSelect.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<PurpleSelectPagedResponsePurpleApiResponse>(`${this.configuration.basePath}/${encodeURIComponent(String(culture))}/api/BackOffice/GetSaleEventItemsCategoriesSelect`,
            apiGetPurpleSelectRequest,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param culture 
     * @param getSaleEventLimitsRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getSaleEventLimits(culture: string, getSaleEventLimitsRequest?: GetSaleEventLimitsRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<SaleEventLimitsPurpleApiResponse>;
    public getSaleEventLimits(culture: string, getSaleEventLimitsRequest?: GetSaleEventLimitsRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<SaleEventLimitsPurpleApiResponse>>;
    public getSaleEventLimits(culture: string, getSaleEventLimitsRequest?: GetSaleEventLimitsRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<SaleEventLimitsPurpleApiResponse>>;
    public getSaleEventLimits(culture: string, getSaleEventLimitsRequest?: GetSaleEventLimitsRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (culture === null || culture === undefined) {
            throw new Error('Required parameter culture was null or undefined when calling GetSaleEventLimits.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<SaleEventLimitsPurpleApiResponse>(`${this.configuration.basePath}/${encodeURIComponent(String(culture))}/api/BackOffice/GetSaleEventLimits`,
            getSaleEventLimitsRequest,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param culture 
     * @param getSaleEventOwnerFiltersRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getSaleEventOwnerFilters(culture: string, getSaleEventOwnerFiltersRequest?: GetSaleEventOwnerFiltersRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<OwnerListPurpleApiResponse>;
    public getSaleEventOwnerFilters(culture: string, getSaleEventOwnerFiltersRequest?: GetSaleEventOwnerFiltersRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<OwnerListPurpleApiResponse>>;
    public getSaleEventOwnerFilters(culture: string, getSaleEventOwnerFiltersRequest?: GetSaleEventOwnerFiltersRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<OwnerListPurpleApiResponse>>;
    public getSaleEventOwnerFilters(culture: string, getSaleEventOwnerFiltersRequest?: GetSaleEventOwnerFiltersRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (culture === null || culture === undefined) {
            throw new Error('Required parameter culture was null or undefined when calling GetSaleEventOwnerFilters.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<OwnerListPurpleApiResponse>(`${this.configuration.basePath}/${encodeURIComponent(String(culture))}/api/BackOffice/GetSaleEventOwnerFilters`,
            getSaleEventOwnerFiltersRequest,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param culture 
     * @param apiGetPurpleTableRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getSaleEventPoliciesTable(culture: string, apiGetPurpleTableRequest?: ApiGetPurpleTableRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<PRPVPolicyFullPurpleTablePagedResponsePurpleApiResponse>;
    public getSaleEventPoliciesTable(culture: string, apiGetPurpleTableRequest?: ApiGetPurpleTableRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<PRPVPolicyFullPurpleTablePagedResponsePurpleApiResponse>>;
    public getSaleEventPoliciesTable(culture: string, apiGetPurpleTableRequest?: ApiGetPurpleTableRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<PRPVPolicyFullPurpleTablePagedResponsePurpleApiResponse>>;
    public getSaleEventPoliciesTable(culture: string, apiGetPurpleTableRequest?: ApiGetPurpleTableRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (culture === null || culture === undefined) {
            throw new Error('Required parameter culture was null or undefined when calling GetSaleEventPoliciesTable.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<PRPVPolicyFullPurpleTablePagedResponsePurpleApiResponse>(`${this.configuration.basePath}/${encodeURIComponent(String(culture))}/api/BackOffice/GetSaleEventPoliciesTable`,
            apiGetPurpleTableRequest,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param culture 
     * @param apiGetSaleEventRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getSaleEvent(culture: string, apiGetSaleEventRequest?: ApiGetSaleEventRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<PRPVSaleEventFullExtendedPurpleApiResponse>;
    public getSaleEvent(culture: string, apiGetSaleEventRequest?: ApiGetSaleEventRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<PRPVSaleEventFullExtendedPurpleApiResponse>>;
    public getSaleEvent(culture: string, apiGetSaleEventRequest?: ApiGetSaleEventRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<PRPVSaleEventFullExtendedPurpleApiResponse>>;
    public getSaleEvent(culture: string, apiGetSaleEventRequest?: ApiGetSaleEventRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (culture === null || culture === undefined) {
            throw new Error('Required parameter culture was null or undefined when calling GetSaleEvent.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<PRPVSaleEventFullExtendedPurpleApiResponse>(`${this.configuration.basePath}/${encodeURIComponent(String(culture))}/api/BackOffice/GetSaleEvent`,
            apiGetSaleEventRequest,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param culture 
     * @param apiGetPurpleSelectByIdRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getSaleEventReferralRolesByIdSelect(culture: string, apiGetPurpleSelectByIdRequest?: ApiGetPurpleSelectByIdRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<PurpleSelectPagedResponsePurpleApiResponse>;
    public getSaleEventReferralRolesByIdSelect(culture: string, apiGetPurpleSelectByIdRequest?: ApiGetPurpleSelectByIdRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<PurpleSelectPagedResponsePurpleApiResponse>>;
    public getSaleEventReferralRolesByIdSelect(culture: string, apiGetPurpleSelectByIdRequest?: ApiGetPurpleSelectByIdRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<PurpleSelectPagedResponsePurpleApiResponse>>;
    public getSaleEventReferralRolesByIdSelect(culture: string, apiGetPurpleSelectByIdRequest?: ApiGetPurpleSelectByIdRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (culture === null || culture === undefined) {
            throw new Error('Required parameter culture was null or undefined when calling GetSaleEventReferralRolesByIdSelect.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<PurpleSelectPagedResponsePurpleApiResponse>(`${this.configuration.basePath}/${encodeURIComponent(String(culture))}/api/BackOffice/GetSaleEventReferralRolesByIdSelect`,
            apiGetPurpleSelectByIdRequest,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param culture 
     * @param apiGetPurpleSelectByNameRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getSaleEventReferralRolesByNameSelect(culture: string, apiGetPurpleSelectByNameRequest?: ApiGetPurpleSelectByNameRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<PurpleSelectPagedResponsePurpleApiResponse>;
    public getSaleEventReferralRolesByNameSelect(culture: string, apiGetPurpleSelectByNameRequest?: ApiGetPurpleSelectByNameRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<PurpleSelectPagedResponsePurpleApiResponse>>;
    public getSaleEventReferralRolesByNameSelect(culture: string, apiGetPurpleSelectByNameRequest?: ApiGetPurpleSelectByNameRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<PurpleSelectPagedResponsePurpleApiResponse>>;
    public getSaleEventReferralRolesByNameSelect(culture: string, apiGetPurpleSelectByNameRequest?: ApiGetPurpleSelectByNameRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (culture === null || culture === undefined) {
            throw new Error('Required parameter culture was null or undefined when calling GetSaleEventReferralRolesByNameSelect.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<PurpleSelectPagedResponsePurpleApiResponse>(`${this.configuration.basePath}/${encodeURIComponent(String(culture))}/api/BackOffice/GetSaleEventReferralRolesByNameSelect`,
            apiGetPurpleSelectByNameRequest,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param culture 
     * @param apiGetPurpleSelectRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getSaleEventReferralRolesSelect(culture: string, apiGetPurpleSelectRequest?: ApiGetPurpleSelectRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<PurpleSelectPagedResponsePurpleApiResponse>;
    public getSaleEventReferralRolesSelect(culture: string, apiGetPurpleSelectRequest?: ApiGetPurpleSelectRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<PurpleSelectPagedResponsePurpleApiResponse>>;
    public getSaleEventReferralRolesSelect(culture: string, apiGetPurpleSelectRequest?: ApiGetPurpleSelectRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<PurpleSelectPagedResponsePurpleApiResponse>>;
    public getSaleEventReferralRolesSelect(culture: string, apiGetPurpleSelectRequest?: ApiGetPurpleSelectRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (culture === null || culture === undefined) {
            throw new Error('Required parameter culture was null or undefined when calling GetSaleEventReferralRolesSelect.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<PurpleSelectPagedResponsePurpleApiResponse>(`${this.configuration.basePath}/${encodeURIComponent(String(culture))}/api/BackOffice/GetSaleEventReferralRolesSelect`,
            apiGetPurpleSelectRequest,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param culture 
     * @param apiGetPurpleTableRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getSaleEventRoleStartInviteStartDatesTable(culture: string, apiGetPurpleTableRequest?: ApiGetPurpleTableRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<PRPVSaleEventRoleInviteStartDatePurpleTablePagedResponsePurpleApiResponse>;
    public getSaleEventRoleStartInviteStartDatesTable(culture: string, apiGetPurpleTableRequest?: ApiGetPurpleTableRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<PRPVSaleEventRoleInviteStartDatePurpleTablePagedResponsePurpleApiResponse>>;
    public getSaleEventRoleStartInviteStartDatesTable(culture: string, apiGetPurpleTableRequest?: ApiGetPurpleTableRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<PRPVSaleEventRoleInviteStartDatePurpleTablePagedResponsePurpleApiResponse>>;
    public getSaleEventRoleStartInviteStartDatesTable(culture: string, apiGetPurpleTableRequest?: ApiGetPurpleTableRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (culture === null || culture === undefined) {
            throw new Error('Required parameter culture was null or undefined when calling GetSaleEventRoleStartInviteStartDatesTable.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<PRPVSaleEventRoleInviteStartDatePurpleTablePagedResponsePurpleApiResponse>(`${this.configuration.basePath}/${encodeURIComponent(String(culture))}/api/BackOffice/GetSaleEventRoleStartInviteStartDatesTable`,
            apiGetPurpleTableRequest,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param culture 
     * @param apiGetPurpleTableRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getSaleEventRoleStopDatesTable(culture: string, apiGetPurpleTableRequest?: ApiGetPurpleTableRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<PRPVSaleEventRoleStopDatePurpleTablePagedResponsePurpleApiResponse>;
    public getSaleEventRoleStopDatesTable(culture: string, apiGetPurpleTableRequest?: ApiGetPurpleTableRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<PRPVSaleEventRoleStopDatePurpleTablePagedResponsePurpleApiResponse>>;
    public getSaleEventRoleStopDatesTable(culture: string, apiGetPurpleTableRequest?: ApiGetPurpleTableRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<PRPVSaleEventRoleStopDatePurpleTablePagedResponsePurpleApiResponse>>;
    public getSaleEventRoleStopDatesTable(culture: string, apiGetPurpleTableRequest?: ApiGetPurpleTableRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (culture === null || culture === undefined) {
            throw new Error('Required parameter culture was null or undefined when calling GetSaleEventRoleStopDatesTable.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<PRPVSaleEventRoleStopDatePurpleTablePagedResponsePurpleApiResponse>(`${this.configuration.basePath}/${encodeURIComponent(String(culture))}/api/BackOffice/GetSaleEventRoleStopDatesTable`,
            apiGetPurpleTableRequest,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param culture 
     * @param apiGetPurpleSelectByIdRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getSaleEventRolesByIdSelect(culture: string, apiGetPurpleSelectByIdRequest?: ApiGetPurpleSelectByIdRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<PurpleSelectPagedResponsePurpleApiResponse>;
    public getSaleEventRolesByIdSelect(culture: string, apiGetPurpleSelectByIdRequest?: ApiGetPurpleSelectByIdRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<PurpleSelectPagedResponsePurpleApiResponse>>;
    public getSaleEventRolesByIdSelect(culture: string, apiGetPurpleSelectByIdRequest?: ApiGetPurpleSelectByIdRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<PurpleSelectPagedResponsePurpleApiResponse>>;
    public getSaleEventRolesByIdSelect(culture: string, apiGetPurpleSelectByIdRequest?: ApiGetPurpleSelectByIdRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (culture === null || culture === undefined) {
            throw new Error('Required parameter culture was null or undefined when calling GetSaleEventRolesByIdSelect.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<PurpleSelectPagedResponsePurpleApiResponse>(`${this.configuration.basePath}/${encodeURIComponent(String(culture))}/api/BackOffice/GetSaleEventRolesByIdSelect`,
            apiGetPurpleSelectByIdRequest,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param culture 
     * @param apiGetPurpleSelectByNameRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getSaleEventRolesByNameSelect(culture: string, apiGetPurpleSelectByNameRequest?: ApiGetPurpleSelectByNameRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<PurpleSelectPagedResponsePurpleApiResponse>;
    public getSaleEventRolesByNameSelect(culture: string, apiGetPurpleSelectByNameRequest?: ApiGetPurpleSelectByNameRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<PurpleSelectPagedResponsePurpleApiResponse>>;
    public getSaleEventRolesByNameSelect(culture: string, apiGetPurpleSelectByNameRequest?: ApiGetPurpleSelectByNameRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<PurpleSelectPagedResponsePurpleApiResponse>>;
    public getSaleEventRolesByNameSelect(culture: string, apiGetPurpleSelectByNameRequest?: ApiGetPurpleSelectByNameRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (culture === null || culture === undefined) {
            throw new Error('Required parameter culture was null or undefined when calling GetSaleEventRolesByNameSelect.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<PurpleSelectPagedResponsePurpleApiResponse>(`${this.configuration.basePath}/${encodeURIComponent(String(culture))}/api/BackOffice/GetSaleEventRolesByNameSelect`,
            apiGetPurpleSelectByNameRequest,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param culture 
     * @param apiGetPurpleSelectRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getSaleEventRolesSelect(culture: string, apiGetPurpleSelectRequest?: ApiGetPurpleSelectRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<PurpleSelectPagedResponsePurpleApiResponse>;
    public getSaleEventRolesSelect(culture: string, apiGetPurpleSelectRequest?: ApiGetPurpleSelectRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<PurpleSelectPagedResponsePurpleApiResponse>>;
    public getSaleEventRolesSelect(culture: string, apiGetPurpleSelectRequest?: ApiGetPurpleSelectRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<PurpleSelectPagedResponsePurpleApiResponse>>;
    public getSaleEventRolesSelect(culture: string, apiGetPurpleSelectRequest?: ApiGetPurpleSelectRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (culture === null || culture === undefined) {
            throw new Error('Required parameter culture was null or undefined when calling GetSaleEventRolesSelect.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<PurpleSelectPagedResponsePurpleApiResponse>(`${this.configuration.basePath}/${encodeURIComponent(String(culture))}/api/BackOffice/GetSaleEventRolesSelect`,
            apiGetPurpleSelectRequest,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param culture 
     * @param getSaleEventSlotBookingUserDetailRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getSaleEventSlotBookingUserDetail(culture: string, getSaleEventSlotBookingUserDetailRequest?: GetSaleEventSlotBookingUserDetailRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<UserBookingDetailAmountPurpleApiResponse>;
    public getSaleEventSlotBookingUserDetail(culture: string, getSaleEventSlotBookingUserDetailRequest?: GetSaleEventSlotBookingUserDetailRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<UserBookingDetailAmountPurpleApiResponse>>;
    public getSaleEventSlotBookingUserDetail(culture: string, getSaleEventSlotBookingUserDetailRequest?: GetSaleEventSlotBookingUserDetailRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<UserBookingDetailAmountPurpleApiResponse>>;
    public getSaleEventSlotBookingUserDetail(culture: string, getSaleEventSlotBookingUserDetailRequest?: GetSaleEventSlotBookingUserDetailRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (culture === null || culture === undefined) {
            throw new Error('Required parameter culture was null or undefined when calling GetSaleEventSlotBookingUserDetail.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<UserBookingDetailAmountPurpleApiResponse>(`${this.configuration.basePath}/${encodeURIComponent(String(culture))}/api/BackOffice/GetSaleEventSlotBookingUserDetail`,
            getSaleEventSlotBookingUserDetailRequest,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param culture 
     * @param getSaleEventSlotBookingsRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getSaleEventSlotBookings(culture: string, getSaleEventSlotBookingsRequest?: GetSaleEventSlotBookingsRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<ApiTreeItemListPurpleApiResponse>;
    public getSaleEventSlotBookings(culture: string, getSaleEventSlotBookingsRequest?: GetSaleEventSlotBookingsRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<ApiTreeItemListPurpleApiResponse>>;
    public getSaleEventSlotBookings(culture: string, getSaleEventSlotBookingsRequest?: GetSaleEventSlotBookingsRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<ApiTreeItemListPurpleApiResponse>>;
    public getSaleEventSlotBookings(culture: string, getSaleEventSlotBookingsRequest?: GetSaleEventSlotBookingsRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (culture === null || culture === undefined) {
            throw new Error('Required parameter culture was null or undefined when calling GetSaleEventSlotBookings.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<ApiTreeItemListPurpleApiResponse>(`${this.configuration.basePath}/${encodeURIComponent(String(culture))}/api/BackOffice/GetSaleEventSlotBookings`,
            getSaleEventSlotBookingsRequest,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param culture 
     * @param getSaleEventSlotsSelectRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getSaleEventSlotsSelect(culture: string, getSaleEventSlotsSelectRequest?: GetSaleEventSlotsSelectRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<GetSaleEventSlotsSelectItemListPurpleApiResponse>;
    public getSaleEventSlotsSelect(culture: string, getSaleEventSlotsSelectRequest?: GetSaleEventSlotsSelectRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<GetSaleEventSlotsSelectItemListPurpleApiResponse>>;
    public getSaleEventSlotsSelect(culture: string, getSaleEventSlotsSelectRequest?: GetSaleEventSlotsSelectRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<GetSaleEventSlotsSelectItemListPurpleApiResponse>>;
    public getSaleEventSlotsSelect(culture: string, getSaleEventSlotsSelectRequest?: GetSaleEventSlotsSelectRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (culture === null || culture === undefined) {
            throw new Error('Required parameter culture was null or undefined when calling GetSaleEventSlotsSelect.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<GetSaleEventSlotsSelectItemListPurpleApiResponse>(`${this.configuration.basePath}/${encodeURIComponent(String(culture))}/api/BackOffice/GetSaleEventSlotsSelect`,
            getSaleEventSlotsSelectRequest,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param culture 
     * @param apiGetSaleEventSystemEventExceptionsRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getSaleEventSystemEventExceptions(culture: string, apiGetSaleEventSystemEventExceptionsRequest?: ApiGetSaleEventSystemEventExceptionsRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<GetSaleEventSystemEventExceptionsResponseListPurpleApiResponse>;
    public getSaleEventSystemEventExceptions(culture: string, apiGetSaleEventSystemEventExceptionsRequest?: ApiGetSaleEventSystemEventExceptionsRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<GetSaleEventSystemEventExceptionsResponseListPurpleApiResponse>>;
    public getSaleEventSystemEventExceptions(culture: string, apiGetSaleEventSystemEventExceptionsRequest?: ApiGetSaleEventSystemEventExceptionsRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<GetSaleEventSystemEventExceptionsResponseListPurpleApiResponse>>;
    public getSaleEventSystemEventExceptions(culture: string, apiGetSaleEventSystemEventExceptionsRequest?: ApiGetSaleEventSystemEventExceptionsRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (culture === null || culture === undefined) {
            throw new Error('Required parameter culture was null or undefined when calling GetSaleEventSystemEventExceptions.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<GetSaleEventSystemEventExceptionsResponseListPurpleApiResponse>(`${this.configuration.basePath}/${encodeURIComponent(String(culture))}/api/BackOffice/GetSaleEventSystemEventExceptions`,
            apiGetSaleEventSystemEventExceptionsRequest,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param culture 
     * @param apiGetPurpleTableRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getSaleEventSystemEventExceptionsTable(culture: string, apiGetPurpleTableRequest?: ApiGetPurpleTableRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<GetSaleEventSystemEventExceptionsResponsePurpleTablePagedResponsePurpleApiResponse>;
    public getSaleEventSystemEventExceptionsTable(culture: string, apiGetPurpleTableRequest?: ApiGetPurpleTableRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<GetSaleEventSystemEventExceptionsResponsePurpleTablePagedResponsePurpleApiResponse>>;
    public getSaleEventSystemEventExceptionsTable(culture: string, apiGetPurpleTableRequest?: ApiGetPurpleTableRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<GetSaleEventSystemEventExceptionsResponsePurpleTablePagedResponsePurpleApiResponse>>;
    public getSaleEventSystemEventExceptionsTable(culture: string, apiGetPurpleTableRequest?: ApiGetPurpleTableRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (culture === null || culture === undefined) {
            throw new Error('Required parameter culture was null or undefined when calling GetSaleEventSystemEventExceptionsTable.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<GetSaleEventSystemEventExceptionsResponsePurpleTablePagedResponsePurpleApiResponse>(`${this.configuration.basePath}/${encodeURIComponent(String(culture))}/api/BackOffice/GetSaleEventSystemEventExceptionsTable`,
            apiGetPurpleTableRequest,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param culture 
     * @param apiGetSaleEventSystemEventsRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getSaleEventSystemEvents(culture: string, apiGetSaleEventSystemEventsRequest?: ApiGetSaleEventSystemEventsRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<GetSaleEventSystemEventsResponseListPurpleApiResponse>;
    public getSaleEventSystemEvents(culture: string, apiGetSaleEventSystemEventsRequest?: ApiGetSaleEventSystemEventsRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<GetSaleEventSystemEventsResponseListPurpleApiResponse>>;
    public getSaleEventSystemEvents(culture: string, apiGetSaleEventSystemEventsRequest?: ApiGetSaleEventSystemEventsRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<GetSaleEventSystemEventsResponseListPurpleApiResponse>>;
    public getSaleEventSystemEvents(culture: string, apiGetSaleEventSystemEventsRequest?: ApiGetSaleEventSystemEventsRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (culture === null || culture === undefined) {
            throw new Error('Required parameter culture was null or undefined when calling GetSaleEventSystemEvents.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<GetSaleEventSystemEventsResponseListPurpleApiResponse>(`${this.configuration.basePath}/${encodeURIComponent(String(culture))}/api/BackOffice/GetSaleEventSystemEvents`,
            apiGetSaleEventSystemEventsRequest,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param culture 
     * @param apiGetPurpleTableRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getSaleEventSystemEventsTable(culture: string, apiGetPurpleTableRequest?: ApiGetPurpleTableRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<GetSaleEventSystemEventsResponsePurpleTablePagedResponsePurpleApiResponse>;
    public getSaleEventSystemEventsTable(culture: string, apiGetPurpleTableRequest?: ApiGetPurpleTableRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<GetSaleEventSystemEventsResponsePurpleTablePagedResponsePurpleApiResponse>>;
    public getSaleEventSystemEventsTable(culture: string, apiGetPurpleTableRequest?: ApiGetPurpleTableRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<GetSaleEventSystemEventsResponsePurpleTablePagedResponsePurpleApiResponse>>;
    public getSaleEventSystemEventsTable(culture: string, apiGetPurpleTableRequest?: ApiGetPurpleTableRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (culture === null || culture === undefined) {
            throw new Error('Required parameter culture was null or undefined when calling GetSaleEventSystemEventsTable.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<GetSaleEventSystemEventsResponsePurpleTablePagedResponsePurpleApiResponse>(`${this.configuration.basePath}/${encodeURIComponent(String(culture))}/api/BackOffice/GetSaleEventSystemEventsTable`,
            apiGetPurpleTableRequest,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param culture 
     * @param apiGetPurpleTableRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getSaleEventUserInvitesTable(culture: string, apiGetPurpleTableRequest?: ApiGetPurpleTableRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<SaleEventUserInviteTableItemPurpleTablePagedResponsePurpleApiResponse>;
    public getSaleEventUserInvitesTable(culture: string, apiGetPurpleTableRequest?: ApiGetPurpleTableRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<SaleEventUserInviteTableItemPurpleTablePagedResponsePurpleApiResponse>>;
    public getSaleEventUserInvitesTable(culture: string, apiGetPurpleTableRequest?: ApiGetPurpleTableRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<SaleEventUserInviteTableItemPurpleTablePagedResponsePurpleApiResponse>>;
    public getSaleEventUserInvitesTable(culture: string, apiGetPurpleTableRequest?: ApiGetPurpleTableRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (culture === null || culture === undefined) {
            throw new Error('Required parameter culture was null or undefined when calling GetSaleEventUserInvitesTable.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<SaleEventUserInviteTableItemPurpleTablePagedResponsePurpleApiResponse>(`${this.configuration.basePath}/${encodeURIComponent(String(culture))}/api/BackOffice/GetSaleEventUserInvitesTable`,
            apiGetPurpleTableRequest,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param culture 
     * @param apiGetPurpleTableRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getSaleEventUserRoles(culture: string, apiGetPurpleTableRequest?: ApiGetPurpleTableRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<SaleEventUserRoleTableItemPurpleTablePagedResponsePurpleApiResponse>;
    public getSaleEventUserRoles(culture: string, apiGetPurpleTableRequest?: ApiGetPurpleTableRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<SaleEventUserRoleTableItemPurpleTablePagedResponsePurpleApiResponse>>;
    public getSaleEventUserRoles(culture: string, apiGetPurpleTableRequest?: ApiGetPurpleTableRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<SaleEventUserRoleTableItemPurpleTablePagedResponsePurpleApiResponse>>;
    public getSaleEventUserRoles(culture: string, apiGetPurpleTableRequest?: ApiGetPurpleTableRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (culture === null || culture === undefined) {
            throw new Error('Required parameter culture was null or undefined when calling GetSaleEventUserRoles.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<SaleEventUserRoleTableItemPurpleTablePagedResponsePurpleApiResponse>(`${this.configuration.basePath}/${encodeURIComponent(String(culture))}/api/BackOffice/GetSaleEventUserRoles`,
            apiGetPurpleTableRequest,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param culture 
     * @param apiGetPurpleTableRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getSaleEventUsersExtendedTable(culture: string, apiGetPurpleTableRequest?: ApiGetPurpleTableRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<SaleEventUserExtendedTableItemPurpleTablePagedResponsePurpleApiResponse>;
    public getSaleEventUsersExtendedTable(culture: string, apiGetPurpleTableRequest?: ApiGetPurpleTableRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<SaleEventUserExtendedTableItemPurpleTablePagedResponsePurpleApiResponse>>;
    public getSaleEventUsersExtendedTable(culture: string, apiGetPurpleTableRequest?: ApiGetPurpleTableRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<SaleEventUserExtendedTableItemPurpleTablePagedResponsePurpleApiResponse>>;
    public getSaleEventUsersExtendedTable(culture: string, apiGetPurpleTableRequest?: ApiGetPurpleTableRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (culture === null || culture === undefined) {
            throw new Error('Required parameter culture was null or undefined when calling GetSaleEventUsersExtendedTable.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<SaleEventUserExtendedTableItemPurpleTablePagedResponsePurpleApiResponse>(`${this.configuration.basePath}/${encodeURIComponent(String(culture))}/api/BackOffice/GetSaleEventUsersExtendedTable`,
            apiGetPurpleTableRequest,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param culture 
     * @param apiGetPurpleTableRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getSaleEventsTable(culture: string, apiGetPurpleTableRequest?: ApiGetPurpleTableRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<PRPVSaleEventPurpleTablePagedResponsePurpleApiResponse>;
    public getSaleEventsTable(culture: string, apiGetPurpleTableRequest?: ApiGetPurpleTableRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<PRPVSaleEventPurpleTablePagedResponsePurpleApiResponse>>;
    public getSaleEventsTable(culture: string, apiGetPurpleTableRequest?: ApiGetPurpleTableRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<PRPVSaleEventPurpleTablePagedResponsePurpleApiResponse>>;
    public getSaleEventsTable(culture: string, apiGetPurpleTableRequest?: ApiGetPurpleTableRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (culture === null || culture === undefined) {
            throw new Error('Required parameter culture was null or undefined when calling GetSaleEventsTable.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<PRPVSaleEventPurpleTablePagedResponsePurpleApiResponse>(`${this.configuration.basePath}/${encodeURIComponent(String(culture))}/api/BackOffice/GetSaleEventsTable`,
            apiGetPurpleTableRequest,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param culture 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getSmtpConfigurations(culture: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<SmtpServerListPurpleApiResponse>;
    public getSmtpConfigurations(culture: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<SmtpServerListPurpleApiResponse>>;
    public getSmtpConfigurations(culture: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<SmtpServerListPurpleApiResponse>>;
    public getSmtpConfigurations(culture: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (culture === null || culture === undefined) {
            throw new Error('Required parameter culture was null or undefined when calling GetSmtpConfigurations.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<SmtpServerListPurpleApiResponse>(`${this.configuration.basePath}/${encodeURIComponent(String(culture))}/api/BackOffice/GetSmtpConfigurations`,
            null,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param culture 
     * @param apiGetPurpleSelectByIdRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getSmtpServersByIdSelect(culture: string, apiGetPurpleSelectByIdRequest?: ApiGetPurpleSelectByIdRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<PurpleSelectPagedResponsePurpleApiResponse>;
    public getSmtpServersByIdSelect(culture: string, apiGetPurpleSelectByIdRequest?: ApiGetPurpleSelectByIdRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<PurpleSelectPagedResponsePurpleApiResponse>>;
    public getSmtpServersByIdSelect(culture: string, apiGetPurpleSelectByIdRequest?: ApiGetPurpleSelectByIdRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<PurpleSelectPagedResponsePurpleApiResponse>>;
    public getSmtpServersByIdSelect(culture: string, apiGetPurpleSelectByIdRequest?: ApiGetPurpleSelectByIdRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (culture === null || culture === undefined) {
            throw new Error('Required parameter culture was null or undefined when calling GetSmtpServersByIdSelect.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<PurpleSelectPagedResponsePurpleApiResponse>(`${this.configuration.basePath}/${encodeURIComponent(String(culture))}/api/BackOffice/GetSmtpServersByIdSelect`,
            apiGetPurpleSelectByIdRequest,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param culture 
     * @param apiGetPurpleSelectByNameRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getSmtpServersByNameSelect(culture: string, apiGetPurpleSelectByNameRequest?: ApiGetPurpleSelectByNameRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<PurpleSelectPagedResponsePurpleApiResponse>;
    public getSmtpServersByNameSelect(culture: string, apiGetPurpleSelectByNameRequest?: ApiGetPurpleSelectByNameRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<PurpleSelectPagedResponsePurpleApiResponse>>;
    public getSmtpServersByNameSelect(culture: string, apiGetPurpleSelectByNameRequest?: ApiGetPurpleSelectByNameRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<PurpleSelectPagedResponsePurpleApiResponse>>;
    public getSmtpServersByNameSelect(culture: string, apiGetPurpleSelectByNameRequest?: ApiGetPurpleSelectByNameRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (culture === null || culture === undefined) {
            throw new Error('Required parameter culture was null or undefined when calling GetSmtpServersByNameSelect.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<PurpleSelectPagedResponsePurpleApiResponse>(`${this.configuration.basePath}/${encodeURIComponent(String(culture))}/api/BackOffice/GetSmtpServersByNameSelect`,
            apiGetPurpleSelectByNameRequest,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param culture 
     * @param apiGetPurpleSelectRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getSmtpServersSelect(culture: string, apiGetPurpleSelectRequest?: ApiGetPurpleSelectRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<PurpleSelectPagedResponsePurpleApiResponse>;
    public getSmtpServersSelect(culture: string, apiGetPurpleSelectRequest?: ApiGetPurpleSelectRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<PurpleSelectPagedResponsePurpleApiResponse>>;
    public getSmtpServersSelect(culture: string, apiGetPurpleSelectRequest?: ApiGetPurpleSelectRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<PurpleSelectPagedResponsePurpleApiResponse>>;
    public getSmtpServersSelect(culture: string, apiGetPurpleSelectRequest?: ApiGetPurpleSelectRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (culture === null || culture === undefined) {
            throw new Error('Required parameter culture was null or undefined when calling GetSmtpServersSelect.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<PurpleSelectPagedResponsePurpleApiResponse>(`${this.configuration.basePath}/${encodeURIComponent(String(culture))}/api/BackOffice/GetSmtpServersSelect`,
            apiGetPurpleSelectRequest,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param culture 
     * @param apiGetPurpleTableRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getSmtpServersTable(culture: string, apiGetPurpleTableRequest?: ApiGetPurpleTableRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<SmtpServerPurpleTablePagedResponsePurpleApiResponse>;
    public getSmtpServersTable(culture: string, apiGetPurpleTableRequest?: ApiGetPurpleTableRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<SmtpServerPurpleTablePagedResponsePurpleApiResponse>>;
    public getSmtpServersTable(culture: string, apiGetPurpleTableRequest?: ApiGetPurpleTableRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<SmtpServerPurpleTablePagedResponsePurpleApiResponse>>;
    public getSmtpServersTable(culture: string, apiGetPurpleTableRequest?: ApiGetPurpleTableRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (culture === null || culture === undefined) {
            throw new Error('Required parameter culture was null or undefined when calling GetSmtpServersTable.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<SmtpServerPurpleTablePagedResponsePurpleApiResponse>(`${this.configuration.basePath}/${encodeURIComponent(String(culture))}/api/BackOffice/GetSmtpServersTable`,
            apiGetPurpleTableRequest,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param culture 
     * @param apiGetPurpleSelectByIdRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getSqlTimezonesByIdSelect(culture: string, apiGetPurpleSelectByIdRequest?: ApiGetPurpleSelectByIdRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<PurpleSelectPagedResponsePurpleApiResponse>;
    public getSqlTimezonesByIdSelect(culture: string, apiGetPurpleSelectByIdRequest?: ApiGetPurpleSelectByIdRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<PurpleSelectPagedResponsePurpleApiResponse>>;
    public getSqlTimezonesByIdSelect(culture: string, apiGetPurpleSelectByIdRequest?: ApiGetPurpleSelectByIdRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<PurpleSelectPagedResponsePurpleApiResponse>>;
    public getSqlTimezonesByIdSelect(culture: string, apiGetPurpleSelectByIdRequest?: ApiGetPurpleSelectByIdRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (culture === null || culture === undefined) {
            throw new Error('Required parameter culture was null or undefined when calling GetSqlTimezonesByIdSelect.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<PurpleSelectPagedResponsePurpleApiResponse>(`${this.configuration.basePath}/${encodeURIComponent(String(culture))}/api/BackOffice/GetSqlTimezonesByIdSelect`,
            apiGetPurpleSelectByIdRequest,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param culture 
     * @param apiGetPurpleSelectByNameRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getSqlTimezonesByNameSelect(culture: string, apiGetPurpleSelectByNameRequest?: ApiGetPurpleSelectByNameRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<PurpleSelectPagedResponsePurpleApiResponse>;
    public getSqlTimezonesByNameSelect(culture: string, apiGetPurpleSelectByNameRequest?: ApiGetPurpleSelectByNameRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<PurpleSelectPagedResponsePurpleApiResponse>>;
    public getSqlTimezonesByNameSelect(culture: string, apiGetPurpleSelectByNameRequest?: ApiGetPurpleSelectByNameRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<PurpleSelectPagedResponsePurpleApiResponse>>;
    public getSqlTimezonesByNameSelect(culture: string, apiGetPurpleSelectByNameRequest?: ApiGetPurpleSelectByNameRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (culture === null || culture === undefined) {
            throw new Error('Required parameter culture was null or undefined when calling GetSqlTimezonesByNameSelect.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<PurpleSelectPagedResponsePurpleApiResponse>(`${this.configuration.basePath}/${encodeURIComponent(String(culture))}/api/BackOffice/GetSqlTimezonesByNameSelect`,
            apiGetPurpleSelectByNameRequest,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param culture 
     * @param apiGetPurpleSelectRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getSqlTimezonesSelect(culture: string, apiGetPurpleSelectRequest?: ApiGetPurpleSelectRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<PurpleSelectPagedResponsePurpleApiResponse>;
    public getSqlTimezonesSelect(culture: string, apiGetPurpleSelectRequest?: ApiGetPurpleSelectRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<PurpleSelectPagedResponsePurpleApiResponse>>;
    public getSqlTimezonesSelect(culture: string, apiGetPurpleSelectRequest?: ApiGetPurpleSelectRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<PurpleSelectPagedResponsePurpleApiResponse>>;
    public getSqlTimezonesSelect(culture: string, apiGetPurpleSelectRequest?: ApiGetPurpleSelectRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (culture === null || culture === undefined) {
            throw new Error('Required parameter culture was null or undefined when calling GetSqlTimezonesSelect.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<PurpleSelectPagedResponsePurpleApiResponse>(`${this.configuration.basePath}/${encodeURIComponent(String(culture))}/api/BackOffice/GetSqlTimezonesSelect`,
            apiGetPurpleSelectRequest,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param culture 
     * @param apiGetSystemEventExceptionExtraKeysRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getSystemEventExceptionExtraKeys(culture: string, apiGetSystemEventExceptionExtraKeysRequest?: ApiGetSystemEventExceptionExtraKeysRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<SystemEventExtrasPurpleApiResponse>;
    public getSystemEventExceptionExtraKeys(culture: string, apiGetSystemEventExceptionExtraKeysRequest?: ApiGetSystemEventExceptionExtraKeysRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<SystemEventExtrasPurpleApiResponse>>;
    public getSystemEventExceptionExtraKeys(culture: string, apiGetSystemEventExceptionExtraKeysRequest?: ApiGetSystemEventExceptionExtraKeysRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<SystemEventExtrasPurpleApiResponse>>;
    public getSystemEventExceptionExtraKeys(culture: string, apiGetSystemEventExceptionExtraKeysRequest?: ApiGetSystemEventExceptionExtraKeysRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (culture === null || culture === undefined) {
            throw new Error('Required parameter culture was null or undefined when calling GetSystemEventExceptionExtraKeys.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<SystemEventExtrasPurpleApiResponse>(`${this.configuration.basePath}/${encodeURIComponent(String(culture))}/api/BackOffice/GetSystemEventExceptionExtraKeys`,
            apiGetSystemEventExceptionExtraKeysRequest,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param culture 
     * @param getSystemEventSaleEventMailTemplateRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getSystemEventSaleEventMailTemplateDetail(culture: string, getSystemEventSaleEventMailTemplateRequest?: GetSystemEventSaleEventMailTemplateRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<SaleEventMailTemplateItemPurpleApiResponse>;
    public getSystemEventSaleEventMailTemplateDetail(culture: string, getSystemEventSaleEventMailTemplateRequest?: GetSystemEventSaleEventMailTemplateRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<SaleEventMailTemplateItemPurpleApiResponse>>;
    public getSystemEventSaleEventMailTemplateDetail(culture: string, getSystemEventSaleEventMailTemplateRequest?: GetSystemEventSaleEventMailTemplateRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<SaleEventMailTemplateItemPurpleApiResponse>>;
    public getSystemEventSaleEventMailTemplateDetail(culture: string, getSystemEventSaleEventMailTemplateRequest?: GetSystemEventSaleEventMailTemplateRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (culture === null || culture === undefined) {
            throw new Error('Required parameter culture was null or undefined when calling GetSystemEventSaleEventMailTemplateDetail.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<SaleEventMailTemplateItemPurpleApiResponse>(`${this.configuration.basePath}/${encodeURIComponent(String(culture))}/api/BackOffice/GetSystemEventSaleEventMailTemplateDetail`,
            getSystemEventSaleEventMailTemplateRequest,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param culture 
     * @param getSystemEventSaleEventMailTemplateExceptionRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getSystemEventSaleEventMailTemplateExceptionDetail(culture: string, getSystemEventSaleEventMailTemplateExceptionRequest?: GetSystemEventSaleEventMailTemplateExceptionRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<SaleEventMailTemplateExceptionItemPurpleApiResponse>;
    public getSystemEventSaleEventMailTemplateExceptionDetail(culture: string, getSystemEventSaleEventMailTemplateExceptionRequest?: GetSystemEventSaleEventMailTemplateExceptionRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<SaleEventMailTemplateExceptionItemPurpleApiResponse>>;
    public getSystemEventSaleEventMailTemplateExceptionDetail(culture: string, getSystemEventSaleEventMailTemplateExceptionRequest?: GetSystemEventSaleEventMailTemplateExceptionRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<SaleEventMailTemplateExceptionItemPurpleApiResponse>>;
    public getSystemEventSaleEventMailTemplateExceptionDetail(culture: string, getSystemEventSaleEventMailTemplateExceptionRequest?: GetSystemEventSaleEventMailTemplateExceptionRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (culture === null || culture === undefined) {
            throw new Error('Required parameter culture was null or undefined when calling GetSystemEventSaleEventMailTemplateExceptionDetail.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<SaleEventMailTemplateExceptionItemPurpleApiResponse>(`${this.configuration.basePath}/${encodeURIComponent(String(culture))}/api/BackOffice/GetSystemEventSaleEventMailTemplateExceptionDetail`,
            getSystemEventSaleEventMailTemplateExceptionRequest,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param culture 
     * @param apiGetPurpleSelectByIdRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getSystemEventsByIdSelect(culture: string, apiGetPurpleSelectByIdRequest?: ApiGetPurpleSelectByIdRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<PurpleSelectPagedResponsePurpleApiResponse>;
    public getSystemEventsByIdSelect(culture: string, apiGetPurpleSelectByIdRequest?: ApiGetPurpleSelectByIdRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<PurpleSelectPagedResponsePurpleApiResponse>>;
    public getSystemEventsByIdSelect(culture: string, apiGetPurpleSelectByIdRequest?: ApiGetPurpleSelectByIdRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<PurpleSelectPagedResponsePurpleApiResponse>>;
    public getSystemEventsByIdSelect(culture: string, apiGetPurpleSelectByIdRequest?: ApiGetPurpleSelectByIdRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (culture === null || culture === undefined) {
            throw new Error('Required parameter culture was null or undefined when calling GetSystemEventsByIdSelect.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<PurpleSelectPagedResponsePurpleApiResponse>(`${this.configuration.basePath}/${encodeURIComponent(String(culture))}/api/BackOffice/GetSystemEventsByIdSelect`,
            apiGetPurpleSelectByIdRequest,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param culture 
     * @param apiGetPurpleSelectByNameRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getSystemEventsByNameSelect(culture: string, apiGetPurpleSelectByNameRequest?: ApiGetPurpleSelectByNameRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<PurpleSelectPagedResponsePurpleApiResponse>;
    public getSystemEventsByNameSelect(culture: string, apiGetPurpleSelectByNameRequest?: ApiGetPurpleSelectByNameRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<PurpleSelectPagedResponsePurpleApiResponse>>;
    public getSystemEventsByNameSelect(culture: string, apiGetPurpleSelectByNameRequest?: ApiGetPurpleSelectByNameRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<PurpleSelectPagedResponsePurpleApiResponse>>;
    public getSystemEventsByNameSelect(culture: string, apiGetPurpleSelectByNameRequest?: ApiGetPurpleSelectByNameRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (culture === null || culture === undefined) {
            throw new Error('Required parameter culture was null or undefined when calling GetSystemEventsByNameSelect.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<PurpleSelectPagedResponsePurpleApiResponse>(`${this.configuration.basePath}/${encodeURIComponent(String(culture))}/api/BackOffice/GetSystemEventsByNameSelect`,
            apiGetPurpleSelectByNameRequest,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param culture 
     * @param apiGetPurpleSelectRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getSystemEventsSelect(culture: string, apiGetPurpleSelectRequest?: ApiGetPurpleSelectRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<PurpleSelectPagedResponsePurpleApiResponse>;
    public getSystemEventsSelect(culture: string, apiGetPurpleSelectRequest?: ApiGetPurpleSelectRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<PurpleSelectPagedResponsePurpleApiResponse>>;
    public getSystemEventsSelect(culture: string, apiGetPurpleSelectRequest?: ApiGetPurpleSelectRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<PurpleSelectPagedResponsePurpleApiResponse>>;
    public getSystemEventsSelect(culture: string, apiGetPurpleSelectRequest?: ApiGetPurpleSelectRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (culture === null || culture === undefined) {
            throw new Error('Required parameter culture was null or undefined when calling GetSystemEventsSelect.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<PurpleSelectPagedResponsePurpleApiResponse>(`${this.configuration.basePath}/${encodeURIComponent(String(culture))}/api/BackOffice/GetSystemEventsSelect`,
            apiGetPurpleSelectRequest,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param culture 
     * @param apiGetPurpleTableRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getTranslationsTable(culture: string, apiGetPurpleTableRequest?: ApiGetPurpleTableRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<PRPVTranslationFullPurpleTablePagedResponsePurpleApiResponse>;
    public getTranslationsTable(culture: string, apiGetPurpleTableRequest?: ApiGetPurpleTableRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<PRPVTranslationFullPurpleTablePagedResponsePurpleApiResponse>>;
    public getTranslationsTable(culture: string, apiGetPurpleTableRequest?: ApiGetPurpleTableRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<PRPVTranslationFullPurpleTablePagedResponsePurpleApiResponse>>;
    public getTranslationsTable(culture: string, apiGetPurpleTableRequest?: ApiGetPurpleTableRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (culture === null || culture === undefined) {
            throw new Error('Required parameter culture was null or undefined when calling GetTranslationsTable.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<PRPVTranslationFullPurpleTablePagedResponsePurpleApiResponse>(`${this.configuration.basePath}/${encodeURIComponent(String(culture))}/api/BackOffice/GetTranslationsTable`,
            apiGetPurpleTableRequest,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param culture 
     * @param apiGetPurpleTableRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getUserAddressesTable(culture: string, apiGetPurpleTableRequest?: ApiGetPurpleTableRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<PRPVUserAddressPurpleTablePagedResponsePurpleApiResponse>;
    public getUserAddressesTable(culture: string, apiGetPurpleTableRequest?: ApiGetPurpleTableRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<PRPVUserAddressPurpleTablePagedResponsePurpleApiResponse>>;
    public getUserAddressesTable(culture: string, apiGetPurpleTableRequest?: ApiGetPurpleTableRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<PRPVUserAddressPurpleTablePagedResponsePurpleApiResponse>>;
    public getUserAddressesTable(culture: string, apiGetPurpleTableRequest?: ApiGetPurpleTableRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (culture === null || culture === undefined) {
            throw new Error('Required parameter culture was null or undefined when calling GetUserAddressesTable.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<PRPVUserAddressPurpleTablePagedResponsePurpleApiResponse>(`${this.configuration.basePath}/${encodeURIComponent(String(culture))}/api/BackOffice/GetUserAddressesTable`,
            apiGetPurpleTableRequest,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param culture 
     * @param apiGetUserDetailBookingsRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getUserDetailBookings(culture: string, apiGetUserDetailBookingsRequest?: ApiGetUserDetailBookingsRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<GetUserProfileBookingsResponsePurpleApiResponse>;
    public getUserDetailBookings(culture: string, apiGetUserDetailBookingsRequest?: ApiGetUserDetailBookingsRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<GetUserProfileBookingsResponsePurpleApiResponse>>;
    public getUserDetailBookings(culture: string, apiGetUserDetailBookingsRequest?: ApiGetUserDetailBookingsRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<GetUserProfileBookingsResponsePurpleApiResponse>>;
    public getUserDetailBookings(culture: string, apiGetUserDetailBookingsRequest?: ApiGetUserDetailBookingsRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (culture === null || culture === undefined) {
            throw new Error('Required parameter culture was null or undefined when calling GetUserDetailBookings.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<GetUserProfileBookingsResponsePurpleApiResponse>(`${this.configuration.basePath}/${encodeURIComponent(String(culture))}/api/BackOffice/GetUserDetailBookings`,
            apiGetUserDetailBookingsRequest,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param culture 
     * @param getUserDetailRequestRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getUserDetail(culture: string, getUserDetailRequestRequest?: GetUserDetailRequestRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<UserDetailPurpleApiResponse>;
    public getUserDetail(culture: string, getUserDetailRequestRequest?: GetUserDetailRequestRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<UserDetailPurpleApiResponse>>;
    public getUserDetail(culture: string, getUserDetailRequestRequest?: GetUserDetailRequestRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<UserDetailPurpleApiResponse>>;
    public getUserDetail(culture: string, getUserDetailRequestRequest?: GetUserDetailRequestRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (culture === null || culture === undefined) {
            throw new Error('Required parameter culture was null or undefined when calling GetUserDetail.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<UserDetailPurpleApiResponse>(`${this.configuration.basePath}/${encodeURIComponent(String(culture))}/api/BackOffice/GetUserDetail`,
            getUserDetailRequestRequest,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param culture 
     * @param apiGetPurpleSelectByIdRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getUsersByIdSelect(culture: string, apiGetPurpleSelectByIdRequest?: ApiGetPurpleSelectByIdRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<PurpleSelectPagedResponsePurpleApiResponse>;
    public getUsersByIdSelect(culture: string, apiGetPurpleSelectByIdRequest?: ApiGetPurpleSelectByIdRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<PurpleSelectPagedResponsePurpleApiResponse>>;
    public getUsersByIdSelect(culture: string, apiGetPurpleSelectByIdRequest?: ApiGetPurpleSelectByIdRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<PurpleSelectPagedResponsePurpleApiResponse>>;
    public getUsersByIdSelect(culture: string, apiGetPurpleSelectByIdRequest?: ApiGetPurpleSelectByIdRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (culture === null || culture === undefined) {
            throw new Error('Required parameter culture was null or undefined when calling GetUsersByIdSelect.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<PurpleSelectPagedResponsePurpleApiResponse>(`${this.configuration.basePath}/${encodeURIComponent(String(culture))}/api/BackOffice/GetUsersByIdSelect`,
            apiGetPurpleSelectByIdRequest,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param culture 
     * @param apiGetPurpleSelectByNameRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getUsersByNameSelect(culture: string, apiGetPurpleSelectByNameRequest?: ApiGetPurpleSelectByNameRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<PurpleSelectPagedResponsePurpleApiResponse>;
    public getUsersByNameSelect(culture: string, apiGetPurpleSelectByNameRequest?: ApiGetPurpleSelectByNameRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<PurpleSelectPagedResponsePurpleApiResponse>>;
    public getUsersByNameSelect(culture: string, apiGetPurpleSelectByNameRequest?: ApiGetPurpleSelectByNameRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<PurpleSelectPagedResponsePurpleApiResponse>>;
    public getUsersByNameSelect(culture: string, apiGetPurpleSelectByNameRequest?: ApiGetPurpleSelectByNameRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (culture === null || culture === undefined) {
            throw new Error('Required parameter culture was null or undefined when calling GetUsersByNameSelect.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<PurpleSelectPagedResponsePurpleApiResponse>(`${this.configuration.basePath}/${encodeURIComponent(String(culture))}/api/BackOffice/GetUsersByNameSelect`,
            apiGetPurpleSelectByNameRequest,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param culture 
     * @param apiGetPurpleSelectRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getUsersSelect(culture: string, apiGetPurpleSelectRequest?: ApiGetPurpleSelectRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<PurpleSelectPagedResponsePurpleApiResponse>;
    public getUsersSelect(culture: string, apiGetPurpleSelectRequest?: ApiGetPurpleSelectRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<PurpleSelectPagedResponsePurpleApiResponse>>;
    public getUsersSelect(culture: string, apiGetPurpleSelectRequest?: ApiGetPurpleSelectRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<PurpleSelectPagedResponsePurpleApiResponse>>;
    public getUsersSelect(culture: string, apiGetPurpleSelectRequest?: ApiGetPurpleSelectRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (culture === null || culture === undefined) {
            throw new Error('Required parameter culture was null or undefined when calling GetUsersSelect.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<PurpleSelectPagedResponsePurpleApiResponse>(`${this.configuration.basePath}/${encodeURIComponent(String(culture))}/api/BackOffice/GetUsersSelect`,
            apiGetPurpleSelectRequest,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param culture 
     * @param apiGetPurpleTableRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getUsersTable(culture: string, apiGetPurpleTableRequest?: ApiGetPurpleTableRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<UserTableItemPurpleTablePagedResponsePurpleApiResponse>;
    public getUsersTable(culture: string, apiGetPurpleTableRequest?: ApiGetPurpleTableRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<UserTableItemPurpleTablePagedResponsePurpleApiResponse>>;
    public getUsersTable(culture: string, apiGetPurpleTableRequest?: ApiGetPurpleTableRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<UserTableItemPurpleTablePagedResponsePurpleApiResponse>>;
    public getUsersTable(culture: string, apiGetPurpleTableRequest?: ApiGetPurpleTableRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (culture === null || culture === undefined) {
            throw new Error('Required parameter culture was null or undefined when calling GetUsersTable.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<UserTableItemPurpleTablePagedResponsePurpleApiResponse>(`${this.configuration.basePath}/${encodeURIComponent(String(culture))}/api/BackOffice/GetUsersTable`,
            apiGetPurpleTableRequest,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param culture 
     * @param excelFile 
     * @param saleEventId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public importExcelInvites(culture: string, excelFile: Blob, saleEventId?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<BooleanPurpleApiResponse>;
    public importExcelInvites(culture: string, excelFile: Blob, saleEventId?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<BooleanPurpleApiResponse>>;
    public importExcelInvites(culture: string, excelFile: Blob, saleEventId?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<BooleanPurpleApiResponse>>;
    public importExcelInvites(culture: string, excelFile: Blob, saleEventId?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (culture === null || culture === undefined) {
            throw new Error('Required parameter culture was null or undefined when calling ImportExcelInvites.');
        }
        if (excelFile === null || excelFile === undefined) {
            throw new Error('Required parameter excelFile was null or undefined when calling ImportExcelInvites.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any; };
        let useForm = false;
        let convertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: this.encoder});
        }

        if (excelFile !== undefined) {
            formParams = formParams.append('ExcelFile', <any>excelFile) as any || formParams;
        }
        if (saleEventId !== undefined) {
            formParams = formParams.append('SaleEventId', <any>saleEventId) as any || formParams;
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<BooleanPurpleApiResponse>(`${this.configuration.basePath}/${encodeURIComponent(String(culture))}/api/BackOffice/ImportExcelInvites`,
            convertFormParamsToString ? formParams.toString() : formParams,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param culture 
     * @param reInviteRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public reInvite(culture: string, reInviteRequest?: ReInviteRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<StringPurpleApiResponse>;
    public reInvite(culture: string, reInviteRequest?: ReInviteRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<StringPurpleApiResponse>>;
    public reInvite(culture: string, reInviteRequest?: ReInviteRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<StringPurpleApiResponse>>;
    public reInvite(culture: string, reInviteRequest?: ReInviteRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (culture === null || culture === undefined) {
            throw new Error('Required parameter culture was null or undefined when calling ReInvite.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<StringPurpleApiResponse>(`${this.configuration.basePath}/${encodeURIComponent(String(culture))}/api/BackOffice/ReInvite`,
            reInviteRequest,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param culture 
     * @param removeSaleEventAttributeValueRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public removeSaleEventAttributeValue(culture: string, removeSaleEventAttributeValueRequest?: RemoveSaleEventAttributeValueRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<BooleanPurpleApiResponse>;
    public removeSaleEventAttributeValue(culture: string, removeSaleEventAttributeValueRequest?: RemoveSaleEventAttributeValueRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<BooleanPurpleApiResponse>>;
    public removeSaleEventAttributeValue(culture: string, removeSaleEventAttributeValueRequest?: RemoveSaleEventAttributeValueRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<BooleanPurpleApiResponse>>;
    public removeSaleEventAttributeValue(culture: string, removeSaleEventAttributeValueRequest?: RemoveSaleEventAttributeValueRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (culture === null || culture === undefined) {
            throw new Error('Required parameter culture was null or undefined when calling RemoveSaleEventAttributeValue.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<BooleanPurpleApiResponse>(`${this.configuration.basePath}/${encodeURIComponent(String(culture))}/api/BackOffice/RemoveSaleEventAttributeValue`,
            removeSaleEventAttributeValueRequest,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param culture 
     * @param reorderSaleEventPoliciesRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public reorderSaleEventPolicies(culture: string, reorderSaleEventPoliciesRequest?: ReorderSaleEventPoliciesRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<BooleanPurpleApiResponse>;
    public reorderSaleEventPolicies(culture: string, reorderSaleEventPoliciesRequest?: ReorderSaleEventPoliciesRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<BooleanPurpleApiResponse>>;
    public reorderSaleEventPolicies(culture: string, reorderSaleEventPoliciesRequest?: ReorderSaleEventPoliciesRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<BooleanPurpleApiResponse>>;
    public reorderSaleEventPolicies(culture: string, reorderSaleEventPoliciesRequest?: ReorderSaleEventPoliciesRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (culture === null || culture === undefined) {
            throw new Error('Required parameter culture was null or undefined when calling ReorderSaleEventPolicies.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<BooleanPurpleApiResponse>(`${this.configuration.basePath}/${encodeURIComponent(String(culture))}/api/BackOffice/ReorderSaleEventPolicies`,
            reorderSaleEventPoliciesRequest,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param culture 
     * @param resetUserPasswordRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public resetUserPassword(culture: string, resetUserPasswordRequest?: ResetUserPasswordRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<BooleanPurpleApiResponse>;
    public resetUserPassword(culture: string, resetUserPasswordRequest?: ResetUserPasswordRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<BooleanPurpleApiResponse>>;
    public resetUserPassword(culture: string, resetUserPasswordRequest?: ResetUserPasswordRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<BooleanPurpleApiResponse>>;
    public resetUserPassword(culture: string, resetUserPasswordRequest?: ResetUserPasswordRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (culture === null || culture === undefined) {
            throw new Error('Required parameter culture was null or undefined when calling ResetUserPassword.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<BooleanPurpleApiResponse>(`${this.configuration.basePath}/${encodeURIComponent(String(culture))}/api/BackOffice/ResetUserPassword`,
            resetUserPasswordRequest,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param culture 
     * @param sendUserRemindersRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public sendUserReminders(culture: string, sendUserRemindersRequest?: SendUserRemindersRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<PurpleApiResponse>;
    public sendUserReminders(culture: string, sendUserRemindersRequest?: SendUserRemindersRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<PurpleApiResponse>>;
    public sendUserReminders(culture: string, sendUserRemindersRequest?: SendUserRemindersRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<PurpleApiResponse>>;
    public sendUserReminders(culture: string, sendUserRemindersRequest?: SendUserRemindersRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (culture === null || culture === undefined) {
            throw new Error('Required parameter culture was null or undefined when calling SendUserReminders.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<PurpleApiResponse>(`${this.configuration.basePath}/${encodeURIComponent(String(culture))}/api/BackOffice/SendUserReminders`,
            sendUserRemindersRequest,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param culture 
     * @param apiSetSaleEventSystemEventVisibilityRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public setSaleEventSystemEventVisibility(culture: string, apiSetSaleEventSystemEventVisibilityRequest?: ApiSetSaleEventSystemEventVisibilityRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<BooleanPurpleApiResponse>;
    public setSaleEventSystemEventVisibility(culture: string, apiSetSaleEventSystemEventVisibilityRequest?: ApiSetSaleEventSystemEventVisibilityRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<BooleanPurpleApiResponse>>;
    public setSaleEventSystemEventVisibility(culture: string, apiSetSaleEventSystemEventVisibilityRequest?: ApiSetSaleEventSystemEventVisibilityRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<BooleanPurpleApiResponse>>;
    public setSaleEventSystemEventVisibility(culture: string, apiSetSaleEventSystemEventVisibilityRequest?: ApiSetSaleEventSystemEventVisibilityRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (culture === null || culture === undefined) {
            throw new Error('Required parameter culture was null or undefined when calling SetSaleEventSystemEventVisibility.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<BooleanPurpleApiResponse>(`${this.configuration.basePath}/${encodeURIComponent(String(culture))}/api/BackOffice/SetSaleEventSystemEventVisibility`,
            apiSetSaleEventSystemEventVisibilityRequest,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param culture 
     * @param testSmtpRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public testSmtpConfiguration(culture: string, testSmtpRequest?: TestSmtpRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<BooleanPurpleApiResponse>;
    public testSmtpConfiguration(culture: string, testSmtpRequest?: TestSmtpRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<BooleanPurpleApiResponse>>;
    public testSmtpConfiguration(culture: string, testSmtpRequest?: TestSmtpRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<BooleanPurpleApiResponse>>;
    public testSmtpConfiguration(culture: string, testSmtpRequest?: TestSmtpRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (culture === null || culture === undefined) {
            throw new Error('Required parameter culture was null or undefined when calling TestSmtpConfiguration.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<BooleanPurpleApiResponse>(`${this.configuration.basePath}/${encodeURIComponent(String(culture))}/api/BackOffice/TestSmtpConfiguration`,
            testSmtpRequest,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param culture 
     * @param unBookSlotAdminRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public unBookSlotAdmin(culture: string, unBookSlotAdminRequest?: UnBookSlotAdminRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<PurpleApiResponse>;
    public unBookSlotAdmin(culture: string, unBookSlotAdminRequest?: UnBookSlotAdminRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<PurpleApiResponse>>;
    public unBookSlotAdmin(culture: string, unBookSlotAdminRequest?: UnBookSlotAdminRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<PurpleApiResponse>>;
    public unBookSlotAdmin(culture: string, unBookSlotAdminRequest?: UnBookSlotAdminRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (culture === null || culture === undefined) {
            throw new Error('Required parameter culture was null or undefined when calling UnBookSlotAdmin.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<PurpleApiResponse>(`${this.configuration.basePath}/${encodeURIComponent(String(culture))}/api/BackOffice/UnBookSlotAdmin`,
            unBookSlotAdminRequest,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param culture 
     * @param apiUpdateReferralRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateReferral(culture: string, apiUpdateReferralRequest?: ApiUpdateReferralRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<BooleanPurpleApiResponse>;
    public updateReferral(culture: string, apiUpdateReferralRequest?: ApiUpdateReferralRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<BooleanPurpleApiResponse>>;
    public updateReferral(culture: string, apiUpdateReferralRequest?: ApiUpdateReferralRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<BooleanPurpleApiResponse>>;
    public updateReferral(culture: string, apiUpdateReferralRequest?: ApiUpdateReferralRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (culture === null || culture === undefined) {
            throw new Error('Required parameter culture was null or undefined when calling UpdateReferral.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<BooleanPurpleApiResponse>(`${this.configuration.basePath}/${encodeURIComponent(String(culture))}/api/BackOffice/UpdateReferral`,
            apiUpdateReferralRequest,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param culture 
     * @param updateSaleEventLimitsRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateSaleEventLimits(culture: string, updateSaleEventLimitsRequest?: UpdateSaleEventLimitsRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<BooleanPurpleApiResponse>;
    public updateSaleEventLimits(culture: string, updateSaleEventLimitsRequest?: UpdateSaleEventLimitsRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<BooleanPurpleApiResponse>>;
    public updateSaleEventLimits(culture: string, updateSaleEventLimitsRequest?: UpdateSaleEventLimitsRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<BooleanPurpleApiResponse>>;
    public updateSaleEventLimits(culture: string, updateSaleEventLimitsRequest?: UpdateSaleEventLimitsRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (culture === null || culture === undefined) {
            throw new Error('Required parameter culture was null or undefined when calling UpdateSaleEventLimits.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<BooleanPurpleApiResponse>(`${this.configuration.basePath}/${encodeURIComponent(String(culture))}/api/BackOffice/UpdateSaleEventLimits`,
            updateSaleEventLimitsRequest,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param culture 
     * @param apiUpdateSaleEventOnlineStatusRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateSaleEventOnlineStatus(culture: string, apiUpdateSaleEventOnlineStatusRequest?: ApiUpdateSaleEventOnlineStatusRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<BooleanPurpleApiResponse>;
    public updateSaleEventOnlineStatus(culture: string, apiUpdateSaleEventOnlineStatusRequest?: ApiUpdateSaleEventOnlineStatusRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<BooleanPurpleApiResponse>>;
    public updateSaleEventOnlineStatus(culture: string, apiUpdateSaleEventOnlineStatusRequest?: ApiUpdateSaleEventOnlineStatusRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<BooleanPurpleApiResponse>>;
    public updateSaleEventOnlineStatus(culture: string, apiUpdateSaleEventOnlineStatusRequest?: ApiUpdateSaleEventOnlineStatusRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (culture === null || culture === undefined) {
            throw new Error('Required parameter culture was null or undefined when calling UpdateSaleEventOnlineStatus.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<BooleanPurpleApiResponse>(`${this.configuration.basePath}/${encodeURIComponent(String(culture))}/api/BackOffice/UpdateSaleEventOnlineStatus`,
            apiUpdateSaleEventOnlineStatusRequest,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param culture 
     * @param apiUpdateSaleEventRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateSaleEvent(culture: string, apiUpdateSaleEventRequest?: ApiUpdateSaleEventRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<PRPVSaleEventFullNullablePurpleApiResponse>;
    public updateSaleEvent(culture: string, apiUpdateSaleEventRequest?: ApiUpdateSaleEventRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<PRPVSaleEventFullNullablePurpleApiResponse>>;
    public updateSaleEvent(culture: string, apiUpdateSaleEventRequest?: ApiUpdateSaleEventRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<PRPVSaleEventFullNullablePurpleApiResponse>>;
    public updateSaleEvent(culture: string, apiUpdateSaleEventRequest?: ApiUpdateSaleEventRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (culture === null || culture === undefined) {
            throw new Error('Required parameter culture was null or undefined when calling UpdateSaleEvent.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<PRPVSaleEventFullNullablePurpleApiResponse>(`${this.configuration.basePath}/${encodeURIComponent(String(culture))}/api/BackOffice/UpdateSaleEvent`,
            apiUpdateSaleEventRequest,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param culture 
     * @param updateSaleEventRoleDatesRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateSaleEventRoleDates(culture: string, updateSaleEventRoleDatesRequest?: UpdateSaleEventRoleDatesRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<BooleanPurpleApiResponse>;
    public updateSaleEventRoleDates(culture: string, updateSaleEventRoleDatesRequest?: UpdateSaleEventRoleDatesRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<BooleanPurpleApiResponse>>;
    public updateSaleEventRoleDates(culture: string, updateSaleEventRoleDatesRequest?: UpdateSaleEventRoleDatesRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<BooleanPurpleApiResponse>>;
    public updateSaleEventRoleDates(culture: string, updateSaleEventRoleDatesRequest?: UpdateSaleEventRoleDatesRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (culture === null || culture === undefined) {
            throw new Error('Required parameter culture was null or undefined when calling UpdateSaleEventRoleDates.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<BooleanPurpleApiResponse>(`${this.configuration.basePath}/${encodeURIComponent(String(culture))}/api/BackOffice/UpdateSaleEventRoleDates`,
            updateSaleEventRoleDatesRequest,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param culture 
     * @param updateShowSaleEventCarouselSliderRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateShowSaleEventCarouselSlider(culture: string, updateShowSaleEventCarouselSliderRequest?: UpdateShowSaleEventCarouselSliderRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<BooleanPurpleApiResponse>;
    public updateShowSaleEventCarouselSlider(culture: string, updateShowSaleEventCarouselSliderRequest?: UpdateShowSaleEventCarouselSliderRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<BooleanPurpleApiResponse>>;
    public updateShowSaleEventCarouselSlider(culture: string, updateShowSaleEventCarouselSliderRequest?: UpdateShowSaleEventCarouselSliderRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<BooleanPurpleApiResponse>>;
    public updateShowSaleEventCarouselSlider(culture: string, updateShowSaleEventCarouselSliderRequest?: UpdateShowSaleEventCarouselSliderRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (culture === null || culture === undefined) {
            throw new Error('Required parameter culture was null or undefined when calling UpdateShowSaleEventCarouselSlider.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<BooleanPurpleApiResponse>(`${this.configuration.basePath}/${encodeURIComponent(String(culture))}/api/BackOffice/UpdateShowSaleEventCarouselSlider`,
            updateShowSaleEventCarouselSliderRequest,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param culture 
     * @param updateSystemEventSaleEventMailTemplateExceptionRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateSystemEventSaleEventMailTemplateException(culture: string, updateSystemEventSaleEventMailTemplateExceptionRequest?: UpdateSystemEventSaleEventMailTemplateExceptionRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<BooleanPurpleApiResponse>;
    public updateSystemEventSaleEventMailTemplateException(culture: string, updateSystemEventSaleEventMailTemplateExceptionRequest?: UpdateSystemEventSaleEventMailTemplateExceptionRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<BooleanPurpleApiResponse>>;
    public updateSystemEventSaleEventMailTemplateException(culture: string, updateSystemEventSaleEventMailTemplateExceptionRequest?: UpdateSystemEventSaleEventMailTemplateExceptionRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<BooleanPurpleApiResponse>>;
    public updateSystemEventSaleEventMailTemplateException(culture: string, updateSystemEventSaleEventMailTemplateExceptionRequest?: UpdateSystemEventSaleEventMailTemplateExceptionRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (culture === null || culture === undefined) {
            throw new Error('Required parameter culture was null or undefined when calling UpdateSystemEventSaleEventMailTemplateException.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<BooleanPurpleApiResponse>(`${this.configuration.basePath}/${encodeURIComponent(String(culture))}/api/BackOffice/UpdateSystemEventSaleEventMailTemplateException`,
            updateSystemEventSaleEventMailTemplateExceptionRequest,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param culture 
     * @param updateSaleEventSystemEventMailTemplateRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateSystemEventSaleEventMailTemplate(culture: string, updateSaleEventSystemEventMailTemplateRequest?: UpdateSaleEventSystemEventMailTemplateRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<BooleanPurpleApiResponse>;
    public updateSystemEventSaleEventMailTemplate(culture: string, updateSaleEventSystemEventMailTemplateRequest?: UpdateSaleEventSystemEventMailTemplateRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<BooleanPurpleApiResponse>>;
    public updateSystemEventSaleEventMailTemplate(culture: string, updateSaleEventSystemEventMailTemplateRequest?: UpdateSaleEventSystemEventMailTemplateRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<BooleanPurpleApiResponse>>;
    public updateSystemEventSaleEventMailTemplate(culture: string, updateSaleEventSystemEventMailTemplateRequest?: UpdateSaleEventSystemEventMailTemplateRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (culture === null || culture === undefined) {
            throw new Error('Required parameter culture was null or undefined when calling UpdateSystemEventSaleEventMailTemplate.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<BooleanPurpleApiResponse>(`${this.configuration.basePath}/${encodeURIComponent(String(culture))}/api/BackOffice/UpdateSystemEventSaleEventMailTemplate`,
            updateSaleEventSystemEventMailTemplateRequest,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
