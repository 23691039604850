import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Helper, PurpleApiMakeRequestResponse, PurpleApiProxyService, PurpleSelectFilter, PurpleTranslationPipe, purpleFormFieldsGroup } from 'purple-lib';
import { AppTranslationService } from 'src/core-modules/localization/localization.service';
import { BackOfficeService, PRPVPolicyFull } from 'src/core-modules/sdk/api';
import { NzModalService, NzModalRef, NZ_MODAL_DATA } from 'ng-zorro-antd/modal';
import { NzMessageService } from 'ng-zorro-antd/message';
import { Subject, takeUntil } from 'rxjs';
import { environment } from 'src/environments/default/environment';
import { EditBasePolicyModalComponent } from '../edit-base-policy-modal/edit-base-policy-modal.component';

@Component({
  selector: 'app-edit-policy-modal',
  templateUrl: './edit-policy-modal.component.html',
  styleUrls: ['./edit-policy-modal.component.scss']
})
export class EditPolicyModalComponent implements OnInit {

  constructor(@Inject(NZ_MODAL_DATA) private data: { policyFull: PRPVPolicyFull | undefined, saleEventId: string, haveAlreadyBookingPolicy: boolean }, private modalRef: NzModalRef, private mesSvc: NzMessageService, private modal: NzModalService, private admSvc: BackOfficeService,
    private tsvc: AppTranslationService, private fb: FormBuilder, private tranPipe: PurpleTranslationPipe,private apiProxySvc: PurpleApiProxyService) { }

  /* @Input() policyFull: PRPVPolicyFull | undefined = undefined;
  @Input() haveAlreadyBookingPolicy: boolean = false;
  @Input() saleEventId: string = Guid.empty(); */

  originalPolicy!: editPolicy;
  newObj!: editPolicy;
  isNew: boolean = false;
  validateForm: FormGroup = this.fb.group({});
  purpleFormFieldGroups: purpleFormFieldsGroup[] | undefined;
  disableForm: boolean = false;
  isLoading: boolean = false;
  availablePositions: { label: string, value: string }[] = [];
  sub: Subject<void> = new Subject();
  refreshFormFields: string[] = [];

  refreshPolicyPosition: number = 0;

  ngOnInit() {
    this.originalPolicy = {
      isMandatoryPolicy: this.data.policyFull?.isMandatoryPolicy,
      policyContentId: (this.data.policyFull == undefined) ? undefined : (this.data.policyFull?.policyContentId ?? this.data.saleEventId + "_" + this.data.policyFull?.policyId + "_" + this.tsvc.translationLanguage.value),
      policyContentTypeId: this.data.policyFull?.policyContentTypeId ?? 'policy',
      policyHtml: this.data.policyFull?.policyHtml ?? undefined,
      policyId: this.data.policyFull?.policyId,
      policyPage: this.data.policyFull?.policyPage,
      policyText: this.data.policyFull?.policyText ?? undefined,
      policyTitle: this.data.policyFull?.policyTitle ?? undefined,
      policyType: this.data.policyFull?.policyTypeId,
      policyUrl: this.data.policyFull?.policyUrl ?? undefined
    }

    if (this.data.policyFull == undefined) {
      this.isNew = true;
    }

    this.newObj = Helper.storeNewObj(this.originalPolicy);

    this.purpleFormFieldGroups = [
      {
        fieldGroupNumber: 1,
        fielGroupBootstrapColumn: 12,
        fieldGroupPaddingLeft: false,
        fieldGroupPaddingRight: false,
        formFieldFormGroup: this.validateForm,
        formFields: [
          {
            fieldType: 'select',
            fieldControlName: 'policyType',
            fieldBootstrapColumn: 12,
            fieldName: this.tranPipe.transform('edit_policy_policy_type', 'Tipologia policy', []),
            fieldPlaceholder: this.tranPipe.transform('edit_policy_policy_type_placeholder', 'Seleziona la tipologia di policy', []),
            fieldValue: this.newObj.policyType,
            fieldIsRequired: true,
            fieldIsDisabled: false,
            fieldPaddingLeft: false,
            fieldPaddingRight: false,
            fieldOptions: [
              { id: 'scrollFunction', value: this.policyTypeScrollFunction },
              { id: 'searchFunction', value: this.policyTypeSearchFunction },
              { id: 'searchByIdFunction', value: this.policyTypeSearchByIdFunction },
              { id: 'refreshFieldNumber', value: 0 },
              { id: 'enableSearch', value: false }
            ]
          },
          {
            fieldType: 'select',
            fieldControlName: 'policy',
            fieldBootstrapColumn: 6,
            fieldName: this.tranPipe.transform('edit_policy_policy', 'policy', []),
            fieldPlaceholder: this.tranPipe.transform('edit_policy_policy_placeholder', 'Seleziona la Policy', []),
            fieldValue: this.newObj.policyId,
            fieldIsRequired: true,
            fieldIsDisabled: !this.isNew || this.newObj.policyType == undefined,
            fieldPaddingLeft: false,
            fieldPaddingRight: true,
            fieldOptions: [
              { id: 'scrollFunction', value: this.policyScrollFunction },
              { id: 'searchFunction', value: this.policySearchFunction },
              { id: 'searchByIdFunction', value: this.policySearchByIdFunction },
              { id: 'refreshFieldNumber', value: 0 },
              { id: 'showAddNewItem', value: true },
              { id: 'addNewItemFunction', value: this.editBasePolicy },
            ]
          },
          {
            fieldType: 'toggle',
            fieldControlName: 'isMandatory',
            fieldBootstrapColumn: 6,
            fieldName: this.tranPipe.transform('edit_policy_is_mandatori', 'Accettazione Obbligatoria', []),
            fieldPlaceholder: this.tranPipe.transform('edit_policy_is_mandatori_placeholder', '', []),
            fieldValue: this.newObj.isMandatoryPolicy,
            fieldIsRequired: false,
            fieldIsDisabled: this.newObj.policyType == undefined,
            fieldPaddingLeft: true,
            fieldPaddingRight: false,
            fieldOptions: []
          },
          {
            fieldType: 'select-local',
            fieldControlName: 'policyPosition',
            fieldBootstrapColumn: 12,
            fieldName: this.tranPipe.transform('edit_policy_position', 'Posizione', []),
            fieldPlaceholder: this.tranPipe.transform('edit_policy_position_placeholder', 'Seleziona la posizione in cui posizionare policy', []),
            fieldValue: this.newObj.policyPage,
            fieldIsRequired: true,
            fieldIsDisabled: !this.isNew || this.newObj.policyType == undefined,
            fieldPaddingLeft: false,
            fieldPaddingRight: false,
            fieldOptions: [
              { id: 'type', value: 'default' },
              { id: 'showArrow', value: true },
              { id: 'enableSearch', value: true },
              { id: 'options', value: this.availablePositions },
              { id: 'refreshFieldNumber', value: this.refreshPolicyPosition }
            ]
          },
          {
            fieldType: 'input-text',
            fieldControlName: 'policyTitle',
            fieldBootstrapColumn: 12,
            fieldName: this.tranPipe.transform('edit_policy_title', 'Titolo', []),
            fieldPlaceholder: this.tranPipe.transform('edit_policy_title_placeholder', 'Inserisci il titolo della policy', []),
            fieldValue: this.newObj.policyTitle,
            fieldIsRequired: true,
            fieldIsDisabled: this.newObj.policyType == undefined,
            fieldPaddingLeft: false,
            fieldPaddingRight: false
          }
        ]
      }
    ]

    if (!this.isNew) {
      this.onSwitchPolicyType(this.newObj.policyType!);
    }


    setTimeout(() => {
      this.validateForm.addControl("policyValue", new FormControl({ value: undefined, disabled: false }));

      this.validateForm.controls["policyType"].valueChanges.pipe(takeUntil(this.sub)).subscribe((s: string | undefined) => {
        console.log("Cambio policy type: ", s)
        if (s != undefined) {
          this.onSwitchPolicyType(s);
          this.validateForm.get("policy")!.enable();
          this.validateForm.get("policyPosition")!.enable();
          this.validateForm.get("isMandatory")!.enable();
          this.validateForm.get("policyTitle")!.enable();
        } else {
          this.onSwitchPolicyType('default');
          this.validateForm.get("policy")!.disable();
          this.validateForm.get("policyPosition")!.disable();
          this.validateForm.get("isMandatory")!.disable();
          this.validateForm.get("policyTitle")!.disable();
        }
      });


      this.validateForm.controls["policyPosition"].valueChanges.pipe(takeUntil(this.sub)).subscribe((s: string | undefined) => {
        this.refreshFormFields = ["policyValue"]
      });
    }, 100);

  }


  changePolicyValueFieldType(policyType: string) {
    const fieldIdx = this.purpleFormFieldGroups!.find(f => f.fieldGroupNumber == 1)!.formFields.findIndex(f => f.fieldControlName.strEq("policyValue"));

    if (fieldIdx != -1) {
      this.purpleFormFieldGroups!.find(f => f.fieldGroupNumber == 1)!.formFields.splice(fieldIdx, 1);
    }

    switch (policyType) {
      case "text":
        if (this.validateForm.contains("policyValue")) {
          this.validateForm.get("policyValue")?.addValidators(Validators.required);
        }
        this.purpleFormFieldGroups!.find(f => f.fieldGroupNumber == 1)!.formFields.push(
          {
            fieldType: 'textarea',
            fieldControlName: 'policyValue',
            fieldBootstrapColumn: 12,
            fieldName: this.tranPipe.transform('edit_policy_text_value', 'Testo', []),
            fieldPlaceholder: this.tranPipe.transform('edit_policy_text_value_placeholder', 'Inserisci il testo della policy', []),
            fieldValue: this.newObj.policyText,
            fieldIsRequired: true,
            fieldIsDisabled: false,
            fieldPaddingLeft: false,
            fieldPaddingRight: false,
            fieldOptions: [{ id: 'rowNumber', value: 4 }]
          }
        );
        break;
      case "link":
        if (this.validateForm.contains("policyValue")) {
          this.validateForm.get("policyValue")?.addValidators(Validators.required);
        }
        this.purpleFormFieldGroups!.find(f => f.fieldGroupNumber == 1)!.formFields.push(
          {
            fieldType: 'input-text',
            fieldControlName: 'policyValue',
            fieldBootstrapColumn: 12,
            fieldName: this.tranPipe.transform('edit_policy_link_value', 'Link', []),
            fieldPlaceholder: this.tranPipe.transform('edit_policy_link_value_placeholder', 'Inserisci il link della policy', []),
            fieldValue: this.newObj.policyUrl,
            fieldIsRequired: true,
            fieldIsDisabled: false,
            fieldPaddingLeft: false,
            fieldPaddingRight: false
          }
        );
        break;
      case "iubenda":
        if (this.validateForm.contains("policyValue")) {
          this.validateForm.get("policyValue")?.addValidators(Validators.required);
        }
        this.purpleFormFieldGroups!.find(f => f.fieldGroupNumber == 1)!.formFields.push(
          {
            fieldType: 'input-text',
            fieldControlName: 'policyValue',
            fieldBootstrapColumn: 12,
            fieldName: this.tranPipe.transform('edit_policy_iubenda_value', 'Link Iubenda', []),
            fieldPlaceholder: this.tranPipe.transform('edit_policy_iubenda_value_placeholder', 'Inserisci il link iubenda della policy', []),
            fieldValue: this.newObj.policyUrl,
            fieldIsRequired: true,
            fieldIsDisabled: false,
            fieldPaddingLeft: false,
            fieldPaddingRight: false
          }
        );
        break;
      case "html":
        if (this.validateForm.contains("policyValue")) {
          this.validateForm.get("policyValue")?.addValidators(Validators.required);
        }
        this.purpleFormFieldGroups!.find(f => f.fieldGroupNumber == 1)!.formFields.push(
          {
            fieldType: 'code-editor',
            fieldControlName: 'policyValue',
            fieldBootstrapColumn: 12,
            fieldName: this.tranPipe.transform('edit_policy_html_value', 'Codice html', []),
            fieldPlaceholder: this.tranPipe.transform('edit_policy_html_value_placeholder', 'Inserisci il codice html della policy', []),
            fieldValue: this.newObj.policyHtml,
            fieldIsRequired: true,
            fieldIsDisabled: false,
            fieldPaddingLeft: false,
            fieldPaddingRight: false,
            fieldOptions: [
              { id: 'min-height', value: '250px' },
              { id: 'language', value: 'html' }
            ]
          }
        );
        break;

      default:
        if (this.validateForm.contains("policyValue")) {
          this.validateForm.get("policyValue")?.removeValidators(Validators.required);
        }
        break;
    }
  }

  onSwitchPolicyType(type: string) {
    switch (type.toLowerCase()) {
      case "default":
        this.purpleFormFieldGroups!.find(f => f.fieldGroupNumber == 1)!.formFields.find(f => f.fieldControlName.strEq("policyPosition"))!.fieldOptions!.find(f => f.id.strEq("options"))!.value = 
        PolicyTypesAllowedPolicyPositionOptions.Default;
        break;
      case "text":
        this.purpleFormFieldGroups!.find(f => f.fieldGroupNumber == 1)!.formFields.find(f => f.fieldControlName.strEq("policyPosition"))!.fieldOptions!.find(f => f.id.strEq("options"))!.value = 
        PolicyTypesAllowedPolicyPositionOptions.Text;
        break;
      case "html":
        this.purpleFormFieldGroups!.find(f => f.fieldGroupNumber == 1)!.formFields.find(f => f.fieldControlName.strEq("policyPosition"))!.fieldOptions!.find(f => f.id.strEq("options"))!.value = 
        PolicyTypesAllowedPolicyPositionOptions.Html;
        break;
      case "iubenda":
        this.purpleFormFieldGroups!.find(f => f.fieldGroupNumber == 1)!.formFields.find(f => f.fieldControlName.strEq("policyPosition"))!.fieldOptions!.find(f => f.id.strEq("options"))!.value = 
        PolicyTypesAllowedPolicyPositionOptions.Iubenda;
        break;
      case "link":
        this.purpleFormFieldGroups!.find(f => f.fieldGroupNumber == 1)!.formFields.find(f => f.fieldControlName.strEq("policyPosition"))!.fieldOptions!.find(f => f.id.strEq("options"))!.value = 
        PolicyTypesAllowedPolicyPositionOptions.Link;
        break;
      case "media":
        this.purpleFormFieldGroups!.find(f => f.fieldGroupNumber == 1)!.formFields.find(f => f.fieldControlName.strEq("policyPosition"))!.fieldOptions!.find(f => f.id.strEq("options"))!.value = 
        PolicyTypesAllowedPolicyPositionOptions.Media;
        break;
      default:
        break;
    }

    this.changePolicyValueFieldType(type);
  }


  async savePolicy() {
    this.isLoading = true;
    for (const i in this.validateForm.controls) {
      this.validateForm.controls[i].markAsDirty();
      this.validateForm.controls[i].updateValueAndValidity();
    }

    if (this.validateForm.valid) {
      const type: string = this.validateForm.controls["policyType"].value;
      await this.apiProxySvc.makeRequestErrorMessage<boolean>(() => this.admSvc.addOrUpdateSaleEventPolicy(this.tsvc.translationLanguage.value, {
        policyId: this.validateForm.controls["policy"].value,
        isMandatoryPolicy: this.validateForm.controls["isMandatory"].value,
        policyPage: this.validateForm.controls["policyPosition"].value,
        policyTypeId: type,
        saleEventId: this.data.saleEventId,
        policyContentId: this.newObj.policyContentId,
        policyContentTypeId: this.newObj.policyContentTypeId,
        policyHtml: type.strEq("html") ? this.validateForm.controls["policyValue"].value : null,
        policyText: type.strEq("text") ? this.validateForm.controls["policyValue"].value : null,
        policyTitle: this.validateForm.controls["policyTitle"].value,
        policyUrl: (type.strEq("link") || type.strEq("iubenda")) ? this.validateForm.controls["policyValue"].value : null,
        isNew: this.isNew
      }), false, "internal-loader", 500, undefined, undefined, (res: PurpleApiMakeRequestResponse<boolean>) => {
        this.mesSvc.success(this.isNew ? this.tranPipe.transform('add_sale_event_policy_ok', 'Policy aggiunta con successo', []) : this.tranPipe.transform('update_sale_event_policy_ok', 'Policy aggiornata con successo', []), {
          nzDuration: environment.MESSAGE_DURATION
        });

        this.modalRef.close(true);
      })
    }

    this.isLoading = false;
  }


  editBasePolicy = () => {
    this.modal.create({
      nzContent: EditBasePolicyModalComponent,
      nzTitle: this.tranPipe.transform('edit_policy_add_new_title', 'Aggiungi nuova policy', []),
      nzWidth: '1000px',
      nzOkText: "Conferma",
      nzClassName: 'purple-modal',
      nzFooter: null,
      nzMaskClosable: false,
    }).afterClose.subscribe(async (policyId: string | undefined) => {
      console.log("CLose edit base: ", policyId)
      if (policyId != undefined) {
        this.purpleFormFieldGroups!.find(f => f.fieldGroupNumber == 1)!.formFields.find(f => f.fieldControlName.strEq("policy"))!.fieldValue = policyId;
        this.refreshFormFields = ["policy"];
      }
    });
  }

  ////////////////////////////////////////POLICY TYPE SELECT///////////////////////////////////////////////

  //ScrollFunction
  policyTypeScrollFunction = (args: { pageNumber: number, pageSize: number, culture: string, searchName: string, searchFilters: PurpleSelectFilter[] }) => {
    args.searchFilters = [
      { propertyName: "saleEventId", filterValues: [this.data.saleEventId] }
    ]

    if(this.validateForm.controls['policyPosition'].value != undefined){
      args.searchFilters.push({ propertyName: "policyPosition", filterValues: [this.validateForm.controls['policyPosition'].value] })
    }

    return this.admSvc.getPolicyTypesSelect(args.culture, args)
  }

  //SearchFunction
  policyTypeSearchFunction = (args: { pageNumber: number, pageSize: number, culture: string, searchName: string, searchFilters: PurpleSelectFilter[] }) => {
    args.searchFilters = [
      { propertyName: "saleEventId", filterValues: [this.data.saleEventId] }
    ]

    if(this.validateForm.controls['policyPosition'].value != undefined){
      args.searchFilters.push({ propertyName: "policyPosition", filterValues: [this.validateForm.controls['policyPosition'].value] })
    }

    return this.admSvc.getPolicyTypesByNameSelect(args.culture, args)
  }

  //InitFunction (SearchByIdFunction)
  policyTypeSearchByIdFunction = (args: { pageNumber: number, pageSize: number, culture: string, searchName: string, searchFilters: PurpleSelectFilter[] }) => {
    return this.admSvc.getPolicyTypesByIdSelect(args.culture, args)
  }


  ////////////////////////////////////////POLICY SELECT///////////////////////////////////////////////////

  //ScrollFunction
  policyScrollFunction = (args: { pageNumber: number, pageSize: number, culture: string, searchName: string, searchFilters: PurpleSelectFilter[] }) => {
    args.searchFilters = [
      { propertyName: "showOnlyNotUsed", filterValues: ["true"] },
      { propertyName: "saleEventId", filterValues: [this.data.saleEventId] }
    ]
    return this.admSvc.getPoliciesSelect(args.culture, args)
  }

  //SearchFunction
  policySearchFunction = (args: { pageNumber: number, pageSize: number, culture: string, searchName: string, searchFilters: PurpleSelectFilter[] }) => {
    args.searchFilters = [
      { propertyName: "showOnlyNotUsed", filterValues: ["true"] },
      { propertyName: "saleEventId", filterValues: [this.data.saleEventId] }
    ]
    return this.admSvc.getPoliciesByNameSelect(args.culture, args)
  }

  //InitFunction (SearchByIdFunction)
  policySearchByIdFunction = (args: { pageNumber: number, pageSize: number, culture: string, searchName: string, searchFilters: PurpleSelectFilter[] }) => {

    return this.admSvc.getPoliciesByIdSelect(args.culture, args)
  }

}

interface editPolicy {
  policyType: string | undefined;
  policyId?: string | undefined;
  policyPage?: string | undefined;
  isMandatoryPolicy?: boolean | undefined;
  policyTitle?: string | undefined;
  policyUrl?: string | undefined;
  policyText?: string | undefined;
  policyHtml?: string | undefined;
  policyContentTypeId?: string | undefined;
  policyContentId?: string | undefined;
}

class PolicyTypesAllowedPolicyPositionOptions {
  public static Default: { label: string, value: string }[] = [
    { label: "Footer", value: "footer" },
    { label: "Form Registrazione", value: "register" },
    { label: "Prenotazione Slot", value: "booking" }
  ];
  public static Iubenda: { label: string, value: string }[] = [
    { label: "Footer", value: "footer" },
    { label: "Form Registrazione", value: "register" },
    { label: "Prenotazione Slot", value: "booking" }
  ];
  public static Media: { label: string, value: string }[] = [
    { label: "Form Registrazione", value: "register" },
    { label: "Footer", value: "footer" }
  ];
  public static Link: { label: string, value: string }[] = [
    { label: "Form Registrazione", value: "register" },
    { label: "Footer", value: "footer" }
  ];
  public static Html: { label: string, value: string }[] = [
    { label: "Form Registrazione", value: "register" },
    { label: "Prenotazione Slot", value: "booking" }
  ];
  public static Text: { label: string, value: string }[] = [
    { label: "Form Registrazione", value: "register" },
    { label: "Prenotazione Slot", value: "booking" }
  ];
}