<div class="country-container ps-d-flex ps-flex-wrap">
  <div class="ps-w-100 ps-d-flex ps-flex-wrap ps-justify-content-center">
    <!-- <div class="ps-w-100 ps-d-flex ps-justify-content-center">
      <img class="country-image" [src]="imagePath" />
    </div> -->
    <div class="button-container ps-w-100 ps-d-flex ps-flex-wrap ps-align-items-center ps-justify-content-center">
      <button (click)="setCountry(country)" class="country-button" *ngFor="let country of countries" nz-button>
          <span class="flag-icon fi fi-{{country.COUNTRY_FLAG}}"></span>
          {{country.COUNTRY_NAME}}
          <span class="arrow" nz-icon nzType="arrow-right" nzTheme="outline"></span>
      </button>
    </div>
  </div>
</div>