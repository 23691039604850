import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/default/environment';
import { CountryModule } from './app/country/country.module';

if (environment.production) {
  enableProdMode();
}


const urlParams = new URLSearchParams(window.location.search);
var hasCountry = false;

if (urlParams.has('country')) {
  hasCountry = true;
  const c = urlParams.get('country');
  const country = environment.COUNTRY_SELECT.find(f => f.COUNTRY_FLAG.strEq(c!))

  if (country != null && country != undefined) {
    localStorage.setItem("BookingHost", country.BOOKING_CLIENT_HOST);
    localStorage.setItem("BasePath", country.COUNTRY_BASE_PATH);
    localStorage.setItem("MediaPath", country.COUNTRY_MEDIA_PATH);
    localStorage.setItem("DefaultLanguage", country.COUNTRY_DEFAULT_LANGUAGE);
  } else {
    hasCountry = false;
  }

  urlParams.delete('country');
  const newUrl = window.location.protocol + "//" + window.location.host + window.location.pathname + "?" + urlParams.toString();
  window.history.replaceState({}, '', newUrl);
}

const basePath = localStorage.getItem("BasePath");
const mediaPath = localStorage.getItem("MediaPath");
const defaultLanguage = localStorage.getItem("DefaultLanguage");
const bookingHost = localStorage.getItem("BookingHost");

if (!environment.SHOW_COUNTRY_SELECT || (basePath != undefined && basePath != null && defaultLanguage != undefined && defaultLanguage != null && bookingHost != undefined && bookingHost != null
  && mediaPath != undefined && mediaPath != null)) {

  if (!environment.SHOW_COUNTRY_SELECT && !hasCountry) {
    localStorage.setItem("BasePath", environment.COUNTRY_SELECT[0].COUNTRY_BASE_PATH);
    localStorage.setItem("MediaPath", environment.COUNTRY_SELECT[0].COUNTRY_MEDIA_PATH);
    localStorage.setItem("DefaultLanguage", environment.COUNTRY_SELECT[0].COUNTRY_DEFAULT_LANGUAGE);
    localStorage.setItem("BookingHost", environment.COUNTRY_SELECT[0].BOOKING_CLIENT_HOST);
  }

  platformBrowserDynamic().bootstrapModule(AppModule)
    .then(() => {
      const icon1 = document.createElement('link');
      const icon2 = document.createElement('link');
      const icon3 = document.createElement('link');
      const manifest = document.createElement('link');
      const icon4 = document.createElement('link');

      const title = document.createElement('title');

      const safeCompanyAbb = String.prototype.isNullOrEmpty(environment.COMPANY_ABB) ? "default" : environment.COMPANY_ABB;
      const safeCompanyName = String.prototype.isNullOrEmpty(environment.COMPANY_NAME) ? "PurpleAdmin" : environment.COMPANY_NAME;


      title.innerText = safeCompanyName + " - Manager"

      document.head.appendChild(title)

      const meta1 = document.createElement('meta');
      meta1.name = "msapplication-TileColor";
      meta1.content = String.prototype.isNullOrEmpty(environment.COMPANY_NAME) ? "#000000" : environment.HTML_TITLE_COLOR;
      document.head.appendChild(meta1)

      const meta2 = document.createElement('meta');
      meta2.name = "theme-color";
      meta2.content = String.prototype.isNullOrEmpty(environment.HTML_THEME_COLOR) ? "#000000" : environment.HTML_THEME_COLOR;
      document.head.appendChild(meta2)

      fetch("/assets/icons/" + safeCompanyAbb + "/manifest.webmanifest")
        .then(
          () => {

            icon1.rel = "apple-touch-icon";
            icon1.setAttribute("sizes", "180x180")
            icon1.href = "/assets/icons/" + safeCompanyAbb + "/apple-touch-icon.png"

            icon2.rel = "icon";
            icon2.type = "image/png";
            icon2.setAttribute("sizes", "32x32")
            icon2.href = "/assets/icons/" + safeCompanyAbb + "/favicon-32x32.png"

            icon3.rel = "icon";
            icon3.type = "image/png";
            icon3.setAttribute("sizes", "16x16")
            icon3.href = "/assets/icons/" + safeCompanyAbb + "/favicon-16x16.png"

            manifest.href = "/assets/icons/" + safeCompanyAbb + "/manifest.webmanifest"

            icon4.rel = "mask-icon"
            icon4.href = "/assets/icons/" + safeCompanyAbb + "/safari-pinned-tab.svg"
            icon4.setAttribute("color", String.prototype.isNullOrEmpty(environment.SAFARI_COLOR) ? "#000000" : environment.SAFARI_COLOR)

            document.head.appendChild(icon1)
            document.head.appendChild(icon2)
            document.head.appendChild(icon3)
            document.head.appendChild(icon4)
            document.head.appendChild(manifest)
          }
        )
        .catch(
          () => {
            icon1.rel = "apple-touch-icon";
            icon1.setAttribute("sizes", "180x180")
            icon1.href = "/assets/icons/default/apple-touch-icon.png"

            icon2.rel = "icon";
            icon2.type = "image/png";
            icon2.setAttribute("sizes", "32x32")
            icon2.href = "/assets/icons/default/favicon-32x32.png"

            icon3.rel = "icon";
            icon3.type = "image/png";
            icon3.setAttribute("sizes", "16x16")
            icon3.href = "/assets/icons/default/favicon-16x16.png"

            manifest.href = "/assets/icons/default/manifest.webmanifest"

            icon4.rel = "mask-icon"
            icon4.href = "/assets/icons/default/safari-pinned-tab.svg"
            icon4.setAttribute("color", "#000000")

            document.head.appendChild(icon1)
            document.head.appendChild(icon2)
            document.head.appendChild(icon3)
            document.head.appendChild(icon4)
            document.head.appendChild(manifest)
          }
        )
    }).catch(err => console.error(err));
}else{
  platformBrowserDynamic()
  .bootstrapModule(CountryModule)
  .catch(err => console.error(err));
}