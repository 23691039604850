import { Component, OnInit } from '@angular/core';
import { PurpleLoaderService, PurpleTranslationPipe, PurpleApiProxyService, PurpleTableColumn, PurpleTableSearchValue, PurpleTableSelectFilter, PurpleTableBoxColumn, PurpleSelectFilter, PurpleStorageService } from 'purple-lib';
import { Subject, takeUntil } from 'rxjs';
import { AppTranslationService } from 'src/core-modules/localization/localization.service';

import { BackOfficeService, DefaultCouponPurpleTableResponse, DefaultCoupon, PurpleTableSearchGroupGroup } from 'src/core-modules/sdk/api';
import { DashboardLayout2Service } from '../../dashboard-layout-2/dashboard-layout-2.service';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NzMessageService } from 'ng-zorro-antd/message';
import { environment } from 'src/environments/default/environment';
import { EditDefaultCouponModalComponent } from '../modals/edit-default-coupon-modal/edit-default-coupon-modal.component';

@Component({
  selector: 'app-coupons-registry',
  templateUrl: './coupons-registry.component.html',
  styleUrls: ['./coupons-registry.component.scss']
})
export class CouponsRegistryComponent implements OnInit {

  constructor(public strSvc: PurpleStorageService, public dashSvc: DashboardLayout2Service, public backSvc: BackOfficeService, private loaderSvc: PurpleLoaderService,
    public tsvc: AppTranslationService, private modal: NzModalService, private tranPipe: PurpleTranslationPipe, private mesSvc: NzMessageService, private apiProxySvc: PurpleApiProxyService) {
  }

  ngAfterViewInit(): void {
    const pageName = "coupons-registry";
    this.dashSvc.setPageHeader(
      this.tranPipe.transform(pageName + '_page_title', 'Anagrafica Coupon', []),
      this.tranPipe.transform(pageName + '_page_subtitle', 'Lista dei coupon base presenti nel sistema', []))
  }

  ngOnDestroy(): void {
    this.dashSvc.clearPageHeader();

    this.sub.next();
    this.sub.complete();
  }
  sub: Subject<void> = new Subject();

  passwordVisible: string[] = [];
  listOfData: Array<DefaultCouponPurpleTableResponse> | undefined | null;
  refreshTableRowsNumber: number = 0;
  
  //ScrollFunction
  couponTypesScrollFunction = (args: { pageNumber: number, pageSize: number, culture: string, searchName: string, searchFilters: PurpleSelectFilter[] }) => {
    return this.backSvc.getCouponTypesSelect(args.culture, args)
  }
  //SearchFunction
  couponTypesSearchFunction = (args: { pageNumber: number, pageSize: number, culture: string, searchName: string, searchFilters: PurpleSelectFilter[] }) => {
    return this.backSvc.getCouponTypesByNameSelect(args.culture, args)
  }
  //InitFunction (SearchByIdFunction)
  couponTypesSearchByIdFunction = (args: { pageNumber: number, pageSize: number, culture: string, searchName: string, searchFilters: PurpleSelectFilter[] }) => {
    return this.backSvc.getCouponTypesByIdSelect(args.culture, args)
  }

  tabColumns: PurpleTableBoxColumn[] = [
    {
      name: this.tranPipe.transform("default_coupon_table_header_name", "Nome"),
      dbKey: "defaultCouponName",
      hasReorder: true,
      hasFilter: true,
      filterField: {
        fieldType: 'input-text',
        fieldControlName: 'defaultCouponName',
        fieldBootstrapColumn: 6,
        fieldName: this.tranPipe.transform('default_coupon_table_box_filter_name', 'Nome template', []),
        fieldPlaceholder: this.tranPipe.transform('default_coupon_table_box_filter_name_placeholder', 'Nome del template coupon', []),
        fieldValue: undefined,
        fieldIsRequired: false,
        fieldIsDisabled: false,
        fieldPaddingLeft: false,
        fieldPaddingRight: true
      },
      operatorType: "like"
    },
    {
      name: this.tranPipe.transform("default_coupon_table_header_discount_type", "Tipologia Sconto"),
      dbKey: "isCouponDiscountMoneyEnabled",
      hasReorder: true,
      hasFilter: true,
      filterField: {
        fieldType: 'select-local',
        fieldControlName: 'isCouponDiscountMoneyEnabled',
        fieldBootstrapColumn: 6,
        fieldName: this.tranPipe.transform('default_coupon_table_box_filter_discount_type', 'Tipologia sconto', []),
        fieldPlaceholder: this.tranPipe.transform('default_coupon_table_box_filter_discount_type_placeholder', 'Tipologia sconto', []),
        fieldValue: undefined,
        fieldIsRequired: false,
        fieldIsDisabled: false,
        fieldPaddingLeft: true,
        fieldPaddingRight: false,
        fieldOptions: [
          { id: 'isMultiple', value: false },
          { id: 'showArrow', value: true },
          { id: 'enableSearch', value: false },
          {
            id: 'options', value: [
              {
                label: this.tranPipe.transform('table_discount_type_money', 'Soldi', []),
                value: true
              },
              {
                label: this.tranPipe.transform('table_discount_type_precentage', 'Percentuale', []),
                value: false
              }
            ]
          }
        ]
      },
      operatorType: '='
    },
    {
      name: this.tranPipe.transform("default_coupon_table_header_discount", "Sconto"),
      dbKey: "",
      hasReorder: false,
      hasFilter: false
    },
    {
      name: this.tranPipe.transform("default_coupon_table_header_total_max_redeem", "Max utilizzi"),
      dbKey: "couponMaxTotalRedeems",
      hasReorder: true,
      hasFilter: true,
      filterField: {
        fieldType: 'input-number',
        fieldControlName: 'couponMaxTotalRedeems',
        fieldBootstrapColumn: 6,
        fieldName: this.tranPipe.transform('default_coupon_table_box_filter_total_max_redeem', 'Numero massimo utilizzi', []),
        fieldPlaceholder: this.tranPipe.transform('default_coupon_table_box_filter_total_max_redeem_placeholder', 'Numero massimo utilizzi', []),
        fieldValue: undefined,
        fieldIsRequired: false,
        fieldIsDisabled: false,
        fieldPaddingLeft: false,
        fieldPaddingRight: true,
        fieldOptions: [{ id: 'minNumber', value: 0 }]
      },
      operatorType: "="
    },
    {
      name: this.tranPipe.transform("default_coupon_table_header_max_user_redeem", "Max utilizzi utente"),
      dbKey: "couponMaxUserRedeems",
      hasReorder: true,
      hasFilter: true,
      filterField: {
        fieldType: 'input-number',
        fieldControlName: 'couponMaxUserRedeems',
        fieldBootstrapColumn: 6,
        fieldName: this.tranPipe.transform('default_coupon_table_box_filter_max_user_redeem', 'Numero massimo utilizzi per utente', []),
        fieldPlaceholder: this.tranPipe.transform('default_coupon_table_box_filter_max_user_redeem_placeholder', 'Numero massimo utilizzi per utente', []),
        fieldValue: undefined,
        fieldIsRequired: false,
        fieldIsDisabled: false,
        fieldPaddingLeft: true,
        fieldPaddingRight: false,
        fieldOptions: [{ id: 'minNumber', value: 0 }]
      },
      operatorType: "="
    },
    {
      name: this.tranPipe.transform("default_coupon_table_header_is_cumulative", "Cumulabile"),
      dbKey: "couponIsCumulative",
      hasReorder: true,
      hasFilter: true,
      filterField: {
        fieldType: 'select-local',
        fieldControlName: 'couponIsCumulative',
        fieldBootstrapColumn: 12,
        fieldName: this.tranPipe.transform('default_coupon_table_box_filter_is_cumulative', 'Cumulabile', []),
        fieldPlaceholder: this.tranPipe.transform('default_coupon_table_box_filter_is_cumulative_placeholder', 'Coupon cumulabile', []),
        fieldValue: undefined,
        fieldIsRequired: false,
        fieldIsDisabled: false,
        fieldPaddingLeft: false,
        fieldPaddingRight: false,
        fieldOptions: [
          { id: 'isMultiple', value: false },
          { id: 'showArrow', value: true },
          { id: 'enableSearch', value: false },
          {
            id: 'options', value: [
              {
                label: 'Si',
                value: 1
              },
              {
                label: 'No',
                value: 0
              }
            ]
          }
        ]
      },
      operatorType: "="
    },
    {
      name: this.tranPipe.transform("table_header_actions", "Azioni"),
      hasReorder: false,
      hasFilter: false,
      fixedWidth: "180px"
    }
  ]

  refreshFunction = (args: {
    pageNumber: number, pageSize: number, orderColumnName?: string | null, orderType?: string | null,
    tableSearchValues?: Array<PurpleTableSearchGroupGroup> | null, tableFilters?: Array<PurpleTableSelectFilter> | null, switchOn?: boolean,
    culture: string
  }) => {
    return this.backSvc.getDefaultCouponTable(args.culture, args);
  }


  async ngOnInit() {
    this.loaderSvc.addRequest("internal-loader")
    this.dashSvc.dashMainItems = [];

    this.dashSvc.dashMainItems?.push({
      id: "bt",
      type: "button",
      buttonText: this.tranPipe.transform("default_coupon_table_add_new_item_button", "Aggiungi template coupon", []),
      buttonFunction: () => { this.editCoupon(undefined) }
    })

    this.loaderSvc.removeRequest("internal-loader", 350)
  }


  editCoupon(defaultCoupon: DefaultCoupon | undefined) {
    this.modal.create<EditDefaultCouponModalComponent, DefaultCoupon | undefined>({
      nzContent: EditDefaultCouponModalComponent,
      nzData: defaultCoupon,
      nzTitle: defaultCoupon == undefined ? this.tranPipe.transform("default_coupon_table_item_edit_modal_title_new", "Aggiungi template coupon", []) :
        this.tranPipe.transform("default_coupon_table_item_edit_modal_title_update", "Modifica template coupon <b>{0}</b>", [defaultCoupon.defaultCouponName]),
      nzWidth: '800px',
      nzOkText: "Conferma",
      nzClassName: 'purple-modal',
      nzFooter: null,
      nzMaskClosable: false,
    }).afterClose.pipe(takeUntil(this.sub)).subscribe(async (refresh: boolean) => {
      if (refresh) {
        this.refreshTableRowsNumber++;
      }
    });
  }

  async deleteCoupon(defaultCoupon: DefaultCoupon) {
    this.modal.create({
      nzTitle: this.tranPipe.transform('delete_default_coupon_title', 'Eliminare <b>{0}</b>', [defaultCoupon.defaultCouponName]),
      nzContent: this.tranPipe.transform('delete_default_coupon_subtitle', 'Vuoi davvero eliminare il coupon <b>{0}</b>', [defaultCoupon.defaultCouponName]),
      nzWidth: '600px',
      nzClassName: 'purple-simple-modal',
      nzOkText: this.tranPipe.transform('delete_default_coupon_button', 'Elimina', []),
      nzOnOk: async () =>
        await this.apiProxySvc.makeRequestErrorMessage<boolean>(() => this.backSvc.deleteCouponBase(this.tsvc.currentLanguage.value, {
          defaultCouponId: defaultCoupon.defaultCouponId
        }), false, "internal-loader", 500, undefined, undefined, () => {
          this.mesSvc.success(this.tranPipe.transform("delete_default_coupon_ok_response", "Template coupon elimninato correttamente"), {
            nzDuration: environment.MESSAGE_DURATION
          });
          this.refreshTableRowsNumber++;
          this.modal.closeAll();
        })
    })
  }

}
