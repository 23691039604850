import { Component, Inject, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalRef, NZ_MODAL_DATA } from 'ng-zorro-antd/modal';
import { Guid, Helper, PurpleSelectFilter, PurpleTranslationPipe, purpleFormFieldsGroup } from 'purple-lib';
import { PurpleApiMakeRequestResponse, PurpleApiProxyService } from 'purple-lib';
import { AuthenticationService } from 'src/core-modules/authentication/authentication.service';
import { AppTranslationService } from 'src/core-modules/localization/localization.service';

import { UserTableItem, BackOfficeService } from 'src/core-modules/sdk/api';
import { environment } from 'src/environments/default/environment';

@Component({
  selector: 'app-edit-employee',
  templateUrl: './edit-employee.component.html',
  styleUrls: ['./edit-employee.component.scss']
})
export class EditEmployeeComponent implements OnInit {

  employee!: editEmployeeItem;
  newObj!: editEmployeeItem;

  isNew: boolean = false;
  validateForm: FormGroup = this.fb.group({});
  purpleFormFieldGroups: purpleFormFieldsGroup[] | undefined;
  disableForm: boolean = false;
  isLoading: boolean = false;
  refreshUserScroll: number = 0;
  refreshRolesScroll: number = 0;

  rolesSearchFilter: PurpleSelectFilter[] = [
    { propertyName: "roleType", filterValues: ["staff"],  }
  ];

  constructor(@Inject(NZ_MODAL_DATA) private employeeFull: UserTableItem | undefined, private modalRef: NzModalRef, private mesSvc: NzMessageService,private tranPipe: PurpleTranslationPipe, private apiProxySvc: PurpleApiProxyService,
    private backUserSvc: BackOfficeService, private tsvc: AppTranslationService, private fb: FormBuilder, private authSvc: AuthenticationService ) { }

  async ngOnInit(): Promise<void> {
    if(!this.authSvc.isPurpleAdminUser()){
      this.rolesSearchFilter.push({ propertyName: "rolesToExclude", filterValues: [Guid.empty()] })
    }


    if (this.employeeFull == undefined) {
      this.employee = {
        userId: "",
        roleIds: []
      }

      this.isNew = true;
    } else {
      this.employee = {
        userId: this.employeeFull.userId,
        roleIds: this.employeeFull.roles.map(m => m.roleId)
      }
    }

    this.newObj = Helper.storeNewObj(this.employee)

    this.purpleFormFieldGroups = [
      {
        fieldGroupNumber: 1,
        fielGroupBootstrapColumn: 12,
        fieldGroupPaddingLeft: false,
        fieldGroupPaddingRight: false,
        formFieldFormGroup: this.validateForm,
        formFields: [
          {
            fieldType: 'select',
            fieldControlName: 'user',
            fieldBootstrapColumn: 12,
            fieldName: this.tranPipe.transform('edit_employee_user', 'Utente', []),
            fieldPlaceholder: this.tranPipe.transform('edit_employee_user_placeholder', 'Utente', []),

            fieldCustomClass: undefined,
            fieldOptions: [
              { id: 'scrollFunction', value: this.usersScrollFunction },
              { id: 'searchFunction', value: this.usersSearchFunction },
              { id: 'searchByIdFunction', value: this.usersSearchByIdFunction },
              { id: 'refreshFieldNumber', value: this.refreshUserScroll }

            ],
            fieldValue: this.newObj.userId,
            fieldIsRequired: true,
            fieldIsDisabled: false,
            fieldPaddingLeft: false,
            fieldPaddingRight: false
          },
          {
            fieldType: 'select-multiple',
            fieldControlName: 'roles',
            fieldBootstrapColumn: 12,
            fieldName: this.tranPipe.transform('edit_employee_roles', 'Ruoli', []),
            fieldPlaceholder: this.tranPipe.transform('edit_employee_roles_placeholder', 'Seleziona i ruoli che vuoi assegnare', []),
            fieldCustomClass: undefined,
            fieldOptions: [
              { id: 'scrollFunction', value: this.rolesScrollFunction },
              { id: 'searchFunction', value: this.rolesSearchFunction },
              { id: 'searchByIdFunction', value: this.rolesSearchByIdFunction },
              { id: 'refreshFieldNumber', value: this.refreshRolesScroll }
            ],
            fieldValue: this.newObj.roleIds,
            fieldIsRequired: true,
            fieldIsDisabled: false,
            fieldPaddingLeft: false,
            fieldPaddingRight: false
          }
        ]
        }
      ]
  }

  //ScrollFunction
  usersScrollFunction = (args: { pageNumber: number, pageSize: number, culture: string, searchName: string, searchFilters: PurpleSelectFilter[] }) => {
    return this.backUserSvc.getUsersSelect(args.culture, args)
  }
  //SearchFunction
  usersSearchFunction = (args: { pageNumber: number, pageSize: number, culture: string, searchName: string, searchFilters: PurpleSelectFilter[] }) => {
    return this.backUserSvc.getUsersByNameSelect(args.culture, args)
  }
  //InitFunction (SearchByIdFunction)
  usersSearchByIdFunction = (args: { pageNumber: number, pageSize: number, culture: string, searchName: string, searchFilters: PurpleSelectFilter[] }) => {
    return this.backUserSvc.getUsersByIdSelect(args.culture, args)
  }

  //ScrollFunction
  rolesScrollFunction = (args: { pageNumber: number, pageSize: number, culture: string, searchName: string, searchFilters: PurpleSelectFilter[] }) => {
    args.searchFilters = this.rolesSearchFilter
    return this.backUserSvc.getRolesSelect(args.culture, args)
  }
  //SearchFunction
  rolesSearchFunction = (args: { pageNumber: number, pageSize: number, culture: string, searchName: string, searchFilters: PurpleSelectFilter[] }) => {
    args.searchFilters = this.rolesSearchFilter
    return this.backUserSvc.getRolesByNameSelect(args.culture, args)
  }
  //InitFunction (SearchByIdFunction)
  rolesSearchByIdFunction = (args: { pageNumber: number, pageSize: number, culture: string, searchName: string[], searchFilters: PurpleSelectFilter[] }) => {
    args.searchFilters = this.rolesSearchFilter
    return this.backUserSvc.getRolesByIdsSelect(args.culture, args)
  }



  async editEmployee() {
    this.isLoading = true;

    this.newObj.userId = this.validateForm.controls["user"].value;
    this.newObj.roleIds = this.validateForm.controls["roles"].value;

    for (const i in this.validateForm.controls) {
      this.validateForm.controls[i].markAsDirty();
      this.validateForm.controls[i].updateValueAndValidity();
    }

    if (this.validateForm.valid) {
      console.log("Check: ", Helper.checkIfDoChanges(this.newObj, this.employee))
      if (Helper.checkIfDoChanges(this.newObj, this.employee)) {
       
        await this.apiProxySvc.makeRequestErrorMessage<boolean>(()=>this.backUserSvc.addOrUpdateEmployee(this.tsvc.currentLanguage.value, {
          userId: this.newObj.userId,
          roles: this.newObj.roleIds,
          isNew: this.isNew
        }),false, "internal-loader",500,undefined,undefined,(res: PurpleApiMakeRequestResponse<Boolean>)=>{
          let text: string;
          if (this.isNew) {
            text = this.tranPipe.transform("edit_employee_add", "Dipendente aggiunto con successo");
          } else {
            text = this.tranPipe.transform("edit_employee_update", "Dipendente aggiornato con successo");
          }
          this.mesSvc.success(text, {
            nzDuration: environment.MESSAGE_DURATION
          });
          this.isLoading = false;
          this.modalRef.destroy(res.data);
        })
      } else {
        this.mesSvc.warning(this.tranPipe.transform('edit_no_changes','Nessuna modifica effettuata', []), {
          nzDuration: environment.MESSAGE_DURATION
        });
        this.isLoading = false
      }
    }else{
      this.isLoading = false;
    }

  }

}

export interface editEmployeeItem {
  userId: string;
  roleIds: string[];
}