import { Component, Inject, Input, OnInit } from '@angular/core';
import { PurpleApiMakeRequestResponse, PurpleApiProxyService, PurpleTranslationPipe } from 'purple-lib';
import { AppTranslationService } from 'src/core-modules/localization/localization.service';
import { SaleEventDayItem, SaleEventCloneDateItem, BackOfficeService } from 'src/core-modules/sdk/api';
import { NzModalService, NzModalRef, NZ_MODAL_DATA } from 'ng-zorro-antd/modal';
import { NzMessageService } from 'ng-zorro-antd/message';
import { environment } from 'src/environments/default/environment';
import { DatePipe } from '@angular/common';
import { isAfter } from 'date-fns';
import { PrivateSaleService } from '../../../private-sale-service.service';

@Component({
  selector: 'app-clone-day-slots-modal',
  templateUrl: './clone-day-slots-modal.component.html',
  styleUrls: ['./clone-day-slots-modal.component.scss']
})
export class CloneDaySlotsModalComponent implements OnInit {

  constructor(@Inject(NZ_MODAL_DATA) public daySlots: SaleEventDayItem, private tsvc: AppTranslationService, private tranPipe: PurpleTranslationPipe, private seSvc: PrivateSaleService, private admSvc: BackOfficeService,
    public datePipe: DatePipe, private modal: NzModalService, private apiProxySvc: PurpleApiProxyService, private modalRef: NzModalRef, private mesSvc: NzMessageService) { }

  days: SaleEventCloneDateItem[] | undefined = undefined;
  isLoading: boolean = true;
  bookingHost: string = localStorage.getItem("BookingHost")!;

  async ngOnInit() {
    this.isLoading = true;
    
    await this.apiProxySvc.makeRequestErrorMessage<SaleEventCloneDateItem[]>(() => this.admSvc.getSaleEventCloneDateSlotDateRange(this.tsvc.currentLanguage.value, {
      saleEventId: this.seSvc.currentSaleEvent$.value!.saleEventId,
      selectedDate: this.daySlots.dateDay
    }), false, "internal-loader", 500, undefined, undefined, (res: PurpleApiMakeRequestResponse<SaleEventCloneDateItem[]>) => {
      this.days = (res.data??[]).filter(f=> isAfter(new Date(Date.parse(f.date)), Date.now()))
      this.isLoading = false;
    })

    if (this.days == undefined) {
      this.modalRef.close()
    }
  }

  getSlotCapacityLabel(ctn: number): string{
    if(ctn == -1){
      return this.tranPipe.transform('clone_day_slots_clone_day_illimited','Illimitati', [])
    }else{
      return ctn.toString();
    }
  }

  getSelectedDays(): SaleEventCloneDateItem[]{
    return this.days?.filter(f=> f.isSelected)??[]
  }

  closeModal(){
    this.modalRef.close();
  }


  someDaysSelected() {
    return (this.days ?? []).findIndex(f => f.isSelected) != -1;
  }

  cloneDaySlots() {
    this.isLoading = true;

    if (this.someDaysSelected()) {
      this.modal.create({
        nzTitle: this.tranPipe.transform('clone_daty_slots_modal_title', 'Attenzione! Procedere al salvataggio?', []),
        nzContent: this.tranPipe.transform('clone_daty_slots_modal_subtitle',
          'Le fascie orarie dei giorni selezionati verranno sovrascritte con quelle del <b>{0}</b>!'
          , [this.datePipe.transform(this.daySlots.dateDay, "dd MMMM yyyy")!]),
        nzWidth: '600px',
        nzOkText: this.tranPipe.transform('clone_daty_slots_modal_button_ok', 'Conferma', []),
        nzClassName: 'purple-simple-modal',
        nzCancelText: this.tranPipe.transform('clone_daty_slots_modal_button_no', 'Annulla', []),
        nzMaskClosable: false,
        nzOnOk: async () => {
          await this.apiProxySvc.makeRequestErrorMessage<boolean>(() => this.admSvc.cloneDateSlot(this.tsvc.currentLanguage.value, {
            saleEventId: this.seSvc.currentSaleEvent$.value!.saleEventId,
            saleEventCloneDates: this.days!.filter(f => f.isSelected),
            saleEventDayItem: this.daySlots,
            bookingClientHost: this.bookingHost
          }), false, "internal-loader", 500, undefined, undefined, (_: PurpleApiMakeRequestResponse<boolean>) => {
            this.isLoading = false;
            this.modalRef.close(true);
          })
        }
      })
    } else {
      this.mesSvc.warning(this.tranPipe.transform('clone_daty_slots_no_days_selected', 'Nessun giorno selezionato', []), {
        nzDuration: environment.MESSAGE_DURATION
      });
    }

    this.isLoading = false;
  }

}
