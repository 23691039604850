import { Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Helper, PurpleApiMakeRequestResponse, PurpleApiProxyService, PurpleTranslationPipe, purpleFormField, purpleFormFieldsGroup } from 'purple-lib';
import { AppTranslationService } from 'src/core-modules/localization/localization.service';
import { ApiTreeItem, BackOfficeService, DateSlotRole, SaleEventDaySlotItem } from 'src/core-modules/sdk/api';
import { NzModalService, NzModalRef, NZ_MODAL_DATA } from 'ng-zorro-antd/modal';
import { NzMessageService } from 'ng-zorro-antd/message';
import { environment } from 'src/environments/default/environment';
import { isSameHour, isSameMinute } from 'date-fns';
import { Subject, takeUntil } from 'rxjs';
import { PrivateSaleService } from '../../../private-sale-service.service';
import { SaleEventDatePipe } from '../../../sale-event-date.pipe.';

@Component({
  selector: 'app-edit-day-slot-modal',
  templateUrl: './edit-day-slot-modal.component.html',
  styleUrls: ['./edit-day-slot-modal.component.scss']
})
export class EditDaySlotModalComponent implements OnInit, OnDestroy {

  constructor(@Inject(NZ_MODAL_DATA) private data: { daySlots:SaleEventDaySlotItem[], clickedSlot: Date | undefined, daySlot: SaleEventDaySlotItem | undefined },
   private fb: FormBuilder, private apiProxySvc: PurpleApiProxyService, private admSvc: BackOfficeService, private tsvc: AppTranslationService, private tranPipe: PurpleTranslationPipe,
    private seSvc: PrivateSaleService, private modal: NzModalService, private saleEventDatePipe: SaleEventDatePipe, private mesSvc: NzMessageService, private modalRef: NzModalRef) { }

  /* @Input() daySlots: SaleEventDaySlotItem[] = [];
  @Input() daySlot: SaleEventDaySlotItem | undefined;
  @Input() clickedSlot: Date | undefined; */

  validateForm: FormGroup = this.fb.group({});
  purpleFormFieldGroups: editSaleFormSlot[] | undefined;
  disableForm: boolean = false;
  isLoading: boolean = false;
  editDaySlot!: EditDaySlotItem;
  originalEditDaySlot!: EditDaySlotItem;

  isNewSlot: boolean = false;
  sub: Subject<void> = new Subject();

  slotRoleEnablesFormField!: purpleFormField;
  bookingHost: string = localStorage.getItem("BookingHost")!;

  async ngOnInit() {
    this.isLoading = true;
    await this.apiProxySvc.makeRequestErrorMessage<DateSlotRole[]>(() => this.admSvc.getDateSlotAllRoles(this.tsvc.currentLanguage.value, {
      currentRoles: this.data.daySlot?.roles ?? []
    }), false, "internal-loader", 500, undefined, undefined, (res: PurpleApiMakeRequestResponse<DateSlotRole[]>) => {
      if (this.data.daySlot == undefined) {
        this.editDaySlot = {
          isMixedRoleEnabled: false,
          roles: [],
          mixedRoles: [
            {
              checked: true,
              isStaffRole: false,
              roleId: "all",
              roleName: this.tranPipe.transform('mixed_roles_all_role_table_role_name', 'Tutti i ruoli', []),
              slotAvailableCapacity: -1,
              slotTotalCapacity: -1,
              roleLevel: 100
            }
          ],
          slotEndDate: undefined,
          slotStartDate: this.data.clickedSlot,
          slotDescription: undefined,
          slotName: undefined
        }

        this.isNewSlot = true;
      }
      else {
        this.editDaySlot = {
          isMixedRoleEnabled: this.data.daySlot.isMixedRoleEnabled,
          mixedRoles: [
            {
              checked: true,
              isStaffRole: false,
              roleId: "all",
              roleName: this.tranPipe.transform('mixed_roles_all_role_table_role_name', 'Tutti i ruoli', []),
              slotAvailableCapacity: this.data.daySlot.maxMixedRoleAttendees == -1 ? -1 : this.data.daySlot.maxMixedRoleAttendees - this.data.daySlot.currentSlotRoleAttendees,
              slotTotalCapacity: this.data.daySlot.maxMixedRoleAttendees,
              roleLevel: 100
            }
          ],
          roles: res.data?.filter(f => f.checked) ?? [],
          slotEndDate: new Date(Date.parse(this.data.daySlot.slotEndDate.changeDateTimezone())),
          slotStartDate: new Date(Date.parse(this.data.daySlot.slotStartDate.changeDateTimezone())),
          slotDescription: this.data.daySlot.slotDescription ?? undefined,
          slotName: this.data.daySlot.slotName ?? undefined
        }

        this.isNewSlot = false;
      }

      this.originalEditDaySlot = Helper.storeNewObj(this.editDaySlot)

      console.log("Edit slot: ", this.editDaySlot);
      console.log("Oroginal Edit slot: ", this.originalEditDaySlot);

      this.slotRoleEnablesFormField = {
        fieldType: 'select-local',
        fieldControlName: 'slotEnabledRoles',
        fieldBootstrapColumn: 12,
        fieldName: this.tranPipe.transform('edit_day_slots_modal_slot_enabled_roles', 'Ruoli abilitati', []),
        fieldPlaceholder: this.tranPipe.transform('edit_day_slots_modal_slot_enabled_roles_placeholder', 'Ruoli abilitati alla prenotazione', []),
        fieldValue: this.editDaySlot.roles,
        fieldIsRequired: true,
        fieldIsDisabled: false,
        fieldPaddingLeft: false,
        fieldPaddingRight: false,
        fieldOptions: [
          { id: 'type', value: 'multiple' },
          { id: 'showArrow', value: true },
          { id: 'enableSearch', value: true },
          {
            id: 'options', value: res.data!.map(m => {
              return {
                value: m,
                label: m.roleName
              }
            })
          }
        ]
      }

      this.purpleFormFieldGroups = [
        {
          formSectionId: "general_info",
          formSectionName: this.tranPipe.transform('edit_day_slot_basic_info', 'Informazioni Generali', []),
          purpleFormGroups: [
            {
              fieldGroupNumber: 1,
              fielGroupBootstrapColumn: 12,
              fieldGroupPaddingLeft: false,
              fieldGroupPaddingRight: false,
              formFieldFormGroup: this.validateForm,
              formFields: [
                {
                  fieldType: 'time-picker',
                  fieldControlName: 'startTime',
                  fieldBootstrapColumn: 6,
                  fieldName: this.tranPipe.transform('edit_day_slot_start_time', 'Inizio', []),
                  fieldPlaceholder: this.tranPipe.transform('edit_day_slot_start_time_placeholder', 'Ora inizio slot', []),
                  fieldValue: this.editDaySlot.slotStartDate,
                  fieldIsRequired: true,
                  fieldIsDisabled: !this.isNewSlot,
                  fieldOptions: [
                    { id: 'timeFormat', value: 'HH:mm' },
                    { id: 'minuteStep', value: 15 },
                    { id: 'disabledHours', value: this.disabledStartHours },
                    { id: 'disabledMinutes', value: this.disabledStartMinutes }
                  ],
                  fieldPaddingLeft: false,
                  fieldPaddingRight: true,
                },
                {
                  fieldType: 'time-picker',
                  fieldControlName: 'endTime',
                  fieldBootstrapColumn: 6,
                  fieldName: this.tranPipe.transform('edit_day_slot_end_time', 'Fine', []),
                  fieldPlaceholder: this.tranPipe.transform('edit_day_slot_end_time_placeholder', 'Ora fine slot', []),
                  fieldValue: this.editDaySlot.slotEndDate,
                  fieldIsRequired: true,
                  fieldIsDisabled: !this.isNewSlot,
                  fieldOptions: [
                    { id: 'timeFormat', value: 'HH:mm' },
                    { id: 'minuteStep', value: 15 },
                    { id: 'disabledHours', value: this.disabledEndHours },
                    { id: 'disabledMinutes', value: this.disabledEndMinutes }
                  ],
                  fieldPaddingLeft: true,
                  fieldPaddingRight: false,
                }
              ]
            }
          ],
          purpleFormCollapsedGroups: [
            {
              fieldGroupNumber: 2,
              fielGroupBootstrapColumn: 12,
              fieldGroupPaddingLeft: false,
              fieldGroupPaddingRight: false,
              formFieldFormGroup: this.validateForm,
              formFields: [
                {
                  fieldType: 'input-text',
                  fieldControlName: 'name',
                  fieldBootstrapColumn: 12,
                  fieldName: this.tranPipe.transform('edit_day_slot_name', 'Nome', []),
                  fieldPlaceholder: this.tranPipe.transform('edit_day_slot_name_placeholder', 'Nome slot', []),
                  fieldValue: this.editDaySlot.slotName,
                  fieldIsRequired: false,
                  fieldIsDisabled: false,
                  fieldPaddingLeft: false,
                  fieldPaddingRight: false
                },
                {
                  fieldType: 'textarea',
                  fieldControlName: 'description',
                  fieldBootstrapColumn: 12,
                  fieldName: this.tranPipe.transform('edit_day_slot_description', 'Descrizione', []),
                  fieldPlaceholder: this.tranPipe.transform('edit_day_slot_description_placeholder', 'Descrizione slot', []),
                  fieldValue: this.editDaySlot.slotDescription,
                  fieldIsRequired: false,
                  fieldIsDisabled: false,
                  fieldPaddingLeft: false,
                  fieldPaddingRight: false,
                  fieldOptions: [{ id: 'rowNumber', value: 4 }]
                }
              ]
            }
          ],
          showLine: true
        },
        {
          formSectionId: "role_and_limit",
          formSectionName: this.tranPipe.transform('edit_day_slot_role_limit', 'Ruoli e Limitazioni', []),
          purpleFormCollapsedGroups: [],
          showLine: false,
          purpleFormGroups: [
            {
              fieldGroupNumber: 1,
              fielGroupBootstrapColumn: 12,
              fieldGroupPaddingLeft: false,
              fieldGroupPaddingRight: false,
              formFieldFormGroup: this.validateForm,
              formFields: [
                {
                  fieldType: 'select-local',
                  fieldControlName: 'mixedRoleEnabled',
                  fieldBootstrapColumn: 12,
                  fieldName: this.tranPipe.transform('edit_day_slot_is_mixed_role_enabled', 'Tipologia limitazione', []),
                  fieldPlaceholder: this.tranPipe.transform('edit_day_slot_is_mixed_role_enabled_placeholder', 'Tipologia limitazione slot', []),
                  fieldValue: this.editDaySlot.isMixedRoleEnabled,
                  fieldIsRequired: true,
                  fieldIsDisabled: false,
                  fieldPaddingLeft: false,
                  fieldPaddingRight: false,
                  fieldOptions: [
                    { id: 'type', value: 'default' },
                    { id: 'showArrow', value: true },
                    { id: 'enableSearch', value: false },
                    {
                      id: 'options', value: [
                        {
                          label: this.tranPipe.transform('edit_day_slots_modal_si_role_select', 'Limita Globalmente', []),
                          value: true
                        },
                        {
                          label: this.tranPipe.transform('edit_day_slots_modal_no_role_select', 'Limita per Ruolo', []),
                          value: false
                        }
                      ]
                    }
                  ]
                },
                this.slotRoleEnablesFormField
              ]
            }
          ]
        }
      ]

      setTimeout(() => {
        if (!this.isNewSlot) {
          this.validateForm.controls["startTime"].disable();
          this.validateForm.controls["endTime"].disable();
        }

        this.validateForm.controls["endTime"].valueChanges.pipe(takeUntil(this.sub)).subscribe((s: Date | undefined) => {
          this.editDaySlot.slotEndDate = s;

          console.log("Cambio tempo fine: ", this.editDaySlot)
        });

        this.validateForm.controls["startTime"].valueChanges.pipe(takeUntil(this.sub)).subscribe((s: Date | undefined) => {
          this.editDaySlot.slotStartDate = s;
        });

        this.validateForm.controls["mixedRoleEnabled"].valueChanges.pipe(takeUntil(this.sub)).subscribe((s: boolean) => {
          this.editDaySlot.isMixedRoleEnabled = s;

          if (s == true) {
            this.validateForm.get("slotEnabledRoles")!.removeValidators(Validators.required);

            this.purpleFormFieldGroups!.find(f => f.formSectionId.strEq("role_and_limit"))!.purpleFormGroups[0].formFields =
              [(this.purpleFormFieldGroups ?? []).find(f => f.formSectionId.strEq("role_and_limit"))!.purpleFormGroups[0].formFields[0]];

          } else {
            if (this.validateForm.contains("slotEnabledRoles")) {
              this.validateForm.get("slotEnabledRoles")!.addValidators(Validators.required);
              this.purpleFormFieldGroups!.find(f => f.formSectionId.strEq("role_and_limit"))!.purpleFormGroups[0].formFields =
                [(this.purpleFormFieldGroups ?? []).find(f => f.formSectionId.strEq("role_and_limit"))!.purpleFormGroups[0].formFields[0],
                this.slotRoleEnablesFormField];
            }
          }
        })


        this.validateForm.controls["slotEnabledRoles"].valueChanges.pipe(takeUntil(this.sub)).subscribe((s: DateSlotRole[]) => {

          const originalEnabledRoles = this.editDaySlot.roles.filter(f => f.checked);
          const newRole = s.map(m => m.roleId);
          const missing = originalEnabledRoles.filter(item => newRole.indexOf(item.roleId) < 0);

          if (!this.isNewSlot && missing.length > 0 && ((missing[0].slotAvailableCapacity ?? -1) == -1 || (missing[0].slotAvailableCapacity ?? -1) > 0)) {
            this.modal.create({
              nzTitle: this.tranPipe.transform('check_day_slot_enabled_role_modal_title', 'Attenzione rimuovere il ruolo <b>{0}</b>?', [missing[0].roleName]),
              nzContent: this.tranPipe.transform('check_day_slot_enabled_role_modal_content',
                '<b>{0}</b> utenti hanno prenotato questo slot con il ruolo <b>{1}</b> che si vuole rimuovere! A quest\'ultimi verrà notificata la cancellazione delle loro prenotazini',
                [(missing[0].slotTotalCapacity == -1 ? 'Diversi' : ((missing[0].slotTotalCapacity ?? 0) - (missing[0].slotAvailableCapacity ?? 0)).toString()), missing[0].roleName]),
              nzWidth: '800px',
              nzOkText: this.tranPipe.transform("check_day_slot_enabled_role_modal_ok_button", "Rimuovi"),
              nzClassName: 'purple-simple-modal',
              nzMaskClosable: false,
              nzOnOk: () => {
                this.editDaySlot.roles = s;
              },
              nzOnCancel: () => {
                this.validateForm.controls["slotEnabledRoles"].setValue([...s, missing[0]]);
              }
            });
          } else {
            this.editDaySlot.roles = s;
          }

          console.log("DateSlots: ", s)
        });


        this.validateForm.controls["mixedRoleEnabled"].setValue(this.editDaySlot.isMixedRoleEnabled);
        this.isLoading = false;
      }, 100);
    })
  }

  ngOnDestroy(): void {
    this.sub.next();
    this.sub.complete();
  }

  async deleteDaySlot() {
    if (!this.isNewSlot && this.data.daySlot != undefined) {
      await this.apiProxySvc.makeRequestErrorMessage<ApiTreeItem[]>(() => this.admSvc.getSaleEventSlotBookings(this.tsvc.currentLanguage.value, {
        saleEventId: this.seSvc.currentSaleEvent$.value!.saleEventId,
        slotStartDate: this.data.daySlot!.slotStartDate
      }), false, "internal-loader", 500, undefined, undefined, (res: PurpleApiMakeRequestResponse<ApiTreeItem[]>) => {
        this.modal.create({
          nzTitle: this.tranPipe.transform('delete_day_slot_modal_title', 'Eliminazione fascia oraria: {0} - {1}',
            [this.saleEventDatePipe.transform(this.data.daySlot!.slotStartDate, "HH:mm")!, this.saleEventDatePipe.transform(this.data.daySlot!.slotEndDate, "HH:mm")!]),
          nzContent: res.data?.length ?? 0 > 0 ?
            this.tranPipe.transform('delete_day_slot_modal_info', 'Attenzione lo slot <b>{0} - {1}</b> è già stato prenotato da <b>{2}</b> utenti! ai quali verrà <b>notificata tramite mail</b> la cancellazione della fascia oraria.',
              [this.saleEventDatePipe.transform(this.data.daySlot!.slotStartDate, "HH:mm")!, this.saleEventDatePipe.transform(this.data.daySlot!.slotEndDate, "HH:mm")!,
              res.data?.reduce((sum, current) => sum + current.children.length, 0).toString() ?? ""])
            : this.tranPipe.transform('delete_day_slot_modal_info_empty', 'Vuoi davvero eliminare lo slot <b>{0} - {1}</b>',
              [this.saleEventDatePipe.transform(this.data.daySlot!.slotStartDate, "HH:mm")!, this.saleEventDatePipe.transform(this.data.daySlot!.slotEndDate, "HH:mm")!]),
          nzWidth: '600px',
          nzOkText: res.data?.length ?? 0 > 0 ? this.tranPipe.transform("delete_day_slot_modal_delete_of_confirm", "Elimina Comunque") :
            this.tranPipe.transform("delete_day_slot_modal_delete_confirm", "Elimina"),
          nzClassName: 'purple-simple-modal',
          nzMaskClosable: false,
          nzOnOk: async () => {

            await this.apiProxySvc.makeRequestErrorMessage<boolean>(() => this.admSvc.deleteSaleEventSlot(this.tsvc.currentLanguage.value, {
              saleEventId: this.seSvc.currentSaleEvent$.value!.saleEventId,
              slotStartDate: this.data.daySlot!.slotStartDate,
              bookingClientHost: this.bookingHost
            }), false, "internal-loader", 500, undefined, undefined, () => {
              this.mesSvc.success(this.tranPipe.transform("delete_day_slot_modal_success", "Fascia Oraria eliminata correttamente"), { nzDuration: environment.MESSAGE_DURATION });
              this.modalRef.close({ slot: this.data.daySlot!, type: "delete" });
              this.modal.closeAll();
            })
          }
        });
      })
    }
  }

  changeRoleSlotCapacity(capacity: number, slot: DateSlotRole) {
    const stc = (slot.slotTotalCapacity ?? -1);
    const sac = (slot.slotAvailableCapacity ?? -1);
    const soc = stc == -1 ? -1 : stc - sac;

    if (capacity == 0) {
      if (stc > 0) {
        slot.slotTotalCapacity = -1;
        slot.slotAvailableCapacity = -1;
        return;
      }
      else {
        const originalSlot = this.originalEditDaySlot.roles.find(f => f.roleId.strEq(slot.roleId));
        var osoc = -1;

        if (originalSlot != undefined) {
          const ostc = (originalSlot.slotTotalCapacity ?? -1);
          const osac = (originalSlot.slotAvailableCapacity ?? -1);
          osoc = osac == -1 ? -1 : ostc - osac;
        }


        if (osoc > 1) {
          slot.slotTotalCapacity = osoc;
          slot.slotAvailableCapacity = 0;

        } else {
          slot.slotTotalCapacity = 1;
          slot.slotAvailableCapacity = 1;
        }

        return;
      }
    }

    if (capacity < soc && stc == soc) {
      slot.slotTotalCapacity = -1;
      slot.slotAvailableCapacity = -1;
      return;

    }

    slot.slotTotalCapacity = capacity;
    slot.slotAvailableCapacity = capacity - soc;
  }

  closeModal() {
    if (this.checkIfDoChanges()) {
      this.modal.create({
        nzTitle: this.tranPipe.transform('check_day_slot_prevent_close_modal_title', 'Attenzione modifiche non salvate', []),
        nzContent: this.tranPipe.transform('check_day_slot_prevent_close_modal_content',
          'Tutte le modifiche non salvate verranno perse! chiudere comunque il modale?', []),
        nzWidth: '600px',
        nzOkText: this.tranPipe.transform("check_day_slot_prevent_close_modal_ok_button", "Chiudi"),
        nzClassName: 'purple-simple-modal',
        nzMaskClosable: false,
        nzOnOk: () => {
          this.modal.closeAll();
        }
      });
    } else {
      this.modal.closeAll();
    }
  }

  async saveChanges() {
    console.log("salvo modifiche")
    this.isLoading = true;

    for (const i in this.validateForm.controls) {
      this.validateForm.controls[i].markAsDirty();
      this.validateForm.controls[i].updateValueAndValidity();
    }

    console.log("Il form: ", this.validateForm.controls)
    if (this.checkIfDoChanges()) {
      if (this.validateForm.valid) {

        await this.apiProxySvc.makeRequestErrorMessage<SaleEventDaySlotItem>(() => this.admSvc.createOrUpdateSaleEventDateDaySlot(this.tsvc.currentLanguage.value, {
          dateDay: this.validateForm.controls["startTime"].value.toIsoStringPurple(),
          isNewSlot: this.isNewSlot,
          saleEventId: this.seSvc.currentSaleEvent$.value!.saleEventId,
          slotEndDate: this.validateForm.controls["endTime"].value.toIsoStringPurple(),
          slotStartDate: this.validateForm.controls["startTime"].value.toIsoStringPurple(),
          slotDescription: this.validateForm.controls["description"].value,
          slotName: this.validateForm.controls["name"].value,
          roles: this.editDaySlot.roles.map(m => { m.checked = true; return m }),
          isMixedRoleEnabled: this.validateForm.controls["mixedRoleEnabled"].value ?? false,
          maxMixedRoleAttendees: this.editDaySlot.mixedRoles[0].slotTotalCapacity,
          bookingClientHost: this.bookingHost
        }), false, "internal-loader", 500, undefined, undefined, (res: PurpleApiMakeRequestResponse<SaleEventDaySlotItem>) => {
          this.mesSvc.success(this.tranPipe.transform('edit_day_slot_save_success', 'Modifiche salvate con successo', []), {
            nzDuration: environment.MESSAGE_DURATION
          });
          this.modalRef.close({ slot: res.data, type: "add" });
        })
      }
    } else {
      this.mesSvc.warning(this.tranPipe.transform('edit_no_changes', 'Nessuna modifica effettuata', []), {
        nzDuration: environment.MESSAGE_DURATION
      });
    }


    this.isLoading = false;
  }

  private checkIfDoChanges() {
    if (!this.isSameSlotTime(
      { st: this.originalEditDaySlot.slotStartDate, et: this.originalEditDaySlot.slotEndDate },
      { st: this.validateForm.controls["startTime"].value, et: this.validateForm.controls["endTime"].value })) {
      return true
    }

    if (this.originalEditDaySlot.slotName != this.validateForm.controls["name"].value) {
      return true;
    }

    if (this.originalEditDaySlot.slotDescription != this.validateForm.controls["description"].value) {
      return true;
    }

    if (this.originalEditDaySlot.isMixedRoleEnabled != this.validateForm.controls["mixedRoleEnabled"].value) {
      return true;
    }

    if (this.validateForm.controls["mixedRoleEnabled"].value) {
      const checkMixedRole = this.editDaySlot.mixedRoles.filter(item => this.originalEditDaySlot.mixedRoles.findIndex((f: DateSlotRole) =>
        f.roleId == item.roleId && f.checked == item.checked && f.slotTotalCapacity == item.slotTotalCapacity) < 0)
      if (checkMixedRole.length > 0) {
        return true
      }
    } else {
      const checkRole = this.editDaySlot.roles.filter(item => this.originalEditDaySlot.roles.findIndex((f: DateSlotRole) =>
        f.roleId == item.roleId && f.checked == item.checked && f.slotTotalCapacity == item.slotTotalCapacity) < 0)
      if (checkRole.length > 0) {
        return true
      }
    }

    return false;

  }


  private isSameSlotTime(slot1: { st: Date | undefined, et: Date | undefined }, slot2: { st: Date | undefined, et: Date | undefined }) {
    var check = true;

    if (slot1.st != undefined && slot2.st != undefined) {

      check = isSameHour(slot1.st, slot2.st) && isSameMinute(slot1.st, slot2.st)
    } else {
      if (slot1.st == undefined && slot2.st == undefined) {
        check = true;
      } else {
        return false;
      }
    }

    if (check == false) {
      return false
    }

    if (slot1.et != undefined && slot2.et != undefined) {
      return isSameHour(slot1.et, slot2.et) && isSameMinute(slot1.et, slot2.et)
    } else {
      if (slot1.et == undefined && slot2.et == undefined) {
        return true;
      } else {
        return false;
      }
    }
  }

  private range(start: number, end: number): number[] {
    const result: number[] = [];
    for (let i = start; i < end; i++) {
      result.push(i);
    }
    return result;
  }

  disabledEndHours = (): number[] => {
    const ctrl: AbstractControl = this.validateForm.controls["startTime"];

    if (ctrl != undefined && ctrl.value != undefined) {
      return this.range(0, ctrl.value.getHours())
    }

    return [];
  }

  disabledEndMinutes = (hour: number): number[] => {
    const ctrl: AbstractControl = this.validateForm.controls["startTime"];

    if (ctrl != undefined && ctrl.value != undefined && hour == ctrl.value.getHours()) {
      return this.range(0, ctrl.value.getMinutes())
    }

    return [];
  }

  disabledStartHours = (): number[] => {
    const ctrl: AbstractControl = this.validateForm.controls["endTime"];

    if (ctrl != undefined && ctrl.value != undefined) {
      return this.range(ctrl.value.getHours() + 1, 24)
    }

    return [];
  }

  disabledStartMinutes = (hour: number): number[] => {
    const ctrl: AbstractControl = this.validateForm.controls["endTime"];

    if (ctrl != undefined && ctrl.value != undefined && hour == ctrl.value.getHours()) {
      return this.range(ctrl.value.getMinutes(), 60)
    }

    return [];
  }

  formatterUser = (value: number): string => {
    if (value === -1) {
      return this.tranPipe.transform('edit_day_slots_modal_table_illimited', 'Illimitato', [])
    }
    if (value === undefined) {
      return this.tranPipe.transform('edit_day_slots_modal_table_illimited', 'Illimitato', [])
    }

    return `${value}`
  };

}

export interface EditDaySlotItem {
  slotName?: string | undefined;
  slotDescription?: string | undefined;
  slotStartDate: Date | undefined;
  slotEndDate: Date | undefined;
  isMixedRoleEnabled: boolean;
  roles: Array<DateSlotRole>;
  mixedRoles: Array<DateSlotRole>;
}

interface editSaleFormSlot {
  formSectionId: string;
  formSectionName: string;
  purpleFormGroups: purpleFormFieldsGroup[]
  purpleFormCollapsedGroups: purpleFormFieldsGroup[];
  showLine?: boolean | undefined;
}