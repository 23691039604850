import { Component } from '@angular/core';
import { Router, RouterEvent, NavigationStart, NavigationEnd, NavigationCancel, NavigationError } from '@angular/router';
import { PurpleApiError, PurpleApiErrorPage, PurpleApiErrorService, PurpleErrorService, PurpleLoaderService } from 'purple-lib';
import { AuthenticationService } from 'src/core-modules/authentication/authentication.service';
import { AppTranslationService } from 'src/core-modules/localization/localization.service';
import { environment } from 'src/environments/default/environment';
import { DashboardLayout2Service } from './dashboard/dashboard-layout-2/dashboard-layout-2.service';
import { Subject, takeUntil, throttleTime } from 'rxjs';
import { NzModalService } from 'ng-zorro-antd/modal';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  loaderId: string = "global";

  constructor(public loaderSvc: PurpleLoaderService, public tsvc: AppTranslationService, public authService: AuthenticationService, private modalSvc: NzModalService,
    private router: Router, private dashSvc: DashboardLayout2Service, private errorSvc: PurpleErrorService, private errorInterceptorSvc: PurpleApiErrorService) {
      
      this.router.events.subscribe((e: any) => {
        this.navigationInterceptor(e);
      });
  }
  private throttleLogout = new Subject<any>();
  errorInfo: PurpleApiError | undefined = undefined;
  sub: Subject<void> = new Subject();

  enableErrorPages: PurpleApiErrorPage[] = [
    {
      errorStatus: 0,
      errorTitle: "OPS SOMETHING WENT WRONG...",
      errorDescription: "It appears there was an error connecting to the server. Try reloading the page again.",
      errorButton: {
        text: "Change Country",
        fn: () => {
          localStorage.clear();
          window.location.reload();
        }
      }
    }
  ]
  
  
  // Shows and hides the loading spinner during RouterEvent changes
  navigationInterceptor(event: RouterEvent): void {

    this.dashSvc.isUserMenuOpen = false;
    
    if (event instanceof NavigationStart) {
      this.errorSvc.removeAllRequest("internal-error");
      if (!event.url.includes('/' + this.tsvc.currentLanguage.value + '/dashboard/')) {
        this.loaderSvc.addRequest(this.loaderId);
      }
    }
    if (event instanceof NavigationEnd) {
      this.loaderSvc.removeRequest(this.loaderId, environment.LOADER_DELAY);
    }

    if (event instanceof NavigationCancel) {
      this.loaderSvc.removeRequest(this.loaderId, environment.LOADER_DELAY);
    }
    if (event instanceof NavigationError) {
      this.loaderSvc.removeRequest(this.loaderId, environment.LOADER_DELAY);
    }
  }

  async ngOnInit(): Promise<void> {
    this.throttleLogout.pipe(takeUntil(this.sub), throttleTime(5000)).subscribe(async url => {
      this.modalSvc.closeAll();
      this.authService.logout();
      await this.router.navigate([this.tsvc.currentLanguage.value, 'login']);
    });


    this.errorInterceptorSvc.errorState.pipe(takeUntil(this.sub))
    .subscribe((error: PurpleApiError | undefined) => {
      console.log("Errore: ", error)
      this.errorInfo = error;

      if (error != undefined) {
        switch (error.errorStatus) {
          case 0:
            this.modalSvc.closeAll();
            this.loaderSvc.removeAllRequest(this.loaderId);
            break;

          case 401:
            this.throttleLogout.next(1);
            break;

          default:
            break;
        }
      }else{
        this.errorInfo = undefined;
      }
    });
    
  }

  ngOnDestroy() {
    this.sub.next();
    this.sub.complete();
  }
}

