import { NgModule } from '@angular/core';
import { CommonModule, CurrencyPipe, DatePipe } from '@angular/common';
import { PrivateSaleDetailRoutingModule } from './private-sale-detail-routing.module';
import { PurpleEditImageModule, PurpleFormModule, PurpleGeneralDirectiveModule, PurplePipeModule, PurpleSelectModule, PurpleStringColorDirective, PurpleTableModule } from 'purple-lib';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzTagModule } from 'ng-zorro-antd/tag';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzNotificationModule } from 'ng-zorro-antd/notification';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { EditOnlineStatusModalComponent } from './modals/edit-online-status-modal/edit-online-status-modal.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CalendarModule, DateAdapter, CalendarDateFormatter, CalendarNativeDateFormatter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { EditDaySlotModalComponent } from './modals/edit-day-slot-modal/edit-day-slot-modal.component';
import { NzCollapseModule } from 'ng-zorro-antd/collapse';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzPopoverModule } from 'ng-zorro-antd/popover';
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { NzDescriptionsModule } from 'ng-zorro-antd/descriptions';
import { NzTreeViewModule } from 'ng-zorro-antd/tree-view';
import { NzInputNumberModule } from 'ng-zorro-antd/input-number';
import { NzUploadModule } from 'ng-zorro-antd/upload';
import { NzMessageModule } from 'ng-zorro-antd/message';
import { CloneDaySlotsModalComponent } from './modals/clone-day-slots-modal/clone-day-slots-modal.component';
import { PrivateSaleDaysSlotsComponent } from './private-sale-days-slots/private-sale-days-slots.component';
import { PrivateSaleGeneralInfoComponent } from './private-sale-general-info/private-sale-general-info.component';
import { SummaryStatsItemComponent } from '../common/summary-stats-item/summary-stats-item.component';
import { PrivateSaleRolesComponent } from './private-sale-roles/private-sale-roles.component';
import { AddTabRoleModalComponent } from './modals/add-tab-role-modal/add-tab-role-modal.component';
import { PrivateSaleUsersPrivateComponent } from './users/private-sale-users/private-sale-users-private/private-sale-users-private.component';
import { PrivateSaleImportedUsersComponent } from './users/private-sale-imported-users/private-sale-imported-users.component';
import { SendImportedUsersEmailModalComponent } from './modals/send-imported-users-email-modal/send-imported-users-email-modal.component';
import { ImportPrivateSaleUsersModalComponent } from './modals/import-private-sale-users-modal/import-private-sale-users-modal.component';
import { PrivateSalePoliciesComponent } from './private-sale-policies/private-sale-policies.component';
import { ReorderPoliciesModalComponent } from './modals/reorder-policies-modal/reorder-policies-modal.component';
import { EditPolicyModalComponent } from './modals/edit-policy-modal/edit-policy-modal.component';
import { EditBasePolicyModalComponent } from './modals/edit-base-policy-modal/edit-base-policy-modal.component';
import { PrivateSaleCashMachineSettingsComponent } from './private-sale-cash-machine-settings/private-sale-cash-machine-settings.component';
import { EditBrandLimitModalComponent } from './modals/edit-brand-limit-modal/edit-brand-limit-modal.component';
import { EditCategoryLimitModalComponent } from './modals/edit-category-limit-modal/edit-category-limit-modal.component';
import { EditCategoryLimitExceptionModalComponent } from './modals/edit-category-limit-exception-modal/edit-category-limit-exception-modal.component';
import { PrivateSaleBookingsReportComponent } from './private-sale-bookings-report/private-sale-bookings-report.component';
import { ShowBookedUsersModalComponent } from './modals/show-booked-users-modal/show-booked-users-modal.component';
import { AddUserRoleModalComponent } from './modals/add-user-role-modal/add-user-role-modal.component';
import { PrivateSaleImagesComponent } from './private-sale-images/private-sale-images.component';
import { PrivateSaleCouponsComponent } from './private-sale-coupons/private-sale-coupons.component';
import { EditCouponModalComponent } from './modals/edit-coupon-modal/edit-coupon-modal.component';
import { PrivateSaleUserReferralsComponent } from './users/private-sale-user-referrals/private-sale-user-referrals.component';
import { EditUserReferralModalComponent } from './modals/edit-user-referral-modal/edit-user-referral-modal.component';
import { PrivateSaleAttributesComponent } from './private-sale-attributes/private-sale-attributes.component';
import { EditSaleEventAttributesModalComponent } from './modals/edit-sale-event-attributes-modal/edit-sale-event-attributes-modal.component';
import { PrivateSaleUsersPublicComponent } from './users/private-sale-users/private-sale-users-public/private-sale-users-public.component';
import { PrivateSaleUsersComponent } from './users/private-sale-users/private-sale-users.component';
import { BookSlotUserComponent } from './modals/book-slot-user/book-slot-user.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    PrivateSaleDetailRoutingModule,
    PurpleTableModule,
    NzIconModule,
    PurplePipeModule,
    NzTagModule,
    NzToolTipModule,
    NzSpinModule,
    PurpleFormModule,
    NzButtonModule,
    NzNotificationModule,
    NzDatePickerModule,
    NzSelectModule,
    PurpleGeneralDirectiveModule,
    NzDropDownModule,
    NzCollapseModule,
    NzTableModule,
    NzInputNumberModule,
    NzTreeViewModule,
    NzPopoverModule,
    NzTabsModule,
    NzDescriptionsModule,
    PurpleSelectModule,
    NzUploadModule,
    NzMessageModule,
    PurpleEditImageModule,

    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    })
  ],
  declarations: [PrivateSaleGeneralInfoComponent, SummaryStatsItemComponent,
    EditOnlineStatusModalComponent, PrivateSaleDaysSlotsComponent, EditDaySlotModalComponent,
    CloneDaySlotsModalComponent, PrivateSaleRolesComponent, AddTabRoleModalComponent, PrivateSaleUsersPrivateComponent,
    PrivateSaleImportedUsersComponent, SendImportedUsersEmailModalComponent, ImportPrivateSaleUsersModalComponent,
    PrivateSalePoliciesComponent, ReorderPoliciesModalComponent, EditPolicyModalComponent, EditBasePolicyModalComponent, 
    PrivateSaleCashMachineSettingsComponent, EditBrandLimitModalComponent, EditCategoryLimitModalComponent, BookSlotUserComponent,
    EditCategoryLimitExceptionModalComponent, PrivateSaleBookingsReportComponent, ShowBookedUsersModalComponent, PrivateSaleUsersComponent,
    AddUserRoleModalComponent, PrivateSaleImagesComponent, PrivateSaleCouponsComponent, EditCouponModalComponent, PrivateSaleUsersPublicComponent,
    PrivateSaleUserReferralsComponent, EditUserReferralModalComponent, PrivateSaleAttributesComponent, EditSaleEventAttributesModalComponent],
  providers: [
    PurpleStringColorDirective,
    DatePipe,
    CurrencyPipe,
    { provide: CalendarDateFormatter, useClass: CalendarNativeDateFormatter }
  ]
})
export class PrivateSaleDetailModule { }
