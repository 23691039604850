import { Component, OnDestroy, OnInit } from '@angular/core';
import { AppTranslationService } from 'src/core-modules/localization/localization.service';
import { PurpleStorageService, showMenu } from 'purple-lib';
import { AuthenticationService } from 'src/core-modules/authentication/authentication.service';
import { DashboardLayout2Service } from './dashboard-layout-2.service';
import { Subject, takeUntil } from 'rxjs';
import { environment } from 'src/environments/default/environment';
import { ActivatedRoute } from '@angular/router';
import { PrivateSaleService } from '../private-sales/private-sale-service.service';
import { PRPVSaleEventFullExtended } from 'src/core-modules/sdk/api';

@Component({
  selector: 'app-dashboard-layout-2',
  templateUrl: './dashboard-layout-2.component.html',
  styleUrls: ['./dashboard-layout-2.component.scss']
})

export class DashboardLayout2Component implements OnInit, OnDestroy {

  constructor(public strSvc: PurpleStorageService, public dashSvc: DashboardLayout2Service, private route: ActivatedRoute,
    public tsvc: AppTranslationService, private seSvc: PrivateSaleService, public authSvc: AuthenticationService) { }

  isOpen: boolean = true;

  sub: Subject<void> = new Subject();
  pgTitle: string = "";
  pgSubTitle: string = "";
  activeMenus: showMenu[] = environment.ACTIVE_MENU;
  countryFlag: string = "";
  basePath: string = localStorage.getItem("BasePath")!;
  defLan: string = localStorage.getItem("DefaultLanguage")!;

  ngOnDestroy() {
    this.sub.next();
    this.sub.complete();
  }

  async ngOnInit(): Promise<void> {
    this.countryFlag = environment.COUNTRY_SELECT.find(f=> f.COUNTRY_DEFAULT_LANGUAGE.strEq(this.defLan) &&  f.COUNTRY_BASE_PATH.strEq(this.basePath))?.COUNTRY_FLAG??"it"
    this.seSvc.currentSaleEvent$.pipe(takeUntil(this.sub)).subscribe((s: PRPVSaleEventFullExtended | undefined)=>{
      var cm = this.dashSvc.currentMenuSection.find(f=>f.id.strEq("private-sale-detail"));
      if(cm !=null){        
        cm?.subMenus.forEach(f=>{
          f.queryParams = { saleEvent: s?.saleEventId }
        })
      }
    })

    this.route.queryParams.subscribe(async params => {
      console.log("Questa è la tran language: ", params["translationLanguage"])
      this.tsvc.translationLanguage.next(params['translationLanguage']??this.tsvc.currentLanguage.value);
    });

    this.dashSvc.pageTitle.pipe(takeUntil(this.sub)).subscribe(message => this.pgTitle = message ?? "")
    this.dashSvc.pageSubTitle.pipe(takeUntil(this.sub)).subscribe(message => this.pgSubTitle = message ?? "")
  }

  ngAfterViewInit(): void {
    setTimeout(() => {      
      const wh = window.innerHeight;
      const natH = wh - 330;
      this.dashSvc.dashboardHeight = natH + 'px';
      this.dashSvc.dashboardHeightNumber.next(natH);
    }, 100);
    
  }

  onResize() {
    const wh = window.innerHeight;
    const natH = wh - 330;
    this.dashSvc.dashboardHeight = natH + 'px';
    this.dashSvc.dashboardHeightNumber.next(natH);
  }

  contentClick() {
    this.dashSvc.isUserMenuOpen = false;
  }
}
