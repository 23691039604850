import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, UrlTree } from '@angular/router';
import { AppTranslationService } from 'src/core-modules/localization/localization.service';

@Injectable({
  providedIn: 'root'
})
export class PrivateSaleGuard {
  constructor(private router: Router, private tsvc: AppTranslationService) { }

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    const check = (route.queryParams['saleEvent'] ?? undefined) != undefined;

    return check ? true : this.router.navigate([this.tsvc.currentLanguage.value, 'dashboard', 'private-sales', 'private-sale-list']);
  }
}
