import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { PurpleTranslationPipe, PurpleApiProxyService, dashboardButton, PurpleApiMakeRequestResponse, purpleFormFieldsGroup, dashboardTag, PurpleTableColumn, PurpleTableSearchValue, PurpleTableSelectFilter, Guid } from 'purple-lib';
import { Subject, takeUntil } from 'rxjs';
import { DashboardLayout2Service } from 'src/app/dashboard/dashboard-layout-2/dashboard-layout-2.service';
import { AppTranslationService } from 'src/core-modules/localization/localization.service';
import { BackOfficeService, BrandLimitTableItem, BrandLimitTableItemPurpleTableResponse,  PRPVSaleEventFullExtended, PurpleTableSearchGroupGroup, SaleEventLimitedExceptionSaleCategory, SaleEventLimitedExceptionSaleCategoryPurpleTableResponse, SaleEventLimitedSaleCategory, SaleEventLimitedSaleCategoryPurpleTableResponse, SaleEventLimits } from 'src/core-modules/sdk/api';
import { PrivateSaleService } from '../../private-sale-service.service';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NzMessageService } from 'ng-zorro-antd/message';
import { FormBuilder, FormGroup } from '@angular/forms';
import { environment } from 'src/environments/default/environment';

import { CurrencyPipe } from '@angular/common';
import { EditBrandLimitModalComponent } from '../modals/edit-brand-limit-modal/edit-brand-limit-modal.component';
import { EditCategoryLimitModalComponent } from '../modals/edit-category-limit-modal/edit-category-limit-modal.component';
import { EditCategoryLimitExceptionModalComponent } from '../modals/edit-category-limit-exception-modal/edit-category-limit-exception-modal.component';

@Component({
  selector: 'app-private-sale-cash-machine-settings',
  templateUrl: './private-sale-cash-machine-settings.component.html',
  styleUrls: ['./private-sale-cash-machine-settings.component.scss']
})
export class PrivateSaleCashMachineSettingsComponent implements OnInit {

  constructor(private fb: FormBuilder, private tranPipe: PurpleTranslationPipe, public dashSvc: DashboardLayout2Service, private tsvc: AppTranslationService, private router: Router, private mesSvc: NzMessageService,
    public admSvc: BackOfficeService, private seSvc: PrivateSaleService, private apiProxySvc: PurpleApiProxyService, private route: ActivatedRoute, private modal: NzModalService, private currencyPipe: CurrencyPipe) { }

  saleEvent: PRPVSaleEventFullExtended | undefined;
  sub: Subject<void> = new Subject();

  tabIndex: number = 0;

  validateForm: FormGroup = this.fb.group({});
  purpleFormFieldGroups: purpleFormFieldsGroup[] | undefined;
  disableForm: boolean = false;
  isLoading: boolean = false;

  isLimitEnable: boolean = false;

  brandLimitListOfData: Array<BrandLimitTableItemPurpleTableResponse> | undefined | null;
  refreshBrandLimitTableRowsNumber: number = 0;
  brandLimitTabColumns: PurpleTableColumn[] = [
    {
      name: this.tranPipe.transform("brand_limit_table_header_brand", "Brand"),
      dbKey: "brandName",
      hasReorder: true,
      hasFilter: true,
      filterType: 'search'
    },
    {
      name: this.tranPipe.transform("brand_limit_table_header_max_qty", "Quantità Massima"),
      dbKey: "brandLimitMaxQty",
      hasReorder: true,
      hasFilter: false
    },
    {
      name: this.tranPipe.transform("brand_limit_table_header_max_cash", "Spesa Massima"),
      dbKey: "brandLimitMaxAmount",
      hasReorder: true,
      hasFilter: false
    },
    {
      name: this.tranPipe.transform("table_header_actions", "Azioni"),
      hasReorder: false,
      hasFilter: false,
      fixedWidth: "180px"
    }
  ]

  brandLimitRefreshFunction = (args: {
    pageNumber: number, pageSize: number, orderColumnName?: string | null, orderType?: string | null,
    tableSearchValues?: Array<PurpleTableSearchGroupGroup> | null, tableFilters?: Array<PurpleTableSelectFilter> | null, switchOn?: boolean,
    culture: string
  }) => {
    args.culture = this.tsvc.translationLanguage.value;
    args.tableFilters = [
      {
        property: "saleEventId",
        filterValue: this.saleEvent?.saleEventId ?? Guid.empty()
        //filterValue: Guid.empty()
      }
    ]

    return this.admSvc.getSaleEventBrandsLimitsTable(args.culture, args);
  }

  categoryLimitListOfData: Array<SaleEventLimitedSaleCategoryPurpleTableResponse> | undefined | null;
  refreshCategoryLimitTableRowsNumber: number = 0;
  categoryLimitTabColumns: PurpleTableColumn[] = [
    {
      name: this.tranPipe.transform("category_limit_table_header_category", "Categoria"),
      dbKey: "SaleCategory",
      hasReorder: true,
      hasFilter: true,
      filterType: 'search'
    },
    {
      name: this.tranPipe.transform("category_limit_table_header_max_qty", "Quantità Massima"),
      dbKey: "categoryLimitMaxQty",
      hasReorder: true,
      hasFilter: false
    },
    {
      name: this.tranPipe.transform("category_limit_table_header_max_cash", "Spesa Massima"),
      dbKey: "categoryLimitMaxAmount",
      hasReorder: true,
      hasFilter: false
    },
    {
      name: this.tranPipe.transform("table_header_actions", "Azioni"),
      hasReorder: false,
      hasFilter: false,
      fixedWidth: "180px"
    }
  ]

  categoryLimitRefreshFunction = (args: {
    pageNumber: number, pageSize: number, orderColumnName?: string | null, orderType?: string | null,
    tableSearchValues?: Array<PurpleTableSearchGroupGroup> | null, tableFilters?: Array<PurpleTableSelectFilter> | null, switchOn?: boolean,
    culture: string
  }) => {
    args.culture = this.tsvc.translationLanguage.value;
    args.tableFilters = [
      {
        property: "saleEventId",
        filterValue: this.saleEvent?.saleEventId ?? Guid.empty()
        //filterValue: Guid.empty()
      }
    ]

    return this.admSvc.getSaleEventCategoryItemsLimitsTable(args.culture, args);
  }


  categoryExceptionLimitListOfData: Array<SaleEventLimitedExceptionSaleCategoryPurpleTableResponse> | undefined | null;
  refreshcategoryExceptionLimitTableRowsNumber: number = 0;
  categoryExceptionLimitTabColumns: PurpleTableColumn[] = [
    {
      name: this.tranPipe.transform("category_exception_limit_table_header_category", "Categoria"),
      dbKey: "SaleCategory",
      hasReorder: true,
      hasFilter: true,
      filterType: 'search'
    },
    {
      name: this.tranPipe.transform("category_exception_limit_table_header_qty_limit_disabled", "Limiti quantità disabilitati"),
      dbKey: "skipCount",
      hasReorder: true,
      hasFilter: false
    },
    {
      name: this.tranPipe.transform("category_exception_limit_table_header_amount_limit_disabled", "Limiti importo disabilitati"),
      dbKey: "skipAmount",
      hasReorder: true,
      hasFilter: false
    },
    {
      name: this.tranPipe.transform("table_header_actions", "Azioni"),
      hasReorder: false,
      hasFilter: false,
      fixedWidth: "180px"
    }
  ]

  categoryExceptionLimitRefreshFunction = (args: {
    pageNumber: number, pageSize: number, orderColumnName?: string | null, orderType?: string | null,
    tableSearchValues?: Array<PurpleTableSearchGroupGroup> | null, tableFilters?: Array<PurpleTableSelectFilter> | null, switchOn?: boolean,
    culture: string
  }) => {
    args.culture = this.tsvc.translationLanguage.value;
    args.tableFilters = [
      {
        property: "saleEventId",
        filterValue: this.saleEvent?.saleEventId ?? Guid.empty()
        //filterValue: Guid.empty()
      }
    ]

    return this.admSvc.getSaleEventCategoryExceptionLimitsTable(args.culture, args);
  }


  ngOnInit() {
    this.seSvc.currentSaleEvent$.pipe(takeUntil(this.sub)).subscribe(async (se: PRPVSaleEventFullExtended | undefined) => {
      this.saleEvent = se;

      await this.getSaleEventLimits();
    });

    this.route.queryParams.subscribe(async params => {
      this.dashSvc.dashBackButtons.push(
        new dashboardButton(
          "back-button",
          this.tranPipe.transform("dashboard_private_general_iofo_back_button", "Torna alla lista", []),
          () => { this.router.navigate([this.tsvc.currentLanguage.value, 'dashboard', 'private-sales', 'private-sale-list']) },
          undefined,
          "arrow-left",
          undefined,
          "left"
        )
      )

      await this.refreshSale(params['saleEvent']);
    });
  }


  private async getSaleEventLimits() {
    await this.apiProxySvc.makeRequestErrorPage<SaleEventLimits>(() => this.admSvc.getSaleEventLimits(this.tsvc.currentLanguage.value, {
      saleEventId: this.saleEvent!.saleEventId
    }), true, "internal-loader", 500, "internal-error", () => { this.getSaleEventLimits() }, (res: PurpleApiMakeRequestResponse<SaleEventLimits>) => {
      this.purpleFormFieldGroups = [
        {
          fieldGroupNumber: 1,
          fielGroupBootstrapColumn: 12,
          fieldGroupPaddingLeft: false,
          fieldGroupPaddingRight: false,
          formFieldFormGroup: this.validateForm,
          formFields: [
            {
              fieldType: 'input-number',
              fieldControlName: 'maxNetAmount',
              fieldBootstrapColumn: 6,
              fieldName: this.tranPipe.transform('edit_global_limits_max_net_amount', 'Importo netto massimo consentito', []),
              fieldPlaceholder: this.tranPipe.transform('edit_global_limits_max_net_amount_placeholder', 'Specifica il massimo importo netto consentito per scontrino', []),
              fieldValue: res.data?.maxNetAmountItemWithLimits,
              fieldIsRequired: true,
              fieldIsDisabled: false,
              fieldPaddingLeft: false,
              fieldPaddingRight: true,
              fieldOptions: [{ id: 'minNumber', value: 0 }]
            },
            {
              fieldType: 'input-number',
              fieldControlName: 'maxGrossAmount',
              fieldBootstrapColumn: 6,
              fieldName: this.tranPipe.transform('edit_global_limits_max_gross_amount', 'Importo lordo massimo consentito', []),
              fieldPlaceholder: this.tranPipe.transform('edit_global_limits_max_gross_amount_placeholder', 'Specifica il massimo importo lordo consentito per scontrino', []),
              fieldValue: res.data?.maxGrossAmountItemWithLimits,
              fieldIsRequired: true,
              fieldIsDisabled: false,
              fieldPaddingLeft: true,
              fieldPaddingRight: false,
              fieldOptions: [{ id: 'minNumber', value: 0 }]
            },
            {
              fieldType: 'input-number',
              fieldControlName: 'maxItemQty',
              fieldBootstrapColumn: 6,
              fieldName: this.tranPipe.transform('edit_global_limits_max_item_qty_amount', 'Numero massimo articoli consentito', []),
              fieldPlaceholder: this.tranPipe.transform('edit_global_limits_max_item_qty_amount_placeholder', 'Specifica il numero massimo di articoli consentito per scontrino', []),
              fieldValue: res.data?.maxQtyItemWithLimits,
              fieldIsRequired: true,
              fieldIsDisabled: false,
              fieldPaddingLeft: false,
              fieldPaddingRight: true,
              fieldOptions: [{ id: 'minNumber', value: 0 }]
            },
            /* {
              fieldType: 'toggle',
              fieldControlName: 'enabledCashMachineDiscount',
              fieldBootstrapColumn: 6,
              fieldName: this.tranPipe.transform('edit_global_limits_enabled_cash_machine_discount', 'Abilita sconti in cassa', []),
              fieldPlaceholder: this.tranPipe.transform('edit_global_limits_enabled_cash_machine_discount_placeholder', 'Specificare la possibilità di effettuare sconti in cassa', []),
              fieldValue: res.data?.isCartDiscountEnabled,
              fieldIsRequired: true,
              fieldIsDisabled: false,
              fieldPaddingLeft: true,
              fieldPaddingRight: false,
              fieldOptions: [
                { id: 'checkText', value: this.tranPipe.transform('toggle_yes', 'si', []) },
                { id: 'unCheckText', value: this.tranPipe.transform('toggle_no', 'no', []) }
              ]
            } */
          ]
        }
      ];

      this.isLimitEnable = res.data?.hasLimits ?? false;
      this.dashSvc.dashMainItems = [];

      this.setDashboardItems(this.tabIndex)
    })
  }

  onTabIndexChange(tabIdx: number) {
    this.tabIndex = tabIdx;
    this.setDashboardItems(this.tabIndex)
  }


  setDashboardItems(tabIdx: number) {
    this.dashSvc.dashMainItems = [];

    this.dashSvc.dashMainItems.push(
      new dashboardTag(
        "tag-online",
        this.isLimitEnable ? this.tranPipe.transform('cash_machine_settings_limit_enabled', 'Abilitati', []) :
          this.tranPipe.transform('cash_machine_settings_limit_disabled', 'Disabilitati', []),
        true,
        this.isLimitEnable ? "green" : "red",
        () => {
          this.modal.create({
            nzTitle: this.tranPipe.transform("edit_sale_event_cash_machine_limit_status_title", "Stato limiti acquisto: <b>{0}</b>", [
              this.isLimitEnable ? this.tranPipe.transform('cash_machine_settings_limit_enabled', 'Abilitati', []).toUpperCase() :
                this.tranPipe.transform('cash_machine_settings_limit_disabled', 'Disabilitati', []).toUpperCase()
            ]),
            nzContent: this.isLimitEnable ? this.tranPipe.transform("edit_sale_event_cash_machine_limit_status_subtitle_disable", "Vuoi davvero <b>DISABILITARE</b> i limiti per la private sale <b>{0}</b>?",
              [this.saleEvent!.saleEventName]) :
              this.tranPipe.transform("edit_sale_event_cash_machine_limit_status_subtitle_enable", "Vuoi davvero <b>ABILITARE</b> i limiti per la private sale <b>{0}</b>?",
                [this.saleEvent!.saleEventName]),
            nzWidth: '600px',
            nzClassName: 'purple-simple-modal',
            nzCancelText: undefined,
            nzOkText: this.isLimitEnable ? this.tranPipe.transform('edit_sale_event_cash_machine_limit_status_button_disable', 'Disabilita', []) :
              this.tranPipe.transform('edit_sale_event_cash_machine_limit_status_button_enable', 'Abilita', []),
            nzOnOk: async () => {
              await this.enableDisableCashMachineLimits(!this.isLimitEnable);
            }
          });
        }
      )
    )


    if (this.isLimitEnable) {
      switch (tabIdx) {
        case 0:
          this.dashSvc.dashMainItems.push(
            new dashboardButton(
              "set-global-limit",
              this.tranPipe.transform("edit_cash_machine_global_limit_button", "Imposta limiti", []),
              () => { this.editCashMachineLimits() }
            )
          )
          break;

        case 1:
          this.dashSvc.dashMainItems.push(
            new dashboardButton(
              "add-brand-limit",
              this.tranPipe.transform("edit_cash_machine_brand_limit_button", "Aggiungi limiti brand", []),
              () => { this.editBrandLimit(undefined) }
            )
          )
          break;

        case 2:
          this.dashSvc.dashMainItems.push(
            new dashboardButton(
              "add-category-limit",
              this.tranPipe.transform("edit_cash_machine_category_limit_button", "Aggiungi limiti categoria", []),
              () => { this.editCategoryLimit(undefined) }
            )
          )
          break;

        case 3:
          this.dashSvc.dashMainItems.push(
            new dashboardButton(
              "add-category-exception-limit",
              this.tranPipe.transform("edit_cash_machine_category_limit_exception_button", "Aggiungi eccezioni limiti", []),
              () => { this.editCategoryExceptionLimit(undefined) }
            )
          )
          break;

        default:
          break;
      }
    }


  }


  async enableDisableCashMachineLimits(enabled: boolean) {
    await this.apiProxySvc.makeRequestErrorMessage<boolean>(() => this.admSvc.enableDisableSaleEventLimits(this.tsvc.currentLanguage.value, {
      saleEventId: this.saleEvent!.saleEventId,
      hasLimits: enabled
    }), true, "internal-loader", 500, undefined, undefined, async (res: PurpleApiMakeRequestResponse<boolean>) => {
      const text = (res.data ?? false) ?
        this.tranPipe.transform("enable_sale_event_limits_ok_response", "Limiti della private sale \"<b>{0}</b>\" <b>ABILITATI</b>", [this.saleEvent!.saleEventName ?? ""]) :
        this.tranPipe.transform("disable_sale_event_limits_ok_response", "Limiti della private sale \"<b>{0}</b>\" <b>DISABILITATI</b>", [this.saleEvent!.saleEventName ?? ""])

      this.mesSvc.success(text, {
        nzDuration: environment.MESSAGE_DURATION
      });
      await this.getSaleEventLimits();
    })
  }

  async editCashMachineLimits() {
    for (const i in this.validateForm.controls) {
      this.validateForm.controls[i].markAsDirty();
      this.validateForm.controls[i].updateValueAndValidity();
    }

    if (this.validateForm.valid) {
      await this.apiProxySvc.makeRequestErrorMessage<boolean>(() => this.admSvc.updateSaleEventLimits(this.tsvc.currentLanguage.value, {
        saleEventId: this.saleEvent!.saleEventId,
        //isCartDiscountEnabled: this.validateForm.controls["enabledCashMachineDiscount"].value,
        maxGrossAmountItemWithLimits: this.validateForm.controls["maxGrossAmount"].value,
        maxNetAmountItemWithLimits: this.validateForm.controls["maxNetAmount"].value,
        maxQtyItemWithLimits: this.validateForm.controls["maxItemQty"].value
      }), true, "internal-loader", 500, undefined, undefined, async (res: PurpleApiMakeRequestResponse<boolean>) => {
  
        this.mesSvc.success(this.tranPipe.transform("update_cash_machine_limits_ok_response", "Limiti salvati con successo", []), {
          nzDuration: environment.MESSAGE_DURATION
        });
        await this.getSaleEventLimits();
      })
    }    
  }

  getLimitLabel(isLimitEnable: boolean, limitValue: number | undefined, isCurrency: boolean = false) {
    if (!isLimitEnable) {
      return this.tranPipe.transform('cash_machine_limit_no_limits', 'Nessun limite', [])
    }

    if (isCurrency) {
      return this.currencyPipe.transform(limitValue, this.saleEvent?.currency)
    }

    return limitValue;
  }


  getLimitStatusLabel(isLimitEnable: boolean) {
    if (isLimitEnable) {
      return this.tranPipe.transform('table_yes', 'Si', []);
    }

    return this.tranPipe.transform('table_no', 'No', []);
  }

  private async refreshSale(seId: string) {
    await this.dashSvc.refreshSaleEvent(seId, "private-sale-cash-machine-settings", 'Imposta i limiti di acquisto');
  }

  ngOnDestroy(): void {
    this.sub.next();
    this.sub.complete();
    this.dashSvc.clearPageHeader();
  }



  ////////////////////BRAND LIMITS//////////////////////////////////////
  editBrandLimit(brand: BrandLimitTableItem | undefined) {
    this.modal.create<EditBrandLimitModalComponent, { brandLimitFull: BrandLimitTableItem | undefined, saleEventId: string }>({
      nzContent: EditBrandLimitModalComponent,
      nzData: {
        brandLimitFull: brand,
        saleEventId: this.saleEvent!.saleEventId
      },
      nzTitle: brand == undefined ?
        this.tranPipe.transform('edit_brand_limit_modal_title_new', 'Nuovo limite brand', []) :
        this.tranPipe.transform('edit_brand_limit_modal_title_update', 'Modifica limite brand', []),
      nzWidth: '800px',
      nzClassName: 'purple-modal',
      nzFooter: null,
      nzMaskClosable: false,
    }).afterClose.subscribe(async () => {
      this.refreshBrandLimitTableRowsNumber++;
    });
  }

  async deleteBrandLimit(brand: BrandLimitTableItem) {
    this.modal.create({
      nzTitle: this.tranPipe.transform('delete_brand_limit_modal_title', 'Eliminare limiti per il brand: <b>{0}</b>', [brand.brandName]),
      nzContent: this.tranPipe.transform('delete_brand_limit_modal_subtitle', 'Tutti i limiti imposti sul brand <b>{0}</b> verranno definitivamente cancellati', [brand.brandName]),
      nzWidth: '600px',
      nzClassName: 'purple-simple-modal',
      nzOkText: this.tranPipe.transform('delete_brand_limit_modal_button', 'Elimina', []),
      nzOnOk: async () => {

        await this.apiProxySvc.makeRequestErrorMessage<boolean>(() => this.admSvc.deleteSaleEventBrandLimit(this.tsvc.currentLanguage.value, {
          brandId: brand.brandId,
          saleEventId: brand.saleEventId
        }), false, "internal-loader", 500, undefined, undefined, (res: PurpleApiMakeRequestResponse<boolean>) => {
          this.mesSvc.success(this.tranPipe.transform("delete_brand_limit_modal_ok_response", "Limiti brand eliminati correttamente"), {
            nzDuration: environment.MESSAGE_DURATION
          });
        })
        this.refreshBrandLimitTableRowsNumber++;
      }
    })
  }


  ////////////////////CATEGORY LIMITS//////////////////////////////////////
  editCategoryLimit(category: SaleEventLimitedSaleCategory | undefined) {
    this.modal.create<EditCategoryLimitModalComponent, { categoryLimitFull: SaleEventLimitedSaleCategory | undefined, saleEventId: string }>({
      nzContent: EditCategoryLimitModalComponent,
      nzData: {
        categoryLimitFull: category,
        saleEventId: this.saleEvent!.saleEventId
      },
      nzTitle: category == undefined ?
        this.tranPipe.transform('edit_category_limit_modal_title_new', 'Nuovo limite categoria', []) :
        this.tranPipe.transform('edit_category_limit_modal_title_update', 'Modifica limite categoria', []),
      nzWidth: '800px',
      nzClassName: 'purple-modal',
      nzFooter: null,
      nzMaskClosable: false,
    }).afterClose.subscribe(async () => {
      this.refreshCategoryLimitTableRowsNumber++;
    });
  }

  async deleteCategoryLimit(category: SaleEventLimitedSaleCategory) {
    this.modal.create({
      nzTitle: this.tranPipe.transform('delete_category_limit_modal_title', 'Eliminare limiti per la categoria: <b>{0}</b>', [category.saleCategory]),
      nzContent: this.tranPipe.transform('delete_category_limit_modal_subtitle', 'Tutti i limiti imposti sulla categoria <b>{0}</b> verranno definitivamente cancellati', [category.saleCategory]),
      nzWidth: '600px',
      nzClassName: 'purple-simple-modal',
      nzOkText: this.tranPipe.transform('delete_brand_limit_modal_button', 'Elimina', []),
      nzOnOk: async () => {

        await this.apiProxySvc.makeRequestErrorMessage<boolean>(() => this.admSvc.deleteSaleEventCategoryLimit(this.tsvc.currentLanguage.value, {
          saleCategory: category.saleCategory,
          saleEventId: category.saleEventId
        }), false, "internal-loader", 500, undefined, undefined, (res: PurpleApiMakeRequestResponse<boolean>) => {
          this.mesSvc.success(this.tranPipe.transform("delete_category_limit_modal_ok_response", "Limiti categoria eliminati correttamente"), {
            nzDuration: environment.MESSAGE_DURATION
          });
          this.refreshCategoryLimitTableRowsNumber++;
        })
      }
    })
  }

  ////////////////////CATEGORY LIMITS//////////////////////////////////////
  editCategoryExceptionLimit(categoryException: SaleEventLimitedExceptionSaleCategory | undefined) {
    this.modal.create<EditCategoryLimitExceptionModalComponent, { categoryExceptionLimitFull: SaleEventLimitedExceptionSaleCategory | undefined, saleEventId: string }>({
      nzContent: EditCategoryLimitExceptionModalComponent,
      nzData: {
        categoryExceptionLimitFull: categoryException,
        saleEventId: this.saleEvent!.saleEventId
      },
      nzTitle: categoryException == undefined ?
        this.tranPipe.transform('edit_category_limit_exception_modal_title_new', 'Nuova Eccezione Limite Categoria', []) :
        this.tranPipe.transform('edit_category_limit_exception_modal_title_update', 'Modifica Eccezione Limite Categoria', []),
      nzWidth: '1000px',
      nzOkText: "Conferma",
      nzClassName: 'purple-modal',
      nzFooter: null,
      nzMaskClosable: false,
    }).afterClose.subscribe(async () => {
      this.refreshcategoryExceptionLimitTableRowsNumber++;
    });
  }


  async deleteCategoryExceptionLimit(category: SaleEventLimitedExceptionSaleCategory) {
    this.modal.create({
      nzTitle: this.tranPipe.transform('delete_category_exception_limit_modal_title', 'Eliminare eccezioni limiti per la categoria: <b>{0}</b>', [category.saleCategory]),
      nzContent: this.tranPipe.transform('delete_catgory_exception_limit_modal_subtitle', 'Tutte le eccezioni dei limiti imposti sulla categoria <b>{0}</b> verranno definitivamente cancellati', [category.saleCategory]),
      nzWidth: '600px',
      nzClassName: 'purple-simple-modal',
      nzOkText: this.tranPipe.transform('delete_category_limit_modal_button', 'Elimina', []),
      nzOnOk: async () => {

        await this.apiProxySvc.makeRequestErrorMessage<boolean>(() => this.admSvc.deleteSaleEventCategoryExceptionLimit(this.tsvc.currentLanguage.value, {
          saleCategory: category.saleCategory,
          saleEventId: category.saleEventId
        }), false, "internal-loader", 500, undefined, undefined, (res: PurpleApiMakeRequestResponse<boolean>) => {
          this.mesSvc.success(this.tranPipe.transform("delete_category_exception_limit_modal_ok_response", "Eccezioni limiti categoria eliminati correttamente"), {
            nzDuration: environment.MESSAGE_DURATION
          });
          this.refreshcategoryExceptionLimitTableRowsNumber++;
        })
      }
    })
  }
}
