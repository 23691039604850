<nz-spin [nzSpinning]="isLoading" [nzIndicator]="purpleModalLoaderTemplate">
  <app-purple-form *ngIf="purpleFormFieldGroups!= undefined" [disbledForm]="disableForm" [(formGroup)]="validateForm"
    [purpleFormFieldGroups]="purpleFormFieldGroups">
  </app-purple-form>
</nz-spin>
<ng-template #purpleModalLoaderTemplate>
  <div class="purple-modal-custom-spinner">
    <span nz-icon nzType="loading"></span>
  </div>
</ng-template>
<div class="purple-button-container ps-w-100 ps-d-flex">
  <button (click)="editUserAddress()" nz-button class="purple-modal-button" [disabled]="isLoading"
    [nzLoading]="isLoading">
    <container-element [ngSwitch]="isNew">
      <ng-container *ngSwitchCase="true">
        {{ 'edit_user_address_update' | translation: 'Aggiungi': []}}
      </ng-container>
      <ng-container *ngSwitchCase="false">
        {{ 'edit_user_address_add' | translation: 'Modifica': []}}
      </ng-container>
    </container-element>
  </button>
</div>