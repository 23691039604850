import { Component, OnInit } from '@angular/core';
import { PurpleApiMakeRequestResponse, PurpleApiProxyService } from 'purple-lib';
import { AppTranslationService } from 'src/core-modules/localization/localization.service';
import { BackOfficeService, PRPVPolicyFull } from 'src/core-modules/sdk/api';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { NzModalRef } from 'ng-zorro-antd/modal';

@Component({
  selector: 'app-reorder-policies-modal',
  templateUrl: './reorder-policies-modal.component.html',
  styleUrls: ['./reorder-policies-modal.component.scss']
})
export class ReorderPoliciesModalComponent implements OnInit {

  constructor(private tsvc: AppTranslationService, private apiProxySvc: PurpleApiProxyService,
    private ammSvc: BackOfficeService, private modal: NzModalRef) { }

  policies: PRPVPolicyFull[] = [];
  pagePolicies: PRPVPolicyFull[] = [];
  isLoading: boolean = false;
  selectedPage: string | undefined;
  
  async ngOnInit() {
    console.log("POLICIES: ", this.policies)
  }

  changePage(page: string) {
    switch (page.toLowerCase()) {
      case 'footer':
        this.pagePolicies = this.policies.filter(f=>f.policyPage.strEq("footer"));
        break;
      case 'register':
        this.pagePolicies = this.policies.filter(f=>f.policyPage.strEq("register"));
        break;
      case 'booking':
        this.pagePolicies = this.policies.filter(f=>f.policyPage.strEq("booking"));
        break;
      default:
        break;
    }

    console.log("PAGE POLICIES: ", this.pagePolicies)
  }

  reorderFields(event: any) {
    moveItemInArray(this.pagePolicies, event.previousIndex, event.currentIndex);

    for (let index = 1; index <= this.pagePolicies.length; index++) {
      this.pagePolicies[index - 1].policyOrderIdx = index;
    }
  }

  async saveReorder() {
    await this.apiProxySvc.makeRequestErrorMessage<boolean>(()=>this.ammSvc.reorderSaleEventPolicies(this.tsvc.currentLanguage.value, {
      policies: this.pagePolicies
    }),false, "internal-loader",500,undefined,undefined,(res: PurpleApiMakeRequestResponse<boolean>)=>{
      this.modal.close(true)
    })
  }
}
