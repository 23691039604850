import { Component, Inject, Input, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { purpleFormFieldsGroup, PurpleApiProxyService, PurpleTranslationPipe, Helper, PurpleSelectFilter, PurpleApiMakeRequestResponse } from 'purple-lib';
import { AppTranslationService } from 'src/core-modules/localization/localization.service';
import { LocalizationService, BrandLimitTableItem, BackOfficeService } from 'src/core-modules/sdk/api';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalRef, NZ_MODAL_DATA } from 'ng-zorro-antd/modal';

import { takeUntil, Subject } from 'rxjs';
import { environment } from 'src/environments/default/environment';

@Component({
  selector: 'app-edit-brand-limit-modal',
  templateUrl: './edit-brand-limit-modal.component.html',
  styleUrls: ['./edit-brand-limit-modal.component.scss']
})
export class EditBrandLimitModalComponent implements OnInit {
  /* @Input() brandLimitFull: BrandLimitTableItem | undefined = undefined;
  @Input() saleEventId!: string; */

  sub: Subject<void> = new Subject();
  originalBrandLimit!: EditBrandLimit;
  newObj!: EditBrandLimit;
  isNew: boolean = false;
  validateForm: FormGroup = this.fb.group({});
  purpleFormFieldGroups: purpleFormFieldsGroup[] | undefined;
  disableForm: boolean = false;
  isLoading: boolean = false;

  constructor(@Inject(NZ_MODAL_DATA) private data: { brandLimitFull: BrandLimitTableItem | undefined, saleEventId: string }, private modalRef: NzModalRef, private mesSvc: NzMessageService, private apiProxySvc: PurpleApiProxyService, private admSvc: BackOfficeService,
    private locSvc: LocalizationService, private tsvc: AppTranslationService, private fb: FormBuilder, private tranPipe: PurpleTranslationPipe) { }

  async ngOnInit(): Promise<void> {
    this.originalBrandLimit = {
      brandId: this.data.brandLimitFull?.brandId,
      brandLimitMaxAmount: this.data.brandLimitFull?.brandLimitMaxAmount,
      brandLimitMaxQty: this.data.brandLimitFull?.brandLimitMaxQty,
      isBrandLimitMaxAmount: this.data.brandLimitFull?.isBrandLimitMaxAmount,
      isBrandLimitMaxQty: this.data.brandLimitFull?.isBrandLimitMaxQty
    }

    if(this.data.brandLimitFull == undefined){
      this.isNew = true;
    }

    this.newObj = Helper.storeNewObj(this.originalBrandLimit);

    this.purpleFormFieldGroups = [
      {
        fieldGroupNumber: 1,
        fielGroupBootstrapColumn: 12,
        fieldGroupPaddingLeft: false,
        fieldGroupPaddingRight: false,
        formFieldFormGroup: this.validateForm,
        formFields: [
          {
            fieldType: 'select',
            fieldControlName: 'brand',
            fieldBootstrapColumn: 12,
            fieldName: this.tranPipe.transform('edit_brand_limit_brand', 'Brand', []),
            fieldPlaceholder: this.tranPipe.transform('edit_brand_limit_brand_placeholder', 'Seleziona il brand', []),
            fieldValue: this.newObj.brandId,
            fieldIsRequired: true,
            fieldIsDisabled: false,
            fieldPaddingLeft: false,
            fieldPaddingRight: false,
            fieldOptions: [
              { id: 'scrollFunction', value: this.brandsScrollFunction },
              { id: 'searchFunction', value: this.brandsSearchFunction },
              { id: 'searchByIdFunction', value: this.brandsSearchByIdFunction },
              { id: 'refreshFieldNumber', value: 0 },
            ]
          },
          {
            fieldType: 'input-number',
            fieldControlName: 'brandLimitMaxAmount',
            fieldBootstrapColumn: 12,
            fieldName: this.tranPipe.transform('edit_brand_limit_max_amount', 'Importo massimo consentito', []),
            fieldPlaceholder: this.tranPipe.transform('edit_brand_limit_max_amount_placeholder', 'Seleziona l\'importo massimo consentito per scontrino', []),
            fieldValue: this.newObj.brandLimitMaxAmount,
            fieldIsRequired: this.newObj.isBrandLimitMaxAmount,
            fieldIsDisabled: !this.newObj.isBrandLimitMaxAmount,
            fieldPaddingLeft: false,
            fieldPaddingRight: false,
            fieldOptions: [{ id: 'minNumber', value: 0 }],
            fieldAffix: {
              fieldType: 'toggle',
              fieldControlName: 'isBrandLimitMaxAmount',
              fieldWidth: "60px",
              fieldValue: this.newObj.isBrandLimitMaxAmount,
              fieldIsRequired: false,
              fieldIsDisabled: false,
              fieldOptions: [],
              fieldPlaceholder: ""
            }
          },
          {
            fieldType: 'input-number',
            fieldControlName: 'brandLimitMaxQty',
            fieldBootstrapColumn: 12,
            fieldName: this.tranPipe.transform('edit_brand_limit_max_qty', 'Numero massimo articoli consentito', []),
            fieldPlaceholder: this.tranPipe.transform('edit_brand_limit_max_qty_placeholder', 'Seleziona il numero massimo di articoli per scontrino', []),
            fieldValue: this.newObj.brandLimitMaxQty,
            fieldIsRequired: this.newObj.isBrandLimitMaxQty,
            fieldIsDisabled: !this.newObj.isBrandLimitMaxQty,
            fieldPaddingLeft: false,
            fieldPaddingRight: false,
            fieldOptions: [{ id: 'minNumber', value: 0 }],
            fieldAffix: {
              fieldType: 'toggle',
              fieldControlName: 'isBrandLimitMaxQty',
              fieldWidth: "60px",
              fieldValue: this.newObj.isBrandLimitMaxQty,
              fieldIsRequired: false,
              fieldIsDisabled: false,
              fieldOptions: [],
              fieldPlaceholder: ""
            }
          }
        ]
      }
    ]

    setTimeout(() => {
      this.validateForm.controls["isBrandLimitMaxAmount"].valueChanges.pipe(takeUntil(this.sub)).subscribe((s: boolean) => {
        if (s) {
          this.validateForm.controls['brandLimitMaxAmount'].enable();
          this.validateForm.controls['brandLimitMaxAmount'].addValidators(Validators.required);
        } else {
          this.validateForm.controls['brandLimitMaxAmount'].disable();
          this.validateForm.controls['brandLimitMaxAmount'].removeValidators(Validators.required);
        }
      });

      this.validateForm.controls["isBrandLimitMaxQty"].valueChanges.pipe(takeUntil(this.sub)).subscribe((s: boolean) => {
        if (s) {
          this.validateForm.controls['brandLimitMaxQty'].enable();
          this.validateForm.controls['brandLimitMaxQty'].addValidators(Validators.required);
        } else {
          this.validateForm.controls['brandLimitMaxQty'].disable();
          this.validateForm.controls['brandLimitMaxQty'].removeValidators(Validators.required);
        }
      });
    }, 100);
  }


  async editBrandLimit() {
    this.isLoading = true;
    for (const i in this.validateForm.controls) {
      this.validateForm.controls[i].markAsDirty();
      this.validateForm.controls[i].updateValueAndValidity();
    }
    if (this.validateForm.valid) {
      await this.apiProxySvc.makeRequestErrorMessage<boolean>(() => this.admSvc.addOrUpdateSaleEventBrandLimit(this.tsvc.currentLanguage.value, {
        brandId: this.validateForm.controls['brand'].value,
        brandLimitMaxAmount: this.validateForm.controls['brandLimitMaxAmount'].value,
        brandLimitMaxQty: this.validateForm.controls['brandLimitMaxQty'].value,
        isBrandLimitMaxAmount: this.validateForm.controls['isBrandLimitMaxAmount'].value,
        isBrandLimitMaxQty: this.validateForm.controls['isBrandLimitMaxQty'].value,
        isNew: this.isNew,
        saleEventId: this.data.saleEventId
      }), false, "internal-loader", 500, undefined, undefined, (res: PurpleApiMakeRequestResponse<boolean>) => {
        let text: string;
        if (this.isNew) {
          text = this.tranPipe.transform('edit_limit_brand_add_success', 'Limite Brand aggiunto con successo', [])
        } else {
          text = this.tranPipe.transform('edit_limit_brand_update_success', 'Limite Brand aggiornato con successo', [])
        }
        this.mesSvc.success(text, {
          nzDuration: environment.MESSAGE_DURATION
        });
        this.modalRef.destroy(res.data);
      })
      
    }
    this.isLoading = false
  }

  //ScrollFunction
  brandsScrollFunction = (args: { pageNumber: number, pageSize: number, culture: string, searchName: string, searchFilters: PurpleSelectFilter[] }) => {
    args.searchFilters = [
      { propertyName: "saleEventId", filterValues: [this.data.saleEventId], filterOperator:"=" }
    ]
    return this.admSvc.getSaleEventItemsBrandsSelect(args.culture, args)
  }
  //SearchFunction
  brandsSearchFunction = (args: { pageNumber: number, pageSize: number, culture: string, searchName: string, searchFilters: PurpleSelectFilter[] }) => {
    args.searchFilters = [
      { propertyName: "saleEventId", filterValues: [this.data.saleEventId], filterOperator:"=" }
    ]
    return this.admSvc.getSaleEventItemsBrandsByNameSelect(args.culture, args)
  }
  //InitFunction (SearchByIdFunction)
  brandsSearchByIdFunction = (args: { pageNumber: number, pageSize: number, culture: string, searchName: string, searchFilters: PurpleSelectFilter[] }) => {
    return this.admSvc.getSaleEventItemsBrandsByIdSelect(args.culture, args)
  }

}


interface EditBrandLimit {
  brandId: string | undefined;
  brandLimitMaxQty: number | undefined;
  isBrandLimitMaxQty: boolean | undefined;
  brandLimitMaxAmount: number | undefined;
  isBrandLimitMaxAmount: boolean | undefined;
}