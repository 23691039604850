<purple-table #purpleTable [tableBody]="tableBody" [isBordered]="true" [switchOn]="false"
  [refreshFunction]="refreshFunction" (tableData)="listOfData = $event" [tableColumns]="tabColumns"
  [tableScroll]="{ x: '1120px', y: '500px' }" [refreshTableRowsNumber]="refreshTableRowsNumber" [version]="2"
  [showCustomPagination]="true">

  <ng-template #tableBody>
    <ng-container *ngFor="let data of listOfData; let i = index">
      <tr class="{{i%2==0?'main-purple-table-tr-even': 'main-purple-table-tr-odd'}} ">
        <td>{{ data.item.addressLabel }}</td>
        <td>{{ data.item.addressName }}</td>
        <td>{{ data.item.addressNumber }}</td>
        <td>{{ data.item.intercom }}</td>
        <td>{{ data.item.addressCity }}</td>
        <td>{{ data.item.addressProvince }}</td>
        <td>{{ data.item.addressCountry }}</td>
        <td>{{ data.item.notes }}</td>
        <td [nzAlign]="'center'">
          <container-element [ngSwitch]="data.item.isEnable">
            <ng-container *ngSwitchCase="true">
              <p class="ps-m-0 ps-p-0 fw-b">{{ 'table_yes' | translation: 'Si': []}}</p>
            </ng-container>
            <ng-container *ngSwitchCase="false">
              <p class="ps-m-0 ps-p-0 fw-b">{{ 'table_no' | translation: 'No': []}}</p>

            </ng-container>
          </container-element>
        </td>
        <td [nzAlign]="'center'">

          <button (click)="enableDisableUserAddress(data.item!)" class="ps-mr-3"
            [nzTooltipTitle]='getEnableTooltipTitle(data.item!)' nzTooltipPlacement="top" nz-tooltip nz-button
            nzType="default" nzShape="circle">
            <i nz-icon [nzType]="!data.item.isEnable?'check':'stop'"></i>
          </button>

          <button (click)="editUserAddress(data.item)"
            [nzTooltipTitle]='"user_address_table_edit" | translation: "Modifica indirizzo"' nzTooltipPlacement="top"
            nz-tooltip class="ps-mr-3" nz-button nzType="default" nzShape="circle"><i nz-icon class="fsp-18-0"
              nzType="edit"></i></button>
      </tr>
    </ng-container>
  </ng-template>
</purple-table>

<div class="purple-button-container ps-w-100 ps-d-flex ps-pt-2">
  <button (click)="editUserAddress()" nz-button class="purple-modal-button">
    {{ 'user_address_table_add_new_button' | translation: 'Aggiungi nuovo Indirizzo': []}}
  </button>
</div>