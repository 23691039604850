import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthenticationService } from 'src/core-modules/authentication/authentication.service';
import { AppTranslationService } from 'src/core-modules/localization/localization.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  validateForm!: FormGroup;

  async submitForm(): Promise<void> {
    for (const i in this.validateForm.controls) {
      if (this.validateForm.controls.hasOwnProperty(i)) {
        this.validateForm.controls[i].markAsDirty();
        this.validateForm.controls[i].updateValueAndValidity();
      }
    }

    if (this.validateForm.valid) {
      const mode = this.validateForm.controls["remember"].value ? 'local' : 'session';

      console.log("Mode: ", mode)
      this.authSvc.login(this.validateForm.controls["email"].value, this.validateForm.controls["password"].value, mode).then(
        async (authenticated: boolean) => {
          if (authenticated) {
            console.log('Autenticato');
            await this.router.navigate([this.tsvc.currentLanguage.value,'dashboard']);
          }
        }
      )
    }
  }

  constructor(private fb: FormBuilder, public authSvc: AuthenticationService, private router: Router,
    public tsvc: AppTranslationService) { }

  async ngOnInit(): Promise<void> {
    this.validateForm = this.fb.group({
      email: [null, [Validators.email, Validators.required]],
      password: [null, [Validators.required]],
      remember: [true]
    });

    if (this.authSvc.isLoggedIn){
      console.log('Sono loggato navigo a dashboard');
      await this.router.navigate([this.tsvc.currentLanguage.value,'dashboard']);
    }
  }
}
