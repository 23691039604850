import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { LoginResponse, PurpleApiResponseStatus, UsersService } from '../sdk/api';
import { AppTranslationService } from '../localization/localization.service';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalService } from 'ng-zorro-antd/modal';
import { IAuthenticatedUser } from './IAuthenticatedUser';
import { PurpleApiMakeRequestResponse, PurpleApiProxyService, PurpleStorageService, PurpleTranslationPipe } from 'purple-lib';
import { environment } from 'src/environments/default/environment';
import { JwtHelperService } from '@auth0/angular-jwt';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  isLoggedIn = false;
  currentUser: IAuthenticatedUser | undefined;
  storageMode = "local";
  bookingHost: string = localStorage.getItem("BookingHost")!;

  constructor(private storageService: PurpleStorageService, private tsvc: AppTranslationService, private userSvc: UsersService, private mesSvc: NzMessageService,
    private modal: NzModalService, private tranPipe: PurpleTranslationPipe, private jwtSvc: JwtHelperService, private apiProxySvc: PurpleApiProxyService) {
    this.loadFromStorage();
  }

  isPurpleAdminUser() {
    return (this.currentUser?.roleLevel ?? environment.DEFAULT_MENU_LEVEL) === 0;
  }

  isAdminUser() {
    return (this.currentUser?.roleLevel ?? environment.DEFAULT_MENU_LEVEL) < environment.DEFAULT_MENU_LEVEL;
  }

  async login(username: string, password: string, mode: string = 'local'): Promise<boolean> {
    this.isLoggedIn = false;
    this.storageMode = mode;

    await this.apiProxySvc.makeRequestErrorMessage<LoginResponse>(() => this.userSvc.login(this.tsvc.currentLanguage.value, {
      email: username,
      password: password
    }), true, "global", 500, undefined, undefined, (res: PurpleApiMakeRequestResponse<LoginResponse>) => {
      this.setUserLogin(res.data!)
    });

    return this.isLoggedIn;
  }

  checkUserRoles(accessToken: string) {
    if (this.currentUser != undefined) {
      try {
        var t = this.jwtSvc.decodeToken(accessToken);
        var roles = [];

        if (typeof (t.role) == 'string') {
          roles.push(t.role);
        } else {
          roles = (t.role as string[]).filter((value: string, index: number, array: string[]) => array.indexOf(value) === index)
        }

        this.currentUser.roleLevel = +t.RoleLevel;
        this.currentUser.roles = (roles as string[]);
      } catch (error) {
        this.currentUser.roleLevel = environment.DEFAULT_MENU_LEVEL;
        this.currentUser.roles = (roles as string[]);
      }
    }

  }

  checkVerified(accessToken: string) {
    if (this.currentUser != undefined) {
      try {
        var t = this.jwtSvc.decodeToken(accessToken);
        this.currentUser.isVerified = t.IsVerified.strEq("true");
      } catch (error) {
        this.currentUser.isVerified = false;
      }
    }
  }

  setUserLogin(loginResp: LoginResponse) {
    this.currentUser = {
      token: loginResp.accessToken,
      user: loginResp.user
    };
    this.storageService.set('CurrentUser', this.currentUser, this.storageMode);
    this.storageService.set('StorageMode', this.storageMode);

    this.checkUserRoles(loginResp.accessToken);
    this.checkVerified(loginResp.accessToken);

    this.isLoggedIn = this.currentUser?.token != undefined;
  }

  logout(): boolean {
    //console.log("LOGOUT")
    this.isLoggedIn = false;
    this.currentUser = undefined;
    this.storageService.remove('CurrentUser', this.storageMode);
    this.storageService.remove('StorageMode', this.storageMode);
    this.storageMode = 'local';
    return !this.isLoggedIn;
  }

  private loadFromStorage(): void {
    this.storageMode = this.storageService.get<string>('StorageMode');
    this.currentUser = this.storageService.get<IAuthenticatedUser>('CurrentUser', this.storageMode);
    this.isLoggedIn = this.currentUser?.token != undefined;
  }

  async requestResetPassword(email: string, isMobile: boolean): Promise<boolean> {
    const reqResp = await lastValueFrom(this.userSvc.requestResetPassword(this.tsvc.currentLanguage.value, {
      email: email,
      clientHost: this.bookingHost
    }));

    const isOk = reqResp.status == PurpleApiResponseStatus.Success;
    let title = "";

    if (isOk) {
      if (isMobile) {
        title = this.tranPipe.transform("modal_request_reset_password_title_mobile_ok", "Richiesta inviata")
      } else {
        title = this.tranPipe.transform("modal_request_reset_password_title_ok", "Richiesta inviata correttamente")
      }
    } else {
      if (isMobile) {
        title = this.tranPipe.transform("modal_request_reset_password_title_mobile_ko", "Errore")
      } else {
        title = this.tranPipe.transform("modal_request_reset_password_title_ko", "Ops! qualcosa è andato storto")
      }
    }

    this.modal.create(
      {
        nzTitle: title,
        nzContent: reqResp.message,
        nzWidth: isMobile ? '80%' : '600px',
        nzClassName: 'purple-simple-modal',
        nzCentered: true,
        nzClosable: false,
        nzMaskClosable: false,
        nzCancelText: null,
        nzOkText: null
      }
    )

    return isOk;
  }

  async checkResetPasswordLinkUrl(resetId: string): Promise<{ status: boolean, message: string }> {
    const reqResp = await lastValueFrom(this.userSvc.checkResetPasswordLink(this.tsvc.currentLanguage.value, {
      resetId: resetId
    }));

    return { status: (reqResp.status ?? PurpleApiResponseStatus.Error) == PurpleApiResponseStatus.Success, message: reqResp.message };
  }

  async resetPassword(email: string, resetId: string, newPassword: string): Promise<{ status: boolean, message: string }> {
    const reqResp = await lastValueFrom(this.userSvc.resetPassword(this.tsvc.currentLanguage.value, {
      email: email,
      newPassword: newPassword,
      resetId: resetId,
      clientHost: this.bookingHost
    }));

    return { status: (reqResp.status ?? PurpleApiResponseStatus.Error) == PurpleApiResponseStatus.Success, message: reqResp.message };
  }


}
