import { Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalService, NZ_MODAL_DATA } from 'ng-zorro-antd/modal';
import { PurpleTranslationPipe, PurpleTableSearchValue, PurpleTableColumn, PurpleTableSelectFilter } from 'purple-lib';
import { AppTranslationService } from 'src/core-modules/localization/localization.service';
import { UserTableItem, PRPVUserAddress, BackOfficeService, PRPVUserAddressPurpleTableResponse, PurpleTableSearchGroupGroup } from 'src/core-modules/sdk/api';
import { environment } from 'src/environments/default/environment';
import { EditUserAddressDetailComponent } from '../edit-user-address-detail/edit-user-address-detail.component';
import { PurpleApiProxyService } from 'purple-lib';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-edit-user-addresses',
  templateUrl: './edit-user-addresses.component.html',
  styleUrls: ['./edit-user-addresses.component.scss']
})
export class EditUserAddressesComponent implements OnInit, OnDestroy {

  constructor(@Inject(NZ_MODAL_DATA) private user: UserTableItem, private tranPipe: PurpleTranslationPipe, private modal: NzModalService, private apiProxySvc: PurpleApiProxyService,
    private mesSvc: NzMessageService, private backUserSvc: BackOfficeService, private tsvc: AppTranslationService) { }
  
  sub: Subject<void> = new Subject();
  listOfData: Array<PRPVUserAddressPurpleTableResponse> | undefined | null;
  refreshTableRowsNumber: number = 0;
  showTable: boolean = false;
  tabColumns: PurpleTableColumn[] = [
    {
      name: this.tranPipe.transform('user_address_table_header_label','Label', []),
      hasReorder: false,
      hasFilter: false
    },
    {
      name: this.tranPipe.transform('user_address_table_header_street','Via/Piazza', []),
      hasReorder: false,
      hasFilter: false
    },
    {
      name: this.tranPipe.transform('user_address_table_header_address_number','Civico', []),
      hasReorder: false,
      hasFilter: false
    },
    {
      name: this.tranPipe.transform('user_address_table_header_address_intercom','Citofono', []),
      hasReorder: false,
      hasFilter: false
    },
    {
      name: this.tranPipe.transform('user_address_table_header_address_city','Città', []),
      hasReorder: false,
      hasFilter: false
    },
    {
      name: this.tranPipe.transform('user_address_table_header_address_province','Provincia', []),
      hasReorder: false,
      hasFilter: false
    },
    {
      name: this.tranPipe.transform('user_address_table_header_address_country','Paese', []),
      hasReorder: false,
      hasFilter: false
    },
    {
      name: this.tranPipe.transform('user_address_table_header_address_notes','Note', []),
      hasReorder: false,
      hasFilter: false
    },
    {
      name: this.tranPipe.transform('user_address_table_header_address_is_active','Attivo', []),
      hasReorder: false,
      hasFilter: false
    },
    {
      name: this.tranPipe.transform("table_header_actions", "Azioni"),
      hasReorder: false,
      hasFilter: false,
      fixedWidth: "150px"
    }
  ]

  ngOnDestroy(): void {
    this.sub.next();
    this.sub.complete();
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.showTable = true;
    }, 200);
  }

  refreshFunction = (args: {
    pageNumber: number, pageSize: number, orderColumnName?: string | null, orderType?: string | null,
    tableSearchValues?: Array<PurpleTableSearchGroupGroup> | null, tableFilters?: Array<PurpleTableSelectFilter> | null, switchOn?: boolean,
    culture: string
  }) => {
    args.tableFilters = [{
      property: "UserId",
      filterValue: this.user.userId
    }];
    args.switchOn = false;

    return this.backUserSvc.getUserAddressesTable(args.culture, args)
  }

  getEnableTooltipTitle(address: PRPVUserAddress) {
    if (address.isEnable) {
      return this.tranPipe.transform('edit_address_enable_button_active', 'Attiva indirizzo', [])
    } else {
      return this.tranPipe.transform('edit_address_enable_button_not_active', 'Disabilita indirizzo', [])
    }
  }

  editUserAddress(currUserAddress?: PRPVUserAddress | undefined) {
    this.modal.create<EditUserAddressDetailComponent, { userAddressFull: PRPVUserAddress | undefined, userId: string }>({
      nzTitle: currUserAddress == undefined ? this.tranPipe.transform("table_item_edit_modal_title_new", "Aggiungi {0}", ["indirizzo"]) : this.tranPipe.transform("table_item_edit_modal_title_update", "Modifica {0}", ["indirizzo"]),
      nzContent: EditUserAddressDetailComponent,
      nzData: {
        userAddressFull: currUserAddress,
        userId: this.user.userId
      },
      nzWidth: '1000px',
      nzClassName: 'purple-modal',
      nzFooter: null,
    }).afterClose.pipe(takeUntil(this.sub)).subscribe(async (refresh: boolean | undefined) => {
      if (refresh ?? false) {
        this.refreshTableRowsNumber++;
      }
    });
  }

  enableDisableUserAddress(currAdd: PRPVUserAddress) {
    var title: string;
    var subtitle: string;
    var message: string;
    var button: string;
    if (currAdd.isEnable) {
      title = this.tranPipe.transform("disable_user_address_admin_title", "Disabilitare il seguente indirizzo?");
      subtitle = this.tranPipe.transform("disable_user_address_admin_subtitle", "Vuoi davvero disabilitare il seguente indirizzo: <b>{0}</b>", [currAdd.addressFull ?? ""])
      message = this.tranPipe.transform("disable_user_address_admin_ok_response", "Indirizzo disabilitato correttamente");
      button = this.tranPipe.transform("disable_user_address_admin_button", "Disabilita");
    } else {
      title = this.tranPipe.transform("enable_user_address_admin_title", "Abilitare il seguente indirizzo?");
      subtitle = this.tranPipe.transform("enable_user_address_admin_subtitle", "Vuoi davvero abilitare il seguente indirizzo: <b>{0}</b>", [currAdd.addressFull ?? ""])
      message = this.tranPipe.transform("enable_user_address_admin_ok_response", "Indirizzo abilitato correttamente");
      button = this.tranPipe.transform("enable_user_address_admin_button", "Abilita");
    }

    this.modal.create({
      nzTitle: title,
      nzContent: subtitle,
      nzWidth: '600px',
      nzClassName: 'purple-simple-modal',
      nzOkText: button,
      nzOnOk: async () => await this.apiProxySvc.makeRequestErrorMessage<Boolean>(() => this.backUserSvc.enableDisableUserAddress(this.tsvc.currentLanguage.value, {
        userId: currAdd.userId!,
        addressId: currAdd.addressId!,
        isEnable: !currAdd.isEnable
      }), false, "internal-loader", 500, undefined, undefined, () => {
        this.mesSvc.success(message, {
          nzDuration: environment.MESSAGE_DURATION
        });
        this.refreshTableRowsNumber++;
      })

    });
  }

}
