<div class="login-container ps-d-flex ps-flex-wrap ps-align-items-center ps-justify-content-center">
  <form class="login-form" nz-form [formGroup]="validateForm" (ngSubmit)="submitForm()">
    <div class="ps-w-100 ps-d-flex ps-align-items-center ps-justify-content-center ps-pb-4">
      <img purpleEditableImage contentId="logo-backoffice-login" contentTypeId="logo" 
      [resizeWidthPx]="250" [resizeHeightPx]="250" [editAlwaysOn]="false"/>
    </div> 
    <nz-form-item>
      <nz-form-control [nzErrorTip]="'login_form_email_empty_error' | translation: 'Email richiesta!': []">
        <nz-input-group nzPrefixIcon="mail">
          <input type="email" nz-input formControlName="email" placeholder="Email" />
        </nz-input-group>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item>
      <nz-form-control [nzErrorTip]="'login_form_password_empty_error' | translation: 'Password richiesta!': []">
        <nz-input-group nzPrefixIcon="lock">
          <input type="password" nz-input formControlName="password"
            placeholder="Password" />
        </nz-input-group>
      </nz-form-control>
    </nz-form-item>
    <div class="ps-d-flex ps-flex-wrap ps-w-100 ps-align-items-center ps-justify-content-start">
      <div class="ps-col-12 ps-col-lg-6 ps-px-0">
        <label nz-checkbox formControlName="remember">
          <span>{{ 'login_form_remember' | translation: 'Remember me': []}}</span>
        </label>
      </div>
    </div>
    <button nz-button class="submit-button ps-mt-4 purple-button" nzType="primary">{{ 'login_form_login_button' | translation: 'Accedi': []}}</button>
  </form>
</div>
