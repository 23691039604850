<div class="ps-w-100">
  <div class="ps-w-100 ps-d-flex ps-align-items-center form-item-container ps-pt-0">
    <purple-select [scrollFunction]="rolesScrollFunction"
      [searchNameFunction]="rolesSearchFunction" [searchIdFunction]="rolesSearchByIdFunction"
      [currentItem]="roleId" (onValueChange)="roleId = $event"
      [placeholder]='"send_imported_users_email_roles_placeholder"|translation: "Seleziona uno o più ruoli"'>
    </purple-select>
  </div>
  <div class="purple-button-container ps-w-100 ps-d-flex">
    <button (click)="sendEmail()" nz-button class="purple-modal-button" [disabled]="isLoading"
      [nzLoading]="isLoading">
      {{ 'send_imported_users_email_send_button' | translation: 'Invia': []}}
    </button>
  </div>
</div>

