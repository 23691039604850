<container-element [ngSwitch]="isLimitEnable">
  <ng-container *ngSwitchCase="true">
    <nz-tabset class="cash-machine-setting-menu-tabset" nzTabPosition="left" [nzSelectedIndex]="tabIndex"
      (nzSelectedIndexChange)="onTabIndexChange($event)">
      <nz-tab [nzTitle]="'private_sale_cash_machine_settings_global_limits_tab' | translation: 'Limiti Globali': []">
        <nz-spin [nzSpinning]="isLoading" [nzIndicator]="purpleModalLoaderTemplate">
          <app-purple-form *ngIf="purpleFormFieldGroups!= undefined" [disbledForm]="disableForm"
            [(formGroup)]="validateForm" [purpleFormFieldGroups]="purpleFormFieldGroups">
          </app-purple-form>
        </nz-spin>
        <ng-template #purpleModalLoaderTemplate>
          <div class="cash-machine-custom-spinner">
            <span nz-icon nzType="loading"></span>
          </div>
        </ng-template>
      </nz-tab>
      <nz-tab [nzTitle]="'private_sale_cash_machine_settings_brand_limits_tab' | translation: 'Limiti per Brand': []">
        <purple-table *ngIf="tabIndex == 1" [tableBody]="tableBody" [isBordered]="true" [switchOn]="false"
          [refreshFunction]="brandLimitRefreshFunction" (tableData)="brandLimitListOfData = $event"
          [tableColumns]="brandLimitTabColumns" [tableScroll]="{y:dashSvc.dashboardHeight}"
          [refreshTableRowsNumber]="refreshBrandLimitTableRowsNumber" [version]="2" [showCustomPagination]="true">
          <ng-template #tableBody>
            <tr *ngFor="let data of brandLimitListOfData; let i = index"
              class="{{i%2==0?'main-purple-table-tr-even': 'main-purple-table-tr-odd'}} ">
              <td>{{ data.item.brandDescription }}</td>
              <td>{{getLimitLabel(data.item.isBrandLimitMaxQty, data.item.brandLimitMaxQty)}}</td>
              <td>{{getLimitLabel(data.item.isBrandLimitMaxAmount, data.item.brandLimitMaxAmount, true)}}</td>
              <td>
                <button (click)="editBrandLimit(data.item)"
                  [nzTooltipTitle]='"cash_desk_limits_brand_table_edit_limit" | translation: "Modifica Limite"'
                  nzTooltipPlacement="top" nz-tooltip class="ps-mr-3" nz-button nzType="default" nzShape="circle">
                  <i nz-icon class="fsp-18-0" nzType="edit"></i>
                </button>
                <button (click)="deleteBrandLimit(data.item)"
                  [nzTooltipTitle]='"cash_desk_limits_brand_table_delete_limit" | translation: "Elimina Limite"'
                  nzTooltipPlacement="top" nz-tooltip nz-button nzType="default" nzShape="circle">
                  <i nz-icon class="fsp-18-0" nzType="delete"></i>
                </button>
              </td>
            </tr>
          </ng-template>
        </purple-table>
      </nz-tab>
      <nz-tab
        [nzTitle]="'private_sale_cash_machine_settings_category_limits_tab' | translation: 'Limiti per Categorie': []">
        <purple-table *ngIf="tabIndex == 2" [tableBody]="tableBody" [isBordered]="true" [switchOn]="false"
          [refreshFunction]="categoryLimitRefreshFunction" (tableData)="categoryLimitListOfData = $event"
          [tableColumns]="categoryLimitTabColumns" [tableScroll]="{y:dashSvc.dashboardHeight}"
          [refreshTableRowsNumber]="refreshCategoryLimitTableRowsNumber" [version]="2" [showCustomPagination]="true">
          <ng-template #tableBody>
            <tr *ngFor="let data of categoryLimitListOfData; let i = index"
              class="{{i%2==0?'main-purple-table-tr-even': 'main-purple-table-tr-odd'}} ">
              <td>{{ data.item.saleCategory }}</td>
              <td>{{getLimitLabel(data.item.isCategoryLimitMaxQty, data.item.categoryLimitMaxQty)}}</td>
              <td>{{getLimitLabel(data.item.isCategoryLimitMaxAmount, data.item.categoryLimitMaxAmount, true)}}</td>
              <td>
                <button (click)="editCategoryLimit(data.item)"
                  [nzTooltipTitle]='"cash_desk_limits_category_table_edit_limit" | translation: "Modifica Limite"'
                  nzTooltipPlacement="top" nz-tooltip class="ps-mr-3" nz-button nzType="default" nzShape="circle">
                  <i nz-icon class="fsp-18-0" nzType="edit"></i>
                </button>
                <button (click)="deleteCategoryLimit(data.item)"
                  [nzTooltipTitle]='"cash_desk_limits_category_table_delete_limit" | translation: "Elimina Limite"'
                  nzTooltipPlacement="top" nz-tooltip nz-button nzType="default" nzShape="circle">
                  <i nz-icon class="fsp-18-0" nzType="delete"></i>
                </button>
              </td>
            </tr>
          </ng-template>
        </purple-table>
      </nz-tab>
      <nz-tab
        [nzTitle]="'private_sale_cash_machine_settings_exception_category_limits_tab' | translation: 'Eccezioni': []">
        <purple-table *ngIf="tabIndex == 3" [tableBody]="tableBody" [isBordered]="true" [switchOn]="false"
          [refreshFunction]="categoryExceptionLimitRefreshFunction"
          (tableData)="categoryExceptionLimitListOfData = $event" [tableColumns]="categoryExceptionLimitTabColumns"
          [tableScroll]="{y:dashSvc.dashboardHeight}"
          [refreshTableRowsNumber]="refreshcategoryExceptionLimitTableRowsNumber" [version]="2"
          [showCustomPagination]="true">
          <ng-template #tableBody>
            <tr *ngFor="let data of categoryExceptionLimitListOfData; let i = index"
              class="{{i%2==0?'main-purple-table-tr-even': 'main-purple-table-tr-odd'}} ">
              <td>{{ data.item.saleCategory }}</td>
              <td nzAlign="center" class="fw-b">{{getLimitStatusLabel(data.item.skipCount)}}</td>
              <td nzAlign="center" class="fw-b">{{getLimitStatusLabel(data.item.skipAmount)}}</td>
              <td>
                <button (click)="editCategoryExceptionLimit(data.item)"
                  [nzTooltipTitle]='"cash_desk_limits_category_table_edit_limit" | translation: "Modifica Limite"'
                  nzTooltipPlacement="top" nz-tooltip class="ps-mr-3" nz-button nzType="default" nzShape="circle">
                  <i nz-icon class="fsp-18-0" nzType="edit"></i>
                </button>
                <button (click)="deleteCategoryExceptionLimit(data.item)"
                  [nzTooltipTitle]='"cash_desk_limits_category_table_delete_limit" | translation: "Elimina Limite"'
                  nzTooltipPlacement="top" nz-tooltip nz-button nzType="default" nzShape="circle">
                  <i nz-icon class="fsp-18-0" nzType="delete"></i>
                </button>
              </td>
            </tr>
          </ng-template>
        </purple-table>
      </nz-tab>
    </nz-tabset>
  </ng-container>
  <ng-container *ngSwitchCase="false">
    <div class="ps-w-100 ps-d-flex ps-flex-wrap ps-justify-content-center fsp-16-0 ps-mt-3">
      <div
        [innerHTML]="'cash_machine_limits_are_disabled_exception' | translation: 'Limiti cassa \<b>DISABILITATI\</b>': []">
      </div>
      <div class="ps-w-100 ps-d-flex ps-flex-wrap ps-justify-content-center ps-pt-2">
        <button nz-button nzType="primary" (click)="enableDisableCashMachineLimits(true)">
          {{'enable_cash_machine_limits' | translation: 'Abilita Limiti'}}
        </button>
      </div>
    </div>
  </ng-container>
</container-element>