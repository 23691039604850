import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalRef, NZ_MODAL_DATA } from 'ng-zorro-antd/modal';
import { Helper, PurpleSelectFilter, PurpleTranslationPipe } from 'purple-lib';
import { purpleFormFieldsGroup } from 'purple-lib';
import { AppTranslationService } from 'src/core-modules/localization/localization.service';
import { BackOfficeService, BookingService, PRPVUserAddress } from 'src/core-modules/sdk/api';
import { environment } from 'src/environments/default/environment';
import { PurpleApiMakeRequestResponse, PurpleApiProxyService } from 'purple-lib';


@Component({
  selector: 'app-edit-user-address-detail',
  templateUrl: './edit-user-address-detail.component.html',
  styleUrls: ['./edit-user-address-detail.component.scss']
})
export class EditUserAddressDetailComponent implements OnInit {

  constructor(@Inject(NZ_MODAL_DATA) public data: { userAddressFull: PRPVUserAddress | undefined, userId: string }, private bookSvc: BookingService, private modalRef: NzModalRef, private fb: FormBuilder, private tranPipe: PurpleTranslationPipe,
    private mesSvc: NzMessageService, private backAddSvc: BackOfficeService, private tsvc: AppTranslationService, private apiProxySvc: PurpleApiProxyService) { }

  userAddress!: editUserAdderessItem;
  newObj!: editUserAdderessItem;
  isNew: boolean = false;
  validateForm: FormGroup = this.fb.group({});
  purpleFormFieldGroups: purpleFormFieldsGroup[] | undefined;
  disableForm: boolean = false;
  isLoading: boolean = false;

  async ngOnInit() {
    this.userAddress = {
      externalAddressId: this.data.userAddressFull?.addressExternalId ?? undefined,
      intercom: this.data.userAddressFull?.intercom ?? undefined,
      label: this.data.userAddressFull?.addressLabel ?? undefined,
      notes: this.data.userAddressFull?.notes ?? undefined,
    }

    this.isNew = this.data.userAddressFull == undefined;
    this.newObj = Helper.storeNewObj(this.userAddress)

    this.purpleFormFieldGroups = [
      {
        fieldGroupNumber: 1,
        fielGroupBootstrapColumn: 6,
        fieldGroupPaddingLeft: false,
        fieldGroupPaddingRight: true,
        formFieldFormGroup: this.validateForm,
        formFields: [
          {
            fieldType: 'select',
            fieldControlName: 'address',
            fieldBootstrapColumn: 12,
            fieldName: this.tranPipe.transform('edit_user_address_address', 'Indirizzo', []),
            fieldPlaceholder: this.tranPipe.transform('edit_user_address_address_placeholder', 'Indirizzo', []),
            fieldValue: this.newObj.externalAddressId,
            fieldIsRequired: true,
            fieldIsDisabled: !this.isNew,
            fieldPaddingLeft: false,
            fieldPaddingRight: false,
            fieldOptions: [
              { id: 'searchFunction', value: this.addressSearchFunction },
              { id: 'searchByIdFunction', value: this.addressSearchIdFunction },
              { id: 'refreshFieldNumber', value: 0 },
            ]
          },
          {
            fieldType: 'input-text',
            fieldControlName: 'label',
            fieldBootstrapColumn: 12,
            fieldName: this.tranPipe.transform('edit_user_address_label', 'Etichetta', []),
            fieldPlaceholder: this.tranPipe.transform('edit_user_address_label_placeholder', 'Etichetta indirizzo', []),
            fieldValue: this.newObj.label,
            fieldIsRequired: true,
            fieldPaddingLeft: false,
            fieldPaddingRight: false
          },
          {
            fieldType: 'input-text',
            fieldControlName: 'intercom',
            fieldBootstrapColumn: 12,
            fieldName: this.tranPipe.transform('edit_user_address_intercom', 'Citofono', []),
            fieldPlaceholder: this.tranPipe.transform('edit_user_address_intercom_placeholder', 'Citofono indirizzo', []),
            fieldValue: this.newObj.intercom,
            fieldIsRequired: false,
            fieldPaddingLeft: false,
            fieldPaddingRight: false
          }
        ]
      },
      {
        fieldGroupNumber: 2,
        fielGroupBootstrapColumn: 6,
        fieldGroupPaddingLeft: true,
        fieldGroupPaddingRight: false,
        formFieldFormGroup: this.validateForm,
        formFields: [
          {
            fieldType: 'textarea',
            fieldControlName: 'notes',
            fieldBootstrapColumn: 12,
            fieldName: this.tranPipe.transform('edit_user_address_note', 'Note', []),
            fieldPlaceholder: this.tranPipe.transform('edit_user_address_note_placeholder', 'Note per la consegna', []),
            fieldValue: this.newObj.notes,
            fieldIsRequired: false,
            fieldOptions: [{ id: 'rowNumber', value: 8 }],
            fieldPaddingLeft: false,
            fieldPaddingRight: false
          }
        ]
      }
    ]
  }

  async editUserAddress() {
    this.isLoading = true;

    for (const i in this.validateForm.controls) {
      this.validateForm.controls[i].markAsDirty();
      this.validateForm.controls[i].updateValueAndValidity();
    }

    if (this.validateForm.valid) {
      this.newObj.intercom = this.validateForm.controls["intercom"].value;
      this.newObj.label = this.validateForm.controls["label"].value;
      this.newObj.notes = this.validateForm.controls["notes"].value;
      this.newObj.externalAddressId = this.validateForm.controls["address"].value;

      if (Helper.checkIfDoChanges(this.newObj, this.userAddress)) {
        await this.apiProxySvc.makeRequestErrorMessage<Boolean>(() => this.backAddSvc.addOrUpdateUserAddress(this.tsvc.currentLanguage.value, {
          isNew: this.isNew,
          externalAddressId: this.newObj.externalAddressId!,
          label: this.newObj.label!,
          intercom: this.newObj.intercom,
          notes: this.newObj.notes,
          userId: this.data.userId
        }), false, "internal-loader", 500, undefined, undefined, (res: PurpleApiMakeRequestResponse<Boolean>) => {
          let text: string;
          if (this.isNew) {
            text = this.tranPipe.transform('edit_user_address', 'Indirizzo aggiunto con successo', [])
          } else {
            text = this.tranPipe.transform('edit_user_address', 'Indirizzo aggiornato con successo', [])
          }
          this.mesSvc.success(text, {
            nzDuration: environment.MESSAGE_DURATION
          });
          this.isLoading = false;
          this.modalRef.destroy(res.data);
        })
      } else {
        this.mesSvc.warning(this.tranPipe.transform('edit_no_changes', 'Nessuna modifica effettuata', []), {
          nzDuration: environment.MESSAGE_DURATION,
        });
      }
    }

    this.isLoading = false
  }

  //#region Address
  addressSearchFunction = (args: { pageNumber: number, pageSize: number, culture: string, searchName: string, searchFilters: PurpleSelectFilter[] }) => {
    return this.bookSvc.getAddressByNameSelect(args.culture, args)
  }

  addressSearchIdFunction = (args: { pageNumber: number, pageSize: number, culture: string, searchName: string, searchFilters: PurpleSelectFilter[] }) => {
    return this.bookSvc.getAddressByIdSelect(args.culture, args)
  }
  //#endregion

}

interface editUserAdderessItem {
  externalAddressId: string | undefined;
  label: string | undefined;
  notes: string | undefined;
  intercom: string | undefined;
}
