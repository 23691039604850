<div class="summary-stats-item-container">
  <div class="ps-d-flex ps-flex-wrap ps-w-100">
    <h2 class="ps-w-100 summary-stats-item-title">
      {{title}}
    </h2>
    <div class="ps-d-flex ps-align-items-start summary-stats-item-value-container ">
      <div class="ps-pl-0 {{showSecondaryValue?'r-space':''}}">
        <p class="summary-stats-item-main-value-intro">
          {{mainValueTitle}}
        </p>
        <p class="ps-w-100 summary-stats-item-main-value">
         {{mainValue}}
        </p>
      </div>
      <div class="ps-pl-0" *ngIf="showSecondaryValue">
        <p class="summary-stats-item-main-value-intro">
          {{secondaryValueTitle}}
        </p>
        <p [style.color]="valueCustomColor" class="ps-w-100 summary-stats-item-main-value-secondary">
          {{secondaryValue}}
        </p>
      </div>
    </div>
    <div class="ps-w-100 ps-d-flex">
      <p class="summary-stats-item-main-value-sub">
        <span [style.color]="valueCustomColor">
          <i *ngIf="otherValueIcon!= undefined" nz-icon [nzType]="otherValueIcon"></i> 
          {{otherValue}}
        </span> {{otherValueText}}
      </p>
    </div>
    
  </div>
  <div *ngIf="imageUrl!= undefined" [style.background-image]="'url('+imageUrl+')'" class="summary-stats-item-back-img"></div>
</div>