import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { PrivateSalesRoutingModule } from './private-sales-routing.module';
import { PrivateSalesListComponent } from './private-sales-list/private-sales-list.component';
import { PurpleFormModule, PurpleGeneralDirectiveModule, PurplePipeModule, PurpleTableModule } from 'purple-lib';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzTagModule } from 'ng-zorro-antd/tag';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzNotificationModule } from 'ng-zorro-antd/notification';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { AddSaleEventFastModalComponent } from './modals/add-sale-event-fast-modal/add-sale-event-fast.component-modal';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    PrivateSalesRoutingModule,
    PurpleTableModule,
    NzIconModule,
    PurplePipeModule,
    NzTagModule,
    NzToolTipModule,
    NzSpinModule,
    PurpleFormModule,
    NzButtonModule,
    NzNotificationModule,
    NzDatePickerModule,
    NzSelectModule,
    PurpleGeneralDirectiveModule
  ],
  declarations: [PrivateSalesListComponent, AddSaleEventFastModalComponent],
  providers:[DatePipe]
})
export class PrivateSalesModule { }
