import { getLocaleDateFormat, FormatWidth } from '@angular/common';
import { Component, Inject, Input, LOCALE_ID, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { purpleFormFieldsGroup, PurpleTranslationPipe, PurpleApiProxyService, Helper, PurpleApiMakeRequestResponse, PurpleSelectFilter } from 'purple-lib';
import { AppTranslationService } from 'src/core-modules/localization/localization.service';
import { BackOfficeService } from 'src/core-modules/sdk/api';
import { environment } from 'src/environments/default/environment';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalRef, NZ_MODAL_DATA } from 'ng-zorro-antd/modal';


@Component({
  selector: 'app-add-user-role-modal',
  templateUrl: './add-user-role-modal.component.html',
  styleUrls: ['./add-user-role-modal.component.scss']
})
export class AddUserRoleModalComponent implements OnInit {
  
  userId: string | undefined;

  validateForm: FormGroup = this.fb.group({});
  purpleFormFieldGroups: purpleFormFieldsGroup[] | undefined;
  disableForm: boolean = false;
  isLoading: boolean = false;

  dateFormat = getLocaleDateFormat(this.locale, FormatWidth.Medium);
  staffDate: Date[] | null = null;
  publicDate: Date[] | null = null;

  constructor(@Inject(NZ_MODAL_DATA) private data: { saleEventId: string, roleId: string }, @Inject(LOCALE_ID) private locale: string, private modalRef: NzModalRef, private fb: FormBuilder, private tranPipe: PurpleTranslationPipe, private apiProxySvc: PurpleApiProxyService,
    private mesSvc: NzMessageService, private backSvc: BackOfficeService, private tsvc: AppTranslationService) { }


  async ngOnInit(): Promise<void> {
    this.purpleFormFieldGroups = [
      {
        fieldGroupNumber: 1,
        fielGroupBootstrapColumn: 12,
        fieldGroupPaddingLeft: false,
        fieldGroupPaddingRight: false,
        formFieldFormGroup: this.validateForm,
        formFields: [
          {
            fieldType: 'select',
            fieldControlName: 'user',
            fieldBootstrapColumn: 12,
            fieldName: this.tranPipe.transform('edit_employee_user', 'Utente', []),
            fieldPlaceholder: this.tranPipe.transform('edit_employee_user_placeholder', 'Utente', []),

            fieldCustomClass: undefined,
            fieldOptions: [
              { id: 'scrollFunction', value: this.usersScrollFunction },
              { id: 'searchFunction', value: this.usersSearchFunction },
              { id: 'searchByIdFunction', value: this.usersSearchByIdFunction },
              { id: 'refreshFieldNumber', value: 0 }

            ],
            fieldValue: this.userId,
            fieldIsRequired: true,
            fieldIsDisabled: false,
            fieldPaddingLeft: false,
            fieldPaddingRight: false
          },
          {
            fieldType: 'select',
            fieldControlName: 'role',
            fieldBootstrapColumn: 12,
            fieldName: this.tranPipe.transform('edit_employee_roles', 'Ruoli', []),
            fieldPlaceholder: this.tranPipe.transform('edit_employee_roles_placeholder', 'Seleziona i ruoli che vuoi assegnare', []),
            fieldCustomClass: undefined,
            fieldOptions: [
              { id: 'scrollFunction', value: this.rolesScrollFunction },
              { id: 'searchFunction', value: this.rolesSearchFunction },
              { id: 'searchByIdFunction', value: this.rolesSearchByIdFunction },
              { id: 'refreshFieldNumber', value: 0 }
            ],
            fieldValue: this.data.roleId,
            fieldIsRequired: true,
            fieldIsDisabled: true,
            fieldPaddingLeft: false,
            fieldPaddingRight: false
          }
        ]
      }
    ]
  }

  async addSale() {
    this.isLoading = true;
    for (const i in this.validateForm.controls) {
      this.validateForm.controls[i].markAsDirty();
      this.validateForm.controls[i].updateValueAndValidity();
    }

    if (this.validateForm.valid) {
      await this.apiProxySvc.makeRequestErrorMessage<Boolean>(() => this.backSvc.addOrUpdateSaleEventUserRole(this.tsvc.translationLanguage.value, {
        roleId: this.data.roleId,
        saleEventId: this.data.saleEventId,
        userId: this.validateForm.controls["user"].value
      }), false, "internal-loader", 500, undefined, undefined, (res: PurpleApiMakeRequestResponse<Boolean>) => {
        let text: string;
        this.mesSvc.success(this.tranPipe.transform('save_changes_ok', 'Modifiche salvate con successo', []), {
          nzDuration: environment.MESSAGE_DURATION
        });
        this.modalRef.destroy(res.data);
      })
    }
    this.isLoading = false
  }




  //ScrollFunction
  rolesScrollFunction = (args: { pageNumber: number, pageSize: number, culture: string, searchName: string, searchFilters: PurpleSelectFilter[] }) => {
    args.searchFilters = [
      { propertyName: "roleType", filterValues: ["notStaff"] }
    ]
    return this.backSvc.getRolesSelect(args.culture, args)
  }
  //SearchFunction
  rolesSearchFunction = (args: { pageNumber: number, pageSize: number, culture: string, searchName: string, searchFilters: PurpleSelectFilter[] }) => {
    args.searchFilters = [
      { propertyName: "roleType", filterValues: ["notStaff"] }
    ]
    return this.backSvc.getRolesByNameSelect(args.culture, args)
  }
  //InitFunction (SearchByIdFunction)
  rolesSearchByIdFunction = (args: { pageNumber: number, pageSize: number, culture: string, searchName: string, searchFilters: PurpleSelectFilter[] }) => {
    return this.backSvc.getRolesByIdSelect(args.culture, args)
  }

  //ScrollFunction
  usersScrollFunction = (args: { pageNumber: number, pageSize: number, culture: string, searchName: string, searchFilters: PurpleSelectFilter[] }) => {
    return this.backSvc.getUsersSelect(args.culture, args)
  }
  //SearchFunction
  usersSearchFunction = (args: { pageNumber: number, pageSize: number, culture: string, searchName: string, searchFilters: PurpleSelectFilter[] }) => {
    return this.backSvc.getUsersByNameSelect(args.culture, args)
  }
  //InitFunction (SearchByIdFunction)
  usersSearchByIdFunction = (args: { pageNumber: number, pageSize: number, culture: string, searchName: string, searchFilters: PurpleSelectFilter[] }) => {
    return this.backSvc.getUsersByIdSelect(args.culture, args)
  }

}
