<ng-container *ngIf="dashSvc.dashboardHeight != undefined">

  <purple-table #purpleTable [tableBody]="tableBody" [isBordered]="true" [switchOn]="false"
    [refreshFunction]="refreshFunction" (tableData)="listOfData = $event" [tableColumns]="tabColumns"
    [tableScroll]="{y:dashSvc.dashboardHeight}" [refreshTableRowsNumber]="refreshTableRowsNumber" [version]="2" 
    [showCustomPagination]="true" tableSearchType="box" (filterBox)="dashSvc.setPurpleTablePopover($event)"
    (onSearchBoxEnd)="dashSvc.closePurpleTablePopover()" [filterBoxStatus]="dashSvc.purpleTableBoxFilterStatus">


    <ng-template #tableBody>
      <ng-container *ngFor="let data of listOfData; let i = index">
        <tr class="{{i%2==0?'main-purple-table-tr-even': 'main-purple-table-tr-odd'}} ">
          <td nzAlign="center"><span class="{{data.item.languageId | languageIcon}}"></span></td>
          <td nzAlign="center">
            <container-element [ngSwitch]="data.item.isTranslated">
              <ng-container *ngSwitchCase="true">
                <span nz-icon nzType="check-circle" [nzTheme]="'twotone'" [nzTwotoneColor]="'#52c41a'"></span>
              </ng-container>
              <ng-container *ngSwitchCase="false">
                <span nz-icon nzType="close-circle" [nzTheme]="'twotone'" [nzTwotoneColor]="'#eb2f96'"></span>
              </ng-container>
            </container-element>
          </td>
          <td>{{ data.item.translationId}}</td>
          <td>{{ data.item.translationValue }}</td>
          
          <td nzAlign="center">
            <button (click)="editTranslation(data.item!)" 
            [nzTooltipTitle]="'translation_table_edit_translation_tooltip' | translation: 'Modifica traduzione': []"
            
            nzTooltipPlacement="top" nz-tooltip class="ps-mr-3" nz-button nzType="default" nzShape="circle"><i nz-icon
              class="fsp-18-0" nzType="edit"></i></button>
              <button (click)="deleteTranslation(data.item!)" 
              [nzTooltipTitle]="'translation_table_remove_translation_tooltip' | translation: 'Elimina traduzione': []"
            nzTooltipPlacement="top" nz-tooltip  nz-button nzType="default" nzShape="circle"><i nz-icon
              class="fsp-18-0" nzType="delete"></i></button>
          </td>
        </tr>
      </ng-container>
    </ng-template>
  </purple-table>
</ng-container>