<nz-spin [nzSpinning]="isLoading" [nzIndicator]="purpleModalLoaderTemplate">
  <app-purple-form *ngIf="purpleFormFieldGroups!= undefined" [disbledForm]="disableForm" [(formGroup)]="validateForm"
    [purpleFormFieldGroups]="purpleFormFieldGroups">
  </app-purple-form>
</nz-spin>
<ng-template #purpleModalLoaderTemplate>
  <div class="purple-modal-custom-spinner">
    <span nz-icon nzType="loading"></span>
  </div>
</ng-template>
<div class="purple-button-container ps-w-100 ps-d-flex">
  <button (click)="editCategoryExceptionLimit()" nz-button class="purple-modal-button" [disabled]="isLoading"
    [nzLoading]="isLoading">
    {{ 'edit_modal_save_button' | translation: 'Salva {0}': ['modifiche']}}
  </button>
</div>