<nz-spin [nzSpinning]="isLoading" [nzIndicator]="purpleModalLoaderTemplate">
  <form nz-form [formGroup]="validateForm" class="ps-d-flex ps-w-100 ps-flex-wrap">
    <div class="edit-item-container ps-col-12 ps-px-0">
      <p class="edit-item-field-name">{{ 'add_new_mail_template_exception_syatem_event' | translation: 'Mail template':
        []}}:<sup>*</sup></p>
      <nz-form-item class="edit-item-from-item">
        <nz-form-control [nzErrorTip]="'input_error_required' | translation: 'Campo obbligatorio': []">
          <div class="ps-w-100 ps-d-flex ps-flex-wrap ps-align-items-center">
            <purple-select [scrollFunction]="systemEventScrollFunction" formControlName="systemEventId" ngDefaultControl
              [searchIdFunction]="systemEventSearchByIdFunction" [searchNameFunction]="systemEventSearchFunction"
              [currentItem]="newObj.systemEventId" (onValueChange)="onSelectSystemEvent($event)"
              [placeholder]='"edit_mail_smtp_placeholder"|translation: "Seleziona una configurazione smtp"'
              class="drawer-row-input">
            </purple-select>
          </div>
        </nz-form-control>
      </nz-form-item>
    </div>
    <ng-container class="ps-w-100 ps-d-flex" *ngIf="(currentSystemEventExtras?.extraPk1??undefined) != undefined">
      <container-element class="ps-w-100 ps-d-flex" [ngSwitch]="currentSystemEventExtras!.extraPk1.tableName">
        <ng-container *ngSwitchCase="'Roles'">
          <div class="edit-item-container ps-col-12 ps-px-0">
            <p class="edit-item-field-name">{{ 'add_new_mail_template_exception_role' | translation: 'Ruolo':
              []}}:<sup>*</sup></p>
            <nz-form-item class="edit-item-from-item">
              <nz-form-control [nzErrorTip]="'input_error_required' | translation: 'Campo obbligatorio': []">
                <div class="ps-w-100 ps-d-flex ps-flex-wrap ps-align-items-center">
                  <container-element class="ps-w-100 ps-d-flex"
                    [ngSwitch]="currentSystemEventExtras!.extraPk1.angularAdminUiType">
                    <ng-container *ngSwitchCase="'select'">
                      <purple-select [scrollFunction]="rolesScrollFunction" formControlName="extraPk1" ngDefaultControl
                        [searchIdFunction]="rolesSearchByIdFunction" [searchNameFunction]="rolesSearchFunction"
                        [currentItem]="newObj.extraPk1" (onValueChange)="newObj.extraPk1 = $event"
                        [placeholder]='"add_new_mail_template_exception_role_placeholder"|translation: "Seleziona un ruolo"'
                        class="drawer-row-input">
                      </purple-select>
                    </ng-container>
                  </container-element>
                </div>
              </nz-form-control>
            </nz-form-item>
          </div>
        </ng-container>
      </container-element>
    </ng-container>
  </form>
</nz-spin>
<ng-template #purpleModalLoaderTemplate>
  <div class="purple-modal-custom-spinner">
    <span nz-icon nzType="loading"></span>
  </div>
</ng-template>
<div class="purple-button-container ps-w-100 ps-d-flex">
  <button (click)="addNewMailTemplateException()" nz-button class="purple-modal-button" [disabled]="isLoading"
    [nzLoading]="isLoading">
    {{ 'add_new_mail_template_exception_save_button' | translation: 'Salva': []}}
  </button>
</div>