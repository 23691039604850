import { Component, Inject, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalRef, NZ_MODAL_DATA } from 'ng-zorro-antd/modal';
import { PurpleApiMakeRequestResponse, PurpleApiProxyService, PurpleSelectFilter } from 'purple-lib';
import { AppTranslationService } from 'src/core-modules/localization/localization.service';

import { AddSystemEventSaleEventMailTemplateExceptionRequest, BackOfficeService, GetSaleEventSystemEventExceptionsResponse, SystemEventExtras } from 'src/core-modules/sdk/api';
import { environment } from 'src/environments/default/environment';

@Component({
  selector: 'app-add-mail-template-exception-fast',
  templateUrl: './add-mail-template-exception-fast.component.html',
  styleUrls: ['./add-mail-template-exception-fast.component.scss']
})
export class AddMailTemplateExceptionFastComponent implements OnInit {

  constructor(@Inject(NZ_MODAL_DATA) private data: {alreadyPresentSystemEventExceptions: GetSaleEventSystemEventExceptionsResponse[], saleEventId: string }, private admSvc: BackOfficeService, private fb: FormBuilder, private tsvc: AppTranslationService, private mesSvc: NzMessageService,
    private modalRef: NzModalRef, private apiProxySvc: PurpleApiProxyService) { }
  
  newObj!: SystemEventMailTemplateExceptionFastItem;
  validateForm: FormGroup = new FormGroup({});
  //systemEventsToExclude: string | undefined;
  rolesToExclude: string | undefined;
  isLoading: boolean = false;
  currentSystemEventExtras: SystemEventExtras | undefined;

  ngOnInit() {
    console.log("saleEventId: ", this.data.saleEventId)
    this.newObj = {
      systemEventId: undefined
    }

    this.validateForm = this.fb.group({
      systemEventId: [{ value: this.newObj.systemEventId, disabled: false }, [Validators.required]],
      extraPk1: [{ value: this.newObj.extraPk1, disabled: false }, [Validators.required]],
      extraPk2: [{ value: this.newObj.extraPk2 }],
      extraPk3: [{ value: this.newObj.extraPk3 }],
      extraPk4: [{ value: this.newObj.extraPk4 }],
    });
  }

  async addNewMailTemplateException() {
    this.isLoading = true;

    this.validateForm.controls["systemEventId"].setValue(this.newObj.systemEventId);
    this.validateForm.controls["extraPk1"].setValue(this.newObj.extraPk1);
    this.validateForm.controls["extraPk2"].setValue(this.newObj.extraPk2);
    this.validateForm.controls["extraPk3"].setValue(this.newObj.extraPk3);
    this.validateForm.controls["extraPk4"].setValue(this.newObj.extraPk4);

    for (const i in this.validateForm.controls) {
      this.validateForm.controls[i].markAsDirty();
      this.validateForm.controls[i].updateValueAndValidity();
    }

    if (this.validateForm.valid) {
      var request: AddSystemEventSaleEventMailTemplateExceptionRequest = {
        saleEventId: this.data.saleEventId,
        extraPk1: this.validateForm.controls["extraPk1"].value,
        extraPk2: this.validateForm.controls["extraPk2"].value,
        extraPk3: this.validateForm.controls["extraPk3"].value,
        extraPk4: this.validateForm.controls["extraPk4"].value,
        systemEventId: this.validateForm.controls["systemEventId"].value
      }

      await this.apiProxySvc.makeRequestErrorMessage<boolean>(() => this.admSvc.addSystemEventSaleEventMailTemplateException(this.tsvc.translationLanguage.value,
        request), false, "internal-loader", 500, undefined, undefined, (res: PurpleApiMakeRequestResponse<boolean>) => {
          this.mesSvc.success('Aggiornato con successo', {
            nzDuration: environment.MESSAGE_DURATION
          });
          this.modalRef.close(true);
        })

      this.isLoading = false;
    }

  }

  async onSelectSystemEvent(systemEventId: string) {
    this.currentSystemEventExtras = undefined;
    this.newObj.systemEventId = systemEventId;

    await this.apiProxySvc.makeRequestErrorMessage<SystemEventExtras>(() => this.admSvc.getSystemEventExceptionExtraKeys(this.tsvc.translationLanguage.value, {
      systemEventId: systemEventId
    }), false, "internal-loader", 500, undefined, undefined, (res: PurpleApiMakeRequestResponse<SystemEventExtras>) => {
      this.currentSystemEventExtras = res.data;

      if (res.data?.extraPk1.tableName.strEq("roles")) {
        const extraPk1s: string[] = this.data.alreadyPresentSystemEventExceptions.filter(f => f.systemEvent.systemEventId.strEq(systemEventId) && (f.extraPk1 != null || f.extraPk1 != undefined)).map(m => m.extraPk1?.extraId ?? "");

        if (extraPk1s.length > 0) {
          this.rolesToExclude = [...new Set(extraPk1s)].join(",")
        }
      }
    })
  }


  //ScrollFunction
  systemEventScrollFunction = (args: { pageNumber: number, pageSize: number, culture: string, searchName: string, searchFilters: PurpleSelectFilter[] }) => {
    args.searchFilters = [
      { propertyName: "showOnlyExceptions", filterValues: ["true"] },
    ]

    return this.admSvc.getSystemEventsSelect(args.culture, args)
  }
  //SearchFunction
  systemEventSearchFunction = (args: { pageNumber: number, pageSize: number, culture: string, searchName: string, searchFilters: PurpleSelectFilter[] }) => {
    args.searchFilters = [
      { propertyName: "showOnlyExceptions", filterValues: ["true"] },
    ]

    return this.admSvc.getSystemEventsByNameSelect(args.culture, args)
  }
  //InitFunction (SearchByIdFunction)
  systemEventSearchByIdFunction = (args: { pageNumber: number, pageSize: number, culture: string, searchName: string, searchFilters: PurpleSelectFilter[] }) => {
    return this.admSvc.getSystemEventsByIdSelect(args.culture, args)
  }

  //ScrollFunction
  rolesScrollFunction = (args: { pageNumber: number, pageSize: number, culture: string, searchName: string, searchFilters: PurpleSelectFilter[] }) => {
    args.searchFilters = [
      { propertyName: "roleType", filterValues: ["notStaff"] }
    ]

    if (this.rolesToExclude != undefined) {
      args.searchFilters.push({ propertyName: "rolesToExclude", filterValues: [this.rolesToExclude] })
    }

    return this.admSvc.getRolesSelect(args.culture, args)
  }
  //SearchFunction
  rolesSearchFunction = (args: { pageNumber: number, pageSize: number, culture: string, searchName: string, searchFilters: PurpleSelectFilter[] }) => {
    args.searchFilters = [
      { propertyName: "roleType", filterValues: ["notStaff"] }
    ]

    if (this.rolesToExclude != undefined) {
      args.searchFilters.push({ propertyName: "rolesToExclude", filterValues: [this.rolesToExclude] })
    }

    return this.admSvc.getRolesByNameSelect(args.culture, args)
  }
  //InitFunction (SearchByIdFunction)
  rolesSearchByIdFunction = (args: { pageNumber: number, pageSize: number, culture: string, searchName: string, searchFilters: PurpleSelectFilter[] }) => {
    return this.admSvc.getRolesByIdSelect(args.culture, args)
  }
}

interface SystemEventMailTemplateExceptionFastItem {
  systemEventId: string | undefined;
  extraPk1?: string | undefined;
  extraPk2?: string | undefined;
  extraPk3?: string | undefined;
  extraPk4?: string | undefined;
}