import { Component, Inject, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalRef, NZ_MODAL_DATA } from 'ng-zorro-antd/modal';
import { PurpleTranslationPipe, Helper } from 'purple-lib';
import { purpleFormFieldsGroup } from 'purple-lib';
import { AppTranslationService } from 'src/core-modules/localization/localization.service';
import { LocalizationService, PRPVTranslationFull } from 'src/core-modules/sdk/api';
import { environment } from 'src/environments/default/environment';
import { PurpleApiProxyService } from 'purple-lib';

@Component({
  selector: 'app-edit-translation',
  templateUrl: './edit-translation.component.html',
  styleUrls: ['./edit-translation.component.scss']
})
export class EditTranslationComponent implements OnInit {

  newObj!: PRPVTranslationFull;
  isNew: boolean = false;
  validateForm: FormGroup = this.fb.group({});
  purpleFormFieldGroups: purpleFormFieldsGroup[] | undefined;
  disableForm: boolean = false;
  isLoading: boolean = false;

  constructor(@Inject(NZ_MODAL_DATA) private translation: PRPVTranslationFull | undefined,private modalRef: NzModalRef, private mesSvc: NzMessageService, private apiProxySvc: PurpleApiProxyService,
    private locSvc: LocalizationService, private tsvc: AppTranslationService, private fb: FormBuilder, private tranPipe: PurpleTranslationPipe) { }

  async ngOnInit(): Promise<void> {
    if (this.translation == undefined) {
      this.translation = {
        translationId: "",
        translationValue: "",
        isTranslated: false,
        languageId: this.tsvc.currentLanguage.value,
        lastUpdate: ""
      }

      this.isNew = true;
    }
    this.newObj = Helper.storeNewObj(this.translation);

    this.purpleFormFieldGroups = [
      {
        fieldGroupNumber: 1,
        fielGroupBootstrapColumn: 12,
        fieldGroupPaddingLeft: false,
        fieldGroupPaddingRight: false,
        formFieldFormGroup: this.validateForm,
        formFields: [
          {
            fieldType: 'input-text',
            fieldControlName: 'key',
            fieldBootstrapColumn: 12,
            fieldName: this.tranPipe.transform('edit_translation_name', 'Chiave', []),
            fieldPlaceholder: this.tranPipe.transform('edit_translation_name_placeholder', 'Chiave traduzione', []),

            fieldCustomClass: undefined,
            fieldOptions: undefined,
            fieldValue: this.translation.translationId,
            fieldVerticalSpace: undefined,
            fieldIsRequired: true,
            fieldCustomValidators: undefined,
            fieldIsDisabled: !this.isNew,
            fieldPaddingLeft: false,
            fieldPaddingRight: false
          },
          {
          fieldType: 'textarea',
          fieldControlName: 'value',
          fieldBootstrapColumn: 12,
          fieldName: this.tranPipe.transform('edit_translation_value', 'Traduzione', []),
            fieldPlaceholder: this.tranPipe.transform('edit_translation_value_placeholder', 'Traduzione', []),
          fieldValue: this.translation.translationValue,
          fieldIsRequired: true,
          fieldIsDisabled: false,
          fieldPaddingLeft: false,
          fieldPaddingRight: false,
          fieldOptions: [{ id: 'rowNumber', value: 4 }]
          }
          /* {
            fieldType: 'input-text',
            fieldControlName: 'value',
            fieldBootstrapColumn: 12,
            fieldName: this.tranPipe.transform('edit_translation_value', 'Traduzione', []),
            fieldPlaceholder: this.tranPipe.transform('edit_translation_value_placeholder', 'Traduzione', []),

            fieldCustomClass: undefined,
            fieldOptions: undefined,
            fieldValue: this.translation.translationValue,
            fieldVerticalSpace: undefined,
            fieldIsRequired: true,
            fieldCustomValidators: undefined,
            fieldIsDisabled: false,
            fieldPaddingLeft: false,
            fieldPaddingRight: false
          } */
        ]
      }
    ]
  }

  async editTranslation() {
    this.isLoading = true;
    for (const i in this.validateForm.controls) {
      this.validateForm.controls[i].markAsDirty();
      this.validateForm.controls[i].updateValueAndValidity();
    }


    if (this.validateForm.valid) {
      this.newObj.translationId = this.validateForm.controls["key"].value;
      this.newObj.translationValue = this.validateForm.controls["value"].value;

      if (Helper.objIsEqual(this.newObj, this.translation)) {
        this.mesSvc.warning(this.tranPipe.transform('edit_no_changes', 'Nessuna modifica effettuata', []), {
          nzDuration: environment.MESSAGE_DURATION,
        });
        this.isLoading = false;
        return;
      }
      await this.apiProxySvc.makeRequestErrorMessage<string>(() => this.locSvc.addOrUpdateTranslationValue(this.tsvc.translationLanguage.value, {
        key: this.newObj.translationId!,
        value: this.newObj.translationValue!
      }), false, "internal-loader", 500, undefined, undefined, () => {
        let text: string;
        if (this.isNew) {
          text = this.tranPipe.transform('edit_translation_add', 'Traduzione aggiunta con successo', [])
        } else {
          text = this.tranPipe.transform('edit_translation_update', 'Traduzione aggiornata con successo', [])
        }
        this.mesSvc.success(text, {
          nzDuration: environment.MESSAGE_DURATION
        });
        this.modalRef.destroy(true);
      })
    }

    this.isLoading = false


  }

}
