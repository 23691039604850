import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { PurpleTranslationPipe, PurpleApiProxyService, PurpleSelectFilter, purpleFormFieldsGroup, PurpleApiMakeRequestResponse } from 'purple-lib';
import { AppTranslationService } from 'src/core-modules/localization/localization.service';
import { BackOfficeService, MembershipManagerService, TransactionsService, UserTableItem } from 'src/core-modules/sdk/api';
import { NzModalRef, NZ_MODAL_DATA } from 'ng-zorro-antd/modal';
import { NzMessageService } from 'ng-zorro-antd/message';
import { environment } from 'src/environments/default/environment';

@Component({
  selector: 'app-buy-user-membership',
  templateUrl: './buy-user-membership.component.html',
  styleUrls: ['./buy-user-membership.component.scss']
})
export class BuyUserMembershipComponent implements OnInit {

  constructor(@Inject(NZ_MODAL_DATA) private user: UserTableItem | undefined, private modalRef: NzModalRef, private fb: FormBuilder, private tranPipe: PurpleTranslationPipe, private apiProxySvc: PurpleApiProxyService,
    private mesSvc: NzMessageService, private admSvc: BackOfficeService, private tsvc: AppTranslationService, private memSvc: MembershipManagerService, private tranSvc: TransactionsService) { }

  validateForm: FormGroup = this.fb.group({});
  purpleFormFieldGroups: purpleFormFieldsGroup[] | undefined;
  disableForm: boolean = false;
  isLoading: boolean = false;
  bookingHost: string = localStorage.getItem("BookingHost")!;

  async ngOnInit(): Promise<void> {
    this.setForm()
  }

  setForm() {
    this.purpleFormFieldGroups = [
      {
        fieldGroupNumber: 1,
        fielGroupBootstrapColumn: 12,
        fieldGroupPaddingLeft: false,
        fieldGroupPaddingRight: false,
        formFieldFormGroup: this.validateForm,
        formFields: [
          {
            fieldType: 'select',
            fieldControlName: 'user',
            fieldBootstrapColumn: 12,
            fieldName: this.tranPipe.transform('buy_user_membership_user', 'Utente', []),
            fieldPlaceholder: this.tranPipe.transform('buy_user_membership_user_placeholder', 'Utente', []),

            fieldCustomClass: undefined,
            fieldOptions: [
              { id: 'scrollFunction', value: this.usersScrollFunction },
              { id: 'searchFunction', value: this.usersSearchFunction },
              { id: 'searchByIdFunction', value: this.usersSearchByIdFunction },
              { id: 'refreshFieldNumber', value: 0 }

            ],
            fieldValue: this.user?.userId,
            fieldIsRequired: true,
            fieldIsDisabled: (this.user?.userId??undefined) != undefined,
            fieldPaddingLeft: false,
            fieldPaddingRight: false
          },
          {
            fieldType: 'select',
            fieldControlName: 'membership',
            fieldBootstrapColumn: 12,
            fieldName: this.tranPipe.transform('buy_user_membership_membership', 'Membership', []),
            fieldPlaceholder: this.tranPipe.transform('buy_user_membership_membership_placeholder', 'Membership', []),

            fieldCustomClass: undefined,
            fieldOptions: [
              { id: 'scrollFunction', value: this.membershipsScrollFunction },
              { id: 'searchFunction', value: this.membershipsSearchFunction },
              { id: 'searchByIdFunction', value: this.membershipsSearchByIdFunction },
              { id: 'refreshFieldNumber', value: 0 }

            ],
            fieldValue: undefined,
            fieldIsRequired: true,
            fieldIsDisabled: false,
            fieldPaddingLeft: false,
            fieldPaddingRight: false
          }
        ]
      }
    ]
  }

  async buyMembership() {
    this.isLoading = true;

    for (const i in this.validateForm.controls) {
      this.validateForm.controls[i].markAsDirty();
      this.validateForm.controls[i].updateValueAndValidity();
    }

    if (this.validateForm.valid) {
      const memb = this.validateForm.controls["membership"].value;

      await this.apiProxySvc.makeRequestErrorMessage<boolean>(() => this.tranSvc.addMembershipToUserAdmin(this.tsvc.translationLanguage.value, {
        isRenew: this.user?.membership.membershipEndDate != undefined && this.user?.membership.membershipName.strEq(memb),
        skuId: memb,
        userId: this.validateForm.controls["user"].value
      }), false, "internal-loader", 500, undefined, undefined, (res: PurpleApiMakeRequestResponse<boolean>) => {
        this.mesSvc.success(res.message, {
          nzDuration: environment.MESSAGE_DURATION
        });

        this.modalRef.destroy(res.data ?? false);
      })

    }

    this.isLoading = false
  }

  //ScrollFunction
  usersScrollFunction = (args: { pageNumber: number, pageSize: number, culture: string, searchName: string, searchFilters: PurpleSelectFilter[] }) => {
    return this.admSvc.getUsersSelect(args.culture, args)
  }
  //SearchFunction
  usersSearchFunction = (args: { pageNumber: number, pageSize: number, culture: string, searchName: string, searchFilters: PurpleSelectFilter[] }) => {
    return this.admSvc.getUsersByNameSelect(args.culture, args)
  }
  //InitFunction (SearchByIdFunction)
  usersSearchByIdFunction = (args: { pageNumber: number, pageSize: number, culture: string, searchName: string, searchFilters: PurpleSelectFilter[] }) => {
    return this.admSvc.getUsersByIdSelect(args.culture, args)
  }

  
  //ScrollFunction
  membershipsScrollFunction = (args: { pageNumber: number, pageSize: number, culture: string, searchName: string, searchFilters: PurpleSelectFilter[] }) => {
    return this.memSvc.getMembershipsSelect(args.culture, args)
  }
  //SearchFunction
  membershipsSearchFunction = (args: { pageNumber: number, pageSize: number, culture: string, searchName: string, searchFilters: PurpleSelectFilter[] }) => {
    return this.memSvc.getMembershipsByNameSelect(args.culture, args)
  }
  //InitFunction (SearchByIdFunction)
  membershipsSearchByIdFunction = (args: { pageNumber: number, pageSize: number, culture: string, searchName: string, searchFilters: PurpleSelectFilter[] }) => {
    return this.memSvc.getMembershipsByIdSelect(args.culture, args)
  }
}
