import { Component, Inject, OnInit } from '@angular/core';
import { PurpleTranslationPipe, PurpleApiProxyService, PurpleTableColumn, PurpleTableSelectFilter, PurpleApiMakeRequestResponse, PurpleStorageService } from 'purple-lib';
import { Subject } from 'rxjs';
import { DashboardLayout2Service } from 'src/app/dashboard/dashboard-layout-2/dashboard-layout-2.service';
import { AppTranslationService } from 'src/core-modules/localization/localization.service';

import { BackOfficeService, PRPVSaleEventSlotsBookingPurpleTableResponse, PurpleTableSearchGroupGroup, UserBookingDetailAmount } from 'src/core-modules/sdk/api';
import { NZ_MODAL_DATA } from 'ng-zorro-antd/modal';

@Component({
  selector: 'app-show-booked-users-modal',
  templateUrl: './show-booked-users-modal.component.html',
  styleUrls: ['./show-booked-users-modal.component.scss']
})
export class ShowBookedUsersModalComponent implements OnInit {

  constructor(@Inject(NZ_MODAL_DATA) public data: { slotStartDate: string | undefined, saleEventId: string, saleEventCurrency: string, exportDataType: "day" | "slot" | "all" },
   public strSvc: PurpleStorageService, public dashSvc: DashboardLayout2Service, public admSvc: BackOfficeService,
    public tsvc: AppTranslationService, private tranPipe: PurpleTranslationPipe, private apiProxySvc: PurpleApiProxyService) {
  }
  
/*   @Input() saleEventId!: string;
  @Input() saleEventCurrency!: string;
  @Input() exportDataType: 'day' | 'slot' | 'all' = 'all';
  @Input() slotStartDate: string | undefined; */

  sub: Subject<void> = new Subject();
  
  userDetailAmount: UserBookingDetailAmount | undefined;
  isLoading: boolean = false;
  passwordVisible: string[] = [];
  listOfData: Array<PRPVSaleEventSlotsBookingPurpleTableResponse> | undefined | null;
  refreshTableRowsNumber: number = 0;
  tabColumns: PurpleTableColumn[] = [
    {
      name: this.tranPipe.transform("booked_user_table_header_slot", "Fascia Oraria"),
      dbKey: "",
      hasReorder: false,
      hasFilter: false
    },
    {
      name: this.tranPipe.transform("booked_user_table_header_firstname", "Nome"),
      dbKey: "firstname",
      hasReorder: true,
      hasFilter: true,
      filterType: 'search'
    },
    {
      name: this.tranPipe.transform("booked_user_table_header_lastname", "Cognome"),
      dbKey: "lastname",
      hasReorder: true,
      hasFilter: true,
      filterType: 'search'
    },
    {
      name: this.tranPipe.transform("booked_user_table_header_email", "Email"),
      dbKey: "email",
      hasReorder: true,
      hasFilter: true,
      filterType: 'search'
    },
    {
      name: this.tranPipe.transform("booked_user_table_header_checkInDate", "Ora check-in"),
      dbKey: "checkInDate",
      hasReorder: true,
      hasFilter: false,
      filterType: 'search'
    },
    {
      name: this.tranPipe.transform("booked_user_table_header_booked_slot_role_name", "Ruolo Prenotazione"),
      dbKey: "bookedSlotRoleName",
      hasReorder: true,
      hasFilter: true,
      filterType: 'search'
    },
    {
      name: "Azioni",
      hasReorder: false,
      hasFilter: false,
      fixedWidth: "120px"
    }
  ]

  refreshFunction = (args: {
    pageNumber: number, pageSize: number, orderColumnName?: string | null, orderType?: string | null,
    tableSearchValues?: Array<PurpleTableSearchGroupGroup> | null, tableFilters?: Array<PurpleTableSelectFilter> | null, switchOn?: boolean,
    culture: string
  }) => {
    args.culture = this.tsvc.translationLanguage.value;
    args.tableFilters = [
      {
        property: "saleEventId",
        filterValue: this.data.saleEventId
      },
      {
        property: "exportDataType",
        filterValue: this.data.exportDataType
      }
    ]

    if(this.data.slotStartDate != undefined){
      args.tableFilters.push(
        {
          property: "slotStartDate",
          filterValue: this.data.slotStartDate
        }
      )
    }
    return this.admSvc.getSaleEventBookedUsersTable(args.culture, args);
  }

  async showMoreInfo(el: PRPVSaleEventSlotsBookingPurpleTableResponse, event: boolean): Promise<void> {
    this.isLoading = true;

    this.listOfData!.map(m => m.expanded = false);
    el.expanded = event
    this.userDetailAmount = undefined;

    if (event == true) {

      await this.apiProxySvc.makeRequestErrorMessage<UserBookingDetailAmount>(()=>this.admSvc.getSaleEventSlotBookingUserDetail(this.tsvc.currentLanguage.value, {
        saleEventId: this.data.saleEventId,
        slotEndDate: el.item.slotEndDate,
        slotStartDate: el.item.slotStartDate,
        userId: el.item.userId
      }),false, "internal-loader",500,undefined,undefined,(res: PurpleApiMakeRequestResponse<UserBookingDetailAmount>)=>{
        this.userDetailAmount = res.data;
      })
    }

    this.isLoading = false;
  }

  async ngOnInit() {
  }

}
