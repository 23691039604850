import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { PurpleTranslationPipe, PurpleApiProxyService, PurpleSelectFilter, purpleFormFieldsGroup, PurpleApiMakeRequestResponse } from 'purple-lib';
import { AppTranslationService } from 'src/core-modules/localization/localization.service';
import { BackOfficeService, GetSaleEventSlotsSelectItem, GetSaleEventSlotsSelectItemListPurpleApiResponse } from 'src/core-modules/sdk/api';
import { NzModalRef, NZ_MODAL_DATA } from 'ng-zorro-antd/modal';
import { NzMessageService } from 'ng-zorro-antd/message';
import { environment } from 'src/environments/default/environment';
import { PrivateSaleService } from '../../../private-sale-service.service';

@Component({
  selector: 'app-book-slot-user',
  templateUrl: './book-slot-user.component.html',
  styleUrls: ['./book-slot-user.component.scss']
})
export class BookSlotUserComponent implements OnInit {

  constructor(@Inject(NZ_MODAL_DATA) private userId: string | undefined, private modalRef: NzModalRef, private fb: FormBuilder, private tranPipe: PurpleTranslationPipe, private apiProxySvc: PurpleApiProxyService,
    private mesSvc: NzMessageService, private backUserSvc: BackOfficeService, private tsvc: AppTranslationService, private seSvc: PrivateSaleService) { }

  validateForm: FormGroup = this.fb.group({});
  purpleFormFieldGroups: purpleFormFieldsGroup[] | undefined;
  disableForm: boolean = false;
  isLoading: boolean = false;
  isLoadingForce: boolean = false;
  isLoadingNormal: boolean = false;

  bookingHost: string = localStorage.getItem("BookingHost")!;

  selectSlots: { label: string, value: any }[] = [];

  async ngOnInit(): Promise<void> {
    this.isLoading = true;


    await this.apiProxySvc.makeRequestErrorFunction<GetSaleEventSlotsSelectItem[]>(() => this.backUserSvc.getSaleEventSlotsSelect(this.tsvc.currentLanguage.value, {
      saleEventId: this.seSvc.currentSaleEvent$.value!.saleEventId
    }), false, "internal-loader", 500, (res: PurpleApiMakeRequestResponse<GetSaleEventSlotsSelectItem[]>) => {
      this.setForm()
    }, (res: PurpleApiMakeRequestResponse<GetSaleEventSlotsSelectItem[]>) => {
      this.selectSlots = res.data!.map(m => {
        return {
          label: m.slotLabel,
          value: m.slotStartDate
        }
      })
      this.setForm()
    })

    this.isLoading = false;
  }

  setForm() {
    this.purpleFormFieldGroups = [
      {
        fieldGroupNumber: 1,
        fielGroupBootstrapColumn: 12,
        fieldGroupPaddingLeft: false,
        fieldGroupPaddingRight: false,
        formFieldFormGroup: this.validateForm,
        formFields: [
          {
            fieldType: 'select-local',
            fieldControlName: 'slot',
            fieldBootstrapColumn: 12,
            fieldName: this.tranPipe.transform('book_slot_user_slot', 'Slot', []),
            fieldPlaceholder: this.tranPipe.transform('book_slot_user_slot_placeholder', 'Slot', []),
            fieldValue: undefined,
            fieldIsRequired: true,
            fieldIsDisabled: false,
            fieldPaddingLeft: false,
            fieldPaddingRight: false,
            fieldOptions: [
              { id: 'isMultiple', value: false },
              { id: 'showArrow', value: true },
              { id: 'enableSearch', value: false },
              {
                id: 'options', value: this.selectSlots
              }
            ]
          },
          {
            fieldType: 'select',
            fieldControlName: 'user',
            fieldBootstrapColumn: 12,
            fieldName: this.tranPipe.transform('book_slot_user_user', 'Utente', []),
            fieldPlaceholder: this.tranPipe.transform('book_slot_user_user_placeholder', 'Utente', []),

            fieldCustomClass: undefined,
            fieldOptions: [
              { id: 'scrollFunction', value: this.usersScrollFunction },
              { id: 'searchFunction', value: this.usersSearchFunction },
              { id: 'searchByIdFunction', value: this.usersSearchByIdFunction },
              { id: 'refreshFieldNumber', value: 0 }

            ],
            fieldValue: this.userId,
            fieldIsRequired: true,
            fieldIsDisabled: false,
            fieldPaddingLeft: false,
            fieldPaddingRight: false
          },
          {
            fieldType: 'toggle',
            fieldControlName: 'assistance',
            fieldBootstrapColumn: 12,
            fieldName: this.tranPipe.transform('book_slot_user_assistance', 'Richiede Assistenza', []),
            fieldPlaceholder: this.tranPipe.transform('book_slot_user_assistance_placeholder', 'Richiede assistenza', []),
            fieldValue: undefined,
            fieldIsRequired: false,
            fieldIsDisabled: false,
            fieldPaddingLeft: false,
            fieldPaddingRight: false
          }
        ]
      }
    ]
  }

  async bookSlot(forceBook: boolean) {
    this.isLoadingNormal = !forceBook;
    this.isLoadingForce = forceBook;

    for (const i in this.validateForm.controls) {
      this.validateForm.controls[i].markAsDirty();
      this.validateForm.controls[i].updateValueAndValidity();
    }

    if (this.validateForm.valid) {
      await this.apiProxySvc.makeRequestErrorMessage<boolean>(() => this.backUserSvc.bookSlotAdmin(this.tsvc.translationLanguage.value, {
        bookingClientHost: this.bookingHost,
        forceBook: forceBook,
        requireAssistance: this.validateForm.controls["assistance"].value,
        saleEventId: this.seSvc.currentSaleEvent$.value!.saleEventId,
        slotStartDate: this.validateForm.controls["slot"].value,
        userId: this.validateForm.controls["user"].value
      }), false, "internal-loader", 500, undefined, undefined, (res: PurpleApiMakeRequestResponse<boolean>) => {
        this.mesSvc.success(res.message, {
          nzDuration: environment.MESSAGE_DURATION
        });

        this.modalRef.destroy(res.data ?? false);
      })

    }

    this.isLoadingForce = false
    this.isLoadingNormal = false
  }

  //ScrollFunction
  usersScrollFunction = (args: { pageNumber: number, pageSize: number, culture: string, searchName: string, searchFilters: PurpleSelectFilter[] }) => {
    return this.backUserSvc.getUsersSelect(args.culture, args)
  }
  //SearchFunction
  usersSearchFunction = (args: { pageNumber: number, pageSize: number, culture: string, searchName: string, searchFilters: PurpleSelectFilter[] }) => {
    return this.backUserSvc.getUsersByNameSelect(args.culture, args)
  }
  //InitFunction (SearchByIdFunction)
  usersSearchByIdFunction = (args: { pageNumber: number, pageSize: number, culture: string, searchName: string, searchFilters: PurpleSelectFilter[] }) => {
    return this.backUserSvc.getUsersByIdSelect(args.culture, args)
  }
}
