import { Component, Inject, LOCALE_ID, OnDestroy, OnInit } from '@angular/core';
import { Guid, PurpleApiMakeRequestResponse, PurpleApiProxyService, PurpleSelectFilter, PurpleTableBoxColumn, PurpleTableSelectFilter, PurpleTranslationPipe, dashboardButton } from 'purple-lib';
import { DashboardLayout2Service } from 'src/app/dashboard/dashboard-layout-2/dashboard-layout-2.service';
import { AppTranslationService } from 'src/core-modules/localization/localization.service';

import { BackOfficeService, PRPVSaleEventFullExtended, PurpleTableSearchGroupGroup, SaleEventUserExtendedTableItem, SaleEventUserExtendedTableItemPurpleTableResponse } from 'src/core-modules/sdk/api';
import { PrivateSaleService } from '../../../../private-sale-service.service';
import { Subject, lastValueFrom, takeUntil } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/default/environment';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthenticationService } from 'src/core-modules/authentication/authentication.service';

@Component({
  selector: 'app-private-sale-users-private',
  templateUrl: './private-sale-users-private.component.html',
  styleUrls: ['./private-sale-users-private.component.scss']
})
export class PrivateSaleUsersPrivateComponent implements OnInit, OnDestroy {

  constructor(@Inject(LOCALE_ID) private locale: string, private tranPipe: PurpleTranslationPipe, public dashSvc: DashboardLayout2Service, private tsvc: AppTranslationService, private router: Router,
    public admSvc: BackOfficeService, private seSvc: PrivateSaleService, private apiProxySvc: PurpleApiProxyService, private route: ActivatedRoute,
    private modal: NzModalService, private mesSvc: NzMessageService, private authSvc: AuthenticationService, private notification: NzNotificationService, private http: HttpClient) { }

  bookingHost: string = localStorage.getItem("BookingHost")!;
  basePath: string = localStorage.getItem("BasePath")!;
  showTable: boolean = false;
  saleEvent: PRPVSaleEventFullExtended | undefined;
  sub: Subject<void> = new Subject();
  listOfData: Array<SaleEventUserExtendedTableItemPurpleTableResponse> | undefined | null;
  refreshTableRowsNumber: number = 0;

  //ScrollFunction
  usersScrollFunction = (args: { pageNumber: number, pageSize: number, culture: string, searchName: string, searchFilters: PurpleSelectFilter[] }) => {
    return this.admSvc.getUsersSelect(args.culture, args)
  }
  //SearchFunction
  usersSearchFunction = (args: { pageNumber: number, pageSize: number, culture: string, searchName: string, searchFilters: PurpleSelectFilter[] }) => {
    return this.admSvc.getUsersByNameSelect(args.culture, args)
  }
  //InitFunction (SearchByIdFunction)
  usersSearchByIdFunction = (args: { pageNumber: number, pageSize: number, culture: string, searchName: string, searchFilters: PurpleSelectFilter[] }) => {
    return this.admSvc.getUsersByIdSelect(args.culture, args)
  }

  //ScrollFunction
  saleEventRolesScrollFunction = (args: { pageNumber: number, pageSize: number, culture: string, searchName: string, searchFilters: PurpleSelectFilter[] }) => {
    args.searchFilters = [
      {
        propertyName: "saleEventId",
        filterOperator: "=",
        filterValues: [this.saleEvent!.saleEventId]
      },
      {
        propertyName: "IsPrivateInviteCounter",
        filterOperator: "=",
        filterValues: [this.saleEvent!.isPrivateInviteCounter?"true":"false"]
      }
    ]
    return this.admSvc.getSaleEventRolesSelect(args.culture, args)
  }
  //SearchFunction
  saleEventRolesSearchFunction = (args: { pageNumber: number, pageSize: number, culture: string, searchName: string, searchFilters: PurpleSelectFilter[] }) => {
    args.searchFilters = [
      {
        propertyName: "saleEventId",
        filterOperator: "=",
        filterValues: [this.saleEvent!.saleEventId]
      },
      {
        propertyName: "IsPrivateInviteCounter",
        filterOperator: "=",
        filterValues: [this.saleEvent!.isPrivateInviteCounter?"true":"false"]
      }
    ]
    return this.admSvc.getSaleEventRolesByNameSelect(args.culture, args)
  }
  //InitFunction (SearchByIdFunction)
  saleEventRolesSearchByIdFunction = (args: { pageNumber: number, pageSize: number, culture: string, searchName: string, searchFilters: PurpleSelectFilter[] }) => {
    return this.admSvc.getSaleEventRolesByIdSelect(args.culture, args)
  }


  tabColumns: PurpleTableBoxColumn[] = [
    {
      name: this.tranPipe.transform("private_sale_table_header_name", "Nome"),
      dbKey: "sourceFirstName",
      hasReorder: true,
      hasFilter: true,
      filterField: {
        fieldType: 'input-text',
        fieldControlName: 'sourceFirstName',
        fieldBootstrapColumn: 6,
        fieldName: this.tranPipe.transform('private_table_box_filter_firstname', 'Nome', []),
        fieldPlaceholder: this.tranPipe.transform('table_box_filter_firstname_placeholder', 'Nome del cliente', []),
        fieldValue: undefined,
        fieldIsRequired: false,
        fieldIsDisabled: false,
        fieldPaddingLeft: false,
        fieldPaddingRight: true
      },
      operatorType: "like"
    },
    {
      name: this.tranPipe.transform("private_sale_table_header_lastname", "Cognome"),
      dbKey: "sourceLastName",
      hasReorder: true,
      hasFilter: true,
      filterField: {
        fieldType: 'input-text',
        fieldControlName: 'sourceLastName',
        fieldBootstrapColumn: 6,
        fieldName: this.tranPipe.transform('tprivate_table_box_filter_lastname', 'Cognome', []),
        fieldPlaceholder: this.tranPipe.transform('private_table_box_filter_lastname_placeholder', 'Cognome del cliente', []),
        fieldValue: undefined,
        fieldIsRequired: false,
        fieldIsDisabled: false,
        fieldPaddingLeft: true,
        fieldPaddingRight: false
      },
      operatorType: "like"
    },
    {
      name: this.tranPipe.transform("private_sale_table_header_email", "Email"),
      dbKey: "sourceEmail",
      hasReorder: true,
      hasFilter: true,
      filterField: {
        fieldType: 'input-text',
        fieldControlName: 'sourceEmail',
        fieldBootstrapColumn: 6,
        fieldName: this.tranPipe.transform('private_table_box_filter_email', 'Email', []),
        fieldPlaceholder: this.tranPipe.transform('private_table_box_filter_email_placeholder', 'Email del cliente', []),
        fieldValue: undefined,
        fieldIsRequired: false,
        fieldIsDisabled: false,
        fieldPaddingLeft: false,
        fieldPaddingRight: true
      },
      operatorType: 'like'
    },
    {
      name: this.tranPipe.transform("private_sale_table_header_company", "Azienda"),
      dbKey: "sourceUserCompany",
      hasReorder: false,
      hasFilter: true,
      filterField: {
        fieldType: 'input-text',
        fieldControlName: 'sourceUserCompany',
        fieldBootstrapColumn: 6,
        fieldName: this.tranPipe.transform('private_table_box_filter_company', 'Azienda', []),
        fieldPlaceholder: this.tranPipe.transform('private_table_box_filter_company_placeholder', 'Azienda del cliente', []),
        fieldValue: undefined,
        fieldIsRequired: false,
        fieldIsDisabled: false,
        fieldPaddingLeft: true,
        fieldPaddingRight: false
      },
      operatorType: "like"
    },
    {
      name: this.tranPipe.transform("private_sale_table_header_blocked", "Bloccato"),
      dbKey: "sourceUserIsPending",
      hasReorder: true,
      hasFilter: true,
      filterField: {
        fieldType: 'select-local',
        fieldControlName: 'sourceUserIsPending',
        fieldBootstrapColumn: 6,
        fieldName: this.tranPipe.transform('private_table_box_filter_blocked', 'Bloccato', []),
        fieldPlaceholder: this.tranPipe.transform('private_table_box_filter_blocked_placeholder', 'Cliente bloccato', []),
        fieldValue: undefined,
        fieldIsRequired: false,
        fieldIsDisabled: false,
        fieldPaddingLeft: false,
        fieldPaddingRight: true,
        fieldOptions: [
          { id: 'isMultiple', value: false },
          { id: 'showArrow', value: true },
          { id: 'enableSearch', value: false },
          {
            id: 'options', value: [
              {
                label: 'Si',
                value: 1
              },
              {
                label: 'No',
                value: 0
              }
            ]
          }
        ]
      },
      operatorType: "="
    },
    {
      name: this.tranPipe.transform("private_sale_table_header_invited", "Invitato"),
      dbKey: "isInvited",
      hasReorder: true,
      hasFilter: true,
      filterField: {
        fieldType: 'select-local',
        fieldControlName: 'isInvited',
        fieldBootstrapColumn: 6,
        fieldName: this.tranPipe.transform('private_table_box_filter_invited', 'Invitato', []),
        fieldPlaceholder: this.tranPipe.transform('private_table_box_filter_invited_placeholder', 'Cliente invitato', []),
        fieldValue: undefined,
        fieldIsRequired: false,
        fieldIsDisabled: false,
        fieldPaddingLeft: true,
        fieldPaddingRight: false,
        fieldOptions: [
          { id: 'isMultiple', value: false },
          { id: 'showArrow', value: true },
          { id: 'enableSearch', value: false },
          {
            id: 'options', value: [
              {
                label: 'Si',
                value: 1
              },
              {
                label: 'No',
                value: 0
              }
            ]
          }
        ]
      },
      operatorType: "="
    },
    {
      name: this.tranPipe.transform("private_sale_table_header_invited_by", "Invitato da"),
      dbKey: "invitedByUserId",
      hasReorder: true,
      hasFilter: true,
      operatorType: '=',
      filterField: {
        fieldType: 'select',
        fieldControlName: 'invitedByUserId',
        fieldBootstrapColumn: 12,
        fieldName: this.tranPipe.transform('private_table_box_filter_invited_by', 'Invitato da', []),
        fieldPlaceholder: this.tranPipe.transform('private_table_box_filter_invited_by_placeholder', 'Invitato da', []),
        fieldValue: undefined,
        fieldIsRequired: false,
        fieldIsDisabled: false,
        fieldPaddingLeft: false,
        fieldPaddingRight: false,
        fieldOptions: [
          { id: 'scrollFunction', value: this.usersScrollFunction },
          { id: 'searchFunction', value: this.usersSearchFunction },
          { id: 'searchByIdFunction', value: this.usersSearchByIdFunction },
          { id: 'refreshFieldNumber', value: 0 }
        ]
      }
    },
    {
      name: this.tranPipe.transform("table_header_actions", "Azioni"),
      hasReorder: false,
      hasFilter: false,
      fixedWidth: "230px"
    },
    {
      name: this.tranPipe.transform("private_sale_table_header_role", "Ruolo"),
      dbKey: "sourceRoleId",
      hasReorder: true,
      hasFilter: true,
      filterField: {
        fieldType: 'select',
        fieldControlName: 'sourceRoleId',
        fieldBootstrapColumn: 12,
        fieldName: this.tranPipe.transform('private_table_box_filter_role', 'Ruolo', []),
        fieldPlaceholder: this.tranPipe.transform('private_table_box_filter_role_placeholder', 'Ruplo cliente', []),
        fieldValue: undefined,
        fieldIsRequired: false,
        fieldIsDisabled: false,
        fieldPaddingLeft: false,
        fieldPaddingRight: false,
        fieldOptions: [
          { id: 'scrollFunction', value: this.saleEventRolesScrollFunction },
          { id: 'searchFunction', value: this.saleEventRolesSearchFunction },
          { id: 'searchByIdFunction', value: this.saleEventRolesSearchByIdFunction },
          { id: 'refreshFieldNumber', value: 0 }
        ]
      },
      operatorType: '=',
      hiddenColumn: true
    }
  ]

  refreshFunction = (args: {
    pageNumber: number, pageSize: number, orderColumnName?: string | null, orderType?: string | null,
    tableSearchValues?: Array<PurpleTableSearchGroupGroup> | null, tableFilters?: Array<PurpleTableSelectFilter> | null, switchOn?: boolean,
    culture: string
  }) => {
    args.culture = this.tsvc.translationLanguage.value;

    args.tableFilters = [
      {
        property: "saleEventId",
        filterValue: this.saleEvent?.saleEventId ?? Guid.empty()
      }
    ];


    return this.admSvc.getSaleEventUsersExtendedTable(args.culture, args);
  }

  ngOnDestroy(): void {
    this.sub.next();
    this.sub.complete();
    this.dashSvc.clearPageHeader();
  }

  ngOnInit() {
    this.seSvc.currentSaleEvent$.pipe(takeUntil(this.sub)).subscribe((se: PRPVSaleEventFullExtended | undefined) => {
      this.saleEvent = se;

      this.showTable = true;
    });

    this.route.queryParams.subscribe(async params => {
      this.dashSvc.dashBackButtons?.push(
        new dashboardButton(
          "back-button",
          this.tranPipe.transform("dashboard_private_general_iofo_back_button", "Torna alla lista", []),
          () => { this.router.navigate([this.tsvc.currentLanguage.value, 'dashboard', 'private-sales', 'private-sale-list']) },
          undefined,
          "arrow-left",
          undefined,
          "left"
        )
      );

      this.dashSvc.dashMainItems.push(
        new dashboardButton(
          "reminder-users",
          this.tranPipe.transform('users_reminder_button', 'Invio Reminder Utenti', []),
          async () => { await this.reminderUsers() }),
        new dashboardButton(
          "download-users",
          this.tranPipe.transform('users_download_excel_button', 'Download Excel Utenti', []),
          async () => { await this.exportExcel() })
      )


      await this.refreshSale(params['saleEvent']);
    });
  }

  private async refreshSale(seId: string) {
    await this.dashSvc.refreshSaleEvent(seId, "private-sale-users", 'Riepilogo utenti');
  }


  getPendingTooltipTitle(user: SaleEventUserExtendedTableItem) {
    if (user.sourceUserIsPending) {
      return this.tranPipe.transform('edit_pending_button_active', 'Attiva utente', [])
    } else {
      return this.tranPipe.transform('edit_pending_button_not_active', 'Disabilita utente', [])
    }
  }

  async deleteUser(user: SaleEventUserExtendedTableItem) {
    this.modal.create({
      nzTitle: this.tranPipe.transform('delete_user_title', 'Rimuovere <u>definitivamente</u> utente <b>{0}</b>', [user.sourceFirstName + " " + user.sourceLastName]),

      nzContent: this.tranPipe.transform('delete_user_subtitle', "Sei sicuro di voler rimuovere <b>definitivamente</b> l'utente <b>{0}</b> dal sistema?", [user.sourceFirstName + " " + user.sourceLastName]),
      nzWidth: '600px',
      nzOkText: this.tranPipe.transform('delete_user_button', 'Elimina', []),
      nzClassName: 'purple-simple-modal',
      nzOnOk: async () => {
        await this.apiProxySvc.makeRequestErrorMessage<boolean>(() => this.admSvc.deleteUser(this.tsvc.currentLanguage.value, {
          host: this.bookingHost,
          userId: user.sourceUserId
        }), false, "internal-loader", 500, undefined, undefined, (res: PurpleApiMakeRequestResponse<boolean>) => {
          this.mesSvc.success(this.tranPipe.transform('delete_user_ok', 'Utente rimosso con successo', []), {
            nzDuration: environment.MESSAGE_DURATION
          });
          this.refreshTableRowsNumber++;
        })
      }
    })
  }

  async pendingUser(user: SaleEventUserExtendedTableItem) {
    this.modal.create({
      nzTitle: user.sourceUserIsPending ? this.tranPipe.transform('edit_pending_user_active_title', 'Attivare utente <b>{0}</b>', [user.sourceFirstName + " " + user.sourceLastName]) :
        this.tranPipe.transform('edit_pending_user_not_active_title', 'Disabilita utente <b>{0}</b>', [user.sourceFirstName + " " + user.sourceLastName]),

      nzContent: user.sourceUserIsPending ? this.tranPipe.transform('edit_pending_user_active_subtitle', '<b>Togliere</b> dalla lista di attesa l\'utente <b>{0}</b>', [user.sourceFirstName + " " + user.sourceLastName]) :
        this.tranPipe.transform('edit_pending_user_not_active_subtitle', '<b>Aggiungere</b> alla lista di attesa l\'utente <b>{0}</b>', [user.sourceFirstName + " " + user.sourceLastName]),
      nzWidth: '600px',
      nzOkText: user.sourceUserIsPending ? this.tranPipe.transform('edit_pending_user_active_button', 'Attiva', []) : this.tranPipe.transform('edit_pending_user_not_active_button', 'Disattiva', []),
      nzClassName: 'purple-simple-modal',
      nzOnOk: async () => {
        await this.apiProxySvc.makeRequestErrorMessage<boolean>(() => this.admSvc.activeOrPendingUser(this.tsvc.currentLanguage.value, {
          userId: user.sourceUserId,
          saleEventId: user.saleEventId,
          bookingHost: this.bookingHost
        }), false, "internal-loader", 500, undefined, undefined, (res: PurpleApiMakeRequestResponse<boolean>) => {
          this.mesSvc.success(this.tranPipe.transform('edit_user_pending_ok', 'Modifiche salvate con successo', []), {
            nzDuration: environment.MESSAGE_DURATION
          });
          this.refreshTableRowsNumber++;
        })
      }
    })
  }

  async reinviteUser(user: SaleEventUserExtendedTableItem) {
    this.modal.create({
      nzTitle: this.tranPipe.transform('reinvite_user_title', 'Invitare nuovamente l\'utente <b>{0}</b>', [user.sourceFirstName + " " + user.sourceLastName]),
      nzContent: this.tranPipe.transform('reinvite_user_subtitle', 'L\'utente <b>{0}</b> riceverà una nuova mail di invito alla private sale "<b>{1}</b>"',
        [user.sourceFirstName + " " + user.sourceLastName, this.saleEvent?.saleEventName ?? ""]),
      nzWidth: '600px',
      nzClassName: 'purple-simple-modal',
      nzFooter: [
        {
          label: this.tranPipe.transform('reinvite_user_button_copy_link', 'Copia Link', []),
          type: "default",
          onClick: async () => {
            await this.apiProxySvc.makeRequestErrorMessage<string>(() => this.admSvc.reInvite(this.tsvc.currentLanguage.value, {
              userId: user.sourceUserId,
              saleEventId: user.saleEventId,
              bookingHost: this.bookingHost,
              sendEmail: false
            }), false, "internal-loader", 500, undefined, undefined, (res: PurpleApiMakeRequestResponse<string>) => {
              navigator.clipboard.writeText(res.data ?? "");
              this.mesSvc.success(this.tranPipe.transform('reinvite_user_copy_link_ok', 'Link copiato negli appunti', []), {
                nzDuration: environment.MESSAGE_DURATION
              });
            })
          }
        },
        {
          label: this.tranPipe.transform('reinvite_user_button_send_email', 'Invita', []),
          type: "primary",
          onClick: async () => {
            await this.apiProxySvc.makeRequestErrorMessage<string>(() => this.admSvc.reInvite(this.tsvc.currentLanguage.value, {
              userId: user.sourceUserId,
              saleEventId: user.saleEventId,
              bookingHost: this.bookingHost,
              sendEmail: true
            }), false, "internal-loader", 500, undefined, undefined, (res: PurpleApiMakeRequestResponse<string>) => {
              this.mesSvc.success(this.tranPipe.transform('reinvite_user_send_email_ok', 'Inviti inviato con successo', []), {
                nzDuration: environment.MESSAGE_DURATION
              });
            });
          }
        }
      ]
    })
  }

  async exportExcel() {
    {
      this.notification.blank(this.tranPipe.transform('export_user_excel_download_message_title', 'Download Excel in corso...', []),
        this.tranPipe.transform('export_user_excel_download_message_subtitle', 'La generazione e il download del excel protrrebbe richiedere alcuni secondi', []), {
        nzKey: 'download-excel'
      });

      const headers = new HttpHeaders().set('authorization', 'Bearer ' + this.authSvc.currentUser?.token);

      const file = await lastValueFrom(this.http.get<Blob>(`${this.basePath}/${this.tsvc.currentLanguage.value}/backoffice/ExportSaleEventUsersExcel?saleeventId=${this.saleEvent?.saleEventId}`,
        { headers, responseType: 'blob' as 'json' }));


      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(file);
      link.download = `${this.saleEvent?.saleEventCode}_users.xlsx`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      this.notification.success(this.tranPipe.transform('export_user_excel_downloaded_message_title', 'Download Excel completato', []),
        this.tranPipe.transform('export_user_excel_downloaded_message_subtitle', 'Il file excel è stato generato e scaricato correttamente', []), {
        nzKey: 'download-excel'
      });
    }
  }

  async reminderUsers() {
    this.modal.create({
      nzTitle: this.tranPipe.transform('reminder_user_title', 'Invio email reminder', []),

      nzContent: this.tranPipe.transform('reminder_user_subtitle', "A tutti gli utenti senza una prenotazione verrà inviata una mail di reminder", []),
      nzWidth: '600px',
      nzOkText: this.tranPipe.transform('reminder_user_button', 'Invia', []),
      nzClassName: 'purple-simple-modal',
      nzOnOk: async () => {

        await this.apiProxySvc.makeRequestErrorMessage<boolean>(() => this.admSvc.sendUserReminders(this.tsvc.currentLanguage.value, {
          saleEventId: this.saleEvent!.saleEventId!,
          clientHost: this.bookingHost
        }), false, "internal-loader", 500, undefined, undefined, (res: PurpleApiMakeRequestResponse<boolean>) => {
          this.mesSvc.success(this.tranPipe.transform('reminder_user_ok', 'Reminder inviati con successo', []), {
            nzDuration: environment.MESSAGE_DURATION
          });
          this.refreshTableRowsNumber++;
        })
      }
    })
  }




}
