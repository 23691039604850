import { Component, Inject, Input, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { purpleFormFieldsGroup, PurpleApiProxyService, PurpleTranslationPipe, Helper, PurpleSelectFilter, PurpleApiMakeRequestResponse } from 'purple-lib';
import { AppTranslationService } from 'src/core-modules/localization/localization.service';
import { LocalizationService, BackOfficeService, SaleEventLimitedSaleCategory } from 'src/core-modules/sdk/api';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalRef, NZ_MODAL_DATA } from 'ng-zorro-antd/modal';

import { takeUntil, Subject } from 'rxjs';
import { environment } from 'src/environments/default/environment';

@Component({
  selector: 'app-edit-category-limit-modal',
  templateUrl: './edit-category-limit-modal.component.html',
  styleUrls: ['./edit-category-limit-modal.component.scss']
})
export class EditCategoryLimitModalComponent implements OnInit {
  /* @Input() categoryLimitFull: SaleEventLimitedSaleCategory | undefined = undefined;
  @Input() saleEventId!: string; */

  sub: Subject<void> = new Subject();
  originalCategoryLimit!: EditCategoryLimit;
  newObj!: EditCategoryLimit;
  isNew: boolean = false;
  validateForm: FormGroup = this.fb.group({});
  purpleFormFieldGroups: purpleFormFieldsGroup[] | undefined;
  disableForm: boolean = false;
  isLoading: boolean = false;

  constructor(@Inject(NZ_MODAL_DATA) private data: { categoryLimitFull: SaleEventLimitedSaleCategory | undefined, saleEventId: string }, private modalRef: NzModalRef, private mesSvc: NzMessageService, private apiProxySvc: PurpleApiProxyService, private admSvc: BackOfficeService,
    private locSvc: LocalizationService, private tsvc: AppTranslationService, private fb: FormBuilder, private tranPipe: PurpleTranslationPipe) { }

  async ngOnInit(): Promise<void> {
    this.originalCategoryLimit = {
      categoryId: this.data.categoryLimitFull?.saleCategory,
      categoryLimitMaxAmount: this.data.categoryLimitFull?.categoryLimitMaxAmount,
      categoryLimitMaxQty: this.data.categoryLimitFull?.categoryLimitMaxQty,
      isCategoryLimitMaxAmount: this.data.categoryLimitFull?.isCategoryLimitMaxAmount,
      isCategoryLimitMaxQty: this.data.categoryLimitFull?.isCategoryLimitMaxQty
    }

    if(this.data.categoryLimitFull == undefined){
      this.isNew = true;
    }

    this.newObj = Helper.storeNewObj(this.originalCategoryLimit);

    this.purpleFormFieldGroups = [
      {
        fieldGroupNumber: 1,
        fielGroupBootstrapColumn: 12,
        fieldGroupPaddingLeft: false,
        fieldGroupPaddingRight: false,
        formFieldFormGroup: this.validateForm,
        formFields: [
          {
            fieldType: 'select',
            fieldControlName: 'category',
            fieldBootstrapColumn: 12,
            fieldName: this.tranPipe.transform('edit_category_limit_category', 'Categoria', []),
            fieldPlaceholder: this.tranPipe.transform('edit_category_limit_category_placeholder', 'Seleziona la categoria', []),
            fieldValue: this.newObj.categoryId,
            fieldIsRequired: true,
            fieldIsDisabled: false,
            fieldPaddingLeft: false,
            fieldPaddingRight: false,
            fieldOptions: [
              { id: 'scrollFunction', value: this.categorysScrollFunction },
              { id: 'searchFunction', value: this.categorysSearchFunction },
              { id: 'searchByIdFunction', value: this.categorysSearchByIdFunction },
              { id: 'refreshFieldNumber', value: 0 },
            ]
          },
          {
            fieldType: 'input-number',
            fieldControlName: 'categoryLimitMaxAmount',
            fieldBootstrapColumn: 12,
            fieldName: this.tranPipe.transform('edit_category_limit_max_amount', 'Importo massimo consentito', []),
            fieldPlaceholder: this.tranPipe.transform('edit_category_limit_max_amount_placeholder', 'Seleziona l\'importo massimo consentito per scontrino', []),
            fieldValue: this.newObj.categoryLimitMaxAmount,
            fieldIsRequired: this.newObj.isCategoryLimitMaxAmount,
            fieldIsDisabled: !this.newObj.isCategoryLimitMaxAmount,
            fieldPaddingLeft: false,
            fieldPaddingRight: false,
            fieldOptions: [{ id: 'minNumber', value: 0 }],
            fieldAffix: {
              fieldType: 'toggle',
              fieldControlName: 'isCategoryLimitMaxAmount',
              fieldWidth: "60px",
              fieldValue: this.newObj.isCategoryLimitMaxAmount,
              fieldIsRequired: false,
              fieldIsDisabled: false,
              fieldOptions: [],
              fieldPlaceholder: ""
            }
          },
          {
            fieldType: 'input-number',
            fieldControlName: 'categoryLimitMaxQty',
            fieldBootstrapColumn: 12,
            fieldName: this.tranPipe.transform('edit_category_limit_max_qty', 'Numero massimo articoli consentito', []),
            fieldPlaceholder: this.tranPipe.transform('edit_category_limit_max_qty_placeholder', 'Seleziona il numero massimo di articoli per scontrino', []),
            fieldValue: this.newObj.categoryLimitMaxQty,
            fieldIsRequired: this.newObj.isCategoryLimitMaxQty,
            fieldIsDisabled: !this.newObj.isCategoryLimitMaxQty,
            fieldPaddingLeft: false,
            fieldPaddingRight: false,
            fieldOptions: [{ id: 'minNumber', value: 0 }],
            fieldAffix: {
              fieldType: 'toggle',
              fieldControlName: 'isCategoryLimitMaxQty',
              fieldWidth: "60px",
              fieldValue: this.newObj.isCategoryLimitMaxQty,
              fieldIsRequired: false,
              fieldIsDisabled: false,
              fieldOptions: [],
              fieldPlaceholder: ""
            }
          }
        ]
      }
    ]

    setTimeout(() => {
      this.validateForm.controls["isCategoryLimitMaxAmount"].valueChanges.pipe(takeUntil(this.sub)).subscribe((s: boolean) => {
        if (s) {
          this.validateForm.controls['categoryLimitMaxAmount'].enable();
          this.validateForm.controls['categoryLimitMaxAmount'].addValidators(Validators.required);
        } else {
          this.validateForm.controls['categoryLimitMaxAmount'].disable();
          this.validateForm.controls['categoryLimitMaxAmount'].removeValidators(Validators.required);
        }
      });

      this.validateForm.controls["isCategoryLimitMaxQty"].valueChanges.pipe(takeUntil(this.sub)).subscribe((s: boolean) => {
        if (s) {
          this.validateForm.controls['categoryLimitMaxQty'].enable();
          this.validateForm.controls['categoryLimitMaxQty'].addValidators(Validators.required);
        } else {
          this.validateForm.controls['categoryLimitMaxQty'].disable();
          this.validateForm.controls['categoryLimitMaxQty'].removeValidators(Validators.required);
        }
      });
    }, 100);
  }


  async editCategoryLimit() {
    this.isLoading = true;
    for (const i in this.validateForm.controls) {
      this.validateForm.controls[i].markAsDirty();
      this.validateForm.controls[i].updateValueAndValidity();
    }
    if (this.validateForm.valid) {
      await this.apiProxySvc.makeRequestErrorMessage<boolean>(() => this.admSvc.addOrUpdateSaleEventCategoryLimit(this.tsvc.currentLanguage.value, {
        saleCategory: this.validateForm.controls['category'].value,
        categoryLimitMaxAmount: this.validateForm.controls['categoryLimitMaxAmount'].value,
        categoryLimitMaxQty: this.validateForm.controls['categoryLimitMaxQty'].value,
        isCategoryLimitMaxAmount: this.validateForm.controls['isCategoryLimitMaxAmount'].value,
        isCategoryLimitMaxQty: this.validateForm.controls['isCategoryLimitMaxQty'].value,
        isNew: this.isNew,
        saleEventId: this.data.saleEventId
      }), false, "internal-loader", 500, undefined, undefined, (res: PurpleApiMakeRequestResponse<boolean>) => {
        let text: string;
        if (this.isNew) {
          text = this.tranPipe.transform('edit_limit_category_add_success', 'Limite Category aggiunto con successo', [])
        } else {
          text = this.tranPipe.transform('edit_limit_category_update_success', 'Limite Category aggiornato con successo', [])
        }
        this.mesSvc.success(text, {
          nzDuration: environment.MESSAGE_DURATION
        });
        this.modalRef.destroy(res.data);
      })
      
    }
    this.isLoading = false
  }

  //ScrollFunction
  categorysScrollFunction = (args: { pageNumber: number, pageSize: number, culture: string, searchName: string, searchFilters: PurpleSelectFilter[] }) => {
    args.searchFilters = [
      { propertyName: "saleEventId", filterValues: [this.data.saleEventId], filterOperator:"=" }
    ]
    return this.admSvc.getSaleEventItemsCategoriesSelect(args.culture, args)
  }
  //SearchFunction
  categorysSearchFunction = (args: { pageNumber: number, pageSize: number, culture: string, searchName: string, searchFilters: PurpleSelectFilter[] }) => {
    args.searchFilters = [
      { propertyName: "saleEventId", filterValues: [this.data.saleEventId], filterOperator:"=" }
    ]
    return this.admSvc.getSaleEventItemsCategoriesByNameSelect(args.culture, args)
  }
  //InitFunction (SearchByIdFunction)
  categorysSearchByIdFunction = (args: { pageNumber: number, pageSize: number, culture: string, searchName: string, searchFilters: PurpleSelectFilter[] }) => {
    return this.admSvc.getSaleEventItemsCategoriesByIdSelect(args.culture, args)
  }

}


interface EditCategoryLimit {
  categoryId: string | undefined;
  categoryLimitMaxQty: number | undefined;
  isCategoryLimitMaxQty: boolean | undefined;
  categoryLimitMaxAmount: number | undefined;
  isCategoryLimitMaxAmount: boolean | undefined;
}