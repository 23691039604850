import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/default/environment';

@Component({
  selector: 'app-root',
  templateUrl: './country.component.html',
  styleUrls: ['./country.component.scss']
})
export class CountryComponent implements OnInit {

  constructor() { }
  imagePath: string = '/assets/images/'+environment.COMPANY_ABB+'/country-image.webp';
  countries: {
    COUNTRY_NAME: string,
    COUNTRY_FLAG: string,
    COUNTRY_BASE_PATH: string,
    COUNTRY_MEDIA_PATH: string,
    COUNTRY_DEFAULT_LANGUAGE: string,
    BOOKING_CLIENT_HOST: string
  }[] = environment.COUNTRY_SELECT

  ngOnInit() {}


  setCountry(country: {
    COUNTRY_NAME: string,
    COUNTRY_FLAG: string,
    COUNTRY_BASE_PATH: string,
    COUNTRY_MEDIA_PATH: string,
    COUNTRY_DEFAULT_LANGUAGE: string,
    BOOKING_CLIENT_HOST: string
  }){
    localStorage.setItem("BookingHost", country.BOOKING_CLIENT_HOST);
    localStorage.setItem("BasePath", country.COUNTRY_BASE_PATH);
    localStorage.setItem("MediaPath", country.COUNTRY_MEDIA_PATH);
    localStorage.setItem("DefaultLanguage", country.COUNTRY_DEFAULT_LANGUAGE);
    window.location.reload();
  }

}
